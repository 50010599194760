import { useMutation } from '@tanstack/react-query'
import { keyBy, maxBy } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useLiveSessionIdContextIfAvailable } from 'sierra-client/components/liveV2/live-session-id-provider'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import {
  DropAWordChangedData,
  DropAWordDeletedData,
  liveSessionDataChannel,
} from 'sierra-client/realtime-data/channels'
import { typedPost, useCachedQuery } from 'sierra-client/state/api'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectIsFacilitator } from 'sierra-client/state/live-session/selectors'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { useRecapContext } from 'sierra-client/views/recap/recap-context'
import { dropAWordWordAdded, dropAWordWordDeleted } from 'sierra-client/views/v3-author/drop-a-word/logger'
import { DropAWord } from 'sierra-client/views/v3-author/drop-a-word/renderer/drop-a-word'
import { CreateContentId, LiveSessionId } from 'sierra-domain/api/nano-id'
import { DropAWordCardDeleteWordRequest, DropAWordCardUpsertWordRequest } from 'sierra-domain/api/strategy-v2'
import { UUID } from 'sierra-domain/api/uuid'
import { ScopedFileId, ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { DropAWordData } from 'sierra-domain/flexible-content/types'
import {
  XRealtimeStrategyContentDataDropAWordDeleteWord,
  XRealtimeStrategyContentDataDropAWordUpsertWord,
  XRealtimeStrategyContentDataGetDropAWordResponses,
} from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'

const useLiveSessionId = (): LiveSessionId => {
  const liveContext = useLiveSessionIdContextIfAvailable()
  const recapContext = useRecapContext()

  const available = liveContext ?? recapContext

  if (!available)
    throw new Error('Unable to find a live session id, make sure drop-a-word is used in a supported context')

  return ScopedLiveSessionId.extractId(available.liveSessionId)
}

function onTitleChanged(): void {
  throw new Error('Cannot change title in live')
}

export const LiveDropAWordCard: React.FC<{
  contentId: CreateContentId
  fileId: FileId
  fileData: DropAWordData
  addWordDisabled?: boolean
}> = ({ contentId, fileId, fileData, addWordDisabled }) => {
  const [deletedIds, setDeletedIds] = useState<Set<UUID>>(new Set())
  const [realTimeDataMap, setRealTimeDataMap] = useState<{
    [id: string]: DropAWordChangedData
  }>({})
  const liveSessionId = useLiveSessionId()
  const dispatch = useDispatch()

  const { isReceivingData } = liveSessionDataChannel.useChannel({
    channelId: liveSessionId,
    callback: newData => {
      const eventId = ScopedFileId.extractId(fileId)
      switch (newData.event) {
        case `drop-a-word-changed:${eventId}`: {
          const data = newData.data as DropAWordChangedData
          setRealTimeDataMap(previous => ({ ...previous, [data.id]: data }))
          break
        }
        case `drop-a-word-deleted:${eventId}`: {
          const data = newData.data as DropAWordDeletedData
          setDeletedIds(previous => new Set([...previous, data.id]))
          break
        }
      }
    },
  })

  const dropAWordResponses = useCachedQuery(
    XRealtimeStrategyContentDataGetDropAWordResponses,
    {
      contentId,
      fileId,
      liveSessionId,
    },
    {
      enabled: isReceivingData,
    }
  )

  const upsertUpsertWordMutation = useMutation({
    mutationFn: (data: DropAWordCardUpsertWordRequest) =>
      typedPost(XRealtimeStrategyContentDataDropAWordUpsertWord, data),
    onSuccess: () => dropAWordResponses.refetch(),
  })

  const dropAWordData = useMemo(() => {
    if (dropAWordResponses.data === undefined) return undefined

    const apiResponseMap = keyBy(dropAWordResponses.data.responses, 'id')
    const ids = new Set([...Object.keys(apiResponseMap), ...Object.keys(realTimeDataMap)] as UUID[])

    return {
      responses: Array.from(ids)
        .filter(id => !deletedIds.has(id))
        .map(id => {
          const realTimeData = realTimeDataMap[id]
          const apiData = apiResponseMap[id]

          const latest = maxBy([realTimeData, apiData], data =>
            data !== undefined ? new Date(data.updatedAt).valueOf() : 0
          )

          if (latest === undefined) return

          return {
            id: latest.id,
            word: latest.word,
            color: color(latest.color),
            userId: latest.userId,
          }
        })
        .filter(isDefined),
    }
  }, [deletedIds, dropAWordResponses.data, realTimeDataMap])

  const isFacilitator = useSelector(selectIsFacilitator)
  const userId = useSelector(selectUserId)

  const canDeletePillWithId = useCallback(
    (id: string) => {
      if (isFacilitator) return true

      const pill = dropAWordData?.responses.find(pill => pill.id === id)
      return pill !== undefined && pill.userId === userId
    },
    [dropAWordData?.responses, isFacilitator, userId]
  )

  const onAddWord = useStableFunction((word: string) => {
    upsertUpsertWordMutation.mutate({
      fileId,
      liveSessionId,
      contentId,
      word,
    })

    if (userId === undefined) return
    void dispatch(dropAWordWordAdded({ wordLength: word.length, userId }))
  })

  const deleteWordMutation = useMutation({
    mutationFn: (data: DropAWordCardDeleteWordRequest) =>
      typedPost(XRealtimeStrategyContentDataDropAWordDeleteWord, data),
    onSuccess: () => dropAWordResponses.refetch(),
  })

  const deletePillWithId = useStableFunction((responseId: UUID) => {
    deleteWordMutation.mutate({
      contentId,
      fileId,
      responseId,
      liveSessionId,
    })

    const word = dropAWordData?.responses.find(pill => pill.id === responseId)?.word
    if (word === undefined || userId === undefined) return
    void dispatch(dropAWordWordDeleted({ wordLength: word.length, userId }))
  })

  return (
    <DropAWord
      addWordDisabled={addWordDisabled}
      title={fileData.title}
      onTitleChanged={onTitleChanged}
      deletePillWithId={deletePillWithId}
      canDeletePillWithId={canDeletePillWithId}
      pills={dropAWordData?.responses}
      onAddWord={onAddWord}
    />
  )
}

import { createFileRoute } from '@tanstack/react-router'

import React from 'react'
import { LoadRecentOrDefaultDashboard } from 'sierra-client/features/insights'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { ManageHeadline } from 'sierra-client/views/manage/components/layout/manage-headline'
import { useInsightsEnabled } from 'sierra-client/views/manage/hooks/use-insights-enabled'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

// Sadly needed due to how the manage layout works
const Wrapper = styled(View)`
  height: 100%;
  width: 100%;
`

const InsightsPage: React.FC = () => {
  const insightsEnabled = useInsightsEnabled()
  const { t } = useTranslation()

  if (!insightsEnabled) {
    return null
  }

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageInsights()}>
      <Wrapper direction='column'>
        <View direction='column'>
          <ManageHeadline>{t('manage.insights.headline')}</ManageHeadline>
          <LoadRecentOrDefaultDashboard />
        </View>
      </Wrapper>
    </ManagePageWrapper>
  )
}

const RouteComponent = InsightsPage

export const Route = createFileRoute('/manage/insights/')({
  component: RouteComponent as React.FC,
})

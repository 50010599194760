import { UseQueryResult } from '@tanstack/react-query'
import { getInsightsQueryOptions } from 'sierra-client/features/insights/api-hooks/query-options'
import { useCachedQuery } from 'sierra-client/state/api'
import { InsightsDashboardTemplateListResponse } from 'sierra-domain/api/insights'
import { XAnalyticsDashboardsTemplatesList } from 'sierra-domain/routes'

export const useListDashboardTemplates = (): UseQueryResult<InsightsDashboardTemplateListResponse> => {
  const dashboardData = useCachedQuery(
    XAnalyticsDashboardsTemplatesList,
    {},
    {
      ...getInsightsQueryOptions<InsightsDashboardTemplateListResponse>(),
      staleTime: 10 * 1000,
    }
  )

  return dashboardData
}

import { RouteIds, type RegisteredRouter } from '@tanstack/react-router'

//
// We provide a global router to allow access to the router from anywhere in the
// app, and to be able to shim Next's global router.
//
// We need to define the router elsewhere (e.g. entry-client.tsx) and then set
// it here. Otherwise any route using the global router would also import the
// `routeTree`, resulting in circular imports.
//

export type GlobalRouter = RegisteredRouter
export type RouteId = RouteIds<GlobalRouter['routeTree']>

let globalRouter: GlobalRouter | undefined

/**
 * Access the global router instance.
 */
export function getGlobalRouter(): GlobalRouter {
  if (!globalRouter) {
    throw new Error('Tried to access global router before it was created!')
  }

  return globalRouter
}

/**
 * Set the global router instance. Should only be done once and as soon as the router is created.
 */
export function setGlobalRouter(router: GlobalRouter): void {
  globalRouter = router
}

/**
 * Cleans up and replaces unnecessarily encoded characters in the current location pathname.
 * It will still preserve any encoded characters that are invalid pathname characters.
 *
 * Useful to support URLs that might have been incorrectly encoded outside of our control without having to check for
 * edge cases when using the path params.
 *
 * @example
 * '/file%3AfileId?foo=bar' -> '/file:fileId?foo=bar'
 * '/file%3AfileId%3Ffoo=bar' -> '/file:fileId%3Ffoo=bar'
 * '/äåö' -> '/%C3%A4%C3%A5%C3%B6'
 */
export function cleanEntryUrl(): void {
  const decodedPathname = decodeURIComponent(location.pathname)

  if (location.pathname !== decodedPathname) {
    const cleanedUrl = new URL(location.href)

    // We set pathname specifically to ensure characters that only invalid path segment characters are re-encoded (like '?')
    // while keeping those that are (like ':').
    cleanedUrl.pathname = decodedPathname

    history.replaceState(null, '', cleanedUrl.href)
  }
}

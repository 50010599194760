import { UseQueryResult } from '@tanstack/react-query'
import React, { RefObject, useEffect, useRef, useState } from 'react'
import { useDebouncedState } from 'sierra-client/hooks/use-debounced-state'
import { useCachedQuery } from 'sierra-client/state/api'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { assertScenarioCard } from 'sierra-client/views/v3-author/scenario/utils'
import { CourseId } from 'sierra-domain/api/nano-id'
import { apply } from 'sierra-domain/editor/operations'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import {
  XRealtimeSelfPacedScenarioGetScenarioObjectiveSuggestions,
  XRealtimeSelfPacedScenarioGetScenarioSuggestions,
  XRealtimeSelfPacedScenarioGetScenarioTopicSuggestions,
} from 'sierra-domain/routes'
import { iife, isNonEmptyArray } from 'sierra-domain/utils'
import { HideScrollbarUnlessHovered } from 'sierra-ui/components/layout-kit'
import { IconButton, Skeleton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { FCC } from 'sierra-ui/types'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'

const Blurrer = styled.div<{ blurLeft?: boolean; blurRight?: boolean }>`
  position: absolute;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 20px;
    display: ${p => (p.blurLeft === true ? 'block' : 'none')};
    background: linear-gradient(to left, rgba(255, 255, 255, 0), ${token('surface/default')} 100%);
  }

  &::after {
    content: '';
    height: 100%;
    width: 40px;
    right: 0;
    position: absolute;
    display: ${p => (p.blurRight === true ? 'block' : 'none')};
    background: linear-gradient(to right, rgba(255, 255, 255, 0), ${token('surface/default')} 100%);
  }
`

interface UseIntersectionObserverArgs {
  target: RefObject<Element> // The target element to observe
  onIntersect: IntersectionObserverCallback // Callback function to execute when intersection occurs
  threshold?: number | number[] // Single or multiple thresholds at which to trigger the callback
  root?: Element | null // The element that is used as the viewport for checking visibility
  rootMargin?: string // Margin around the root, can have values similar to the CSS margin property
}

const useIntersectionObserver = ({
  target,
  onIntersect,
  threshold = 0,
  root = null,
  rootMargin = '0px',
}: UseIntersectionObserverArgs): void => {
  useEffect(() => {
    const t = target.current
    if (!t) {
      return
    }

    const observer = new IntersectionObserver(onIntersect, {
      root,
      rootMargin,
      threshold,
    })

    observer.observe(t)

    return () => {
      observer.unobserve(t)
    }
  }, [target, onIntersect, threshold, root, rootMargin])
}

const ButtonWrapper = styled(View)`
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 6px;
  width: fit-content;
  cursor: pointer;
  background: ${token('form/background/2')};
  padding: 3.5px 6px 3.5px 6px;
  height: 25px;

  &:hover {
    p {
      color: ${token('foreground/primary')};
    }
  }
`
const SuggestionButton: FCC<{ onClick: () => void }> = ({ children, onClick }) => {
  return (
    <ButtonWrapper onClick={onClick}>
      <Text color='foreground/secondary' size='micro' bold>
        {children}
      </Text>
    </ButtonWrapper>
  )
}

const LoadingSkeleton = React.forwardRef<HTMLDivElement, unknown>((_, ref) => (
  <Skeleton noDelay ref={ref} $radius='6px' $height={25} $width={200} />
))

const Wrapper = styled(View).attrs({ wrap: 'nowrap' })`
  overflow: auto;
  ${HideScrollbarUnlessHovered}
`

export const ScenarioSuggestions: React.FC<{ courseId: CourseId; fileId: FileId }> = ({
  courseId,
  fileId,
}) => {
  const suggestions = useCachedQuery(
    XRealtimeSelfPacedScenarioGetScenarioSuggestions,
    {
      courseId: courseId,
      fileId: fileId,
    },
    {
      staleTime: 60_000,
      gcTime: 60_000,
      refetchOnWindowFocus: false,
    }
  )
  const { operationState } = useCreatePageContext()
  const parentRef = useRef<HTMLDivElement>(null)
  const firstItemRef = useRef<HTMLDivElement>(null)
  const lastItemRef = useRef<HTMLDivElement>(null)
  const [blurLeft, setBlurLeft] = useState(false)
  const [blurRight, setBlurRight] = useState(true)

  useIntersectionObserver({
    target: firstItemRef,
    root: parentRef.current,
    threshold: 0.99,
    onIntersect: entries => {
      if (Boolean(entries[0]?.isIntersecting)) {
        setBlurLeft(false)
      } else {
        setBlurLeft(true)
      }
    },
  })
  useIntersectionObserver({
    target: lastItemRef,
    root: parentRef.current,
    threshold: 0.99,
    onIntersect: entries => {
      if (Boolean(entries[0]?.isIntersecting)) {
        setBlurRight(false)
      } else {
        setBlurRight(true)
      }
    },
  })

  const onPick = (title: string, description: string): void => {
    apply(operationState, {
      type: 'update-files',
      fileIds: [fileId],
      update: file => {
        assertScenarioCard(file)
        file.data.input.scenario = { title, description, type: 'custom' }
      },
    })
  }

  return (
    <View position='relative'>
      <Wrapper ref={parentRef}>
        {iife(() => {
          if (suggestions.isLoading || suggestions.isPending || suggestions.isRefetching) {
            return (
              <View>
                {Array.from({ length: 3 }).map((_, index) => (
                  <LoadingSkeleton
                    ref={index === 0 ? firstItemRef : index === 2 ? lastItemRef : undefined}
                    key={index}
                  />
                ))}
              </View>
            )
          }

          if (isNonEmptyArray(suggestions.data)) {
            return suggestions.data.map((suggestion, index) => (
              <div
                key={suggestion.title}
                ref={
                  isNonEmptyArray(suggestions.data)
                    ? index === 0
                      ? firstItemRef
                      : index === suggestions.data.length - 1
                        ? lastItemRef
                        : undefined
                    : undefined
                }
              >
                <SuggestionButton
                  onClick={() => {
                    onPick(suggestion.title, suggestion.description)
                  }}
                >
                  {suggestion.title}
                </SuggestionButton>
              </div>
            ))
          }
        })}
      </Wrapper>
      <Blurrer blurLeft={blurLeft} blurRight={blurRight} />
    </View>
  )
}

export const ScenarioTopicSuggestions: React.FC<{
  courseId: CourseId
  fileId: FileId
}> = ({ courseId, fileId }) => {
  const suggestions = useCachedQuery(
    XRealtimeSelfPacedScenarioGetScenarioTopicSuggestions,
    {
      courseId: courseId,
      fileId: fileId,
    },
    {
      staleTime: 60_000,
      gcTime: 60_000,
      refetchOnWindowFocus: false,
    }
  )
  const { operationState } = useCreatePageContext()
  const parentRef = useRef<HTMLDivElement>(null)
  const firstItemRef = useRef<HTMLDivElement>(null)
  const lastItemRef = useRef<HTMLDivElement>(null)
  const [blurLeft, setBlurLeft] = useState(false)
  const [blurRight, setBlurRight] = useState(true)

  useIntersectionObserver({
    target: firstItemRef,
    root: parentRef.current,
    threshold: 0.99,
    onIntersect: entries => {
      if (Boolean(entries[0]?.isIntersecting)) {
        setBlurLeft(false)
      } else {
        setBlurLeft(true)
      }
    },
  })
  useIntersectionObserver({
    target: lastItemRef,
    root: parentRef.current,
    threshold: 0.99,
    onIntersect: entries => {
      if (Boolean(entries[0]?.isIntersecting)) {
        setBlurRight(false)
      } else {
        setBlurRight(true)
      }
    },
  })

  const onPick = (topic: string): void => {
    apply(operationState, {
      type: 'update-files',
      fileIds: [fileId],
      update: file => {
        assertScenarioCard(file)
        file.data.input.scenario = { title: topic, description: '', type: 'custom' }
      },
    })
  }

  return (
    <View position='relative'>
      <Wrapper ref={parentRef}>
        {iife(() => {
          if (suggestions.isLoading || suggestions.isPending || suggestions.isRefetching) {
            return (
              <View>
                {Array.from({ length: 3 }).map((_, index) => (
                  <LoadingSkeleton
                    ref={index === 0 ? firstItemRef : index === 2 ? lastItemRef : undefined}
                    key={index}
                  />
                ))}
              </View>
            )
          }

          if (isNonEmptyArray(suggestions.data)) {
            return suggestions.data.map((suggestion, index) => (
              <div
                key={suggestion.topic}
                ref={
                  isNonEmptyArray(suggestions.data)
                    ? index === 0
                      ? firstItemRef
                      : index === suggestions.data.length - 1
                        ? lastItemRef
                        : undefined
                    : undefined
                }
              >
                <SuggestionButton
                  onClick={() => {
                    onPick(suggestion.topic)
                  }}
                >
                  {suggestion.topic}
                </SuggestionButton>
              </div>
            ))
          }
        })}
        <IconButton
          iconId='restart'
          onClick={() => {
            void suggestions.refetch()
          }}
          variant='transparent'
        />
      </Wrapper>
      <Blurrer blurLeft={blurLeft} blurRight={blurRight} />
    </View>
  )
}

const useDebouncedObjectiveSuggestions = ({
  topic,
  courseId,
  fileId,
}: {
  topic: {
    title: string
    description: string
  }
  courseId: CourseId
  fileId: FileId
}): UseQueryResult<Array<{ title: string; objective: string }>, Error> => {
  const [debouncedTopic, setTopic] = useDebouncedState(topic, { wait: 1500 })

  // debounce based on topic title so we do not do request on every update of value
  useOnChanged((p, n) => {
    if (p?.title !== n.title) {
      setTopic(topic)
    }
  }, topic)

  const suggestions = useCachedQuery(
    XRealtimeSelfPacedScenarioGetScenarioObjectiveSuggestions,
    {
      courseId: courseId,
      fileId: fileId,
      topic: debouncedTopic,
    },
    {
      staleTime: 60_000,
      gcTime: 60_000,
      refetchOnWindowFocus: false,
    }
  )

  return suggestions
}

export const ScenarioObjectiveSuggestions: React.FC<{
  topic: {
    title: string
    description: string
  }
  courseId: CourseId
  fileId: FileId
}> = ({ topic, courseId, fileId }) => {
  const suggestions = useDebouncedObjectiveSuggestions({
    courseId: courseId,
    fileId: fileId,
    topic: topic,
  })
  const { operationState } = useCreatePageContext()
  const parentRef = useRef<HTMLDivElement>(null)
  const firstItemRef = useRef<HTMLDivElement>(null)
  const lastItemRef = useRef<HTMLDivElement>(null)
  const [blurLeft, setBlurLeft] = useState(false)
  const [blurRight, setBlurRight] = useState(true)

  useIntersectionObserver({
    target: firstItemRef,
    root: parentRef.current,
    threshold: 0.99,
    onIntersect: entries => {
      if (Boolean(entries[0]?.isIntersecting)) {
        setBlurLeft(false)
      } else {
        setBlurLeft(true)
      }
    },
  })
  useIntersectionObserver({
    target: lastItemRef,
    root: parentRef.current,
    threshold: 0.99,
    onIntersect: entries => {
      if (Boolean(entries[0]?.isIntersecting)) {
        setBlurRight(false)
      } else {
        setBlurRight(true)
      }
    },
  })

  const onPick = (objective: string): void => {
    apply(operationState, {
      type: 'update-files',
      fileIds: [fileId],
      update: file => {
        assertScenarioCard(file)
        file.data.input.learnerGoal = objective
      },
    })
  }

  return (
    <View position='relative'>
      <Wrapper ref={parentRef}>
        {iife(() => {
          if (suggestions.isLoading || suggestions.isPending || suggestions.isRefetching) {
            return (
              <View>
                {Array.from({ length: 3 }).map((_, index) => (
                  <LoadingSkeleton
                    ref={index === 0 ? firstItemRef : index === 2 ? lastItemRef : undefined}
                    key={index}
                  />
                ))}
              </View>
            )
          }

          if (isNonEmptyArray(suggestions.data)) {
            return suggestions.data.map((suggestion, index) => (
              <div
                key={suggestion.title}
                ref={
                  isNonEmptyArray(suggestions.data)
                    ? index === 0
                      ? firstItemRef
                      : index === suggestions.data.length - 1
                        ? lastItemRef
                        : undefined
                    : undefined
                }
              >
                <SuggestionButton
                  onClick={() => {
                    onPick(suggestion.objective)
                  }}
                >
                  {suggestion.title}
                </SuggestionButton>
              </div>
            ))
          }
        })}
      </Wrapper>
      <Blurrer blurLeft={blurLeft} blurRight={blurRight} />
    </View>
  )
}

import { UseQueryResult } from '@tanstack/react-query'
import React from 'react'
import { useCachedQuery } from 'sierra-client/state/api'
import { ManageContentTabular } from 'sierra-client/views/manage/content/manage-content-tabular'
import { WhoopsPage } from 'sierra-client/views/whoops-page'
import { FilterContentDomainResponse } from 'sierra-domain/filter/request'
import { XRealtimeContentFilterContentDomain } from 'sierra-domain/routes'
import { LoadingSpinner, View } from 'sierra-ui/primitives'

const useFilterContentDomain = (): UseQueryResult<FilterContentDomainResponse> => {
  const res = useCachedQuery(XRealtimeContentFilterContentDomain, {
    key: 'content.filter',
  })

  return res
}

export const ManageContent: React.FC<unknown> = () => {
  const domainReps = useFilterContentDomain()

  if (domainReps.isPending) {
    return (
      <View>
        <LoadingSpinner />
      </View>
    )
  } else if (domainReps.isError) {
    // TODO: we should wrap the outer container with a StrategicErrorBoundary and throw
    return <WhoopsPage error={domainReps.error} />
  } else {
    // Render
    return <ManageContentTabular domainReps={domainReps.data.domains} />
  }
}

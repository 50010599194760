import { useContext, useMemo } from 'react'
import { useConfig } from 'sierra-client/context/config-context'
import { customThemeWithDefaults, migrateCustomThemes } from 'sierra-client/hooks/use-themes'
import { FCC } from 'sierra-client/types'
import { File } from 'sierra-domain/flexible-content/types'
import { assertNever } from 'sierra-domain/utils'
import { ContentThemeTransformingTokenProvider } from 'sierra-ui/theming'
import {
  CustomThemeName,
  PresetThemeName,
  Theme,
  getTheme,
  useCardTheme,
  useLightTheme,
} from 'sierra-ui/theming/legacy-theme'
import { ThemeContext, ThemeProvider } from 'styled-components'

type Props = {
  data: File['data']
  theme?: File['theme']
  previewTheme?: CustomThemeName | PresetThemeName | undefined
}

export function getDefaultCardTheme({
  dataType,
  lightTheme,
  cardTheme,
}: {
  dataType: File['data']['type']
  cardTheme: Theme
  lightTheme: Theme
}): Theme {
  switch (dataType) {
    case 'poll':
      return getTheme(cardTheme, 'purple-vivid-darker')
    case 'sliding-scale':
      return getTheme(cardTheme, 'blue-light')
    case 'flip-cards':
      return getTheme(cardTheme, 'green-darker-vivid')
    case 'reflections':
      return getTheme(cardTheme, 'orange-bright')
    case 'general':
    case 'bullet':
    case 'slate-card':
    case 'notepad':
    case 'external-notepad':
    case 'embed':
    case 'sticky-notes':
    case 'project-card':
    case 'drop-a-word':
    case 'scenario':
    case 'roleplay':
      return getTheme(lightTheme, 'white')
    case 'question-card':
    case 'assessment-card':
    case 'homework':
      return getTheme(cardTheme, 'blue-darker-vivid')
    case 'live-lobby':
    case 'video':
    case 'image':
    case 'breakout-room':
      return getTheme(cardTheme, 'black')

    case 'stupid-questions':
      return getTheme(cardTheme, 'pink-darker-vivid')
    default:
      assertNever(dataType)
  }
}

export function useThemeForFile({ data, theme }: Partial<Props> = {}): Theme {
  const organizationThemes = useConfig().settings.brand.customThemes
  const lightTheme = useLightTheme()
  const cardTheme = useCardTheme()

  const resolvedTheme = useMemo(() => {
    if (data === undefined) return lightTheme

    if (theme === undefined) {
      return getDefaultCardTheme({ dataType: data.type, lightTheme, cardTheme })
    } else {
      // There is an unfortunate inconsistency in the naming of the CustomTheme keys which requires the following transformation:
      const customThemes = customThemeWithDefaults(migrateCustomThemes(organizationThemes))
      const result = getTheme({ ...lightTheme, customThemes }, theme)
      return result
    }
  }, [data, lightTheme, theme, cardTheme, organizationThemes])

  return resolvedTheme
}

export const PolarisCardTheme: FCC<Props> = ({ children, ...props }) => {
  const themeContext = useContext(ThemeContext)
  const fileTheme = useThemeForFile(props)
  const theme = props.previewTheme ? getTheme(themeContext, props.previewTheme) : fileTheme

  return (
    <ThemeProvider theme={theme}>
      <ContentThemeTransformingTokenProvider>{children}</ContentThemeTransformingTokenProvider>
    </ThemeProvider>
  )
}

import { useCallback, useMemo } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useFlags } from 'sierra-client/context/flag-context'
import { useReadLatestValidSelection, useSetSlashMenuState } from 'sierra-client/editor/editor-jotai-context'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { useSlashMenuCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { useEditorUploadFile } from 'sierra-client/views/v3-author/file-attachment/use-editor-upload-file'
import * as ShortcutCommands from 'sierra-client/views/v3-author/slash-menu/event-handlers'
import { EditorKeyboardEvent } from 'sierra-domain/v3-author'
import { useSlateStatic } from 'slate-react'

export const useSlashMenuOnKeyDown = (): {
  onKeyDown: (event: EditorKeyboardEvent) => void
} => {
  const { dynamicT } = useTranslation()
  const notification = useNotif()
  const dispatch = useDispatch()
  const editor = useSlateStatic()
  const flags = useFlags()
  const createPageContext = useSlashMenuCreatePageContext()
  const readLatestSelection = useReadLatestValidSelection()
  const setSlashMenuState = useSetSlashMenuState()
  const showUploadFileModal = useEditorUploadFile().showUploadModal
  const onKeyDown = useCallback(
    (event: EditorKeyboardEvent) => {
      const latestSelection = readLatestSelection()
      setSlashMenuState(state =>
        ShortcutCommands.onKeyDown({
          createPageContext,
          event,
          editor,
          selection: latestSelection,
          dispatch,
          state,
          notification,
          dynamicT,
          showUploadFileModal,
          flags,
        })
      )
    },
    [
      readLatestSelection,
      setSlashMenuState,
      createPageContext,
      editor,
      dispatch,
      notification,
      dynamicT,
      showUploadFileModal,
      flags,
    ]
  )

  return useMemo(() => ({ onKeyDown }), [onKeyDown])
}

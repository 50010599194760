import { useAtomValue } from 'jotai'
import * as React from 'react'
import { useState } from 'react'
import { useConfirmationModalContext } from 'sierra-client/components/common/modals/confirmation-modal'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { OrganizationClusterState, useOrganizationCluster } from 'sierra-client/features/multi-tenancy'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import {
  TabularToolbar,
  TabularToolbarButton,
  TabularToolbarDownloadButton,
} from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { ContentTableData, contentDataLoader } from 'sierra-client/lib/tabular/dataloader/content'
import { exportTableData } from 'sierra-client/lib/tabular/export'
import { TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { BasicTabular } from 'sierra-client/lib/tabular/provider/components/basic'
import { Filtering } from 'sierra-client/lib/tabular/tables/content'
import { RowRef } from 'sierra-client/lib/tabular/types'
import { UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import {
  defaultMenuActionVirtualColumn,
  defaultSelectVirtualColumn,
  getRowDataForRowRefs,
  getRowDataFromTableAPI,
  temporaryComputeSelectedRows,
} from 'sierra-client/lib/tabular/utils'
import { VirtualColumns } from 'sierra-client/lib/tabular/virtual-columns'
import { getGlobalRouter } from 'sierra-client/router'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import { ManageHeader, ManageHeaderSearchConfig } from 'sierra-client/views/manage/components/manage-header'
import {
  ContentActions,
  ContentActionsProps,
} from 'sierra-client/views/manage/content/components/content-actions'
import { ImportCourse } from 'sierra-client/views/manage/content/components/import-course'
import { useFeaturedContentIds } from 'sierra-client/views/manage/content/hooks/use-featured-content'
import { useManageContent } from 'sierra-client/views/manage/content/hooks/use-manage-content'
import {
  ContentItem,
  bareContentId,
  getHrefForContentDetails,
} from 'sierra-client/views/manage/content/utils/content-utils'
import { contentRepToEditionPrefillData } from 'sierra-client/views/manage/courses/course-groups/modals/create-course-group-modal'
import { PageHeader } from 'sierra-client/views/settings/components/page-header'
import { DomainRep } from 'sierra-domain/filter/datatype/domain'
import { andAll, createFilter } from 'sierra-domain/filter/datatype/filter'
import { Or } from 'sierra-domain/filter/datatype/pred'
import { defaultOp } from 'sierra-domain/filter/query'
import { iife, isDefined, isNotDefined } from 'sierra-domain/utils'
import { LabelMenuItem } from 'sierra-ui/components'
import { LightTokenProvider } from 'sierra-ui/theming'

export type ManageContentTabularActions = {
  onViewDetails: (id: RowRef, api: TableAPI<ContentTableData>) => void
  onAssignContent: (ids: RowRef[], api: TableAPI<ContentTableData>) => void
  onConvertToCourseGroup: (ids: RowRef[], api: TableAPI<ContentTableData>) => void
  onEditFeaturePresentation: (id: RowRef, api: TableAPI<ContentTableData>) => void
  onFeaturePresentation: (id: RowRef, api: TableAPI<ContentTableData>) => void
  onDetachEdition: (id: RowRef, api: TableAPI<ContentTableData>) => void
  onDetachAllEditions: (id: RowRef, api: TableAPI<ContentTableData>) => void
  onMultiOrgViewInParentOrg: (id: RowRef, api: TableAPI<ContentTableData>) => void
}

type ManageContentTablePermissions = {
  canEditFeaturedContent: boolean
  canCreateCourseGroup: boolean
  canEditAssignments: boolean
}

const virtualColumns = (
  actions: ManageContentTabularActions,
  { canEditFeaturedContent, canCreateCourseGroup, canEditAssignments }: ManageContentTablePermissions,
  t: TranslationLookup,
  orgCluster: OrganizationClusterState
): VirtualColumns<ContentTableData> => ({
  left: [defaultSelectVirtualColumn()],
  right: [
    defaultMenuActionVirtualColumn<ContentTableData>({
      getProps: (api, pos) => {
        const data = getRowDataFromTableAPI(api, pos.row)?.content.data

        if (isNotDefined(data)) return 'no-actions'

        const menuItems = iife(() => {
          const hideCourseGroupConvert =
            !canCreateCourseGroup ||
            data.isCourseEdition === true ||
            (data.courseKind !== 'native:self-paced' && data.courseKind !== 'scorm')

          const viewDetailsAction: LabelMenuItem = {
            id: pos.row + '-view-details',
            type: 'label',
            label: t('manage.view-details'),
            onClick: () => actions.onViewDetails(pos.row, api),
          }

          const assignAction: LabelMenuItem = {
            id: pos.row + '-assign',
            type: 'label',
            label: t('dictionary.assign'),
            hidden: !canEditAssignments,
            onClick: () => actions.onAssignContent([pos.row], api),
          }

          const featureAction: LabelMenuItem =
            data.isFeatured === true
              ? {
                  id: pos.row + '-edit-presentation',
                  type: 'label',
                  label: t('manage.content.actions.edit-presentation'),
                  onClick: () => actions.onEditFeaturePresentation(pos.row, api),
                }
              : {
                  id: pos.row + '-feature',
                  type: 'label',
                  label: t('manage.content.actions.feature'),
                  onClick: () => actions.onFeaturePresentation(pos.row, api),
                }

          const parentOrg = !orgCluster.loading
            ? orgCluster.cluster.find(org => org.isClusterParent)
            : undefined

          const multiOrgSubOrgAction: LabelMenuItem = {
            id: pos.row + '-multi-org-sub-org',
            type: 'label',
            label: t('manage.content.actions.multi-org-open-in-parent-org', {
              org: parentOrg?.name,
            }),
            icon: 'launch',
            onClick: () => actions.onMultiOrgViewInParentOrg(pos.row, api),
            hidden: isNotDefined(parentOrg) || data.readOnly !== true,
          }

          if (data.isCourseEdition) {
            return [viewDetailsAction, assignAction]
          }

          return (
            [viewDetailsAction, assignAction]
              .concat(
                hideCourseGroupConvert
                  ? []
                  : [
                      {
                        id: pos.row + '-convert-to-course-group',
                        type: 'label',
                        label: t('course-editions.create.content-table.label'),
                        onClick: () => actions.onConvertToCourseGroup([pos.row], api),
                      } satisfies LabelMenuItem,
                    ]
              )
              // TODO(Multi-T):Even if the user can feature on an org-level, a distributed course
              //  is not featurable as it's not possible to edit visibility in the child org.
              .concat(canEditFeaturedContent && data.readOnly !== true ? [featureAction] : [])
              .concat(multiOrgSubOrgAction)
          )
        })
        return {
          menuItems,
        }
      },
    }),
  ],
})

const queryKey = ['manage-content-tabular']
const useManageContentTableAPI = (
  actions: ManageContentTabularActions,
  domainReps: DomainRep[],
  permissions: ManageContentTablePermissions,
  generateFiltersEnabled: boolean
): UseTableAPI<ContentTableData, any> => {
  const { t } = useTranslation()
  const organizationCluster = useOrganizationCluster()
  const isClusterParent = !organizationCluster.loading && organizationCluster.selfIsParent

  const api = useTableAPI({
    dataLoader: React.useMemo(
      () => ({ loader: contentDataLoader(t, isClusterParent), options: { queryKey } }),
      [t, isClusterParent]
    ),
    virtualColumns: React.useMemo(
      () => virtualColumns(actions, permissions, t, organizationCluster),
      [actions, permissions, t, organizationCluster]
    ),
    options: React.useMemo(
      () => ({
        filter: {
          domainReps: domainReps,
          initialFilter: andAll(
            domainReps.map(domain => createFilter(domain.ref, defaultOp(domain), Or([])))
          ),
          allowEmptyFilters: true,
          domain: generateFiltersEnabled ? { type: 'content' } : undefined,
        },
        sorting: { staticSorting: [{ column: 'FEATURED', direction: 'descending' }] },
        limit: 30,
      }),
      [domainReps, generateFiltersEnabled]
    ),
  })

  return api
}

const searchBar: ManageHeaderSearchConfig = { placeholder: 'manage.search.content' }

const ConvertToCourseGroupAction = ({
  api,
  actions,
}: {
  api: TableAPI<ContentTableData>
  actions: ManageContentTabularActions
}): JSX.Element => {
  const { t } = useTranslation()
  const sel = useAtomValue(api.atoms.selection)

  const tableData = useAtomValue(api.atoms.tableData)

  const { validSelfPacedCourseIds, validScormCourseIds } = React.useMemo(() => {
    const validSelfPacedCourseIds = new Set<string>()
    const validScormCourseIds = new Set<string>()

    tableData.forEach(page => {
      page.rows.forEach(row => {
        const content = row.data.content.data
        if (content !== undefined) {
          const id = content.id
          if (content.contentType === 'course' && !content.isCourseEdition) {
            if (content.courseKind === 'native:self-paced') {
              validSelfPacedCourseIds.add(id)
            } else if (content.courseKind === 'scorm') {
              validScormCourseIds.add(id)
            }
          }
        }
      })
    })

    return { validSelfPacedCourseIds, validScormCourseIds }
  }, [tableData])

  const selectedIds = React.useMemo(() => temporaryComputeSelectedRows(tableData, sel), [tableData, sel])

  const isValidSelection = React.useMemo(() => {
    if (selectedIds.length === 0) return false

    // All courses must be of the same type to create a course group
    return (
      selectedIds.every(id => validSelfPacedCourseIds.has(id)) ||
      selectedIds.every(id => validScormCourseIds.has(id))
    )
  }, [selectedIds, validSelfPacedCourseIds, validScormCourseIds])

  if (!isValidSelection) return <></>

  return (
    <TabularToolbarButton
      onClick={() => {
        actions.onConvertToCourseGroup(selectedIds, api)
      }}
      icon='bookmark'
    >
      {t('manage.content.convert-to-course-group')}
    </TabularToolbarButton>
  )
}

const AssignToolbarAction = ({
  api,
  actions,
}: {
  api: TableAPI<ContentTableData>
  actions: ManageContentTabularActions
}): JSX.Element => {
  const { t } = useTranslation()
  const tableData = useAtomValue(api.atoms.tableData)
  const sel = useAtomValue(api.atoms.selection)
  if (sel.type === 'none') return <></>
  return (
    <TabularToolbarButton
      onClick={() => actions.onAssignContent(temporaryComputeSelectedRows(tableData, sel), api)}
      icon='add'
    >
      {t('dictionary.assign')}
    </TabularToolbarButton>
  )
}

export const ManageContentTabular: React.FC<{
  domainReps: DomainRep[]
}> = React.memo(({ domainReps }) => {
  const { t } = useTranslation()
  const orgCluster = useOrganizationCluster()

  const { detachEdition, deleteCourseGroup } = useManageContent()
  const { featuredContentIds, refetch: refetchFeaturedContentIds } = useFeaturedContentIds()
  const [action, setAction] = useState<ContentActionsProps['action']>({ modal: undefined })
  const handleOpenFeaturedContentModal = React.useCallback(
    (contentItem: ContentItem): void => {
      setAction({ modal: 'organization-add-featured-content', contentItem })
    },
    [setAction]
  )

  const confirmationModalContext = useConfirmationModalContext()

  const getEditionIds = (api: TableAPI, courseGroupId: string): string[] => {
    const tableData = api.query.tableData().tableData

    for (const page of tableData) {
      const nested = page.nested[courseGroupId]
      if (nested !== undefined) return nested.rows.map(r => r.id)
    }

    return []
  }

  const tabularActions = React.useMemo<ManageContentTabularActions>(
    () => ({
      onViewDetails: (id, api) => {
        const data = getRowDataFromTableAPI(api, id)?.content.data

        if (isNotDefined(data)) return

        const url = getHrefForContentDetails({
          id: bareContentId(id),
          contentType: data.contentType,
          courseKind: data.courseKind,
        })

        void getGlobalRouter().navigate({ to: url })
      },
      onAssignContent: (ids, api) => {
        const targets = ids.flatMap(id => {
          const data = getRowDataFromTableAPI(api, id)?.content.data
          if (isNotDefined(data)) return []

          return [
            {
              id: bareContentId(id),
              contentType: data.contentType,
              courseKind: data.courseKind,
            },
          ]
        })

        setAction({
          modal: 'enroll',
          targets,
        })
      },
      onConvertToCourseGroup: (ids, api) => {
        const data = getRowDataForRowRefs(api.query.tableData().tableData, ids)

        const [firstValue, ...otherValues] = Object.values(data)

        const firstContentData = firstValue?.content.data

        if (firstContentData === undefined) return

        const firstEdition = contentRepToEditionPrefillData(firstContentData)
        const otherEditions = otherValues
          .map(it => it.content.data)
          .filter(isDefined)
          .map(contentRepToEditionPrefillData)

        setAction({
          modal: 'create-course-group',
          prefillEditions: [firstEdition, ...otherEditions],
        })

        // Clear selection to allow for new grouping
        api.action.setSelection({ type: 'none' })
      },
      onEditFeaturePresentation: (id, api) => {
        const data = getRowDataFromTableAPI(api, id)?.content.data
        if (isNotDefined(data)) return

        setAction({
          modal: 'organization-add-featured-content',
          contentItem: data,
        })
      },

      onFeaturePresentation: (id, api) => {
        const data = getRowDataFromTableAPI(api, id)?.content.data

        if (isNotDefined(data)) return

        if (featuredContentIds.find(x => x === id) !== undefined) {
          confirmationModalContext.show({
            bodyText: t('manage.content.actions.feature-another-content-warning'),
            onConfirm: () => {
              handleOpenFeaturedContentModal(data)
            },
          })
        } else {
          handleOpenFeaturedContentModal(data)
        }
      },

      onDetachAllEditions: courseGroupId => {
        setAction({
          modal: 'detach-all-editions',
          onConfirm: async () => {
            await deleteCourseGroup(bareContentId(courseGroupId))
          },
        })
      },

      onMultiOrgViewInParentOrg: (id, api) => {
        const data = getRowDataFromTableAPI(api, id)?.content.data
        if (isNotDefined(data)) return

        const courseId = bareContentId(data.id)
        const parentOrg = !orgCluster.loading
          ? orgCluster.cluster.find(org => org.isClusterParent)
          : undefined

        if (isNotDefined(parentOrg)) return

        const courseUrl = new URL(`manage/courses/${courseId}`, `https://${parentOrg.domain}`).toString()
        void window.open(courseUrl, '_blank')
      },

      onDetachEdition: (editionId, api) => {
        const editionData = getRowDataFromTableAPI(api, editionId)?.content.data
        const courseGroupId = editionData?.courseGroupId

        if (isNotDefined(courseGroupId)) return

        const allEditionIds = getEditionIds(api, courseGroupId)

        const shouldDeleteGroup = allEditionIds.length <= 2

        setAction({
          modal: 'detach-edition',
          onConfirm: async () => {
            if (shouldDeleteGroup) {
              // Delete whole group
              await deleteCourseGroup(bareContentId(courseGroupId))
            } else {
              // Detach edition
              await detachEdition(bareContentId(editionId))
            }
          },
        })
      },

      onRefresh: () => {},
    }),
    [
      featuredContentIds,
      confirmationModalContext,
      t,
      handleOpenFeaturedContentModal,
      deleteCourseGroup,
      orgCluster.loading,
      orgCluster.cluster,
      detachEdition,
    ]
  )

  const orgPermissions = useOrganizationPermissions()
  const canEditFeaturedContent = orgPermissions.has('EDIT_FEATURED')
  const canCreateCourseGroup = orgPermissions.has('CREATE_COURSE_GROUP')
  const canEditAssignments = orgPermissions.has('EDIT_CONTENT_ASSIGNMENTS')
  const permissions = React.useMemo(
    () => ({
      canEditFeaturedContent,
      canCreateCourseGroup,
      canEditAssignments,
    }),
    [canEditFeaturedContent, canCreateCourseGroup, canEditAssignments]
  )
  const generateFiltersEnabled = useFlag('manage/generate-filters')

  const tableAPI = useManageContentTableAPI(tabularActions, domainReps, permissions, generateFiltersEnabled)

  const importCourseHeaderAction = React.useMemo(
    () => <ImportCourse onSelect={target => setAction({ modal: target })} />,
    [setAction]
  )

  return (
    <>
      <PageTitle title={t('dictionary.content-plural')} />

      <TabularProviderFromTableAPI tableAPI={tableAPI}>
        <ColumnLayout>
          <PageHeader
            title='dictionary.content-singular'
            withoutMargin
            helpLink='https://help.sana.ai/en/collections/89966-managing-content'
          />

          <ManageHeader
            api={tableAPI.api}
            search={searchBar}
            filtering={<Filtering api={tableAPI.api} />}
            actionElement={importCourseHeaderAction}
          />

          <TabularToolbar
            countsTranslationKeys={{
              totalKey: 'manage.content.n-contents',
              filterKey: 'manage.program-members.n-filtered',
              selectedKey: 'manage.tables.n-selected',
            }}
            api={tableAPI.api}
            clearFilters={false}
            actions={
              <>
                {canEditAssignments && <AssignToolbarAction api={tableAPI.api} actions={tabularActions} />}
                {canCreateCourseGroup && (
                  <ConvertToCourseGroupAction api={tableAPI.api} actions={tabularActions} />
                )}
                <TabularToolbarDownloadButton
                  onDownloadCSV={() =>
                    exportTableData({
                      api: tableAPI.api,
                      dataLoader: tableAPI.dataLoader,
                      t,
                      fileType: 'csv',
                    })
                  }
                  onDownloadXLSX={() =>
                    exportTableData({
                      api: tableAPI.api,
                      dataLoader: tableAPI.dataLoader,
                      t,
                      fileType: 'xlsx',
                    })
                  }
                />
              </>
            }
          />
          <BasicTabular />
        </ColumnLayout>
      </TabularProviderFromTableAPI>
      <LightTokenProvider>
        <ContentActions
          action={action}
          onClose={() => {
            if (action.modal === 'organization-add-featured-content') {
              void refetchFeaturedContentIds()
            }
            setAction({ modal: undefined })
            void tableAPI.api.action.refresh()
          }}
          onDone={() => {
            // Close modal
            setAction({ modal: undefined })
            void tableAPI.api.action.refresh()
          }}
        />
      </LightTokenProvider>
    </>
  )
})

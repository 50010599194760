import { UseQueryResult } from '@tanstack/react-query'
import fuzzysort from 'fuzzysort'
import { useAtomValue } from 'jotai'
import React, { useEffect, useState } from 'react'
import { useListDashboardTemplates } from 'sierra-client/features/insights/api-hooks/use-list-dashboard-templates'
import { useListDashboards } from 'sierra-client/features/insights/api-hooks/use-list-dashboards'
import { useUpsertDashboard } from 'sierra-client/features/insights/api-hooks/use-upsert-dashboard'
import { dashboardsMetaAtom } from 'sierra-client/features/insights/atoms'
import { createNewDashboardAndRedirect } from 'sierra-client/features/insights/dashboard-selector/utils'
import { DashboardErrorCard } from 'sierra-client/features/insights/dashboard/dashboard-error'
import {
  MyDashboardsList,
  SharedDashboardsList,
  TemplateList,
} from 'sierra-client/features/insights/home/dashboard-list'
import { insightsDashboardCreatedLogger } from 'sierra-client/features/insights/logger'
import { useDebouncedState } from 'sierra-client/hooks/use-debounced-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { labelToString } from 'sierra-client/lib/filter/components/common'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { useDispatch } from 'sierra-client/state/hooks'
import { AnimatedSearch } from 'sierra-client/views/manage/components/animated-search'
import {
  InsightsDashboardListResponse,
  InsightsDashboardTemplateListResponse,
} from 'sierra-domain/api/insights'
import { Button, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { spacing, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const ViewWithBottomBorder = styled(View).attrs({ animated: true, layout: true })`
  padding-bottom: ${spacing['12']};
  border-bottom: 1px solid ${token('border/default')};
`

const NoResultsFoundWrapper = styled(View)`
  height: 12.5rem;
`

const NoMatchingContent: React.FC<{ clear: () => void }> = ({ clear }) => {
  const { t } = useTranslation()

  return (
    <NoResultsFoundWrapper direction='column' justifyContent='center' alignItems='center' gap='24'>
      <View direction='column' justifyContent='flex-start' alignItems='center' gap='2'>
        <Text bold color='foreground/muted' align='center'>
          {t('dictionary.no-matching-content')}
        </Text>
        <Text color='foreground/muted' align='center'>
          {t('create-page.adjust-filters')}
        </Text>
      </View>
      <View onClick={clear} cursor='pointer'>
        <Text bold color='foreground/secondary' align='center' onClick={clear}>
          {t('dictionary.clear-all-filters')}
        </Text>
      </View>
    </NoResultsFoundWrapper>
  )
}

const SectionWrapperView = styled(View).attrs({
  direction: 'column',
  gap: '16',
  animated: true,
  layout: true,
})``

const SectionHeaderText = styled(Text).attrs({ bold: true, size: 'small', color: 'foreground/secondary' })``

const DashboardsWithData: React.FC<{
  dashboards: InsightsDashboardListResponse['dashboards']
  templateResponse: UseQueryResult<InsightsDashboardTemplateListResponse>
  onCreateNewDashboard: () => Promise<void>
  searchTerm: string
  clearSearch: () => void
}> = ({ dashboards, templateResponse, onCreateNewDashboard, searchTerm, clearSearch }) => {
  const { t, dynamicT } = useTranslation()
  const dashboardsMeta = useAtomValue(dashboardsMetaAtom)

  const sortedDashboards = dashboards.sort(
    (a, b) =>
      (dashboardsMeta[b.dashboard.id]?.lastVisitedTimestampMs ?? 0) -
      (dashboardsMeta[a.dashboard.id]?.lastVisitedTimestampMs ?? 0)
  )

  const myDashboards = sortedDashboards.filter(dashboard => dashboard.role === 'owner')
  const sharedWithMe = sortedDashboards.filter(dashboard => dashboard.role !== 'owner')
  const translatedTemplates = (templateResponse.data ?? []).map(template => ({
    ...template,
    name: labelToString(template.name, dynamicT),
    description: labelToString(template.description, dynamicT),
  }))

  const isSearching = searchTerm.length > 0
  const options = { all: true, limit: isSearching ? 10 : undefined } satisfies Fuzzysort.Options

  const searchedMyDashboards = fuzzysort
    .go(searchTerm, myDashboards, { ...options, key: 'dashboard.title' })
    .map(result => result.obj)

  const searchedSharedWithMeDashboards = fuzzysort
    .go(searchTerm, sharedWithMe, { ...options, key: 'dashboard.title' })
    .map(result => result.obj)

  const searchedTemplates = fuzzysort
    .go(searchTerm, translatedTemplates, { ...options, key: 'name' })
    .map(result => result.obj)

  const hideMyDashboards = isSearching && searchedMyDashboards.length === 0
  const hideSharedWithMeDashboards = isSearching && searchedSharedWithMeDashboards.length === 0
  const hideTemplates = isSearching && searchedTemplates.length === 0

  const noSearchResults = hideMyDashboards && hideSharedWithMeDashboards && hideTemplates

  if (noSearchResults) {
    return <NoMatchingContent clear={clearSearch} />
  }

  return (
    <>
      {!hideMyDashboards && (
        <SectionWrapperView>
          <ViewWithBottomBorder>
            <SectionHeaderText>{t('manage.insights.home.my-dashboards-header')}</SectionHeaderText>
          </ViewWithBottomBorder>

          <MyDashboardsList dashboards={searchedMyDashboards} onCreateNewDashboard={onCreateNewDashboard} />
        </SectionWrapperView>
      )}

      {!hideSharedWithMeDashboards && (
        <SectionWrapperView>
          <ViewWithBottomBorder>
            <SectionHeaderText>{t('manage.insights.home.shared-dashboards-header')}</SectionHeaderText>
          </ViewWithBottomBorder>

          <SharedDashboardsList dashboards={searchedSharedWithMeDashboards} />
        </SectionWrapperView>
      )}

      {!hideTemplates && (
        <SectionWrapperView>
          {!(templateResponse.status === 'success' && templateResponse.data.length === 0) && (
            <ViewWithBottomBorder>
              <SectionHeaderText>{t('manage.insights.home.standard-dashboards-header')}</SectionHeaderText>
            </ViewWithBottomBorder>
          )}
          {templateResponse.isPending ? (
            <LoadingSpinner />
          ) : templateResponse.isError ? (
            <DashboardErrorCard />
          ) : templateResponse.data.length === 0 ? null : (
            <TemplateList templates={searchedTemplates} />
          )}
        </SectionWrapperView>
      )}
    </>
  )
}

const DashboardsWithSearch: React.FC<{
  onCreateNewDashboard: () => Promise<void>
  searchTerm: string
  clearSearch: () => void
}> = ({ onCreateNewDashboard, searchTerm, clearSearch }) => {
  const dashboards = useListDashboards()
  const templateResponse = useListDashboardTemplates()

  if (dashboards.isPending) {
    return <LoadingSpinner />
  }

  if (dashboards.isError) {
    return <DashboardErrorCard />
  }

  return (
    <DashboardsWithData
      dashboards={dashboards.data.dashboards}
      templateResponse={templateResponse}
      onCreateNewDashboard={onCreateNewDashboard}
      searchTerm={searchTerm}
      clearSearch={clearSearch}
    />
  )
}

const SEARCH_TERM_ININIAL_VALUE = ''

export const AllDashboards: React.FC = () => {
  const { t } = useTranslation()
  const mutateDashboard = useUpsertDashboard()
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const [searchTerm, setSearchTerm] = useState(SEARCH_TERM_ININIAL_VALUE)
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useDebouncedState(searchTerm, { wait: 500 })

  useEffect(() => {
    setDebouncedSearchTerm(searchTerm)
  }, [searchTerm, setDebouncedSearchTerm])

  const clearSearch = (): void => {
    setSearchTerm(SEARCH_TERM_ININIAL_VALUE)
  }

  const onChangeSearch = (value: string): void => {
    setSearchTerm(value)
  }

  const createNewDashboard = async (): Promise<void> => {
    await createNewDashboardAndRedirect('empty', t, async dashboard => {
      const mutatedDashboard = await mutateDashboard.mutateAsync({ dashboard })
      return mutatedDashboard.dashboard
    })
    await dispatch(insightsDashboardCreatedLogger({ dashboardId: undefined }))
  }

  return (
    <>
      <View direction='column'>
        <Text bold size='regular'>
          {t('manage.insights.home.all-dashboards-header')}
        </Text>
        <View grow justifyContent='space-between'>
          <View>
            <AnimatedSearch
              onChange={onChangeSearch}
              value={searchTerm}
              placeholder={t('dictionary.search')}
              expandedWidth={isMobile ? '100%' : '200px'}
              disableClose={isMobile}
              initialOpen={isMobile}
            />
          </View>

          <View justifyContent='flex-end'>
            <Button onClick={createNewDashboard}>
              {t('manage.insights.dashboard-selector.create-new-dashboard')}
            </Button>
          </View>
        </View>
      </View>

      <View direction='column' gap='32'>
        <DashboardsWithSearch
          onCreateNewDashboard={createNewDashboard}
          searchTerm={debouncedSearchTerm}
          clearSearch={clearSearch}
        />
      </View>
    </>
  )
}

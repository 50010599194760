import { atom } from 'jotai'
import { loadable } from 'jotai/utils'
import * as React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { Filter, isEmptyFilter } from 'sierra-client/lib/filter'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import { TabularToolbarButton } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { UserTableData, usersDataLoader } from 'sierra-client/lib/tabular/dataloader/users'
import { UsersTabular } from 'sierra-client/lib/tabular/tables/users'
import { TableAPIOptions, UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import {
  defaultMenuActionVirtualColumn,
  defaultSelectVirtualColumn,
  temporaryComputeSelectedRows,
} from 'sierra-client/lib/tabular/utils'
import { VirtualColumns } from 'sierra-client/lib/tabular/virtual-columns'
import { typedPost } from 'sierra-client/state/api'
import { InviteUsersButton } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/index'
import { XRealtimeAdminUsersFilterUsersValidation } from 'sierra-domain/routes'
import { assertNever } from 'sierra-domain/utils'
import { MenuItem, Tooltip } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'

export type ManageUsersTabularActions = {
  meta: {
    canDelete: boolean
    canResetProgress: boolean
    canAssignContent: boolean
  }
  onShowHeatmap: (api: TableAPI<UserTableData>) => () => void
  onCreateSmartGroup: (api: TableAPI<UserTableData>) => () => void
  onInviteUser: (api: TableAPI<UserTableData>) => () => void
  onViewUserDetails: (api: TableAPI<UserTableData>) => (ref: string) => void
  onResetLearnerProgress: (api: TableAPI<UserTableData>) => (ref: string) => void
  onDeleteUsers: (api: TableAPI<UserTableData>) => (refs: string[]) => void
  onAddContentToUsers: (api: TableAPI<UserTableData>) => (refs: string[]) => void
  onAddContentToAllUsers: (api: TableAPI<UserTableData>) => void
  onBulkAddProgram: (api: TableAPI<UserTableData>) => (refs: string[]) => void
  onActivateUsers: (api: TableAPI<UserTableData>) => (refs: string[]) => void
  onActivateAllUsers: (api: TableAPI<UserTableData>) => void
  onPauseUsers: (api: TableAPI<UserTableData>) => (refs: string[]) => void
  onPauseAllUsers: (api: TableAPI<UserTableData>) => void
}

const virtualColumns = (
  actions: ManageUsersTabularActions,
  t: TranslationLookup
): VirtualColumns<UserTableData> => ({
  left: [defaultSelectVirtualColumn()],
  right: [
    defaultMenuActionVirtualColumn({
      getProps: (api, pos) => {
        const menuItems: Array<MenuItem> = [
          {
            id: pos.row + '-view-details',
            type: 'label',
            label: t('manage.view-details'),
            onClick: () => actions.onViewUserDetails(api)(pos.row),
          },
        ]
        if (actions.meta.canAssignContent) {
          menuItems.push({
            id: pos.row + '-add-to-program',
            type: 'label',
            label: t('admin.organization.users.add-to-program'),
            onClick: () => actions.onBulkAddProgram(api)([pos.row]),
          })
        }
        if (actions.meta.canResetProgress) {
          menuItems.push({
            id: pos.row + '-reset-progress',
            type: 'label',
            label: t('admin.organization.users.reset-progress'),
            onClick: () => actions.onResetLearnerProgress(api)(pos.row),
            color: 'destructive/background',
          })
        }
        return {
          menuItems,
        }
      },
    }),
  ],
})

const isFilterValidForFilterGroup = async (filter: Filter): Promise<boolean> => {
  const resp = await typedPost(XRealtimeAdminUsersFilterUsersValidation, {
    filter: filter,
    keys: ['user.filter-group'],
  })

  return resp.ok['user.filter-group'] === true
}

export const useManageUsersTableAPI = (
  actions: ManageUsersTabularActions,
  options: TableAPIOptions
): UseTableAPI<UserTableData, any> => {
  const { t, dynamicT } = useTranslation()

  return useTableAPI({
    dataLoader: React.useMemo(
      () => ({ loader: usersDataLoader(t, dynamicT), options: { queryKey: ['manage-users-tabular'] } }),
      [t, dynamicT]
    ),
    virtualColumns: React.useMemo(() => virtualColumns(actions, t), [actions, t]),
    options,
  })
}

export const ManageUsersTabular: React.FC<{
  actions: ManageUsersTabularActions
  tableAPI: UseTableAPI<UserTableData, any>
  canCreateSmartGroups: boolean
  canInviteUsers: boolean
  canAssignContent: boolean
}> = React.memo(({ actions, tableAPI, canCreateSmartGroups, canInviteUsers, canAssignContent }) => {
  const { t } = useTranslation()

  const isFilterValidForFilterGroupAtom = React.useMemo(() => {
    const validAtom = loadable(
      atom(async get => {
        const filter = get(tableAPI.api.atoms.filter)
        const valid = await isFilterValidForFilterGroup(filter)
        return valid
      })
    )
    return atom(get => {
      const valid = get(validAtom)
      return valid.state === 'hasData' && valid.data
    })
  }, [tableAPI.api.atoms.filter])

  const toolbarActionsAtom = React.useMemo(
    () =>
      atom(get => {
        const tableData = get(tableAPI.api.atoms.tableData)
        const selection = get(tableAPI.api.atoms.selection)

        const noneToolbar = (
          <>
            <TabularToolbarButton onClick={actions.onShowHeatmap(tableAPI.api)} icon='trend--up'>
              {t('manage.insights.view-heatmap')}
            </TabularToolbarButton>
            {canCreateSmartGroups && !isEmptyFilter(get(tableAPI.api.atoms.filter)) && (
              <Tooltip
                title={
                  !get(isFilterValidForFilterGroupAtom)
                    ? "Can't create smart group containing groups in filter"
                    : undefined
                }
              >
                <span>
                  <TabularToolbarButton
                    icon='bolt'
                    disabled={!get(isFilterValidForFilterGroupAtom)}
                    onClick={actions.onCreateSmartGroup(tableAPI.api)}
                  >
                    {t('create-smart-group')}
                  </TabularToolbarButton>
                </span>
              </Tooltip>
            )}
          </>
        )
        const manualToolbar = (
          <>
            {canAssignContent && (
              <TabularToolbarButton
                onClick={() => {
                  actions.onAddContentToUsers(tableAPI.api)(
                    temporaryComputeSelectedRows(tableData, selection)
                  )
                }}
                icon='add'
              >
                {t('manage.groups.assign-content')}
              </TabularToolbarButton>
            )}
            <TabularToolbarButton
              onClick={() => {
                actions.onActivateUsers(tableAPI.api)(temporaryComputeSelectedRows(tableData, selection))
              }}
            >
              {t('admin.organization.learners.unfreeze')}
            </TabularToolbarButton>
            <TabularToolbarButton
              onClick={() => {
                actions.onPauseUsers(tableAPI.api)(temporaryComputeSelectedRows(tableData, selection))
              }}
            >
              {t('admin.organization.learners.freeze')}
            </TabularToolbarButton>
            {actions.meta.canDelete && (
              <TabularToolbarButton
                onClick={() => {
                  actions.onDeleteUsers(tableAPI.api)(temporaryComputeSelectedRows(tableData, selection))
                }}
                icon='trash-can'
              >
                {t('admin.delete')}
              </TabularToolbarButton>
            )}
          </>
        )
        const allToolbar = (
          <>
            <TabularToolbarButton
              onClick={() => {
                actions.onAddContentToAllUsers(tableAPI.api)
              }}
              icon='add'
            >
              {t('manage.groups.assign-content')}
            </TabularToolbarButton>
            <TabularToolbarButton
              onClick={() => {
                actions.onActivateAllUsers(tableAPI.api)
              }}
            >
              {t('admin.organization.learners.unfreeze')}
            </TabularToolbarButton>
            {/* <TabularToolbarButton */}
            {/*   onClick={() => { */}
            {/*     actions.onPauseAllUsers(tableAPI.api) */}
            {/*   }} */}
            {/* > */}
            {/*   {t('admin.organization.learners.freeze')} */}
            {/* </TabularToolbarButton> */}
          </>
        )
        const { type } = get(tableAPI.api.atoms.selection)
        switch (type) {
          case 'none':
            return noneToolbar
          case 'manual':
            return manualToolbar
          case 'all':
            return allToolbar
          default:
            assertNever(type)
        }
      }),
    [tableAPI.api, actions, t, isFilterValidForFilterGroupAtom, canCreateSmartGroups, canAssignContent]
  )

  const headerActionElement = React.useMemo(() => {
    if (!canInviteUsers) return null

    return (
      <View>
        <InviteUsersButton onOpen={actions.onInviteUser(tableAPI.api)} />
      </View>
    )
  }, [canInviteUsers, actions, tableAPI.api])

  return (
    <UsersTabular
      tableAPI={tableAPI}
      toolbarActionsAtom={toolbarActionsAtom}
      headerActionElement={headerActionElement}
    />
  )
})

import * as Paragraph from 'sierra-client/editor/blocks/paragraph'
import { BlockDefinition } from 'sierra-client/views/block-types'
import {
  StickyNotesCardContainer,
  StickyNotesCardSection,
  StickyNotesCardSectionContainer,
} from 'sierra-client/views/sticky-notes-card/create'
import {
  withStickyNotesCard,
  withStickyNotesCardSection,
} from 'sierra-client/views/sticky-notes-card/with-sticky-notes-card'
import * as AssessmentCards from 'sierra-client/views/v3-author/assessment-card'
import { withAssessments } from 'sierra-client/views/v3-author/assessment-card/with-assessment-card'
import * as BlockQuote from 'sierra-client/views/v3-author/block-quote/block-quote'
import { SimpleQuote } from 'sierra-client/views/v3-author/block-quote/simple-quote'
import { withBlockQuote } from 'sierra-client/views/v3-author/block-quote/with-block-quote'
import * as BulletCard from 'sierra-client/views/v3-author/bullet-card'
import { withBulletCard } from 'sierra-client/views/v3-author/bullet-card/with-bullet-card'
import * as Code from 'sierra-client/views/v3-author/code/code'
import { withCode } from 'sierra-client/views/v3-author/code/with-code'
import * as Components from 'sierra-client/views/v3-author/components'
import * as Embed from 'sierra-client/views/v3-author/embed/embed'
import { withEmbed } from 'sierra-client/views/v3-author/embed/with-embed'
import * as FileAttachment from 'sierra-client/views/v3-author/file-attachment/file-attachment'
import { withFileAttachment } from 'sierra-client/views/v3-author/file-attachment/with-file-attachment'
import * as FlipCards from 'sierra-client/views/v3-author/flip-cards/card'
import {
  withFlipCard,
  withFlipCardSides,
  withFlipCards,
  withFlipCardsCardContainer,
} from 'sierra-client/views/v3-author/flip-cards/with-flip-cards'
import * as Heading from 'sierra-client/views/v3-author/heading/heading'
import * as Homework from 'sierra-client/views/v3-author/homework/homework-card'
import { withHomeworkContainer } from 'sierra-client/views/v3-author/homework/with-homework-container'
import * as Images from 'sierra-client/views/v3-author/images/image'
import { withImage } from 'sierra-client/views/v3-author/images/with-image'
import * as Link from 'sierra-client/views/v3-author/link/link-component'
import { withLinks } from 'sierra-client/views/v3-author/link/with-links'
import * as CheckList from 'sierra-client/views/v3-author/list/checklist'
import * as List from 'sierra-client/views/v3-author/list/list'
import { withCheckLists } from 'sierra-client/views/v3-author/list/with-checklist'
import { withLists } from 'sierra-client/views/v3-author/list/with-list'
import * as Markdown from 'sierra-client/views/v3-author/markdown/markdown'
import { withMarkdown } from 'sierra-client/views/v3-author/markdown/with-markdown'
import * as Matrix from 'sierra-client/views/v3-author/matrix'
import * as Placeholder from 'sierra-client/views/v3-author/placeholder/placeholder'
import { withPlaceholder } from 'sierra-client/views/v3-author/placeholder/with-placeholder'
import { withHeading } from 'sierra-client/views/v3-author/plugins/with-heading'
import * as PollCard from 'sierra-client/views/v3-author/poll-card'
import * as Preamble from 'sierra-client/views/v3-author/preamble/preamble'
import { withPreamble } from 'sierra-client/views/v3-author/preamble/with-preamble'
import {
  ProjectCardContainer,
  ProjectCardSection,
  ProjectCardSectionContainer,
} from 'sierra-client/views/v3-author/project-card/create'
import {
  withProjectCard,
  withProjectCardSection,
} from 'sierra-client/views/v3-author/project-card/with-project-card'
import * as QuestionCard from 'sierra-client/views/v3-author/question-card'
import { withQuestionCards } from 'sierra-client/views/v3-author/question-card/with-question-card'
import { withQuestionVariations } from 'sierra-client/views/v3-author/question-card/with-variations'
import * as ReflectionCard from 'sierra-client/views/v3-author/reflection-card'
import * as ScenarioCard from 'sierra-client/views/v3-author/scenario'
import { withScenarioCard } from 'sierra-client/views/v3-author/scenario/plugin'
import { HorizontalStack, VerticalStack } from 'sierra-client/views/v3-author/sections/stacks'
import * as Separator from 'sierra-client/views/v3-author/separator/separator'
import { withSeparator } from 'sierra-client/views/v3-author/separator/with-separator'
import * as SlidingScaleCard from 'sierra-client/views/v3-author/sliding-scale-card'
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRoot,
  TableRow,
} from 'sierra-client/views/v3-author/table'
import { Tag } from 'sierra-client/views/v3-author/tags/tags'
import * as Takeaways from 'sierra-client/views/v3-author/takeaways/takeaways'
import { withTakeaways } from 'sierra-client/views/v3-author/takeaways/with-takeaways'
import * as TitleCard from 'sierra-client/views/v3-author/title-card'
import * as Video from 'sierra-client/views/v3-author/video/video'
import { withVideo } from 'sierra-client/views/v3-author/video/with-video'
import { BlockWrapper, CardWrapper } from 'sierra-client/views/v3-author/wrapper'
import { CustomElementRegistry } from 'sierra-domain/v3-author'

// ADD-BLOCK-PATH: 5
export const blockDefinitions: { [key in keyof CustomElementRegistry]: BlockDefinition } = {
  'paragraph': {
    Component: Components.EmptyComponent,
    Wrapper: Paragraph.ParagraphWrapper,
    plugin: Paragraph.withParagraph,
    hasGenericCommentIndicator: true,
    actionsVerticalAlignment: 'middle',
  },
  'heading': {
    Wrapper: Heading.HeadingWrapper,
    plugin: withHeading,
    hasGenericCommentIndicator: true,
    actionsVerticalAlignment: 'middle',
  },
  'image': {
    Component: Images.Image,
    plugin: withImage,
    enableBlockComments: true,
    actionsVerticalAlignment: 'middle',
    supportsCollaborativeCursors: true,
  },
  'block-quote': {
    Component: BlockQuote.BlockQuote,
    Wrapper: BlockWrapper,
    plugin: withBlockQuote,
    enableBlockComments: true,
    actionsVerticalAlignment: 'middle',
  },
  'simple-quote': SimpleQuote,
  'block-quote-subtitle': {
    Component: BlockQuote.BlockQuoteSubtitle,
    Wrapper: BlockQuote.SubtitleContainer,
  },
  'bulleted-list': {
    Wrapper: List.UnorderedList,
    Component: Components.EmptyComponent,
    plugin: withLists,
    hasGenericCommentIndicator: true,
    actionsVerticalAlignment: 'middle',
    enableBlockComments: false,
  },
  'numbered-list': {
    Wrapper: List.OrderedList,
    Component: Components.EmptyComponent,
    hasGenericCommentIndicator: true,
    actionsVerticalAlignment: 'middle',
    enableBlockComments: false,
  },
  'list-item': {
    Component: List.ListItem,
    Wrapper: List.ListItemContainer,
    preventDrag: true,
    hasGenericCommentIndicator: true,
    actionsVerticalAlignment: 'middle',
  },
  'check-list': {
    Component: CheckList.CheckList,
    Wrapper: CheckList.CheckListWrapper,
    plugin: withCheckLists,
    hasGenericCommentIndicator: true,
    actionsVerticalAlignment: 'middle',
  },
  'check-list-item': {
    Component: CheckList.CheckListItem,
    Wrapper: CheckList.CheckListItemContainer,
    preventDrag: true,
    hasGenericCommentIndicator: true,
    actionsVerticalAlignment: 'middle',
  },
  'video': {
    Component: Video.Video,
    plugin: withVideo,
    hasGenericCommentIndicator: true,
    enableBlockComments: true,
    actionsVerticalAlignment: 'middle',
  },
  'preamble': {
    Component: Preamble.Preamble,
    plugin: withPreamble,
    hasGenericCommentIndicator: true,
    enableBlockComments: true,
    actionsVerticalAlignment: 'middle',
    supportsCollaborativeCursors: true,
  },
  'separator': {
    Component: Separator.SeparatorComponent,
    plugin: withSeparator,
    actionsVerticalAlignment: 'middle',
    supportsCollaborativeCursors: true,
  },
  'code': {
    Component: Code.Code,
    Wrapper: Code.CodeWrapper,
    plugin: withCode,
    enableBlockComments: true,
    actionsVerticalAlignment: 'middle',
    supportsCollaborativeCursors: true,
  },
  'file-attachment': {
    Component: FileAttachment.FileAttachment,
    plugin: withFileAttachment,
    hasGenericCommentIndicator: true,
    enableBlockComments: true,
    actionsVerticalAlignment: 'middle',
    supportsCollaborativeCursors: true,
  },
  'embed': {
    Component: Embed.Embed,
    plugin: withEmbed,
    enableBlockComments: true,
    actionsVerticalAlignment: 'middle',
    supportsCollaborativeCursors: true,
  },
  'markdown': {
    Component: Markdown.Markdown,
    Wrapper: Markdown.MarkdownWrapper,
    plugin: withMarkdown,
    enableBlockComments: true,
    actionsVerticalAlignment: 'middle',
    supportsCollaborativeCursors: true,
  },
  'link': {
    Component: Link.Link,
    plugin: withLinks,
    supportsCollaborativeCursors: true,
  },
  'placeholder': {
    Wrapper: Placeholder.PlaceholderWrapper,
    Component: Placeholder.Placeholder,
    plugin: withPlaceholder,
  },
  'takeaways': {
    Component: Takeaways.Takeaways,
    plugin: withTakeaways,
    actionsVerticalAlignment: 'middle',
  },
  'takeaway-item': {
    Component: Takeaways.TakeawaysItem,
    Wrapper: Takeaways.Wrapper,
    enableBlockComments: true,
    preventDrag: true,
    actionsVerticalAlignment: 'middle',
    supportsCollaborativeCursors: true,
  },
  'homework-card': {
    Component: Components.EmptyComponent,
    Wrapper: Homework.HomeworkWrapper,
    preventDrag: true,
    plugin: withHomeworkContainer,
  },
  'scenario-card': {
    Component: Components.EmptyComponent,
    Wrapper: ScenarioCard.ScenarioCardsContainer,
    preventDrag: true,
    plugin: withScenarioCard,
  },
  'flip-cards': {
    Wrapper: FlipCards.FlipCardsContainer,
    actionsVerticalAlignment: 'middle',
    preventDrag: QuestionCard.isNotInline,
    plugin: withFlipCards,
  },
  'flip-cards-card-container': {
    Wrapper: FlipCards.FlipCardsCardContainer,
    Component: FlipCards.FlipCardsCardRow,
    preventDrag: true,
    enableBlockComments: false,
    plugin: withFlipCardsCardContainer,
  },
  'flip-card': {
    Wrapper: FlipCards.FlipCard,
    Component: Components.EmptyComponent,
    preventDrag: true,
    enableBlockComments: false,
    plugin: withFlipCard,
  },
  'flip-card-front': {
    Wrapper: FlipCards.FlipCardSideComponent,
    Component: Components.EmptyComponent,
    preventDrag: true,
    enableBlockComments: false,
    plugin: withFlipCardSides,
  },
  'flip-card-back': {
    Wrapper: FlipCards.FlipCardSideComponent,
    Component: Components.EmptyComponent,
    preventDrag: true,
    enableBlockComments: false,
    plugin: withFlipCardSides,
  },
  'title-card': {
    Component: TitleCard.TitleCardComponent,
    Wrapper: CardWrapper,
    plugin: TitleCard.withTitleCard,
    preventDrag: true,
  },
  'title-card-heading': {
    Component: TitleCard.TitleCardHeading,
    preventDrag: true,
    hasGenericCommentIndicator: true,
    actionsVerticalAlignment: 'middle',
  },
  'reflection-card': {
    Component: ReflectionCard.ReflectionCard,
    Wrapper: ReflectionCard.ReflectionCardContainer,
    plugin: ReflectionCard.withReflectionCard,
    preventDrag: true,
  },
  'sliding-scale-card': {
    Component: SlidingScaleCard.SlidingScaleCard,
    Wrapper: SlidingScaleCard.SlidingScaleCardContainer,
    plugin: SlidingScaleCard.withSlidingScaleCard,
    preventDrag: true,
  },
  'sliding-scale-card-option': {
    Component: SlidingScaleCard.SlidingScaleOption,
    plugin: SlidingScaleCard.withSlidingScaleCardOption,
    preventDrag: true,
  },
  'matrix': {
    Component: Matrix.Matrix,
    Wrapper: Matrix.MatrixContainer,
    plugin: Matrix.withMatrix,
  },
  'matrix-option': {
    Component: Matrix.MatrixOption,
    plugin: Matrix.withMatrixOption,
    preventDrag: true,
  },
  'poll-card': {
    Wrapper: PollCard.PollCardContainer,
    preventDrag: true,
    plugin: PollCard.withPollCard,
  },
  'poll-card-alternative': {
    Component: Components.EmptyComponent,
    Wrapper: PollCard.PollCardAlternative,
    plugin: PollCard.withPollCardAlternative,
  },
  'poll-card-alternative-container': {
    Component: PollCard.PollCardAlternativeRow,
    Wrapper: PollCard.PollCardAlternativeRowContainer,
    plugin: PollCard.withPollCardAlternativeContainer,
  },
  'question-card': {
    Component: QuestionCard.QuestionCard,
    Wrapper: QuestionCard.QuestionCardContainer,
    preventDrag: QuestionCard.isNotInline,
    actionsVerticalAlignment: 'middle',
    plugin: withQuestionCards,
    supportsCollaborativeCursors: QuestionCard.isInline,
  },
  'question-card-free-text-body': {
    Component: QuestionCard.QuestionCardFreeTextBody,
    preventDrag: true,
  },
  'question-card-pick-the-best-option-body': {
    Component: QuestionCard.QuestionCardMultipleChoiceBody,
    Wrapper: QuestionCard.QuestionCardBodyContainer,
    preventDrag: true,
  },
  'question-card-select-all-that-apply-body': {
    Component: QuestionCard.QuestionCardMultipleChoiceBody,
    Wrapper: QuestionCard.QuestionCardBodyContainer,
    preventDrag: true,
  },
  'question-card-match-the-pairs-body': {
    Component: QuestionCard.QuestionCardMatchThePairsBody,
    Wrapper: QuestionCard.QuestionCardMatchThePairsContainer,
    preventDrag: true,
  },
  'question-card-multiple-choice-alternative': {
    Component: QuestionCard.QuestionCardMultipleChoiceAlternative,
    Wrapper: QuestionCard.QuestionCardMultipleChoiceAlternativeWrapper,
    supportsCollaborativeCursors: true,
    preventDrag: true,
  },
  'question-card-multiple-choice-alternative-option': {
    Component: QuestionCard.QuestionCardMultipleChoiceAlternativeOption,
    Wrapper: QuestionCard.QuestionCardMultipleChoiceAlternativeOptionWrapper,
    preventDrag: true,
  },
  'question-card-multiple-choice-alternative-explanation': {
    Component: QuestionCard.QuestionCardMultipleChoiceAlternativeExplanation,
    Wrapper: QuestionCard.QuestionCardMultipleChoiceAlternativeExplanationWrapper,
    preventDrag: true,
  },
  'question-card-match-the-pairs-alternative': {
    Component: QuestionCard.QuestionCardMatchThePairsAlternative,
    Wrapper: QuestionCard.QuestionCardMatchThePairsAlternativeWrapper,
    preventDrag: true,
  },
  'question-card-match-the-pairs-alternative-option': {
    Component: QuestionCard.QuestionCardMatchThePairsAlternativeOption,
    Wrapper: QuestionCard.QuestionCardMatchThePairsAlternativeOptionWrapper,
    preventDrag: true,
  },
  'bullet-card': {
    Component: BulletCard.BulletCard,
    Wrapper: CardWrapper,
    preventDrag: true,
  },
  'bullet-card-item': {
    Component: BulletCard.BulletCardItem,
    Wrapper: BulletCard.BulletCardItemContainer,
    plugin: withBulletCard,
    hasGenericCommentIndicator: true,
  },
  'sticky-notes-card': {
    Component: Components.EmptyComponent,
    Wrapper: StickyNotesCardContainer,
    plugin: withStickyNotesCard,
    preventDrag: true,
  },
  'sticky-notes-card-section': {
    Component: StickyNotesCardSection,
    Wrapper: StickyNotesCardSectionContainer,
    plugin: withStickyNotesCardSection,
    preventDrag: true,
  },
  'project-card': {
    Component: Components.EmptyComponent,
    Wrapper: ProjectCardContainer,
    plugin: withProjectCard,
    preventDrag: true,
  },
  'project-card-section': {
    Component: ProjectCardSection,
    Wrapper: ProjectCardSectionContainer,
    plugin: withProjectCardSection,
    preventDrag: true,
  },
  'assessment-card': {
    Wrapper: AssessmentCards.AssessmentCardWrapper,
    Component: Components.EmptyComponent,
    preventDrag: QuestionCard.isNotInline,
    plugin: withAssessments,
  },
  'assessment-introduction': {
    Wrapper: AssessmentCards.AssessmentIntroductionWrapper,
    Component: AssessmentCards.AssessmentIntroduction,
    preventDrag: QuestionCard.isNotInline,
  },
  'assessment-question': {
    Wrapper: AssessmentCards.AssessmentQuestionWrapper,
    Component: Components.EmptyComponent,
    preventDrag: QuestionCard.isNotInline,
  },
  'question-variations': {
    Wrapper: QuestionCard.QuestionVariationsWrapper,
    Component: Components.EmptyComponent,
    preventDrag: true,
    plugin: withQuestionVariations,
  },
  'table': TableRoot,
  'table-head': TableHead,
  'table-body': TableBody,
  'table-row': TableRow,
  'table-cell': TableCell,
  'table-header-cell': TableHeaderCell,
  'vertical-stack': VerticalStack,
  'horizontal-stack': HorizontalStack,
  'tag': Tag,
}

export function assertBlockDefintionExists<Type extends keyof CustomElementRegistry>(type: Type): void {
  const definition = blockDefinitions[type]

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (definition === undefined) throw new Error(`Expected to find a block definition with type ${type}.`)
}

import _ from 'lodash'
import { DateTime, Duration } from 'luxon'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { HorizontalLineDivider } from 'sierra-client/components/common/horizontal-line-divider'
import { TimerButton, TimerText, secondsToDuration } from 'sierra-client/components/liveV2/timer-button'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getServerTimeNow, selectClock } from 'sierra-client/state/collaboration/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { AddQuestion } from 'sierra-client/views/v3-author/assessment-card/add-question'
import {
  AssessmentContainer,
  AssessmentHeadline,
  AssessmentIconBar,
  assessmentContainerRadius,
} from 'sierra-client/views/v3-author/assessment-card/assessment-container'
import {
  Assessments,
  useAssessmentContext,
} from 'sierra-client/views/v3-author/assessment-card/assessment-context'
import { removeNodeWithId } from 'sierra-client/views/v3-author/command'
import { useParent, usePath } from 'sierra-client/views/v3-author/hooks'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { QuestionCardIconBar } from 'sierra-client/views/v3-author/question-card/question-card-icon-bar'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { createAssessmentQuestion } from 'sierra-domain/v3-author/create-blocks'
import { Icon } from 'sierra-ui/components'
import { IconButton } from 'sierra-ui/primitives'
import { ConditionalWrapper } from 'sierra-ui/utils'
import { Editor, Element, Path, Transforms } from 'slate'
import { useSlateStatic } from 'slate-react'
import styled, { css } from 'styled-components'

const FadeInWrapper = styled.div<{ show: boolean }>`
  transition: opacity 200ms;
  opacity: 0;
  ${p =>
    p.show &&
    css`
      opacity: 1;
    `}
`

export const AssessmentTimer = (): JSX.Element | null => {
  const { state, setState } = useAssessmentContext()
  const endTime = state.status === 'during' ? state.endTime : undefined
  const clock = useSelector(selectClock)
  const [countDownTimeRemaining, setCountDownTimeRemaining] = useState<Duration | undefined>()

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>
    if (endTime === undefined) {
      setCountDownTimeRemaining(undefined)
    } else {
      interval = setInterval(async () => {
        const now = DateTime.fromISO(getServerTimeNow(clock))
        const diff = endTime.diff(now)
        if (diff.as('seconds') < 0) {
          setCountDownTimeRemaining(Duration.fromMillis(0))
          clearInterval(interval)
          const nextState = await Assessments.timesUp(state)
          setState(nextState)
        } else {
          setCountDownTimeRemaining(diff)
        }
      }, 200)
    }

    return () => {
      clearInterval(interval)
    }
  }, [clock, endTime, setState, state])

  const milliseconds = countDownTimeRemaining?.milliseconds
  const timeString = secondsToDuration((milliseconds ?? 0) / 1000)
  const isAlmostDone = milliseconds !== undefined ? milliseconds < 30 * 1000 : false

  return (
    <FadeInWrapper show={milliseconds !== undefined}>
      <TimerButton
        id='timer'
        $clickable={false}
        $timerState={isAlmostDone ? 'done' : 'normal'}
        initial='initial'
        animate={'initial'}
      >
        <ConditionalWrapper
          renderWrapper={children => <AppThemeTokenProvider>{children}</AppThemeTokenProvider>}
          condition={isAlmostDone}
        >
          <>
            <Icon iconId='timer' color='foreground/primary' />
            <TimerText size='small'>
              <div>{timeString}</div>
            </TimerText>
          </>
        </ConditionalWrapper>
      </TimerButton>
    </FadeInWrapper>
  )
}

const AssessmentQuestionCreateWrapper = React.forwardRef<HTMLDivElement, SlateWrapperProps>((props, ref) => {
  const { children, element, attributes, readOnly } = props
  assertElementType('assessment-question', element)

  const assessmentCard = useParent({ nodeId: element.id })
  const questionCount = Element.isElement(assessmentCard) && assessmentCard.children.length - 1 // introduction
  const { t } = useTranslation()

  const currentPath = usePath({ nodeId: element.id })
  const questionIndex = _.last(currentPath)

  const isFirstQuestion = questionIndex === 1 // 0 is introduction
  const isLastQuestion = questionIndex === questionCount

  const editor = useSlateStatic()
  const addQuestion = useCallback(() => {
    if (readOnly) return

    Transforms.insertNodes(editor, createAssessmentQuestion(), { at: Path.next(currentPath) })
    Transforms.select(editor, Editor.start(editor, Path.next(currentPath)))
  }, [editor, currentPath, readOnly])

  return (
    <>
      <AssessmentContainer {...attributes} ref={ref} radius={assessmentContainerRadius}>
        <QuestionCardIconBar justifyContent='space-between'>
          <AssessmentHeadline
            iconId='help'
            label={t('assessment-card.question-number', { number: questionIndex ?? 0 })}
          />
          {!readOnly && (
            <AssessmentIconBar>
              {questionCount !== false && questionCount > 1 && (
                <>
                  <IconButton
                    size='small'
                    variant='transparent'
                    iconId='arrow--up'
                    disabled={isFirstQuestion}
                    onClick={() =>
                      Transforms.moveNodes(editor, { at: currentPath, to: Path.previous(currentPath) })
                    }
                  />
                  <IconButton
                    size='small'
                    variant='transparent'
                    iconId='arrow--down'
                    disabled={isLastQuestion}
                    onClick={() =>
                      Transforms.moveNodes(editor, { at: currentPath, to: Path.next(currentPath) })
                    }
                  />
                  {(!isFirstQuestion || !isLastQuestion) && <HorizontalLineDivider />}
                </>
              )}
              <IconButton
                size='small'
                variant='transparent'
                iconId='trash-can'
                disabled={questionCount !== false && questionCount < 2}
                onClick={() => removeNodeWithId(editor, element.id)}
              />
            </AssessmentIconBar>
          )}
        </QuestionCardIconBar>
        {children}
      </AssessmentContainer>
      <AddQuestion onClick={addQuestion} hide={readOnly} isGrey={isLastQuestion} />
    </>
  )
})

const AssessmentQuestionLearnerWrapper = React.forwardRef<HTMLDivElement, SlateWrapperProps>((props, ref) => {
  const { children, element, attributes } = props
  assertElementType('assessment-question', element)

  const { state } = useAssessmentContext()
  const currentPath = usePath({ nodeId: element.id })
  const questionIndex = _.last(currentPath)

  const shouldShowQuestion = useMemo((): boolean => {
    if (state.status === 'during') {
      const { currentIndex } = state
      return currentIndex + 1 === questionIndex
    }

    return false
  }, [state, questionIndex])

  return (
    <AssessmentContainer {...attributes} ref={ref} radius='none' $hide={!shouldShowQuestion}>
      {shouldShowQuestion && state.status === 'during' ? (
        <>
          {/* <QuestionCardIconBar justifyContent='center'>
            <ProgressBar
              assessmentState={state}
              totalQuestions={questionCount}
              next={async () => {
                const newState = await Assessments.next(state)
                setState(newState)
              }}
              previous={async () => {
                const newState = await Assessments.previous(state)
                setState(newState)
              }}
            />

            {state.endTime !== undefined && (
              <View marginTop={'8'}>
                <AssessmentTimer />
              </View>
            )}
          </QuestionCardIconBar> */}
          {children}
        </>
      ) : null}
    </AssessmentContainer>
  )
})

export const AssessmentQuestionWrapper = React.forwardRef<HTMLDivElement, SlateWrapperProps>((props, ref) => {
  const { children, element, mode } = props
  assertElementType('assessment-question', element)

  const Renderer =
    mode === 'create' || mode === 'template' || mode === 'version-history'
      ? AssessmentQuestionCreateWrapper
      : AssessmentQuestionLearnerWrapper

  return (
    <RenderingContext preventDrag={true} allowBlockComments={true} disableMenu={true}>
      <Renderer {...props} ref={ref}>
        {children}
      </Renderer>
    </RenderingContext>
  )
})

import { useMutation } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'framer-motion'
import _ from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Emoji, useEmojiPicker } from 'sierra-client/components/common/emoji'
import { useSelectCurrentCardBackgroundColor } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { useLiveSessionIdContext } from 'sierra-client/components/liveV2/live-session-id-provider'
import { OnOutsideClickCallbackFn } from 'sierra-client/editor/emoji-types'
import {
  NowButtonCSS,
  NowButtonCSSProps,
  ResponsiveNowButton,
  TransparentNowButton,
} from 'sierra-client/features/sana-now/header/buttons'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { typedPost } from 'sierra-client/state/api'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { sendEmojiMessage } from 'sierra-client/state/chat/actions'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { CreateChatMessageRequest } from 'sierra-domain/api/chat'
import { ChatMessageId, uuid } from 'sierra-domain/api/uuid'
import { ScopedChatId, ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { XRealtimeChatCreateMessage } from 'sierra-domain/routes'
import { Icon } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const EMOJI_CONTAINER_HEIGHT = '52px'

const EmojiInputTransformContainer = styled.div`
  border-radius: 36px;
  box-shadow:
    0 16px 24px 0 rgba(0, 0, 0, 0.08),
    0 0 0 1px rgba(0, 0, 0, 0.04);
`

const HoverContainer = styled(motion.div)`
  padding-bottom: 0.75rem;
  position: absolute;
  transform: translateX(-0.375rem);
  bottom: 36px;
`

const EmojiInputInnerContainer = styled(motion.div)<NowButtonCSSProps & { $transparent?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  height: ${EMOJI_CONTAINER_HEIGHT};
  padding: 0 20px;
  cursor: pointer;

  ${p => p.$transparent === true && NowButtonCSS}
  border-radius: 36px;
`

const ReactWithEmojiButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
`

const VerticalDivider = styled.hr`
  transform: rotate(90deg);
  background-color: rgba(0, 0, 0, 0.08);
  height: 1px;
  width: 20px;
`

const StyledEmoji = styled(Emoji)`
  color: ${token('foreground/primary')};
`

const reactions = ['❤️', '👏', '👍', '😂', '😢']

const EmojiItems: React.FC<{ send: (reaction: string) => void }> = ({ send }) => {
  return (
    <View gap='none'>
      <View gap='8'>
        {reactions.map(reaction => (
          <ReactWithEmojiButton
            color='foreground/primary'
            key={reaction}
            onClick={() => {
              send(reaction)
            }}
          >
            <motion.div
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 1 }}
              transition={{
                type: 'spring',
                stiffness: 500,
              }}
            >
              <StyledEmoji emojiUnicode={reaction} size={20} />
            </motion.div>
          </ReactWithEmojiButton>
        ))}
      </View>
      <VerticalDivider />
    </View>
  )
}

export const EmojiReactions: React.FC<{
  transparent?: boolean
  onClickOutsideEmojiPicker?: OnOutsideClickCallbackFn
}> = ({ transparent, onClickOutsideEmojiPicker }) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null)
  const { liveSessionId } = useLiveSessionIdContext()

  const backgroundColor = useSelectCurrentCardBackgroundColor()

  const { open: openEmojiPicker, isOpen: isEmojiPickerOpen, close: closeEmojiPicker } = useEmojiPicker()

  const chatId = ScopedChatId.fromId(liveSessionId)

  const dispatch = useDispatch()
  const userId = useSelector(selectUserId)

  const newChatSendMessageMutation = useMutation({
    mutationFn: async (params: CreateChatMessageRequest) => {
      await typedPost(XRealtimeChatCreateMessage, params)
    },
  })

  const send = useMemo(() => {
    return _.throttle(async (emoji: string) => {
      if (userId === undefined) return false
      const messageId = ChatMessageId.parse(uuid())
      void dispatch(sendEmojiMessage({ id: messageId, chatId, threadId: 'root', userId, emoji: emoji }))

      const messageData = { type: 'emoji', emoji }

      newChatSendMessageMutation.mutate({
        chatIdentifier: {
          type: 'live-session',
          liveSessionId: ScopedLiveSessionId.extractId(liveSessionId),
        },
        messageId,
        messageData,
      })
    }, 250)
  }, [chatId, dispatch, liveSessionId, newChatSendMessageMutation, userId])

  const toggleEmojiPicker = (): void => {
    if (isEmojiPickerOpen) {
      closeEmojiPicker()
    } else if (container) {
      openEmojiPicker(container, send, onClickOutsideEmojiPicker)
    }
  }

  useEffect(() => {
    return () => closeEmojiPicker()
  }, [closeEmojiPicker])

  return (
    <View ref={setContainer} position='relative'>
      <EmojiInputInnerContainer
        $backgroundColor={backgroundColor}
        $transparent={transparent}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.15 } }}
        transition={{ duration: 0.1 }}
      >
        <EmojiItems send={send} />
        <ReactWithEmojiButton onClick={toggleEmojiPicker}>
          <Icon size='size-20' iconId='face--add' color={transparent === true ? 'currentColor' : 'grey30'} />
        </ReactWithEmojiButton>
      </EmojiInputInnerContainer>
    </View>
  )
}

export const EmojiReactionButton: React.FC<{
  responsive?: boolean
}> = ({ responsive }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { isOpen: isEmojiPickerOpen } = useEmojiPicker()
  const isMobile = useIsMobile()
  const backgroundColor = useSelectCurrentCardBackgroundColor()

  const clearDelayedClose = (): void => {
    if (timeoutRef.current !== null) {
      clearInterval(timeoutRef.current)
      timeoutRef.current = null
    }
  }
  const delayClose = (): void => {
    if (!isEmojiPickerOpen) {
      timeoutRef.current = setTimeout(() => {
        setOpen(false)
      }, 1000)
    }
  }

  return (
    <View position='relative' justifyContent='center' gap='none'>
      <div
        onMouseEnter={
          isMobile
            ? undefined
            : () => {
                setOpen(true)
                clearDelayedClose()
              }
        }
        onMouseLeave={isMobile ? undefined : () => setOpen(false)}
      >
        {responsive === true ? (
          <ResponsiveNowButton
            onClick={() => setOpen(open => !open)}
            iconId='skill--party--popper'
            title={t('sana-now.header.react-button-text')}
          />
        ) : (
          <TransparentNowButton
            $backgroundColor={backgroundColor}
            onClick={() => setOpen(open => !open)}
            icon='skill--party--popper'
          >
            {t('sana-now.header.react-button-text')}
          </TransparentNowButton>
        )}
      </div>

      <AnimatePresence>
        {open && (
          <HoverContainer
            onMouseEnter={
              isMobile
                ? undefined
                : () => {
                    clearDelayedClose()
                    setOpen(true)
                  }
            }
            onMouseLeave={isMobile ? undefined : delayClose}
          >
            <EmojiInputTransformContainer>
              <EmojiReactions onClickOutsideEmojiPicker={() => setOpen(false)} />
            </EmojiInputTransformContainer>
          </HoverContainer>
        )}
      </AnimatePresence>
    </View>
  )
}

import { motion } from 'framer-motion'
import { useAtomValue } from 'jotai'
import { collapsableSidebarStateAtom } from 'sierra-client/features/collapsable-sidebar/atoms'
import {
  ItemTitleCSS,
  ItemWrapperCSS,
} from 'sierra-client/features/collapsable-sidebar/items/shared/header-item-wrapper'
import { ItemLink } from 'sierra-client/features/collapsable-sidebar/items/shared/item-link'
import { usePathname } from 'sierra-client/hooks/router/use-pathname'
import { useWasRenderedOnce } from 'sierra-client/hooks/use-was-rendered-once'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { FCC } from 'sierra-client/types'
import { isDefined } from 'sierra-domain/utils'
import { Icon, IconId, TruncatedTextWithTooltip } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { CSSPropTwo, Spacing } from 'sierra-ui/types'
import styled from 'styled-components'

/*
 * <NestedSection> ->  Grow or shrink based on parent
 *   <NestedItemList>
 *     <NestedListItem />
 *   </NestedItemList>
 * </NestedSection>
 * */

const AnimateHeight = styled(motion.div)`
  overflow: hidden;
  height: auto;
`

const NestedItemsContainer = styled(motion.ul)`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 0;

  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: 22px;

  overflow: hidden;

  & > li {
    padding: 0;
  }
`

export const WaitOneRender: FCC = ({ children }) => {
  const wasRenderedOnce = useWasRenderedOnce()
  return wasRenderedOnce ? <>{children}</> : null
}

export const NestedItemList: FCC<{ open: boolean; id?: string }> = ({ open, id, children }) => {
  const isMobile = useIsMobile()
  const sidebarOpen = useAtomValue(collapsableSidebarStateAtom)

  const transformX = isMobile || sidebarOpen !== 'collapsed' ? 22 : 0
  return (
    <WaitOneRender>
      <AnimateHeight
        id={id}
        aria-hidden={!open}
        transition={{ duration: 0.18, ease: 'easeInOut' }}
        initial={open ? { height: 'auto' } : { height: 0 }}
        animate={open ? { height: 'auto' } : { height: 0 }}
      >
        <NestedItemsContainer
          initial={
            open
              ? { opacity: 1, y: 0, marginLeft: transformX }
              : { opacity: 0, y: -10, marginLeft: transformX }
          }
          animate={
            open
              ? { opacity: 1, y: 0, marginLeft: transformX }
              : { opacity: 0, y: -10, marginLeft: transformX }
          }
          transition={{ duration: 0.25, ease: 'easeInOut' }}
        >
          {children}
        </NestedItemsContainer>
      </AnimateHeight>
    </WaitOneRender>
  )
}

const NestedListItemWrapper = styled(motion.li)<{ $isSelected: boolean }>`
  ${ItemWrapperCSS}
`

const NestedListItemTitle = styled(View).attrs({
  direction: 'row',
  gap: 'none',
  grow: true,
  justifyContent: 'space-between',
})`
  ${ItemTitleCSS}
`

export const NestedListItem: React.FC<{
  title: string
  href: string
  postComponent?: React.ReactNode
  preComponent?: { type: 'pre-component'; preComponent: React.ReactNode } | { type: 'icon'; iconId: IconId }
  isSelected?: boolean
  hidden?: boolean
  tourId?: string
  nestedItemPadding?: CSSPropTwo<Spacing>
}> = ({ title, href, postComponent, preComponent, isSelected, tourId, hidden, nestedItemPadding }) => {
  const pathname = usePathname()
  const isItemSelected = isSelected ?? pathname === href

  if (hidden === true) {
    return null
  }

  return (
    <NestedListItemWrapper id={tourId} $isSelected={isItemSelected}>
      <ItemLink gap='10' padding={nestedItemPadding ?? '8 10'} href={href}>
        {isDefined(preComponent) &&
          (preComponent.type === 'icon' ? (
            <Icon
              iconId={preComponent.iconId}
              color={isItemSelected ? 'foreground/primary' : 'foreground/muted'}
            />
          ) : (
            preComponent.preComponent
          ))}
        <NestedListItemTitle>
          <TruncatedTextWithTooltip
            bold={isSelected}
            color={isItemSelected ? 'foreground/primary' : 'foreground/secondary'}
          >
            {title}
          </TruncatedTextWithTooltip>
          {postComponent}
        </NestedListItemTitle>
      </ItemLink>
    </NestedListItemWrapper>
  )
}

import { motion } from 'framer-motion'
import _ from 'lodash'
import {
  KeyboardEventHandler,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { selectIsCollaborator } from 'sierra-client/state/author-course-settings/selectors'
import { isInLiveSession, isInSelfPaced } from 'sierra-client/state/content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsFacilitator } from 'sierra-client/state/live-session/selectors'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { useUserLegacy, useUsersLegacy } from 'sierra-client/state/users/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { useHasManageAccess } from 'sierra-client/views/manage/permissions/use-has-manage-access'
import { AutoexpandingReflectionInput } from 'sierra-client/views/v3-author/reflection-card/atoms'
import { ReactionInput } from 'sierra-client/views/v3-author/reflection-card/reaction-input'
import {
  ReflectionResponse,
  ReflectionResponseReaction,
} from 'sierra-client/views/v3-author/reflection-card/reflection-card-contexts'
import { ReflectionCardReaction } from 'sierra-client/views/v3-author/reflection-card/reflection-card-reaction'
import { UserId } from 'sierra-domain/api/uuid'
import { LightUser } from 'sierra-domain/user'
import { getUserName, isDefined } from 'sierra-domain/utils'
import { AvatarStack, RoundAvatar } from 'sierra-ui/components'
import { Button, IconButton, ScrollView, Spacer, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { LightTokenProvider, palette, spacing, token } from 'sierra-ui/theming'
import { legacyLight } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeProvider } from 'styled-components'

const Container = styled(motion.div)`
  font-size: 1rem;
  padding: 1.25rem;
  background-color: ${palette.primitives.white};
  color: ${palette.primitives.black};
  border-radius: 12px;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  display: inline-block;
  width: 100%;
  border: 1px solid ${palette.grey[5]};
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.04);
`

export const User = styled.div<{ $isMe: boolean }>`
  display: flex;
  align-items: center;
  color: ${p => (p.$isMe ? palette.primitives.black : p.theme.color.grey25)};
  font-size: 0.875rem;
  overflow: hidden;
  min-width: 80px;

  &:hover {
    cursor: ${p => (p.onClick !== undefined ? 'pointer' : 'default')};
  }
`

const UserWritingContainer = styled.div`
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: ${palette.primitives.white};
  color: ${p => p.theme.color.grey25};
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 12px;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  border: 1px solid ${palette.grey[5]};
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.04);
`

const ReflectionCardFooter = styled.div`
  min-height: 28px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.5rem;
`

export const ReflectionCardHeader = styled(View)`
  margin-bottom: 0.75rem;
`

export const UserName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
`

const StyledEditButton = styled(IconButton).attrs({
  variant: 'transparent',
  iconId: 'edit',
  color: 'grey25',
})``

const StyledDeleteButton = styled(IconButton).attrs({
  variant: 'transparent',
  iconId: 'trash-can',
  color: 'grey25',
})``

const StyledReplyButton = styled(IconButton).attrs({
  variant: 'transparent',
  iconId: 'reply',
  color: 'grey25',
})``

const RightButtonsContainer = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`

const StyledScrollView = styled(ScrollView)`
  min-height: 1.5rem;
  max-height: 400px;
  overscroll-behavior: auto;
`

type UserReflectionProps = {
  reflectionId: string
  reflectionResponse: ReflectionResponse
  editResponse?: (props: { responseId: string; newResponse: string }) => void
  reactToResponse?: (responseId: string, reaction: string, answerId?: string) => void
  unReactToResponse?: (responseId: string, reaction: string, answerId?: string) => void
  deleteReflection?: (responseId: string) => void
  submitReflectionAnswer?: (answer: string, responseId: string, anonymous: boolean) => void
  editReflectionAnswer?: (newAnswer: string, responseId: string, answerId: string) => void
  deleteReflectionAnswer?: (responseId: string, answerId: string) => void
  allowAnonymity?: boolean
}

type UserWritingProps = {
  userId?: UserId
  anonymous?: boolean
}

export const useAnonymousUserData = (): LightUser => {
  const { t } = useTranslation()

  // We need this value to use as a React key, but we shouldn't use it anywhere else
  // or pass it to the backend because it doesn't refer to any actual user.
  // Note: We'll intentionally generate an invalid UUID so that it won't parse if we
  //  accidentally send it to the backend. We should fix this in the types though.
  const id = useMemo(() => _.uniqueId('anonymous_') as UserId, [])

  return {
    uuid: id,
    firstName: t('dictionary.anonymous'),
    avatarColor: 'redVivid',
    lastName: '',
    email: '',
    avatar: undefined,
  } as const
}

export const UserWriting = forwardRef<HTMLDivElement, UserWritingProps>(({ userId, anonymous }, ref) => {
  const realUser = useUserLegacy(userId)
  const { t } = useTranslation()

  const anonymousUser = useAnonymousUserData()
  const user = anonymous === true ? anonymousUser : realUser

  return (
    <UserWritingContainer ref={ref}>
      <View gap='8'>
        <RoundAvatar
          size='small'
          firstName={user?.firstName}
          lastName={user?.lastName}
          src={getAvatarImage(user?.uuid, user?.avatar)}
          color={user?.avatarColor}
        />
        {anonymous === true ? t('dictionary.anonymous') : user?.firstName}
        {' ' + t('learner.reflection.is-typing')}
      </View>
    </UserWritingContainer>
  )
})

type Reaction = ReflectionResponseReaction & { names: string[] }

const ReplyView = styled(View).attrs({ radius: 'size-8', padding: '16', direction: 'column' })`
  background-color: ${palette.grey[2]};
`

const MinHeightView = styled(View)`
  min-height: 28px;
`

const StyledReactionInput = styled(ReactionInput)`
  margin-left: 4px;
  margin-bottom: -1px;
`

const useAutosizeTextArea = (textAreaRef: HTMLTextAreaElement | null, value: string): void => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = '0px'
      const scrollHeight = textAreaRef.scrollHeight + 2 //This is to match the original size of the textarea

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = scrollHeight.toString() + 'px'
    }
  }, [textAreaRef, value])
}

const StyledText = styled(Text)`
  white-space: nowrap;
  padding-left: 2px;
`

const AnswerReflectionInput = styled.textarea`
  width: 100%;
  resize: none;
  border: 1px solid ${p => p.theme.color.grey10};
  outline: none;
  background-color: ${palette.primitives.white};
  padding: 10px 12px;
  border-radius: ${spacing['8']};
  letter-spacing: 0;
  font-size: 14px;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::placeholder {
    color: ${p => p.theme.color.grey25};
  }

  &:hover {
    border-color: ${p => p.theme.color.grey20};
  }
`

const InputView = styled(View)`
  position: relative;
  margin-top: 16px;
`

const AnswerButtonView = styled(View)`
  position: absolute;
  right: 8px;
  bottom: 8px;
`

const StyledSendButton = styled(IconButton)`
  position: absolute;
  right: 7px;
  bottom: 7px;
`

const AnswerToReflectionResponse: React.FC<{
  answer: string
  anonymous: boolean
  responseId: string
  answerId: string
  timeEdited: string | undefined
  userId?: UserId
  isMyAnswer: boolean
  editReflectionAnswer?: (newAnswer: string, responseId: string, answerId: string) => void
  deleteReflectionAnswer?: (responseId: string, answerId: string) => void
  reactToResponse?: (responseId: string, reaction: string, answerId?: string) => void
  unReactToResponse?: (responseId: string, reaction: string, answerId?: string) => void
  reactions: ReflectionResponseReaction[]
  responseName: string
}> = ({
  answer,
  responseId,
  answerId,
  userId,
  isMyAnswer,
  editReflectionAnswer,
  deleteReflectionAnswer,
  timeEdited,
  anonymous,
  reactions,
  responseName,
  reactToResponse,
  unReactToResponse,
}) => {
  const { t } = useTranslation()

  const realUser = useUserLegacy(userId)
  const me = useSelector(selectUser)
  const isCollaborator = useSelector(selectIsCollaborator)
  const hasManageAccess = useHasManageAccess()
  const anonymousUser = useAnonymousUserData()

  const user = anonymous ? anonymousUser : realUser

  const canDeleteAnswer = isMyAnswer || isCollaborator || hasManageAccess

  const [isEditing, setIsEditing] = useState<string | undefined>(undefined)

  const sendNewAnswer = useCallback(
    (isEditing: string) => {
      if (!isDefined(editReflectionAnswer)) return
      void editReflectionAnswer(isEditing, responseId, answerId)
    },
    [editReflectionAnswer, answerId, responseId]
  )

  const myReactions = useMemo(() => {
    if (me?.uuid === undefined) return []

    return _.chain(reactions)
      .filter(reaction => reaction.reactedByUserIds.includes(me.uuid))
      .map(reaction => reaction.reaction)
      .uniq()
      .value()
  }, [me, reactions])

  const toggleReaction = useCallback(
    (reaction: string) => {
      if (myReactions.includes(reaction)) {
        unReactToResponse?.(responseId, reaction, answerId)
      } else {
        reactToResponse?.(responseId, reaction, answerId)
      }
    },
    [myReactions, unReactToResponse, responseId, answerId, reactToResponse]
  )

  const reactingUsers = useUsersLegacy(reactions.flatMap(it => it.reactedByUserIds))
  const reactingUsersById = _.keyBy(reactingUsers, 'uuid')

  const reactionsWithNames: Reaction[] = reactions.map(reaction => ({
    ...reaction,
    names: _.chain(reaction.reactedByUserIds)
      .map(userId => reactingUsersById[userId]?.firstName)
      .compact()
      .value(),
  }))

  const handleKeyPress: KeyboardEventHandler<HTMLTextAreaElement> = event => {
    event.stopPropagation()

    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      if (isEditing === undefined || isEditing.length === 0) return
      event.preventDefault()
      sendNewAnswer(isEditing)
      setIsEditing(undefined)
    }

    if (event.key === 'Escape') {
      event.preventDefault()
      setIsEditing(undefined)
    }
  }

  return (
    <ReplyView gap='12'>
      <User $isMe={isMyAnswer}>
        <RoundAvatar
          size='small'
          firstName={user?.firstName}
          lastName={user?.lastName}
          src={getAvatarImage(user?.uuid, user?.avatar)}
          color={user?.avatarColor}
        />
        <Spacer size='xxsmall' />
        <UserName>
          {isMyAnswer && anonymous === false ? (
            _.capitalize(t('dictionary.you'))
          ) : (
            <>
              {getUserName(user)}
              {isMyAnswer && anonymous === true ? '(' + _.capitalize(t('dictionary.you')) + ')' : null}
            </>
          )}
        </UserName>
      </User>
      {isEditing !== undefined ? (
        <>
          <InputView>
            <AnswerReflectionInput
              autoFocus
              placeholder={t('learner.reflection-card.answer.placeholder', { name: responseName })}
              onKeyDown={handleKeyPress}
              onChange={e => setIsEditing(e.target.value)}
              rows={3}
              value={isEditing}
              onFocus={e => {
                e.target.setSelectionRange(e.target.value.length, e.target.value.length)
              }}
            />
            <StyledSendButton
              iconId='send--filled'
              color='black'
              tooltip='Send'
              disabled={isEditing.length === 0}
              onClick={() => {
                sendNewAnswer(isEditing)
                setIsEditing(undefined)
              }}
            />
          </InputView>
        </>
      ) : (
        <StyledScrollView>
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular'>
            {answer}{' '}
            {timeEdited !== undefined && (
              <Text color='grey40' size='micro' as='span'>
                {'(' + _.lowerCase(t('dictionary.edited')) + ')'}
              </Text>
            )}
          </Text>
        </StyledScrollView>
      )}
      <MinHeightView justifyContent='flex-end' alignItems='flex-end'>
        <View grow wrap='wrap' alignItems='center' direction='row' gap='4' position='relative'>
          {reactions.length > 0 && ( //TODO break out into component
            <>
              {reactionsWithNames.map(({ reaction, count, names }) => (
                <ReflectionCardReaction
                  key={reaction}
                  reaction={reaction}
                  count={count}
                  names={names}
                  highlighted={myReactions.includes(reaction)}
                  onClick={() => {
                    toggleReaction(reaction)
                  }}
                />
              ))}
            </>
          )}
          {reactToResponse !== undefined && (
            <StyledReactionInput currentSelectedReactions={myReactions} onReactionClicked={toggleReaction} />
          )}
        </View>
        <MinHeightView alignItems='center' direction='row'>
          {isMyAnswer && (
            <StyledEditButton
              tooltip={t('dictionary.edit')}
              onClick={() => {
                if (isEditing === undefined) {
                  setIsEditing(answer)
                } else setIsEditing(undefined)
              }}
            />
          )}

          {canDeleteAnswer && (
            <StyledDeleteButton
              tooltip={t('dictionary.delete')}
              onClick={() => {
                if (!isDefined(deleteReflectionAnswer)) return
                deleteReflectionAnswer(responseId, answerId)
              }}
            />
          )}
        </MinHeightView>
      </MinHeightView>
    </ReplyView>
  )
}

const CreateAnswerToReflectionResponse: React.FC<{
  allowAnonymity: boolean | undefined
  setShowReplies: (show: boolean) => void
  setIsReplying: (isReplying: boolean) => void
  responseId: string
  responseName: string
  submitReflectionAnswer?: (answer: string, responseId: string, anonymous: boolean) => void
}> = ({
  responseId,
  submitReflectionAnswer,
  setShowReplies,
  setIsReplying,
  allowAnonymity,
  responseName,
}) => {
  const [answer, setAnswer] = useState('')

  const { t } = useTranslation()
  const [isAnonymous, setIsAnonymous] = useState(false)

  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  useAutosizeTextArea(textAreaRef.current, answer)

  const handleAnswerChanged = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const value = e.target.value
    setAnswer(_.truncate(value, { length: 5000, omission: '' }))
  }

  const sendAnswer = useCallback(
    (answer: string) => {
      if (!isDefined(submitReflectionAnswer)) return
      void submitReflectionAnswer(answer, responseId, isAnonymous)
    },
    [isAnonymous, responseId, submitReflectionAnswer]
  )

  const handleKeyPress: KeyboardEventHandler<HTMLTextAreaElement> = event => {
    event.stopPropagation()

    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      if (answer.length === 0) return
      event.preventDefault()
      sendAnswer(answer)
      setShowReplies(true)
      setAnswer('')
    }

    if (event.key === 'Escape') {
      event.preventDefault()
      setIsReplying(false)
    }
  }

  return (
    <InputView direction='column' gap='none'>
      <AnswerReflectionInput
        value={answer}
        autoFocus
        placeholder={t('learner.reflection-card.answer.placeholder', { name: responseName })}
        ref={textAreaRef}
        onKeyDown={handleKeyPress}
        onChange={handleAnswerChanged}
        rows={1}
        onFocus={e => {
          e.target.setSelectionRange(e.target.value.length, e.target.value.length)
        }}
      />
      <AnswerButtonView gap={'2'}>
        <IconButton
          iconId='send--filled'
          tooltip='Send'
          size='small'
          variant='transparent'
          disabled={answer.length === 0}
          onClick={() => {
            sendAnswer(answer)
            setShowReplies(true)
            setAnswer('')
          }}
        />
        {allowAnonymity === true && (
          <IconMenu
            size='small'
            variant='transparent'
            iconId='chevron--down'
            menuItems={[
              {
                id: 'not-anonymous',
                type: 'label',
                label: t('learner.reflection-card.post-as-yourself'),
                selected: !isAnonymous,
              },
              {
                id: 'anonymous',
                type: 'label',
                label: t('learner.reflection-card.post-anonymously'),
                selected: isAnonymous,
              },
            ]}
            onSelect={item => {
              if (item.id === 'anonymous') {
                setIsAnonymous(true)
              } else {
                setIsAnonymous(false)
              }
            }}
          />
        )}
      </AnswerButtonView>
    </InputView>
  )
}

const StyledAvatarButton = styled.button`
  height: 28px;
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  padding-right: 8px;
  padding-left: 4px;
  margin-right: 8px;
  cursor: pointer;
  transition: 100ms;
  background-color: ${token('surface/soft')};

  &:hover {
    background-color: ${token('surface/strong')};
  }
`

const UserReflectionResponse: React.FC<UserReflectionProps> = forwardRef<HTMLDivElement, UserReflectionProps>(
  (
    {
      reflectionResponse,
      reactToResponse,
      unReactToResponse,
      editResponse,
      deleteReflection,
      submitReflectionAnswer,
      editReflectionAnswer,
      deleteReflectionAnswer,
      allowAnonymity,
    },
    ref
  ) => {
    const {
      id: responseId,
      userId,
      response,
      isMyResponse,
      timeEdited,
      reactions,
      anonymous,
    } = reflectionResponse

    const { t } = useTranslation()
    const realUser = useUserLegacy(userId)

    const me = useSelector(selectUser)
    const [isEditing, setIsEditing] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [newResponse, setNewResponse] = useState(response ?? '')

    const anonymousUser = useAnonymousUserData()

    const user = anonymous === true ? anonymousUser : realUser

    const inLiveSession = useSelector(isInLiveSession)
    const inSelfPaced = useSelector(isInSelfPaced)
    const isFacilitator = useSelector(selectIsFacilitator)
    const isCollaborator = useSelector(selectIsCollaborator)
    const hasManageAccess = useHasManageAccess()
    const [isReplying, setIsReplying] = useState(false)
    const [showReplies, setShowReplies] = useState(false)

    const canDeleteReflection = useMemo(() => {
      if (deleteReflection === undefined) return false
      const satisfiesLiveConditions = inLiveSession && isFacilitator
      const satisfiesSelfPacedConditions = inSelfPaced && (isCollaborator || hasManageAccess)
      return isMyResponse || satisfiesLiveConditions || satisfiesSelfPacedConditions
    }, [
      deleteReflection,
      inLiveSession,
      isFacilitator,
      inSelfPaced,
      isCollaborator,
      hasManageAccess,
      isMyResponse,
    ])

    const myReactions = useMemo(() => {
      if (me?.uuid === undefined) return []

      return _.chain(reactions)
        .filter(reaction => reaction.reactedByUserIds.includes(me.uuid))
        .map(reaction => reaction.reaction)
        .uniq()
        .value()
    }, [me, reactions])

    const handleResponseChanged = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
      const value = e.target.value
      setNewResponse(_.truncate(value, { length: 5000, omission: '' }))
    }

    const cancel = (): void => {
      setNewResponse(response ?? '')
      setIsEditing(false)
    }

    const save = (): void => {
      setIsEditing(false)
      if (editResponse === undefined) return
      if (newResponse === response) return
      editResponse({ responseId, newResponse })
    }

    const deleteUserResponse = (): void => {
      if (deleteReflection === undefined) return
      deleteReflection(responseId)
    }

    const toggleReaction = useCallback(
      (reaction: string) => {
        if (myReactions.includes(reaction)) {
          unReactToResponse?.(responseId, reaction)
        } else {
          reactToResponse?.(responseId, reaction)
        }
      },
      [myReactions, unReactToResponse, responseId, reactToResponse]
    )

    const handleKeyPress: KeyboardEventHandler<HTMLTextAreaElement> = event => {
      event.stopPropagation()

      if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
        event.preventDefault()
        save()
      }

      if (event.key === 'Escape') {
        event.preventDefault()
        cancel()
      }
    }

    const reactingUsers = useUsersLegacy(reactions.flatMap(it => it.reactedByUserIds))
    const reactingUsersById = _.keyBy(reactingUsers, 'uuid')

    const reactionsWithNames: Reaction[] = reactions.map(reaction => ({
      ...reaction,
      names: _.chain(reaction.reactedByUserIds)
        .map(userId => reactingUsersById[userId]?.firstName)
        .compact()
        .value(),
    }))

    const numberOfResponseAnswers = reflectionResponse.answers.length

    const nonAnonymousUsers = _.chain(
      useUsersLegacy(
        reflectionResponse.answers
          .map(answer => (answer.anonymous === false ? answer.userId : undefined))
          .filter(isDefined)
      )
    )
      .filter(isDefined)
      .map(user => ({ ...user, avatar: getAvatarImage(user.uuid, user.avatar) }))
      .groupBy('uuid')
      .value()

    const allUsersInOrder = reflectionResponse.answers
      .map(answer =>
        answer.anonymous === true || answer.userId === undefined
          ? anonymousUser
          : nonAnonymousUsers[answer.userId]?.[0]
      )
      .filter(isDefined)

    const uniqueUsersInOrder = _.uniq(allUsersInOrder)

    return (
      <Container
        key={responseId}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { ease: [0.25, 0.1, 0.25, 1], duration: 0.15 } }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.25 }}
        layout='position'
        ref={ref}
      >
        <LightTokenProvider>
          <ThemeProvider theme={legacyLight}>
            <ReflectionCardHeader>
              <User $isMe={me?.uuid === userId}>
                <RoundAvatar
                  size='small'
                  firstName={user?.firstName}
                  lastName={user?.lastName}
                  src={getAvatarImage(user?.uuid, user?.avatar)}
                  color={user?.avatarColor}
                />
                <Spacer size='xxsmall' />
                <UserName>
                  {isMyResponse && anonymous === false ? (
                    _.capitalize(t('dictionary.you'))
                  ) : (
                    <>
                      {getUserName(user)}
                      {isMyResponse && anonymous === true
                        ? '(' + _.capitalize(t('dictionary.you')) + ')'
                        : null}
                    </>
                  )}
                </UserName>
              </User>
            </ReflectionCardHeader>
            {isEditing ? (
              <AutoexpandingReflectionInput
                autoFocus
                placeholder={t('learner.reflection-card.placeholder')}
                value={newResponse}
                onKeyDown={handleKeyPress}
                onChange={handleResponseChanged}
                onFocus={e => {
                  e.target.setSelectionRange(e.target.value.length, e.target.value.length)
                }}
              />
            ) : (
              <StyledScrollView>
                <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular'>
                  {response}{' '}
                  {timeEdited !== undefined && (
                    <Text color='grey40' size='micro' as='span'>
                      {'(' + _.lowerCase(t('dictionary.edited')) + ')'}
                    </Text>
                  )}
                </Text>
              </StyledScrollView>
            )}

            <ReflectionCardFooter>
              <View gap='4' grow direction='row' alignItems='flex-end'>
                {isEditing ? (
                  <View grow justifyContent='flex-end'>
                    <Button variant='secondary' onClick={cancel}>
                      {t('dictionary.cancel')}
                    </Button>
                    <Button variant='primary' onClick={save}>
                      {t('dictionary.save')}
                    </Button>
                  </View>
                ) : (
                  <>
                    <View grow wrap='wrap' alignItems='center' direction='row' gap='4'>
                      {reactions.length > 0 && (
                        <>
                          {reactionsWithNames.map(({ reaction, count, names }) => (
                            <ReflectionCardReaction
                              key={reaction}
                              reaction={reaction}
                              count={count}
                              names={names}
                              highlighted={myReactions.includes(reaction)}
                              onClick={() => {
                                toggleReaction(reaction)
                              }}
                            />
                          ))}
                        </>
                      )}
                      {reactToResponse !== undefined && (
                        <ReactionInput
                          currentSelectedReactions={myReactions}
                          onReactionClicked={toggleReaction}
                        />
                      )}
                    </View>
                    <RightButtonsContainer>
                      {numberOfResponseAnswers > 0 && (
                        <StyledAvatarButton
                          onClick={() => {
                            setShowReplies(previous => !previous)
                          }}
                        >
                          <AvatarStack size='minuscule' users={uniqueUsersInOrder} max={5} />
                          <StyledText color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' bold size='micro'>
                            {t('learner.reflection-card.answer.count-replies', {
                              count: reflectionResponse.answers.length,
                            })}
                          </StyledText>
                        </StyledAvatarButton>
                      )}
                      {isMyResponse && editResponse && (
                        <StyledEditButton tooltip={t('dictionary.edit')} onClick={() => setIsEditing(true)} />
                      )}
                      {canDeleteReflection && (
                        <StyledDeleteButton
                          tooltip={t('dictionary.delete')}
                          onClick={() => setShowDeleteModal(true)}
                        />
                      )}
                      {(inLiveSession || inSelfPaced) && (
                        <StyledReplyButton
                          tooltip={t('dictionary.reply')}
                          onClick={() => setIsReplying(!isReplying)}
                        />
                      )}
                    </RightButtonsContainer>
                  </>
                )}
              </View>
            </ReflectionCardFooter>

            {showReplies && numberOfResponseAnswers > 0 && (
              <View grow direction='column' gap='12' marginTop='24'>
                {numberOfResponseAnswers > 0 &&
                  reflectionResponse.answers.map(answer => (
                    <AnswerToReflectionResponse
                      anonymous={answer.anonymous}
                      timeEdited={answer.timeEdited}
                      answer={answer.answer}
                      key={answer.answerId}
                      responseId={responseId}
                      userId={answer.userId}
                      isMyAnswer={answer.isMyAnswer}
                      answerId={answer.answerId}
                      editReflectionAnswer={editReflectionAnswer}
                      deleteReflectionAnswer={deleteReflectionAnswer}
                      reactToResponse={reactToResponse}
                      unReactToResponse={unReactToResponse}
                      reactions={answer.reactions}
                      responseName={user?.firstName ?? ''}
                    />
                  ))}
              </View>
            )}
            {isReplying && (
              <CreateAnswerToReflectionResponse
                allowAnonymity={allowAnonymity}
                responseId={responseId}
                submitReflectionAnswer={submitReflectionAnswer}
                setShowReplies={setShowReplies}
                setIsReplying={setIsReplying}
                responseName={user?.firstName ?? ''}
              />
            )}
            <ActionModal
              open={showDeleteModal}
              onClose={() => setShowDeleteModal(false)}
              deleteAction
              primaryAction={deleteUserResponse}
              primaryActionLabel={t('content.delete-button')}
            />
          </ThemeProvider>
        </LightTokenProvider>
      </Container>
    )
  }
)

export const UserReflectionReponseMemo = memo(UserReflectionResponse)

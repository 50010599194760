import { useSearch } from '@tanstack/react-router'
import { useSetAtom } from 'jotai'
import React, { useCallback, useEffect, useState } from 'react'
import { RouterLink } from 'sierra-client/components/common/link'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { Logging } from 'sierra-client/core/logging'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useContentKindPermissions, useHasContentKindPermission } from 'sierra-client/hooks/use-permissions'
import { usePost } from 'sierra-client/hooks/use-post'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useCachedQuery } from 'sierra-client/state/api'
import {
  courseSelectors,
  linkCourseSelectors,
  partnerCourseSelectors,
  scormCourseSelectors,
  selectPrependUrlWithProgramAndPath,
} from 'sierra-client/state/content/selectors'
import { CourseGroupEntity } from 'sierra-client/state/content/types'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { fetchCourseStatusById } from 'sierra-client/state/v2/courses-actions'
import { selectCurrentCoursePassedTimestamp } from 'sierra-client/state/v2/selectors'
import { useIsValidCertificateCourseId } from 'sierra-client/views/learner/certificate/utils'
import { SmartCardsAtom } from 'sierra-client/views/showcase/sections/lil-stack'
import { continueUrl } from 'sierra-client/views/workspace/utils/urls'
import { editUrlFromScopedId } from 'sierra-domain/api/editable-content'
import { CourseId, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { ScopedSelfPacedContentId } from 'sierra-domain/collaboration/types'
import {
  XRealtimeContentGetSmartReviewCards,
  XRealtimeUserResetCourse,
  XRealtimeUserSetCourseState,
} from 'sierra-domain/routes'
import { assertNever } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { IconId } from 'sierra-ui/components/icon'
import { Button, ButtonProps, Spacer, Text } from 'sierra-ui/primitives'
import { v2_breakpoint, v2_breakpoint_int } from 'sierra-ui/theming/breakpoints'
import { legacyLight } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeProvider } from 'styled-components'

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
  width: 100%;

  @media screen and (max-width: ${v2_breakpoint_int.phone - 1}px) {
    margin-top: 1rem;
  }

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    order: 1;
  }
`

const CertificateLinkAnchor = styled.a`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 1rem;
  margin-top: 1rem;
`

const CertificateIcon = styled(Icon).attrs({ iconId: 'recommend', size: 'size-16' })`
  margin-right: 0.5rem;
  color: ${p => p.theme.color.greenBright};
`

const LinkText = styled(Text)`
  color: ${p => p.theme.color.greenBright};
`

const CertificateLink: React.FC<{ courseId: string }> = ({ courseId }) => {
  const { t } = useTranslation()

  return (
    <CertificateLinkAnchor href={`/certificate-v2?courseId=${courseId}`} target='_blank'>
      <CertificateIcon />
      <LinkText color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular'>
        {t('course-overview.download-certificate')}
      </LinkText>
    </CertificateLinkAnchor>
  )
}

type HeaderButtonProps = {
  href?: string
  onClick?: () => void
  type?: ButtonProps['variant']
  iconId?: IconId
  iconPos?: 'left' | 'right'
  disabled?: boolean
}

const _RouterLink = styled(RouterLink)<{ grow?: boolean }>`
  display: ${p => (p.grow === true ? 'contents' : 'inline')};
`

const HeaderButton = ({
  href,
  onClick,
  type = 'primary',
  iconId,
  iconPos,
  children,
  external = false,
  grow,
  disabled,
}: HeaderButtonProps & {
  children: string
  external?: boolean
  grow?: boolean
}): JSX.Element | null => {
  if (href === undefined) return null

  const link = external ? (
    <Button
      variant={type}
      icon={iconId}
      decoratorPosition={iconPos}
      onClick={onClick}
      grow={grow}
      href={href}
      rel='noopener noreferrer'
      target='_blank'
    >
      {children}
    </Button>
  ) : (
    <Button
      variant={type}
      icon={iconId}
      decoratorPosition={iconPos}
      onClick={onClick}
      disabled={disabled}
      grow={grow}
    >
      {children}
    </Button>
  )

  if (external) return link

  return (
    <_RouterLink grow={grow} href={href}>
      {link}
    </_RouterLink>
  )
}

const PartnerHeaderButtons: React.FC<{ courseId: CourseId; href: string }> = ({ courseId, href }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <ButtonContainer>
      <HeaderButton
        href={href}
        type='primary'
        onClick={() =>
          void dispatch(Logging.course.buttonClicked({ courseId: courseId, buttonId: 'start-course-header' }))
        }
        external
      >
        {t('manage.linked-in.launch')}
      </HeaderButton>
    </ButtonContainer>
  )
}

const LinkHeaderButtons: React.FC<{ courseId: CourseId; href: string }> = ({ courseId, href }) => {
  const canEditCompletion = useHasContentKindPermission(courseId, 'LEARN_MARK_AS_COMPLETED')

  const passedTimestamp = useSelector(selectCurrentCoursePassedTimestamp)
  const { postWithUserErrorException } = usePost()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  return (
    <>
      {canEditCompletion && passedTimestamp !== undefined ? (
        <>
          <ButtonContainer>
            <HeaderButton href={href} type='primary' external={true}>
              {t('course-overview.direct-link.retake')}
            </HeaderButton>
          </ButtonContainer>
          <Spacer size='xxsmall' />
          <ButtonContainer>
            <Button
              variant='transparent'
              icon='restart'
              decoratorPosition='left'
              loading={loading}
              onClick={async () => {
                setLoading(true)
                try {
                  await postWithUserErrorException(XRealtimeUserSetCourseState, {
                    courseId,
                    complete: false,
                  })
                  await dispatch(fetchCourseStatusById({ courseId }))
                } finally {
                  setLoading(false)
                }
              }}
            >
              {t('course-overview.direct-link.uncomplete')}
            </Button>
          </ButtonContainer>
        </>
      ) : (
        <>
          <ButtonContainer>
            <HeaderButton
              href={href}
              type='primary'
              iconId='launch'
              iconPos={'right'}
              external
              onClick={() =>
                void dispatch(
                  Logging.course.buttonClicked({ courseId: courseId, buttonId: 'start-course-header' })
                )
              }
            >
              {t('course-overview.direct-link.open')}
            </HeaderButton>
          </ButtonContainer>

          {canEditCompletion && (
            <>
              <Spacer size='xxsmall' />
              <ButtonContainer>
                <Button
                  variant='transparent'
                  icon='checkmark--outline'
                  decoratorPosition='left'
                  loading={loading}
                  onClick={async () => {
                    setLoading(true)
                    try {
                      await postWithUserErrorException(XRealtimeUserSetCourseState, {
                        courseId,
                        complete: true,
                      })
                      await dispatch(fetchCourseStatusById({ courseId }))
                    } finally {
                      setLoading(false)
                    }
                  }}
                >
                  {t('course-overview.direct-link.complete')}
                </Button>
              </ButtonContainer>
            </>
          )}
        </>
      )}
    </>
  )
}

const ScormHeaderButtons: React.FC<{ courseId: CourseId }> = ({ courseId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const prependUrlWithProgramAndPath = useSelector(selectPrependUrlWithProgramAndPath)

  return (
    <ButtonContainer>
      <HeaderButton
        href={prependUrlWithProgramAndPath(`/course/${courseId}/scorm`)}
        type='primary'
        onClick={() =>
          void dispatch(Logging.course.buttonClicked({ courseId: courseId, buttonId: 'start-course-header' }))
        }
      >
        {t('scorm.launch')}
      </HeaderButton>
    </ButtonContainer>
  )
}

const SmartCardsButton: React.FC<{ courseId: CourseId }> = ({ courseId }) => {
  const { t } = useTranslation()

  const shouldAutomaticallyOpenReview = useSearch({
    strict: false,
    select: search => search['smart-review'] === true,
  })

  const setSmartCardsAtom = useSetAtom(SmartCardsAtom)

  const smartCards = useCachedQuery(XRealtimeContentGetSmartReviewCards, { courseId }).data?.smartCards

  const startSmartReview = useCallback(() => {
    if (smartCards) {
      setSmartCardsAtom({
        cards: smartCards.map(smartCard => ({
          id: smartCard.id,
          title: smartCard.title,
          description: smartCard.description,
          image: smartCard.image,
          theme: smartCard.theme?.name,
          sourceCourseId: smartCard.sourceCourseId,
        })),
        mode: 'view',
      })
    }
  }, [setSmartCardsAtom, smartCards])

  useEffect(() => {
    if (shouldAutomaticallyOpenReview) {
      startSmartReview()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartCards])

  if (!smartCards) return null

  return (
    <Button onClick={() => startSmartReview()} variant='ghost' icon='smart'>
      {t('smart-review.start')}
    </Button>
  )
}

type CompletionStatus = 'not-started' | 'started' | 'finished'

const EditCourseButton: React.FC<{ courseId: CourseId }> = ({ courseId }) => {
  const { t } = useTranslation()
  const canEdit = useContentKindPermissions(courseId).has('EDIT_CONTENT')
  const editorUrl = editUrlFromScopedId(ScopedSelfPacedContentId.fromId(courseId as SelfPacedContentId))

  if (canEdit) {
    return (
      <Button href={editorUrl} variant='secondary' icon='edit'>
        {t('author.edit.edit-content')}
      </Button>
    )
  } else {
    return null
  }
}

const SelfPacedButtons = ({
  courseStarted,
  courseCompleted,
  courseId,
}: {
  courseStarted: boolean | undefined
  courseCompleted: boolean | undefined
  courseId: CourseId
}): JSX.Element => {
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()
  const [startOverOpen, { on: openStartOver, off: closeStartOver }] = useToggle()

  const prependUrlWithProgramAndPath = useSelector(selectPrependUrlWithProgramAndPath)
  const url = prependUrlWithProgramAndPath(`/s/${courseId}/next`)

  const resetCourse = async (): Promise<void> => {
    await postWithUserErrorException(XRealtimeUserResetCourse, {
      contentType: 'course',
      contentId: courseId,
    })

    window.location.reload()
  }

  const validCertificateCourse = useIsValidCertificateCourseId({ courseId })

  const completionStatus: CompletionStatus = !(courseStarted ?? false)
    ? 'not-started'
    : courseCompleted ?? false
      ? 'finished'
      : 'started'

  const showCertificate = validCertificateCourse && completionStatus === 'finished'

  const linkingToEditorFlag = useFlag('linking-to-editor')

  return (
    <>
      <ThemeProvider theme={legacyLight}>
        <ActionModal
          open={startOverOpen}
          onClose={closeStartOver}
          primaryAction={resetCourse}
          title={t('manage.start-over')}
          primaryActionLabel={t('dictionary.start-over')}
          deleteAction={false}
        >
          {t('manage.start-over-description')}
        </ActionModal>
      </ThemeProvider>
      <ButtonContainer>
        {completionStatus === 'not-started' ? (
          <Button onClick={() => void getGlobalRouter().navigate({ to: url })}>
            {t('course-overview.start-course')}
          </Button>
        ) : completionStatus === 'started' ? (
          <Button onClick={() => void getGlobalRouter().navigate({ to: url })}>
            {t('course-overview.resume-learning')}
          </Button>
        ) : (
          <>
            <Button onClick={() => void getGlobalRouter().navigate({ to: url })}>
              {t('course-overview.view-course')}
            </Button>
            <Button variant='secondary' onClick={openStartOver}>
              {t('course-overview.reset-course')}
            </Button>
          </>
        )}
        {completionStatus === 'finished' && <SmartCardsButton courseId={courseId} />}
        {showCertificate && <CertificateLink courseId={courseId} />}
        {linkingToEditorFlag && <EditCourseButton courseId={courseId} />}
      </ButtonContainer>
    </>
  )
}

const getEditionURL = ({
  editionId,
  courseGroupType,
}: {
  editionId: string
  courseGroupType: CourseGroupEntity['type']
}): string => {
  switch (courseGroupType) {
    case 'native:course-group':
      return continueUrl({
        type: 'native:self-paced',
        id: editionId,
      })
    case 'scorm:course-group':
      return continueUrl({
        type: 'scorm',
        id: editionId,
      })
    default:
      assertNever(courseGroupType)
  }
}

// TODO(course-editions): Change button copy depending on edition's completion status
export const CourseGroupButtons: React.FC<{
  courseId?: CourseId
  courseGroupType: CourseGroupEntity['type']
}> = ({ courseId, courseGroupType }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const prependUrlWithProgramAndPath = useSelector(selectPrependUrlWithProgramAndPath)

  return (
    <ButtonContainer>
      {courseId !== undefined ? (
        <HeaderButton
          href={prependUrlWithProgramAndPath(getEditionURL({ editionId: courseId, courseGroupType }))}
          type='primary'
          onClick={() =>
            void dispatch(Logging.course.buttonClicked({ courseId, buttonId: 'start-course-header' }))
          }
          grow
        >
          {t('course-overview.item-start')}
        </HeaderButton>
      ) : (
        <HeaderButton grow type='primary' disabled={true}>
          {t('course-overview.item-start')}
        </HeaderButton>
      )}
    </ButtonContainer>
  )
}

export const ExerciseButton: React.FC<{ href: string }> = ({ href }) => {
  const { t } = useTranslation()
  return (
    <ButtonContainer>
      <HeaderButton iconId='restart' href={href} type='primary' grow>
        {t('course-overview.retry-exercise.text')}
      </HeaderButton>
    </ButtonContainer>
  )
}

const LiveCourseButtons: React.FC<{
  courseStarted: boolean | undefined
  courseCompleted: boolean | undefined
  courseId: CourseId
}> = () => {
  return <ButtonContainer>{/* TODO: Get live session status */}</ButtonContainer>
}

export const CourseHeaderButtons: React.FC<{
  courseStarted: boolean | undefined
  courseCompleted: boolean | undefined
  courseId: CourseId
}> = props => {
  const partnerCourses = useSelector(partnerCourseSelectors.selectEntities)
  const partnerCourse = partnerCourses[props.courseId]
  const scormCourses = useSelector(scormCourseSelectors.selectEntities)
  const scormCourse = scormCourses[props.courseId]
  const linkCourses = useSelector(linkCourseSelectors.selectEntities)
  const linkCourse = linkCourses[props.courseId]
  const nativeCourses = useSelector(courseSelectors.selectEntities)
  const nativeCourse = nativeCourses[props.courseId]

  if (partnerCourse !== undefined) {
    return <PartnerHeaderButtons courseId={CourseId.parse(partnerCourse.id)} href={partnerCourse.url} />
  }
  if (scormCourse !== undefined) {
    return <ScormHeaderButtons courseId={CourseId.parse(scormCourse.id)} />
  }
  if (linkCourse !== undefined) {
    return <LinkHeaderButtons courseId={CourseId.parse(linkCourse.id)} href={linkCourse.url} />
  }
  if (nativeCourse !== undefined && nativeCourse.type === 'native:self-paced') {
    return <SelfPacedButtons {...props} />
  }
  if (nativeCourse !== undefined && nativeCourse.type === 'native:live') {
    return <LiveCourseButtons {...props} />
  }
  if (
    nativeCourse !== undefined &&
    (nativeCourse.type === 'native:course-group' || nativeCourse.type === 'scorm:course-group')
  ) {
    return <CourseGroupButtons {...props} courseGroupType={nativeCourse.type} />
  }
  return null
}

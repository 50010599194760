import React, { useState } from 'react'
import { MeetLogo } from 'sierra-client/components/common/logos/meet-logo'
import { TeamsLogo } from 'sierra-client/components/common/logos/teams-logo'
import { ZoomLogo } from 'sierra-client/components/common/logos/zoom-logo'
import { LiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { useSelectCurrentCardBackgroundColor } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { NowButtonCSS } from 'sierra-client/features/sana-now/header/buttons'
import { useNowSessionContext } from 'sierra-client/features/sana-now/hooks/use-now-session-context'
import { useResetBooleanAfterDelay } from 'sierra-client/hooks/use-reset-boolean-after-delay'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getOrInferVideoCallProviderFromVideoCallSetting } from 'sierra-client/lib/sana-now/get-video-call-provider-from-video-call-setting'
import { assert, iife } from 'sierra-domain/utils'
import { Icon, Modal } from 'sierra-ui/components'
import { Button, IconButton, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { maxWidth } from 'sierra-ui/utils/media-query-styles'
import styled from 'styled-components'

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 24px;
  top: 24px;
`

const StyledModal = styled(Modal).attrs({ overlayVariant: 'none' })`
  border: 1px solid ${token('elevated/border')};
  position: absolute;
  right: 0.75rem;
  top: 3.75rem;

  ${maxWidth.tablet} {
    padding: 24px;
  }
`

const ModalContainer = styled(View)`
  flex: 1;
  margin: 32px 56px;

  ${maxWidth.phone} {
    justify-content: center;
  }
`

const JoinTheVideoCallModal: React.FC<{
  liveSession: LiveSessionContext
  open: boolean
  close: () => void
}> = ({ liveSession, open, close }) => {
  const { t } = useTranslation()

  const videoCallProvider = getOrInferVideoCallProviderFromVideoCallSetting(liveSession.data.videoCallSetting)

  const url = iife(() => {
    switch (liveSession.data.videoCallSetting.type) {
      case 'google-meet':
      case 'microsoft-teams':
      case 'zoom':
      case 'url':
        return liveSession.data.videoCallSetting.url
      case 'none': {
        throw Error('JoinTheVideoCallModal used with videoCallSetting.type "none"')
      }
    }
  })

  const copy = iife(() => {
    switch (videoCallProvider) {
      case 'google-meet':
        return {
          logo: <MeetLogo width={48} height={48} />,
          title: t('sana-now.join-modal.title-meet'),
          subTitle: t('sana-now.pre-lobby.join-video-call--subtitle-meet'),
        }
      case 'microsoft-teams':
        return {
          logo: <TeamsLogo width={48} height={48} />,
          title: t('sana-now.join-modal.title-teams'),
          subTitle: t('sana-now.pre-lobby.join-video-call--subtitle-teams'),
        }
      case 'zoom':
        return {
          logo: <ZoomLogo width={48} height={48} />,
          title: t('sana-now.join-modal.title-zoom'),
          subTitle: t('sana-now.pre-lobby.join-video-call--subtitle-zoom'),
        }
      default:
        return {
          logo: <Icon iconId='video-call' size='size-48' />,
          title: t('sana-now.join-modal.title-other'),
          subTitle: t('sana-now.pre-lobby.join-video-call--subtitle-other'),
        }
    }
  })

  const { isEnabled: recentlyClicked, setTrue: setRecentlyClicked } = useResetBooleanAfterDelay()

  const handleClick = (): void => {
    void window.navigator.clipboard.writeText(window.location.href)
    setRecentlyClicked()
  }

  return (
    <StyledModal size={{ width: 420 }} onClose={close} open={open} radius='size-20'>
      <CloseButton variant='secondary' iconId='close' onClick={close} />
      <ModalContainer justifyContent='center' alignItems='center' direction='column' gap='none'>
        {copy.logo} <Spacer size='24' />
        <View direction='column' gap='4'>
          <Text bold size='regular' align='center'>
            {copy.title}
          </Text>
          <Text align='center' size='small' color='foreground/secondary'>
            {copy.subTitle}
          </Text>
        </View>
        <Spacer size='24' />
        <View gap='8'>
          <Button icon={recentlyClicked ? 'checkmark' : 'link'} variant='secondary' onClick={handleClick}>
            {t('share.copy-link')}
          </Button>
          <Button
            decoratorPosition='right'
            icon='new--tab--fill'
            variant='primary'
            onClick={() => {
              window.open(url, '_blank', 'noopener,noreferrer')
              close()
            }}
          >
            {t('sana-now.pre-lobby.join-video-call--button-text')}
          </Button>
        </View>
      </ModalContainer>
    </StyledModal>
  )
}

const StyledIconButton = styled(IconButton)`
  ${NowButtonCSS}
`

export const JoinVideoCallButton: React.FC = () => {
  const liveSession = useNowSessionContext()
  const [modalOpen, setModalOpen] = useState(true)

  const backgroundColor = useSelectCurrentCardBackgroundColor()

  assert(liveSession.data.videoCallSetting.type !== 'none')

  return (
    <>
      <StyledIconButton
        $backgroundColor={backgroundColor}
        variant='secondary'
        onClick={() => setModalOpen(true)}
        iconId='video-call'
      />

      <JoinTheVideoCallModal liveSession={liveSession} open={modalOpen} close={() => setModalOpen(false)} />
    </>
  )
}

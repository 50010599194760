import { motion } from 'framer-motion'
import { Atom } from 'jotai'
import React from 'react'
import { MessageInput } from 'sierra-client/components/chat/input'
import { Messages } from 'sierra-client/components/chat/messages'
import { TypingUserData } from 'sierra-client/components/chat/typing-user-data-type'
import { UsersWriting } from 'sierra-client/components/chat/users-writing'
import { useSelectAtom } from 'sierra-client/hooks/use-select-atom-value'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import * as selectors from 'sierra-client/state/chat/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { ChatIdentifier } from 'sierra-domain/api/chat'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { assertIsNonNullable } from 'sierra-domain/utils'
import { IconButton } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

const ThreadView = styled(motion.div)`
  position: relative;
  height: 100%;
  background: ${token('surface/default')};
  border-left: 1px solid ${token('border/default')};
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
`

const Header = styled.div`
  font-size: 14px;
  padding: 1.125rem 1rem 1rem 1rem;
  gap: 0.5rem;
  font-weight: ${fonts.weight.medium};
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`

export const ChatThread = React.forwardRef<
  HTMLDivElement,
  {
    chatId: ScopedChatId
    chatIdentifier: ChatIdentifier
    threadId: string
    componentKey: string
    onClose: () => void
    usersTypingAtom: Atom<TypingUserData[]>
    setCurrentUserIsTyping: (threadId: string | undefined) => void
  }
>(
  (
    { chatId, chatIdentifier, threadId, onClose, componentKey, usersTypingAtom, setCurrentUserIsTyping },
    ref
  ) => {
    const messageGroups = useSelector(state => selectors.selectMessageGroups(state, chatId, threadId))
    const rootMessageId = messageGroups[0]?.messageIds[0]
    const { t } = useTranslation()
    assertIsNonNullable(rootMessageId)
    const usersTypingInThreadAtom = useSelectAtom(usersTypingAtom, users =>
      users.filter(user => user.threadId === threadId).map(user => user.userId)
    )

    return (
      <ThreadView ref={ref}>
        <Header>
          <IconButton variant='transparent' iconId='chevron--left--small' onClick={onClose} />
          Thread
        </Header>

        <Messages
          chatId={chatId}
          chatIdentifier={chatIdentifier}
          threadId={threadId}
          componentKey={componentKey}
          withoutContentReferences
          visible
          filter={null}
        />

        <MessageInput
          chatId={chatId}
          chatIdentifier={chatIdentifier}
          threadId={threadId}
          placeholder={t('chat.reply-in-thread')}
          setCurrentUserIsTyping={setCurrentUserIsTyping}
        />
        <UsersWriting usersTypingAtom={usersTypingInThreadAtom} />
      </ThreadView>
    )
  }
)

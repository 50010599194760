import fuzzysort from 'fuzzysort'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { extractCourseGroupContent } from 'sierra-client/features/teamspace'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { NewCourseSettingsModal } from 'sierra-client/views/course-settings/course-settings-modal'
import { CourseSettingsModal } from 'sierra-client/views/course-settings/modal'
import { AnimatedSearch } from 'sierra-client/views/manage/components/animated-search'
import { Container, VerticalSeparator } from 'sierra-client/views/workspace/components'
import { ListVirtualizer } from 'sierra-client/views/workspace/components/list-virtualizer'
import { TeamspaceContentTableRow } from 'sierra-client/views/workspace/teamspace/teamspace-content-table-row'
import {
  EditableContent,
  EditableCourseKind,
  NonEditorTeamspaceCourse,
  NonEditorTeamspaceCourseType,
} from 'sierra-domain/api/editable-content'
import {
  FolderRow,
  Teamspace,
  TeamspaceContent,
  TeamspaceEditableContent,
  TeamspaceNonEditorCourse,
  isCourseGroup,
} from 'sierra-domain/api/teamspace'
import { ExtractFrom, assertNever, iife, isNonEmptyString } from 'sierra-domain/utils'
import { MenuItem } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled, { css } from 'styled-components'

type ContentFilter = {
  contentType: 'any-type' | EditableCourseKind | NonEditorTeamspaceCourseType | 'folder' | 'template'
  publishState: 'any-state' | 'published' | 'draft' | 'pending'
  authorship: 'any-author' | 'only-owner-content' | 'only-shared-content'
}

const includeAllFilter: ContentFilter = {
  contentType: 'any-type',
  publishState: 'any-state',
  authorship: 'any-author',
}

const filterRow = (filter: ContentFilter, row: TeamspaceContent, userId: string | undefined): boolean => {
  const isFolder = row.type === 'folder'
  const isEditableContent = row.type === 'editable'
  const isNonEditorCourse = row.type === 'non-editor-course'
  const isSelfpaced = isEditableContent && row.content.type === 'native:self-paced'
  const isLive = isEditableContent && row.content.type === 'native:live'

  const contentTypeMatch: boolean = iife(() => {
    switch (filter.contentType) {
      case 'any-type':
        return true
      case 'native:self-paced':
        return isSelfpaced && row.content.templateSettings === undefined
      case 'native:live':
        return isLive && row.content.templateSettings === undefined
      case 'folder':
        return isFolder
      case 'template':
        return isEditableContent && row.content.templateSettings !== undefined
      case 'scorm':
        return isNonEditorCourse && row.content.type === 'scorm'
      case 'link':
        return isNonEditorCourse && row.content.type === 'link'
      case 'linkedin':
        return isNonEditorCourse && row.content.type === 'linkedin'
      case 'native:event-group':
        return isNonEditorCourse && row.content.type === 'native:event-group'
      case 'scorm:course-group':
      case 'native:course-group':
        return isNonEditorCourse && isCourseGroup(row)
      default:
        assertNever(filter.contentType)
    }
  })

  const publishStateMatch: boolean = iife(() => {
    switch (filter.publishState) {
      case 'any-state':
        return true
      case 'pending':
        return isSelfpaced && row.content.published && row.content.pending
      case 'published':
        return isSelfpaced && row.content.published && !row.content.pending
      case 'draft':
        return isSelfpaced && !row.content.published
      default:
        assertNever(filter.publishState)
    }
  })

  const authorshipMatch: boolean = iife(() => {
    switch (filter.authorship) {
      case 'any-author':
        return true
      case 'only-owner-content': {
        if (isEditableContent) {
          return row.content.collaborators.some(
            collaborator => collaborator.userId === userId && collaborator.role === 'owner'
          )
        } else {
          return false
        }
      }
      case 'only-shared-content':
        return (
          isEditableContent &&
          row.content.collaborators.some(
            collaborator => collaborator.userId === userId && collaborator.role !== 'owner'
          )
        )
      default:
        assertNever(filter.authorship)
    }
  })

  return contentTypeMatch && publishStateMatch && authorshipMatch
}

type SortFunctionId = 'edited-desc' | 'alphabetically-asc' | 'alphabetically-desc'

const sortFunctions: Record<
  SortFunctionId,
  (_: { content: TeamspaceContent[]; pinnedIds: Set<string> }) => TeamspaceContent[]
> = {
  'edited-desc': ({ content, pinnedIds }) =>
    _.orderBy(
      content,
      [
        x => x.type !== 'folder' && pinnedIds.has(x.content.id),
        x => (x.type === 'folder' ? x.updatedAt : x.content.timestamp),
      ],
      ['desc', 'desc']
    ),
  'alphabetically-asc': ({ content, pinnedIds }) =>
    _.orderBy(
      content,
      [
        x => x.type !== 'folder' && pinnedIds.has(x.content.id),
        x => (x.type === 'folder' ? x.displayName.toLowerCase() : x.content.title.toLowerCase()),
      ],
      ['desc', 'asc']
    ),
  'alphabetically-desc': ({ content, pinnedIds }) =>
    _.orderBy(
      content,
      [
        x => x.type !== 'folder' && pinnedIds.has(x.content.id),
        x => (x.type === 'folder' ? x.displayName.toLowerCase() : x.content.title.toLowerCase()),
      ],
      ['desc', 'desc']
    ),
}

const LeftAlignContainer = styled.div`
  margin-left: auto;
`

const DropHighlight = styled(View)<{ isOver: boolean }>`
  border-radius: 12px;
  height: 100%;
  transition: background 100ms ease-in-out;

  /* Add extra space on the side for the highlight background */
  margin-inline: -12px;
  padding-inline: 12px;

  ${p =>
    p.isOver &&
    css`
      background: rgba(20, 170, 255, 0.1);
    `}
`

type TeamspaceSearchContent = {
  id: string
  displayName: string
  content: TeamspaceContent
  groupedContent: Array<TeamspaceContent>
}

export const TeamspaceContentTable: React.FC<{
  content: TeamspaceContent[]
  scrollElement: HTMLDivElement | null
  loadContent: () => void
  noContentComponent: JSX.Element
  isOver: boolean
  teamspace: Teamspace
  currentFolderId: FolderRow['id'] | undefined
}> = ({ content, scrollElement, loadContent, noContentComponent, isOver, teamspace, currentFolderId }) => {
  const { t } = useTranslation()
  const [debouncedSearchTerm, liveSearchTerm, setSearchTerm] = useDebouncedAndLiveState('', { wait: 200 })
  const userId = useSelector(selectUserId)
  const notifications = useNotif()
  const newCourseSettingsModalEnabled = useFlag('new-course-settings-modal')

  const [contentFilter, setContentFilter] = useState<ContentFilter>(includeAllFilter)
  const [sortFunctionId, setSortFunctionId] = useState<SortFunctionId>('edited-desc')

  // support dynamic pinning without reloading content from backend
  const [pinnedIds, setPinnedIds] = useState<string[]>([])
  useEffect(() => {
    setPinnedIds(previous => {
      const nonEditorContent: TeamspaceNonEditorCourse[] = content.filter(
        (it): it is TeamspaceNonEditorCourse => it.type === 'non-editor-course'
      )
      const editableContent: TeamspaceEditableContent[] = content.filter(
        (it): it is TeamspaceEditableContent => it.type === 'editable'
      )

      const nonFolderContent: (TeamspaceEditableContent | TeamspaceNonEditorCourse)[] = [
        ...nonEditorContent,
        ...editableContent,
      ]

      const pinnableContent: (EditableContent | NonEditorTeamspaceCourse)[] = nonFolderContent.map(
        it => it.content
      )

      const current = pinnableContent
        .filter(c => c.pinned === true)
        .map(c => c.id)
        .sort((a: string, b: string) => a.localeCompare(b))
      if (_.isEqual(previous, current)) return previous
      else return current
    })
  }, [content])

  const handlePin = (id: string, isPinned: boolean): void => {
    if (isPinned) {
      setPinnedIds(prevState => [...prevState, id].sort((a: string, b: string) => a.localeCompare(b)))
    } else {
      setPinnedIds(prevState => prevState.filter(pin => pin !== id))
    }
  }

  const displayedContent = useMemo(() => {
    const pinLookup = new Set(pinnedIds)
    const sortFunction = sortFunctions[sortFunctionId]

    // Content in folders are not shown on the teamspace / folder page
    // but we want to include them in the search results
    const allLevelTeamspaceContent = teamspace.content

    // If we do not have any search term, set the data to just the content and do not have it search
    // through all the content in the teamspace
    const _data = isNonEmptyString(debouncedSearchTerm)
      ? _.uniqBy(_.unionBy([...content, ...allLevelTeamspaceContent]), content =>
          content.type === 'folder' ? content.id : content.content.id
        )
      : content

    // group anything that has a courseGroupId
    const { topLevelContent: data = [], courseGroupContent } = extractCourseGroupContent(_data)

    const filtered = data.filter(it => filterRow(contentFilter, it, userId))

    const sorted: TeamspaceSearchContent[] = sortFunction({ content: filtered, pinnedIds: pinLookup }).map(
      it => ({
        id: it.type === 'folder' ? it.id : it.content.id,
        displayName: it.type === 'folder' ? it.displayName : it.content.title,
        content: it,
        groupedContent: it.type !== 'folder' ? courseGroupContent[it.content.id] ?? [] : [],
      })
    )

    const searched = fuzzysort
      .go(debouncedSearchTerm, sorted, {
        all: true,
        limit: debouncedSearchTerm.length > 0 ? 20 : undefined,
        key: 'displayName',
      })
      .map(({ obj }) => obj)

    return searched.map(({ content, groupedContent }) => ({
      content,
      // currenly only allow for grouped content to be of editable kind
      groupedContent: groupedContent.filter(
        (e): e is ExtractFrom<typeof e, { type: 'editable' | 'non-editor-course' }> => e.type !== 'folder'
      ),
    }))
  }, [pinnedIds, sortFunctionId, teamspace.content, debouncedSearchTerm, content, contentFilter, userId])

  const contentList = iife(() => {
    if (displayedContent.length === 0) {
      return noContentComponent
    }

    return (
      <ListVirtualizer
        scrollElement={scrollElement}
        items={displayedContent}
        estimateSize={78} // Estimated size of CreateContentTableRow element in the DOM.
        renderItem={({ content, groupedContent }) => {
          const pinLookup = new Set(pinnedIds)
          const id = content.type === 'folder' ? content.id : content.content.id
          return (
            <TeamspaceContentTableRow
              teamspace={teamspace}
              isOver={isOver}
              key={id}
              content={content}
              groupedContent={groupedContent}
              isPinned={pinLookup.has(id) || groupedContent.some(c => pinLookup.has(c.content.id))}
              onDeleted={() => {
                notifications.push({ type: 'course-removed' })
                loadContent()
              }}
              onPin={handlePin}
              showCurrentFolder={debouncedSearchTerm !== '' && content.parentFolderId !== currentFolderId}
            />
          )
        }}
      />
    )
  })

  const contentTypeItems: MenuItem<ContentFilter['contentType']>[] = [
    {
      type: 'label',
      id: 'any-type',
      label: t('filter.content.any-type'),
      selected: contentFilter.contentType === 'any-type',
    },
    {
      type: 'label',
      id: 'native:live',
      label: t('dictionary.live'),
      selected: contentFilter.contentType === 'native:live',
    },
    {
      type: 'label',
      id: 'native:self-paced',
      label: t('dictionary.course-singular'),
      selected: contentFilter.contentType === 'native:self-paced',
    },
    {
      type: 'label',
      id: 'template',
      label: t('dictionary.template'),
      selected: contentFilter.contentType === 'template',
    },
    {
      type: 'label',
      id: 'folder',
      label: t('dictionary.folder'),
      selected: contentFilter.contentType === 'folder',
    },
    {
      type: 'label',
      id: 'native:event-group',
      label: t('dictionary.in-person'),
      selected: contentFilter.contentType === 'native:event-group',
    },
    {
      type: 'label',
      id: 'scorm',
      label: t('manage.courses.import.scorm'),
      selected: contentFilter.contentType === 'scorm',
    },
    {
      type: 'label',
      id: 'link',
      label: t('author.slate.link.link'),
      selected: contentFilter.contentType === 'link',
    },
    {
      type: 'label',
      id: 'linkedin',
      label: 'LinkedIn',
      selected: contentFilter.contentType === 'linkedin',
    },
  ]
  const selectedContentTypeFilterItem = contentTypeItems.find(item => item.id === contentFilter.contentType)

  const sortOptionItems: MenuItem<keyof typeof sortFunctions>[] = [
    {
      type: 'label',
      id: 'edited-desc',
      selected: sortFunctionId === 'edited-desc',
      label: t('sort.recently-edited'),
    },
    {
      type: 'label',
      id: 'alphabetically-asc',
      selected: sortFunctionId === 'alphabetically-asc',
      label: t('sort.alphabetically.a-z'),
    },
    {
      type: 'label',
      id: 'alphabetically-desc',
      selected: sortFunctionId === 'alphabetically-desc',
      label: t('sort.alphabetically.z-a'),
    },
  ]
  const selectedSortOption = sortOptionItems.find(item => item.id === sortFunctionId)

  const authorshipItems: MenuItem<ContentFilter['authorship']>[] = [
    {
      type: 'label',
      id: 'any-author',
      selected: contentFilter.authorship === 'any-author',
      label: t('filter.authorship.any-author'),
    },
    {
      type: 'label',
      id: 'only-owner-content',
      selected: contentFilter.authorship === 'only-owner-content',
      label: t('filter.authorship.only-owner-content'),
    },
  ]
  const selectedAuthorshipFilterItem = authorshipItems.find(item => item.id === contentFilter.authorship)

  return (
    <View role='region' aria-label={t('table.search-filter.label')} direction='column'>
      <Container gap='8'>
        <AnimatedSearch
          value={liveSearchTerm}
          onChange={setSearchTerm}
          placeholder={`${t('dictionary.search')}...`}
        />
        <LeftAlignContainer>
          <SingleSelectDropdown
            grow={false}
            bold
            selectedItem={selectedContentTypeFilterItem}
            onSelect={item => setContentFilter(prevState => ({ ...prevState, contentType: item.id }))}
            menuItems={contentTypeItems}
          />
        </LeftAlignContainer>
        <SingleSelectDropdown
          grow={false}
          bold
          selectedItem={selectedAuthorshipFilterItem}
          onSelect={item => {
            setContentFilter(prevState => ({ ...prevState, authorship: item.id }))
          }}
          menuItems={authorshipItems}
        />
        <View gap='16' paddingLeft='8'>
          <VerticalSeparator />
          <SingleSelectDropdown
            grow={false}
            bold
            selectedItem={selectedSortOption}
            onSelect={item => setSortFunctionId(item.id)}
            menuItems={sortOptionItems}
          />
        </View>
      </Container>

      <DropHighlight role='list' overflow='visible' direction='column' gap='none' isOver={isOver}>
        {contentList}
      </DropHighlight>
      {newCourseSettingsModalEnabled ? (
        <NewCourseSettingsModal onSave={loadContent} onClose={loadContent} />
      ) : (
        <CourseSettingsModal onSave={loadContent} onClose={loadContent} />
      )}
    </View>
  )
}

import _ from 'lodash'
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import {
  CommentDraftData,
  CommentingContextValue,
  Focusable,
  Measurable,
} from 'sierra-client/domain/commenting/types'
import { isEditableYjsEditor } from 'sierra-client/editor'
import { useEditorTextActions } from 'sierra-client/features/text-actions'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useTypedMutation } from 'sierra-client/state/api'
import {
  sendCommentMessage,
  sendPlainMessage,
  setCommentAttachmentStatus,
} from 'sierra-client/state/chat/actions'
import { selectUnitComments } from 'sierra-client/state/chat/selectors'
import { browseCommentThread, cancelBrowseComment } from 'sierra-client/state/commenting/actions'
import { selectCurrentBrowsing } from 'sierra-client/state/commenting/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { FCC } from 'sierra-client/types'
import { CommentThread } from 'sierra-client/views/commenting/comment-thread'
import { CommentingContext } from 'sierra-client/views/commenting/context'
import {
  InlineTextIndicator,
  TextHighlightsLayer,
} from 'sierra-client/views/commenting/text-highlights-layer'
import { useCreateRouteIds } from 'sierra-client/views/commenting/use-create-route-ids'
import { useStoredPositionUtils } from 'sierra-client/views/commenting/use-stored-position-utils'
import {
  calculateRelativeRect,
  compareDOMRects,
  createMeasurable,
  getRangeTextPreview,
  getRelativeRange,
  rangeEnclosesOtherRange,
  rangeToMergedRelativeRectangles,
} from 'sierra-client/views/commenting/utils'
import { EditableYjsEditor } from 'sierra-client/views/v3-author/collaboration/types'
import {
  ReadOnlyYjsEditor,
  isReadOnlyYjsEditor,
} from 'sierra-client/views/v3-author/collaboration/with-read-only-yjs-editor'
import { useLatestSelection } from 'sierra-client/views/v3-author/hooks'
import { ChatIdentifier } from 'sierra-domain/api/chat'
import { ChatMessageId, UserId, uuid } from 'sierra-domain/api/uuid'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { XRealtimeChatCreateMessage } from 'sierra-domain/routes'
import { assertNever, iife } from 'sierra-domain/utils'
import { SanaEditor } from 'sierra-domain/v3-author'
import { ColorBuilder } from 'sierra-ui/color'
import { MUIPopper } from 'sierra-ui/mui'
import { Range } from 'slate'
import { useSlate } from 'slate-react'

const EXISTING_OPACITY = 0.25
const DRAFT_OPACITY = 0.45

const ContentCommentingInner: FCC<{
  editor: (EditableYjsEditor | ReadOnlyYjsEditor) & SanaEditor
  allowAllBlockComments: boolean
  container: HTMLElement
  color: ColorBuilder
  editorId: string
  chatId: ScopedChatId
  chatIdentifier: ChatIdentifier
}> = ({ children, editorId, chatId, editor, container, allowAllBlockComments, color, chatIdentifier }) => {
  const route = useCreateRouteIds()
  const contentId = route?.contentId

  const dispatch = useDispatch()
  const unitId = editorId

  const threadPopperRef = useRef<HTMLDivElement | null>(null)
  const threadInputRef = useRef<Focusable>()

  const [threadAnchor, setThreadAnchorState] = useState<Measurable>()
  const updateThreadAnchor = useCallback(
    (anchor: DOMRect) => {
      if (threadAnchor === undefined || !compareDOMRects(threadAnchor.getBoundingClientRect(), anchor)) {
        setThreadAnchorState(createMeasurable(anchor))
      }
    },
    [threadAnchor]
  )

  const currentCommentBrowsing = useSelector(selectCurrentBrowsing)
  const commentBrowseReason = currentCommentBrowsing?.reason
  const currentBrowsedCommentType = currentCommentBrowsing?.comment.contentReference.type
  const browsingCommentId = currentCommentBrowsing?.comment.id
  const browsingInCorrectUnit =
    currentCommentBrowsing === undefined || currentCommentBrowsing.comment.contentReference.unitId === unitId

  const { editorTextActionsState } = useEditorTextActions()

  const [draft, setDraft] = useState<CommentDraftData | undefined>()
  const [draftCommentIndicator, setDraftCommentIndicator] = useState<InlineTextIndicator>()
  const [existingCommentIndicators, setExistingCommentIndicators] = useState<InlineTextIndicator[]>()
  const [textGenerationIndicator, setTextGenerationIndicator] = useState<InlineTextIndicator>()

  const threadOpen =
    threadAnchor !== undefined && (currentCommentBrowsing !== undefined || draft !== undefined)

  const selection = useLatestSelection()
  const document = editor.children

  const comments = useSelector(state => selectUnitComments(state, chatId, unitId), _.isEqual)
  const selectionComments = useMemo(() => {
    return selection === null
      ? []
      : comments.filter(comment => {
          const { contentReference } = comment
          if (contentReference.type === 'block') return false

          const range = getRelativeRange(editor, contentReference.id)

          if (range === undefined) {
            return false
          }

          /*
           * The selection has to fully enclose the comment for us to consider it selected.
           */
          return rangeEnclosesOtherRange(range, selection)
        })
  }, [comments, editor, selection])

  const { cleanUpDraftStoredPosition, createDraftStoredPosition } = useStoredPositionUtils()

  const cancelCommenting = useStableFunction(async (): Promise<void> => {
    if (draft !== undefined && draft.contentReference.type === 'range') {
      await cleanUpDraftStoredPosition({
        storedPositionKey: draft.contentReference.id,
        fileId: unitId,
        yDocId: editor.yDocId,
      })
    }

    setDraft(undefined)
    setDraftCommentIndicator(undefined)

    void dispatch(cancelBrowseComment())
  })

  const initiateCommenting: CommentingContextValue['initiateCommenting'] = useStableFunction(
    async payload => {
      editor.pushActionsLogEntry('initiate-comment')

      await dispatch(cancelBrowseComment())

      setDraft(undefined)
      setDraftCommentIndicator(undefined)

      switch (payload.contentReference.type) {
        case 'block':
          setDraft({
            unitId,
            indicateDraft: payload.indicateDraft ?? false,
            contentReference: payload.contentReference,
          })
          break

        case 'range': {
          const id = await createDraftStoredPosition({
            fileId: unitId,
            range: payload.contentReference.range,
            yDocId: editor.yDocId,
          })

          const preview = getRangeTextPreview(editor, payload.contentReference.range)

          setDraft({
            unitId,
            indicateDraft: payload.indicateDraft ?? true,
            contentReference: {
              ...payload.contentReference,
              id,
              rangePreview: preview,
            },
          })
          break
        }
        default:
          assertNever(payload.contentReference)
      }

      requestAnimationFrame(() => {
        threadInputRef.current?.focus()
      })
    }
  )

  const newChatSendMessageMutation = useTypedMutation(XRealtimeChatCreateMessage)

  const submitComment = useStableFunction(
    async ({
      threadId,
      userId,
      body,
    }: {
      threadId?: string
      userId: UserId
      body: Record<string, unknown>
    }) => {
      const messageId = ChatMessageId.parse(uuid())

      const responseToMessageId = threadId !== undefined ? ChatMessageId.parse(threadId) : undefined
      newChatSendMessageMutation.mutate({
        chatIdentifier,
        messageData: body,
        messageId,
        contentReference: draft?.contentReference,
        responseToMessageId,
      })

      if (draft !== undefined) {
        editor.pushActionsLogEntry('submit-comment')
        void dispatch(
          sendCommentMessage({
            id: messageId,
            chatId,
            threadId: 'root',
            userId,
            tiptapJsonData: body,
            contentReference: draft.contentReference,
          })
        )
      } else {
        editor.pushActionsLogEntry('reply-to-comment')
        if (threadId !== undefined) {
          void dispatch(sendPlainMessage({ id: messageId, chatId, threadId, userId, tiptapJsonData: body }))
        }
      }

      setDraft(undefined)
      setDraftCommentIndicator(undefined)
    }
  )

  const adjustTextGenerationIndicator = useCallback(() => {
    if (editorTextActionsState === undefined) {
      setTextGenerationIndicator(undefined)
      return
    }

    const { key } = editorTextActionsState
    const range = getRelativeRange(editor, key)

    if (range === undefined) {
      return
    }

    const containerRect = container.getBoundingClientRect()

    setTextGenerationIndicator({
      opacity: DRAFT_OPACITY,
      id: key,
      rectangles: rangeToMergedRelativeRectangles({ editor, containerRect, range }),
    })
  }, [container, editorTextActionsState, editor])

  const adjustExistingIndicators = useCallback(() => {
    if (!browsingInCorrectUnit) {
      return
    }

    const containerRect = container.getBoundingClientRect()

    // Position existing comment rectangles
    const existingIndicators = comments.flatMap(comment => {
      const { contentReference } = comment

      if (contentReference.type === 'block') {
        return []
      }

      const range = getRelativeRange(editor, contentReference.id)
      const isDetached = range === undefined || Range.isCollapsed(range)

      if (isDetached) {
        void dispatch(setCommentAttachmentStatus({ chatId, threadId: comment.id, isDetached }))
        return []
      } else if (comment.detachedFromContent === true) {
        /*
         * This comment was detached at some point. Something like cmd+z could bring it back.
         */
        void dispatch(setCommentAttachmentStatus({ chatId, threadId: comment.id, isDetached }))
      }

      return [
        {
          id: comment.id,
          opacity: browsingCommentId === comment.id ? DRAFT_OPACITY : EXISTING_OPACITY,
          rectangles: rangeToMergedRelativeRectangles({ editor, containerRect, range }),
        },
      ]
    })

    setExistingCommentIndicators(existingIndicators)
  }, [browsingCommentId, browsingInCorrectUnit, chatId, comments, container, dispatch, editor])

  const adjustDraftIndicator = useCallback(() => {
    if (!browsingInCorrectUnit) {
      return
    }

    const containerRect = container.getBoundingClientRect()

    if (draft === undefined) {
      return
    }

    if (draft.contentReference.type === 'block') {
      if (draft.indicateDraft !== true) {
        return
      }

      const anchor = threadAnchor?.getBoundingClientRect()
      const anchorRect = anchor !== undefined ? calculateRelativeRect(anchor, containerRect) : undefined

      if (anchorRect !== undefined) {
        setDraftCommentIndicator({
          opacity: DRAFT_OPACITY,
          id: draft.contentReference.blockId,
          rectangles: [anchorRect],
        })
      }
    } else {
      const { id } = draft.contentReference
      const range = getRelativeRange(editor, id)

      if (range !== undefined) {
        // todo(Anton):
        // throw Error(`Failed to resolve stored range ${id}`)
        // the draft range is probably "resolving" at the moment

        const rectangles = rangeToMergedRelativeRectangles({ editor, containerRect, range })
        const anchor = _.last(rectangles)

        if (anchor !== undefined) {
          updateThreadAnchor(anchor)
        }

        setDraftCommentIndicator({
          opacity: DRAFT_OPACITY,
          id: draft.contentReference.id,
          rectangles,
        })
      }
    }
  }, [browsingInCorrectUnit, container, draft, editor, threadAnchor, updateThreadAnchor])

  const adjustThreadPopper = useCallback(() => {
    if (!browsingInCorrectUnit) {
      return
    }

    const containerRect = container.getBoundingClientRect()
    const popperComment = currentCommentBrowsing?.comment || draft

    if (popperComment === undefined) {
      return
    } else if (popperComment.contentReference.type === 'range') {
      const range = getRelativeRange(editor, popperComment.contentReference.id)

      if (range === undefined) {
        setThreadAnchorState(undefined)
      } else {
        const anchor = _.last(rangeToMergedRelativeRectangles({ editor, containerRect, range }))

        if (draft === undefined && anchor !== undefined) {
          updateThreadAnchor(anchor)
        }
      }
    } else {
      const { blockId } = popperComment.contentReference
      const commentAnchor = window.document.querySelector(`[data-comment-anchor="${blockId}"]`)

      if (commentAnchor === null) return

      const anchor = calculateRelativeRect(commentAnchor.getBoundingClientRect(), containerRect)

      updateThreadAnchor(anchor)
    }
  }, [browsingInCorrectUnit, container, currentCommentBrowsing?.comment, draft, editor, updateThreadAnchor])

  const tick = useCallback(() => {
    adjustExistingIndicators()
    adjustDraftIndicator()
    adjustThreadPopper()
    adjustTextGenerationIndicator()
  }, [adjustExistingIndicators, adjustDraftIndicator, adjustThreadPopper, adjustTextGenerationIndicator])

  const commentingContextValue = useMemo(
    (): CommentingContextValue => ({
      allowBlockComments: allowAllBlockComments,
      initiateCommenting,
      submitComment,
      getColor() {
        return color
      },
      triggerRerender: _.throttle(tick, 20),
    }),
    [allowAllBlockComments, initiateCommenting, submitComment, tick, color]
  )

  const threadKey = iife((): string => {
    const prefix = 'thread'

    if (draft !== undefined) {
      const contentReferenceId =
        draft.contentReference.type === 'block' ? draft.contentReference.blockId : 'range'
      return `${prefix}-${contentReferenceId}`
    } else if (browsingCommentId !== undefined) {
      return `${prefix}-${browsingCommentId}`
    } else {
      return prefix
    }
  })

  /*
   * Scroll the comment box into view
   */
  useLayoutEffect(() => {
    if (threadOpen && currentCommentBrowsing?.reason === 'deeplink') {
      setTimeout(() => {
        threadPopperRef.current?.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        })
      }, 750)
    }
  }, [currentCommentBrowsing?.reason, threadOpen])

  /*
   * Cancel comment browsing if units are out of sync
   */
  useEffect(() => {
    if (browsingInCorrectUnit === false) {
      void dispatch(cancelBrowseComment())
    }
  }, [browsingInCorrectUnit, currentCommentBrowsing, dispatch])

  /*
   * Recalculate as the document changes
   */
  useEffect(() => {
    if (document.length > 0) {
      tick()
    }
  }, [document, tick])

  /*
   * Browse comments in the current selection
   */
  const currentComment = selectionComments.length === 1 ? selectionComments[0] : undefined
  useEffect(() => {
    if (currentComment !== undefined) {
      if (
        currentComment.contentReference.type === 'range' &&
        currentComment.id !== browsingCommentId &&
        contentId !== undefined
      ) {
        void dispatch(browseCommentThread({ reason: 'user', comment: currentComment }))
      }
    } else if (
      currentBrowsedCommentType === 'range' &&
      commentBrowseReason === 'user' &&
      browsingCommentId !== undefined
    ) {
      void dispatch(cancelBrowseComment())
    }
  }, [browsingCommentId, dispatch, contentId, commentBrowseReason, currentBrowsedCommentType, currentComment])

  /*
   * Recalculate as the container changes
   */
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      tick()
    })

    tick()
    observer.observe(container)

    return () => observer.disconnect()
  }, [adjustThreadPopper, container, tick])

  /*
   * Mark any dereferenced comments as detached.
   */
  useEffect(() => {
    for (const comment of comments.filter(comment => comment.detachedFromContent !== true)) {
      const detached =
        comment.contentReference.type !== 'range'
          ? false
          : getRelativeRange(editor, comment.contentReference.id) === undefined

      if (detached) {
        // dispatch(markCommentAsDetached({ chatId, threadId: comment.id }))
      }
    }
  }, [comments, dispatch, editor])

  return (
    <CommentingContext.Provider value={commentingContextValue}>
      {children}

      <TextHighlightsLayer color={color} indicators={existingCommentIndicators ?? []} />
      <TextHighlightsLayer color={color} indicators={[draftCommentIndicator]} />
      <TextHighlightsLayer color={color} indicators={[textGenerationIndicator]} />
      {/* <MergedTextBlobHighlightsLayer rectangles={existingCommentIndicators?.[0]?.rectangles} /> */}

      <MUIPopper
        ref={threadPopperRef}
        open={threadOpen}
        disablePortal
        anchorEl={threadAnchor}
        placement='bottom-start'
        modifiers={{
          offset: {
            enabled: true,
            offset: '0,8',
          },
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: false,
          },
          hide: {
            enabled: false,
          },
          computeStyle: {
            gpuAcceleration: true,
          },
        }}
      >
        <CommentThread
          chatId={chatId}
          chatIdentifier={chatIdentifier}
          mode={
            draft !== undefined
              ? { type: 'creating', contentReference: draft.contentReference }
              : browsingCommentId !== undefined
                ? { type: 'browsing', threadId: browsingCommentId }
                : null
          }
          onClose={async () => {
            await cancelCommenting()
          }}
          componentKey={threadKey}
          inputRef={threadInputRef}
        />
      </MUIPopper>
    </CommentingContext.Provider>
  )
}

export const ContentCommenting: FCC<{
  enableCommenting: boolean
  allowAllBlockComments?: boolean
  container: HTMLElement
  color: ColorBuilder
  editorId: string
  chatId?: ScopedChatId
  chatIdentifier?: ChatIdentifier
}> = ({
  children,
  container,
  enableCommenting,
  allowAllBlockComments = false,
  chatId,
  chatIdentifier,
  ...rest
}) => {
  const editor = useSlate()

  if (!enableCommenting) return <>{children}</>

  if (!(isReadOnlyYjsEditor(editor) || isEditableYjsEditor(editor))) {
    console.warn(
      'Attempted to run commenting in a non-yjs editor nor readOnlyYjsEditor. This is not supported.'
    )
    return <>{children}</>
  }

  if (chatId === undefined || chatIdentifier === undefined) {
    return <>{children}</>
  }

  return (
    <ContentCommentingInner
      editor={editor}
      container={container}
      allowAllBlockComments={allowAllBlockComments}
      chatId={chatId}
      chatIdentifier={chatIdentifier}
      {...rest}
    >
      {children}
    </ContentCommentingInner>
  )
}

import { FC } from 'react'
import { useSpeakerNoteContext } from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/context'
import {
  CreateSpeakerNotes,
  LiveSpeakerNotes,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/index'
import { File } from 'sierra-domain/flexible-content/types'
import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { focusRing } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

const SpeakerNoteWrapper = styled(View).attrs({
  direction: 'column',
  paddingLeft: '10',
  paddingRight: '10',
  paddingTop: '8',
  paddingBottom: '8',
  radius: 'size-10',
  height: 'auto',
  grow: true,
  gap: '2',
})<{ $active: boolean }>`
  color: ${p => p.theme.color['black']};
  background-color: ${p => p.theme.color['white']};
`

const LiveSpeakerNoteWrapper = styled(SpeakerNoteWrapper).attrs({ cursor: 'pointer' })`
  color: ${token('foreground/primary')};
  background-color: ${token('surface/default')};

  ${p =>
    p.$active &&
    css`
      background-color: ${token('border/default')};
    `}
`

const CreateSpeakerNoteWrapper = styled(SpeakerNoteWrapper).attrs({
  onClick: (e: React.MouseEvent<HTMLElement>) => e.stopPropagation(),
  marginTop: '6',
  marginBottom: '6',
  marginLeft: '24',
  animated: true,
})`
  color: ${p => p.theme.color['black']};
  background-color: ${p => p.theme.color['white']};
  overflow: hidden;
  border: 1px solid transparent;
  transition: border-color 150ms cubic-bezier(0.25, 0.5, 0.25, 1);

  ${p => p.$active && focusRing};

  &:hover {
    border-color: ${token('form/border/1')};
  }

  ${p =>
    p.$active &&
    css`
      &:hover {
        border-color: transparent;
      }
    `};
`

type SpeakerNoteProps = { file: File }

export const CreateSpeakerNoteInput: FC<SpeakerNoteProps & { editable: boolean }> = ({ file, editable }) => {
  const { focusedSpeakerNote } = useSpeakerNoteContext()

  return (
    <CreateSpeakerNoteWrapper $active={focusedSpeakerNote === file.id}>
      <CreateSpeakerNotes file={file} editable={editable} />
    </CreateSpeakerNoteWrapper>
  )
}

export const LiveSpeakerNoteInput: FC<SpeakerNoteProps & { active: boolean }> = ({ file, active }) => {
  return (
    <LiveSpeakerNoteWrapper $active={active}>
      <LiveSpeakerNotes file={file} editable={false} />
    </LiveSpeakerNoteWrapper>
  )
}

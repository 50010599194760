import { AnimatePresence, motion } from 'framer-motion'
import { useMemo, useState } from 'react'
import type { SkillId, SkillRecommendationId } from 'sierra-client/api/graphql/branded-types'
import { Link } from 'sierra-client/components/common/link'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { useGetFormattedTime } from 'sierra-client/core/format'
import { ContentSkillLevelDropdown } from 'sierra-client/features/skills/components/content-skill/content-skill-level-dropdown'
import {
  useAcceptRecommendationMutation,
  useContentRecommendationForSkill,
  useRejectRecommendationMutation,
  type ContentRecommendationItem,
} from 'sierra-client/features/skills/components/recommendation/gql'
import { rowAnimationProps } from 'sierra-client/features/skills/components/recommendation/motion'
import { useInvalidateSkillContentTableQuery } from 'sierra-client/features/skills/components/tabular/skill-content-table-query'
import { useInvalidateGetSkillsQueries } from 'sierra-client/features/skills/shared-gql-queries'
import { useFakeProgress } from 'sierra-client/hooks/use-fake-progress'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDebug } from 'sierra-client/lib/use-debug/use-debug'
import { bareContentId } from 'sierra-client/views/manage/content/utils/content-utils'
import { CourseId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { iife, isDefined, isNotDefined } from 'sierra-domain/utils'
import {
  Icon,
  ProgressBar,
  TruncatedText,
  aiColor,
  aiColorThree,
  aiColorTwo,
  aiGradient,
} from 'sierra-ui/components'
import { IconButton, LoaderAnimation, Text, View } from 'sierra-ui/primitives'
import { unit, useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'

const RecommendationList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;
  list-style: none;
`

const Row = styled(View).attrs({ variant: 'ghost' })`
  padding-block: 14px;
  padding-inline: 16px;
  border-radius: 12px;
  background-image: radial-gradient(circle at center center, ${aiColorThree} 0%, transparent 100%),
    linear-gradient(to right bottom, ${aiColor} 0%, ${aiColorTwo} 100%);
  background-size: 300% 300%;
  gap: 20px;
`

const FixedWidth = styled(View)<{ $width: number | string }>`
  width: ${p => unit(p.$width)};
`

const RecommendationItem: React.FC<{
  item: ContentRecommendationItem
  onAccept: (item: ContentRecommendationItem) => void
  onReject: (item: ContentRecommendationItem) => void
  onLevelChange: (item: ContentRecommendationItem) => void
}> = ({ item, onAccept, onReject, onLevelChange }) => {
  const { content, skill } = item
  const { t } = useTranslation()

  const formattedDuration = useGetFormattedTime(item.content.duration, false)

  const assetContext: AssetContext = useMemo(
    () => ({
      type: 'course',
      courseId: CourseId.parse(bareContentId(content.contentId)),
    }),
    [content.contentId]
  )

  const thumbnailSrc = useResolveCourseAsset({
    image: item.content.image,
    options: { type: 'default', size: 'small' },
    assetContext,
  })

  return (
    <Row>
      <FixedWidth $width='50%'>
        <Thumbnail image={thumbnailSrc} height={2} width={3} radius={0.375} />

        <View direction='column' gap='none'>
          <Link href={`/create/s/${bareContentId(content.contentId)}`} target='_blank'>
            <TruncatedText>{content.title}</TruncatedText>
          </Link>

          <View gap='2'>
            <Icon iconId='bookmark--filled' size='size-12' color='foreground/muted' />
            <TruncatedText bold lines={1} size='small' color='foreground/muted'>
              {formattedDuration}
            </TruncatedText>
          </View>
        </View>
      </FixedWidth>

      <View justifyContent='space-between' grow>
        <FixedWidth $width={180}>
          <ContentSkillLevelDropdown
            grow
            skill={skill}
            onSelect={value => {
              onLevelChange({ content: item.content, skill: value })
            }}
            variant='default'
          />
        </FixedWidth>

        <View grow justifyContent='flex-end'>
          <IconButton
            variant='secondary'
            iconId='checkbox--cross--filled'
            color='foreground/muted'
            onClick={() => onReject(item)}
            tooltip={t('skills.suggestion.dismiss')}
          />
          <IconButton
            variant='secondary'
            iconId='checkbox--checkmark--filled'
            color='success/background'
            onClick={() => onAccept(item)}
            tooltip={t('skills.suggestion.accept')}
          />
        </View>
      </View>
    </Row>
  )
}

const _ContentRecommendation: React.FC<{
  recommendation: ContentRecommendationItem[] | undefined
  onAccept: (item: ContentRecommendationItem) => void
  onReject: (item: ContentRecommendationItem) => void
  onLevelChange: (item: ContentRecommendationItem) => void
}> = ({ recommendation, onAccept, onReject, onLevelChange }) => {
  return (
    <AnimatePresence>
      {isDefined(recommendation) && recommendation.length > 0 && (
        <RecommendationList>
          {recommendation.map(item => (
            <motion.li key={item.content.contentId} layout {...rowAnimationProps}>
              <RecommendationItem
                item={item}
                onAccept={onAccept}
                onReject={onReject}
                onLevelChange={onLevelChange}
              />
            </motion.li>
          ))}
        </RecommendationList>
      )}
    </AnimatePresence>
  )
}

const Pill = styled.div`
  display: flex;
  height: 16px;
  min-width: 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 9999px;
  ${aiGradient};
  color: white;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
`

const states = {
  HIDDEN: 'HIDDEN',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
} as const
type State = keyof typeof states

export const ContentRecommendation: React.FC<{ skillId: SkillId }> = ({ skillId }) => {
  const { t } = useTranslation()
  const debugging = useIsDebugMode()
  const { debugState } = useDebug('Skill recommendations', { debugState: Object.values(states) })

  const {
    value: fakeProgressValue,
    startInfiniteProgress,
    finishProgress,
  } = useFakeProgress({
    target: 100,
    finishDuration: 4000,
  })
  const [recommendedContent, setRecommendedContent] = useState<ContentRecommendationItem[] | undefined>(
    undefined
  )
  const recommendation = useContentRecommendationForSkill(skillId)
  const invalidateSkillTableQuery = useInvalidateSkillContentTableQuery(skillId)
  const invalidateSkillsQueries = useInvalidateGetSkillsQueries()

  const { mutate: acceptRecommendationMutation } = useAcceptRecommendationMutation({
    onSuccess: () => {
      void invalidateSkillTableQuery()
      void invalidateSkillsQueries()
    },
  })
  const { mutate: rejectRecommendationMutation } = useRejectRecommendationMutation({
    onSuccess: () => {
      void invalidateSkillTableQuery()
      void invalidateSkillsQueries()
    },
  })

  const state = iife((): State => {
    if (debugging) {
      return debugState
    }

    if (recommendedContent?.length === 0) {
      return states.HIDDEN
    } else if (recommendation.data?.status === 'Done') {
      return states.LOADED
    } else if (recommendation.data?.status === 'InProgress') {
      return states.LOADING
    } else {
      return states.HIDDEN
    }
  })

  const getRecommendationId = (courseId: string): SkillRecommendationId | undefined => {
    if (recommendation.data?.status === 'Done') {
      return recommendation.data.recommendationMap.get(courseId)
    } else {
      return undefined
    }
  }

  useOnChanged((_, newState) => {
    switch (newState) {
      case states.LOADED: {
        if (isNotDefined(recommendedContent)) {
          if (recommendation.data?.status === 'Done') {
            setRecommendedContent(recommendation.data.items)
          }
        }

        void finishProgress()

        break
      }
      case states.LOADING: {
        void startInfiniteProgress()
        break
      }
    }
  }, state)

  switch (state) {
    case states.HIDDEN: {
      return null
    }
    case states.ERROR: {
      return null
    }
  }

  return (
    <View direction='column' gap='10'>
      <AnimatePresence>
        <motion.div layout {...rowAnimationProps}>
          <View>
            <Text size='regular' bold>
              {t('skills.recommendation.title')}
            </Text>

            {state === states.LOADED && <Pill>{recommendedContent?.length}</Pill>}
          </View>
        </motion.div>

        {state === states.LOADING && (
          <Row justifyContent='space-between'>
            <View>
              <LoaderAnimation size={24} />
              <Text color='foreground/primary'>{t('skills.suggestion.loading')}</Text>
            </View>

            <FixedWidth $width={100}>
              <ProgressBar percent={fakeProgressValue} variant='ai' />
            </FixedWidth>
          </Row>
        )}
      </AnimatePresence>

      {state === states.LOADED && (
        <_ContentRecommendation
          recommendation={recommendedContent}
          onAccept={item => {
            // Remove the recommendation optimistically
            setRecommendedContent(prev =>
              isDefined(prev) ? prev.filter(it => it.content.contentId !== item.content.contentId) : prev
            )

            const recommendationId = getRecommendationId(item.content.contentId)

            if (isDefined(recommendationId)) {
              acceptRecommendationMutation({ id: recommendationId, levelOverride: item.skill.levelSettingId })
            }
          }}
          onReject={item => {
            // Remove the recommendation optimistically
            setRecommendedContent(prev =>
              isDefined(prev) ? prev.filter(it => it.content.contentId !== item.content.contentId) : prev
            )

            const recommendationId = getRecommendationId(item.content.contentId)

            if (isDefined(recommendationId)) {
              rejectRecommendationMutation({ id: recommendationId })
            }
          }}
          onLevelChange={item => {
            setRecommendedContent(prev =>
              isDefined(prev)
                ? prev.map(it =>
                    it.content.contentId === item.content.contentId ? { ...it, skill: item.skill } : it
                  )
                : prev
            )
          }}
        />
      )}
    </View>
  )
}

/**
 * Analyze the call state and updates the known issues in redux
 */

import { FC, useEffect, useRef, useState } from 'react'
import { useNonExpiringNotif } from 'sierra-client/components/common/notifications'
import { useVideoCallService } from 'sierra-client/components/liveV2/live-context'
import { DetectPerformanceIssue } from 'sierra-client/components/liveV2/live-session-issue-detector/performance-issue-detector'
import {
  isDownlinkBad,
  isUplinkBad,
} from 'sierra-client/components/liveV2/services/video-call-service/helpers/network-quality'
import { useLocalTracks } from 'sierra-client/components/liveV2/services/video-call-service/hooks/useLocalTracks'
import { Mode } from 'sierra-client/components/liveV2/types'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ExponentialMovingAverage } from 'sierra-client/lib/statistics/exponential-moving-average'
import { MovingAverage } from 'sierra-client/lib/statistics/moving-average'
import { RollingWindowAverage } from 'sierra-client/lib/statistics/rolling-window-average'
import { logger } from 'sierra-client/logger/logger'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { addIssue, clearIssue } from 'sierra-client/state/live/actions'
import {
  selectAudioDeviceId,
  selectAudioState,
  selectAvailableMicrophones,
  selectCameraPermission,
  selectConnectionState,
  selectCurrentCameraId,
  selectIssuesInCategory,
  selectJoinState,
  selectMicrophonePermission,
  selectNetworkQualityStats,
  selectVideoState,
} from 'sierra-client/state/live/selectors'

const DetectNetworkIssues = (): null => {
  const connectionStats = useSelector(selectNetworkQualityStats)
  const dispatch = useDispatch()

  const uplinkIsBad = connectionStats && isUplinkBad(connectionStats.uplink.windowedUplink)
  const downlinkIsBad = connectionStats && isDownlinkBad(connectionStats.downlink.windowedDownlink)

  useEffect(() => {
    if (uplinkIsBad === undefined) return

    if (uplinkIsBad) {
      void dispatch(addIssue('uplink-low'))
    } else {
      void dispatch(clearIssue('uplink-low'))
    }
  }, [dispatch, uplinkIsBad])

  useEffect(() => {
    if (downlinkIsBad === undefined) return

    if (downlinkIsBad) {
      void dispatch(addIssue('downlink-low'))
    } else {
      void dispatch(clearIssue('downlink-low'))
    }
  }, [dispatch, downlinkIsBad])

  return null
}

const DetectAgoraConnectionIssues = (): null => {
  const joinState = useSelector(selectJoinState)
  const connectionState = useSelector(selectConnectionState)
  const { push, remove } = useNonExpiringNotif()
  const notificationId = useRef<string | undefined>(undefined)
  const { t } = useTranslation()

  useEffect(() => {
    if (joinState === 'joined' && connectionState === 'DISCONNECTED') {
      logger.warn('Bad connection state found!', { joinState, connectionState })

      notificationId.current = push({
        type: 'custom',
        body: t('live.connection.reconnecting'),
        level: 'info',
      })
    }
  }, [joinState, connectionState, push, t])

  useEffect(() => {
    if (connectionState === 'CONNECTED' && notificationId.current !== undefined) {
      remove(notificationId.current)
      notificationId.current = undefined
    }
  }, [connectionState, remove])

  return null
}

const DetectPermissionIssues = (): null => {
  const hasMicPermission = useSelector(selectMicrophonePermission)
  const hasCamPermission = useSelector(selectCameraPermission)

  const dispatch = useDispatch()

  useEffect(() => {
    if (hasMicPermission === 'denied') {
      void dispatch(addIssue('microphone-permission-denied'))
    } else {
      void dispatch(clearIssue('microphone-permission-denied'))
    }

    if (hasCamPermission === 'denied') {
      void dispatch(addIssue('camera-permission-denied'))
    } else {
      void dispatch(clearIssue('camera-permission-denied'))
    }
  }, [hasMicPermission, hasCamPermission, dispatch])

  return null
}

const DetectCameraIssues: FC<{ mode: Mode }> = ({ mode }): null => {
  const videoState = useSelector(selectVideoState)
  const videoDevice = useSelector(selectCurrentCameraId)
  const videoCallService = useVideoCallService()
  const dispatch = useDispatch()
  const issueIsActive = useSelector(
    state =>
      selectIssuesInCategory(state)('video').find(issue => issue.name === 'camera-no-image') !== undefined
  )

  const cameraPollTime = mode === 'pre-lobby' ? 5_000 : mode === 'in-session' ? 15_000 : 8_000

  useEffect(() => {
    const localVideoTrack = videoCallService?.tracks.localVideoTrack
    if (localVideoTrack === undefined || videoDevice === undefined) return

    if (videoState !== 'on') {
      if (issueIsActive) {
        void dispatch(clearIssue('camera-no-image'))
      }
      return
    }

    const checkCameraImageInterval = setInterval(() => {
      let image
      try {
        image = localVideoTrack.getCurrentFrameData()
      } catch (error) {
        // ignore error
        logger.warn('Error getting camera image', { error })
        return
      }
      const imageData = image.data
      let darkPixelCount = 0
      let checkedPixelsCount = 0

      for (let i = 0; i < imageData.length; i += 400) {
        checkedPixelsCount++
        const r = imageData[i] ?? 0
        const g = imageData[i + 1] ?? 0
        const b = imageData[i + 2] ?? 0
        // const a = imageData[i + 3] // not relevant for this

        if (r < 10 && g < 10 && b < 10) {
          darkPixelCount++
        }
      }

      if (darkPixelCount > checkedPixelsCount * 0.9) {
        if (!issueIsActive) {
          void dispatch(addIssue('camera-no-image'))
        }
      } else if (issueIsActive) {
        void dispatch(clearIssue('camera-no-image'))
      }
    }, cameraPollTime)

    return () => {
      clearInterval(checkCameraImageInterval)
    }
  }, [
    dispatch,
    videoDevice,
    videoState,
    videoCallService?.tracks.localVideoTrack,
    issueIsActive,
    cameraPollTime,
  ])

  return null
}

const DetectMicrophoneIssues = (): null => {
  const audioState = useSelector(selectAudioState)
  const audioDeviceId = useSelector(selectAudioDeviceId)
  const availableMicrophones = useSelector(selectAvailableMicrophones)
  const currentAudioIssues = useSelector(selectIssuesInCategory)('audio')
  const [workingMicrophoneIds, setWorkingMicrophoneIds] = useState<string[]>([])
  const { localAudioTrack } = useLocalTracks()
  const sanaInternal = useFlag('sana-internal')
  const dispatch = useDispatch()

  const hasMicrophoneNoAudioIssue = !!currentAudioIssues.find(issue => issue.name === 'microphone-no-audio')

  useEffect(() => {
    if (localAudioTrack === undefined || audioState !== 'on') return
    if (audioDeviceId !== undefined && workingMicrophoneIds.includes(audioDeviceId)) return

    const volumeWindowedAverage: MovingAverage = sanaInternal
      ? new ExponentialMovingAverage(8)
      : new RollingWindowAverage(8)

    const checkVolumeInterval = setInterval(() => {
      const volume = localAudioTrack.getVolumeLevel()
      volumeWindowedAverage.push(volume)

      if (!hasMicrophoneNoAudioIssue && volumeWindowedAverage.filled() && volumeWindowedAverage.avg() === 0) {
        void dispatch(addIssue('microphone-no-audio'))
      } else if (hasMicrophoneNoAudioIssue && volume > 0) {
        void dispatch(clearIssue('microphone-no-audio'))
      }

      if (audioDeviceId !== undefined && volume > 0) {
        setWorkingMicrophoneIds(currentWorkingMicrophoneIds => {
          if (currentWorkingMicrophoneIds.includes(audioDeviceId)) return currentWorkingMicrophoneIds
          return [...currentWorkingMicrophoneIds, audioDeviceId]
        })
      }
    }, 1000)

    return () => {
      clearInterval(checkVolumeInterval)
    }
  }, [
    dispatch,
    localAudioTrack,
    hasMicrophoneNoAudioIssue,
    audioState,
    audioDeviceId,
    workingMicrophoneIds,
    sanaInternal,
  ])

  useEffect(() => {
    // if availableMicrophones changes, it might have affected the mic 'default'
    setWorkingMicrophoneIds([])
  }, [availableMicrophones])

  return null
}

export const LiveSessionIssueDetector: React.FC<{ mode: Mode }> = ({ mode }) => {
  return (
    <>
      <DetectNetworkIssues />
      <DetectAgoraConnectionIssues />
      <DetectPermissionIssues />
      <DetectMicrophoneIssues />
      <DetectPerformanceIssue />
      <DetectCameraIssues mode={mode} />
    </>
  )
}

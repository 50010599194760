import { RecentlyVisitedEntry } from 'sierra-client/editor/utils/recently-visited/types'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'

export function resolveRecentlyVisitedEntryHref(entry: RecentlyVisitedEntry): string {
  const { scopedId } = entry
  const fileId = entry.fileId !== undefined ? `/${entry.fileId}` : ''
  const id = ScopedCreateContentId.extractId(scopedId)

  switch (entry.type) {
    case 'editor': {
      const scope = ScopedCreateContentId.urlSubPathForId(scopedId)
      return `/create/${scope}/${id}${fileId}`
    }
    case 'self-paced': {
      return `/s/${id}${fileId}`
    }
  }
}

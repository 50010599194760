import { DateTime } from 'luxon'
import React, { useMemo, useState } from 'react'
import { ContentTableRootView, ContentTableRow } from 'sierra-client/components/common/content-table-row'
import { IconMenu } from 'sierra-client/components/common/icon-menu'
import { RouterLink } from 'sierra-client/components/common/link'
import { getAssetContextFromLearnerContent } from 'sierra-client/components/util/asset-contex'
import { getDaysDiff, useDateTimeFormatter, useGetDaysLeft } from 'sierra-client/core/format'
import { useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useIsMobile, useIsSmallDesktop } from 'sierra-client/state/browser/selectors'
import { tagsDataSelectors } from 'sierra-client/state/content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { favoritableContentType, useUserContentHandler } from 'sierra-client/views/favorites-utils'
import { OverdueLabel } from 'sierra-client/views/learner/home/components/overdue-label'
import { SelfEnrollCalendarEvents } from 'sierra-client/views/learner/self-enroll-calendar-events/self-enroll-calendar-events'
import { SelfEnrollLiveSessionList } from 'sierra-client/views/learner/self-enroll-live-session/self-enroll-live-session-list'
import {
  constructScheduleFromLiveData,
  eventScheduleIsToday,
  eventScheduleIsTomorrow,
  formatEventSchedule,
} from 'sierra-client/views/manage/event-groups/event-utils'
import { CalendarEventSchedule } from 'sierra-client/views/manage/event-groups/types'
import {
  CapitalizeFirstLetterView,
  ContentIcon,
  WrappedFavoriteIcon,
} from 'sierra-client/views/workspace/components'
import { StyledThumbnail } from 'sierra-client/views/workspace/create/styled-thumbnail'
import { ActionButton, LinkActionButton } from 'sierra-client/views/workspace/learn/action-button'
import {
  ActualGhostButton,
  HomeworkGradeStatus,
  NoWrapText,
  StepsCompleted,
  TimeEstimate,
} from 'sierra-client/views/workspace/learn/components'
import { convertLearnerContentToLinkable } from 'sierra-client/views/workspace/utils/learnercontent-to-linkable'
import { detailsUrl, titleUrl } from 'sierra-client/views/workspace/utils/urls'
import { AssignedCalendarEventSchedule } from 'sierra-domain/api/common'
import { isGradeFailed } from 'sierra-domain/api/homework'
import { LearnerContent } from 'sierra-domain/api/learn'
import { hasKey, iife, isDefined } from 'sierra-domain/utils'
import { TokenOrColor } from 'sierra-ui/color/token-or-color'
import { Icon, MenuItem, TruncatedText, TruncatedTextWithTooltip } from 'sierra-ui/components'
import { InternalTruncatedText, Spacer, Text, View } from 'sierra-ui/primitives'
import { palette, token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import { narrowDotSeparator } from 'sierra-ui/utils'
import { maxWidth } from 'sierra-ui/utils/media-query-styles'
import styled, { css } from 'styled-components'

const TagLink = styled.a`
  font-weight: ${fonts.weight.bold};

  &:hover {
    color: ${palette.primitives.black} !important;
  }
`

const DetailsButton = styled(ActualGhostButton)`
  color: ${token('foreground/muted')};

  &:hover {
    color: ${token('foreground/primary')};
  }
`

const Tag: React.FC<{ name: string; href: string }> = ({ name, href }) => (
  <RouterLink href={href}>
    <TagLink>{name}</TagLink>
  </RouterLink>
)

const getIsPassedOrCompleted = (row: LearnerContent): boolean => {
  if (row.progress === 1) return true
  if (row.type === 'live-session') {
    return row.endedAt !== undefined || new Date(row.endDate) < new Date()
  }
  if (row.type === 'homework') {
    return row.gradeStatus === 'passed' || row.gradeStatus === 'not-graded'
  }
  return false
}

const isOverdueAndCompleted = (row: LearnerContent): boolean => {
  const isOverdue = row.dueDate !== undefined && new Date(row.dueDate) < new Date()
  const isCompleted = row.progress === 1
  return isOverdue && isCompleted
}

const VerticalAlignedIcon = styled(Icon)`
  vertical-align: sub;
`

const OpenCtaContainer = styled(View)<{ showContainer: boolean }>`
  opacity: 0;
  transition: opacity 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
  ${p =>
    p.showContainer &&
    css`
      opacity: 1;
    `}
`

const MetadataWrapper = styled(CapitalizeFirstLetterView)`
  color: rgba(0, 0, 0, 0.4);
  transition: color 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
`

const Container = styled.div<{ isPassedOrCompleted: boolean }>`
  ${maxWidth.phone} {
    border-bottom: 1px solid ${token('form/border/1')};
  }

  ${ContentTableRootView}:hover {
    ${OpenCtaContainer} {
      opacity: 1;
    }

    ${MetadataWrapper} {
      ${p =>
        !p.isPassedOrCompleted &&
        css`
          color: rgba(0, 0, 0, 0.75);
        `}
    }
  }
`

const assignedEventScheduleToEventSchedule = (
  assignedSchedule: AssignedCalendarEventSchedule
): CalendarEventSchedule => {
  switch (assignedSchedule.type) {
    case 'all-day':
      return {
        type: 'all-day',
        startDate: DateTime.fromISO(assignedSchedule.startDate),
        endDate: DateTime.fromISO(assignedSchedule.endDate),
      }
    case 'with-time':
      return {
        type: 'with-time',
        startTime: DateTime.fromISO(assignedSchedule.startTime),
        endTime: DateTime.fromISO(assignedSchedule.endTime),
        timeZone: assignedSchedule.timeZone,
      }
  }
}

export const LearnContentTableRow: React.FC<{ row: LearnerContent }> = ({ row }) => {
  const { t } = useTranslation()
  const orgTags = useSelector(tagsDataSelectors.selectEntities)
  const isDesktop = !useIsSmallDesktop()
  const isMobile = useIsMobile()
  const [isActionButtonMenuOpen, setIsActionButtonMenuOpen] = useState(false)
  const isPassedOrCompleted = getIsPassedOrCompleted(row)
  const dateFormatter = useDateTimeFormatter({ dateStyle: 'medium' })
  const { isFavorite, addFavorite, removeFavorite, isArchived, archive, unarchive } = useUserContentHandler({
    initialFavorited: row.isFavorite,
    initialArchived: row.isArchived,
    initialRemoved: row.isRemovedFromTodo,
  })

  const { getFormattedDaysLeft } = useGetDaysLeft()
  const formattedDueDate = getFormattedDaysLeft(row.dueDate)
  const isOverdue = isDefined(row.dueDate) && new Date(row.dueDate) < new Date()

  const isDueToday =
    isDefined(row.dueDate) && new Date(row.dueDate).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
  const daysLeft = isDefined(row.dueDate) ? getDaysDiff(DateTime.fromISO(row.dueDate)) : null
  const isDueWithinFiveDays = daysLeft !== null && daysLeft > 0 && daysLeft <= 5
  const hasPendingActions = isDefined(row.pendingActions) ? row.pendingActions > 0 : true

  const getLabelContent = (color: TokenOrColor = 'foreground/secondary'): JSX.Element | null => {
    if (!isDefined(formattedDueDate)) return null
    if (!hasPendingActions) return null

    if (isDueToday || isOverdue) {
      return <OverdueLabel $bgColor='redVivid'>{formattedDueDate}</OverdueLabel>
    }

    if (isDueWithinFiveDays) {
      return <OverdueLabel $bgColor='orangeVivid'>{formattedDueDate}</OverdueLabel>
    }

    return (
      <Text capitalize='first' color={color}>
        {formattedDueDate}
      </Text>
    )
  }

  const tags: { name: string; href: string }[] = row.tags.flatMap(id => {
    const tagName = orgTags[id]?.data.name
    return tagName !== undefined ? [{ name: tagName, href: `/browse?t=${id}` }] : []
  })

  const favoritableType = favoritableContentType(row.type)

  const menuOptions: MenuItem[] = [
    ...(isMobile
      ? [
          {
            id: 'view',
            type: 'label',
            label: t('dictionary.details'),
            onClick: () =>
              getGlobalRouter().navigate({
                to: detailsUrl(convertLearnerContentToLinkable(row)) as string,
              }),
          } satisfies MenuItem,
        ]
      : []),
    ...(row.type === 'live-session' && row.hasRecap === true
      ? [
          {
            id: 'view-session',
            type: 'label',
            label: t('learner-home.view-content'),
            onClick: () => getGlobalRouter().navigate({ to: `/r/${row.id}` }),
          } satisfies MenuItem,
        ]
      : []),
    ...(favoritableType !== undefined
      ? isFavorite
        ? [
            {
              id: 'remove-from-favorites',
              type: 'label',
              label: t('workspace.learn.remove-from-favorites'),
              icon: 'star--filled',
              onClick: () => {
                void removeFavorite(row.id, favoritableType)
              },
            } satisfies MenuItem,
          ]
        : [
            {
              id: 'add-to-favorites',
              type: 'label',
              icon: 'star',
              label: t('workspace.learn.add-to-favorites'),
              onClick: () => {
                void addFavorite(row.id, favoritableType)
              },
            } satisfies MenuItem,
          ]
      : []),
    ...(favoritableType !== undefined
      ? isArchived
        ? [
            {
              id: 'unarchive',
              type: 'label',
              label: t('workspace.learn.unarchive'),
              icon: 'folder',
              onClick: () => {
                void unarchive(row.id, favoritableType)
              },
            } satisfies MenuItem,
          ]
        : [
            {
              id: 'archive',
              type: 'label',
              icon: 'folder',
              label: t('workspace.learn.archive'),
              onClick: () => {
                void archive(row.id, favoritableType)
              },
            } satisfies MenuItem,
          ]
      : []),
  ]

  const exerciseStatus = useMemo(() => {
    if (!hasKey(row, 'exercises')) {
      return null
    } else {
      return row.exercises.at(0) ?? null
    }
  }, [row])

  const completedContent = 'completedAt' in row && isDefined(row.completedAt)
  const showExerciseStatus = !completedContent && exerciseStatus !== null
  const isHardFailExercise = exerciseStatus?.grade === 'failed-with-no-retries'

  const assetContext = getAssetContextFromLearnerContent(row)

  const thumbnailSrc = useResolveCourseAsset({ image: row.image, assetContext })

  const isFaded = isPassedOrCompleted || isHardFailExercise || isArchived

  return (
    <Container isPassedOrCompleted={isFaded} role='listitem'>
      <ContentTableRow
        thumbnail={<StyledThumbnail image={thumbnailSrc} isPassedOrCompleted={isFaded} />}
        left={
          <>
            <View>
              {isFavorite && !isDesktop && <WrappedFavoriteIcon />}
              <RouterLink href={titleUrl(convertLearnerContentToLinkable(row))}>
                <InternalTruncatedText
                  lines={2}
                  size='small'
                  bold
                  color={isFaded ? 'foreground/muted' : 'foreground/primary'}
                >
                  {row.type === 'homework' ? (
                    <>
                      <span>{row.title}</span>
                      <VerticalAlignedIcon iconId='chevron--right--small' />
                      <span>
                        {row.cardTitle !== undefined ? row.cardTitle : t('dictionary.homework-singular')}
                      </span>
                    </>
                  ) : (
                    row.title
                  )}
                </InternalTruncatedText>
              </RouterLink>
            </View>
            <View>
              <MetadataWrapper wrap={isDesktop ? 'nowrap' : 'wrap'} gap='none'>
                {showExerciseStatus ? (
                  <Icon
                    color='foreground/muted'
                    iconId={isGradeFailed(exerciseStatus.grade) ? 'close--circle--filled' : 'time'}
                    size='size-16'
                  />
                ) : (
                  <ContentIcon type={row.type} color='foreground/muted' />
                )}
                <Spacer size='4' />
                <View gap='6'>
                  {iife(() => {
                    // Exercise has its own view here that we care of
                    // Make sure we havent completed the course before we show any data
                    if (showExerciseStatus) {
                      const labelContent = getLabelContent('foreground/muted')

                      switch (exerciseStatus.grade) {
                        case 'failed-with-no-retries':
                        case 'failed': {
                          return (
                            <>
                              <NoWrapText bold color={'foreground/muted'}>
                                {t('homework.not-passed')}
                              </NoWrapText>
                              {labelContent !== null && (
                                <>
                                  <Text size='small' color='foreground/muted'>
                                    {narrowDotSeparator}
                                  </Text>
                                  {labelContent}
                                </>
                              )}
                            </>
                          )
                        }
                        case 'not-graded': {
                          return (
                            <>
                              <NoWrapText bold color={'foreground/muted'}>
                                {t('my-library.items.homework.not-graded')}
                              </NoWrapText>
                              {labelContent !== null && (
                                <>
                                  <Text size='small' color='foreground/muted'>
                                    {narrowDotSeparator}
                                  </Text>
                                  {labelContent}
                                </>
                              )}
                            </>
                          )
                        }
                      }
                    }

                    return (
                      <>
                        {'timeEstimate' in row && (
                          <TimeEstimate color='foreground/muted' timeEstimate={row.timeEstimate} />
                        )}
                        {row.type === 'program' && (
                          <StepsCompleted
                            color='foreground/muted'
                            completedCount={row.completedSteps}
                            totalCount={row.totalSteps}
                          />
                        )}
                        {row.type === 'native:event-group' &&
                          (row.assignedCalendarEvents.length > 1 ? (
                            <NoWrapText color='foreground/muted' size='small'>
                              {t('manage.events.multiple-sessions')}
                            </NoWrapText>
                          ) : row.assignedCalendarEvents[0] !== undefined ? (
                            <TruncatedText color='foreground/muted'>
                              {formatEventSchedule(
                                assignedEventScheduleToEventSchedule(row.assignedCalendarEvents[0].schedule),
                                { month: 'long' }
                              )}
                            </TruncatedText>
                          ) : (
                            <NoWrapText color='foreground/muted' size='small'>
                              {t('home.assigned-content.choose-session')}
                            </NoWrapText>
                          ))}
                        {row.type !== 'live-session' &&
                          row.type !== 'native:event-group' &&
                          row.dueDate !== undefined &&
                          !isOverdueAndCompleted(row) &&
                          isDefined(formattedDueDate) &&
                          getLabelContent()}

                        {row.type === 'live-session' &&
                          iife(() => {
                            const schedule = constructScheduleFromLiveData({
                              startTime: row.startDate,
                              endTime: row.endDate,
                              allDay: row.allDay,
                            })

                            const isToday = eventScheduleIsToday(schedule)
                            const isTomorrow = eventScheduleIsTomorrow(schedule)

                            const extraText = iife(() => {
                              if (isToday) return t('dictionary.today')
                              if (isTomorrow) return t('dictionary.tomorrow')
                              return undefined
                            })

                            return (
                              <>
                                <TruncatedTextWithTooltip
                                  capitalize='first'
                                  size='small'
                                  color='foreground/muted'
                                  tooltip={formatEventSchedule(schedule, { month: 'long' })}
                                >
                                  {extraText !== undefined && (
                                    <>
                                      <Text as='span' bold size='small' color='foreground/muted'>
                                        {extraText}{' '}
                                      </Text>
                                      <Text as='span' size='small' color='foreground/muted'>
                                        {narrowDotSeparator}{' '}
                                      </Text>
                                    </>
                                  )}
                                  {formatEventSchedule(schedule, { month: 'long' })}
                                </TruncatedTextWithTooltip>
                              </>
                            )
                          })}
                        {row.type === 'homework' && (
                          <HomeworkGradeStatus color='foreground/muted' gradeStatus={row.gradeStatus} />
                        )}
                      </>
                    )
                  })}
                  {tags.length > 0 && (
                    <View grow gap='6'>
                      <Text color='foreground/muted' bold>
                        {narrowDotSeparator}
                      </Text>
                      <InternalTruncatedText color='foreground/muted' lines={1} size='small'>
                        {t('my-library.learn-skills', { skills: '' })}
                        {tags.slice(0, -1).map(tag => (
                          <React.Fragment key={tag.href}>
                            <Tag key={tag.href} {...tag} />
                            {', '}
                          </React.Fragment>
                        ))}
                        {tags.slice(-1).map(tag => (
                          <Tag key={tag.href} {...tag} />
                        ))}
                      </InternalTruncatedText>
                    </View>
                  )}
                </View>
              </MetadataWrapper>

              {isDesktop && 'completedAt' in row && row.completedAt !== undefined && (
                <>
                  {narrowDotSeparator}
                  <Text size='small' bold color='foreground/muted'>
                    {t('course-overview.completed')} {dateFormatter.format(row.completedAt)}
                  </Text>
                </>
              )}
            </View>

            {isDesktop && isFavorite && (
              <View>
                <Icon color='yellowBright' iconId='star--filled' />
                <TruncatedText lines={1} size='small' color='foreground/muted'>
                  {t('learner-home.in-your-favorites')}
                </TruncatedText>
              </View>
            )}
          </>
        }
        right={<React.Fragment />}
        actions={
          <>
            {!isMobile && (
              <OpenCtaContainer gap='8' showContainer={isActionButtonMenuOpen}>
                {row.type === 'link' ? (
                  <LinkActionButton
                    row={row}
                    showReset
                    isOpen={isActionButtonMenuOpen}
                    onOpenChange={setIsActionButtonMenuOpen}
                  />
                ) : (
                  <ActionButton
                    row={row}
                    showReset
                    isOpen={isActionButtonMenuOpen}
                    onOpenChange={setIsActionButtonMenuOpen}
                  />
                )}
                <RouterLink href={detailsUrl(convertLearnerContentToLinkable(row))}>
                  <DetailsButton>{t('dictionary.details')}</DetailsButton>
                </RouterLink>
              </OpenCtaContainer>
            )}
            {row.type !== 'homework' && menuOptions.length > 0 ? (
              <IconMenu iconId='overflow-menu--vertical' closeOnPick items={menuOptions} />
            ) : (
              <Spacer size='small' />
            )}
          </>
        }
        sessionList={
          <>
            {row.type === 'native:live' && row.selfEnrollSessions !== undefined && (
              <SelfEnrollLiveSessionList
                courseId={row.id}
                courseImage={row.image}
                sessions={row.selfEnrollSessions}
              />
            )}
            {row.type === 'native:event-group' && row.assignedCalendarEvents.length === 0 && (
              <SelfEnrollCalendarEvents courseId={row.id} eventGroupId={row.id} courseImage={row.image} />
            )}
          </>
        }
      />
    </Container>
  )
}

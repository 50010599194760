import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { CellProps, Column } from 'react-table'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { TableMediumSearchTrigger } from 'sierra-client/components/table/table-medium'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import {
  ManageTableSmall,
  useManageTableSmall,
} from 'sierra-client/views/manage/components/manage-table-small'
import { UserModalActionsProps } from 'sierra-client/views/manage/users/components/user-modal-actions'
import { UserDetailResponse, UserGroupMembership } from 'sierra-domain/api/manage'
import { EmptyAnchorMenu, Menu } from 'sierra-ui/components'
import { MUIBox } from 'sierra-ui/mui'
import { IconButton, Text, View } from 'sierra-ui/primitives'

type GroupCsv = {
  groupId: string
  groupName: string
  assignedAt: string
}

const mapGroupToCsv = (group: UserGroupMembership): GroupCsv => ({
  groupId: group.groupId,
  groupName: group.groupName ?? '',
  assignedAt: new Date(group.assignedAt).toISOString(),
})

export interface UserGroupsTableProps {
  userGroupMemberships: UserDetailResponse['userGroupMemberships']
  setUserAction: Dispatch<SetStateAction<UserModalActionsProps['action']>>
}

export const UserGroupsTable: React.FC<UserGroupsTableProps> = ({ userGroupMemberships }) => {
  const { t } = useTranslation()
  const [focusedGroupId, setFocusedGroupId] = useState<string | undefined>(undefined)
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false)

  const goToGroupDetails = useCallback(async (groupId: string) => {
    await getGlobalRouter().navigate({ to: `/manage/user-groups/${groupId}` })
  }, [])

  const groupColumns: Column<UserGroupMembership>[] = React.useMemo(
    () => [
      {
        id: 'groupName',
        Header: p => {
          return (
            <>
              <SortableHeader label={t('table.name')} smallLabel {...p} />{' '}
            </>
          )
        },
        accessor: row => row.groupName ?? 'N/A',
        Cell: (p: CellProps<UserGroupMembership>) => {
          return (
            <MUIBox display='flex' alignItems='center'>
              <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
                {p.row.original.groupName ?? 'N/A'}
              </Text>
            </MUIBox>
          )
        },
        width: '60%',
      },
      {
        id: 'assignedAt',
        Header: p => <SortableHeader label={t('admin.groups.assigned-at')} smallLabel {...p} />,
        accessor: row => new Date(row.assignedAt).toDateString(),
        width: '30%',
      },
      {
        id: 'actions',
        disableSortBy: true,
        Header: <TableMediumSearchTrigger onClick={() => setIsSearchOpen(true)} />,
        Cell: ({ row }: CellProps<UserGroupMembership>) => {
          const [open, setOpen] = useState(false)
          const [anchor, setAnchor] = React.useState<HTMLSpanElement | null>(null)

          useEffect(() => {
            if (open) {
              setFocusedGroupId(row.id)
            } else {
              setFocusedGroupId(undefined)
            }
          }, [open, row.id])

          return (
            <View justifyContent='flex-end' grow>
              <EmptyAnchorMenu
                withPortal={true}
                open={open}
                anchor={anchor}
                onClickAway={() => setOpen(false)}
                menuRenderer={
                  <Menu
                    items={[
                      {
                        type: 'label',
                        id: 'view-details',
                        label: t('manage.view-details'),
                        onClick: () =>
                          getGlobalRouter().navigate({ to: `/manage/user-groups/${row.original.groupId}` }),
                        icon: 'user--group',
                      },
                    ]}
                  />
                }
              >
                <IconButton
                  iconId={'overflow-menu--horizontal'}
                  variant='transparent'
                  ref={setAnchor}
                  onClick={ev => {
                    ev.stopPropagation()
                    setOpen(o => !o)
                  }}
                  aria-label={'Details'}
                />
              </EmptyAnchorMenu>
            </View>
          )
        },
        width: '10%',
      },
    ],
    [t]
  )

  const { tableInstance } = useManageTableSmall({
    tableOptions: { data: userGroupMemberships, columns: groupColumns },
    getEntityId: s => s.groupId,
  })

  return (
    <ManageTableSmall
      tableInstance={tableInstance}
      isLoading={false}
      focusedId={focusedGroupId}
      onViewDetails={goToGroupDetails}
      getEntityId={p => p.groupId}
      isSearchOpen={isSearchOpen}
      searchTrigger={setIsSearchOpen}
      mapEntityToCsv={mapGroupToCsv}
      translations={{
        searchPlaceholder: t('manage.search.groups'),
        tableLoading: t('manage.groups.table-loading'),
        tableNoResults: t('manage.groups.no-results'),
        csvPrefix: t('admin.analytics.groups'),
      }}
    />
  )
}

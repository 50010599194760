import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { CustomInterfaceThemeProvider } from 'sierra-client/components/common/custom-interface-theme-provider'
import { CommentingContainerRef } from 'sierra-client/domain/commenting/types'
import { ToggleHandlers, useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { selectCurrentBrowsing } from 'sierra-client/state/commenting/selectors'
import { FCC } from 'sierra-client/types'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'

import { PrimitiveAtom, atom, useAtom, useAtomValue } from 'jotai'
import { HorizontalLineDivider } from 'sierra-client/components/common/horizontal-line-divider'
import { PaginatedNewsModal } from 'sierra-client/components/common/modals/paginated-news-modal'
import {
  HeaderButtonGroupWrapper,
  HeaderGroupIconButton,
  HeaderGroupLoadingSpinner,
} from 'sierra-client/components/liveV2/header-buttons'
import { useObservabilityTags } from 'sierra-client/components/liveV2/hooks/use-observability-tags'
import { TrackVisitedContent } from 'sierra-client/editor/utils/recently-visited/track-visited-content'
import { useSidebarShouldOverlayContent } from 'sierra-client/features/global-sidebar'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useResetBooleanAfterDelay } from 'sierra-client/hooks/use-reset-boolean-after-delay'
import { useDuplicateFileCreatedByUser } from 'sierra-client/state/flexible-content/factory'
import * as flexSelectors from 'sierra-client/state/flexible-content/selectors'
import { selectFlexibleContentFile } from 'sierra-client/state/flexible-content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { atomWithStorage } from 'sierra-client/state/storage'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { CommentingQueryListener } from 'sierra-client/views/commenting/commenting-query-listener'
import { CourseSettingsButton } from 'sierra-client/views/course-settings/components/buttons'
import { NewCourseSettingsModal } from 'sierra-client/views/course-settings/course-settings-modal'
import { CourseSettingsModal } from 'sierra-client/views/course-settings/modal'
import { AINarrations } from 'sierra-client/views/flexible-content/ai-narrations/'
import {
  AINarrationControlsState,
  AINarrationNudgeState,
} from 'sierra-client/views/flexible-content/ai-narrations/ai-narrations'
import { FileBackgroundPicker } from 'sierra-client/views/flexible-content/card-background/file-background-picker'
import CardNarration from 'sierra-client/views/flexible-content/card-narration/card-narration'
import { NarrationNudge } from 'sierra-client/views/flexible-content/card-narration/narration-nudge'
import {
  NarrationState,
  NarrationStateSync,
} from 'sierra-client/views/flexible-content/card-narration/narration-state-sync'
import { CreateCardRenderer } from 'sierra-client/views/flexible-content/create-card-renderer'
import {
  useCreatePageContext,
  useCreatePageNodeIdContext,
  useCreatePageYDocContext,
} from 'sierra-client/views/flexible-content/create-page-context'
import { CreateToastRenderer } from 'sierra-client/views/flexible-content/create-page-toast'
import { DataHistorySwitch } from 'sierra-client/views/flexible-content/data-history-switch/data-history-switch'
import { usePasteFile } from 'sierra-client/views/flexible-content/editor/content-sidebar/copy-paste-utils'
import { FullHeightWithPadding } from 'sierra-client/views/flexible-content/editor/content-sidebar/full-height-with-padding'
import { CreatePageErrorBoundary } from 'sierra-client/views/flexible-content/editor/create-page-error-boundary'
import { EditorChat } from 'sierra-client/views/flexible-content/editor/editor-chat'
import { GeneralCardToolbar } from 'sierra-client/views/flexible-content/editor/toolbar/general-card-toolbar'
import { CreatePageTopbar } from 'sierra-client/views/flexible-content/editor/topbar/create-page-topbar'
import { EmbedCardToolbar } from 'sierra-client/views/flexible-content/embed-toolbar'
import { FileThemePicker } from 'sierra-client/views/flexible-content/file-theme-picker'
import { ImageCardToolbar } from 'sierra-client/views/flexible-content/image-card'
import {
  AINarrationsControls,
  NarrationControls,
} from 'sierra-client/views/flexible-content/narration-controls'
import { PolarisCardTheme } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { PolarisEditorContext } from 'sierra-client/views/flexible-content/polaris-editor-provider/polaris-editor-context'
import { PolarisEditorProvider } from 'sierra-client/views/flexible-content/polaris-editor-provider/polaris-editor-provider'
import { useReversibleEditorAction } from 'sierra-client/views/flexible-content/undo-redo/use-reversible-editor-action'
import { VideoControls } from 'sierra-client/views/flexible-content/video-controls'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { BottomContentContainer } from 'sierra-client/views/self-paced/bottom-content'
import { CardCanvas } from 'sierra-client/views/shared/card-canvas'
import { AssessmentCardToolbar } from 'sierra-client/views/v3-author/assessment-card/toolbar'
import { BulletCardToolbar } from 'sierra-client/views/v3-author/bullet-card/toolbar'
import { HomeworkCardSettingsContainer } from 'sierra-client/views/v3-author/homework/homework-settings'
import { useSafeFlexibleContentId } from 'sierra-client/views/v3-author/hooks'
import { QACardToolbar } from 'sierra-client/views/v3-author/qa-card/toolbar'
import { QuestionCardSettingsToolbar } from 'sierra-client/views/v3-author/question-card/toolbar'
import { ReflectionCardToolbar } from 'sierra-client/views/v3-author/reflection-card/toolbar'
import { ScenarioCardToolbar } from 'sierra-client/views/v3-author/scenario/toolbar'
import { TitleCardToolbar } from 'sierra-client/views/v3-author/title-card/toolbar'
import { NarrationMetadata, NarrationSettings } from 'sierra-domain/api/author-v2'
import { CourseId, CreateContentId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { ContentType, ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { CreateOperationState, apply, redo, undo } from 'sierra-domain/editor/operations'
import { FileId, FolderId, LinkId, NodeId } from 'sierra-domain/flexible-content/identifiers'
import { File } from 'sierra-domain/flexible-content/types'
import { assertIsNonNullable, assertNever, guardWith } from 'sierra-domain/utils'
import { Column, ColumnContainer, FoldingColumn, Icon, Layout } from 'sierra-ui/components'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { CustomThemeName, PresetThemeName } from 'sierra-ui/theming/legacy-theme'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'
import { z } from 'zod'

export const CreateCardCanvas = styled(CardCanvas)`
  /* Every child should always take all of the available space */

  && > *:not(${BottomContentContainer}):not(${HomeworkCardSettingsContainer}) {
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
  }

  overflow: auto; /* Should scroll, as parent is overflow: hidden */
`

const EditFile = ({
  fileId,
  previewTheme,
  assetContext,
}: {
  fileId: FileId
  previewTheme: CustomThemeName | PresetThemeName | undefined
  containerRef: CommentingContainerRef
  assetContext: AssetContext
}): JSX.Element | null => {
  const { operationState, createContentId, scopedCreateContentId } = useCreatePageContext()
  const { permission } = useCreatePageYDocContext()
  const file = useSelector(state => flexSelectors.selectFlexibleContentFile(state, createContentId, fileId))
  const loaded = useSelector(state => flexSelectors.selectFlexibleContentIsLoaded(state, createContentId))

  const contentType = ScopedCreateContentId.contentType(scopedCreateContentId)

  if (!loaded || !file) return null

  const readOnly = permission !== 'edit'
  return (
    <PolarisCardTheme previewTheme={previewTheme} {...file}>
      <CourseSettingsButton.ExportAsPdfShortcut
        courseId={CourseId.parse(createContentId)}
        contentType={contentType}
      />

      <CreateCardCanvas card={file} key={file.id} assetContext={assetContext}>
        <CreateCardRenderer
          file={file}
          createContentId={createContentId}
          readOnly={readOnly}
          assetContext={assetContext}
          onBreakoutCardFormFieldChanged={change => {
            apply(operationState, {
              type: 'update-files',
              fileIds: [fileId],
              update: file => {
                if (file.data.type !== 'breakout-room') throw Error(`File ${fileId} is not a breakout room`)

                if (change.key === 'algorithm') file.data.settings[change.key] = change.value
                if (change.key === 'duration')
                  file.data.settings.duration = change.value === '' ? undefined : change.value
                if (change.key === 'roomSize') file.data.settings.roomSize = change.value
                if (change.key === 'topic') file.data.topic = change.value === '' ? undefined : change.value
              },
            })
          }}
          setVideoCardDuration={durationInSeconds => {
            apply(operationState, {
              type: 'update-files',
              fileIds: [fileId],
              update: file => {
                if (file.data.type !== 'video') throw Error(`File ${fileId} is not a video`)
                file.data.video.durationInSeconds = durationInSeconds
              },
            })
          }}
          onVideoCardUploadDone={({ url }) => {
            apply(operationState, {
              type: 'update-files',
              fileIds: [fileId],
              update: file => {
                if (file.data.type !== 'video') throw Error(`File ${fileId} is not a video`)
                file.data.video.url = url
              },
            })
          }}
          onImageCardUploadDone={image => {
            apply(operationState, {
              type: 'update-files',
              fileIds: [fileId],
              update: file => {
                if (file.data.type !== 'image') throw Error(`File ${fileId} is not an image`)
                file.data.image = image
              },
            })
          }}
          onEmbedCardUploadDone={(url, embedType) => {
            apply(operationState, {
              type: 'update-files',
              fileIds: [fileId],
              update: file => {
                if (file.data.type !== 'embed') throw Error(`File ${fileId} is not an embed`)
                file.data.url = url
                file.data.embedType = embedType
              },
            })
          }}
          onHomeworkCardUpdate={updates => {
            apply(operationState, {
              type: 'update-files',
              fileIds: [fileId],
              update: file => {
                if (file.data.type !== 'homework') throw Error(`File ${fileId} is not a homework`)

                if ('reviewers' in updates) {
                  file.data.reviewers = updates.reviewers ?? {}
                }

                if ('usersToBeNotified' in updates) {
                  file.data.usersToBeNotified = updates.usersToBeNotified ?? {}
                }

                if ('limitOfSubmissions' in updates) {
                  file.data.limitOfSubmissions = updates.limitOfSubmissions
                }

                if ('submissionType' in updates) {
                  file.data.submissionType = updates.submissionType ?? 'file'
                }

                if ('fileSubmissionSubtype' in updates) {
                  file.data.fileSubmissionSubtype = updates.fileSubmissionSubtype
                }

                if ('criteria' in updates) {
                  file.data.criteria = updates.criteria ?? []
                }
              },
            })
          }}
        />
      </CreateCardCanvas>
    </PolarisCardTheme>
  )
}

const Divider = styled.div`
  width: 1px;
  background: ${p => p.theme.color.grey10};
  height: 1rem;
`

const CommonToolbarWrapper = styled(View)`
  @media screen and (max-width: ${v2_breakpoint.tablet}) {
    display: none;
  }
`

const CommonToolbar: React.FC<{
  createContentId: CreateContentId
  fileId: FileId
  withDivider?: boolean
  narrationStateAtom: PrimitiveAtom<boolean>
  aiNarrationControlsStateAtom: PrimitiveAtom<AINarrationControlsState>
  aiNarrationNudgeStateAtom: PrimitiveAtom<AINarrationNudgeState>
}> = ({
  createContentId,
  fileId,
  withDivider = false,
  narrationStateAtom,
  aiNarrationControlsStateAtom,
  aiNarrationNudgeStateAtom,
}) => {
  const { t } = useTranslation()
  const currentFile = useSelector(state => selectFlexibleContentFile(state, createContentId, fileId))
  const editorContext = useContext(PolarisEditorContext)
  const userId = useSelector(state => selectUserId(state))
  const parentFolderId = useSelector(state =>
    flexSelectors.selectNodeParentFolderId(state, createContentId, fileId)
  )

  const { scopedCreateContentId } = useCreatePageContext()
  const applyReversibleAction = useReversibleEditorAction()

  const contentType = ScopedCreateContentId.contentType(scopedCreateContentId)

  const isSelfPacedContent = contentType === 'self-paced'
  assertIsNonNullable(parentFolderId)
  const { isEnabled: duplicateFileRecentlyFinished, setTrue: setDuplicateFileRecentlyFinished } =
    useResetBooleanAfterDelay()
  const duplicateFile = useDuplicateFileCreatedByUser({
    folderId: parentFolderId,
    nextTo: fileId,
    onSuccess: setDuplicateFileRecentlyFinished,
  })
  const aiNarrationsEnabled = useFlag('ai-narration')

  if (editorContext === undefined || userId === undefined) return null

  return (
    <>
      {isSelfPacedContent &&
        (aiNarrationsEnabled ? (
          <AINarrationsControls
            fileId={fileId}
            contentType={contentType}
            contentId={createContentId}
            aiNarrationControlsStateAtom={aiNarrationControlsStateAtom}
            legacyNarrationStateAtom={narrationStateAtom}
            aiNarrationNudgeStateAtom={aiNarrationNudgeStateAtom}
          />
        ) : (
          <NarrationControls
            fileId={fileId}
            createContentId={createContentId}
            narrationStateAtom={narrationStateAtom}
          />
        ))}
      {withDivider === true && <Divider />}
      <HeaderButtonGroupWrapper gap='none'>
        {duplicateFile.isPending ? (
          <HeaderGroupLoadingSpinner padding='2' />
        ) : (
          <HeaderGroupIconButton
            iconId={duplicateFileRecentlyFinished ? 'checkmark' : 'duplicate'}
            onClick={() => {
              assertIsNonNullable(currentFile)
              duplicateFile.mutate({ originalFile: currentFile })
            }}
            color='currentColor'
            tooltip={t('dictionary.duplicate')}
          />
        )}
        <View background='form/background/2' style={{ height: '100%' }}>
          <HorizontalLineDivider />
        </View>
        <HeaderGroupIconButton
          iconId={'trash-can'}
          onClick={() => {
            assertIsNonNullable(currentFile)

            applyReversibleAction({
              type: 'remove-files',
              files: [currentFile],
            })
          }}
          color='currentColor'
          tooltip={t('dictionary.delete')}
        />
      </HeaderButtonGroupWrapper>
    </>
  )
}

const CustomToolbarSection: React.FC<{
  file: File
  previewThemes?: (theme: undefined | CustomThemeName | PresetThemeName) => void
  createContentId: CreateContentId
}> = ({ file, previewThemes, createContentId }) => {
  const assetContext = { type: 'course' as const, courseId: createContentId }

  switch (file.data.type) {
    case 'drop-a-word':
      return (
        <HeaderButtonGroupWrapper>
          <FileThemePicker previewThemes={previewThemes} file={file} />
        </HeaderButtonGroupWrapper>
      )
    case 'slate-card':
      return <TitleCardToolbar previewThemes={previewThemes} file={file} assetContext={assetContext} />

    case 'bullet':
      return <BulletCardToolbar previewThemes={previewThemes} file={file} />
    case 'poll':
    case 'reflections':
    case 'sliding-scale':
    case 'flip-cards':
      return <ReflectionCardToolbar previewThemes={previewThemes} file={file} assetContext={assetContext} />
    case 'assessment-card':
      return <AssessmentCardToolbar previewThemes={previewThemes} file={file} />
    case 'notepad':
    case 'external-notepad':
    case 'homework':
      return (
        <HeaderButtonGroupWrapper gap='none'>
          <FileThemePicker previewThemes={previewThemes} file={file} />
          <FileBackgroundPicker file={file} assetContext={assetContext} />
        </HeaderButtonGroupWrapper>
      )
    case 'question-card':
      return (
        <HeaderButtonGroupWrapper gap='none'>
          <QuestionCardSettingsToolbar
            previewThemes={previewThemes}
            file={file}
            createContentId={createContentId}
          />
        </HeaderButtonGroupWrapper>
      )
    case 'general':
      return <GeneralCardToolbar previewThemes={previewThemes} file={file} />
    case 'image':
      return <ImageCardToolbar previewThemes={previewThemes} file={file} assetContext={assetContext} />
    case 'embed':
      return (
        <View>
          <EmbedCardToolbar file={file} />
        </View>
      )
    case 'video':
      return (
        <View>
          <VideoControls file={file} />
        </View>
      )
    case 'stupid-questions':
      return <QACardToolbar previewThemes={previewThemes} file={file} />
    case 'scenario':
      return <ScenarioCardToolbar previewThemes={previewThemes} file={file} />
    case 'roleplay':
    case 'live-lobby':
    case 'breakout-room':
    case 'sticky-notes':
    case 'project-card':
      return null
    default:
      assertNever(file.data)
  }
}

const YUndoRedoButtons: React.FC = () => {
  const { operationState } = useCreatePageContext()
  const { yUndoManager } = operationState

  const [canUndo, setCanUndo] = useState(() => yUndoManager.canUndo())
  const [canRedo, setCanRedo] = useState(() => yUndoManager.canRedo())

  useEffect(() => {
    const updateAvailability = (): void => {
      setCanUndo(yUndoManager.canUndo())
      setCanRedo(yUndoManager.canRedo())
    }

    yUndoManager.on('stack-item-updated', updateAvailability)
    yUndoManager.on('stack-item-added', updateAvailability)
    yUndoManager.on('stack-item-popped', updateAvailability)
    yUndoManager.on('stack-cleared', updateAvailability)

    return () => {
      yUndoManager.off('stack-item-updated', updateAvailability)
      yUndoManager.off('stack-item-added', updateAvailability)
      yUndoManager.off('stack-item-popped', updateAvailability)
      yUndoManager.off('stack-cleared', updateAvailability)
    }
  }, [yUndoManager])

  return (
    <View>
      <IconButton iconId='undo' disabled={!canUndo} onClick={() => undo(operationState)} />
      <IconButton iconId='redo' disabled={!canRedo} onClick={() => redo(operationState)} />
    </View>
  )
}

const FileToolbar: React.FC<{
  previewThemes?: (theme: undefined | CustomThemeName | PresetThemeName) => void
  narrationStateAtom: PrimitiveAtom<boolean>
  aiNarrationControlsStateAtom: PrimitiveAtom<AINarrationControlsState>
  aiNarrationNudgeStateAtom: PrimitiveAtom<AINarrationNudgeState>
}> = ({ previewThemes, narrationStateAtom, aiNarrationControlsStateAtom, aiNarrationNudgeStateAtom }) => {
  const { createContentId } = useCreatePageContext()
  const { nodeId } = useCreatePageNodeIdContext()
  const { permission } = useCreatePageYDocContext()
  const file = useSelector(state =>
    nodeId !== undefined ? flexSelectors.selectFlexibleContentFile(state, createContentId, nodeId) : undefined
  )

  if (permission !== 'edit') return null

  return (
    <CommonToolbarWrapper gap='8'>
      {file !== undefined && (
        <CustomToolbarSection file={file} previewThemes={previewThemes} createContentId={createContentId} />
      )}
      <Debug>
        <YUndoRedoButtons />
      </Debug>
      {file !== undefined && (
        <CommonToolbar
          createContentId={createContentId}
          fileId={file.id}
          narrationStateAtom={narrationStateAtom}
          aiNarrationControlsStateAtom={aiNarrationControlsStateAtom}
          aiNarrationNudgeStateAtom={aiNarrationNudgeStateAtom}
        />
      )}
    </CommonToolbarWrapper>
  )
}

const FileView: React.FC<{
  fileId: FileId
  previewTheme: undefined | CustomThemeName | PresetThemeName
  legacyNarrationStateAtom: PrimitiveAtom<boolean>
  aiNarrationControlsStateAtom: PrimitiveAtom<AINarrationControlsState>
  aiNarrationNudgeStateAtom: PrimitiveAtom<AINarrationNudgeState>
  narrationMetadata: NarrationMetadata | undefined
  narrationSettings: NarrationSettings | undefined
  operationState: CreateOperationState
  createContentId: CreateContentId
  contentType: ContentType
  containerRef: React.RefObject<HTMLDivElement>
}> = ({
  fileId,
  previewTheme,
  legacyNarrationStateAtom,
  aiNarrationControlsStateAtom,
  aiNarrationNudgeStateAtom,
  operationState,
  narrationMetadata,
  narrationSettings,
  containerRef,
}) => {
  const { createContentId, contentType } = useCreatePageContext()
  const narrationIsOpen = useAtomValue(legacyNarrationStateAtom)
  const aiNarrationsEnabled = useFlag('ai-narration')

  const card = useSelector(state => selectFlexibleContentFile(state, createContentId, fileId))

  const isGeneratingVideo = narrationMetadata?.type === 'loading'
  const showNarrationCard = isGeneratingVideo || narrationIsOpen || card?.narration !== undefined

  const aiNarrationNudgeState = useAtomValue(aiNarrationNudgeStateAtom)

  const quotaNotExceeded =
    narrationSettings?.usage !== undefined
      ? narrationSettings.usage.minutesUsed < narrationSettings.quota
      : true

  const showNarrationNudge =
    contentType === 'self-paced' &&
    quotaNotExceeded &&
    aiNarrationsEnabled &&
    !showNarrationCard &&
    aiNarrationNudgeState[createContentId]?.shown !== true

  const assetContext = { type: 'course' as const, courseId: createContentId }

  return (
    <>
      <EditFile
        previewTheme={previewTheme}
        key={fileId}
        fileId={fileId}
        containerRef={containerRef}
        assetContext={assetContext}
      />
      {showNarrationCard && (
        <CardNarration
          createContentId={createContentId}
          fileId={fileId}
          narrationStateAtom={legacyNarrationStateAtom}
          assetContext={assetContext}
        />
      )}
      {narrationSettings && (
        <NarrationNudge
          show={showNarrationNudge}
          aiNarrationNudgeStateAtom={aiNarrationNudgeStateAtom}
          aiNarrationControlsStateAtom={aiNarrationControlsStateAtom}
          avatars={narrationSettings.avatars}
          operationState={operationState}
          fileId={fileId}
          contentId={createContentId}
          contentType={contentType}
        />
      )}
    </>
  )
}

const AuthorCardView = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1;

  background-color: ${token('surface/default')};
  overflow: hidden;
`

const NodeView = ({
  nodeId,
  previewTheme,
  legacyNarrationStateAtom,
  aiNarrationControlsStateAtom,
  aiNarrationNudgeStateAtom,
  operationState,
  narrationMetadata,
  narrationSettings,
  createContentId,
  contentType,
}: {
  nodeId: NodeId
  previewTheme: undefined | CustomThemeName | PresetThemeName
  legacyNarrationStateAtom: PrimitiveAtom<boolean>
  aiNarrationControlsStateAtom: PrimitiveAtom<AINarrationControlsState>
  aiNarrationNudgeStateAtom: PrimitiveAtom<AINarrationNudgeState>
  operationState: CreateOperationState
  createContentId: CreateContentId
  contentType: ContentType
  narrationMetadata: NarrationMetadata | undefined
  narrationSettings: NarrationSettings | undefined
}): JSX.Element => {
  const containerRef = useRef(document.createElement('div'))

  return (
    <AuthorCardView ref={containerRef}>
      {(() => {
        if (guardWith(FolderId, nodeId)) {
          return `TODO: Handle this case: Edit folder (${nodeId})`
        }

        if (guardWith(FileId, nodeId)) {
          return (
            <FileView
              fileId={nodeId}
              previewTheme={previewTheme}
              legacyNarrationStateAtom={legacyNarrationStateAtom}
              aiNarrationControlsStateAtom={aiNarrationControlsStateAtom}
              aiNarrationNudgeStateAtom={aiNarrationNudgeStateAtom}
              narrationMetadata={narrationMetadata}
              operationState={operationState}
              createContentId={createContentId}
              contentType={contentType}
              containerRef={containerRef}
              narrationSettings={narrationSettings}
            />
          )
        }

        if (guardWith(LinkId, nodeId)) {
          return `TODO: Handle this case: Edit link (${nodeId})`
        }

        return `Unexpected nodeId (${nodeId})`
      })()}
    </AuthorCardView>
  )
}

const NoCardsLabel: React.FC = () => {
  const { t } = useTranslation()

  return (
    <AuthorCardView>
      <View direction='column' alignItems='center'>
        <Icon iconId='face' size='size-16' color='foreground/muted' />
        <Text size='small' color='foreground/muted'>
          {t('admin.author.no-cards')}
        </Text>
      </View>
    </AuthorCardView>
  )
}

type PreviewTheme = CustomThemeName | PresetThemeName

const _CreateContent: React.FC<{
  previewTheme: PreviewTheme | undefined
  legacyNarrationStateAtom: PrimitiveAtom<boolean>
  aiNarrationControlsStateAtom: PrimitiveAtom<AINarrationControlsState>
  aiNarrationNudgeStateAtom: PrimitiveAtom<AINarrationNudgeState>
  operationState: CreateOperationState
  createContentId: CreateContentId
  contentType: ContentType
  narrationMetadata: NarrationMetadata | undefined
  narrationSettings: NarrationSettings | undefined
}> = ({
  previewTheme,
  legacyNarrationStateAtom,
  aiNarrationControlsStateAtom,
  aiNarrationNudgeStateAtom,
  operationState,
  createContentId,
  contentType,
  narrationMetadata,
  narrationSettings,
}) => {
  const { nodeId } = useCreatePageNodeIdContext()
  if (nodeId !== undefined)
    return (
      <NodeView
        aiNarrationControlsStateAtom={aiNarrationControlsStateAtom}
        aiNarrationNudgeStateAtom={aiNarrationNudgeStateAtom}
        narrationSettings={narrationSettings}
        legacyNarrationStateAtom={legacyNarrationStateAtom}
        operationState={operationState}
        nodeId={nodeId}
        previewTheme={previewTheme}
        narrationMetadata={narrationMetadata}
        contentType={contentType}
        createContentId={createContentId}
      />
    )
  else return <NoCardsLabel />
}
const CreateContent = React.memo(_CreateContent)

const CreateChat: React.FC<{
  chatOpen: boolean
  chatHandlers: ToggleHandlers
}> = ({ chatOpen, chatHandlers }) => {
  const { chatId, chatIdentifier } = useCreatePageContext()
  const [chatThread, setChatThread] = useState<string>()
  const commentBrowsingReason = useSelector(state => selectCurrentBrowsing(state)?.reason)
  const dispatch = useDispatch()
  const browsingComment = useSelector(state => selectCurrentBrowsing(state)?.comment)
  const browsingCommmentId = useSelector(state => selectCurrentBrowsing(state)?.comment.id)
  const [handledBrowsedIds, setHandledBrowseIds] = useState<string[]>([])

  useEffect(() => {
    if (
      browsingComment !== undefined &&
      commentBrowsingReason === 'deeplink' &&
      !handledBrowsedIds.includes(browsingCommmentId ?? '')
    ) {
      chatHandlers.on()
      setChatThread(browsingCommmentId)
      setHandledBrowseIds([...handledBrowsedIds, browsingCommmentId ?? ''])
    }
  }, [
    browsingComment,
    browsingCommmentId,
    chatHandlers,
    chatOpen,
    commentBrowsingReason,
    dispatch,
    handledBrowsedIds,
  ])

  return (
    <>
      <CommentingQueryListener chatId={chatId} />

      <EditorChat
        open={chatOpen}
        chatId={chatId}
        chatIdentifier={chatIdentifier}
        onClose={chatHandlers.off}
        threadId={chatThread}
        changeThreadId={setChatThread}
      />
    </>
  )
}

const Header: React.FC<{
  chatHandlers: ToggleHandlers
  setPreviewTheme: (_: PreviewTheme | undefined) => void
  toggleSidebar: () => void
  sidebarOpen: boolean
  legacyNarrationStateAtom: PrimitiveAtom<boolean>
  aiNarrationControlsStateAtom: PrimitiveAtom<AINarrationControlsState>
  aiNarrationNudgeStateAtom: PrimitiveAtom<AINarrationNudgeState>
}> = ({
  chatHandlers,
  setPreviewTheme,
  legacyNarrationStateAtom,
  aiNarrationControlsStateAtom,
  aiNarrationNudgeStateAtom,
  ...rest
}) => {
  const { createContentId } = useCreatePageContext()
  const { nodeId } = useCreatePageNodeIdContext()

  return (
    <CreatePageTopbar
      createContentId={createContentId}
      toggleChat={chatHandlers.toggle}
      cardToolbar={
        nodeId === undefined ? null : (
          <FileToolbar
            previewThemes={setPreviewTheme}
            narrationStateAtom={legacyNarrationStateAtom}
            aiNarrationControlsStateAtom={aiNarrationControlsStateAtom}
            aiNarrationNudgeStateAtom={aiNarrationNudgeStateAtom}
          />
        )
      }
      {...rest}
    />
  )
}

const EditorContext: FCC<{ fileId: FileId | undefined }> = ({ fileId, children }) => {
  const { yDoc, awareness, permission, yDocId } = useCreatePageYDocContext()
  const pasteFile = usePasteFile({ nextTo: fileId })

  if (fileId === undefined) return <>{children}</>
  return (
    <PolarisEditorProvider
      yDocId={yDocId}
      yDoc={yDoc}
      awareness={awareness}
      pasteFile={pasteFile}
      fileId={fileId}
      readOnly={permission !== 'edit'}
    >
      {children}
    </PolarisEditorProvider>
  )
}

const ObservabilityTags = (): null => {
  const { scopedCreateContentId } = useCreatePageContext()
  const { permission } = useCreatePageYDocContext()
  const { nodeId } = useCreatePageNodeIdContext()
  const canEdit = permission === 'edit'

  useObservabilityTags({
    createContentId: scopedCreateContentId,
    nodeId,
    canEdit: canEdit.toString(),
    permission,
  })

  return null
}

const ShadowedColumn = styled(Column)`
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  overflow: hidden;
`

export const ContentModelCreatePage: React.FC = () => {
  const sidebarInitiallyOpen = useSidebarShouldOverlayContent()
  const [sidebarOpen, { toggle }] = useToggle(!sidebarInitiallyOpen)
  const [chatOpen, chatHandlers] = useToggle(false)
  const [previewTheme, setPreviewTheme] = useState<undefined | CustomThemeName | PresetThemeName>(undefined)
  const { scopedCreateContentId, createContentId, operationState, contentType } = useCreatePageContext()
  const { t } = useTranslation()
  const { permission } = useCreatePageYDocContext()

  const [narrationState, setNarrationState] = useState<NarrationState | undefined>(undefined)

  const newCourseSettingsModalEnabled = useFlag('new-course-settings-modal')

  const { nodeId: selectedNodeId } = useCreatePageNodeIdContext()

  const narrationMetadata =
    selectedNodeId !== undefined ? narrationState?.narrations[selectedNodeId] : undefined

  const legacyNarrationState = useMemo(() => atom(false), [])

  const [aiNarrationControlsStateAtom] = useState(() => atom<AINarrationControlsState>({ type: 'closed' }))
  const [aiNarrationState, setAiNarrationState] = useAtom(aiNarrationControlsStateAtom)
  const [aiNarrationNudgeStateAtom] = useState(() =>
    atomWithStorage(
      'narration-nudge-shown-for-course',
      {},
      z.record(z.string(), z.object({ shown: z.boolean() })),
      { getOnInit: true }
    )
  )

  // Since the chat and ai narrations both share the same side panel, we do not want both to be open at the same time.
  useOnChanged((_oldChatOpen, newChatOpen) => {
    if (newChatOpen && aiNarrationState.type === 'open') {
      setAiNarrationState({ type: 'closed' })
    }
  }, chatOpen)
  useOnChanged((_oldAiNarrationOpen, newAiNarrationOpen) => {
    if (newAiNarrationOpen.type === 'open' && chatOpen) {
      chatHandlers.off()
    }
  }, aiNarrationState)

  const isSelfPacedContentId = ScopedCreateContentId.isSelfPacedId(scopedCreateContentId)
  const aiNarrationsOpen =
    selectedNodeId !== undefined &&
    aiNarrationState.type === 'open' &&
    isSelfPacedContentId &&
    narrationState !== undefined

  const flexibleContentId = useSafeFlexibleContentId()
  const assetContext =
    flexibleContentId !== undefined
      ? { type: 'course' as const, courseId: flexibleContentId }
      : { type: 'unknown' as const }

  return (
    <>
      <ObservabilityTags />

      {newCourseSettingsModalEnabled ? <NewCourseSettingsModal /> : <CourseSettingsModal />}

      <TrackVisitedContent scopedId={scopedCreateContentId} type='editor' fileId={selectedNodeId} />

      <NarrationStateSync
        contentId={createContentId}
        canEdit={permission === 'edit'}
        contentType={contentType}
        setNarrationState={setNarrationState}
      />

      <EditorContext fileId={selectedNodeId}>
        <CustomInterfaceThemeProvider>
          <Layout>
            <Header
              chatHandlers={chatHandlers}
              setPreviewTheme={setPreviewTheme}
              sidebarOpen={sidebarOpen}
              toggleSidebar={toggle}
              legacyNarrationStateAtom={legacyNarrationState}
              aiNarrationControlsStateAtom={aiNarrationControlsStateAtom}
              aiNarrationNudgeStateAtom={aiNarrationNudgeStateAtom}
            />
            <ColumnContainer gap='8' padding='8' paddingLeft='none'>
              <ShadowedColumn disableScrollbarGutter={true}>
                <FullHeightWithPadding>
                  <CreatePageErrorBoundary>
                    <CreateToastRenderer />
                    <CreateContent
                      previewTheme={previewTheme}
                      aiNarrationControlsStateAtom={aiNarrationControlsStateAtom}
                      legacyNarrationStateAtom={legacyNarrationState}
                      narrationMetadata={narrationMetadata}
                      narrationSettings={narrationState?.settings}
                      createContentId={createContentId}
                      contentType={contentType}
                      aiNarrationNudgeStateAtom={aiNarrationNudgeStateAtom}
                      operationState={operationState}
                    />
                    {selectedNodeId !== undefined && (
                      <DataHistorySwitch contentId={scopedCreateContentId} fileId={selectedNodeId} />
                    )}
                  </CreatePageErrorBoundary>
                </FullHeightWithPadding>
              </ShadowedColumn>
              <FoldingColumn
                open={chatOpen || aiNarrationsOpen}
                width={aiNarrationsOpen ? 496 : 294}
                disableScrollbarGutter={true}
              >
                <FullHeightWithPadding>
                  {aiNarrationsOpen ? (
                    <AINarrations
                      fileId={selectedNodeId}
                      scopedCreateContentId={scopedCreateContentId}
                      operationState={operationState}
                      legacyNarrationStateAtom={legacyNarrationState}
                      aiNarrationControlStateAtom={aiNarrationControlsStateAtom}
                      narrationMetadata={narrationMetadata}
                      narrationSettings={narrationState.settings}
                      assetContext={assetContext}
                    />
                  ) : (
                    <CreateChat chatOpen={chatOpen} chatHandlers={chatHandlers} />
                  )}
                </FullHeightWithPadding>
              </FoldingColumn>
            </ColumnContainer>
            <PaginatedNewsModal
              useOnceKey={'textActionsAndCompletionLaunchModal'}
              useAiImageBackground
              content={[
                {
                  title: t('product-update-modal.text-actions.header'),
                  imagePath: 'images/custom-instructions-transparent.png',
                  body: [t('product-update-modal.text-actions.body')],
                },
                {
                  title: t('product-update-modal.inline-text-completion.header'),
                  imagePath: 'images/inline-transparent.png',
                  body: [t('product-update-modal.inline-text-completion.body')],
                },
              ]}
            />
          </Layout>
        </CustomInterfaceThemeProvider>
      </EditorContext>
    </>
  )
}

import React from 'react'
import { getFileContentImage } from 'sierra-client/api/content'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { DataKey, useOnce } from 'sierra-client/hooks/use-once'
import { cardBackgroundStyles } from 'sierra-client/views/flexible-content/card-background'
import { Modal } from 'sierra-ui/components'
import { Button, Heading, IconButton, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 4rem;
  max-width: 35rem;
`

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 40ch;
`

const Image = styled.div<{ $background?: string }>`
  width: 100%;
  max-width: 35rem;
  aspect-ratio: 2 / 1;

  ${cardBackgroundStyles}
`

const Video = styled.video`
  width: 100%;
  max-width: 35rem;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
`

const IconContainer = styled.div`
  position: relative;
`

const CloseIcon = styled(IconButton).attrs({
  iconId: 'close',
  variant: 'transparent',
})`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 3;
`

type BasicNewsModalProps = {
  useOnceKey: DataKey
  title: string
  body: string[]
  action: string
  secondaryAction?: string
  onSecondary?: () => void
  imagePath?: string
  videoSrc?: string
  titleSecondary?: string
}

export const BasicNewsModal: React.FC<BasicNewsModalProps> = ({
  title,
  body,
  action,
  secondaryAction,
  onSecondary,
  useOnceKey,
  imagePath,
  videoSrc,
  titleSecondary,
}) => {
  const { showOnce, setSeen } = useOnce(useOnceKey)
  const showNewsModal = useFlag('news-modal')

  const imageSrc =
    imagePath !== undefined
      ? getFileContentImage(imagePath, {
          optimize: false,
          bucket: 'sierra-static',
        })
      : undefined

  if (!showOnce || showNewsModal === false) return null

  return (
    <Modal disableScrollbarGutter open onClose={setSeen} size='fit-content'>
      <IconContainer>
        <CloseIcon onClick={setSeen} />
      </IconContainer>
      {imageSrc !== undefined && <Image $background={imageSrc} />}
      {videoSrc !== undefined && (
        <Video muted autoPlay loop>
          <source src={videoSrc} />
        </Video>
      )}
      <ModalContent>
        <Container>
          <Heading size='h5' bold align='center'>
            {title}
          </Heading>
          {titleSecondary !== undefined && (
            <Heading size='h5' bold>
              {titleSecondary}
            </Heading>
          )}
          <Spacer />
          <View direction='column' gap='xxsmall'>
            {body.map((text, i) => (
              <Text size='regular' key={i} color='foreground/secondary'>
                {text}
              </Text>
            ))}
          </View>
          <Spacer size='large' />
          <View>
            <Button variant='secondary' onClick={setSeen}>
              {action}
            </Button>
            {secondaryAction !== undefined && onSecondary !== undefined && (
              <Button
                variant='primary'
                onClick={() => {
                  onSecondary()
                  setSeen()
                }}
              >
                {secondaryAction}
              </Button>
            )}
          </View>
        </Container>
      </ModalContent>
    </Modal>
  )
}

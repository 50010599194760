import { useAtomValue } from 'jotai'
import React, { useMemo } from 'react'
import { layoutValues } from 'sierra-client/config/layout-values'
import { SlidingHeaderProps, useSlidingHeader } from 'sierra-client/layout/header/hooks'
import { responsiveHorizontalCSS } from 'sierra-client/layout/styles/content'
import { HeaderType, headerTypeAtom, useUserMenuIsOpen } from 'sierra-client/state/header'
import { MUIAppBar } from 'sierra-ui/mui'
import { zIndex } from 'sierra-ui/theming'
import { v2_breakpoint, v2_breakpoint_int } from 'sierra-ui/theming/breakpoints'
import { darkTransparent, legacyLight } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeProvider, css } from 'styled-components'

type HeaderWrapperProps = {
  className?: string
  children: React.ReactNode | React.ReactNode[]
  $sidebarOpen?: boolean
  $fullWidth?: boolean
  $overrideHeight?: string
}

const StyledAppBar = styled(
  ({
    className,
    $overrideHeight,
    ...rest
  }: HeaderWrapperProps & { position: 'fixed' | 'absolute' } & SlidingHeaderProps & {
      $isUserMenuOpen: boolean
    }) => <MUIAppBar classes={{ root: className }} {...rest} />
)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zIndex.MENU};
  width: 100%;

  height: ${p => p.$overrideHeight ?? layoutValues.TOP_BAR_BASE_MOBILE_HEIGHT};
  min-height: ${p => p.$overrideHeight ?? layoutValues.TOP_BAR_BASE_MOBILE_HEIGHT};

  background-color: ${p => p.theme.header.background};
  border-bottom: 1px solid;
  color: ${p => p.theme.text.default};
  border-color: ${p => p.theme.header.border};
  justify-content: center;

  transform: translateY(-101%);
  transition:
    background-color 0.1s ease-out 0.12s,
    border-color 0.2s ease,
    transform 0s ease,
    width 225ms cubic-bezier(0, 0, 0.2, 1),
    margin 225ms cubic-bezier(0, 0, 0.2, 1);
  box-shadow: none;

  padding-top: 1rem;
  padding-bottom: 1rem;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  @media screen and (min-width: ${v2_breakpoint.desktop_small}) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    height: ${p => p.$overrideHeight ?? layoutValues.TOP_BAR_BASE_HEIGHT};
  }

  @media screen and (max-width: ${v2_breakpoint_int.phone - 1}px) {
    min-width: 100vw;
  }

  ${({ $isFixed, $isUserMenuOpen }) =>
    $isFixed && !$isUserMenuOpen
      ? css`
          position: fixed;
          transition:
            background-color 0.15s ease-out 0.2s,
            border-color 0.2s ease,
            transform 0.2s ease,
            width 225ms cubic-bezier(0, 0, 0.2, 1),
            margin 225ms cubic-bezier(0, 0, 0.2, 1);
          background-color: ${p => p.theme.header.background};
        `
      : css`
          transition:
            background-color 0s ease-out 0s,
            border-color 0.2s ease,
            transform 0.2s ease,
            width 225ms cubic-bezier(0, 0, 0.2, 1),
            margin 225ms cubic-bezier(0, 0, 0.2, 1);
        `}

  ${({ $shouldSlideDown, $isUserMenuOpen, $isFixed }) =>
    $shouldSlideDown || $isUserMenuOpen || !$isFixed
      ? css`
          transform: translateY(0);
        `
      : ''}

  ${({ $isUserMenuOpen }) =>
    $isUserMenuOpen &&
    css`
      position: fixed;
      transition:
        background-color 0s ease-out 0s,
        border-color 0.2s ease,
        transform 0s ease,
        width 225ms cubic-bezier(0, 0, 0.2, 1),
        margin 225ms cubic-bezier(0, 0, 0.2, 1);
    `}

  ${({ $isNoTransition }) =>
    $isNoTransition &&
    css`
      transition: all 0s linear;
    `}

  ${({ $sidebarOpen }) =>
    $sidebarOpen ?? false
      ? css`
          width: calc(100% - 20rem);
          margin-left: 20rem;

          @media screen and (max-width: 22rem) {
            margin-left: calc(100vw - 3rem);
          }
        `
      : css`
          margin-left: 0;
          width: 100%;
        `}
`

const ContentWrapper = styled.div<{ $fullWidth?: boolean }>`
  ${responsiveHorizontalCSS}

  ${p =>
    p.$fullWidth === true &&
    css`
      max-width: 100%;
    `}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const getHeaderTheme = (headerType: HeaderType, isUserMenuOpen: boolean | undefined): typeof legacyLight => {
  // We'll always want the white theme when the user menu or search is open
  if (isUserMenuOpen ?? false) {
    return legacyLight
  }

  switch (headerType) {
    case 'auth':
      return darkTransparent
    case 'none':
    default:
      // Light theme is the default
      return legacyLight
  }
}

export const HeaderWrapper = ({ children, $fullWidth, ...props }: HeaderWrapperProps): JSX.Element => {
  const headerType = useAtomValue(headerTypeAtom)
  const isMenuOpen = useUserMenuIsOpen()
  const headerTheme = useMemo(() => getHeaderTheme(headerType, isMenuOpen), [headerType, isMenuOpen])

  const { $isFixed, $shouldSlideDown, $isNoTransition } = useSlidingHeader()

  return (
    <ThemeProvider theme={headerTheme}>
      <StyledAppBar
        position={$isFixed ? 'fixed' : 'absolute'}
        $isUserMenuOpen={isMenuOpen}
        $isFixed={$isFixed}
        $shouldSlideDown={$shouldSlideDown}
        $isNoTransition={$isNoTransition}
        {...props}
      >
        <ContentWrapper $fullWidth={$fullWidth}>{children}</ContentWrapper>
      </StyledAppBar>
    </ThemeProvider>
  )
}

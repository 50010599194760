import { useCallback, useMemo, useState } from 'react'
import { DomainRep, Filter, filterEmptyFilters, filteredByDomainReps, orAll } from 'sierra-client/lib/filter'
import { Context } from 'sierra-client/lib/filter/components/common'
import { jsonWithZodSerializer, useQueryState } from 'sierra-client/lib/querystate/use-query-state'
import { FilterGenerateDomain } from 'sierra-domain/api/filter'

export type UseFilter = {
  ctx: Context
  filter: Filter
  domain?: FilterGenerateDomain
}

const filterQueryStateSerializer = jsonWithZodSerializer(Filter)

export const useFilter = ({
  initialFilter,
  domainReps,
  domain,
}: {
  initialFilter: Filter
  domainReps: DomainRep[]
  domain?: FilterGenerateDomain
}): UseFilter => {
  // We filter out all the filters that are not applicable to the domainReps
  const [filter, setFilter] = useState(() => filteredByDomainReps({ filter: initialFilter, domainReps }))

  const ctx = useMemo<Context>(
    () => ({
      domainReps,
      update: modify => setFilter(f => filterEmptyFilters(modify(f), false)),
      remove: () => setFilter(orAll([])),
    }),
    [domainReps]
  )

  return { ctx, filter, domain }
}

export const useFilterWithQueryState = (
  initFilter: Filter,
  domainReps: DomainRep[] | undefined,
  domain?: FilterGenerateDomain,
  queryStateKey = 'filter'
): UseFilter => {
  // Initial validation of the filter once read from URL
  // We filter out all the filters that are not applicable to the domainReps
  const initTransform = useCallback(
    (f: Filter) => filteredByDomainReps({ filter: f, domainReps: domainReps ?? [] }),
    [domainReps]
  )
  const [filter, update] = useQueryState(filterQueryStateSerializer, initFilter, queryStateKey, initTransform)

  const ctx: Context = useMemo(
    () => ({
      domainReps: domainReps ?? [],
      update: modify => update(f => filterEmptyFilters(modify(f), false)),
      remove: () => update(orAll([])),
    }),
    [domainReps, update]
  )

  return { ctx, filter, domain }
}

import { useMemo } from 'react'
import { ShortcutMenu, ShortcutMenuSettingsItem } from 'sierra-client/components/shortcut-menu'
import { useShortcutMenuDispatch } from 'sierra-client/components/shortcut-menu/context'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  CardMiniature,
  CardMiniatureConfig,
  getCardTypeName,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/card-miniature'
import { IconButton, ScrollView, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const CardContainerView = styled(ScrollView)`
  position: relative;
  padding-bottom: 1.5rem;

  > * {
    &:first-child {
      margin-left: 1.5rem;
    }

    &:last-child {
      margin-right: 1.5rem;
    }
  }
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 0.5rem;
  top: 1rem;
`

export const HorizontalCardPickerShortcut: React.FC<{ cards: CardMiniatureConfig[] }> = ({ cards }) => {
  const dispatch = useShortcutMenuDispatch()
  const { t } = useTranslation()
  const shortcutCardItems: ShortcutMenuSettingsItem[] = useMemo(
    () =>
      cards.map(card => ({
        icon: card.icon,
        id: getCardTypeName(card, t),
        label: getCardTypeName(card, t),
      })),
    [cards, t]
  )

  return (
    <ShortcutMenu.Settings
      label='create.home.add-card'
      group='create'
      iconId='add'
      permission='ACCESS_EDITOR'
      items={shortcutCardItems}
      onItemSelected={({ id: name }) => {
        void cards.find(card => getCardTypeName(card, t) === name)?.onClick()
        void dispatch({ type: 'close' })
      }}
    />
  )
}

export const HorizontalCardPicker: React.FC<{
  onClose: () => void
  cards: CardMiniatureConfig[]
}> = ({ onClose, cards }) => {
  const { t } = useTranslation()

  const getCardKey = (card: CardMiniatureConfig): string => {
    if (card.fileType !== 'embed' || card.embedType === undefined) return card.fileType
    else return card.embedType
  }

  return (
    <View direction='column' paddingTop='xsmall' background='surface/default'>
      <View padding='none small'>
        <Text size='small' bold>
          {t('create.home.all-cards')}
        </Text>
        <StyledIconButton
          iconId='close'
          onClick={onClose}
          size='small'
          variant='transparent'
          color='foreground/muted'
        />
      </View>
      <CardContainerView disableScrollbarGutter direction='row' gap='xsmall'>
        {cards.map(card => (
          <CardMiniature key={getCardKey(card)} card={card} onClick={onClose} />
        ))}
      </CardContainerView>
    </View>
  )
}

import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { FileType, UrlType } from 'sierra-domain/flexible-content/types'

export const fileNames: Record<FileType, TranslationKey> = {
  'slate-card': 'author.slate-card.title',
  'bullet': 'author.bullet.title',
  'live-lobby': 'author.lobby.title',
  'reflections': 'dictionary.reflection',
  'poll': 'dictionary.poll',
  'sliding-scale': 'dictionary.sliding-scale',
  'video': 'dictionary.video',
  'image': 'author.image.title',
  'embed': 'dictionary.embed',
  'general': 'dictionary.page',
  'notepad': 'author.notepad.title',
  'external-notepad': 'author.notepad.title',
  'question-card': 'author.question-card.title',
  'breakout-room': 'author.breakout-rooms.title',
  'sticky-notes': 'author.sticky-notes.title',
  'assessment-card': 'assessment-card.assessment',
  'flip-cards': 'author.slate.flip-cards',
  'homework': 'dictionary.homework-singular',
  'project-card': 'author.project-card.title',
  'drop-a-word': 'dictionary.drop-a-word',
  'stupid-questions': 'question-answers.title',
  'scenario': 'dictionary.scenario',
  'roleplay': 'dictionary.roleplay',
}

export const embedFileNames: Record<UrlType, string> = {
  airtable: 'Airtable',
  figma: 'Figma',
  drive: 'Google Drive',
  loom: 'Loom',
  miro: 'Miro',
  pitch: 'Pitch',
  trello: 'Trello',
  youtube: 'Youtube',
  vimeo: 'Vimeo',
}

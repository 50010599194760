import { FC } from 'react'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { useUserLegacy } from 'sierra-client/state/users/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { UserId } from 'sierra-domain/api/uuid'
import { RoundAvatar, Tooltip } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

const noteWidthRem = 15

export const SectionHeader = styled(View)`
  ${fonts.heading.h5};
  font-weight: 500;
  margin-top: 3rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
`

export const SectionText = styled.p`
  ${fonts.heading.h5}
  font-weight: 500;
  color: #000;
  border-radius: 0.125rem;
  margin-left: 0.25rem;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`

export const SectionGrid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, ${noteWidthRem}rem);
  list-style: none;
  margin: 0;
`

export const BaseNoteContainer = styled.div<{ $color?: string }>`
  position: relative;
  width: ${noteWidthRem}rem;
  height: ${noteWidthRem}rem;
  background-color: ${p => p.$color ?? palette.grey[2]};
  /* We set the color explicitly so that it works when dragging. */
  color: ${palette.primitives.black};
`

export const NoteBody = styled.div`
  position: absolute;
  inset: 1.5rem;
  bottom: 4rem;
`

export const NoteFooter = styled(View).attrs({ gap: 'none' })`
  position: absolute;
  bottom: 1.25rem;
  left: 1.5rem;
  right: 1.5rem;
  height: 1.75rem;
`

export const FooterText = styled.div<{ $isMe: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${fonts.body.micro};
  font-weight: 500;
  color: ${p => (p.$isMe === true ? 'black' : 'rgba(0, 0, 0, 0.25)')};
`

export const Avatar: FC<{ userId?: UserId }> = ({ userId }) => {
  const myUser = useSelector(selectUser)
  const otherUser = useUserLegacy(userId)
  const user = userId === undefined ? myUser : otherUser
  const isMe = user?.uuid === myUser?.uuid

  return (
    <Tooltip title={`${user?.firstName} ${user?.lastName}`}>
      <View gap='xxsmall'>
        <RoundAvatar
          size='small'
          firstName={user?.firstName}
          lastName={user?.lastName}
          src={getAvatarImage(user?.uuid, user?.avatar)}
          color={user?.avatarColor}
        />
        <FooterText $isMe={isMe}>{isMe ? 'You' : user?.firstName}</FooterText>
      </View>
    </Tooltip>
  )
}

import { createFileRoute } from '@tanstack/react-router'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { useDeveloperToolsEnabled } from 'sierra-client/hooks/use-developer-tools-enabled'
import { useLearnerTheme } from 'sierra-client/hooks/use-learner-theme'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { ProgramPage } from 'sierra-client/views/learner/program/program-page'
import { ProgramId } from 'sierra-domain/api/uuid'
import { ThemeProvider } from 'styled-components'
import { z } from 'zod'

const ProgramRoutePage = (): JSX.Element | null => {
  const programId = Route.useParams({ select: params => params.programId })
  const learnerTheme = useLearnerTheme()
  const isDev = useDeveloperToolsEnabled()

  return (
    <>
      <SanaPage mode='light' showIntercomLauncher={true} page={PageIdentifier.LearnerProgram({ programId })}>
        <ThemeProvider theme={learnerTheme}>
          <ProgramPage programId={programId} />
        </ThemeProvider>
      </SanaPage>

      {isDev && (
        <ShortcutMenu.Link
          label={{ type: 'untranslated', value: `Open program's admin page` }}
          href={`/manage/programs/${programId}`}
          iconId='arrow--right'
          permission='ACCESS_LMS'
        />
      )}
    </>
  )
}

export const Route = createFileRoute('/program/$programId/')({
  component: requireLoggedIn(ProgramRoutePage, { allowScorm: true }) as React.FC,
  params: {
    parse: z.object({ programId: ProgramId }).parse,
    stringify: p => p,
  },
})

import _ from 'lodash'
import { useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { ManageSettingsResponse, NewIntegrationDefault } from 'sierra-domain/api/search'
import {
  XRealtimeManageSearchSettingsGetDefaultSetting,
  XRealtimeManageSearchSettingsGetSettings,
} from 'sierra-domain/routes'

export function useSearchSettings(): {
  searchSettings: ManageSettingsResponse | undefined
  defaultSetting: NewIntegrationDefault | undefined
  searchWithIntegrations: boolean
} {
  const { postWithUserErrorException } = usePost()
  const [searchSettings, setSearchSettings] = useState<ManageSettingsResponse>()
  const [defaultSetting, setDefaultSetting] = useState<NewIntegrationDefault>()

  const searchWithIntegrations =
    searchSettings !== undefined &&
    (searchSettings.drive.connectFlag === true || searchSettings.microsoft.connectFlag === true)

  const [call] = useState(() =>
    _.debounce(async (): Promise<void> => {
      try {
        const searchManageSettings = await postWithUserErrorException(
          XRealtimeManageSearchSettingsGetSettings,
          {}
        )
        setSearchSettings(searchManageSettings)
      } catch (e) {
        console.debug(e)
      }

      try {
        const defaultSetting = await postWithUserErrorException(
          XRealtimeManageSearchSettingsGetDefaultSetting,
          {}
        )

        setDefaultSetting(defaultSetting)
      } catch (e) {
        console.debug(e)
      }
    })
  )

  useEffect(() => {
    void call()
  }, [call])

  return { searchSettings, defaultSetting, searchWithIntegrations }
}

import { capitalize } from 'lodash'
import { DateTime } from 'luxon'
import { FC, useMemo } from 'react'
import QRCode from 'react-qr-code'
import { getNamespacedImage } from 'sierra-client/api/content'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { SanaLogo } from 'sierra-client/components/common/logos/sana-logo'
import { useConfig } from 'sierra-client/context/config-context'
import { useResetBooleanAfterDelay } from 'sierra-client/hooks/use-reset-boolean-after-delay'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import {
  getEventScheduleStart,
  gqlEventLocationString,
  gqlEventScheduleToEventSchedule,
} from 'sierra-client/views/manage/event-groups/event-utils'
import { CalendarEventId, EventGroupId } from 'sierra-domain/api/nano-id'
import { isNonNullable } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Button, Heading, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const StyledSanaLogo = styled(SanaLogo)`
  & svg {
    transition: color 100ms ease;
    color: ${token('foreground/primary')};
  }
`

const MaskView = styled(View).attrs({
  direction: 'column',
  background: 'elevated/background',
  radius: 'size-16',
  alignItems: 'center',
  gap: 'none',
})`
  padding: 48px 28px 64px 28px;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.08);
  mask-image: radial-gradient(10px at 50% 10px, #0000 98%, #000);
  mask-position: 0px -10px;
  mask-repeat: repeat-y;

  @media print {
    background: none;
    border: 1px solid ${token('border/strong')};
  }
`

const getEvent = graphql(`
  query getEventForCheckinPage($id: CalendarEventId!) {
    calendarEvent(id: $id) {
      selfReportAttendance
      title
      schedule {
        ...CalendarEventScheduleFragment
      }
      location {
        ...CalendarEventLocationFragment
      }
      seatsRemaining
      userAssignment {
        attended
        hasSelfReportedAttendance
        __typename
      }
    }
  }
`)

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  background: ${token('button/background')};
`

const BrandImage = styled.img`
  width: 60px;
`

const QRCodeContainer = styled.div`
  padding: 32px;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  border-radius: 2rem;
  margin-top: 32px;
`

const QRCodeContainerInner = styled.div``

const DottSeparator = styled.div`
  height: 2px;
  width: 2px;
  background: rgba(0, 0, 0, 0.25);
`

const QRCodeBox = (props: { url: string }): JSX.Element => (
  <QRCodeContainerInner>
    <QRCode size={120} value={props.url} viewBox={`0 0 256 256`} bgColor='rgba(0, 0, 0, 0)' />
  </QRCodeContainerInner>
)

const DottedSeparatorLine = (): JSX.Element => (
  <svg width='548' height='2' viewBox='0 0 548 2' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1 1H547'
      x1='0'
      y1='0'
      x2='400'
      y2='0'
      stroke='black'
      opacity='0.5'
      strokeLinecap='round'
      strokeDasharray='0.1 6'
      strokeWidth={'1px'}
    />
  </svg>
)

const InfoView = styled(View).attrs({ padding: '8 16', radius: 'size-28' })`
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.05);
`

const MaxWidthView = styled(View).attrs({ direction: 'column', paddingTop: '16', paddingBottom: '16' })`
  max-width: 344px;
`

const RowView = styled(View).attrs({
  paddingTop: '24',
  direction: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'flex-start',
})`
  width: 100%;
`

const WhiteButton = styled(Button).attrs({})`
  border: 1px solid rgba(255, 255, 255, 0.16);
  @media print {
    display: none;
  }
`

const TopView = styled(View)`
  position: absolute;
  top: 1rem;
  width: 100%;
  justify-content: space-between;
  padding: 0 1rem;
`

export const CopyLinkButton: React.FC<{ text: string }> = ({ text }) => {
  const { t } = useTranslation()
  const { isEnabled: recentlyClicked, setTrue: setRecentlyClicked } = useResetBooleanAfterDelay()

  const handleClick = (): void => {
    void window.navigator.clipboard.writeText(text)
    setRecentlyClicked()
  }

  return (
    <WhiteButton icon={recentlyClicked ? 'checkmark' : 'link'} onClick={handleClick}>
      {t('share.copy-link')}
    </WhiteButton>
  )
}

const PageInner = ({
  eventStartTime,
  eventTitle,
  eventLocation,
  calendarEventId,
  eventGroupId,
}: {
  eventStartTime: DateTime
  eventTitle: string
  eventLocation?: string
  eventGroupId: EventGroupId
  calendarEventId: CalendarEventId
}): JSX.Element => {
  const config = useConfig()
  const { t } = useTranslation()

  const selfCheckInUrl = useMemo(() => {
    const url = new URL(window.location.href)
    return `${url.protocol}//${url.hostname}/check-in/${eventGroupId}/${calendarEventId}`
  }, [calendarEventId, eventGroupId])

  const copyLink = new URL(window.location.href)

  const brandLogo =
    config.settings.brand.logo === null
      ? undefined
      : getNamespacedImage({ type: 'organization-settings' }, config.settings.brand.logo, 'image', {
          width: 80,
        })

  return (
    <PageContainer>
      <TopView>
        <WhiteButton
          onClick={() =>
            getGlobalRouter().navigate({ to: `/manage/in-person-events/${eventGroupId}/${calendarEventId}` })
          }
          icon='calendar'
        >
          {t('events.check-in.view-event-details')}
        </WhiteButton>
        <View>
          <CopyLinkButton text={copyLink.href} />
          <WhiteButton
            icon='download'
            onClick={() => {
              window.print()
            }}
          >
            {capitalize(t('dictionary.download'))}
          </WhiteButton>
        </View>
      </TopView>
      <MaskView>
        <View marginBottom='24'>
          {brandLogo === undefined ? <StyledSanaLogo /> : <BrandImage src={brandLogo} />}
        </View>
        <InfoView>
          <Text bold>{eventTitle}</Text>
          <DottSeparator />
          <Text>{eventStartTime.toLocaleString({ year: '2-digit', month: 'short', day: 'numeric' })}</Text>
        </InfoView>
        <View direction='column' gap='none' paddingBottom='24' paddingTop='16'>
          <Heading bold align='center' size='h3'>
            {t('events.check-in.event-title', { eventTitle })}
          </Heading>
        </View>
        <DottedSeparatorLine />
        <QRCodeContainer>
          <QRCodeBox url={selfCheckInUrl} />
        </QRCodeContainer>
        <View direction='row' padding='16'>
          <Icon color={'foreground/muted'} iconId='phone' />
          <Text color={'foreground/muted'}>{t('events.check-in.scan-explanation')}</Text>
        </View>
        <MaxWidthView>
          <Text align='center' color={'foreground/primary'}>
            {t('events.check-in.qr-code-explanation', { eventTitle })}
          </Text>
        </MaxWidthView>
        <DottedSeparatorLine />
        <RowView>
          <View gap='4' direction='column' alignItems='center'>
            <Text color={'foreground/muted'}>{t('dictionary.event-name')}</Text>
            <Text>{eventTitle}</Text>
          </View>
          <View gap='4' direction='column' alignItems='center'>
            <Text color={'foreground/muted'}>{t('dictionary.event-date')}</Text>
            <Text>{eventStartTime.toLocaleString({ year: 'numeric', month: 'short', day: 'numeric' })}</Text>
          </View>
          <View gap='4' direction='column' alignItems='center'>
            <Text color={'foreground/muted'}>{t('dictionary.location')}</Text>
            <Text>{eventLocation ?? t('events.no-location')}</Text>
          </View>
        </RowView>
      </MaskView>
    </PageContainer>
  )
}

export const QrForSelfReportAttendancePage: FC<{
  eventGroupId: EventGroupId
  calendarEventId: CalendarEventId
}> = ({ calendarEventId, eventGroupId }) => {
  const queryResult = useGraphQuery(
    {
      document: getEvent,
      queryOptions: {
        gcTime: 0,
        refetchOnWindowFocus: false,
      },
    },
    {
      id: calendarEventId,
    }
  )

  const event = queryResult.data?.calendarEvent

  if (isNonNullable(event)) {
    const eventSchedule = gqlEventScheduleToEventSchedule(event.schedule)
    const eventLocation = gqlEventLocationString(event.location)
    return (
      <PageInner
        calendarEventId={calendarEventId}
        eventGroupId={eventGroupId}
        eventTitle={event.title}
        eventLocation={eventLocation}
        eventStartTime={getEventScheduleStart(eventSchedule)}
      />
    )
  }
}

import { capitalize } from 'lodash'
import { useMemo } from 'react'
import { useGetTeamspaceById } from 'sierra-client/api/hooks/use-teamspace'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { TeamspaceContentToMove } from 'sierra-client/features/teamspace/components/move-teamspace-content'
import { TeamspaceIcon } from 'sierra-client/features/teamspace/components/teamspace-icon'
import { useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CourseId, NanoId12 } from 'sierra-domain/api/nano-id'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { isDefined, isNonEmptyArray, isNotDefined } from 'sierra-domain/utils'
import { Modal } from 'sierra-ui/components'
import { Button, IconButton, Skeleton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style-type: none;
  width: 100%;
`
const Li = styled.li`
  padding: 8px;
  border-radius: 4px;
  background: ${token('surface/soft')};
`

const CloseIcon = styled(IconButton).attrs({ variant: 'transparent', iconId: 'close' })`
  position: absolute;
  right: 16px;
  top: 16px;
`

const RowThumbnail = styled(Thumbnail)`
  width: 24px;
  height: 16px;
  min-width: 24px;
  min-height: 16px;
  border-radius: 5px;
  object-fit: cover;
`

const EditionsContainer = styled(View)`
  margin-top: 10px;
  margin-left: 22px;
  border-left: solid 2px ${token('border/strong')};
  padding-left: 8px;
  display: block;
`

const EditionsList = styled.ul`
  list-style-type: none;

  li {
    margin-bottom: 8px;
  }
`

const TeamspaceInfo: React.FC<{ id: NanoId12 }> = ({ id }) => {
  const { data, isLoading } = useGetTeamspaceById(id)

  if (isLoading) {
    return (
      <View>
        <Skeleton></Skeleton>
        <Skeleton></Skeleton>
      </View>
    )
  }

  if (isNotDefined(data)) {
    return null
  }

  return (
    <View>
      <TeamspaceIcon displayName={data.displayName} small themeName={data.iconTheme} />
      <Text>{data.displayName}</Text>
    </View>
  )
}

type FailedItem = {
  title: string
  image?: ImageUnion
  items?: TeamspaceContentToMove[]
  courseId: CourseId
}

const FailedItem: React.FC<{ course: FailedItem }> = ({ course: { title, image, items, courseId } }) => {
  const { t } = useTranslation()

  const numberOfInaccessibleEditions = items?.length ?? 0

  const assetContext = useMemo(() => ({ type: 'course' as const, courseId: courseId }), [courseId])
  const thumbnailSrc = useResolveCourseAsset({
    image,
    options: { type: 'default', size: 'small' },
    assetContext,
  })

  return (
    <Li>
      <View>
        <RowThumbnail image={thumbnailSrc} />
        <Text size='small' bold>
          {title}
        </Text>
      </View>
      {isNonEmptyArray(items) && (
        <EditionsContainer>
          <EditionsList>
            {items.slice(0, 2).map(item => (
              <li key={item.id}>
                <View justifyContent='space-between'>
                  <Text bold>{item.title}</Text>
                  {isDefined(item.currentTeamspaceId) && <TeamspaceInfo id={item.currentTeamspaceId} />}
                </View>
              </li>
            ))}
          </EditionsList>
          {numberOfInaccessibleEditions > 2 && (
            <Text color='foreground/muted' bold>
              {t('teamspaces.import-modal.error-import.inaccessible-count', {
                count: numberOfInaccessibleEditions - 2,
              })}
            </Text>
          )}
        </EditionsContainer>
      )}
    </Li>
  )
}

export const ErroImportModal: React.FC<{
  open: boolean
  onOpenChange: (_: boolean) => void
  onExclude: () => void
  onBack: () => void
  failedItems: Array<TeamspaceContentToMove>
}> = ({ open, onOpenChange, onExclude, onBack, failedItems }) => {
  const { t } = useTranslation()

  const failedItemsByCourse = failedItems.reduce<Record<NanoId12, FailedItem>>((acc, item) => {
    if ('courseGroup' in item) {
      const courseGroup = item.courseGroup
      const prevCourseEditions = acc[courseGroup.id]?.items ?? []

      acc[courseGroup.id] = {
        title: courseGroup.settings.title,
        image: courseGroup.settings.image,
        items: [...prevCourseEditions, item],
        courseId: CourseId.parse(courseGroup.id),
      }
    } else {
      acc[item.id] = {
        title: item.title,
        image: item.image,
        courseId: CourseId.parse(item.id),
      }
    }
    return acc
  }, {})

  return (
    <Modal
      onOpenChange={onOpenChange}
      open={open}
      size={{
        width: 480,
      }}
    >
      <View direction='column' padding='24' gap='24' grow>
        <View direction='column'>
          <View alignItems='flex-start' justifyContent='space-between'>
            <Text size='large' bold>
              {t('teamspaces.import-modal.error-import.title')}
            </Text>
            <CloseIcon onClick={onBack} />
          </View>
          <Text color='foreground/muted'>{t('teamspaces.import-modal.error-import.subtitle')}</Text>
        </View>
        <View>
          <Ul>
            {Object.values(failedItemsByCourse).map((item, index) => (
              <FailedItem key={index} course={item} />
            ))}
          </Ul>
        </View>
        <View gap='8' justifyContent='flex-end'>
          <Button onClick={onBack} variant='secondary'>
            {capitalize(t('dictionary.back'))}
          </Button>
          <Button onClick={onExclude}>{t('teamspaces.import-modal.error-import.button.exclude.text')}</Button>
        </View>
      </View>
    </Modal>
  )
}

import { useEffect } from 'react'
import { useHideIntercom } from 'sierra-client/components/util/show-intercom-launcher'
import { errorLogger } from 'sierra-client/error/error-logger'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { selectCurrentScormCourse } from 'sierra-client/state/content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { fetchCourseStatusById } from 'sierra-client/state/v2/courses-actions'
import { CourseId } from 'sierra-domain/api/nano-id'
import { Layout } from 'sierra-ui/components'
import { breakpoints } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const BorderLessIframe = styled.iframe.withConfig<{
  webkitallowfullscreen: 'true'
  mozallowfullscreen: 'true'
}>({
  shouldForwardProp: (prop, defaultValidator) =>
    prop === 'webkitallowfullscreen' || prop === 'mozallowfullscreen' || defaultValidator(prop),
})`
  border: none;
  padding-top: 4rem;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    padding-top: 4rem;
  }
  width: 100%;
`

const FullContainer = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
`

type ScormPlayerMessage = { error?: string; message: string; action?: 'COMPLETED' }

export type ScormCoursePageProps = {
  courseId: CourseId
}

const ScormCoursePageContent = ({ courseId }: ScormCoursePageProps): JSX.Element | null => {
  const dispatch = useDispatch()
  const course = useSelector(selectCurrentScormCourse)
  useHideIntercom()

  // First dispatch
  useEffect(() => {
    void dispatch(fetchCourseStatusById({ courseId }))
  }, [dispatch, courseId])

  useEffect(() => {
    const listener = (message: MessageEvent): void => {
      // The SCORM pages are alwoys hosted on domains prefixed with x-scorm--, but it is a bit
      // awkward to have this check here. Unclear if we can remove it.
      if (!message.origin.startsWith('https://x-scorm--')) return

      console.debug('[Scorm]', message)

      const scormMessage: ScormPlayerMessage = message.data

      try {
        if (scormMessage.error !== undefined) {
          // handle error
          console.debug('[Scorm] Player error:', scormMessage)
        } else {
          if (scormMessage.action === 'COMPLETED') {
            // @TODO figure out how to call this only after status was sent from the BE
            // fetch status
            setTimeout(() => void dispatch(fetchCourseStatusById({ courseId })), 2 * 1000)
            // re-fetch status, it may take a few seconds for SCORM API to post the update
            setTimeout(() => void dispatch(fetchCourseStatusById({ courseId })), 20 * 1000)
          }
        }
      } catch (e) {
        errorLogger.captureError(e, {
          context: { error: 'Error parsing scorm container postMessage', data: scormMessage },
        })
      }
    }
    window.addEventListener('message', listener)
    return () => {
      window.removeEventListener('message', listener)
    }
  }, [courseId, dispatch])

  if (!course) return null

  return (
    <FullContainer>
      <BorderLessIframe
        id='scorm-player'
        key={courseId}
        src={`/x-realtime/scorm/redirect/${courseId}`}
        // Allow fullscreen for all origins
        allow='fullscreen *'
        allowFullScreen={true}
        webkitallowfullscreen='true'
        mozallowfullscreen='true'
      />
    </FullContainer>
  )
}

export const ScormCoursePage = ({ courseId }: ScormCoursePageProps): JSX.Element => {
  return (
    <Layout>
      <SanaPage mode='light' headerType='scorm' page={PageIdentifier.LearnerCourse({ courseId })}>
        <ScormCoursePageContent courseId={courseId} />
      </SanaPage>
    </Layout>
  )
}

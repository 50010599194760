import { Atom, useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { DateTime } from 'luxon'
import React, { FC, useCallback, useMemo } from 'react'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { date, useGetFormattedTime } from 'sierra-client/core/format'
import { useOrganizationCluster } from 'sierra-client/features/multi-tenancy'
import { useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import { ColorInheritingLink, User } from 'sierra-client/lib/tabular/components/cell-components'
import { BooleanCell } from 'sierra-client/lib/tabular/components/cells/boolean'
import { CertificateIssuedBy } from 'sierra-client/lib/tabular/components/cells/certificate-issued-by'
import { DateTimeCell } from 'sierra-client/lib/tabular/components/cells/datetime'
import { DurationCell } from 'sierra-client/lib/tabular/components/cells/duration'
import { EmptyCell } from 'sierra-client/lib/tabular/components/cells/empty'
import { RenderEnrolledBy } from 'sierra-client/lib/tabular/components/cells/enrolled-by'
import { NumberCell } from 'sierra-client/lib/tabular/components/cells/number'
import { PillCell } from 'sierra-client/lib/tabular/components/cells/pill'
import { SingleSelectCell } from 'sierra-client/lib/tabular/components/cells/single-select'
import { StringCell } from 'sierra-client/lib/tabular/components/cells/string'
import { TitleAndSubtitle } from 'sierra-client/lib/tabular/components/cells/title-and-subtitle'
import { UserCell } from 'sierra-client/lib/tabular/components/cells/user'
import { CellRenderContext, useSelectOnClick } from 'sierra-client/lib/tabular/components/util'
import { Cell, CellIconMenuAction, CellRowAction } from 'sierra-client/lib/tabular/datatype/internal/cell'
import {
  CellButtonSelect,
  CellCard,
  CellContent,
  CellIssuedCertificate,
  CellLink,
  CellSelect,
  CellWithData,
  type CellSkillContent,
  type CellUserStack,
} from 'sierra-client/lib/tabular/datatype/internal/cell-with-data'
import { CertificateRep } from 'sierra-client/lib/tabular/datatype/internal/reps/certificate-rep'
import { GroupRep } from 'sierra-client/lib/tabular/datatype/internal/reps/group-rep'
import { HomeworkRep } from 'sierra-client/lib/tabular/datatype/internal/reps/homework-rep'
import { IssuedRevokedRep } from 'sierra-client/lib/tabular/datatype/internal/reps/issued-revoked-rep'
import { UserShape } from 'sierra-client/lib/tabular/datatype/internal/reps/user-rep'
import { labelToString } from 'sierra-client/lib/tabular/datatype/label'
import { TableData } from 'sierra-client/lib/tabular/datatype/tabledata'
import { RenderHint, findButtonVariant } from 'sierra-client/lib/tabular/hints'
import { Position } from 'sierra-client/lib/tabular/types'
import { createFileFromType } from 'sierra-client/lib/tabular/utils/create-file-from-type'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { FileIcon, FileIconProps } from 'sierra-client/views/flexible-content/editor/content-sidebar/icons'
import { SeatRequestCount } from 'sierra-client/views/learner/event-group/handle-event-group-enrollment-panel/common'
import { RoundAvatarFromAvatar } from 'sierra-client/views/manage/components/round-avatar-from-avatar'
import {
  bareContentId,
  getHrefForContentDetails,
} from 'sierra-client/views/manage/content/utils/content-utils'
import { manageUrl } from 'sierra-client/views/workspace/utils/urls'
import { UserProgramVersion } from 'sierra-domain/api/manage'
import { CourseId, PathId } from 'sierra-domain/api/nano-id'
import { ProgramId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { CourseImageCellProps } from 'sierra-domain/program'
import { assertNever, iife, isDefined } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import {
  AvatarStack,
  AvatarStackUserShape,
  Icon,
  IconId,
  MenuItem,
  Tooltip,
  TruncatedText,
  TruncatedTextWithTooltip,
} from 'sierra-ui/components'
import { Button, Checkbox, IconButton, InternalTruncatedText, Pill, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { palette, token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: background-color 0.2s ease;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 94rem) {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
`

const CheckboxWidth = styled.div`
  width: 1rem;
  max-width: 1rem;
`

export const RenderCheckboxCell: React.FC<{
  cell: CellSelect
  api: TableAPI
}> = ({ cell, api }) => {
  const selected = useAtomValue(cell.selected)
  const onClick = useSelectOnClick(cell, api)

  return (
    <CheckboxWidth>
      <CheckBoxContainer
        onClick={event => {
          event.stopPropagation()
        }}
      >
        <Checkbox
          checked={selected}
          onCheckedChange={() => {
            onClick()
          }}
        />
      </CheckBoxContainer>
    </CheckboxWidth>
  )
}

const VersionText = styled(Text).attrs({
  bold: true,
  color: 'foreground/muted',
  size: 'micro',
})`
  white-space: nowrap;
`

const ProgressPresentation: FC<{ progress: number }> = ({ progress }) => {
  const { t } = useTranslation()

  const percentage = Math.floor(progress * 100)

  if (percentage === 100) {
    return (
      <Text size='small' color='success/background'>
        {t('dictionary.completed')}
      </Text>
    )
  }

  return <Text size='small'>{percentage}%</Text>
}

export const ProgramVersionNumberCell: React.FC<{
  cellData: UserProgramVersion & { progress: number }
  hints: RenderHint[]
}> = ({ cellData, hints: _hints }) => {
  const { t } = useTranslation()

  return (
    <View direction='column' gap='4'>
      <ProgressPresentation progress={cellData.progress} />

      {cellData.isUserInLastVersion === true ? (
        <VersionText>{t('manage.programs.table.current-version')}</VersionText>
      ) : (
        <VersionText>
          {t('manage.programs.version-from-date', {
            date: DateTime.fromISO(cellData.updatedAt).toFormat('yyyy-MM-dd').toString(),
          })}
        </VersionText>
      )}
    </View>
  )
}

const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 16rem;
`

const EmptyImage = styled.div`
  min-width: 2.75rem;
  width: 2.75rem;
`

export const GroupCell: React.FC<{ group: GroupRep; hints: RenderHint[] }> = ({ group, hints }) => {
  return (
    <ColorInheritingLink
      href={`/manage/user-groups/${group.id}`}
      next
      target={hints.includes('open-in-new-tab') ? '_blank' : undefined}
    >
      <View direction='row' alignItems='center'>
        <RoundAvatarFromAvatar size='medium' userId={undefined} avatar={group.avatar} />
        <View>
          <TruncatedText lines={1} size='small'>
            {group.name}
          </TruncatedText>
        </View>
      </View>
    </ColorInheritingLink>
  )
}

// Workaround to make sure iconmenu closes when clicking on a label
type SelectableMenuItem = MenuItem & { onSelect?: () => void }
const toSelectable = (item: MenuItem): SelectableMenuItem => {
  switch (item.type) {
    case 'label':
      return { ...item, onSelect: item.onClick, onClick: undefined }
    case 'nested':
      return { ...item, menuItems: item.menuItems.map(toSelectable) }
    default:
      return item
  }
}
const IconMenuActionCell = ({ cell }: { cell: CellIconMenuAction }): JSX.Element | null => {
  const { t } = useTranslation()

  const workaroundItems = useMemo(() => cell.props.menuItems.map(toSelectable), [cell.props.menuItems])

  const onSelect = useCallback(
    (item: SelectableMenuItem) => {
      if (isDefined(cell.props.onSelect)) {
        return cell.props.onSelect(item)
      }
      if (isDefined(item.onSelect)) {
        return item.onSelect()
      }
    },
    [cell.props]
  )

  return (
    <View justifyContent={'flex-end'} padding={'none xsmall'}>
      <IconMenu
        onSelect={onSelect}
        variant={cell.props.variant ?? 'transparent'}
        menuItems={workaroundItems}
        iconId={cell.props.iconId ?? 'overflow-menu--horizontal'}
        aria-label={cell.props['aria-label'] ?? t('dictionary.details')}
        size={cell.props.size}
      />
    </View>
  )
}

const ActionButton = styled(Button)`
  padding: 0px;
`

const MutedTrashCan = styled(IconButton).attrs({ variant: 'transparent', iconId: 'trash-can' })`
  color: ${token('foreground/muted')};

  &:hover {
    color: ${token('foreground/primary')};
  }
`

const RowActionCell = <TD extends TableData>({
  cell,
  api,
}: {
  cell: CellRowAction<TD>
  api: TableAPI<TD>
}): JSX.Element | null => {
  const { t } = useTranslation()

  const variant = findButtonVariant(cell.action.hints) ?? 'transparent'

  return cell.hints.includes('remove-row') ? (
    <View padding={'none'} justifyContent='flex-end'>
      <MutedTrashCan onClick={event => (event.stopPropagation(), cell.action.callback(cell.pos.row, api))} />
    </View>
  ) : (
    <View padding={'none'}>
      {cell.action.hide?.(cell.pos.row, api) === true ? null : (
        <ActionButton
          grow
          disabled={cell.action.disabled?.(cell.pos.row, api)}
          variant={variant}
          onClick={ev => {
            ev.stopPropagation()
            cell.action.callback(cell.pos.row, api)
          }}
        >
          {labelToString(cell.action.label, t)}
        </ActionButton>
      )}
    </View>
  )
}

const SubrowLeftContainer = styled(View).attrs({ grow: true })`
  height: 100%;
  width: 100%;

  &::after {
    position: absolute;
    top: 0;
    left: 40%;
    content: '';
    width: 4px;
    height: 100%;
    border-radius: 4px;
    background-color: ${color('grey5').toString()};
  }
`

export const SubrowLeftCell: React.FC<{ api: TableAPI }> = () => <SubrowLeftContainer />

const IconAndTextRow = styled.div`
  height: 20px;
`

const StyledPill = styled(Pill)`
  width: fit-content;
`

const ContentTitleAndImage: React.FC<{
  image?: ImageUnion
  title: string
  isCourseEdition: boolean
  titleIcon: React.ReactNode
  additionalInfo?: React.ReactNode
  hints: RenderHint[]
  hasChildrenRows: boolean
  expandedAtom: Atom<boolean>
  toggleExpand: (ev: React.MouseEvent<HTMLElement>) => void
  duration?: number
  assetContext: AssetContext
  readOnly?: boolean
}> = ({
  image,
  title,
  isCourseEdition,
  hints,
  hasChildrenRows,
  expandedAtom,
  toggleExpand,
  duration,
  titleIcon,
  additionalInfo,
  assetContext,
  readOnly,
}) => {
  const imgSrc = useResolveCourseAsset({ image, options: { type: 'admin', size: 'small' }, assetContext })
  const expanded = useAtomValue(expandedAtom)

  const withDuration = hints.includes('content-with-duration')
  const formattedDuration = useGetFormattedTime(duration, false)

  const orgCluster = useOrganizationCluster()
  const parentOrg = !orgCluster.loading ? orgCluster.cluster.find(org => org.isClusterParent) : undefined
  const { t } = useTranslation()

  return (
    <View alignItems='center' gap='xsmall'>
      {!isCourseEdition && <Thumbnail image={imgSrc} height={2} width={2.75} radius={0.5} />}
      {isCourseEdition && <EmptyImage />}
      <View gap='none' direction='column'>
        <View gap='4'>
          {titleIcon}
          <TruncatedText lines={1} bold={true}>
            {title}
          </TruncatedText>
          {additionalInfo}
        </View>
        {readOnly === true && (
          <StyledPill variant='ghost'>
            <Icon iconId='locked--filled' color='foreground/secondary' size='size-12' />
            <Text color='foreground/secondary' size='micro'>
              {isDefined(parentOrg)
                ? t('manage.content.published-from-org', { org: parentOrg.name })
                : t('manage.content.published-from-another-org')}
            </Text>
          </StyledPill>
        )}
        {withDuration && formattedDuration !== undefined && (
          <IconAndTextRow>
            <View>
              <Icon iconId='bookmark--filled' color='foreground/muted' />
              <TruncatedText bold lines={1} size='small' color='foreground/muted'>
                {formattedDuration}
              </TruncatedText>
            </View>
          </IconAndTextRow>
        )}
      </View>
      {hints.includes('with-nested-row-toggle') && hasChildrenRows && (
        <IconButton
          iconId={expanded ? 'chevron--up--small' : 'chevron--down--small'}
          onClick={toggleExpand}
          variant='transparent'
        />
      )}
    </View>
  )
}

const getAssetContext = (cell: CellContent | CellSkillContent): AssetContext => {
  switch (cell.data.contentType) {
    case 'course':
      return { type: 'course' as const, courseId: CourseId.parse(bareContentId(cell.data.id)) }
    case 'path':
      return { type: 'path' as const, pathId: PathId.parse(bareContentId(cell.data.id)) }
    case 'homework':
      return { type: 'course' as const, courseId: CourseId.parse(bareContentId(cell.data.id)) }
    case 'program':
      return { type: 'program' as const, programId: ProgramId.parse(bareContentId(cell.data.id)) }
    default:
      assertNever(cell.data.contentType)
  }
}

export const ContentCell: React.FC<{ cell: CellContent; api: TableAPI }> = ({ cell, api }) => {
  const { t } = useTranslation()
  const toggleExpand = React.useCallback(
    (ev: React.MouseEvent<HTMLElement>) => (
      ev.preventDefault(), ev.stopPropagation(), api.action.toggleExpanded({ row: cell.pos.row })
    ),
    [api, cell.pos.row]
  )
  const expandedAtom = React.useMemo(
    () => selectAtom(api.atoms.expanded, set => set.has(cell.pos.row)),
    [cell.pos.row, api.atoms.expanded]
  )
  const hasChildrenRows = React.useContext(CellRenderContext).hasChildrenRows
  const href = getHrefForContentDetails(cell.data)
  const assetContext = useMemo(() => getAssetContext(cell), [cell])

  return (
    <ColorInheritingLink
      href={href}
      next
      target={cell.hints.includes('open-in-new-tab') ? '_blank' : undefined}
    >
      <ContentTitleAndImage
        image={cell.data.image}
        title={cell.data.title}
        titleIcon={
          cell.data.isFeatured && (
            <IconAndTextRow>
              <Tooltip title={t('manage.content.featured')}>
                <Icon iconId='star--filled' />
              </Tooltip>
            </IconAndTextRow>
          )
        }
        isCourseEdition={cell.data.isCourseEdition}
        hints={cell.hints}
        hasChildrenRows={hasChildrenRows}
        expandedAtom={expandedAtom}
        toggleExpand={toggleExpand}
        duration={cell.data.duration}
        assetContext={assetContext}
        readOnly={cell.data.readOnly}
      />
    </ColorInheritingLink>
  )
}

export const SkillContentCell: React.FC<{ cell: CellSkillContent; api: TableAPI }> = ({ cell, api }) => {
  const { t } = useTranslation()
  const toggleExpand = React.useCallback(
    (ev: React.MouseEvent<HTMLElement>) => (
      ev.preventDefault(), ev.stopPropagation(), api.action.toggleExpanded({ row: cell.pos.row })
    ),
    [api, cell.pos.row]
  )
  const expandedAtom = React.useMemo(
    () => selectAtom(api.atoms.expanded, set => set.has(cell.pos.row)),
    [cell.pos.row, api.atoms.expanded]
  )
  const hasChildrenRows = React.useContext(CellRenderContext).hasChildrenRows
  const href = getHrefForContentDetails(cell.data)
  const assetContext = useMemo(() => getAssetContext(cell), [cell])

  return (
    <ColorInheritingLink
      href={href}
      next
      target={cell.hints.includes('open-in-new-tab') ? '_blank' : undefined}
    >
      <ContentTitleAndImage
        image={cell.data.image}
        title={cell.data.title}
        isCourseEdition={cell.data.isCourseEdition}
        titleIcon={
          cell.data.isLevelFastTrack && (
            <IconAndTextRow>
              <Tooltip title={t('admin.skills.level-fast-tack.label')}>
                <Icon iconId='racing--flag' color='foreground/primary' />
              </Tooltip>
            </IconAndTextRow>
          )
        }
        hints={cell.hints}
        hasChildrenRows={hasChildrenRows}
        expandedAtom={expandedAtom}
        toggleExpand={toggleExpand}
        duration={cell.data.duration}
        assetContext={assetContext}
      />
    </ColorInheritingLink>
  )
}

const CourseImageContainer = styled(View).attrs({ background: 'grey2', radius: 'size-8' })<{
  backgroundImage: CourseImageProps['backgroundImage']
  size: 'small' | 'large'
}>`
  width: ${p => (p.size === 'small' ? '3rem' : '5rem')};
  min-width: ${p => (p.size === 'small' ? '3rem' : '5rem')};
  height: ${p => (p.size === 'small' ? '2rem' : '3rem')};
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: ${token('surface/strong')};

  ${p =>
    p.backgroundImage !== undefined &&
    css`
      background-position: center center;
      background-size: cover;
      background-image: url(${p.backgroundImage});
    `};
`
type CourseImageProps = {
  backgroundImage: string | undefined
  placeholderIcon?: IconId
  size: 'small' | 'large'
}

const CourseImage: FC<CourseImageProps> = ({ backgroundImage, placeholderIcon, size }) => {
  return (
    <CourseImageContainer backgroundImage={backgroundImage} size={size}>
      {backgroundImage === undefined && placeholderIcon !== undefined && (
        <Icon size='size-20' color='foreground/muted' iconId={placeholderIcon} />
      )}
    </CourseImageContainer>
  )
}

const MinWidthWrapper = styled(View)`
  align-self: center;
  min-width: 0; /* for text-overflow: ellipsis to work inside the cell */
`

const CourseTitle = styled(Text).attrs({
  className: 'underline-on-hover',
  bold: true,
})`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
`

const FullHeightView = styled(View)`
  height: 100%;
`

type CourseTitleAndImageProps = {
  image?: ImageUnion
  title: string
  href?: string
  onTitleClick?: () => void
  subline: string
  stepType?: 'path' | 'course' | 'email'
  assetContext: AssetContext
  imageSize?: 'small' | 'large'
}

export const CourseTitleAndImage: React.FC<CourseTitleAndImageProps> = ({
  image,
  title,
  onTitleClick,
  href,
  subline,
  stepType,
  assetContext,
  imageSize = 'large',
}) => {
  const imageUrl = useResolveCourseAsset({ image, assetContext })

  const placeholderIcon = iife((): IconId | undefined => {
    switch (stepType) {
      case undefined:
        return undefined
      case 'email':
        return 'send--filled'
      case 'course':
        return 'course'
      case 'path':
        return 'path'
      default:
        assertNever(stepType)
    }
  })

  const cell = (
    <FullHeightView direction='row' gap='12' alignItems='flex-start'>
      <CourseImage backgroundImage={imageUrl} placeholderIcon={placeholderIcon} size={imageSize} />

      <MinWidthWrapper direction='column' gap='2'>
        <Tooltip title={title}>
          <CourseTitle>{title}</CourseTitle>
        </Tooltip>
        <Text color='foreground/muted'>{subline}</Text>
      </MinWidthWrapper>
    </FullHeightView>
  )

  if (href !== undefined) {
    return (
      <ColorInheritingLink href={href} next>
        {cell}
      </ColorInheritingLink>
    )
  } else if (onTitleClick !== undefined) {
    return <ColorInheritingLink onClick={onTitleClick}>{cell}</ColorInheritingLink>
  } else {
    return cell
  }
}

const Line = styled(View).attrs({ background: 'border/strong' })`
  min-width: 4px;
  border-radius: 9999px;
`

export const CourseImageCell: React.FC<CourseImageCellProps> = ({ editions, assetContext, ...props }) => {
  const { t } = useTranslation()

  return (
    <View direction='column' gap='16'>
      <CourseTitleAndImage {...props} assetContext={assetContext} />
      {editions !== undefined && (
        <View direction='row' alignItems='stretch' gap='16'>
          <Line />
          <View direction='column' gap='24'>
            {editions.map(e => {
              return (
                <CourseTitleAndImage
                  key={e.id}
                  title={e.title}
                  subline={t('dictionary.course-edition-singular')}
                  image={e.image}
                  assetContext={{ type: 'course', courseId: CourseId.parse(e.id) }}
                />
              )
            })}
          </View>
        </View>
      )}
    </View>
  )
}

export const CardCell: React.FC<{ cell: CellCard }> = ({ cell }) => {
  const file: FileIconProps = useMemo(() => {
    const fileData = createFileFromType(cell.data.cardType)
    return { ...fileData, backgroundImage: cell.data.image }
  }, [cell.data.cardType, cell.data.image])

  return (
    <View direction='column' gap='16'>
      <View>
        <FileIcon file={file} assetContext={{ type: 'unknown' }} />
        <TruncatedText>{cell.data.title}</TruncatedText>
      </View>
    </View>
  )
}

export const AddStepContentCell: React.FC<{ data: CourseImageCellProps }> = ({ data }) => {
  return (
    <CourseImageCell
      image={data.image}
      assetContext={data.assetContext}
      title={data.title}
      subline={data.subline}
      editions={data.editions}
    />
  )
}

export const LinkCell: React.FC<{ cell: CellLink }> = ({ cell }) => {
  return (
    <ColorInheritingLink href={cell.data.url} next>
      <View>
        <TruncatedText lines={1} size='small' bold={cell.hints.includes('bold')}>
          {cell.data.label}
        </TruncatedText>
      </View>
    </ColorInheritingLink>
  )
}

//TODO(ludvig): Use Cloudinary to create low-res thumbnails
export const CertificateCell: React.FC<{ certificate: CertificateRep }> = ({ certificate }) => {
  return (
    <ColorInheritingLink href={`/manage/certificates/${certificate.id}`} next>
      <FlexColumnDiv>
        <View direction='row' gap='16'>
          <Thumbnail image={certificate.previewImageUrl} height={2} width={2.75} radius={0.5} />
          <View direction='row' grow gap='10'>
            <TruncatedText size='small' lines={1} bold>
              {certificate.title}
            </TruncatedText>
          </View>
        </View>
      </FlexColumnDiv>
    </ColorInheritingLink>
  )
}

export const EventGroupCell: React.FC<{ cell: CellWithData; api: TableAPI }> = ({ cell, api }) => {
  const { t } = useTranslation()
  const toggleExpand = React.useCallback(
    (ev: React.MouseEvent<HTMLElement>) => (
      ev.preventDefault(), ev.stopPropagation(), api.action.toggleExpanded({ row: cell.pos.row })
    ),
    [api, cell.pos.row]
  )
  const expandedAtom = React.useMemo(
    () => selectAtom(api.atoms.expanded, set => set.has(cell.pos.row)),
    [cell.pos.row, api.atoms.expanded]
  )

  if (cell.type !== 'eventGroup') return

  return (
    <ColorInheritingLink href={`/manage/in-person-events/${cell.data.id}`} next>
      <FlexColumnDiv>
        <ContentTitleAndImage
          assetContext={{ type: 'course' as const, courseId: CourseId.parse(cell.data.id) }}
          image={cell.data.image}
          title={cell.data.title}
          titleIcon={
            cell.data.isFeatured && (
              <IconAndTextRow>
                <Tooltip title={t('manage.content.featured')}>
                  <Icon iconId='star--filled' />
                </Tooltip>
              </IconAndTextRow>
            )
          }
          additionalInfo={
            cell.data.requestCount > 0 && (
              <Tooltip title={t('event-groups.seat-request-headline')}>
                <SeatRequestCount count={cell.data.requestCount} />
              </Tooltip>
            )
          }
          isCourseEdition={false}
          hints={[]}
          hasChildrenRows={false}
          expandedAtom={expandedAtom}
          toggleExpand={toggleExpand}
        />
      </FlexColumnDiv>
    </ColorInheritingLink>
  )
}

const isNestedRow = (api: TableAPI, pos: Position): boolean => {
  const tableData = api.query.tableData().tableData
  // Does any page in the table contain this row in the `nested` record?
  for (const td of tableData) {
    for (const row of td.rows.map(r => r.id)) {
      if (td.nested[row]?.rows.map(r => r.id).includes(pos.row) === true) {
        return true
      }
    }
  }
  return false
}

export const IssuedCertificateCell: React.FC<{
  cell: CellIssuedCertificate
  api: TableAPI
  hints: RenderHint[]
}> = ({ cell, api, hints }) => {
  const isNested = isNestedRow(api, cell.pos)

  const toggleExpand = React.useCallback(
    (ev: React.MouseEvent<HTMLElement>) => (
      ev.preventDefault(), ev.stopPropagation(), api.action.toggleExpanded({ row: cell.pos.row })
    ),
    [api, cell.pos.row]
  )

  const expandedAtom = React.useMemo(
    () => selectAtom(api.atoms.expanded, set => set.has(cell.pos.row)),
    [cell.pos.row, api.atoms.expanded]
  )

  const expanded = useAtomValue(expandedAtom)

  const hasChildrenRows = React.useContext(CellRenderContext).hasChildrenRows

  const [firstName, lastName] = React.useMemo(() => {
    if (cell.data.issuedToUserDisplayName.includes(' ')) {
      return [
        cell.data.issuedToUserDisplayName.slice(0, cell.data.issuedToUserDisplayName.indexOf(' ')),
        cell.data.issuedToUserDisplayName.slice(cell.data.issuedToUserDisplayName.indexOf(' ') + 1),
      ]
    } else {
      return [cell.data.issuedToUserDisplayName, '']
    }
  }, [cell.data.issuedToUserDisplayName])

  return (
    <ColorInheritingLink
      href={`/manage/users/${cell.data.issuedToUserId}`}
      next
      target={hints.includes('open-in-new-tab') ? '_blank' : undefined}
    >
      <User
        hasChildrenRows={hasChildrenRows}
        disabled={!(cell.data.issuedToUserStatus === 'ACTIVE')}
        toggleExpand={toggleExpand}
        expanded={expanded}
        email={cell.data.issuedToUserEmail}
        firstName={firstName}
        lastName={lastName}
        hints={hints}
        isNestedChild={isNested}
        underlineUserOnHover={hints.includes('underline-user-on-hover')}
        src={getAvatarImage(cell.data.issuedToUserId, cell.data.issuedToUserAvatar)}
      />
    </ColorInheritingLink>
  )
}

const ExpiryTime = styled.p`
  color: ${palette.primitives.black};
`
const ExpiryTimeSoon = styled.p`
  color: ${p => p.theme.color.orangeBright};
`
const ExpiryTimeExpired = styled.p`
  .label {
    color: ${p => p.theme.color.redBright};
  }

  .expired {
    color: ${p => p.theme.color.grey25};
  }
`
export const ExpiryTimeCell: React.FC<{ cellData?: Date }> = ({ cellData }) => {
  if (cellData === undefined) {
    return <></>
  }

  const diffInDays = (cellData.getTime() - Date.now()) / (1000 * 60 * 60 * 24)
  if (diffInDays > 30) {
    return (
      <ExpiryTime>
        <TruncatedText lines={1}>{date(cellData.toISOString())}</TruncatedText>
      </ExpiryTime>
    )
  } else if (diffInDays < 0) {
    return (
      <ExpiryTimeExpired>
        <InternalTruncatedText className='label' lines={1}>
          {date(cellData.toISOString())}
        </InternalTruncatedText>
        <InternalTruncatedText className='expired' lines={1} size='small'>
          Expired
        </InternalTruncatedText>
      </ExpiryTimeExpired>
    )
  } else {
    return (
      <ExpiryTimeSoon>
        <TruncatedText lines={1}>{date(cellData.toISOString())}</TruncatedText>
      </ExpiryTimeSoon>
    )
  }
}

const IssuedDate = styled.p`
  color: ${palette.primitives.black};
`
const IssuedDateRevoked = styled.p`
  .label {
    text-decoration: line-through;
    color: ${p => p.theme.color.grey25};
  }

  .revoked {
    color: ${p => p.theme.color.grey50};
  }
`
export const IssuedRevokedCell: React.FC<{ issuedRevoked: IssuedRevokedRep }> = ({ issuedRevoked }) => {
  if (!issuedRevoked.revoked) {
    return (
      <IssuedDate>
        <TruncatedText lines={1}>{date(issuedRevoked.issued.toISOString())}</TruncatedText>
      </IssuedDate>
    )
  } else {
    return (
      <IssuedDateRevoked>
        <InternalTruncatedText lines={1} className='label'>
          {date(issuedRevoked.issued.toISOString())}
        </InternalTruncatedText>
        <InternalTruncatedText lines={1} className='revoked' size='small'>
          Revoked
        </InternalTruncatedText>
      </IssuedDateRevoked>
    )
  }
}

export const AddButtonSelector = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem /* TD padding */;
  height: 80px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const Hidden = styled.div`
  opacity: 0;
  pointer-events: none;
`

export const ButtonSelectCell: React.FC<{ cell: CellButtonSelect; api: TableAPI }> = ({ cell, api }) => {
  const selected = useAtomValue(cell.selected)
  const onClick = useSelectOnClick(cell, api)
  const { t } = useTranslation()

  if (selected) {
    return (
      <>
        <AddButtonSelector className='selected'>
          <Button icon='checkmark' variant='secondary' onClick={onClick}>
            {t('dictionary.added')}
          </Button>
        </AddButtonSelector>
        <Hidden>
          <View>{t('dictionary.added')}</View>
        </Hidden>
      </>
    )
  }

  return (
    <>
      <AddButtonSelector>
        <Button variant='primary' onClick={onClick}>
          {t('dictionary.add')}
        </Button>
      </AddButtonSelector>
      <Hidden>
        <View>{t('dictionary.add')}</View>
      </Hidden>
    </>
  )
}

export const HomeworkCell: React.FC<{ homework: HomeworkRep; hints: RenderHint[] }> = ({
  homework,
  hints,
}) => {
  const { t } = useTranslation()

  const assetContext = useMemo(
    () => ({ type: 'course' as const, courseId: CourseId.parse(homework.course.id) }),
    [homework.course.id]
  )
  const imgSrc = useResolveCourseAsset({
    image: homework.course.image,
    assetContext,
  })

  return (
    <View direction='column' alignItems='flex-start' gap='2'>
      <ColorInheritingLink
        href={manageUrl({ type: 'homework', id: homework.id })}
        next
        target={hints.includes('open-in-new-tab') ? '_blank' : undefined}
      >
        <TruncatedTextWithTooltip bold lines={1} size='small'>
          {homework.title ?? t('dictionary.no-title')}
        </TruncatedTextWithTooltip>
      </ColorInheritingLink>

      <ColorInheritingLink
        href={manageUrl({ type: 'native:self-paced', id: homework.course.id })}
        next
        target={hints.includes('open-in-new-tab') ? '_blank' : undefined}
      >
        <View alignItems='center' justifyContent='center'>
          <Thumbnail image={imgSrc} height={1} width={1.375} radius={0.25} />
          <TruncatedTextWithTooltip lines={1} size='small' color='foreground/muted'>
            {homework.course.title}
          </TruncatedTextWithTooltip>
        </View>
      </ColorInheritingLink>
    </View>
  )
}

const UserStackTooltip = styled(Tooltip)`
  margin: auto;
`

const StyledLink = styled.a`
  color: inherit;
  font-weight: bold;
`

const UserStackCell: React.FC<{ cell: CellUserStack }> = ({ cell }) => {
  const { t } = useTranslation()
  const { users, label } = cell.data
  const stackUsers: AvatarStackUserShape[] = users.map((user: UserShape) => ({
    firstName: user.firstName ?? '-',
    lastName: user.lastName ?? '-',
    avatarColor: user.avatarColor ?? 'pinkBright',
    avatar: user.avatar,
    uuid: user.id,
  }))

  const tooltipTitleComponent = (
    <Text color='surface/default'>
      {isDefined(label?.tooltip) ? labelToString(label.tooltip.label, t) : undefined}
      &nbsp; &nbsp;
      <StyledLink href={label?.tooltip?.contentLink}>
        {t('skills.table.limited-access.open-content-settings')}
      </StyledLink>
    </Text>
  )

  return (
    <View>
      {isDefined(label) && (
        <UserStackTooltip title={isDefined(label.tooltip) ? tooltipTitleComponent : undefined}>
          <View>
            {isDefined(label.icon) && <Icon iconId={label.icon} color='foreground/primary' />}
            <Text
              color={
                cell.hints.includes('muted') || (Array.isArray(label.hints) && label.hints.includes('muted'))
                  ? 'foreground/muted'
                  : undefined
              }
            >
              {labelToString(label.text, t)}
            </Text>
          </View>
        </UserStackTooltip>
      )}
      <AvatarStack users={stackUsers} max={3} size='tiny' withTooltips withRemainerIndication />
    </View>
  )
}

export const RenderCell = <TD extends TableData>({
  cell,
  api,
}: {
  cell: Cell<TD>
  api: TableAPI<TD>
}): JSX.Element => {
  switch (cell.type) {
    case 'string':
      return <StringCell cell={cell} api={api} />
    case 'titleAndSubtitle':
      return <TitleAndSubtitle cell={cell} api={api} />
    case 'number':
      return <NumberCell cellData={cell.data} hints={cell.hints} />
    case 'boolean':
      return <BooleanCell cellData={cell.data} />
    case 'datetime':
      return <DateTimeCell cellData={cell.data} hints={cell.hints} />
    case 'duration':
      return <DurationCell cellData={cell.data} />
    case 'user':
      return <UserCell user={cell.data} hints={cell.hints} />
    case 'userStack':
      return <UserStackCell cell={cell} />
    case 'homework':
      return <HomeworkCell homework={cell.data} hints={cell.hints} />
    case 'select':
      return <RenderCheckboxCell cell={cell} api={api} />
    case 'addButton':
      return <ButtonSelectCell cell={cell} api={api} />
    case 'iconMenuAction':
      return <IconMenuActionCell cell={cell} />
    case 'rowAction':
      return <RowActionCell cell={cell} api={api} />
    case 'enrolledBy':
      return <RenderEnrolledBy enrolledBy={cell.data} api={api} />
    case 'subrowLeft':
      return <SubrowLeftCell api={api} />
    case 'empty':
      return <EmptyCell />
    case 'content':
      return <ContentCell cell={cell} api={api} />
    case 'skillContent':
      return <SkillContentCell cell={cell} api={api} />
    case 'group':
      return <GroupCell group={cell.data} hints={cell.hints} />
    case 'addStepContent':
      return <AddStepContentCell data={cell.data} />
    case 'link':
      return <LinkCell cell={cell} />
    case 'certificate':
      return <CertificateCell certificate={cell.data} />
    case 'certificateIssuedBy':
      return <CertificateIssuedBy issuedBy={cell.data} />
    case 'issuedCertificate':
      return <IssuedCertificateCell cell={cell} api={api} hints={cell.hints} />
    case 'expiryTime':
      return <ExpiryTimeCell cellData={cell.data} />
    case 'issuedRevoked':
      return <IssuedRevokedCell issuedRevoked={cell.data} />
    case 'programVersionNumber':
      return <ProgramVersionNumberCell cellData={cell.data} hints={cell.hints} />
    case 'eventGroup':
      return <EventGroupCell cell={cell} api={api} />
    case 'singleSelect':
      return <SingleSelectCell cell={cell} />
    case 'card':
      return <CardCell cell={cell} />
    case 'canvas':
      return cell.data.view
    case 'pill':
      return <PillCell cell={cell} />
    default:
      assertNever(cell)
  }
}

type StyledInfoIconProps = {
  noMargin?: boolean
}

const StyledInfoIcon = styled(Icon).attrs({
  iconId: 'information',
  color: 'grey25',
})<StyledInfoIconProps>`
  vertical-align: middle;
  margin-left: ${p => (p.noMargin === true ? undefined : '0.25rem')};
  padding-bottom: 2px;
`
export const InfoIcon: React.FC<{
  title?: string
  noMargin?: boolean
}> = ({ title, noMargin }) => {
  if (title !== undefined) {
    return (
      <Tooltip title={title}>
        <StyledInfoIcon noMargin={noMargin} />
      </Tooltip>
    )
  } else {
    return <StyledInfoIcon noMargin={noMargin} />
  }
}

import { PathParamError, RouterProvider, createRouter } from '@tanstack/react-router'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { errorLogger } from 'sierra-client/error/error-logger'
import 'sierra-client/i18n/i18n'
import { checkPolyfills } from 'sierra-client/polyfills/polyfills'
import { routeTree } from 'sierra-client/routeTree.gen'
import { cleanEntryUrl, setGlobalRouter } from 'sierra-client/router'
import { createBrowserHistory as createBrowserHistoryWithoutBeforeUnload } from 'sierra-client/router/browser-history-no-beforeunload'
import { notFound404Redirect } from 'sierra-client/router/navigation'
import { WhoopsPage } from 'sierra-client/views/whoops-page'

checkPolyfills()
cleanEntryUrl()

const router = createRouter({
  routeTree,
  history: createBrowserHistoryWithoutBeforeUnload(),
  defaultOnCatch: error => {
    // By default we expect path param validation to result in a 404, not a 500.
    if (error instanceof PathParamError) {
      throw notFound404Redirect()
    }
  },
})

setGlobalRouter(router)

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }

  /**
   * The state property of the Location and History objects,
   * which can be passed during navigation without affecting the URL.
   */
  interface HistoryState {}
}

const rootElement = document.getElementById('app')!

// TODO: always use StrictMode
const Wrapper = import.meta.env.REACT_STRICT_MODE === 'true' ? React.StrictMode : React.Fragment

ReactDOM.createRoot(rootElement).render(
  <Wrapper>
    <RouterProvider
      router={router}
      defaultErrorComponent={({ error }) => {
        const [sentryEventId, setSentryEventId] = useState<string | undefined>()

        useEffect(() => {
          console.error(error)

          const eventId = errorLogger.captureError(error, { tags: { errorBoundary: 'router-default' } })
          setSentryEventId(eventId)
        }, [error, setSentryEventId])

        return <WhoopsPage error={error} sentryEventId={sentryEventId} />
      }}
    />
  </Wrapper>
)

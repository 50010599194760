import { useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { useCachedQuery } from 'sierra-client/state/api'
import { userDetailDataAtom } from 'sierra-client/views/manage/users/manage-user-details/atoms'
import { UserDetailResponse } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeAdminUsersUserDetail } from 'sierra-domain/routes'

type Result = ReturnType<typeof useCachedQuery<typeof XRealtimeAdminUsersUserDetail>>

export type UseUserDetailData = {
  isLoading: boolean
  refetch: () => Promise<Result>
  userData: UserDetailResponse | undefined
}

export const useUserDetailData = (userId: UserId): UseUserDetailData => {
  const setUserDataAtom = useSetAtom(userDetailDataAtom)

  const detailsRequest = useCachedQuery(
    XRealtimeAdminUsersUserDetail,
    { userId },
    { refetchOnWindowFocus: false }
  )

  useEffect(() => {
    if (detailsRequest.data !== undefined) {
      setUserDataAtom(detailsRequest.data)
    }
  }, [detailsRequest.data, setUserDataAtom])

  return {
    userData: detailsRequest.data,
    isLoading: detailsRequest.isPending,
    refetch: detailsRequest.refetch,
  }
}

import { UseQueryResult } from '@tanstack/react-query'
import {
  CachedQueryOptions,
  InvalidateQueries,
  useCachedQuery,
  useTypedInvalidateQueries,
} from 'sierra-client/state/api'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeUserMe } from 'sierra-domain/routes'
import { User } from 'sierra-domain/user'

export const useCurrentUser = <TSelectData = User>(
  options: CachedQueryOptions<User, TSelectData> = {}
): UseQueryResult<TSelectData> => {
  return useCachedQuery(
    XRealtimeUserMe,
    {},
    {
      retry: false,
      gcTime: Infinity,
      staleTime: Infinity,
      ...options,
    }
  )
}

export const useCurrentUserId = (): UseQueryResult<UserId, unknown> => {
  return useCurrentUser({ select: data => data.uuid })
}

export const useInvalidateUserCache = (): InvalidateQueries => {
  const invalidate = useTypedInvalidateQueries([XRealtimeUserMe])

  return invalidate
}

import _ from 'lodash'
import React, { useMemo } from 'react'
import { IconListItem, VerticalIconList } from 'sierra-client/components/common/icon-list'
import { SanaImage } from 'sierra-client/components/common/image'
import { RouterLink } from 'sierra-client/components/common/link'
import { joinFacilitators } from 'sierra-client/core/format'
import { useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { formatLiveSessionTimeAsSchedule } from 'sierra-client/views/manage/event-groups/event-utils'
import { Separator } from 'sierra-client/views/showcase/common'
import { LiveSessionDetailsResponse } from 'sierra-domain/api/admin'
import { CourseId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { scheduledOrNull } from 'sierra-domain/content/session'
import { Button, Heading, View } from 'sierra-ui/primitives'

export const SideBar: React.FC<{ liveSession: LiveSessionDetailsResponse }> = ({ liveSession }) => {
  const { t } = useTranslation()

  const {
    assignmentsCount,
    facilitatorsInfo,
    image,
    liveSession: {
      liveSessionId,
      data: { title, location, maxNumberOfUsers },
    },
  } = liveSession

  const session = scheduledOrNull(liveSession.liveSession.data)
  const formattedDate = formatLiveSessionTimeAsSchedule(session)

  const physicalLocation = location?.type === 'physical' ? location.value : undefined

  const assetContext: AssetContext = useMemo(
    () => ({
      type: 'course',
      courseId: CourseId.parse(liveSession.liveSession.data.flexibleContentId),
    }),
    [liveSession.liveSession.data.flexibleContentId]
  )
  const imageSrc = useResolveCourseAsset({ image, assetContext })

  // @TODO handle virtual location
  return (
    <View direction='column' gap='xsmall'>
      <SanaImage src={imageSrc} ratio='16:9' rounded />
      <Heading size='h4' bold>
        {title}
      </Heading>
      <VerticalIconList>
        {_.compact([
          <IconListItem
            key='date'
            iconId='calendar'
            color='foreground/primary'
            size='small'
            text={formattedDate ?? t('dictionary.live-session.not-scheduled')}
          />,
          physicalLocation !== undefined && (
            <IconListItem
              key='location'
              iconId='location'
              color='foreground/primary'
              size='small'
              text={physicalLocation}
            />
          ),
          // @TODO handle virtual location
          <IconListItem
            key='facilitator'
            iconId='chat' // @Todo get icon
            color='foreground/primary'
            size='small'
            text={joinFacilitators(facilitatorsInfo)}
          />,
          <IconListItem
            key='seats'
            iconId='user--group'
            color='foreground/primary'
            size='small'
            text={_.compact([assignmentsCount, maxNumberOfUsers]).join(' / ')}
          />,
        ])}
      </VerticalIconList>
      {/* <Separator top='xsmall' bottom='xsmall' /> */}
      <div>
        <RouterLink href={`/l/${liveSessionId}`}>
          <Button variant='secondary' disabled={physicalLocation !== undefined}>
            {t('admin.view')}
          </Button>
        </RouterLink>
      </div>
      <Separator top='xsmall' bottom='xsmall' />
    </View>
  )
}

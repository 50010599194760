import { FC, useMemo } from 'react'
import { SanaImage } from 'sierra-client/components/common/image'
import { useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { HeaderButton } from 'sierra-client/views/learner/home/components/start-with'
import { ProgramInfoButtonWrapper } from 'sierra-client/views/learner/program/atoms'
import {
  EnrollmentStepType,
  getStepAssetContext,
  getStepImage,
} from 'sierra-client/views/learner/program/utils/step-graphql'
import {
  getStepProgress,
  getStepTimeLeft,
  useFormatDuration,
} from 'sierra-client/views/learner/program/utils/utils'
import { isExternalLinkURL, LinkURL } from 'sierra-client/views/workspace/utils/urls'
import { isDefined } from 'sierra-domain/utils'
import { InternalTruncatedText, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

const NextStepView = styled(View)`
  width: 100%;
  border-top: 1px solid ${token('border/default')};
`

const NextStepImage = styled.div`
  width: 72px;
`

const Bold = styled.strong`
  font-weight: ${fonts.weight.bold};
`

type NextStepInfoProps = {
  step: EnrollmentStepType
  href: LinkURL
  hasStartedProgram: boolean
  displayIndex?: number
}

export const NextStepInfo: FC<NextStepInfoProps> = ({ step, href, hasStartedProgram, displayIndex }) => {
  const { t } = useTranslation()

  const image = getStepImage(step)
  const progress = getStepProgress(step)
  const displayTitle = isDefined(displayIndex)
    ? `${displayIndex + 1}. ${step.content?.title}`
    : step.content?.title
  const formattedTimeLeft = useFormatDuration(getStepTimeLeft(step), progress > 0)

  const assetContext = useMemo(() => getStepAssetContext(step), [step])

  const imageSrc = useResolveCourseAsset({
    image: image ?? undefined,
    assetContext,
    options: { type: 'course', size: 'small' },
  })

  return (
    <NextStepView paddingTop='24' direction='column' gap='none'>
      <View paddingBottom='24'>
        <NextStepImage>
          <SanaImage src={imageSrc} ratio='16:9' rounded radius='size-10' />
        </NextStepImage>
        <View direction='column' gap='none'>
          <InternalTruncatedText lines={1}>
            <Trans
              i18nKey={
                hasStartedProgram
                  ? 'program-overview.details.next-step.continue-on.text'
                  : 'program-overview.details.next-step.start.text'
              }
              values={{ content: displayTitle }}
              components={{
                bold: <Bold />,
              }}
            />
          </InternalTruncatedText>
          <Text color='foreground/muted'>{formattedTimeLeft}</Text>
        </View>
      </View>
      <ProgramInfoButtonWrapper>
        <HeaderButton grow icon='play--filled' href={href} external={isExternalLinkURL(href)}>
          {hasStartedProgram ? t('dictionary.continue') : t('dictionary.start')}
        </HeaderButton>
      </ProgramInfoButtonWrapper>
    </NextStepView>
  )
}

import _ from 'lodash'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import ReactParallaxTilt from 'react-parallax-tilt'
import useMeasure from 'react-use-measure'
import { DragItemTypes, FlipCardDragItem } from 'sierra-client/components/common/dnd/dnd-types'
import { interactiveCardGrid } from 'sierra-client/editor/layout'
import { useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { typedPost } from 'sierra-client/state/api'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { liveSessionFacilitatorFlip } from 'sierra-client/state/live-session/actions'
import { selectFlipCardStates, selectIsFacilitator } from 'sierra-client/state/live-session/selectors'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { FCC } from 'sierra-client/types'
import { useFileContext } from 'sierra-client/views/flexible-content/file-context'
import { UploadImageModal } from 'sierra-client/views/upload-image-modal/upload-image-modal'
import { removeNodeWithId, updateNodeWithId } from 'sierra-client/views/v3-author/command'
import { DisplayNone } from 'sierra-client/views/v3-author/components'
import { useEditorAssetContext, useEditorReadOnly } from 'sierra-client/views/v3-author/context'
import { useIsInPdfExport } from 'sierra-client/views/v3-author/export-pdf/use-is-pdf-export'
import {
  useGrandParent,
  useIsInLatestSelection,
  useParent,
  usePath,
  useSafeFlexibleContentId,
  useSiblings,
} from 'sierra-client/views/v3-author/hooks'
import { InteractiveCardInstruction } from 'sierra-client/views/v3-author/interactive-card-instruction'
import { assertElementType, findNode } from 'sierra-client/views/v3-author/queries'
import { RenderingContext, useRenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { BlockWrapper } from 'sierra-client/views/v3-author/wrapper'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { ImageData } from 'sierra-domain/flexible-content/types'
import { XRealtimeImportAssetsFromZip } from 'sierra-domain/routes'
import { hasOnlyEmptyTextInNodes, textInNodes } from 'sierra-domain/slate-util'
import { assertNever, iife } from 'sierra-domain/utils'
import { CustomElement } from 'sierra-domain/v3-author'
import { createFlipCard } from 'sierra-domain/v3-author/create-blocks'
import { Icon } from 'sierra-ui/components'
import { Button, Heading, IconButton, View } from 'sierra-ui/primitives'
import { palette, token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { Theme } from 'sierra-ui/theming/legacy-theme'
import { Element, Path, Transforms } from 'slate'
import { RenderElementProps, useSelected, useSlateStatic } from 'slate-react'
import styled, { ThemeProvider, css, useTheme } from 'styled-components'

type FlipCardContext = {
  flip: boolean
  setFlip: React.Dispatch<React.SetStateAction<boolean>>
}

const ReactFlipCardContext = createContext<FlipCardContext | undefined>(undefined)

const FlipCardInner = styled.div<{ flip: boolean }>`
  position: relative;
  display: grid;
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  border-radius: ${p => p.theme.borderRadius['size-10']};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
  pointer-events: none;

  ${p => (!p.flip ? '' : 'transform: rotateY(180deg);')}
`

const FlipCardFaceCss = css<{ image?: string }>`
  display: flex;
  flex-grow: 1;
  grid-area: 1 / 1 / 2 / 2;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: var(--cardWidth);
  padding: 2rem;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  border-radius: ${p => p.theme.borderRadius['size-10']};
  background-color: ${p => p.theme.home.textColor};
  color: ${p => p.theme.home.backgroundColor};
  transform-style: preserve-3d;

  ${p =>
    p.image !== undefined &&
    css`
      background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('${p.image}');
      background-position: center;
      background-size: cover;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
        border-radius: ${p => p.theme.borderRadius['size-10']};
        transition: background-color 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
        pointer-events: none;
      }
    `};
`

type FlipCardState = 'front' | 'back'

type FlipCardSideWrapperProps = {
  image?: string
  isActive: boolean
  $mode: FlipCardState
  $isSelected: boolean
}

const FlipCardSideWrapper = styled.div<FlipCardSideWrapperProps>`
  ${FlipCardFaceCss}
  pointer-events: ${p => (p.isActive ? 'all' : 'none')};

  ${p =>
    !p.isActive &&
    css`
      pointer-events: none;
      user-select: none;
    `}

  ${p =>
    p.$mode === 'back' &&
    css`
      transform: rotateY(180deg);
    `}

  ${p =>
    p.$isSelected &&
    css`
      box-shadow: 0px 0px 0px 6px ${token('form/focus/highlight')};
    `}
`

const FlipCardPdfSide = styled.div`
  ${FlipCardFaceCss}
  background-color: ${p => p.theme.home.backgroundColor};
  color: ${p => p.theme.home.textColor};
  cursor: default;
`

const InputArea = styled(View).attrs({ gap: 'none', direction: 'column', grow: true })<{ readOnly: boolean }>`
  border: none;
  cursor: ${p => (p.readOnly ? 'pointer' : 'text')};
`

const PreviewPlaceholder = styled(Heading).attrs({ size: 'h3', bold: true, contentEditable: false })`
  color: ${p => p.theme.home.textColor};
  pointer-events: none;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
`

const EditIcon = styled(Icon)`
  position: absolute;
  pointer-events: none;
  user-select: none;
  top: 2rem;
  right: 4rem;
  opacity: 0;
  transition: opacity 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
  padding-left: 0.5rem;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
`

const HoverContainer = styled(View)`
  opacity: 0;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  width: fit-content;
  transition: opacity 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
`

type ParallaxTiltContainerProps = {
  height: number
}

const ParallaxTiltContainer = styled(ReactParallaxTilt).attrs<ParallaxTiltContainerProps>(p => ({
  glareEnable: false,
  transitionSpeed: 500,
  tiltMaxAngleX: 2 / (p.height * 0.005),
  tiltMaxAngleY: 2,
}))<ParallaxTiltContainerProps>`
  scroll-snap-align: start;
  height: 100%;
`

const DragIconContainer = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: 0;
  cursor: grab;
  transition: opacity 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
`

const FlipCardContainer = styled.div`
  background-color: transparent;
  position: relative;
  width: var(--cardWidth);
  min-height: var(--cardHeight);
  height: 100%;
  perspective: 1000px;
  cursor: pointer;
  &:hover {
    ${EditIcon} {
      opacity: 0.5;
    }
    ${DragIconContainer} {
      opacity: 0.5;
    }
    ${HoverContainer} {
      opacity: 1;
    }
  }
`

const SettingsButtonWrapper = styled(View)`
  pointer-events: all;
`

const FlipCardFaceBottomContainer: React.FC<{
  hasImage: boolean
  element: RenderElementProps['element']
  activeFace: 'front' | 'back'
}> = ({ hasImage, element, activeFace }) => {
  const { t } = useTranslation()
  const readOnly = useEditorReadOnly()
  const context = useContext(ReactFlipCardContext)
  const editor = useSlateStatic()
  const parent = useParent({ nodeId: element.id })
  const grandParent = useGrandParent({ nodeId: element.id })
  const aunties = grandParent?.children ?? []

  const [open, setOpen] = useState(false)

  const updateBackground = useCallback(
    (image: ImageData['image'] | undefined): void => {
      if (!hasImage) {
        setOpen(!open)
      }
      updateNodeWithId(editor, element.id, { image: image })
    },
    [editor, element.id, hasImage, open]
  )

  const assetContext = useEditorAssetContext()

  if (context === undefined) return <></>
  return (
    <>
      <View contentEditable={false} justifyContent='space-between' borderColor='transparent'>
        <Button
          variant='secondary'
          onClick={() => {
            context.setFlip(!context.flip)
          }}
          icon='rotate--360'
        >
          {activeFace === 'front'
            ? t('author.flip-cards.flip-to-back')
            : t('author.flip-cards.flip-to-front')}
        </Button>

        {!readOnly && (
          <SettingsButtonWrapper>
            <IconButton
              variant={hasImage ? 'transparent' : 'primary'}
              color={hasImage ? 'white' : 'surface/default'}
              iconId={hasImage ? 'no-image' : 'image'}
              onClick={() => updateBackground(undefined)}
            />
            {aunties.length > 1 && (
              <IconButton
                iconId='trash-can'
                variant={hasImage ? 'transparent' : 'primary'}
                color={hasImage ? 'white' : 'surface/default'}
                tooltip='Delete flip card'
                onClick={() => {
                  if (Element.isElement(parent)) {
                    removeNodeWithId(editor, parent.id)
                  }
                }}
              />
            )}
          </SettingsButtonWrapper>
        )}
      </View>
      {!hasImage && (
        <UploadImageModal
          open={open}
          onUploadDone={image => updateBackground(image)}
          onClose={() => setOpen(false)}
          assetContext={assetContext}
        />
      )}
    </>
  )
}

const FlipCardWrapper: FCC<{ hasImage: boolean; readOnly: boolean }> = ({ children, hasImage, readOnly }) => {
  const patchTheme = useCallback(
    (theme: Theme): Theme => ({
      ...theme,
      home: {
        ...theme.home,
        backgroundColor: theme.home.textColor,
        textColor: hasImage ? palette.primitives.white : theme.home.backgroundColor,
      },
    }),
    [hasImage]
  )

  return (
    <RenderingContext disableMenu={false} disableMiniMenu={true}>
      <InputArea readOnly={readOnly}>
        <ThemeProvider theme={patchTheme}>{children}</ThemeProvider>
      </InputArea>
    </RenderingContext>
  )
}

export const FlipCardSideComponent = React.forwardRef<HTMLDivElement, SlateWrapperProps>(
  ({ children, attributes, element, ...props }, ref) => {
    assertElementType(['flip-card-front', 'flip-card-back'], element)
    const { t } = useTranslation()

    const side = iife(() => {
      switch (element.type) {
        case 'flip-card-front':
          return 'front'
        case 'flip-card-back':
          return 'back'
        default:
          assertNever(element)
      }
    })

    const { image } = element

    const flexibleContentId = useSafeFlexibleContentId()
    const editor = useSlateStatic()

    // Handle copy-pasting of file attachments between courses/orgs
    const [imageIsImporting, setImageIsImporting] = useState(() => {
      const fileId = image?.type === 'file' ? image.file : undefined
      if (fileId === undefined) return false
      return editor.importingAssetsFileUrls[fileId] !== undefined
    })

    useEffect(() => {
      if (!imageIsImporting) return
      const importAsset = async (): Promise<void> => {
        if (flexibleContentId === undefined) return
        const fileId = image?.type === 'file' ? image.file : undefined
        if (fileId === undefined) return
        const signedUrl = editor.importingAssetsFileUrls[fileId]
        if (signedUrl === undefined) return
        await typedPost(XRealtimeImportAssetsFromZip, {
          courseId: flexibleContentId,
          signedUrl,
          filterImageId: [fileId],
        })
        setImageIsImporting(false)
      }
      void importAsset()
    }, [flexibleContentId, editor, imageIsImporting, setImageIsImporting, image])

    const assetContext = useMemo(
      () =>
        flexibleContentId !== undefined
          ? { type: 'course' as const, courseId: flexibleContentId }
          : { type: 'unknown' as const },
      [flexibleContentId]
    )
    const resolvedBackgroundImage = useResolveCourseAsset({
      image,
      assetContext,
      options: { size: 'normal', type: 'course' },
    })
    const backgroundImageSrc = image !== undefined && !imageIsImporting ? resolvedBackgroundImage : undefined

    const context = useContext(ReactFlipCardContext)
    const { preview } = useRenderingContext()

    const isSelected = useIsInLatestSelection({ nodeId: element.id })

    const dispatch = useDispatch()
    const parent = useParent({ nodeId: element.id })
    const showBothFlipCardSides = useIsInPdfExport()

    const isFacilitator = useSelector(selectIsFacilitator)

    const isEmpty = hasOnlyEmptyTextInNodes([element]) && !props.readOnly

    const handleFlipACard = useCallback(() => {
      if (props.readOnly && context !== undefined) {
        context.setFlip(!context.flip)

        if (isFacilitator === true && Element.isElement(parent)) {
          dispatch(liveSessionFacilitatorFlip({ cardId: parent.id, flip: !context.flip }))
        }
      }
    }, [context, dispatch, isFacilitator, parent, props.readOnly])

    const hasImage = image !== undefined
    const patchThemeShowBoth = useCallback(
      (theme: Theme): Theme => ({
        ...theme,
        home: {
          ...theme.home,
          backgroundColor: theme.home.textColor,
          textColor: hasImage ? palette.primitives.white : theme.home.backgroundColor,
        },
      }),
      [hasImage]
    )

    if (context === undefined) return <></>
    if (showBothFlipCardSides === true) {
      return (
        <View {...attributes} {...props} ref={ref}>
          <ThemeProvider theme={patchThemeShowBoth}>
            <FlipCardPdfSide image={backgroundImageSrc}>{children}</FlipCardPdfSide>
          </ThemeProvider>
        </View>
      )
    }

    return (
      <FlipCardSideWrapper
        $isSelected={isSelected === true && !props.readOnly}
        $mode={side}
        isActive={context.flip ? side === 'back' : side === 'front'}
        onClick={handleFlipACard}
        image={backgroundImageSrc}
        {...attributes}
        {...props}
        ref={ref}
      >
        <FlipCardWrapper hasImage={image !== undefined} readOnly={props.readOnly}>
          {children}
        </FlipCardWrapper>
        {preview === false && !props.readOnly && (
          <FlipCardFaceBottomContainer activeFace={side} hasImage={image !== undefined} element={element} />
        )}
        {isEmpty && <PreviewPlaceholder>{t('author.slate.heading')}</PreviewPlaceholder>}
      </FlipCardSideWrapper>
    )
  }
)

const FlipCardInnerComponent: FCC = ({ children }) => {
  const context = useContext(ReactFlipCardContext)

  if (context === undefined) return <></>
  return <FlipCardInner flip={context.flip}>{children}</FlipCardInner>
}

const useRemoteFlipChanged = (cardId?: string): boolean | undefined =>
  useSelector(selectFlipCardStates)?.[cardId ?? '']?.flip

const DragAndDropContainer = styled.div<{
  $isDragging: boolean
  $indicateDropBorder: 'left' | 'right' | undefined
}>`
  opacity: ${p => (p.$isDragging ? '0.5' : '1')};
  height: 100%;
  min-width: 100%;
  border-radius: ${p => p.theme.borderRadius['size-10']};
  transition: box-shadow transform 0.5s;

  ${p =>
    p.$indicateDropBorder === 'right' &&
    css`
      &::before {
        content: '';
        position: absolute;
        width: 5px;
        border-radius: 2.5px;
        height: 100%;
        background-color: ${token('foreground/primary').opacity(0.25)};
        right: -10.5px;
      }
    `}

  ${p =>
    p.$indicateDropBorder === 'left' &&
    css`
      &::before {
        content: '';
        position: absolute;
        width: 5px;
        border-radius: 2.5px;
        height: 100%;
        background-color: ${token('foreground/primary').opacity(0.25)};
        left: -10.5px;
      }
    `}
`

export const FlipCard = React.forwardRef<HTMLDivElement, SlateWrapperProps>(
  ({ children, attributes, readOnly, ...props }, ref) => {
    assertElementType('flip-card', props.element)
    const remoteHasFlipped = useRemoteFlipChanged(props.element.id)
    const [sizeRef, { height }] = useMeasure()
    const [flip, setFlip] = useState<boolean>(remoteHasFlipped ?? false)
    const editor = useSlateStatic()
    const { preview } = useRenderingContext()

    useEffect(() => {
      if (remoteHasFlipped !== undefined) {
        setFlip(remoteHasFlipped)
      }
    }, [remoteHasFlipped])

    const siblings = useSiblings({ nodeId: props.element.id })
    const parent = useParent({ nodeId: props.element.id })
    const indexOfCurrentFlipCard = siblings.indexOf(props.element)
    const isMostLeftSibling = indexOfCurrentFlipCard === 0
    const isMostRightSibling = indexOfCurrentFlipCard === siblings.length - 1
    const [, dropPath] = findNode(editor, n => n.id === props.element.id)
    const [dropIndicator, setDropIndicator] = useState<'left' | 'right'>()

    const nodeRef = useRef<HTMLDivElement | null>(null)
    const dragElementRef = useRef<HTMLDivElement | null>(null)

    const front = props.element.children[0]
    const firstTextNodeInFront = textInNodes(front ? [front] : [])[0]
    const theme = useTheme()

    const [{ isDragging }, dragRef] = useDrag<FlipCardDragItem, void, { isDragging: boolean }>(
      () => ({
        type: DragItemTypes.FlipCard,
        item: {
          firstTextNode: firstTextNodeInFront,
          currentPath: dropPath,
          theme: theme,
          type: DragItemTypes.FlipCard,
          parentId: (parent as CustomElement | undefined)?.id as NanoId12 | undefined,
        },
        collect: monitor => ({ isDragging: monitor.isDragging() }),
      }),
      [dropPath, firstTextNodeInFront, parent, theme]
    )

    const [{ isOver, canDrop }, dropRef] = useDrop<
      FlipCardDragItem,
      void,
      { isOver: boolean; canDrop: boolean }
    >(() => {
      return {
        accept: DragItemTypes.FlipCard,
        collect: monitor => ({
          isOver: monitor.isOver({ shallow: true }),
          canDrop: monitor.canDrop(),
        }),
        hover: (item, monitor) => {
          if (!monitor.isOver({ shallow: true })) return

          const hoveringPosition = monitor.getClientOffset()
          const targetBoundingBox = nodeRef.current?.getBoundingClientRect()

          if (hoveringPosition !== null && targetBoundingBox !== undefined) {
            const hoveredPortion = (hoveringPosition.x - targetBoundingBox.x) / targetBoundingBox.width
            if (hoveredPortion > 0.5) {
              setDropIndicator('right')
            } else {
              setDropIndicator('left')
            }
          }
        },
        canDrop: item => {
          if (_.get(parent, 'id') !== item.parentId) return false
          if (dropPath === undefined) return false
          if (_.isEqual(dropPath, item.currentPath)) return false
          if (
            !isMostLeftSibling &&
            dropIndicator === 'left' &&
            _.isEqual(item.currentPath, Path.previous(dropPath))
          )
            return false
          if (
            !isMostRightSibling &&
            dropIndicator === 'right' &&
            _.isEqual(item.currentPath, Path.next(dropPath))
          )
            return false

          return true
        },
        drop: (item, monitor) => {
          if (monitor.didDrop()) {
            return
          }

          if (dropPath === undefined || item.currentPath === undefined) return

          //Dropping at right side of a flip-card
          if (dropIndicator === 'right') {
            if (isMostLeftSibling) {
              return Transforms.moveNodes(editor, { at: item.currentPath, to: Path.next(dropPath) })
            }
            if (isMostRightSibling) {
              return Transforms.moveNodes(editor, { at: item.currentPath, to: dropPath })
            }

            // If dragging from left to right
            if (Path.isBefore(item.currentPath, dropPath)) {
              return Transforms.moveNodes(editor, { at: item.currentPath, to: dropPath })
            }

            return Transforms.moveNodes(editor, { at: item.currentPath, to: Path.next(dropPath) })
          }

          //Dropping on left side of flip card
          if (isMostLeftSibling) {
            return Transforms.moveNodes(editor, { at: item.currentPath, to: dropPath })
          }
          if (isMostRightSibling) {
            return Transforms.moveNodes(editor, { at: item.currentPath, to: Path.previous(dropPath) })
          }
          if (Path.isBefore(item.currentPath, dropPath)) {
            return Transforms.moveNodes(editor, { at: item.currentPath, to: Path.previous(dropPath) })
          }

          return Transforms.moveNodes(editor, { at: item.currentPath, to: dropPath })
        },
      }
    }, [parent, dropPath, isMostLeftSibling, dropIndicator, isMostRightSibling, editor])

    const flipCardContextValue = useMemo(() => ({ flip, setFlip }), [flip, setFlip])

    dragRef(dragElementRef)
    dropRef(nodeRef)

    return (
      <ReactFlipCardContext.Provider value={flipCardContextValue}>
        <div {...attributes} {...props} ref={ref}>
          <DragAndDropContainer
            ref={nodeRef}
            $isDragging={isDragging}
            contentEditable={isDragging ? false : undefined}
            $indicateDropBorder={canDrop && isOver ? dropIndicator : undefined}
          >
            <ParallaxTiltContainer height={height}>
              <FlipCardContainer ref={sizeRef}>
                <FlipCardInnerComponent>{children}</FlipCardInnerComponent>
                {!readOnly && (
                  <DragIconContainer ref={dragElementRef} contentEditable={false}>
                    <Icon
                      size='size-16'
                      iconId='draggable'
                      color={preview === true ? 'surface/default' : 'black'}
                    />
                  </DragIconContainer>
                )}
              </FlipCardContainer>
            </ParallaxTiltContainer>
          </DragAndDropContainer>
        </div>
      </ReactFlipCardContext.Provider>
    )
  }
)

const CardContainerCss = css`
  --cardWidth: 349px;
  --cardHeight: 456px;

  @media screen and (max-width: ${v2_breakpoint.phone}) {
    --cardWidth: 100%;
  }

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--cardWidth), var(--cardWidth)));
  grid-gap: 1rem;
`

const CardContainer = styled.div<{ isGeneralCard: boolean }>`
  ${CardContainerCss}
  justify-content: start;
`

const UnselectableView = styled(View)`
  user-select: none;
  direction: ltr;
`

export const FlipCardsCardRow: SlateFC = ({ children, element, readOnly }) => {
  assertElementType('flip-cards-card-container', element)
  const editor = useSlateStatic()
  const { t } = useTranslation()
  const path = usePath({ nodeId: element.id })
  const newOptionIndex = element.children.length
  const preview = !useSelected()
  const parent = useParent({ nodeId: element.id })
  const isGeneralCard = useFileContext().file.data.type === 'general'
  const addOption = useCallback((): void => {
    Transforms.insertNodes(editor, createFlipCard(), { at: [...path, newOptionIndex] })
  }, [path, editor, newOptionIndex])

  return (
    <RenderingContext preview={preview}>
      <CardContainer isGeneralCard={isGeneralCard} contentEditable={false}>
        <InteractiveCardInstruction>{t('learner.flip-cards.flip-cards')}</InteractiveCardInstruction>
      </CardContainer>

      <View data-block-inner={_.get(parent, 'id')} direction='column' gap='xsmall'>
        <CardContainer isGeneralCard={isGeneralCard}>{children}</CardContainer>

        {!readOnly && (
          <CardContainer contentEditable={false} isGeneralCard={isGeneralCard}>
            <UnselectableView justifyContent='flex-start' contentEditable={false} marginTop='xsmall'>
              <Button icon='add' onClick={addOption}>
                {t('author.flip-cards.add-flip-card')}
              </Button>
            </UnselectableView>
          </CardContainer>
        )}
      </View>
    </RenderingContext>
  )
}

export const FlipCardsCardContainer = styled(View).attrs({ marginTop: '16', gap: '16', direction: 'column' })`
  grid-column-end: -1;

  @media screen and (max-width: ${v2_breakpoint.phone}) {
    padding: 0;
  }
`

const StyledBlockWrapper = styled(BlockWrapper)<{ $isGeneralCard: boolean }>`
  ${p => (!p.$isGeneralCard ? interactiveCardGrid : `padding: 0;`)}

  row-gap: 0;
  margin-top: auto;
  margin-bottom: auto;
`

export const FlipCardsContainer = React.forwardRef<HTMLDivElement, SlateWrapperProps>((props, ref) => {
  const { children, attributes, element, ...rest } = props
  assertElementType('flip-cards', element)
  const userId = useSelector(selectUserId)
  const isGeneralCard = useFileContext().file.data.type === 'general'

  if (userId === undefined) return <DisplayNone>{children}</DisplayNone>
  else
    return (
      <RenderingContext disableMenu={false}>
        <StyledBlockWrapper $isGeneralCard={isGeneralCard} {...props} {...attributes} {...rest} ref={ref}>
          <RenderingContext withGrid={false}>{children}</RenderingContext>
        </StyledBlockWrapper>
      </RenderingContext>
    )
})

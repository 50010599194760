import _ from 'lodash'
import { useMemo, useState, type FormEventHandler } from 'react'
import {
  ContentType,
  ExpensiveContentFragmentFragment,
  VisibilityContentFragmentFragment,
} from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { getAssetContextFromContentFragment } from 'sierra-client/components/util/asset-contex'
import { useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import type { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { ListVirtualizer } from 'sierra-client/views/workspace/components/list-virtualizer'
import { StyledThumbnail } from 'sierra-client/views/workspace/create/styled-thumbnail'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { TruncatedText } from 'sierra-ui/components'
import { Button, Checkbox, ScrollView, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
`

const RowContainer = styled.label`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  margin: 0px 24px 8px;
  cursor: pointer;
`

const OverFlowHiddenView = styled(View)`
  width: 100%;
  overflow: hidden;
`

const ContentWrapper = styled(View).attrs({ justifyContent: 'space-between' })`
  width: 100%;
  padding: 8px 16px;
  border-radius: 12px;
  transition: all 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    background-color: ${token('surface/soft')};
  }
`

const useContentTypeLabel = (content: ExpensiveContentFragmentFragment): string => {
  const { t } = useTranslation()

  if ('courseKind' in content) {
    switch (content.courseKind) {
      case 'LINK':
      case 'LINKEDIN':
      case 'SCORM':
      case 'SCORM_COURSE_GROUP':
        return t('dictionary.external')
      case 'NATIVE_COURSE_GROUP':
      case 'NATIVE_SELF_PACED':
        return t('dictionary.course-singular')
      case 'NATIVE_LIVE':
        return t('dictionary.live')
      case 'NATIVE_EVENT_GROUP':
        return t('dictionary.event')
    }
    content.courseKind satisfies never
  }

  switch (content.contentType) {
    case 'COURSE':
      return t('dictionary.course-singular')
    case 'PATH':
      return t('dictionary.path-singular')
    case 'PROGRAM':
      return t('dictionary.program-singular')
  }
  content.contentType satisfies never
}

const ContentRow: React.FC<{
  selected: boolean
  content: ExpensiveContentFragmentFragment
  onSelectedChange: (selected: boolean) => void
}> = ({ selected, content, onSelectedChange }) => {
  const contentTypeLabel = useContentTypeLabel(content)

  const assetContext = useMemo(() => getAssetContextFromContentFragment(content), [content])
  const imageSrc = useResolveCourseAsset({
    image: convertGQLImage(content.image),
    assetContext,
    options: {
      type: 'default',
      size: 'small',
    },
  })

  return (
    <RowContainer>
      <ContentWrapper>
        <OverFlowHiddenView gap='6'>
          <StyledThumbnail image={imageSrc} />
          <OverFlowHiddenView direction='column' gap='none'>
            <TruncatedText lines={2} size='small' bold>
              {content.title}
            </TruncatedText>
            <OverFlowHiddenView gap='2'>
              <Text size='small' color='foreground/muted'>
                {contentTypeLabel}
              </Text>
            </OverFlowHiddenView>
          </OverFlowHiddenView>
        </OverFlowHiddenView>
        <Checkbox
          checked={selected}
          onCheckedChange={checked => {
            if (checked !== 'indeterminate') {
              onSelectedChange(checked)
            }
          }}
        />
      </ContentWrapper>
    </RowContainer>
  )
}

export const isContentNotVisible = (content: VisibilityContentFragmentFragment): boolean => {
  if (content.__typename === 'Path') {
    return content.isPathVisible === false
  } else if (content.__typename === 'Program') {
    return content.programVisibility !== 'VISIBLE'
  } else {
    return content.courseVisibility !== 'VISIBLE'
  }
}

const convertContentTypeToTranslationKey = (type: ContentType): TranslationKey => {
  switch (type) {
    case 'COURSE':
      return 'content-table.types.course'
    case 'PATH':
      return 'content-table.types.path'
    case 'PROGRAM':
      return 'content-table.types.program'
    default:
      assertNever(type)
  }
}

const ModalMessage: React.FC<{ selectedContent: ExpensiveContentFragmentFragment[] }> = ({
  selectedContent,
}) => {
  const { t } = useTranslation()

  if (isDefined(selectedContent[0]) && selectedContent.length === 1) {
    const contentType = selectedContent[0].contentType
    const contentTypeTranslationKey = convertContentTypeToTranslationKey(contentType)

    return (
      <Text>
        {t('skills.assign-content.confirmation-modal-body', {
          contentType: t(contentTypeTranslationKey).toLowerCase(),
          contentTitle: selectedContent[0].title,
        })}
      </Text>
    )
  } else if (selectedContent.length === 2 && isDefined(selectedContent[0]) && isDefined(selectedContent[1])) {
    const firstContentType = selectedContent[0].contentType
    const secondContentType = selectedContent[1].contentType
    const firstContentTypeTranslationKey = convertContentTypeToTranslationKey(firstContentType)
    const secondContentTypeTranslationKey = convertContentTypeToTranslationKey(secondContentType)

    return (
      <Text>
        {t('skills.assign-content.confirmation-modal-body-two', {
          contentType1: t(firstContentTypeTranslationKey).toLowerCase(),
          contentTitle1: selectedContent[0].title,
          contentType2: t(secondContentTypeTranslationKey).toLowerCase(),
          contentTitle2: selectedContent[1].title,
        })}
      </Text>
    )
  } else if (selectedContent.length > 2 && isDefined(selectedContent[0]) && isDefined(selectedContent[1])) {
    const firstContentType = selectedContent[0].contentType
    const secondContentType = selectedContent[1].contentType
    const firstContentTypeTranslationKey = convertContentTypeToTranslationKey(firstContentType)
    const secondContentTypeTranslationKey = convertContentTypeToTranslationKey(secondContentType)

    return (
      <Text>
        {t('skills.assign-content.confirmation-modal-body-many', {
          contentType1: t(firstContentTypeTranslationKey).toLowerCase(),
          contentTitle1: selectedContent[0].title,
          contentType2: t(secondContentTypeTranslationKey).toLowerCase(),
          contentTitle2: selectedContent[1].title,
          countOther: selectedContent.length - 2,
        })}
      </Text>
    )
  } else return ''
}

export const SelectContentForm: React.FC<{
  onCancel: () => void
  onSubmit: (content: ExpensiveContentFragmentFragment[]) => void
  content: ExpensiveContentFragmentFragment[]
}> = ({ onCancel, onSubmit, content }) => {
  const { t } = useTranslation()
  const [nodeRef, setNodeRef] = useState<HTMLDivElement | null>(null)

  const [selectedContent, setSelectedContent] = useState<ExpensiveContentFragmentFragment[]>([])
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)

  const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault()

    const someNotVisibleToAll = selectedContent.some(isContentNotVisible)

    someNotVisibleToAll ? setOpenConfirmationModal(true) : onSubmit(selectedContent)
  }

  // Weird bug in virtualizer where if `nodeRef` doesnt exist, the list will not virtualize
  // Here we just make the nodeRef just rerender the whole list
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <ScrollView ref={setNodeRef} grow>
          {nodeRef && (
            <ListVirtualizer
              scrollElement={nodeRef}
              items={content}
              estimateSize={66}
              renderItem={item => {
                return (
                  <ContentRow
                    content={item}
                    selected={selectedContent.find(it => it.contentId === item.contentId) !== undefined}
                    onSelectedChange={selected => {
                      if (!selected) {
                        setSelectedContent(prev => _.reject(prev, { contentId: item.contentId }))
                      } else {
                        setSelectedContent(prev => [...prev, item])
                      }
                    }}
                  />
                )
              }}
            />
          )}
        </ScrollView>

        <View paddingLeft='40' paddingRight='40' paddingBottom='24' gap='8' justifyContent='flex-end'>
          <Button variant='secondary' type='button' onClick={onCancel}>
            {t('dictionary.cancel')}
          </Button>
          <Button disabled={selectedContent.length === 0} type='submit'>
            {t('dictionary.save')}
          </Button>
        </View>
      </Form>
      <ActionModal
        open={openConfirmationModal}
        primaryActionLabel={t('dictionary.continue')}
        primaryAction={() => onSubmit(selectedContent)}
        secondaryAction={() => setOpenConfirmationModal(false)}
        secondaryActionLabel={t('dictionary.cancel')}
        title={t('skills.assign-content.confirmation-modal-title')}
        onClose={() => setOpenConfirmationModal(false)}
        size={{ width: 450 }}
      >
        <ModalMessage selectedContent={selectedContent} />
      </ActionModal>
    </>
  )
}

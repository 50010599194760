import { AnimatePresence, AnimationProps, motion } from 'framer-motion'
import { useCallback, useRef, useState } from 'react'
import { Emoji } from 'sierra-client/components/common/emoji'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { MUIClickAwayListener, MUIPopper } from 'sierra-ui/mui'
import { IconButton, View } from 'sierra-ui/primitives'
import { palette, spacing } from 'sierra-ui/theming'
import styled from 'styled-components'

const EmojiInputButtonContainer = styled.div`
  position: relative;
`

const EmojiInputInnerContainer = styled(View)`
  border-radius: 16px;
  background-color: white;
  padding: ${spacing['4']};

  border: 1px solid ${palette.grey[5]};
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`

const Spacer = styled.div`
  padding-bottom: ${spacing['4']};
  background-color: transparent;
`

const EmojiButton = styled.button<{ $active: boolean }>`
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  overflow: hidden;
  background-color: ${p => (p.$active ? palette.grey[5] : undefined)};
  border-radius: 6px;

  &:hover {
    background-color: ${palette.grey[5]};
  }
`

type ReactionInputProps = {
  onReactionClicked: (reaction: string) => void
  currentSelectedReactions: string[]
}

const REACTIONS = [':eyes:', ':+1:', ':100:', ':heart:', ':cry:', ':white_check_mark:']

const fadeInOutAnimationProps: AnimationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1, y: -2 },
  exit: { opacity: 0, y: 0 },
  transition: { duration: 0.1 },
}

export const ReactionInput: React.FC<ReactionInputProps> = ({
  onReactionClicked,
  currentSelectedReactions,
}) => {
  const closeTimeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const [open, setOpen] = useState(false)
  const anchor = useRef<HTMLButtonElement | null>(null)
  const { t } = useTranslation()

  const react = useCallback(
    (reaction: string) => {
      return () => {
        onReactionClicked(reaction)
        setOpen(false)
      }
    },
    [onReactionClicked]
  )

  return (
    <EmojiInputButtonContainer
      onMouseEnter={() => {
        clearTimeout(closeTimeoutRef.current)
        setOpen(true)
      }}
      onMouseLeave={(): void => {
        clearTimeout(closeTimeoutRef.current)
        closeTimeoutRef.current = setTimeout(() => setOpen(false), 30)
      }}
    >
      <IconButton
        variant='transparent'
        iconId='face--add'
        color='grey25'
        onClick={() => {
          setOpen(current => !current)
        }}
        ref={anchor}
        tooltip={t('dictionary.react')}
      />
      {anchor.current && (
        <MUIPopper placement='top' open anchorEl={anchor.current}>
          <AnimatePresence>
            {open && (
              <motion.div {...fadeInOutAnimationProps}>
                <MUIClickAwayListener onClickAway={() => setOpen(false)}>
                  <Spacer>
                    <EmojiInputInnerContainer direction='row'>
                      {REACTIONS.map(reaction => (
                        <EmojiButton
                          key={reaction}
                          $active={currentSelectedReactions.includes(reaction)}
                          onClick={react(reaction)}
                        >
                          <Emoji emojiCode={reaction} size={18} />
                        </EmojiButton>
                      ))}
                    </EmojiInputInnerContainer>
                  </Spacer>
                </MUIClickAwayListener>
              </motion.div>
            )}
          </AnimatePresence>
        </MUIPopper>
      )}
    </EmojiInputButtonContainer>
  )
}

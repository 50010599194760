import { isFulfilled } from '@reduxjs/toolkit'
import { useSetAtom } from 'jotai'
import _ from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useConfirmationModalContext } from 'sierra-client/components/common/modals/confirmation-modal'
import { config } from 'sierra-client/config/global-config'
import { Logging } from 'sierra-client/core/logging'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useCachedQuery } from 'sierra-client/state/api'
import * as settingsState from 'sierra-client/state/author-course-settings/slice'
import * as flexActions from 'sierra-client/state/flexible-content/actions'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { manageSessionsPanelStateAtom } from 'sierra-client/views/flexible-content/editor/topbar/manage-sessions-panel-state'
import { LiveContentId } from 'sierra-domain/api/nano-id'
import { FolderId } from 'sierra-domain/flexible-content/identifiers'
import { XRealtimeAuthorLiveSessionsListLiveSessions } from 'sierra-domain/routes'
import { assertNever } from 'sierra-domain/utils'
import { MenuButton, MenuItem } from 'sierra-ui/components'

type LiveContentTopbarProps = {
  title: string | undefined
  liveContentId: LiveContentId
  rootFolderId: FolderId
}

type ScheduleMenuItem = MenuItem<'start' | 'schedule' | 'view'>
export const LegacyLiveContentTopbar: React.FC<LiveContentTopbarProps> = ({
  title,
  liveContentId,
  rootFolderId,
}) => {
  const { t } = useTranslation()
  const userId = useSelector(selectUserId)
  const dispatch = useDispatch()

  const collaboratorsIdsAboveOrEqualEditAccess = useSelector(
    settingsState.selectors.selectCollaboratorIdsAboveOrEqualToEditAccess
  )
  const setPanelState = useSetAtom(manageSessionsPanelStateAtom)
  const liveRoomsEnabled = useFlag('live-session-rooms')

  const onLaunchInstantLiveClick = useCallback(async (): Promise<void> => {
    if (userId === undefined) return

    void dispatch(Logging.liveSession.instantSessionStarted())

    const result = await dispatch(
      flexActions.launchInstantLive({
        flexibleContentId: liveContentId,
        folderId: rootFolderId,
        facilitatorIds: collaboratorsIdsAboveOrEqualEditAccess,
        title: title ?? 'Untitled session',
      })
    )

    if (isFulfilled(result)) {
      const { liveSessionId, liveRoomId } = result.payload
      const url =
        liveRoomsEnabled && liveRoomId !== undefined
          ? new URL(`${window.location.origin}/room/${liveRoomId}`)
          : new URL(`${window.location.origin}/l/${liveSessionId}`)

      const host = config.auth.host
      if (host !== undefined) url.searchParams.set('x-host', host)

      window.open(url, '_blank')
    }
  }, [
    collaboratorsIdsAboveOrEqualEditAccess,
    dispatch,
    liveContentId,
    liveRoomsEnabled,
    rootFolderId,
    title,
    userId,
  ])

  const menuItems = useMemo<ScheduleMenuItem[]>(
    () => [
      {
        id: 'start',
        type: 'label',
        label: t('author.start-session'),
      },
      {
        id: 'schedule',
        type: 'label',
        label: t('author.schedule-session'),
      },
      {
        id: 'view',
        type: 'label',
        label: t('dictionary.view-all'),
      },
    ],
    [t]
  )

  return (
    <div id='tour-schedule-create'>
      <MenuButton
        variant='destructive'
        onSelect={item => {
          switch (item.id) {
            case 'start':
              void onLaunchInstantLiveClick()
              return
            case 'schedule':
              setPanelState({ type: 'create-session' })
              return
            case 'view':
              setPanelState({ type: 'list-sessions' })
              return
            default:
              assertNever(item.id)
          }
        }}
        onPrimaryClick={() => setPanelState({ type: 'create-session' })}
        menuItems={menuItems}
        menuLabel={t('author.more-schedule-actions')}
      >
        {t('author.schedule-session')}
      </MenuButton>
    </div>
  )
}

const SanaNowContentTopBar: React.FC<LiveContentTopbarProps> = ({ title, liveContentId, rootFolderId }) => {
  const userId = useSelector(selectUserId)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const setPanelState = useSetAtom(manageSessionsPanelStateAtom)

  const collaboratorsIdsAboveOrEqualEditAccess = useSelector(
    settingsState.selectors.selectCollaboratorIdsAboveOrEqualToEditAccess
  )
  const liveRoomsEnabled = useFlag('live-session-rooms')

  const onLaunchSanaNowClick = useCallback(async (): Promise<void> => {
    if (userId === undefined) return

    void dispatch(Logging.liveSession.instantSessionStarted())

    const result = await dispatch(
      flexActions.launchInstantNow({
        flexibleContentId: liveContentId,
        folderId: rootFolderId,
        facilitatorIds: collaboratorsIdsAboveOrEqualEditAccess,
        title: title ?? 'Untitled session',
      })
    )

    if (isFulfilled(result)) {
      void dispatch(
        Logging.liveSession.sanaNowPresentClicked({
          liveSessionId: result.payload.liveSessionId,
          contentId: liveContentId,
        })
      )
      const { liveSessionId, liveRoomId } = result.payload
      if (liveRoomsEnabled && liveRoomId !== undefined) {
        void getGlobalRouter().navigate({ to: `/room/${liveRoomId}` })
      } else {
        void getGlobalRouter().navigate({ to: `/l/${liveSessionId}` })
      }
    }
  }, [
    collaboratorsIdsAboveOrEqualEditAccess,
    dispatch,
    liveContentId,
    liveRoomsEnabled,
    rootFolderId,
    title,
    userId,
  ])

  type ScheduleMenuItem = MenuItem<'start-now' | 'schedule-for-later' | 'separator' | 'view-scheduled'>
  const menuItems = useMemo<ScheduleMenuItem[]>(() => {
    const items: ScheduleMenuItem[] = [
      {
        id: 'start-now',
        type: 'label',
        icon: 'play--circle--outline',
        label: t('author.schedule-sana-now-session.start-now'),
      },
      {
        id: 'schedule-for-later',
        type: 'label',
        icon: 'calendar',
        label: t('author.schedule-sana-now-session.schedule'),
      },
      { id: 'separator', type: 'separator' },
      {
        id: 'view-scheduled',
        type: 'label',
        label: t('author.schedule-sana-now-session.view-scheduled'),
      },
    ]

    return items
  }, [t])

  // This is also used to list ongoing sessions in the sidebar, where
  // we also subscribe to ably updates which triggers refetches.
  const ongoingLiveSessionQuery = useCachedQuery(
    XRealtimeAuthorLiveSessionsListLiveSessions,
    {
      flexibleContentId: liveContentId,
    },
    {
      select(data) {
        return _.chain(data.liveSessions)
          .filter(session => session.data.startedAt !== undefined && session.data.endedAt === undefined)
          .sortBy(session => session.data.startedAt)
          .last()
          .value()
      },
    }
  )

  const hasOngoingLiveSession = ongoingLiveSessionQuery.data !== undefined
  const confirmationModalContext = useConfirmationModalContext()
  const linkingToEditorFlagEnabled = useFlag('linking-to-editor')

  return (
    <div id='tour-schedule-create'>
      <MenuButton
        menuWidth={250}
        variant='success'
        onSelect={(item): void => {
          switch (item.id) {
            case 'start-now':
              void onLaunchSanaNowClick()
              return
            case 'schedule-for-later':
              setPanelState({ type: 'create-session' })

              return
            case 'view-scheduled':
              setPanelState({ type: 'list-sessions' })
              return
            case 'separator':
              return
            default:
              assertNever(item.id)
          }
        }}
        onPrimaryClick={() => {
          if (hasOngoingLiveSession && linkingToEditorFlagEnabled) {
            confirmationModalContext.show({
              title: t('admin.author.sessions.ongoing-session', { count: 1 }),
              bodyText: t('admin.author.sessions.ongoing-session-description'),
              confirmLabel: t('admin.author.sessions.join-ongoing-session'),
              secondaryActionLabel: t('sana-now.post-session-page.new-session-in-room'),
              onSecondaryAction: () => {
                void onLaunchSanaNowClick()
              },
              onConfirm: () => {
                void getGlobalRouter().navigate({ to: `/l/${ongoingLiveSessionQuery.data.liveSessionId}` })
              },
            })
          } else {
            void onLaunchSanaNowClick()
          }
        }}
        menuItems={menuItems}
        menuLabel={t('author.schedule-sana-now-session.start-now')}
      >
        {t('author.schedule-sana-now-session.start-now')}
      </MenuButton>
    </div>
  )
}

export const LiveContentTopbar: React.FC<LiveContentTopbarProps> = ({
  title,
  liveContentId,
  rootFolderId,
}) => {
  const usingSanaNow = useFlag('sana-now')

  if (usingSanaNow) {
    return <SanaNowContentTopBar title={title} liveContentId={liveContentId} rootFolderId={rootFolderId} />
  }

  return <LegacyLiveContentTopbar title={title} liveContentId={liveContentId} rootFolderId={rootFolderId} />
}

import { AnimatePresence, motion, useIsPresent, useMotionValue, useTransform } from 'framer-motion'
import { atom, useAtom } from 'jotai'
import _ from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { generativeFeatureUsed } from 'sierra-client/core/logging/authoring/logger'
import { useThemes } from 'sierra-client/hooks/use-themes'
import { subscribeSse } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import { FCC } from 'sierra-client/types'
import { CourseId } from 'sierra-domain/api/nano-id'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { SSEXRealtimeAuthorRegenerateSmartCard } from 'sierra-domain/routes-sse'
import { asNonNullable } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { ColorValue } from 'sierra-ui/color/types'
import { Icon } from 'sierra-ui/components'
import { Button, Heading, IconButton, Spacer, Text, View } from 'sierra-ui/primitives'
import { DarkTokenProvider } from 'sierra-ui/theming'
import { CustomTheme, CustomThemeName, ThemeName, isCustomThemeName } from 'sierra-ui/theming/legacy-theme'
import styled, { css } from 'styled-components'

import { usePost } from 'sierra-client/hooks/use-post'
import { useCourseAssetResolver } from 'sierra-client/hooks/use-resolve-course-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import * as actions from 'sierra-client/state/author-course-settings/actions'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { AssetContext } from 'sierra-domain/asset-context'
import { isRight } from 'sierra-domain/either'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { XRealtimeAuthorExtractAllImagesInContent } from 'sierra-domain/routes'
import { keyframes } from 'styled-components'

export const SmartCardsAtom = atom<{ cards: Card[]; mode: 'edit' | 'view' } | undefined>(undefined)

export const _LilCard = styled(motion.div)<{
  $color?: ColorValue
  $imageUrl?: string
  $noSelect: boolean
  editMode?: boolean
  clickable?: boolean
  $isMobile?: boolean
}>`
  flex-shrink: 0;
  padding: 36px;

  ${p =>
    p.editMode !== true &&
    css`
      position: absolute;
      left: 50%;
      top: 50%;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    `}

  ${p => p.clickable === true && 'cursor: pointer'};

  height: 695px;
  width: 508px;

  ${p =>
    p.$isMobile === true &&
    css`
      height: 600px;
      width: 400px;
    `};

  border-radius: 32px;

  ${p =>
    p.$color !== undefined &&
    css`
      background: linear-gradient(190.21deg, rgba(0, 0, 0, 0) 6.07%, rgba(0, 0, 0, 0.25) 86.24%),
        linear-gradient(0deg, ${color(p.$color)}, ${color(p.$color)});
    `}

  ${p =>
    p.$imageUrl !== undefined &&
    css`
      background:
        linear-gradient(204.11deg, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.75) 97.32%),
        url(${p.$imageUrl}) center / cover;
    `}

    box-shadow: 0px 24px 40px 0px #0000001F, 0px 0px 0px 1px #0000000a, 0px 1px 0px 0px #ffffff66 inset;

  /* box-shadow:
    ${p => color(p.$color ?? 'black').opacity(0.2)} 0px 20px 25px -5px,
    ${p => color(p.$color ?? 'black').opacity(0.08)} 0px 10px 10px -5px; */

  user-select: ${p => (p.$noSelect ? 'none' : 'auto')};
`

const ROTATE_Z = [-2, 1, 3, -1]

const delayedFadeIn = keyframes`
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
`

const TinyCardContainer = styled.div`
  transform: scale(0.08);
  position: absolute;
  pointer-events: none;
  animation: 0.5s cubic-bezier(0.25, 0.1, 0.25, 1) ${delayedFadeIn} forwards;
`

const LilCardView: FCC<{
  onDismiss: () => void
  isCurrent: boolean
  index: number
  color: ColorValue
  imageUrl?: string
}> = ({ children, onDismiss, isCurrent, index, color, imageUrl }) => {
  const x = useMotionValue(0)
  const isPresent = useIsPresent()
  const isMobile = useIsMobile()

  // We'll initialize this to a random value so that we pop in a random direction
  // when using the button. (for release we might only want to pop to the left though)
  const [endDistance, setEndDistance] = useState(() => (Math.random() > 0.5 ? 1 : -1))

  const rotateZ = isCurrent ? 0 : asNonNullable(ROTATE_Z[index % ROTATE_Z.length]) * 3
  const scale = isCurrent ? 1.02 : 1

  const dismissDistance = 25
  const opacity = useTransform(x, distance =>
    _.clamp(1 - (Math.abs(distance) - dismissDistance) / 300, 0.8, 1)
  )
  const rotateY = useTransform(x, distance => _.clamp(distance / 300, -1, 1) * 5)

  return (
    <_LilCard
      $isMobile={isMobile}
      $color={color}
      $imageUrl={imageUrl}
      drag={isCurrent ? 'x' : false}
      $noSelect={!isCurrent}
      // eslint-disable-next-line react/forbid-component-props
      style={{
        x,
        originX: 0.5,
        originY: 0.5,
        translateX: '-50%',
        translateY: '-50%',
        opacity: isPresent ? opacity : undefined,
        rotateY,
        transformPerspective: '10cm',
      }}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        x: 0,
        rotateZ,
        scale,
        transition: {
          type: 'spring',
          bounce: 1,
          stiffness: 200,
          mass: 1,
          scale: {
            duration: 0.2,
          },
          opacity: { duration: 0.1 },
        },
      }}
      exit={{
        x: endDistance > 0 ? 1000 : -1000,
        opacity: 0,
        transition: {
          duration: 0.4,
          ease: 'easeIn',
          opacity: { delay: 0.2, duration: 0.2, ease: 'easeIn' },
        },
        zIndex: 1,
      }}
      // whileHover={{ scale: 1.02 }}
      dragSnapToOrigin
      dragConstraints={{ top: 0, bottom: 0, left: 0, right: 0 }}
      dragElastic={0.4}
      onDragEnd={() => {
        const distance = x.get()
        if (Math.abs(distance) > dismissDistance) {
          setEndDistance(distance)
          onDismiss()
        }
      }}
    >
      {children}
    </_LilCard>
  )
}

const LilCardEdit: FCC<{
  color: ColorValue
  imageUrl?: string
}> = ({ children, color, imageUrl }) => {
  return (
    <_LilCard
      editMode
      $color={color}
      $imageUrl={imageUrl}
      $noSelect={false}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
      }}
    >
      {children}
    </_LilCard>
  )
}

export const LittleCard: FCC<{
  index: number
  color: ColorValue
  imageUrl?: string
}> = ({ children, index, color, imageUrl }) => {
  const x = useMotionValue(0)

  const rotateZ = index * -5 + 15
  const scale = 1

  const rotateY = useTransform(x, distance => _.clamp(distance / 300, -1, 1) * 5)

  return (
    <TinyCardContainer>
      <_LilCard
        $color={color}
        $imageUrl={imageUrl}
        $noSelect={true}
        // eslint-disable-next-line react/forbid-component-props
        style={{
          x: 80 * -index + 300,
          y: 10 * -index,
          rotateY,
          transformPerspective: '10cm',
        }}
        initial={false}
        animate={{
          rotateZ,
          scale,
          transition: {
            type: 'spring',
            bounce: 1,
            stiffness: 200,
            mass: 1,
            scale: {
              duration: 0.2,
            },
          },
        }}
        exit={{ opacity: 0 }}
        // whileHover={{ scale: 1.02 }}
      >
        {children}
      </_LilCard>
    </TinyCardContainer>
  )
}

export type Card = {
  id: string
  title: string
  description: string
  theme?: ThemeName
  image?: ImageUnion
  sourceCourseId?: CourseId
}

const OpaqueText = styled(Text)`
  opacity: 0.7;
`

const DarkenedBackground = styled(motion.div)<{ open: boolean }>`
  position: fixed;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  gap: 6px;

  ${p =>
    p.open !== true &&
    css`
      pointer-events: none;
    `}
`

const WrapPre = styled.pre`
  text-wrap: wrap;
  white-space: pre-line;
`

const LilCardContents: React.FC<{
  imageUrl: string | undefined
  theme: CustomTheme | undefined
  smartCardsState: { cards: Card[]; mode: 'edit' | 'view' }
  index: number
  totalNumberOfCards: number
  card: Card
  abortControllerRef?: React.MutableRefObject<AbortController | undefined>
  handleRegenerateSmartCard?: (cardToRegenerate: Card) => Promise<void>
  handleRemoveSmartCard?: (cardToBeRemoved: Card) => Promise<void>
}> = ({
  imageUrl,
  theme,
  smartCardsState,
  index,
  totalNumberOfCards,
  card,
  abortControllerRef,
  handleRegenerateSmartCard,
  handleRemoveSmartCard,
}) => {
  const { t } = useTranslation()
  return (
    <View style={{ height: '100%' }} direction='column' justifyContent='space-between'>
      <View>
        <Text color={imageUrl !== undefined ? 'white' : color(theme?.backgroundColor ?? 'white')} bold>
          {t('smart-review.n-of-m', { index: index + 1, totalNumberOfCards: totalNumberOfCards })}
        </Text>
        {card.sourceCourseId !== undefined &&
          smartCardsState.mode === 'edit' &&
          abortControllerRef !== undefined && (
            <>
              <View grow />
              <View>
                {handleRegenerateSmartCard !== undefined && (
                  <Button
                    variant='ghost'
                    onClick={async () => {
                      abortControllerRef.current?.abort()
                      abortControllerRef.current = new AbortController()
                      await handleRegenerateSmartCard(card)
                    }}
                    icon='sana-symbol'
                  >
                    {t('smart-review.edit.re-generate')}
                  </Button>
                )}
                {handleRemoveSmartCard !== undefined && (
                  <IconButton
                    variant='ghost'
                    onClick={async () => {
                      await handleRemoveSmartCard(card)
                    }}
                    iconId='trash-can'
                  />
                )}
              </View>
            </>
          )}
      </View>
      <View gap='none' direction='column'>
        <WrapPre>
          <Text
            style={{ maxWidth: '34ch' }}
            color={imageUrl !== undefined ? 'white' : color(theme?.backgroundColor ?? 'white')}
            bold
            size='large'
          >
            {card.title}
          </Text>
        </WrapPre>
        <WrapPre>
          <OpaqueText
            style={{ maxWidth: '34ch' }}
            color={imageUrl !== undefined ? 'white' : color(theme?.backgroundColor ?? 'white')}
            bold
            size='large'
          >
            {card.description}
          </OpaqueText>
        </WrapPre>
      </View>
    </View>
  )
}

type LilCardsAtom = {
  smartCardsState: {
    cards: Card[]
    mode: 'edit' | 'view'
  }
  setSmartCardsState: (smartCardsState: { cards: Card[]; mode: 'edit' | 'view' } | undefined) => void
  renderedCards: Card[]
  setRenderedCards: React.Dispatch<React.SetStateAction<Card[]>>
  closeSmartCardsViewer: () => void
}

const LilStack: React.FC<LilCardsAtom> = ({ smartCardsState, renderedCards, setRenderedCards }) => {
  const { t } = useTranslation()

  const dismissTopCard = useCallback((): void => {
    setRenderedCards(curr => curr.slice(0, -1))
  }, [setRenderedCards])

  const onKeyDown = useCallback(
    (ev: KeyboardEvent): void => {
      if ((ev.key === 'ArrowRight' || ev.key === 'Enter') && renderedCards.length > 0) {
        ev.preventDefault()
        dismissTopCard()
      }

      if (ev.key === 'Escape') {
        ev.preventDefault()
        setRenderedCards([])
      }
    },
    [dismissTopCard, renderedCards, setRenderedCards]
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  const { customThemes } = useThemes()

  const assetResolver = useCourseAssetResolver()

  return (
    <>
      <AnimatePresence>
        {renderedCards.length === 1 && (
          <motion.div
            key='all done'
            style={{
              position: 'fixed',
              margin: 'auto',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
            initial={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.5, delay: 1 } }}
          >
            <Icon color='success/background' size='size-80' iconId='checkmark--filled' />
            <Spacer size='24' />
            <Heading bold size='h3'>
              {t('smart-review.view.completed')}
            </Heading>
          </motion.div>
        )}
        {renderedCards.length > 0 && (
          <div
            key='swipe instruction'
            style={{
              position: 'fixed',
              margin: 'auto',
              height: '100%',
              paddingBottom: 'calc(50vh - 430px)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <View>
              <Icon color={'foreground/secondary'} iconId='touch' />
              <Text color={'foreground/secondary'} bold>
                {t('smart-review.view.swipe')}
              </Text>
            </View>
          </div>
        )}
        {renderedCards.length !== 0 &&
          renderedCards.map((card, index) => {
            const assetContext: AssetContext = card.sourceCourseId
              ? { type: 'course', courseId: card.sourceCourseId }
              : { type: 'unknown' }
            const imageUrl = card.image !== undefined ? assetResolver(card.image, assetContext) : undefined
            const theme =
              card.theme !== undefined
                ? isCustomThemeName(card.theme)
                  ? customThemes[card.theme]
                  : undefined
                : undefined
            return (
              <LilCardView
                key={card.id}
                index={index}
                color={theme?.foregroundColor ?? '#000'}
                imageUrl={imageUrl}
                isCurrent={index === renderedCards.length - 1}
                onDismiss={dismissTopCard}
              >
                <LilCardContents
                  imageUrl={imageUrl}
                  theme={theme}
                  smartCardsState={smartCardsState}
                  index={smartCardsState.cards.length - index - 1}
                  totalNumberOfCards={smartCardsState.cards.length}
                  card={card}
                />
              </LilCardView>
            )
          })}
      </AnimatePresence>
    </>
  )
}

const AddNewCardCard: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <_LilCard
      onClick={onClick}
      clickable
      editMode
      $color={color('grey80')}
      $noSelect
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      // whileHover={{ scale: 1.02 }}
    >
      <div style={{ height: '100%', display: 'flex' }}>
        <View gap='none' grow direction='column' alignItems='center' justifyContent='center'>
          <Icon color={'foreground/muted'} size='size-32' iconId='add--alt' />
          <Spacer size='24' />
          <Text color={'foreground/muted'} size='large' bold>
            {t('smart-review.edit.add-card')}
          </Text>
          <Text color={'foreground/muted'} size='large'>
            {t('smart-review.edit.add-card-description')}
          </Text>
        </View>
      </div>
    </_LilCard>
  )
}

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  width: 100%;
  height: fit-content;
  overflow: auto;
  /* align-items: center;
    justify-content: center; */

  padding-left: 64px;
  padding-right: 64px;
`

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
`

function addOrReplace<T>(list: T[], newElement: T, predicate: (el1: T, el2: T) => boolean): T[] {
  const listCopy = [...list]
  const replaceAtIndex = _.findIndex(listCopy, listElement => predicate(listElement, newElement))
  if (replaceAtIndex !== -1) {
    listCopy.splice(replaceAtIndex, 1, newElement)
    return listCopy
  } else return [...listCopy, newElement]
}

const LilCarousel: React.FC<LilCardsAtom> = ({
  smartCardsState,
  setSmartCardsState,
  closeSmartCardsViewer,
}) => {
  const abortControllerRef = useRef<AbortController>()
  const dispatch = useDispatch()

  const renderedCards = smartCardsState.cards

  const { t } = useTranslation()

  const { customThemes } = useThemes()

  const { postWithUserErrorCode } = usePost()

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      abortControllerRef.current?.abort()
    }
  }, [])

  const handleAddCard = useCallback(async () => {
    const themes = Object.keys(customThemes) as CustomThemeName[]
    const sourceCourseId = _.first(renderedCards)?.sourceCourseId

    if (sourceCourseId === undefined) return

    const courseImages = await postWithUserErrorCode(XRealtimeAuthorExtractAllImagesInContent, {
      courseId: sourceCourseId,
    }).then(it => (isRight(it) ? _.shuffle(it.right.images) : []))

    const randomNumber = Math.floor(Math.random() * Math.max(6, courseImages.length)) // this is to not get the same image for every card in courses with few images.

    const newCard: Card = {
      id: nanoid12(),
      title: '',
      description: '',
      theme: _.sample(themes),
      image: courseImages[randomNumber],
      sourceCourseId: sourceCourseId,
    }
    setSmartCardsState({ cards: [...smartCardsState.cards, newCard], mode: smartCardsState.mode })
    return newCard
  }, [
    customThemes,
    renderedCards,
    postWithUserErrorCode,
    setSmartCardsState,
    smartCardsState.cards,
    smartCardsState.mode,
  ])

  const handleRemoveSmartCard = useCallback(
    async (cardToBeRemoved: Card): Promise<void> => {
      const newCardList = smartCardsState.cards.filter(card => card.id !== cardToBeRemoved.id)
      setSmartCardsState({
        cards: newCardList,
        mode: smartCardsState.mode,
      })
    },
    [setSmartCardsState, smartCardsState.cards, smartCardsState.mode]
  )

  const handleSaveAndClose = useCallback(async () => {
    void dispatch(
      actions.updateCourseSmartCards(
        smartCardsState.cards.map(card => ({
          ...card,
          theme: card.theme !== undefined ? { type: 'preset' as const, name: card.theme } : undefined,
        }))
      )
    )

    await dispatch(settingsActions.saveSettings({ notify: false }))

    closeSmartCardsViewer()
  }, [closeSmartCardsViewer, dispatch, smartCardsState.cards])

  const handleRegenerateSmartCard = useCallback(
    async (cardToRegenerate: Card): Promise<void> => {
      if (cardToRegenerate.sourceCourseId === undefined) return
      let text = ''
      let generatedCard: Card
      let newCardList: Card[] = smartCardsState.cards

      await subscribeSse({
        route: SSEXRealtimeAuthorRegenerateSmartCard,
        input: { sourceCourseId: cardToRegenerate.sourceCourseId },
        onError: e => {
          // bearer:disable javascript_lang_logger_leak
          console.error(e)
        },
        signal: abortControllerRef.current?.signal,
        onMessage: event => {
          text += event.data.text

          const [cardTitle, ...cardText] = text.split('---')
          generatedCard = {
            ...cardToRegenerate,
            title: cardTitle?.trim() ?? 'Refresh your knowledge!',
            description: cardText.join('').trim(),
          }

          newCardList = addOrReplace(
            smartCardsState.cards,
            generatedCard,
            (newCard, listCard) => newCard.id === listCard.id
          )

          setSmartCardsState({
            cards: newCardList,
            mode: smartCardsState.mode,
          })
        },
      })

      void dispatch(
        generativeFeatureUsed({
          contentType: 'self-paced',
          generativeFeature: 'generate-smart-cards',
        })
      )
    },
    [dispatch, setSmartCardsState, smartCardsState.cards, smartCardsState.mode]
  )

  const assetResolver = useCourseAssetResolver()

  return (
    <CenteredContent>
      <AnimatePresence>
        {renderedCards.length !== 0 && (
          <>
            <CarouselContainer>
              {renderedCards.map((card, index) => {
                const assetContext: AssetContext = card.sourceCourseId
                  ? { type: 'course', courseId: card.sourceCourseId }
                  : { type: 'unknown' }
                const imageUrl =
                  card.image !== undefined ? assetResolver(card.image, assetContext) : undefined
                const theme =
                  card.theme !== undefined
                    ? isCustomThemeName(card.theme)
                      ? customThemes[card.theme]
                      : undefined
                    : undefined
                return (
                  <LilCardEdit key={card.id} color={theme?.foregroundColor ?? '#000'} imageUrl={imageUrl}>
                    <LilCardContents
                      key={card.id}
                      imageUrl={imageUrl}
                      theme={theme}
                      smartCardsState={smartCardsState}
                      index={index}
                      totalNumberOfCards={renderedCards.length}
                      card={card}
                      abortControllerRef={abortControllerRef}
                      handleRegenerateSmartCard={handleRegenerateSmartCard}
                      handleRemoveSmartCard={handleRemoveSmartCard}
                    />
                  </LilCardEdit>
                )
              })}
              <AddNewCardCard
                onClick={async () => {
                  const newCard = await handleAddCard()
                  if (newCard === undefined) return
                  await handleRegenerateSmartCard(newCard)
                }}
              />
            </CarouselContainer>
            <motion.div
              style={{ display: 'flex', flexDirection: 'row', gap: '8px', marginTop: '32px' }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Button onClick={closeSmartCardsViewer} variant='secondary'>
                {t('smart-review.edit.cancel')}
              </Button>
              <Button onClick={handleSaveAndClose} variant='primary'>
                {t('smart-review.edit.save-and-close')}
              </Button>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </CenteredContent>
  )
}

export const SmartCards: React.FC = () => {
  const [smartCardsState, setSmartCardsState] = useAtom(SmartCardsAtom)
  const [renderedCards, setRenderedCards] = useState<Card[]>([])
  const [hasSeenLastCard, setHasSeenLastCard] = useState(false)

  useEffect(() => {
    setRenderedCards(smartCardsState?.cards ?? [])
  }, [setRenderedCards, smartCardsState])

  const closeSmartCardsViewer = useCallback(() => {
    setSmartCardsState(prev => (prev !== undefined ? { cards: [], mode: prev.mode } : undefined))
  }, [setSmartCardsState])

  useEffect(() => {
    if (renderedCards.length === 1) setHasSeenLastCard(true)
    if (smartCardsState === undefined || renderedCards.length > 1) setHasSeenLastCard(false)
  }, [renderedCards, smartCardsState])

  return (
    <DarkTokenProvider>
      <DarkenedBackground
        initial={{ backgroundColor: `${color('black').opacity(0)}` }}
        animate={{
          backgroundColor:
            renderedCards.length !== 0 ? `${color('black').opacity(0.8)}` : `${color('black').opacity(0)}`,
          transition: { duration: 0.5, delay: smartCardsState?.mode === 'view' && hasSeenLastCard ? 1 : 0 },
        }}
        onAnimationComplete={() => setHasSeenLastCard(false)}
        open={renderedCards.length !== 0}
        onMouseDown={e => {
          if (e.target === e.currentTarget) {
            closeSmartCardsViewer()
          }
        }}
      >
        {smartCardsState?.mode === 'view' && (
          <LilStack
            smartCardsState={smartCardsState}
            setSmartCardsState={setSmartCardsState}
            renderedCards={renderedCards}
            setRenderedCards={setRenderedCards}
            closeSmartCardsViewer={closeSmartCardsViewer}
          />
        )}

        {smartCardsState?.mode === 'edit' && (
          <LilCarousel
            smartCardsState={smartCardsState}
            setSmartCardsState={setSmartCardsState}
            renderedCards={renderedCards}
            setRenderedCards={setRenderedCards}
            closeSmartCardsViewer={closeSmartCardsViewer}
          />
        )}
      </DarkenedBackground>
    </DarkTokenProvider>
  )
}

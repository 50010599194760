import { UseQueryResult } from '@tanstack/react-query'
import { CachedQueryOptions, useCachedQuery } from 'sierra-client/state/api'
import { CreatePageContent } from 'sierra-domain/api/create-page'
import { XRealtimeContentCreate } from 'sierra-domain/routes'

export const useCreatePageContent = <TSelectData = CreatePageContent>(
  options: CachedQueryOptions<CreatePageContent, TSelectData> = {}
): UseQueryResult<TSelectData> => {
  return useCachedQuery(
    XRealtimeContentCreate,
    {},
    {
      retry: false,
      ...options,
    }
  )
}

import { FC, useCallback, useState } from 'react'
import { Card } from 'sierra-client/features/multi-tenancy/components'
import {
  constructDistributionPatch,
  useCourseDistributionSettingsMutation,
} from 'sierra-client/features/multi-tenancy/hooks/use-course-distribution-settings'
import { useOrganizationCluster } from 'sierra-client/features/multi-tenancy/hooks/use-organization-cluster'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery } from 'sierra-client/state/api'
import { CourseVisibilityInOrg } from 'sierra-domain/api/author-v2'
import { CourseId } from 'sierra-domain/api/nano-id'
import { AccessibleOrganization } from 'sierra-domain/multi-tenancy'
import { XRealtimeGetCourseDistributionSettings } from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { Tooltip } from 'sierra-ui/components'
import { Checkbox, Text, View } from 'sierra-ui/primitives'
import { ConditionalWrapper } from 'sierra-ui/utils'

export const OrganizationSettingsCard: FC<{
  organization: AccessibleOrganization
  onTogglePublishing: (org: AccessibleOrganization, checked: boolean) => void
  publishingInitial: boolean
  locked: boolean
  unavailable: boolean
}> = ({ organization, onTogglePublishing, publishingInitial, locked, unavailable }) => {
  const { t } = useTranslation()
  const [state, setState] = useState(publishingInitial)

  const onCheckedChange = useCallback(
    (n: boolean | 'indeterminate') => {
      const nextChecked = n === true
      setState(nextChecked)
      onTogglePublishing(organization, nextChecked)
    },
    [organization, onTogglePublishing]
  )

  return (
    <ConditionalWrapper
      condition={unavailable}
      renderWrapper={children => (
        <Tooltip title={t('multi-tenancy.content-distribution.sub-org-disabled')}>{children}</Tooltip>
      )}
    >
      <Card $disabled={unavailable}>
        <Checkbox checked={state} onCheckedChange={onCheckedChange} disabled={locked || unavailable} />
        <Text bold color='currentColor'>
          {organization.name}
        </Text>
      </Card>
    </ConditionalWrapper>
  )
}

export const OrganizationClusterVisibilitySettings: FC<{
  courseId: CourseId
  visibility: CourseVisibilityInOrg
}> = ({ courseId, visibility }) => {
  const { t } = useTranslation()
  const cluster = useOrganizationCluster()
  const settingsQuery = useCachedQuery(XRealtimeGetCourseDistributionSettings, { courseId })
  const settingsMutation = useCourseDistributionSettingsMutation(courseId)

  const loading = cluster.loading || settingsQuery.isPending
  const hasCluster = isDefined(cluster.cluster) && cluster.cluster.length > 0

  const onTogglePublishing = useCallback(
    (organization: AccessibleOrganization, checked: boolean) => {
      if (visibility === 'private') return

      const patch = constructDistributionPatch(organization, {
        visibility,
        isDisabled: !checked,
      })

      if (isDefined(patch)) {
        settingsMutation.mutate({
          courseId,
          settings: [patch],
        })
      }
    },
    [courseId, visibility, settingsMutation]
  )

  if (loading || !hasCluster || !cluster.selfIsParent) {
    return null
  }

  return (
    <View direction='column'>
      <Text bold color='foreground/muted'>
        {t('multi-tenancy.content-distribution.publish-to')}
      </Text>

      <View direction='column' gap='6'>
        {cluster.cluster.map(org => {
          const settings = settingsQuery.data?.settings.find(setting =>
            org.namespaceId.startsWith(setting.childOrganization.namespaceId)
          )
          const publishing = isDefined(settings?.isDisabled) ? !settings.isDisabled : false
          const unavailable = !org.isClusterParent && visibility === 'private'

          return (
            <OrganizationSettingsCard
              key={org.domain}
              organization={org}
              onTogglePublishing={onTogglePublishing}
              publishingInitial={org.isClusterParent ? true : publishing}
              locked={org.isClusterParent}
              unavailable={unavailable}
            />
          )
        })}
      </View>
    </View>
  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ContentLabels } from 'sierra-client/components/common/content-elements'
import { SanaImage } from 'sierra-client/components/common/image'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { AssignModal } from 'sierra-client/components/common/modals/multi-assign-modal'
import { parseModalToContentAssignment } from 'sierra-client/components/common/modals/multi-assign-modal/utils'
import { useNotif } from 'sierra-client/components/common/notifications'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { useOrganizationCluster } from 'sierra-client/features/multi-tenancy'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useDispatch } from 'sierra-client/state/hooks'
import { fetchTagsData } from 'sierra-client/state/v2/tags-actions'
import { NewCourseSettingsModal } from 'sierra-client/views/course-settings/course-settings-modal'
import { CourseSettingsModal } from 'sierra-client/views/course-settings/modal'
import {
  ManageDetailContainer,
  ManageDetailContent,
  ManageDetailGrid,
  ManageDetailSidebar,
} from 'sierra-client/views/manage/components/common'
import { ContentEnrollmentSection } from 'sierra-client/views/manage/components/content-sections'
import { ContentGroupsTable } from 'sierra-client/views/manage/components/content-tables'
import { DetailsHeader } from 'sierra-client/views/manage/components/details-header'
import { DueDateDialog, useDueDate } from 'sierra-client/views/manage/components/due-date'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import { CourseLiveSessionAssignmentTable } from 'sierra-client/views/manage/courses/components/course-live-session-assignment-table'
import { DeleteCourseModal } from 'sierra-client/views/manage/courses/components/modals'
import {
  ActionsSection,
  DurationSection,
  FeedbackSection,
  LastEditedSection,
  OtherCourseDetailsSection,
  OverviewSection,
  QuickSettingsSection,
  SmartReviewSection,
} from 'sierra-client/views/manage/courses/components/sections'
import { CourseLiveSessionTable } from 'sierra-client/views/manage/courses/components/tables'
import { TeamspaceDropdown } from 'sierra-client/views/manage/courses/components/teamspace-dropdown'
import { useManageCourseDetails } from 'sierra-client/views/manage/courses/use-manage-course-details'
import {
  useContentGroupAssignmentPanes,
  useLiveSessionAssignmentPanes,
} from 'sierra-client/views/manage/courses/utils/use-content-group-assignment-panes'
import { Separator } from 'sierra-client/views/showcase/common'
import { manageUrl } from 'sierra-client/views/workspace/utils/urls'
import { CourseKind } from 'sierra-domain/api/common'
import { DueDateAbsolute, DueDateGroup } from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { isDefined } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Heading, Pill, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

interface CourseDetailsProps {
  courseId: CourseId
}

const OrgLink = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 4px;
`

export const ManageCourseDetails: React.FC<CourseDetailsProps> = ({ courseId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const notifications = useNotif()
  const assignmentPanes = useContentGroupAssignmentPanes()
  const liveSessionAssignmentPanes = useLiveSessionAssignmentPanes()

  const newCourseSettingsModalEnabled = useFlag('new-course-settings-modal')

  const {
    courseData,
    courseProgramAssignments,
    courseUserAssignments,
    courseComments,
    liveSessions,
    isLoading,
    fetchUserAssignments,
    fetchProgramAssignments,
    fetchLiveSessions,
    fetchCourse,
    saveVisibility,
    unassignFromCourse,
    deleteCourse,
  } = useManageCourseDetails(courseId)
  const { setUsersDueDate, assignWithDueDate } = useDueDate()

  const onLiveSessionAssignChange = useCallback(() => {
    void fetchLiveSessions(courseId)
    void fetchCourse()
    // void fetchUserAssignments(courseId, undefined, { reset: true })
    // void fetchGroupAssignments(courseId)
  }, [courseId, fetchLiveSessions, fetchCourse])

  const [action, setAction] = useState<
    | {
        modal: 'enroll-users' | 'enroll-programs' | 'delete'
      }
    | {
        modal: 'unassign-users'
        ids: UserId[]
      }
    | {
        modal: 'assign-live-sessions'
        ids: string[]
      }
    | {
        modal: 'due-date-group'
        groupId: string
        currentDueDate?: DueDateGroup
      }
    | {
        modal: 'due-date-user'
        userId: UserId
        currentDueDate?: DueDateAbsolute
        origin?: 'group' | 'user'
      }
    | undefined
  >()

  const onRemoveUsers = useCallback((ids: UserId[]) => {
    setAction({ modal: 'unassign-users', ids })
  }, [])

  const onAssignLiveSessions = useCallback((sessionIds: string[]) => {
    setAction({ modal: 'assign-live-sessions', ids: sessionIds })
  }, [])

  const onSetUserDueDate = useCallback(
    (userId: UserId, currentDueDate?: DueDateAbsolute, origin?: 'group' | 'user') => {
      setAction({ modal: 'due-date-user', userId, currentDueDate, origin })
    },
    []
  )

  useEffect(() => {
    void dispatch(fetchTagsData())
  }, [dispatch])

  const rerouteCourseKinds: CourseKind[] = useMemo(
    () => ['native:course-group', 'scorm:course-group', 'native:event-group'],
    []
  )

  useEffect(() => {
    if (isDefined(courseData?.kind) && rerouteCourseKinds.includes(courseData.kind)) {
      void getGlobalRouter().navigate({
        to: manageUrl({ type: courseData.kind, id: courseId }) as string,
        replace: true,
      })
    }
  }, [courseId, courseData?.kind, rerouteCourseKinds])

  const organizationCluster = useOrganizationCluster()
  const parentOrg = organizationCluster.cluster?.find(c => c.isClusterParent)

  const coursePermissions = useContentKindPermissions(courseId)

  const assetContext = useMemo(() => ({ type: 'course' as const, courseId }), [courseId])
  const imgSrc = useResolveCourseAsset({ image: courseData?.image, assetContext })

  // We have a separate pages for course groups and event groups. Maybe they should be unified for simpler routing.
  // Until then we should redirect to the other page so we don't have two different versions of the course page.
  if (!courseData || rerouteCourseKinds.includes(courseData.kind)) {
    return null
  }

  return (
    <>
      <PageTitle title={courseData.title} />

      <ColumnLayout>
        <DetailsHeader
          backlink={{
            href: '/manage/content',
            label: 'manage.backlinks--content',
          }}
          titleAddon={
            <ContentLabels
              contentType='course'
              courseKind={courseData.kind}
              isCourseEdition={courseData.courseGroupId !== undefined}
            />
          }
        />
        <ManageDetailContainer>
          <ManageDetailGrid>
            <ManageDetailSidebar>
              <SanaImage src={imgSrc} ratio='16:9' rounded />
              <Spacer size='small' />
              <Heading size='h4' bold>
                {courseData.title}
              </Heading>
              <Spacer size='xxsmall' />
              {courseData.readOnly === true && (
                <Pill variant='ghost'>
                  {isDefined(parentOrg) ? (
                    <OrgLink href={`https://${parentOrg.domain}/manage/courses/${courseId}`} target='_blank'>
                      <Icon iconId='locked--filled' color='foreground/secondary' size='size-12' />
                      <Text color='foreground/secondary' size='micro'>
                        {t('manage.content.published-from-org', { org: parentOrg.name })}
                      </Text>
                    </OrgLink>
                  ) : (
                    <>
                      <Icon iconId='locked--filled' color='foreground/secondary' size='size-12' />
                      <Text color='foreground/secondary' size='micro'>
                        {t('manage.content.published-from-another-org')}
                      </Text>
                    </>
                  )}
                </Pill>
              )}
              <Spacer size='xsmall' />
              {courseData.kind === 'native:live' ? (
                <View gap='4'>
                  <Icon iconId='play--circle--filled' size='size-14' color='foreground/muted' />
                  <Text>{t('dictionary.live')}</Text>
                </View>
              ) : (
                <DurationSection timeTotal={courseData.timeTotal} />
              )}
              <Spacer size='xxsmall' />
              <Spacer size='xxsmall' />
              <LastEditedSection
                publishedAt={courseData.publishedAt}
                lastEditedAt={courseData.lastEditedAt}
              />
              <Spacer size='xsmall' />
              <ActionsSection
                courseId={courseId}
                courseKind={courseData.kind}
                canEdit={courseData.canEdit}
                canEditMetadata={coursePermissions.has('EDIT_METADATA')}
                canDelete={coursePermissions.has('DELETE')}
                onClickDelete={() => setAction({ modal: 'delete' })}
              />

              <QuickSettingsSection
                courseId={courseId}
                courseKind={courseData.kind}
                isVisible={courseData.isVisible}
                saveVisibility={saveVisibility}
              />

              {courseData.kind === 'native:self-paced' && (
                <SmartReviewSection courseId={courseId} courseKind={courseData.kind} />
              )}

              <OtherCourseDetailsSection
                description={courseData.description}
                tags={courseData.tags}
                includedInPaths={courseData.includedInPaths}
              />
              <Separator />
              {(courseData.kind === 'scorm' ||
                courseData.kind === 'link' ||
                courseData.kind === 'linkedin' ||
                courseData.kind === 'native:live' ||
                courseData.kind === 'native:self-paced') && (
                <TeamspaceDropdown courseId={courseId} courseType={courseData.kind} />
              )}
            </ManageDetailSidebar>
            <ManageDetailContent>
              <View direction='column' gap='none'>
                <OverviewSection
                  courseKind={courseData.kind}
                  completionRate={courseData.completionRate}
                  rating={courseData.rating}
                  ratingCount={courseData.ratingCount}
                  directAssignmentsCount={courseData.enrollmentCount}
                  selfStartedUsersCount={courseData.assignedOrStartedUsersCount - courseData.enrollmentCount}
                />
                {courseData.kind === 'native:live' ? (
                  <>
                    <CourseLiveSessionTable
                      liveSessions={liveSessions}
                      openAssignModal={onAssignLiveSessions}
                    />
                    <Spacer size='xxlarge' />
                    <CourseLiveSessionAssignmentTable
                      courseId={courseId}
                      onDone={onLiveSessionAssignChange}
                    />
                    {courseProgramAssignments.length > 0 && (
                      <>
                        <ContentGroupsTable assignedPrograms={courseProgramAssignments} />
                        <Spacer size='xxlarge' />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Spacer size='xxlarge' />
                    <ContentEnrollmentSection
                      assignedPrograms={courseProgramAssignments}
                      assignedUsers={courseUserAssignments}
                      openEnrollUsers={() => setAction({ modal: 'enroll-users' })}
                      onSetUserDueDate={onSetUserDueDate}
                      onRemoveUser={onRemoveUsers}
                      contentId={courseId}
                      fetchUserAssignments={fetchUserAssignments}
                      contentType='course'
                      canEditAssignments={coursePermissions.has('EDIT_ASSIGNMENTS')}
                    />
                  </>
                )}
                {courseData.kind !== 'native:live' && (
                  <>
                    <Spacer size='xxlarge' />
                    <FeedbackSection feedbacks={courseComments} />
                  </>
                )}
              </View>
            </ManageDetailContent>
          </ManageDetailGrid>

          {/* Modals */}

          {action?.modal === 'unassign-users' && (
            <ActionModal
              open
              isLoading={isLoading}
              onClose={() => setAction(undefined)}
              primaryAction={async (): Promise<void> => {
                await unassignFromCourse(courseId, { userIds: action.ids })
                setAction(undefined)
                notifications.push({
                  type: 'custom',
                  level: 'success',
                  body: t('notifications.done'),
                })
              }}
              primaryActionLabel={t('dictionary.unassign')}
              title={t('manage.unassign-users.title', { count: action.ids.length })}
              deleteAction
            />
          )}

          {['enroll-users', 'enroll-groups'].includes(action?.modal ?? '') && (
            <AssignModal
              isOpen
              subjectType='course'
              subjects={courseId}
              subjectsSupportAssignmentSettings={
                courseData.kind === 'native:live' || courseData.kind === 'native:event-group'
              }
              autoAssignmentAvailable={courseData.kind === 'native:live'}
              panes={assignmentPanes}
              showDueDates={assignmentPanes}
              title={t('dictionary.enrollments')}
              activePane={action?.modal === 'enroll-programs' ? 'program' : 'user'}
              onClose={() => {
                setAction(undefined)
              }}
              onSave={async selections => {
                const result = await assignWithDueDate(
                  parseModalToContentAssignment([courseId], 'course', selections)
                )
                if (result?.error !== undefined) return

                notifications.push({ type: 'assigned' })
                setAction(undefined)
                void Promise.all([
                  fetchCourse(),
                  fetchUserAssignments(courseId, undefined, { reset: true }),
                  fetchProgramAssignments(courseId),
                ])
              }}
            />
          )}
          {action?.modal === 'assign-live-sessions' && (
            <AssignModal
              isOpen
              subjectType='live-session'
              subjects={action.ids}
              panes={liveSessionAssignmentPanes}
              title={t('dictionary.enrollments')}
              activePane='user'
              onClose={() => setAction(undefined)}
              onSave={async selections => {
                const result = await assignWithDueDate(
                  parseModalToContentAssignment(action.ids, 'live-session', selections)
                )
                if (result?.error !== undefined) return

                setAction(undefined)
                void fetchLiveSessions(courseId)
              }}
            />
          )}
          <DeleteCourseModal
            isOpen={action?.modal === 'delete'}
            onClose={() => setAction(undefined)}
            onDelete={async () => {
              await deleteCourse()
            }}
          />

          {newCourseSettingsModalEnabled ? (
            <NewCourseSettingsModal onSave={fetchCourse} />
          ) : (
            <CourseSettingsModal onSave={fetchCourse} />
          )}

          <DueDateDialog
            open={action?.modal === 'due-date-user'}
            onClose={() => setAction(undefined)}
            value={action?.modal === 'due-date-user' ? action.currentDueDate : undefined}
            type='user'
            forceDisableRemove={action?.modal === 'due-date-user' ? action.origin !== 'user' : undefined}
            onChange={async value => {
              if (action?.modal !== 'due-date-user' || value?.type === 'relative') return

              await setUsersDueDate(
                [action.userId],
                [{ dueDate: value?.date, content: { type: 'course', id: courseId } }]
              )
              // void fetchUserAssignments(courseId)
              void fetchUserAssignments(courseId, undefined, { reset: true })
              setAction(undefined)
            }}
          />
        </ManageDetailContainer>
      </ColumnLayout>
    </>
  )
}

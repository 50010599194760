import { useAtom } from 'jotai'
import { DateTime } from 'luxon'
import { FC, useMemo } from 'react'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useCachedQuery } from 'sierra-client/state/api'
import { selectFlexibleContentFile } from 'sierra-client/state/flexible-content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import {
  ScopedCreateContentId,
  ScopedLiveContentId,
  ScopedSelfPacedContentId,
} from 'sierra-domain/collaboration/types'
import { LiveSessionBase } from 'sierra-domain/content/session'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { File } from 'sierra-domain/flexible-content/types'
import { XRealtimeAuthorLiveSessionsListLiveSessions } from 'sierra-domain/routes'
import { iife } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { MenuButton, MenuItem, Tooltip } from 'sierra-ui/components'
import { Button } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: ${token('surface/default')};
  padding: 12px;
  border-radius: 70px;

  position: absolute;
  bottom: 16px;
  right: 50%;
  transform: translateX(50%);

  box-shadow:
    0px 8px 16px 0px rgba(0, 0, 0, 0.08),
    0px 0px 0px 1px rgba(0, 0, 0, 0.04);
`

const Separator = styled.div`
  height: 16px;
  width: 1px;
  background-color: ${token('border/strong')};
`

const tmpT = (s: string): string => s

const getSessionLabel = (session: LiveSessionBase): string => {
  const time = iife(() => {
    if (session.startedAt !== undefined) return session.startedAt
    if (session.type === 'scheduled') return session.startTime
    return session.endedAt
  })

  if (time !== undefined) return DateTime.fromISO(time).toFormat('MMMM dd, HH:mm')

  return 'unknown'
}

const EditOrResponsesButton = styled(Button).attrs({ variant: 'ghost' })<{ $selected: boolean }>`
  ${p =>
    p.$selected
      ? css`
          background: ${color('grey10')};
        `
      : css`
          background: transparent;
        `}
`

const LiveDataHistorySwitch: FC<{ contentId: ScopedLiveContentId }> = ({ contentId }) => {
  const [editOrResponsesState, setEditOrResponsesState] = useAtom(
    useCreatePageContext().editOrResponsesStateAtom
  )
  const pastLiveSessionsQuery = useCachedQuery(
    XRealtimeAuthorLiveSessionsListLiveSessions,
    {
      flexibleContentId: ScopedLiveContentId.extractId(contentId),
    },
    {
      select(data) {
        return data.liveSessions.filter(session => session.data.endedAt !== undefined)
      },
    }
  )

  const sessionsData = pastLiveSessionsQuery.data

  const hasSessions = sessionsData !== undefined && sessionsData.length > 0
  const lastLiveSession = sessionsData?.[0]

  const selectedLiveSessionId =
    editOrResponsesState.type === 'responses' ? editOrResponsesState.liveSessionId : undefined
  const menuItems = useMemo(() => {
    return (
      pastLiveSessionsQuery.data?.map<MenuItem<LiveSessionId>>(session => ({
        type: 'label',
        id: session.liveSessionId,
        label: getSessionLabel(session.data),
        icon: 'calendar',
        selected: selectedLiveSessionId === session.liveSessionId,
      })) ?? []
    )
  }, [pastLiveSessionsQuery.data, selectedLiveSessionId])

  return (
    <Container>
      <EditOrResponsesButton
        $selected={editOrResponsesState.type === 'edit'}
        onClick={() => {
          setEditOrResponsesState(prev => ({ ...prev, type: 'edit' }))
        }}
      >
        {tmpT('Edit')}
      </EditOrResponsesButton>
      <Tooltip title={!hasSessions ? tmpT('Run a session to see responses') : ''}>
        <EditOrResponsesButton
          $selected={editOrResponsesState.type === 'responses'}
          onClick={() => {
            setEditOrResponsesState({
              type: 'responses',
              liveSessionId: lastLiveSession?.liveSessionId,
            })
          }}
          disabled={!hasSessions}
        >
          {tmpT('Responses')}
        </EditOrResponsesButton>
      </Tooltip>
      {editOrResponsesState.type === 'responses' && (
        <>
          <Separator />
          <MenuButton
            menuItems={menuItems}
            onSelect={session => {
              setEditOrResponsesState(prev => ({ ...prev, type: 'responses', liveSessionId: session.id }))
            }}
          >
            {tmpT('Sessions')}
          </MenuButton>
        </>
      )}
    </Container>
  )
}

const SelfPacedDataHistorySwitch: FC<{ contentId: ScopedSelfPacedContentId }> = () => {
  const [editOrResponsesState, setEditOrResponsesState] = useAtom(
    useCreatePageContext().editOrResponsesStateAtom
  )

  return (
    <Container>
      <EditOrResponsesButton
        $selected={editOrResponsesState.type === 'edit'}
        onClick={() => {
          setEditOrResponsesState(prev => ({ ...prev, type: 'edit' }))
        }}
      >
        {tmpT('Edit')}
      </EditOrResponsesButton>
      <EditOrResponsesButton
        $selected={editOrResponsesState.type === 'responses'}
        onClick={() => {
          setEditOrResponsesState(prev => ({ ...prev, type: 'responses', liveSessionId: undefined }))
        }}
      >
        {tmpT('Responses')}
      </EditOrResponsesButton>
    </Container>
  )
}

const SUPPORTED_FILES: Record<File['data']['type'], boolean> = {
  'question-card': false,
  'slate-card': false,
  'bullet': false,
  'general': false,
  'image': false,
  'poll': false,
  'sliding-scale': false,
  'video': false,
  'embed': false,
  'assessment-card': false,
  'flip-cards': false,
  'homework': false,
  'stupid-questions': false,
  'drop-a-word': false,
  'scenario': false,
  'sticky-notes': false,
  'notepad': false,
  'breakout-room': false,
  'live-lobby': false,
  'project-card': false,
  'external-notepad': false,
  'roleplay': false,

  'reflections': true,
}

export const DataHistorySwitch: FC<{ contentId: ScopedCreateContentId; fileId: FileId }> = ({
  contentId,
  fileId,
}) => {
  const flagEnabled = useFlag('responses-in-editor')
  const card = useSelector(state =>
    selectFlexibleContentFile(state, ScopedCreateContentId.extractId(contentId), fileId)
  )

  if (!flagEnabled) {
    return null
  }

  if (card?.data.type === undefined || !SUPPORTED_FILES[card.data.type]) {
    return null
  }

  if (ScopedCreateContentId.isSelfPacedId(contentId)) {
    return <SelfPacedDataHistorySwitch contentId={contentId} />
  }

  if (ScopedCreateContentId.isLiveContentId(contentId)) {
    return <LiveDataHistorySwitch contentId={contentId} />
  }

  return null
}

import { FC, PropsWithChildren, useMemo } from 'react'
import { useConfig } from 'sierra-client/context/config-context'
import { dynamicColor } from 'sierra-ui/color'
import { ColorValue } from 'sierra-ui/color/types'
import { ThemeMode, legacyDark, legacyLight } from 'sierra-ui/theming/legacy-theme'
import { ThemeProvider } from 'styled-components'

import { config } from 'sierra-client/config/global-config'
import { isNonEmptyString } from 'sierra-domain/utils'

const fontUrl = (fileId: string | null | undefined): string =>
  isNonEmptyString(fileId)
    ? `https://res.cloudinary.com/sana-ai/raw/upload/${config.organization.bucketPrefix}-assets/${config.organization.tenantId}/organization-settings/${fileId}`
    : ''

export type CustomThemeName =
  | 'custom-1'
  | 'custom-2'
  | 'custom-3'
  | 'custom-4'
  | 'custom-5'
  | 'custom-6'
  | 'custom-7'
  | 'custom-8'
  | 'custom-9'
  | 'custom-10'
  | 'custom-11'
  | 'custom-12'
  | 'custom-13'
  | 'custom-14'
  | 'custom-15'
  | 'custom-16'
  | 'custom-17'
  | 'custom-18'
  | 'custom-19'
  | 'custom-20'
  | 'custom-21'
  | 'custom-22'
  | 'custom-23'
  | 'custom-24'
  | 'custom-25'
  | 'custom-26'
  | 'custom-27'
  | 'custom-28'

export type CustomTheme = {
  backgroundColor: ColorValue
  foregroundColor: ColorValue
}

/**
 * @deprecated The API layer has not yet incorporated type checking for colors, so to support some legacy code
 * we need to be able to coerce the CustomTheme type.
 */
export function coerceCustomTheme({
  backgroundColor,
  foregroundColor,
}: {
  backgroundColor: string
  foregroundColor: string
  customThemeName: string
}): CustomTheme {
  return {
    backgroundColor: dynamicColor(backgroundColor).toRgbaString(),
    foregroundColor: dynamicColor(foregroundColor).toRgbaString(),
  }
}

export const DomainThemeProvider: FC<PropsWithChildren<{ defaultMode: ThemeMode }>> = ({
  children,
  defaultMode,
}) => {
  const config = useConfig()
  const theme = useMemo((): typeof legacyLight => {
    const defaultProvider = defaultMode === 'light' ? legacyLight : legacyDark

    const customFonts = config.settings.brand.fonts
    const customThemes = config.settings.brand.customThemes
    const {
      customTheme1,
      customTheme2,
      customTheme3,
      customTheme4,
      customTheme5,
      customTheme6,
      customTheme7,
      customTheme8,
      customTheme9,
      customTheme10,
      customTheme11,
      customTheme12,
      customTheme13,
      customTheme14,
      customTheme15,
      customTheme16,
      customTheme17,
      customTheme18,
      customTheme19,
      customTheme20,
      customTheme21,
      customTheme22,
      customTheme23,
      customTheme24,
      customTheme25,
      customTheme26,
      customTheme27,
      customTheme28,
    } = customThemes
    return base => ({
      ...defaultProvider(base),
      customFonts: {
        regular: fontUrl(customFonts?.regular),
        bold: fontUrl(customFonts?.bold),
        italic: fontUrl(customFonts?.italic),
        boldItalic: fontUrl(customFonts?.boldItalic),
        headline: fontUrl(customFonts?.headline),
        headlineItalic: fontUrl(customFonts?.headlineItalic),
        headlineBold: fontUrl(customFonts?.headlineBold),
        headlineBoldItalic: fontUrl(customFonts?.headlineBoldItalic),
      },
      customThemes: {
        'custom-1': customTheme1 === null ? null : coerceCustomTheme(customTheme1),
        'custom-2': customTheme2 === null ? null : coerceCustomTheme(customTheme2),
        'custom-3': customTheme3 === null ? null : coerceCustomTheme(customTheme3),
        'custom-4': customTheme4 === null ? null : coerceCustomTheme(customTheme4),
        'custom-5': customTheme5 === null ? null : coerceCustomTheme(customTheme5),
        'custom-6': customTheme6 === null ? null : coerceCustomTheme(customTheme6),
        'custom-7': customTheme7 === null ? null : coerceCustomTheme(customTheme7),
        'custom-8': customTheme8 === null ? null : coerceCustomTheme(customTheme8),
        'custom-9': customTheme9 === null ? null : coerceCustomTheme(customTheme9),
        'custom-10': customTheme10 === null ? null : coerceCustomTheme(customTheme10),
        'custom-11': customTheme11 === null ? null : coerceCustomTheme(customTheme11),
        'custom-12': customTheme12 === null ? null : coerceCustomTheme(customTheme12),
        'custom-13': customTheme13 === null ? null : coerceCustomTheme(customTheme13),
        'custom-14': customTheme14 === null ? null : coerceCustomTheme(customTheme14),
        'custom-15': customTheme15 === null ? null : coerceCustomTheme(customTheme15),
        'custom-16': customTheme16 === null ? null : coerceCustomTheme(customTheme16),
        'custom-17': customTheme17 === null ? null : coerceCustomTheme(customTheme17),
        'custom-18': customTheme18 === null ? null : coerceCustomTheme(customTheme18),
        'custom-19': customTheme19 === null ? null : coerceCustomTheme(customTheme19),
        'custom-20': customTheme20 === null ? null : coerceCustomTheme(customTheme20),
        'custom-21': customTheme21 === null ? null : coerceCustomTheme(customTheme21),
        'custom-22': customTheme22 === null ? null : coerceCustomTheme(customTheme22),
        'custom-23': customTheme23 === null ? null : coerceCustomTheme(customTheme23),
        'custom-24': customTheme24 === null ? null : coerceCustomTheme(customTheme24),
        'custom-25': customTheme25 === null ? null : coerceCustomTheme(customTheme25),
        'custom-26': customTheme26 === null ? null : coerceCustomTheme(customTheme26),
        'custom-27': customTheme27 === null ? null : coerceCustomTheme(customTheme27),
        'custom-28': customTheme28 === null ? null : coerceCustomTheme(customTheme28),
      },
    })
  }, [config, defaultMode])

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

import { useAtomValue } from 'jotai'
import React, { useCallback, useMemo, useState } from 'react'
import { useCoursePermissionSettings } from 'sierra-client/api/hooks/use-course-permission'
import { SelfPacedPublishState } from 'sierra-client/api/hooks/use-self-paced-publish-state'
import { useNotif } from 'sierra-client/components/common/notifications'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { useOrganizationCluster } from 'sierra-client/features/multi-tenancy'
import { useContentKindPermissions, useHasContentKindPermission } from 'sierra-client/hooks/use-permissions'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCreatePageYDocContext } from 'sierra-client/views/flexible-content/create-page-context'
import { PublishModal } from 'sierra-client/views/flexible-content/editor/topbar/publish-modal/publish-modal'
import { PublishModalState } from 'sierra-client/views/flexible-content/editor/topbar/publish-modal/types'
import { GetSelfPacedCoursePublishStateResponse } from 'sierra-domain/api/author-v2'
import { SelfPacedContentId } from 'sierra-domain/api/nano-id'
import {
  XRealtimeAuthorPublishSelfPacedCourse,
  XRealtimeAuthorUnpublishSelfPacedCourse,
} from 'sierra-domain/routes'
import { assertNever, iife } from 'sierra-domain/utils'
import { MenuButton, MenuButtonVariant, MenuItem } from 'sierra-ui/components'
import { Button } from 'sierra-ui/primitives'

type SelfPacedContentTopbarProps = {
  selfPacedContentId: SelfPacedContentId
  publishState: SelfPacedPublishState
  title: string
}

export function derivePublishButtonState(
  publishState: GetSelfPacedCoursePublishStateResponse | undefined,
  latestYUpdateId: number | undefined
): 'loading' | 'draft' | 'publish-changes' | 'published' {
  if (publishState === undefined) return 'loading'

  if (!publishState.published) {
    return 'draft'
  }

  if (publishState.publishedYUpdateId !== undefined && latestYUpdateId !== undefined) {
    if (latestYUpdateId > publishState.publishedYUpdateId) {
      return 'publish-changes'
    }
  }

  return publishState.pending ? 'publish-changes' : 'published'
}

function getMenuButtonVariantFromState(
  state: ReturnType<typeof derivePublishButtonState>
): MenuButtonVariant {
  switch (state) {
    case 'loading':
      return 'pending'
    case 'draft':
      return 'pending'
    case 'publish-changes':
      return 'action'
    case 'published':
      return 'success'
    default:
      assertNever(state)
  }
}

const useUnpublishAllowed = (selfPacedContentId: SelfPacedContentId): boolean => {
  const coursePermissionSettingsQuery = useCoursePermissionSettings(selfPacedContentId)
  const coursePermissions = useContentKindPermissions(selfPacedContentId)
  const isVisibleEverywhereAllowed = coursePermissions.has('EDIT_VISIBLE_EVERYWHERE')
  const canEditPublishedState = coursePermissions.has('EDIT_PUBLISHED')

  return (
    canEditPublishedState &&
    !(
      coursePermissionSettingsQuery.data?.visibilityInOrg === 'visible-everyone' &&
      !isVisibleEverywhereAllowed
    )
  )
}

export const SelfPacedContentTopbar: React.FC<SelfPacedContentTopbarProps> = ({
  selfPacedContentId,
  publishState,
  title,
}) => {
  const { postWithUserErrorException } = usePost()
  const { push } = useNotif()
  const { t } = useTranslation()
  const [publishModalState, setPublishModalState] = useState<PublishModalState>('closed')
  const [isPublishing, setIsPublishing] = useState(false)

  const { latestYUpdateIdAtom } = useCreatePageYDocContext()
  const latestYUpdateId = useAtomValue(latestYUpdateIdAtom)

  const publishButtonState = derivePublishButtonState(publishState.state, latestYUpdateId)
  const canEditPublishedState = useHasContentKindPermission(selfPacedContentId, 'EDIT_PUBLISHED')

  const organizationCluster = useOrganizationCluster()
  const isClusterParent = !organizationCluster.loading && organizationCluster.selfIsParent

  const publish = useCallback(async () => {
    await postWithUserErrorException(XRealtimeAuthorPublishSelfPacedCourse, { contentId: selfPacedContentId })
    await publishState.refresh()

    push({ type: 'course-published', url: `/s/${selfPacedContentId}` })

    if (isClusterParent) {
      setPublishModalState('distribution-settings')
    } else {
      setPublishModalState('closed')
    }
  }, [postWithUserErrorException, selfPacedContentId, publishState, push, isClusterParent])

  const onPublish = useCallback(async () => {
    if (publishButtonState === 'draft') {
      setPublishModalState('reminder')
      return
    }

    setIsPublishing(true)
    await publish()
    setIsPublishing(false)
  }, [publishButtonState, publish])

  const onUnpublish = useCallback(async () => {
    await postWithUserErrorException(XRealtimeAuthorUnpublishSelfPacedCourse, {
      contentId: selfPacedContentId,
    })
    await publishState.refresh()
    push({ type: 'course-unpublished' })
  }, [postWithUserErrorException, push, publishState, selfPacedContentId])

  const label = iife(() => {
    switch (publishButtonState) {
      case 'loading':
        return t('admin.author.loading')
      case 'draft':
        return t('admin.author.publish-draft')
      case 'publish-changes':
        return t('admin.author.publish-changes')
      case 'published':
        return t('admin.author.published')
      default:
        assertNever(publishButtonState)
    }
  })

  const buttonVariant = getMenuButtonVariantFromState(publishButtonState)

  const isUnpublishAllowed = useUnpublishAllowed(selfPacedContentId)

  const publishMenuItems = useMemo<MenuItem<'unpublish'>[]>(
    () => [
      {
        id: 'unpublish' as const,
        type: 'label',
        label: t('admin.author.unpublish'),
        disabled: ['loading', 'draft'].includes(publishButtonState) || !isUnpublishAllowed,
      },
    ],
    [isUnpublishAllowed, publishButtonState, t]
  )

  const buttonTrigger = useMemo(() => {
    return (
      <Button
        variant={publishButtonState === 'published' ? 'success' : 'primary'}
        loading={isPublishing}
        disabled={publishButtonState === 'loading' || !canEditPublishedState || isPublishing}
        onClick={onPublish}
      >
        {label}
      </Button>
    )
  }, [canEditPublishedState, isPublishing, label, onPublish, publishButtonState])

  const menuButtonTrigger = useMemo(() => {
    return (
      <MenuButton
        variant={buttonVariant}
        disabled={publishButtonState === 'loading' || !canEditPublishedState || isPublishing}
        loading={isPublishing}
        onPrimaryClick={onPublish}
        onSelect={item => {
          switch (item.id) {
            case 'unpublish':
              void onUnpublish()
              return
            default:
              assertNever(item.id)
          }
        }}
        menuDirection='down'
        menuItems={publishMenuItems}
        menuLabel={t('dictionary.more-options')}
      >
        {label}
      </MenuButton>
    )
  }, [
    buttonVariant,
    canEditPublishedState,
    isPublishing,
    label,
    onPublish,
    onUnpublish,
    publishButtonState,
    t,
    publishMenuItems,
  ])

  return (
    <>
      <PublishModal
        state={publishModalState}
        onStateChange={setPublishModalState}
        publish={publish}
        selfPacedContentId={selfPacedContentId}
        title={title}
        trigger={isClusterParent ? buttonTrigger : menuButtonTrigger}
      />

      {canEditPublishedState && (
        <ShortcutMenu.Action
          label={{ type: 'untranslated', value: label }}
          run={publish}
          disabled={publishButtonState === 'loading'}
          iconId='book'
          group='create'
          permission='CREATE_CONTENT_EDIT_PUBLISHED'
        />
      )}
    </>
  )
}

import { useSetAtom } from 'jotai'
import { shuffle } from 'lodash'
import { useMemo } from 'react'
import { Skill } from 'sierra-client/api/graphql/gql/graphql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { RouterLink } from 'sierra-client/components/common/link'
import { getAssetContextFromLearnEntity } from 'sierra-client/components/util/asset-contex'
import { useGetFormattedTime } from 'sierra-client/core/format'
import { OpenSelectSkillModalAtom } from 'sierra-client/features/skills/components/learner-board/atoms'
import { Board } from 'sierra-client/features/skills/components/learner-board/board'
import { toLearnerSkillLevelEntities } from 'sierra-client/features/skills/helpers'
import { getMySkillsContent } from 'sierra-client/features/skills/shared-gql-queries'
import { useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsTablet } from 'sierra-client/state/browser/selectors'
import { ScaleOnHover } from 'sierra-client/views/authentication/native/components/common'
import { ContentIcon } from 'sierra-client/views/workspace/components'
import { convertLearnEntityToLinkable } from 'sierra-client/views/workspace/utils/entity-to-linkable'
import { detailsUrl } from 'sierra-client/views/workspace/utils/urls'
import { LearnEntity } from 'sierra-domain/api/entities'
import { isDefined, isEmptyArray, isNotDefined } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Button, Skeleton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const EmptyContainer = styled(View)`
  border-radius: 20px;
  background-color: ${token('surface/soft')};
  height: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`

const ContentList = styled.ul`
  list-style: none;
  overflow: visible;
`

const ContentListItem = styled.li`
  height: 45px;
  width: 100%;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`

const ContentContainer = styled(View)`
  height: 45px;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }
`

const ContentImage = styled.img`
  width: 80px;
  border-radius: 10px;
  height: 45px;
`

const ContentTitle = styled(Text)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
`

type SkillContent = {
  skillName: Skill['name']
  entity: LearnEntity
}

export const ContentItem: React.FC<SkillContent> = ({ entity, skillName }) => {
  const { title, image, timeEstimate, entityType } = entity

  const assetContext = useMemo(() => getAssetContextFromLearnEntity(entity), [entity])
  const resolvedImage = useResolveCourseAsset({
    image,
    options: { type: 'default', size: 'small' },
    assetContext,
  })

  const linkable = convertLearnEntityToLinkable(entity)

  const formattedTime = useGetFormattedTime(timeEstimate, false)

  return (
    <ContentListItem>
      <ScaleOnHover
        initial={{ scale: 1 }}
        exit={{ scale: 1 }}
        transition={{ ease: [0.25, 0.1, 0.25, 1], duration: 0.18 }}
        whileHover={{ scale: 1.03 }}
        whileTap={{ scale: 1.01 }}
      >
        <RouterLink href={detailsUrl(linkable)}>
          <ContentContainer>
            <ContentImage src={resolvedImage} alt={title} />
            <View direction='column'>
              <ContentTitle bold>{title}</ContentTitle>
              <View direction='row'>
                <ContentIcon type={entityType} color='foreground/muted' />
                <Text bold color='foreground/muted'>
                  {formattedTime}
                </Text>
                <Icon size='size-10' iconId='radio-button--dot' color='foreground/muted' />
                <Text color='foreground/muted'>{skillName}</Text>
              </View>
            </View>
          </ContentContainer>
        </RouterLink>
      </ScaleOnHover>
    </ContentListItem>
  )
}

export const LevelUpBoard: React.FC = () => {
  const { data, isLoading } = useGraphQuery(
    {
      document: getMySkillsContent,
      queryOptions: {
        select(data) {
          return data.me.skills.map(skill => {
            if (isDefined(skill)) {
              return {
                ...skill,
                skillLevelEntities: toLearnerSkillLevelEntities(
                  skill.myProgress,
                  skill.sequentialUnlockingEnabled,
                  skill.skillLevels
                ),
              }
            } else return undefined
          })
        },
      },
    },
    {}
  )

  const setSelectSkillOpen = useSetAtom(OpenSelectSkillModalAtom)
  const { t } = useTranslation()
  const isTablet = useIsTablet()

  const content = useMemo(() => {
    const skills = data
    if (isNotDefined(skills)) {
      return []
    }

    const content = [...skills].reduce<SkillContent[]>((result, skill) => {
      const isDuplicate = (entity: LearnEntity): boolean => result.some(item => item.entity.id === entity.id)

      if (isDefined(skill?.myProgress)) {
        const contents: SkillContent[] = skill.skillLevelEntities
          .filter(level => level.skillLevel.isLocked === false)
          .flatMap(level => level.entities)
          .filter((e): e is LearnEntity => e.entityType !== 'skill:restricted-content')
          .filter(e => e.learnerContext.progress < 1)
          .filter(e => !isDuplicate(e))
          .map(e => ({
            entity: e,
            skillName: skill.name,
          }))
        return result.concat(contents)
      }
      return result
    }, [])

    return shuffle(content)
  }, [data])

  if (isLoading) {
    return (
      <Board
        disableBorder
        disableManageButton
        title={isTablet ? undefined : t('skills.board.level-up.title')}
      >
        <View direction='column' gap='24'>
          <Skeleton $height={45} $radius={10} />
          <Skeleton $height={45} $radius={10} />
          <Skeleton $height={45} $radius={10} />
          <Skeleton $height={45} $radius={10} />
          <Skeleton $height={45} $radius={10} />
        </View>
      </Board>
    )
  }

  if (isEmptyArray(content)) {
    return (
      <Board
        disableBorder
        disableManageButton
        title={isTablet ? undefined : t('skills.board.level-up.title')}
      >
        <EmptyContainer>
          <Icon iconId='add--skill' color='foreground/muted' />
          <View direction='column' alignItems='center'>
            <Text color='foreground/secondary' bold>
              {t('skills.board.level-up.empty.title')}
            </Text>
            <Text color='foreground/muted'>{t('skills.board.level-up.empty.subtitle')}</Text>
          </View>
          <Button variant='secondary' onClick={() => setSelectSkillOpen(true)}>
            {t('skills.board.buttons.browse-more.text')}
          </Button>
        </EmptyContainer>
      </Board>
    )
  }

  return (
    <Board disableBorder disableManageButton title={isTablet ? undefined : t('skills.board.level-up.title')}>
      <ContentList>
        {content.slice(0, 5).map(({ entity, skillName }) => (
          <ContentItem key={entity.id} entity={entity} skillName={skillName} />
        ))}
      </ContentList>
    </Board>
  )
}

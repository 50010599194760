import { useState } from 'react'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser, selectUserId } from 'sierra-client/state/user/user-selector'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { DropAWord } from 'sierra-client/views/v3-author/drop-a-word/renderer/drop-a-word'
import { PillDefinition } from 'sierra-client/views/v3-author/drop-a-word/renderer/types'
import { UserId, uuid } from 'sierra-domain/api/uuid'
import { apply } from 'sierra-domain/editor/operations'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { DropAWordData } from 'sierra-domain/flexible-content/types'
import { asNonNullable, assert } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'

const emoji = ['🎉', '🎊', '🎈', '🎁', '😀', '😃', '🙌', '🤨']

const getMockUpPills = (userId: UserId | undefined): PillDefinition[] => {
  if (userId === undefined) return []
  const mocks = [
    { word: 'lorem', id: uuid(), color: color('pinkBright'), userId: userId },
    { word: 'ipsum', id: uuid(), color: color('blueBright'), userId: userId },
    { word: 'dolor', id: uuid(), color: color('greenBright'), userId: userId },
    { word: 'sit amet', id: uuid(), color: color('redVivid'), userId: userId },
    { word: 'consectetur', id: uuid(), color: color('orangeBright'), userId: userId },
    { word: 'adipiscing', id: uuid(), color: color('purpleVivid'), userId: userId },
    { word: 'elit', id: uuid(), color: color('blueVivid'), userId: userId },
    { word: 'sed do eiusmod', id: uuid(), color: color('pinkBright'), userId: userId },
    { word: 'tempor', id: uuid(), color: color('blueBright'), userId: userId },
    { word: 'incididunt', id: uuid(), color: color('greenBright'), userId: userId },
    { word: 'ut labore', id: uuid(), color: color('redVivid'), userId: userId },
    { word: 'et dolore', id: uuid(), color: color('orangeBright'), userId: userId },
    { word: 'magna', id: uuid(), color: color('purpleVivid'), userId: userId },
    { word: 'aliqua', id: uuid(), color: color('blueVivid'), userId: userId },
    { word: 'Ut enim', id: uuid(), color: color('pinkBright'), userId: userId },
    { word: 'ad minim', id: uuid(), color: color('blueBright'), userId: userId },
    { word: 'veniam', id: uuid(), color: color('greenBright'), userId: userId },
  ]
  return mocks.map(({ word, ...rest }, index) => {
    return {
      word: word + ' ' + emoji[index % emoji.length],
      ...rest,
    }
  })
}

export const CreateDropAWordCard: React.FC<{
  readOnly: boolean
  fileData: DropAWordData
  fileId: FileId
}> = ({ readOnly, fileData, fileId }) => {
  const user = useSelector(selectUser)
  const mockupPills = getMockUpPills(user?.uuid)
  const [pills, setPills] = useState<PillDefinition[]>(mockupPills)
  const avatarColor = color(user?.avatarColor ?? 'blueVivid')
  const userId = useSelector(selectUserId)

  const { operationState } = useCreatePageContext()

  const onTitleChanged = (title: string): void => {
    apply(operationState, {
      type: 'update-files',
      fileIds: [fileId],
      update: file => {
        assert(file.data.type === 'drop-a-word')
        file.data.title = title
      },
    })
  }

  return (
    <DropAWord
      pills={pills}
      readOnly={readOnly}
      title={fileData.title}
      onTitleChanged={onTitleChanged}
      canDeletePillWithId={() => true}
      deletePillWithId={id => setPills(pills => pills.filter(pill => pill.id !== id))}
      onAddWord={word => {
        setPills(pills => [
          ...pills,
          { word: word, id: uuid(), color: avatarColor, userId: asNonNullable(userId) },
        ])
      }}
    />
  )
}

import _, { capitalize } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useTypedMutation } from 'sierra-client/state/api'
import { AssistantDifficulty } from 'sierra-client/views/v3-author/scenario/difficulty-view'
import { useScenarioCardActions } from 'sierra-client/views/v3-author/scenario/hooks'
import { Background } from 'sierra-client/views/v3-author/scenario/scenario-background'
import { ScenarioPreview } from 'sierra-client/views/v3-author/scenario/scenario-preview'
import {
  ScenarioObjectiveSuggestions,
  ScenarioSuggestions,
} from 'sierra-client/views/v3-author/scenario/scenario-suggestions'
import { assertScenarioCard, ScenarioFile } from 'sierra-client/views/v3-author/scenario/utils'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { File } from 'sierra-domain/flexible-content/types'
import { XRealtimeSelfPacedScenarioGenerateScenario } from 'sierra-domain/routes'
import { assert, isNonEmptyString } from 'sierra-domain/utils'
import { FoldingColumn, Icon, Modal } from 'sierra-ui/components'
import { aiGradient } from 'sierra-ui/components/ai-kit/ai-gradient'
import { HideScrollbarUnlessHovered } from 'sierra-ui/components/layout-kit'
import {
  Button,
  Heading,
  IconButton,
  InputPrimitive,
  Spacer,
  Text,
  TextAreaPrimitive,
  View,
} from 'sierra-ui/primitives'
import { LightTokenProvider, token } from 'sierra-ui/theming'
import { LightTheme } from 'sierra-ui/theming/legacy-theme'
import styled from 'styled-components'

const HorizontalDivider = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${token('border/default')};
  flex-shrink: 0;
`

const Scrollable = styled(View).attrs({ grow: true, gap: '24', direction: 'column' })`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  ${HideScrollbarUnlessHovered}
  > * {
    margin-bottom: 12px;
  }
`
const ScenarioStepsWrapper = styled(View).attrs({ grow: true, direction: 'column', gap: '24' })`
  padding: 32px 22px 32px 32px;
`
const FooterWrapper = styled(View).attrs({
  alignItems: 'flex-end',
})`
  border-top: 1px solid ${token('border/default')};
  padding: 24px 32px;
`

const ScenarioSection: React.FC<{ file: ScenarioFile; courseId: CreateContentId }> = ({ file, courseId }) => {
  const { t } = useTranslation()
  const { setInputScenario } = useScenarioCardActions({ file })

  const { scenario } = file.data.input
  return (
    <View direction='column' gap='24'>
      <View direction='column' gap='8'>
        <View direction='column'>
          <Text bold capitalize='first'>
            {t('scenario-card.scenario.title.text')}
          </Text>
          <TextAreaPrimitive
            placeholder={t('scenario-card.scenario.title.placeholder')}
            value={scenario?.description ?? ''}
            onChange={v => setInputScenario('', v.currentTarget.value)}
            rows={1}
            autoExpand
          />
        </View>
        <ScenarioSuggestions courseId={courseId} fileId={file.id} />
      </View>
      <Background file={file} />
    </View>
  )
}

const LearnerSection: React.FC<{ file: ScenarioFile; courseId: CreateContentId }> = ({ file, courseId }) => {
  const { t } = useTranslation()
  const { setLearnerGoal, setLearnerRole } = useScenarioCardActions({ file })

  const { scenario, learnerGoal, learnerRole } = file.data.input

  return (
    <View direction='column' gap='none'>
      <View direction='column'>
        <Text bold capitalize='first'>
          {t('dictionary.role')}
        </Text>
        <InputPrimitive
          placeholder={t('scenario-card.scenario.learner-role.placeholder')}
          value={learnerRole ?? ''}
          onChange={v => {
            setLearnerRole(v.currentTarget.value)
          }}
        />
      </View>

      <Spacer size='24' />
      <View direction='column' gap='12'>
        <Text bold capitalize='first'>
          {t('scenario-card.scenario.learner-objective.title')}
        </Text>
        <TextAreaPrimitive
          placeholder={t('scenario-card.scenario.learner-objective.placeholder')}
          value={learnerGoal ?? ''}
          onChange={v => setLearnerGoal(v.currentTarget.value)}
          rows={1}
          autoExpand
        />
      </View>

      {isNonEmptyString(scenario?.title) && (
        <>
          <Spacer size='8' />
          <ScenarioObjectiveSuggestions
            courseId={courseId}
            fileId={file.id}
            topic={{ title: scenario.title, description: scenario.description }}
          />
        </>
      )}
    </View>
  )
}

const AssistantSection: React.FC<{ file: ScenarioFile }> = ({ file }) => {
  const { t } = useTranslation()
  const { setAssistantRole } = useScenarioCardActions({ file })

  const { assistantRole } = file.data.input

  return (
    <View direction='column' gap='24'>
      <View direction='column'>
        <Text bold capitalize='first'>
          {t('dictionary.role')}
        </Text>
        <InputPrimitive
          placeholder={t('scenario-card.scenario.assistant-role.placeholder')}
          value={assistantRole ?? ''}
          onChange={v => {
            setAssistantRole(v.currentTarget.value)
          }}
        />
      </View>
      <View direction='column' gap='12'>
        <Text bold capitalize='first'>
          {t('dictionary.behaviour')}
        </Text>
        <AssistantDifficulty file={file} />
      </View>
    </View>
  )
}

const NumberIcon = styled(Text).attrs({ bold: true })`
  background: ${token('foreground/muted')};
  color: ${token('surface/default')};
  border-radius: 50%;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
`
const BetaPill = styled(Text).attrs({ base: '#5B59EA', bold: true, size: 'micro' })`
  ${aiGradient};
  color: ${token('button/foreground')};
  width: 37px;
  height: 22px;
  padding: 2px 2px 2px 2px;
  gap: 10px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`
const SubtitleText = styled(Text)`
  max-width: 400px;
`
const SettingsV2: React.FC<{
  file: ScenarioFile
  courseId: CreateContentId
}> = ({ file, courseId }) => {
  const { t } = useTranslation()

  return (
    <ScenarioStepsWrapper>
      <View direction='column' gap='16' justifyContent='center' alignItems='center'>
        <Icon iconId='skill--theatermasks' size='size-48' color='foreground/muted' />
        <View direction='column' gap='4'>
          <View justifyContent='center' gap='4'>
            <Text size='regular' bold>
              {t('scenario-card.title.text')}
            </Text>
            <BetaPill>{t('dictionary.beta')}</BetaPill>
          </View>
          <SubtitleText size='small' align='center'>
            {t('scenario-card.subtitle.text')}
          </SubtitleText>
        </View>
      </View>
      <View direction='column' gap='12'>
        <View gap='6'>
          <NumberIcon>1</NumberIcon>
          <Text capitalize='first' bold color='foreground/secondary'>
            {t('dictionary.scenario')}
          </Text>
        </View>
        <ScenarioSection file={file} courseId={courseId} />
      </View>
      <HorizontalDivider />
      <View direction='column' gap='12'>
        <View gap='6'>
          <NumberIcon>2</NumberIcon>
          <Text capitalize='first' bold color='foreground/secondary'>
            {t('dictionary.learner')}
          </Text>
        </View>
        <LearnerSection file={file} courseId={courseId} />
      </View>
      <HorizontalDivider />
      <View direction='column' gap='12'>
        <View gap='6'>
          <NumberIcon>3</NumberIcon>
          <Text capitalize='first' bold color='foreground/secondary'>
            {t('scenario-card.generate.ai-role.title')}
          </Text>
        </View>
        <AssistantSection file={file} />
      </View>
    </ScenarioStepsWrapper>
  )
}

const Wrapper = styled(View).attrs({ direction: 'column' })`
  padding: 16px 20px 20px 20px;
`

const AdvancedSettingsWrapper = styled(View)`
  height: 100%;
  min-width: 600px;
`
const AdvancedSettingsModalWrapper = styled(Modal).attrs({ disableScrollbarGutter: true })``

const AdvancedSettingsModal: React.FC<{
  file: ScenarioFile
  open: boolean
  onClose: () => void
}> = ({ file, open, onClose }) => {
  const { t } = useTranslation()
  const { switchAssistantAndLearner, changeGenerated } = useScenarioCardActions({
    file,
  })
  const { userContext, assistantContext, scenario } = file.data.generated ?? {}

  return (
    <AdvancedSettingsModalWrapper size='fit-content' open={open} onClose={onClose}>
      <AdvancedSettingsWrapper grow direction='column' gap='none'>
        <Scrollable>
          <ScenarioStepsWrapper>
            <View direction='column'>
              <Heading size='h5' bold>
                {_.capitalize(t('scenario-card.scenario-editor.title.text'))}
              </Heading>
            </View>
            <Wrapper gap='12'>
              <View direction='column' gap='24'>
                <Text capitalize='first' bold color='foreground/muted'>
                  {t('dictionary.learner')}
                </Text>
                <View direction='column'>
                  <Text bold capitalize='first'>
                    {t('dictionary.role')}
                  </Text>

                  <InputPrimitive
                    value={userContext?.role ?? ''}
                    onChange={v => {
                      changeGenerated.learnerRole(v.currentTarget.value)
                    }}
                  />
                </View>

                <View direction='column'>
                  <Text bold capitalize='first'>
                    {t('dictionary.scenario')}
                  </Text>
                  <TextAreaPrimitive
                    value={userContext?.scenario ?? ''}
                    onChange={v => {
                      changeGenerated.learnerScenario(v.currentTarget.value)
                    }}
                    autoExpand
                  />
                </View>
                <View direction='column'>
                  <View direction='column' gap='none'>
                    <Text bold capitalize='first'>
                      {t('scenario-card.generate.rules-constraints.title')}
                    </Text>

                    <Text color='foreground/secondary' capitalize='first'>
                      {t('scenario-card.generate.rules-constraints.subtitle')}
                    </Text>
                  </View>

                  <TextAreaPrimitive
                    value={
                      userContext?.goals
                        .map(g => (g.id === 'Custom-Goal' ? g.description : `${g.title}: ${g.description}`))
                        .join('\n\n') ?? ''
                    }
                    onChange={v => {
                      changeGenerated.learnerGoal(v.currentTarget.value)
                    }}
                    rows={5}
                    autoExpand
                  />
                </View>
              </View>
              <View>
                <View grow>
                  <HorizontalDivider />
                </View>
                <IconButton
                  onClick={() => {
                    switchAssistantAndLearner()
                  }}
                  iconId='chevron--sort'
                  variant='transparent'
                />
                <View grow>
                  <HorizontalDivider />
                </View>
              </View>
              <View direction='column' gap='24'>
                <Text color='foreground/muted' bold>
                  {t('scenario-card.generate.ai-role.title')}
                </Text>
                <View direction='column'>
                  <Text bold capitalize='first'>
                    {t('dictionary.role')}
                  </Text>
                  <InputPrimitive
                    value={assistantContext?.role ?? ''}
                    onChange={v => {
                      changeGenerated.assistantRole(v.currentTarget.value)
                    }}
                  />
                </View>
                <View direction='column'>
                  <Text bold capitalize='first'>
                    {t('dictionary.scenario')}
                  </Text>
                  <TextAreaPrimitive
                    value={assistantContext?.scenario ?? ''}
                    onChange={v => {
                      changeGenerated.assistantScenario(v.currentTarget.value)
                    }}
                    autoExpand
                  />
                </View>
                <View direction='column'>
                  <View direction='column' gap='none'>
                    <Text bold capitalize='first'>
                      {t('scenario-card.generate.rules-constraints.title')}
                    </Text>

                    <Text color='foreground/secondary' capitalize='first'>
                      {t('scenario-card.generate.rules-constraints.subtitle')}
                    </Text>
                  </View>

                  <TextAreaPrimitive
                    value={
                      assistantContext?.goals
                        .map(g => (g.id === 'Custom-Goal' ? g.description : `${g.title}: ${g.description}`))
                        .join('\n\n') ?? ''
                    }
                    onChange={v => {
                      changeGenerated.assissantGoal(v.currentTarget.value)
                    }}
                    rows={5}
                    autoExpand
                  />
                </View>
              </View>

              <View grow>
                <HorizontalDivider />
              </View>
              <View direction='column' gap='24'>
                <Text capitalize='first' color='foreground/muted' bold>
                  {t('dictionary.generated-scenario')}
                </Text>
                <View direction='column'>
                  <Text bold capitalize='first'>
                    {t('dictionary.title')}
                  </Text>
                  <InputPrimitive
                    value={scenario?.title ?? ''}
                    onChange={e => changeGenerated.scenarioTitle(e.currentTarget.value)}
                  />
                </View>

                <View direction='column'>
                  <Text bold capitalize='first'>
                    {t('dictionary.description')}
                  </Text>
                  <TextAreaPrimitive
                    value={scenario?.description ?? ''}
                    onChange={v => {
                      changeGenerated.scenarioDescription(v.currentTarget.value)
                    }}
                    rows={5}
                    autoExpand
                  />
                </View>
              </View>
            </Wrapper>
          </ScenarioStepsWrapper>
        </Scrollable>
        <FooterWrapper justifyContent='flex-end' grow>
          <Button
            variant='secondary'
            onClick={() => {
              onClose()
            }}
          >
            {capitalize(t('dictionary.close'))}
          </Button>
        </FooterWrapper>
      </AdvancedSettingsWrapper>
    </AdvancedSettingsModalWrapper>
  )
}

const SettingsWrapper = styled(View)`
  height: calc(100% - 24px);
  outline: 1px solid ${token('border/default')};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background: ${token('surface/default')};
  margin: 12px;
  position: relative;
`

const ClosingButton = styled(IconButton).attrs({ iconId: 'close' })`
  position: absolute;
  top: 16px;
  right: 16px;
`

const InitialSettingsWrapper = styled(View)`
  height: 100%;
`

const Settings: React.FC<{
  onGenerate: () => void
  loading: boolean
  file: File
  courseId: CreateContentId
  onClose: () => void
}> = ({ file, courseId, onClose, onGenerate, loading }) => {
  assertScenarioCard(file)
  const { t } = useTranslation()
  const { assistantContext, userContext } = file.data.generated ?? {}
  const hasScenario = assistantContext !== undefined && userContext !== undefined

  return (
    <SettingsWrapper grow justifyContent='space-between' direction='column'>
      {hasScenario && <ClosingButton onClick={onClose} variant='transparent' />}
      <InitialSettingsWrapper gap='none' direction='column' grow>
        <Scrollable>
          <SettingsV2 courseId={courseId} file={file} />
        </Scrollable>
        <FooterWrapper justifyContent='flex-end' grow>
          <Button
            icon='sana-symbol'
            loading={loading}
            disabled={loading}
            variant='primary'
            onClick={() => {
              onGenerate()
            }}
          >
            {t('scenario-card.buttons.generate-scenario.text')}
          </Button>
        </FooterWrapper>
      </InitialSettingsWrapper>
    </SettingsWrapper>
  )
}

const EditorWrapper = styled(View)`
  height: 100%;
`
const ScenarioCardWrapper = styled(View)`
  background-color: #f3f2f5;
`

export const CreateScenarioCard: React.FC<{
  file: File
  courseId: CreateContentId
  readOnly: boolean
}> = ({ file, courseId, readOnly }) => {
  assertScenarioCard(file)
  const { t } = useTranslation()
  const { scenario } = file.data.input
  const [openAdvancedSettings, setOpenAdvancedSettings] = useState(false)
  const [edit, setEdit] = useState(!readOnly)
  const hasGenerated = file.data.generated !== undefined

  const { setInputScenario, setGenerated } = useScenarioCardActions({
    file,
  })

  const getScenarioMutation = useTypedMutation(XRealtimeSelfPacedScenarioGenerateScenario, {
    onSuccess: ({ userContext, assistantContext, scenario: generatedScenario }) => {
      assert(scenario !== undefined)
      const { title, description } = scenario
      setInputScenario(title, description)
      setGenerated({ userContext, assistantContext, scenario: generatedScenario })
    },
  })

  const loading = getScenarioMutation.isPending

  return (
    <ScenarioCardWrapper grow gap='none'>
      <EditorWrapper grow padding='small' justifyContent='center' alignItems='center' direction='column'>
        <ScenarioPreview
          readOnly={readOnly}
          file={file}
          courseId={courseId}
          isEditing={edit}
          loading={loading}
        />
        <View>
          <Button variant='secondary' onClick={() => setEdit(e => !e)}>
            {t('scenario-card.buttons.edit-scenario')}
          </Button>
          {hasGenerated && (
            <Button disabled={loading} variant='transparent' onClick={() => setOpenAdvancedSettings(true)}>
              {t('scenario-card.buttons.edit-full-prompt')}
            </Button>
          )}
        </View>
      </EditorWrapper>
      <FoldingColumn width={632} open={edit} disableScrollbarGutter>
        <LightTheme>
          <LightTokenProvider>
            <Settings
              loading={loading}
              onGenerate={() => {
                void getScenarioMutation.mutateAsync({ courseId, fileId: file.id })
                setEdit(false)
              }}
              file={file}
              courseId={courseId}
              onClose={() => setEdit(false)}
            />
          </LightTokenProvider>
        </LightTheme>
      </FoldingColumn>

      {openAdvancedSettings && (
        <AdvancedSettingsModal
          file={file}
          onClose={() => setOpenAdvancedSettings(false)}
          open={openAdvancedSettings}
        />
      )}
    </ScenarioCardWrapper>
  )
}

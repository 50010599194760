import { useMemo, useRef } from 'react'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { getAssetContextFromEditableContent } from 'sierra-client/components/util/asset-contex'
import { usePost } from 'sierra-client/hooks/use-post'
import { useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { getEntityIconId } from 'sierra-client/views/workspace/components'
import {
  LastUpdateLabel,
  TruncatedAutoWidth,
} from 'sierra-client/views/workspace/components/content-table-row-components'
import {
  pinnableContentType,
  usePinnedContentDrop,
} from 'sierra-client/views/workspace/create-new/use-pinned-drop'
import { isCollaboratorRoleAboveOrEqual } from 'sierra-client/views/workspace/utils/collaborator-role-utils'
import { Linkable, continueUrl } from 'sierra-client/views/workspace/utils/urls'
import { CreatePageContent } from 'sierra-domain/api/create-page'
import { EditableContent, NonEditorTeamspaceCourse, editUrl } from 'sierra-domain/api/editable-content'
import { XRealtimeUserRemovePins } from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { Icon, TruncatedText } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { palette, token } from 'sierra-ui/theming'
import { maxWidth, minWidth } from 'sierra-ui/utils/media-query-styles'
import styled, { css } from 'styled-components'

const PINNED_TABLE_WIDTH = 336
const PINNED_TABLE_MARGIN = 72

const PinnedContentTable = styled(View)`
  ${minWidth.desktop_small} {
    position: sticky;
  }

  top: 16px;
  min-width: ${PINNED_TABLE_WIDTH}px;
  max-width: ${PINNED_TABLE_WIDTH}px;
  margin-left: ${PINNED_TABLE_MARGIN}px;

  ${maxWidth.desktop_small} {
    margin-left: 0;
    min-width: 100%;
    max-width: 100%;
  }
`

const StyledThumbnail = styled(Thumbnail)`
  width: 48px;
  height: 32px;
  min-width: 48px;
  min-height: 32px;
  border-radius: 8px;
`

const PinnedRowWrapper = styled(View)`
  cursor: pointer;

  &:hover {
    ${TruncatedAutoWidth} {
      color: ${token('foreground/primary')};
    }
  }
`

const PinnedContentTableRow: React.FC<{
  content: EditableContent | NonEditorTeamspaceCourse
  refetchContent: () => void
}> = ({ content, refetchContent }) => {
  const { postWithUserErrorException } = usePost()
  const { t } = useTranslation()
  const removePin = async (): Promise<void> => {
    await postWithUserErrorException(XRealtimeUserRemovePins, {
      items: [{ id: content.id, type: pinnableContentType(content.type) }],
    }).then(() => {
      refetchContent()
    })
  }

  const isNativeContent = content.type === 'native:self-paced' || content.type === 'native:live'
  const isPath = content.type === 'path'
  const lastEditedBy = isPath || isNativeContent ? content.lastEditedBy : undefined
  const iconId = getEntityIconId(content.type, false)

  const { highestCollaboratorRole } = content
  const roleIsAtLeastCommenter =
    isDefined(highestCollaboratorRole) && isCollaboratorRoleAboveOrEqual(highestCollaboratorRole, 'commenter')

  const linkable: Linkable = { id: content.id, type: content.type }
  const link = roleIsAtLeastCommenter ? editUrl(content.type, content.id) : continueUrl(linkable)

  const assetContext = useMemo(() => getAssetContextFromEditableContent(content), [content])

  const imageSrc = useResolveCourseAsset({
    image: content.image,
    options: { type: 'default', size: 'small' },
    assetContext,
  })

  return (
    <PinnedRowWrapper
      onClick={() => {
        void getGlobalRouter().navigate({ to: link })
      }}
    >
      <StyledThumbnail image={imageSrc} />
      <View direction='column' grow gap='4'>
        <TruncatedText lines={1} size='small' bold>
          {content.title}
        </TruncatedText>
        <View gap='6' grow>
          <Icon color='foreground/muted' iconId={iconId} size='size-12' />
          <LastUpdateLabel textSize='micro' lastEditorId={lastEditedBy} updatedAt={content.timestamp} />
        </View>
      </View>
      <IconMenu
        size='small'
        iconId='overflow-menu--vertical'
        onSelect={async (): Promise<void> => {
          await removePin()
        }}
        menuItems={[
          {
            id: 'pin',
            type: 'label',
            label: t('live.unpin'),
          },
        ]}
        variant='transparent'
      />
    </PinnedRowWrapper>
  )
}

const PinnedTableWrapper = styled(View).attrs({ gap: '16', direction: 'column', padding: '16' })<{
  showDropArea: boolean
}>`
  border-radius: 18px;
  border: 1px solid ${color(palette.primitives.black).opacity(0.05)};
  box-shadow: 0px 1px 2px 0px #00000014;

  ${p =>
    p.showDropArea &&
    css`
      background: rgba(20, 170, 255, 0.1);
    `}
`

const EmptyPinnedTable: React.FC = () => {
  const { t } = useTranslation()
  return (
    <View direction='column' padding='48'>
      <Icon iconId='pin--filled' color='foreground/muted' size='size-16' />
      <View direction='column' gap='none' justifyContent='center' alignItems='center'>
        <Text bold color='foreground/muted' align='center'>
          {t('create-page.pinned.title')}
        </Text>
        <Text color='foreground/muted' align='center'>
          {t('create-page.pinned.body')}
        </Text>
      </View>
    </View>
  )
}

export const PinnedTable: React.FC<{
  pinnedContent: CreatePageContent['pinned']
  refetchContent: () => void
}> = ({ pinnedContent, refetchContent }) => {
  const { t } = useTranslation()
  const dropRef = useRef<HTMLDivElement | null>(null)
  const { isOver, canDrop, drop } = usePinnedContentDrop(refetchContent)

  drop(dropRef)
  const content = pinnedContent.content.map(it => {
    return it.content
  })

  const noPinnedContent = content.length === 0
  const showDropArea = isOver && canDrop

  return (
    <PinnedContentTable gap='12' direction='column'>
      <View>
        <Icon iconId='pin' color='foreground/secondary' />
        <Text bold color='foreground/secondary'>
          {t('workspace.create.pinned')}
        </Text>
      </View>
      <PinnedTableWrapper showDropArea={showDropArea} ref={dropRef}>
        {noPinnedContent ? (
          <EmptyPinnedTable />
        ) : (
          <>
            {content.map(it => {
              return <PinnedContentTableRow key={it.id} content={it} refetchContent={refetchContent} />
            })}
          </>
        )}
      </PinnedTableWrapper>
    </PinnedContentTable>
  )
}

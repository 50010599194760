import React, { useEffect, useMemo } from 'react'
import { BreakoutRoom } from 'sierra-client/components/liveV2/cards/breakout-room'
import { Lobby } from 'sierra-client/components/liveV2/cards/lobby'
import { LiveVideoPlayerCard } from 'sierra-client/components/liveV2/cards/video-player-card'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { useIsFacilitatorOrLearnerLedSession } from 'sierra-client/components/liveV2/hooks/use-is-facilitator-or-learner-led-session'
import { cardViewed } from 'sierra-client/core/logging/authoring/logger'
import { usePresenceAtom } from 'sierra-client/features/sana-now/hooks/use-presence-atom'
import { useSelectAtomValue } from 'sierra-client/hooks/use-select-atom-value'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectFacilitatorIds } from 'sierra-client/state/live-session/selectors'
import { FCC } from 'sierra-client/types'
import { FocusEditorContext } from 'sierra-client/views/flexible-content/editor-focus-context'
import { EmbedCard } from 'sierra-client/views/flexible-content/embed-card'
import { ExternalNotepadSlateCard } from 'sierra-client/views/flexible-content/external-notepad'
import { FileContext } from 'sierra-client/views/flexible-content/file-context'
import { LearnerImageCard } from 'sierra-client/views/flexible-content/image-card'
import { MigrateToExternalNotepad } from 'sierra-client/views/flexible-content/migrate-to-external-notepads'
import { SlateCard } from 'sierra-client/views/flexible-content/slate-card'
import { StickyNotesCard } from 'sierra-client/views/sticky-notes-card/learner'
import { LiveDropAWordCard } from 'sierra-client/views/v3-author/drop-a-word/live-drop-a-word-card'
import { ProjectCard } from 'sierra-client/views/v3-author/project-card/learner'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { CreateContentId, LiveContentId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { ScopedLiveContentId } from 'sierra-domain/collaboration/types'
import { LiveFile } from 'sierra-domain/flexible-content/support'
import { assertNever } from 'sierra-domain/utils'

const InterceptKeyboardNavigation: FCC = ({ children }) => {
  return (
    <div
      onKeyDown={e => {
        if (['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft'].includes(e.key)) {
          e.stopPropagation()
        }
      }}
    >
      {children}
    </div>
  )
}

const SMALL_SESSION_LIMIT = 180

const useSelectCanInteractWithAwarenessCards = (): boolean => {
  const presenceAtom = usePresenceAtom()
  const facilitators = useSelector(selectFacilitatorIds)
  const canFacilitate = useIsFacilitatorOrLearnerLedSession()

  const liveSession = useLiveSessionContext()
  const isLearnerLedSession = liveSession.data.learnerLedSession === true

  const isSmallSession = useSelectAtomValue(presenceAtom, it => it.length <= SMALL_SESSION_LIMIT)

  const canInteract = useMemo(() => {
    // Everyone can facilitate, so we just look at how many users are present
    if (isLearnerLedSession) return isSmallSession

    // I'm a learner, so I can only interact if there aren't too many people present
    if (!canFacilitate) return isSmallSession

    // I'm a facilitator, so I can always interact unless there are too many facilitators
    return facilitators.length <= SMALL_SESSION_LIMIT
  }, [canFacilitate, facilitators.length, isLearnerLedSession, isSmallSession])

  return canInteract
}

const Switch: React.FC<{ file: LiveFile; courseId: CreateContentId }> = ({ file, courseId }) => {
  const pasteFile = useStableFunction(() => {
    // Pasting files is not supported in live
  })
  const canInteractWithAwarenessCards = useSelectCanInteractWithAwarenessCards()

  const assetContext: AssetContext = useMemo(() => ({ type: 'course' as const, courseId }), [courseId])

  const mode: EditorMode = 'live'
  const readOnly = file.data.type !== 'notepad'
  switch (file.data.type) {
    case 'live-lobby':
      return <Lobby flexibleContentId={courseId} />
    case 'breakout-room':
      return <BreakoutRoom data={file.data} flexibleContentId={courseId} />
    case 'video':
      return <LiveVideoPlayerCard video={file.data.video} videoId={file.id} />
    case 'image':
      return <LearnerImageCard data={file.data} assetContext={assetContext} />
    case 'embed':
      return <EmbedCard readOnly data={file.data} />
    case 'sticky-notes':
      return (
        <StickyNotesCard
          fileId={file.id}
          mode={mode}
          blockedDueToSessionLimit={!canInteractWithAwarenessCards}
        />
      )
    case 'notepad': {
      return <MigrateToExternalNotepad liveContentId={LiveContentId.parse(courseId)} />
    }
    case 'external-notepad':
      return (
        <InterceptKeyboardNavigation>
          <ExternalNotepadSlateCard
            mode={mode}
            pasteFile={pasteFile}
            file={{ ...file, data: file.data }}
            scopedLiveContentId={ScopedLiveContentId.fromId(LiveContentId.parse(courseId))}
            blockedDueToSessionLimit={!canInteractWithAwarenessCards}
            assetContext={assetContext}
          />
        </InterceptKeyboardNavigation>
      )

    case 'project-card':
      return <ProjectCard fileId={file.id} mode={mode} />
    case 'poll':
    case 'sliding-scale':
    case 'flip-cards':
    case 'reflections':
    case 'question-card':
    case 'slate-card':
    case 'bullet':
    case 'general':
      return <SlateCard readOnly={readOnly} courseId={courseId} file={file} mode={mode} />
    case 'drop-a-word':
      return <LiveDropAWordCard fileId={file.id} contentId={courseId} fileData={file.data} />

    default:
      assertNever(file.data)
  }
}

export const LiveCardRenderer: React.FC<{ file: LiveFile; courseId: CreateContentId }> = ({
  file,
  courseId,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    void dispatch(
      cardViewed({
        contentId: courseId,
        fileId: file.id,
        cardType: file.data.type,
        mode: 'live',
      })
    )
  }, [courseId, dispatch, file.data.type, file.id])

  return (
    <FocusEditorContext>
      <FileContext file={file} flexibleContentId={courseId}>
        <Switch file={file} courseId={courseId} />
      </FileContext>
    </FocusEditorContext>
  )
}

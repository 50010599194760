import { AnimatePresence, motion } from 'framer-motion'
import { Atom } from 'jotai'
import React, { useMemo, useState } from 'react'
import { ChatThread } from 'sierra-client/components/chat/chat-thread'
import { MessageInput } from 'sierra-client/components/chat/input'
import { Messages } from 'sierra-client/components/chat/messages'
import { TypingUserData } from 'sierra-client/components/chat/typing-user-data-type'
import { UsersWriting } from 'sierra-client/components/chat/users-writing'
import { useSelectAtom } from 'sierra-client/hooks/use-select-atom-value'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsTablet } from 'sierra-client/state/browser/selectors'
import { ChatIdentifier } from 'sierra-domain/api/chat'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { MenuItem } from 'sierra-ui/components'
import { IconButton, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled, { css } from 'styled-components'

const ChatContainer = styled.div<{ hasRoundedCorners: boolean; hideBorder: boolean }>`
  background-color: ${token('surface/default')};
  color: ${token('foreground/primary')};
  border: 1px solid ${token('border/default')};
  border-radius: 0.5rem;
  ${p =>
    p.hasRoundedCorners === false && `border-radius: 0; border-top: 0; border-right: 0; border-bottom: 0;`}
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  ${p =>
    p.hideBorder === true
      ? css`
          border: none;
        `
      : ''};
`

const Header = styled.div`
  font-size: 14px;
  padding: 1.125rem 1rem 1rem 1.5rem;
  font-weight: ${fonts.weight.medium};
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`

const ThreadContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  padding-left: 1.5rem;
  height: 100%;
  flex-grow: 1;
  will-change: left;
`

export type ChatProps = {
  chatId: ScopedChatId
  chatIdentifier: ChatIdentifier
  unitId?: string
  blockId?: string
  onClose?: () => void
  showClose?: boolean
  showFilter?: boolean
  title?: string
  visible: boolean
  componentKey: string
  hasRoundedCorners?: boolean
  hideBorder?: boolean
  threadId: string | undefined
  changeThreadId: (id: string | undefined) => void
  limitMessages?: number
  setCurrentUserIsTyping: (threadId: string | undefined) => void
  usersTypingAtom: Atom<TypingUserData[]>
}

type FilterId = 'unresolved' | 'resolved'

export const Chat: React.FC<ChatProps> = ({
  chatId,
  chatIdentifier,
  hasRoundedCorners = true,
  hideBorder = false,
  unitId,
  blockId,
  onClose,
  showClose = true,
  showFilter = false,
  title,
  visible,
  componentKey,
  threadId,
  changeThreadId,
  limitMessages,
  usersTypingAtom,
  setCurrentUserIsTyping,
}) => {
  const closeThread = (): void => changeThreadId(undefined)
  const { t } = useTranslation()
  const isTablet = useIsTablet()
  const [currentFilterId, setCurrentFilterId] = useState<FilterId>('unresolved')
  const usersTypingInRootAtom = useSelectAtom(usersTypingAtom, usersTyping =>
    usersTyping.filter(it => it.threadId === 'root').map(it => it.userId)
  )

  const items: MenuItem[] = useMemo(
    () => [
      { type: 'label', label: t('live.chat.all-messages'), id: 'unresolved' },
      {
        type: 'label',
        label: t('live.chat.resolved-comments'),
        id: 'resolved',
      },
    ],
    [t]
  )

  const handleChange = (item: MenuItem): void => setCurrentFilterId(item.id as FilterId)

  const hideHeader = title === undefined && !showClose && !showFilter

  return (
    <ChatContainer hasRoundedCorners={hasRoundedCorners} hideBorder={hideBorder}>
      {!hideHeader && (
        <Header>
          {showFilter ? (
            <SingleSelectDropdown
              selectedItem={items.find(it => it.id === currentFilterId)}
              menuItems={items}
              onSelect={handleChange}
            />
          ) : (
            title
          )}
          <View marginLeft='8'>
            {showClose && <IconButton iconId='close' onClick={onClose} variant='transparent' />}
          </View>
        </Header>
      )}

      <Messages
        filter={currentFilterId}
        chatId={chatId}
        chatIdentifier={chatIdentifier}
        threadId='root'
        setThreadId={changeThreadId}
        componentKey={componentKey}
        visible={visible}
        limitMessages={limitMessages}
      />

      {currentFilterId !== 'resolved' && (
        <MessageInput
          triggerFocus={isTablet === false && visible} // Not enabled on tablet/mobile since it triggers software keyboard
          chatId={chatId}
          chatIdentifier={chatIdentifier}
          unitId={unitId}
          blockId={blockId}
          threadId={threadId}
          placeholder={t('live.chat.placeholder')}
          setCurrentUserIsTyping={setCurrentUserIsTyping}
        />
      )}
      <UsersWriting usersTypingAtom={usersTypingInRootAtom} />

      <AnimatePresence>
        {threadId !== undefined && (
          <ThreadContainer
            initial={{ left: 350 }}
            animate={{ left: 0 }}
            exit={{ left: 350 }}
            transition={{ duration: 0.25, ease: [0.25, 0.1, 0.25, 1] }}
          >
            <ChatThread
              chatId={chatId}
              chatIdentifier={chatIdentifier}
              threadId={threadId}
              onClose={closeThread}
              componentKey={`${componentKey}-thread`}
              usersTypingAtom={usersTypingAtom}
              setCurrentUserIsTyping={setCurrentUserIsTyping}
            />
          </ThreadContainer>
        )}
      </AnimatePresence>
    </ChatContainer>
  )
}

import { useSetAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { useDevelopmentSnackbar } from 'sierra-client/hooks/use-debug-notif'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { narrationDataChannel } from 'sierra-client/realtime-data/channels/narration-channel'
import { useCachedQuery } from 'sierra-client/state/api'
import { isGeneratingAvatarsAtom } from 'sierra-client/views/flexible-content/editor/content-sidebar/is-generating-avatars-atom'
import {
  FileIdNarrationMap,
  NarrationMetadata,
  NarrationSettings,
  NarrationSettingsUsage,
} from 'sierra-domain/api/author-v2'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { ContentType } from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { XRealtimeAuthorGetGeneratedNarrationState } from 'sierra-domain/routes'
import { assertIsNonNullable, assertWith } from 'sierra-domain/utils'

export type NarrationState = {
  narrations: FileIdNarrationMap
  settings: NarrationSettings
}

const SyncNarrationState = ({
  contentId,
  setNarrationState,
}: {
  contentId: CreateContentId
  setNarrationState: (narrationState: NarrationState) => void
}): null => {
  const [narrations, setNarrations] = useState<FileIdNarrationMap>({})
  const [usage, setUsage] = useState<NarrationSettingsUsage | undefined>(undefined)

  const { reportInDev } = useDevelopmentSnackbar()

  const updateNarrations = useStableFunction((incomingNarrations: [FileId, NarrationMetadata][]) => {
    setNarrations(oldNarrations => {
      const newNarrations = { ...oldNarrations }

      for (const [fileId, newNarration] of incomingNarrations) {
        const existingNarration = oldNarrations[fileId]
        if (existingNarration === undefined) {
          newNarrations[fileId] = newNarration
        } else if (existingNarration.createdAt > newNarration.createdAt) {
          // The update we received was for an older narration than the latest one created.
          // This likely means that the old one was cancelled, and something changed about
          // its state in the backend. Currently however, we only care about the most recently
          // created narration.
        } else if (existingNarration.updatedAt < newNarration.updatedAt) {
          newNarrations[fileId] = newNarration
        } else {
          // The new value was older than the existing value, so we should ignore it
        }
      }

      return newNarrations
    })
  })

  const updateUsage = useStableFunction((newUsage: NarrationSettingsUsage) => {
    setUsage(oldUsage => {
      if (oldUsage === undefined || oldUsage.updatedAt < newUsage.updatedAt) {
        return newUsage
      }
      return oldUsage
    })
  })

  const { isReceivingData } = narrationDataChannel.useChannel({
    channelId: contentId,
    callback: ({ event, data }) => {
      switch (event) {
        case 'settings-usage-updated':
          reportInDev(`Narrations - Minutes used: ${data.usage.minutesUsed}`, { variant: 'info' })
          updateUsage(data.usage)
          return
        case 'narration-updated':
          reportInDev(`Narrations - new state in ${data.fileId}: ${data.narration.type}`, {
            variant: 'info',
          })
          updateNarrations([[data.fileId, data.narration]])
          return
        default:
          return
      }
    },
  })

  const query = useCachedQuery(
    XRealtimeAuthorGetGeneratedNarrationState,
    { contentId },
    // Once we are connected to ably we fetch the state from the backend.
    // This means we don't risk missing new updates that come in while the
    // state is being fetched
    { enabled: isReceivingData }
  )

  useEffect(() => {
    if (query.data !== undefined) {
      updateNarrations(
        Object.entries(query.data.narrations).map(([fileId, narration]) => {
          assertWith(FileId, fileId)
          assertIsNonNullable(narration)

          return [fileId, narration]
        })
      )

      const settings = query.data.settings

      if (settings.usage !== undefined) {
        updateUsage(settings.usage)
      }
    }
  }, [query.data, updateNarrations, updateUsage])

  useEffect(() => {
    if (query.data !== undefined) {
      setNarrationState({ narrations, settings: { ...query.data.settings, usage } })
    }
  }, [narrations, query.data, setNarrationState, usage])

  const setIsGeneratingAvatarsAtom = useSetAtom(isGeneratingAvatarsAtom)
  useEffect(() => {
    setIsGeneratingAvatarsAtom(() => {
      const newState: Record<FileId, boolean> = {}
      for (const [fileId, value] of Object.entries(narrations)) {
        const isLoading = value?.type === 'loading'
        newState[fileId as FileId] = isLoading
      }
      return newState
    })
  }, [narrations, setIsGeneratingAvatarsAtom])

  return null
}

export const NarrationStateSync: React.FC<{
  contentId: CreateContentId
  canEdit: boolean
  contentType: ContentType
  setNarrationState: (narrationState: NarrationState) => void
}> = ({ contentId, canEdit, contentType, setNarrationState }) => {
  const aiNarrationsEnabled = useFlag('ai-narration')

  const enabled = contentType === 'self-paced' && canEdit && aiNarrationsEnabled

  if (!enabled) return null

  return <SyncNarrationState contentId={contentId} setNarrationState={setNarrationState} />
}

import { zodResolver } from '@hookform/resolvers/zod'
import { AnimatePresence, motion } from 'framer-motion'
import React, { FC, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNotif } from 'sierra-client/components/common/notifications'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { SearchSettings } from 'sierra-client/views/manage/settings/components/search-settings'
import { MeetingToolsSettings } from 'sierra-client/views/manage/settings/components/video-call-provider-settings'
import { GeneralSettingsFormData } from 'sierra-client/views/manage/settings/types'
import { useOrganizationSettings } from 'sierra-client/views/manage/settings/use-organization-settings'
import { useSearchSettings } from 'sierra-client/views/manage/settings/use-search-settings'
import { FormInput, FormTextArea, FormTimezoneInput } from 'sierra-client/views/manage/shared/form'
import { PageHeader } from 'sierra-client/views/settings/components/page-header'
import { SettingsSection } from 'sierra-client/views/settings/components/settings-section'
import { SectionSkeleton } from 'sierra-client/views/settings/emails-and-notifications/section-skeleton'
import {
  XRealtimeManageSearchSettingsPostDefaultSetting,
  XRealtimeManageSearchSettingsPostSettings,
} from 'sierra-domain/routes'
import { Button, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StickySave = styled(motion.div).attrs({
  initial: {
    translateY: 8,
    opacity: 0,
  },
  animate: {
    translateY: 0,
    opacity: 1,
  },
  exit: {
    translateY: 8,
    opacity: 0,
  },
})`
  position: sticky;
  bottom: 16px;
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: flex-end;
`

const GeneralSettingsForm: FC<{
  initialState: GeneralSettingsFormData
  onSubmit: (values: GeneralSettingsFormData) => void
  loading: boolean
}> = ({ initialState, onSubmit, loading }) => {
  const { t } = useTranslation()

  const platformDescriptionEnabled = useFlag('platform-description')

  const sanaNowEnabled = useFlag('sana-now')

  const { formState, reset, handleSubmit, control, watch, setValue } = useForm({
    defaultValues: initialState,
    resolver: zodResolver(GeneralSettingsFormData),
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SettingsSection
        iconId='wiki'
        title='settings.general-page.system-defaults.title'
        subtitle='settings.general-page.system-defaults.subtitle'
      >
        <FormTimezoneInput
          control={control}
          name='timezone'
          label={t('manage.settings.customization.timezone')}
        />
      </SettingsSection>

      <SettingsSection
        iconId='keyboard'
        title='settings.general-page.naming.title'
        subtitle='settings.general-page.naming.subtitle'
      >
        <View direction='column' gap='24'>
          <FormInput
            control={control}
            name='platformName'
            placeholder='Sana'
            label={t('manage.settings.platform-name.title')}
            helper={t('manage.settings.platform-name.body')}
          />

          {platformDescriptionEnabled && (
            <FormTextArea
              control={control}
              name='platformDescription'
              label={t('manage.settings.platform-description.title')}
              helper={t('manage.settings.platform-description.body')}
              rows={4}
              placeholder='Sana is where your team comes to learn. Our AI-powered learning platform helps you share knowledge, learn fast, automate admin and perform 10x faster.'
              autoExpand={false}
              autoFocus={false}
            />
          )}

          <FormInput
            control={control}
            name='pageTitle'
            label={t('manage.settings.page-title.title')}
            helper={t('manage.settings.page-title.body')}
            placeholder='Sana'
          />

          <FormInput
            control={control}
            name='welcomeMessage'
            label={t('manage.settings.welcome-message.title')}
            helper={t('manage.settings.welcome-message.body')}
            placeholder='Welcome'
          />
        </View>
      </SettingsSection>

      <SettingsSection
        iconId='search'
        title='settings.general-page.search.title'
        subtitle='settings.general-page.search.subtitle'
      >
        <SearchSettings control={control} watchValues={watch} />
      </SettingsSection>

      {sanaNowEnabled && (
        <SettingsSection
          iconId='video-call'
          title='settings.general-page.meeting-tools.title'
          subtitle='settings.general-page.meeting-tools.subtitle'
        >
          <MeetingToolsSettings setValue={setValue} control={control} watchValues={watch} />
        </SettingsSection>
      )}

      <AnimatePresence>
        {formState.isDirty && (
          <StickySave>
            <Button
              type='button'
              variant='secondary'
              onClick={() => {
                reset(initialState, { keepDirty: false })
              }}
              disabled={loading}
            >
              {t('dictionary.discard')}
            </Button>
            <Button disabled={loading}>{t('modal.save-changes')}</Button>
          </StickySave>
        )}
      </AnimatePresence>
    </form>
  )
}

export const ManageSettings: React.FC<unknown> = () => {
  const { t } = useTranslation()
  const [submitting, setSubmitting] = useState(false)
  const { settings, updateSettings } = useOrganizationSettings()
  const { searchSettings, defaultSetting } = useSearchSettings()
  const { postWithUserErrorException } = usePost()
  const notifications = useNotif()

  const loading = searchSettings === undefined || defaultSetting === undefined

  const initialState = useMemo<GeneralSettingsFormData>(
    () => ({
      timezone: settings.preferredTimezoneId ?? null,
      platformName: settings.brand.platformName ?? '',
      platformDescription: settings.brand.platformDescription ?? '',
      pageTitle: settings.brand.pageTitle ?? '',
      welcomeMessage: settings.brand.welcomeMessage ?? '',

      enabledNewIntegrations: defaultSetting?.enableNewIntegration ?? false,

      openAiResponses: searchSettings?.openAi.assistantFlag ?? false,

      googleDrive: {
        connect: searchSettings?.drive.connectFlag ?? false,
        assistant: searchSettings?.drive.assistantFlag ?? false,
      },
      microsoft: {
        connect: searchSettings?.drive.connectFlag ?? false,
        assistant: searchSettings?.drive.assistantFlag ?? false,
      },

      meetSettings: {
        enabled: settings.meetingToolsSettings.meetSettings.enabled,
      },
      teamsSettings: {
        enabled: settings.meetingToolsSettings.teamsSettings.enabled,
      },
      zoomSettings: {
        enabled: settings.meetingToolsSettings.zoomSettings.enabled,
      },
      sanaLiveSettings: {
        enabled: settings.meetingToolsSettings.sanaSettings.enabled,
      },
    }),
    [defaultSetting?.enableNewIntegration, searchSettings, settings]
  )

  async function onSubmit(values: GeneralSettingsFormData): Promise<void> {
    setSubmitting(true)

    try {
      await Promise.all([
        updateSettings({
          ...settings,
          preferredTimezoneId: values.timezone,
          brand: {
            ...settings.brand,
            platformName: values.platformName,
            platformDescription: values.platformDescription,
            pageTitle: values.pageTitle,
            welcomeMessage: values.welcomeMessage,
          },
          meetingToolsSettings: {
            meetSettings: {
              enabled: values.meetSettings.enabled,
            },
            teamsSettings: {
              enabled: values.teamsSettings.enabled,
            },
            zoomSettings: {
              enabled: values.zoomSettings.enabled,
            },
            sanaSettings: {
              enabled: values.sanaLiveSettings.enabled,
            },
          },
        }),
        postWithUserErrorException(XRealtimeManageSearchSettingsPostDefaultSetting, {
          enableNewIntegration: values.enabledNewIntegrations,
        }),
        postWithUserErrorException(XRealtimeManageSearchSettingsPostSettings, {
          integration: 'openAi',
          connectFlag: true,
          assistantFlag: values.openAiResponses,
        }),
        postWithUserErrorException(XRealtimeManageSearchSettingsPostSettings, {
          integration: 'drive',
          connectFlag: values.googleDrive.connect,
          assistantFlag: values.googleDrive.assistant,
        }),
        postWithUserErrorException(XRealtimeManageSearchSettingsPostSettings, {
          integration: 'microsoft',
          connectFlag: values.microsoft.connect,
          assistantFlag: values.microsoft.assistant,
        }),
      ])
    } finally {
      setSubmitting(false)
    }

    notifications.push({
      type: 'custom',
      level: 'success',
      body: t('notifications.done'),
    })
  }

  return (
    <>
      <PageTitle title={t('settings.general-page.title')} />

      <View direction='column' grow>
        <PageHeader
          title='settings.general-page.title'
          subtitle='settings.general-page.subtitle'
          helpLink='https://help.sana.ai/en/articles/96582-customizing-your-sana-domain'
        />

        {loading ? (
          <>
            <SectionSkeleton />
            <SectionSkeleton />
          </>
        ) : (
          <GeneralSettingsForm initialState={initialState} onSubmit={onSubmit} loading={submitting} />
        )}
      </View>
    </>
  )
}

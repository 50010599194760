import * as auth from 'sierra-client/core/logging/auth/logger'
import * as authoring from 'sierra-client/core/logging/authoring/logger'
import * as certificates from 'sierra-client/core/logging/certificates/logger'
import * as courseGroup from 'sierra-client/core/logging/course-group/logger'
import * as course from 'sierra-client/core/logging/course/logger'
import * as email from 'sierra-client/core/logging/email/logger'
import * as enrollment from 'sierra-client/core/logging/enrollment/logger'
import * as eventGroup from 'sierra-client/core/logging/event-group/logger'
import * as exercise from 'sierra-client/core/logging/exercise/logger'
import * as group from 'sierra-client/core/logging/group/logger'
import * as inlineCompletion from 'sierra-client/core/logging/inline-auto-complete/logger'
import * as liveSession from 'sierra-client/core/logging/live-session/logger'
import { Logger } from 'sierra-client/core/logging/logger'
import * as path from 'sierra-client/core/logging/path/logger'
import * as program from 'sierra-client/core/logging/program/logger'
import * as recommendation from 'sierra-client/core/logging/recommendation/logger'
import * as scorm from 'sierra-client/core/logging/scorm/logger'
import * as search from 'sierra-client/core/logging/search/logger'
import * as settings from 'sierra-client/core/logging/settings/logger'
import * as skill from 'sierra-client/core/logging/skill/logger'

export abstract class Logging {
  public static readonly auth = auth
  public static readonly authoring = authoring
  public static readonly certificates = certificates
  public static readonly course = course
  public static readonly courseGroup = courseGroup
  public static readonly email = email
  public static readonly enrollment = enrollment
  public static readonly exercise = exercise
  public static readonly group = group
  public static readonly liveSession = liveSession
  public static readonly page = Logger.pageLogger
  public static readonly path = path
  public static readonly program = program
  public static readonly recommendation = recommendation
  public static readonly scorm = scorm
  public static readonly search = search
  public static readonly settings = settings
  public static readonly skill = skill
  public static readonly eventGroup = eventGroup
  public static readonly inlineCompletion = inlineCompletion
}

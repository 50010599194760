import { createFileRoute } from '@tanstack/react-router'

import React from 'react'
import { InsightsHome, LoadRecentOrDefaultDashboard } from 'sierra-client/features/insights'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { useInsightsEnabled } from 'sierra-client/views/manage/hooks/use-insights-enabled'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { PageHeader } from 'sierra-client/views/settings/components/page-header'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

// Sadly needed due to how the manage layout works
const Wrapper = styled(View)`
  height: 100%;
  width: 100%;
`

const InsightsDashboardsPage: React.FC = () => {
  const insightsEnabled = useInsightsEnabled()
  const newInsightsHome = useFlag('insights/new-home')

  if (!insightsEnabled) {
    return null
  }

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageInsights()}>
      <Wrapper direction='column'>
        <View direction='column'>
          <PageHeader title='manage.insights.headline' withoutMargin />

          {newInsightsHome ? <InsightsHome /> : <LoadRecentOrDefaultDashboard />}
        </View>
      </Wrapper>
    </ManagePageWrapper>
  )
}

const RouteComponent = InsightsDashboardsPage

export const Route = createFileRoute('/manage/insights/dashboards/')({
  component: RouteComponent as React.FC,
})

import { useCallback } from 'react'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { assertScenarioCard, ScenarioFile } from 'sierra-client/views/v3-author/scenario/utils'
import { apply } from 'sierra-domain/editor/operations'
import { ScenarioDataSource, ScenarioGeneratedData, VoiceType } from 'sierra-domain/flexible-content/types'
import { assert } from 'sierra-domain/utils'

export const useAllowScenarioCard = (): boolean => {
  return useFlag('scenario-card')
}

export const useScenarioCardActions = ({
  file,
}: {
  file: ScenarioFile
}): {
  updateScenario: (update: (file: ScenarioFile) => void) => void
  setInputScenario: (title: string, description: string) => void
  setLearnerGoal: (goal: string) => void
  setLearnerRole: (role: string) => void
  setAssistantRole: (role: string) => void
  switchAssistantAndLearner: () => void
  setAvatar: (avatar: { characterId: string; avatar: string; voice: VoiceType }) => void
  setDataSource: (ds: Array<ScenarioDataSource>) => void
  changeGenerated: {
    learnerScenario: (scenario: string) => void
    learnerGoal: (goal: string) => void
    learnerRole: (role: string) => void
    assistantScenario: (scenario: string) => void
    assistantRole: (role: string) => void
    assissantGoal: (goal: string) => void
    scenarioTitle: (title: string) => void
    scenarioDescription: (description: string) => void
  }
  setGenerated: (generated: ScenarioGeneratedData) => void
} => {
  const { operationState } = useCreatePageContext()

  const updateScenario = useCallback(
    (update: (file: ScenarioFile) => void): void => {
      apply(operationState, {
        type: 'update-files',
        fileIds: [file.id],
        update: file => {
          assertScenarioCard(file)
          update(file)
        },
      })
    },
    [file.id, operationState]
  )

  const setInputScenario = useCallback(
    (title: string, description: string): void => {
      updateScenario(file => {
        file.data.input.scenario = { type: 'custom', title, description }
      })
    },
    [updateScenario]
  )

  const setLearnerGoal = useCallback(
    (goal: string): void => {
      updateScenario(file => {
        file.data.input.learnerGoal = goal
      })
    },
    [updateScenario]
  )

  const setUserRole = useCallback(
    (role: string) => {
      updateScenario(file => {
        file.data.input.learnerRole = role
      })
    },
    [updateScenario]
  )

  const setAssistantRole = useCallback(
    (role: string) => {
      updateScenario(file => {
        file.data.input.assistantRole = role
      })
    },
    [updateScenario]
  )

  const switchAssistantAndUser = useCallback(() => {
    updateScenario(file => {
      assert(file.data.generated !== undefined)
      const assistantContext = file.data.generated.assistantContext
      const userContext = file.data.generated.userContext

      file.data.generated.userContext = {
        goals: assistantContext.goals,
        role: assistantContext.role,
        scenario: assistantContext.scenario,
      }
      file.data.generated.assistantContext = {
        goals: userContext.goals,
        role: userContext.role,
        scenario: userContext.scenario,
      }
    })
  }, [updateScenario])

  const setAvatar = useCallback(
    ({ characterId, avatar, voice }: { characterId: string; avatar: string; voice: VoiceType }) => {
      updateScenario(file => {
        assert(file.data.input.character !== undefined)
        file.data.input.character = { ...file.data.input.character, name: characterId, avatar, voice }
      })
    },
    [updateScenario]
  )

  const setDataSource = useCallback(
    (ds: Array<ScenarioDataSource>) => {
      updateScenario(file => {
        file.data.input.dataSources = ds
      })
    },
    [updateScenario]
  )

  const setGenerated = useCallback(
    (generated: ScenarioGeneratedData) => {
      updateScenario(file => {
        file.data.generated = generated
      })
    },
    [updateScenario]
  )

  const changeGeneratedUserScenario = useCallback(
    (scenario: string) => {
      updateScenario(file => {
        assert(file.data.generated !== undefined)
        file.data.generated.userContext = { ...file.data.generated.userContext, scenario }
      })
    },
    [updateScenario]
  )

  const changeGeneratedUserGoal = useCallback(
    (goal: string) => {
      updateScenario(file => {
        assert(file.data.generated !== undefined)
        file.data.generated.userContext = {
          ...file.data.generated.userContext,
          goals: [{ id: 'Custom-Goal', title: 'Custom Goal', description: goal }],
        }
      })
    },
    [updateScenario]
  )

  const changeGeneratedUserRole = useCallback(
    (role: string) => {
      updateScenario(file => {
        assert(file.data.generated !== undefined)
        file.data.generated.userContext = { ...file.data.generated.userContext, role }
      })
    },
    [updateScenario]
  )

  const changeGeneratedAssistantScenario = useCallback(
    (scenario: string) => {
      updateScenario(file => {
        assert(file.data.generated !== undefined)
        file.data.generated.assistantContext = { ...file.data.generated.assistantContext, scenario }
      })
    },
    [updateScenario]
  )

  const changeGeneratedAssistantRole = useCallback(
    (role: string) => {
      updateScenario(file => {
        assert(file.data.generated !== undefined)
        file.data.generated.assistantContext = { ...file.data.generated.assistantContext, role }
      })
    },
    [updateScenario]
  )

  const changeGeneratedAssistantGoal = useCallback(
    (goal: string) => {
      updateScenario(file => {
        assert(file.data.generated !== undefined)
        file.data.generated.assistantContext = {
          ...file.data.generated.assistantContext,
          goals: [{ id: 'Custom-Goal', title: 'Custom Goal', description: goal }],
        }
      })
    },
    [updateScenario]
  )

  const changeGeneratedScenarioTitle = useCallback(
    (title: string) => {
      updateScenario(file => {
        assert(file.data.generated !== undefined)
        file.data.generated.scenario = { ...file.data.generated.scenario, title: title }
      })
    },
    [updateScenario]
  )

  const changeGeneratedScenarioDescription = useCallback(
    (description: string) => {
      updateScenario(file => {
        assert(file.data.generated !== undefined)
        file.data.generated.scenario = { ...file.data.generated.scenario, description: description }
      })
    },
    [updateScenario]
  )

  return {
    setGenerated,
    updateScenario,
    setLearnerGoal,
    setInputScenario,
    setLearnerRole: setUserRole,
    setAssistantRole,
    switchAssistantAndLearner: switchAssistantAndUser,
    setAvatar,
    setDataSource,
    changeGenerated: {
      learnerScenario: changeGeneratedUserScenario,
      learnerGoal: changeGeneratedUserGoal,
      learnerRole: changeGeneratedUserRole,
      assistantScenario: changeGeneratedAssistantScenario,
      assistantRole: changeGeneratedAssistantRole,
      assissantGoal: changeGeneratedAssistantGoal,
      scenarioTitle: changeGeneratedScenarioTitle,
      scenarioDescription: changeGeneratedScenarioDescription,
    },
  }
}

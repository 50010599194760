import { createFileRoute } from '@tanstack/react-router'

import { useEffect } from 'react'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { getGlobalRouter } from 'sierra-client/router'
import { useInsightsEnabled } from 'sierra-client/views/manage/hooks/use-insights-enabled'
import { ManageOverview } from 'sierra-client/views/manage/overview/manage-overview'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'

// Note: ManagePageWrapper handles additional auth checks.
function ManageOverviewPage(): JSX.Element | null {
  const insightsEnabled = useInsightsEnabled()
  const newInsightsHome = useFlag('insights/new-home')

  useEffect(() => {
    if (insightsEnabled) {
      if (newInsightsHome) {
        void getGlobalRouter().navigate({ to: '/manage/insights', replace: true })
      } else {
        void getGlobalRouter().navigate({ to: '/manage/reports', replace: true })
      }
    }
  }, [insightsEnabled, newInsightsHome])

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageOverview()}>
      <ManageOverview />
    </ManagePageWrapper>
  )
}

const RouteComponent = ManageOverviewPage

export const Route = createFileRoute('/manage/overview')({
  component: RouteComponent as React.FC,
})

import React, { KeyboardEventHandler, useState } from 'react'
import { getFileContentImage } from 'sierra-client/api/content'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import {
  AutoexpandingReflectionInput,
  KeyboardShortcutText,
} from 'sierra-client/views/v3-author/reflection-card/atoms'
import { useReflectionCardCreateContextSafe } from 'sierra-client/views/v3-author/reflection-card/reflection-card-create-context'
import { UserId } from 'sierra-domain/api/uuid'
import { color, deriveTheme } from 'sierra-ui/color'
import { ColorName } from 'sierra-ui/color/types'
import { AvatarStack, KeyboardButtonGroup, RoundAvatar } from 'sierra-ui/components'
import { Button, IconButton, Switch, Text, View } from 'sierra-ui/primitives'
import { LightTokenProvider, palette, token } from 'sierra-ui/theming'
import { LightTheme } from 'sierra-ui/theming/legacy-theme'
import styled from 'styled-components'

const PreviewContainer = styled.div`
  display: flex;
  user-select: none;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const PreviewThreadButton = styled.button`
  height: 28px;
  width: fit-content;
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  padding: 0;
  padding-right: 8px;
  font-size: 10px;
  font-weight: 600;
  padding-left: 4px;
  cursor: pointer;
  transition: 100ms;
  background: rgba(248, 248, 248, 1);

  &:hover {
    background-color: ${token('surface/strong')};
  }
`

const PreviewReaction = styled.button<{ highlighted: boolean }>`
  display: flex;
  align-items: center;
  height: 28px;
  padding: 0 8px 0 8px;
  background-color: ${props => (props.highlighted ? token('surface/strong') : token('surface/soft'))};
  background-color: ${props => (props.highlighted ? 'rgba(248, 248, 248, 1)' : 'rgba(248, 248, 248, 0)')};
  border-radius: 54px;
  border: none;

  &:hover {
    cursor: pointer;
    background-color: ${token('surface/strong')};
  }
`

const PreviewReactionCount = styled.span`
  font-size: 10px;
  margin-left: 6px;
  color: ${palette.primitives.black};
  font-variant-numeric: tabular-nums;
`

const PreviewInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 1.5rem;
  padding-bottom: 3rem;
  width: 33.333%;
  min-width: 300px;

  @media (max-width: 800px) {
    flex-direction: column;
    width: 100%;
    padding-right: 0;
  }
`

const StyledReflectionInputInner = styled(View).attrs({
  direction: 'column',
  background: 'surface/default',
})`
  font-size: 1rem;
  border: 1px solid ${token('border/default')};
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1rem;
`

const CardHolder = styled.div`
  columns: 3 250px;
  column-gap: 1.5rem;

  width: 66.666%;

  @media (max-width: 800px) {
    width: 100%;
  }
`

const ReflectionPreviewCardContainer = styled.div`
  font-size: 1rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: ${palette.primitives.white};
  color: ${palette.primitives.black};
  border: 1px solid ${color('grey5')};
  border-radius: 12px;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
`

const ReflectionCardFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
`

const ReflectionCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 0.5rem;
`

const ReflectionCardPreviewName = styled.div`
  color: ${p => p.theme.color.grey25};
  font-size: 0.875rem;
  font-weight: 500;
`

const ReflectionTypingCardContainer = styled.div`
  padding: 1.5rem;
  margin-bottom: 1.5rem;

  background-color: ${p => deriveTheme(p.theme).secondaryBackgroundColor};
  color: ${p => deriveTheme(p.theme).secondaryTextColor};

  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 12px;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
`

type CardProps = {
  name?: string
  text: string
  reactions?: Record<string, { count: number; highlighted: boolean }>
  replies?: number
  src?: string
}

const Card: React.FC<CardProps> = ({ name, text, reactions, replies, src }) => (
  <LightTheme>
    <ReflectionPreviewCardContainer>
      <ReflectionCardHeader>
        <RoundAvatar size='small' firstName={name} src={src} />
        <ReflectionCardPreviewName>{name}</ReflectionCardPreviewName>
      </ReflectionCardHeader>
      {text}
      <ReflectionCardFooter>
        <View direction='row' gap='4'>
          {Object.entries(reactions ?? {}).map(([emoji, { count, highlighted }]) => (
            <PreviewReaction key={emoji} highlighted={highlighted} onClick={() => {}}>
              {emoji}
              <PreviewReactionCount>{count}</PreviewReactionCount>
            </PreviewReaction>
          ))}
          <IconButton variant='transparent' iconId='face--add' color='grey25' />
        </View>
        <View direction='row' gap='4'>
          {replies !== undefined && (
            <PreviewThreadButton onClick={() => {}}>
              <AvatarStack
                users={[
                  {
                    uuid: 'preview-1' as UserId,
                    firstName: 'Rita',
                    lastName: '',
                    avatar: getFileContentImage('images/home-avatars/rita.png', { bucket: 'sierra-static' }),
                    avatarColor: 'pinkBright' as ColorName,
                  },
                  {
                    uuid: 'preview-2' as UserId,
                    firstName: 'Jon',
                    lastName: '',
                    avatar: getFileContentImage('images/home-avatars/jon.png', { bucket: 'sierra-static' }),
                    avatarColor: 'blueBright' as ColorName,
                  },
                ].slice(0, replies)}
                size='minuscule'
                max={2}
                withTooltips
              />{' '}
              {replies} {replies === 1 ? 'Reply' : 'Replies'}
            </PreviewThreadButton>
          )}
          <IconButton variant='transparent' iconId='reply' color='grey25' />
        </View>
      </ReflectionCardFooter>
    </ReflectionPreviewCardContainer>
  </LightTheme>
)

const CardPreviews: React.FC<{ previewReflection?: string; name?: string }> = ({
  previewReflection,
  name,
}) => {
  const { t } = useTranslation()

  return (
    <CardHolder>
      <Card
        text='Had an insightful call with the US team and brainstormed a new feature for improving team collaboration. Cant wait to see it come to life!'
        name='Jon'
        src={getFileContentImage('images/home-avatars/jon.png', { bucket: 'sierra-static' })}
        reactions={{
          '💯': { count: 4, highlighted: true },
          '❤️': { count: 2, highlighted: true },
          '✅': { count: 3, highlighted: false },
          '👍': { count: 1, highlighted: false },
        }}
      />

      <Card
        text='After noticing a bottleneck in our support process, I implemented a new ticketing system️ that has significantly reduced response times. So far we’ve seen a 2 point bump in our NPS (!!)'
        name='Joel'
        replies={1}
        src={getFileContentImage('images/home-avatars/joel.png', { bucket: 'sierra-static' })}
      />

      <Card
        text='Organized a remote trivia night for our team, and everyone had a blast! It was a great way to bond and relax, even though we are scattered across different time zones ❤️'
        name='Zak'
        reactions={{
          '❤️': { count: 6, highlighted: true },
        }}
        src={getFileContentImage('images/home-avatars/zak.png', { bucket: 'sierra-static' })}
      />

      <Card
        text='been working on automating repetitive tasks using a custom-built script, and now our team saves 10 hours a week, feels amazing to free up time for more creative work!'
        name='Lauren'
        replies={1}
        src={getFileContentImage('images/home-avatars/lauren.png', { bucket: 'sierra-static' })}
      />

      <Card
        text="Developed a comprehensive FAQ section for our website, addressing common user concerns. It's already reduced support inquiries by 40%"
        name='Rita'
        src={getFileContentImage('images/home-avatars/rita.png', { bucket: 'sierra-static' })}
        replies={2}
        reactions={{
          '❤️': { count: 2, highlighted: false },
          '✅': { count: 3, highlighted: true },
          '👍': { count: 4, highlighted: false },
        }}
      />

      {previewReflection !== undefined && <Card name={name} text={previewReflection} />}

      <ReflectionTypingCardContainer>
        {t('learner.poll-card.x-is-typing', { name: 'Javier' })}
      </ReflectionTypingCardContainer>
      <ReflectionTypingCardContainer>
        {t('learner.poll-card.x-is-typing', { name: 'Tamara' })}
      </ReflectionTypingCardContainer>
    </CardHolder>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AnonymousResponses: React.FC = () => {
  const context = useReflectionCardCreateContextSafe()
  const { t } = useTranslation()

  if (context === undefined) return null
  const { allowAnonymousResponses, setAllowAnonymousResponses } = context

  return (
    <Switch
      size='small'
      checked={allowAnonymousResponses}
      onChange={enable => setAllowAnonymousResponses(enable)}
      ariaLabel={t('author.reflection-card.allow-anonymous')}
      text={t('author.reflection-card.allow-anonymous')}
    />
  )
}

export const ReflectionCardPreview: React.FC = () => {
  const [tempPreviewReflection, setTempPreviewReflection] = useState<string>()
  const [previewReflection, setPreviewReflection] = useState<string>()

  const publish = (): void => {
    setPreviewReflection(tempPreviewReflection)
    setTempPreviewReflection('')
  }

  const handleKeyPress: KeyboardEventHandler<HTMLTextAreaElement> = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      event.stopPropagation()
      publish()
    }
  }
  const name = useSelector(selectUser)?.firstName

  const { t } = useTranslation()

  return (
    <>
      <View padding='16 none' justifyContent='space-between' alignItems='center'>
        <Text color='foreground/primary' bold size='small'>
          {t('dictionary.reflection-plural')}
        </Text>
      </View>

      <PreviewContainer>
        <PreviewInputContainer>
          <LightTheme>
            <LightTokenProvider>
              <StyledReflectionInputInner>
                <AutoexpandingReflectionInput
                  placeholder={t('dictionary.add-a-note')}
                  onKeyPress={handleKeyPress}
                  value={tempPreviewReflection ?? ''}
                  onChange={e => setTempPreviewReflection(e.target.value)}
                  autoExpand
                />
                <AnonymousResponses />
              </StyledReflectionInputInner>
            </LightTokenProvider>
          </LightTheme>
          <ButtonContainer>
            <Button variant='ghost' onClick={publish}>
              {t('dictionary.submit')}
            </Button>
            <KeyboardShortcutText>
              <span>{t('dictionary.or-press')}</span>
              <KeyboardButtonGroup
                listen
                codes={[/mac/i.test(navigator.platform) ? 'cmd' : 'ctrl', 'Enter']}
              />
            </KeyboardShortcutText>
          </ButtonContainer>
        </PreviewInputContainer>
        <CardPreviews previewReflection={previewReflection} name={name} />
      </PreviewContainer>
    </>
  )
}

import Ably from 'ably'
import { config } from 'sierra-client/config/global-config'
import { Auth } from 'sierra-client/core/auth'
import { setUnion } from 'sierra-client/lib/querystate/utils'
import { logger } from 'sierra-client/logger/logger'
import { iife } from 'sierra-domain/utils'
import { retry } from 'ts-retry-promise'

export const getAblyAuthParameters = ({
  channels = [],
}: { channels?: string[] } = {}): Partial<Ably.AuthOptions> => {
  const authenticate = '/x-realtime/realtime-data/authenticate'
  const host = config.auth.host
  const sanaAuthToken = Auth.isInitialized() ? Auth.getInstance().getToken() : undefined

  return {
    authUrl: host !== undefined ? `https://${host}${authenticate}` : authenticate,
    authMethod: 'POST',

    // For the authentication work in the MR preview environment, we need ably-js to set
    // withCredentials = true on the XMLHttpRequest, and it only does this if it detects
    // that there is a header named "authorization". It is important that the header name is
    // in lower case for this to work since it does an exact string matching.
    // If withCredentials is set to true, this will cause the XMLHttpRequest to include the
    // cookies, that we need for the Core service to authenticate the request.
    // For details of how the XHR requset is formed, see src/platform/web/lib/transport/xhrrequest.ts
    // in the ably-js repository.
    // The Core service will ignore the authorization header if it is set to empty string,
    // making it fall back to the cookie based authorization as we want.
    authHeaders:
      sanaAuthToken !== undefined ? { authorization: `Bearer ${sanaAuthToken}` } : { authorization: '' },

    authParams: { channels: channels.join(',') },
  }
}

function parseAuthenticatedChannels(tokenDetails: Ably.TokenDetails): string[] {
  return Object.keys(JSON.parse(tokenDetails.capability))
}

export type AblyClientWithAuthState = {
  ablyClient: Ably.Realtime
  authenticatedChannels: Set<string>
  currentToken: Ably.TokenDetails | undefined
}

let ongoingAuthRequest: Promise<Ably.TokenDetails> | undefined = undefined

/**
 * Do not call this function directly, use authenticateChannel instead
 */
export async function _authenticateChannels(
  client: AblyClientWithAuthState,
  channelNames: Set<string>
): Promise<void> {
  // We need to ensure we only do one auth request at a time, otherwise we can get a weird race where
  // we don't get all the needed channel permissions
  // we can ignore if the old request failed

  while (ongoingAuthRequest !== undefined) {
    try {
      await ongoingAuthRequest
    } catch (error) {
      /* ignore */
    }
  }

  try {
    ongoingAuthRequest = retry(
      () =>
        Promise.race([
          client.ablyClient.auth.authorize(
            undefined,
            getAblyAuthParameters({
              channels: Array.from(setUnion(client.authenticatedChannels, channelNames)),
            })
          ),
          new Promise<never>((res, rej) => setTimeout(() => rej(new Error('timeout')), 2500)),
        ]),
      {
        retries: 3,
        retryIf: error => {
          // don't rety auth errors
          if (
            error instanceof Error &&
            'statusCode' in error &&
            (error.statusCode === 403 || error.statusCode === 401)
          )
            return false
          return true
        },
        logger: message => logger.info(`retrying authenticating ably channel: ${message}`),
        delay: 500,
        timeout: 20_000,
      }
    )

    client.currentToken = await ongoingAuthRequest

    const authenticatedChannels = parseAuthenticatedChannels(client.currentToken)
    client.authenticatedChannels = new Set([...authenticatedChannels])
  } catch (error) {
    logger.info('authenticateChannel error', { error, channelNames })
    throw error
  } finally {
    ongoingAuthRequest = undefined
  }
}

type BatchState = {
  currentBatch: Set<string>
  authenticationRequest: Promise<unknown>
}
let batchState: BatchState | undefined = undefined

async function waitNTicks(n: number): Promise<void> {
  for (let i = 0; i < n; i++) {
    await new Promise(resolve => setTimeout(resolve))
  }
}

export async function authenticateChannel(
  client: AblyClientWithAuthState,
  channelName: string
): Promise<boolean> {
  // We do not want to re-authenticate channels that have already been authenticated
  if (client.authenticatedChannels.has(channelName)) {
    return true
  }

  // We want to batch up auth requests to avoid making too many requests sequentially
  if (batchState === undefined) {
    const currentBatch = new Set([channelName])
    batchState = {
      currentBatch,
      authenticationRequest: iife(async () => {
        await waitNTicks(10)
        batchState = undefined
        await _authenticateChannels(client, currentBatch)
      }),
    }
  } else {
    batchState.currentBatch.add(channelName)
  }

  try {
    await batchState.authenticationRequest
  } catch (e) {
    return false
  }

  return client.authenticatedChannels.has(channelName)
}

import { UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import { typedInvalidateQuery, useCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import {
  ContentDistributionVisibility,
  CourseVisibilityInOrg,
  GetDistributionSettingsResponse,
  SetDistributionSettingsRequest,
} from 'sierra-domain/api/author-v2'
import { CourseId } from 'sierra-domain/api/nano-id'
import { RequestError } from 'sierra-domain/error'
import { AccessibleOrganization } from 'sierra-domain/multi-tenancy'
import {
  XRealtimeGetCourseDistributionSettings,
  XRealtimeSetCourseDistributionSettings,
} from 'sierra-domain/routes'
import { assertNever } from 'sierra-domain/utils'

type CourseVisibilityWithoutPrivate = Exclude<CourseVisibilityInOrg, 'private'>

function translateVisibility(
  visibility: CourseVisibilityWithoutPrivate
): ContentDistributionVisibility | null {
  switch (visibility) {
    case 'visible-admins': {
      return 'visible-for-admins'
    }
    case 'visible-everyone': {
      return 'visible'
    }
    default:
      assertNever(visibility)
  }
}

export function constructDistributionPatch(
  organization: AccessibleOrganization,
  patch: {
    visibility: CourseVisibilityWithoutPrivate
    isDisabled?: boolean
  }
): SetDistributionSettingsRequest['settings'][number] | null {
  const namespace = organization.domain.split('.')[0]

  if (namespace === undefined) {
    throw Error(`Failed to parse namespace id from ${organization.domain}`)
  }

  const patchVisibility = translateVisibility(patch.visibility)

  if (!patchVisibility) {
    return null
  }

  const defaultDisabled = !organization.isClusterParent

  return {
    childNamespaceId: namespace,
    isDisabled: patch.isDisabled ?? defaultDisabled,
    visibility: patchVisibility,
  }
}

export const useCourseDistributionSettings = (
  courseId: CourseId
): UseQueryResult<GetDistributionSettingsResponse> => {
  const query = useCachedQuery(XRealtimeGetCourseDistributionSettings, { courseId })
  return query
}

export const useCourseDistributionSettingsMutation = (
  courseId: CourseId
): UseMutationResult<null, RequestError, SetDistributionSettingsRequest> => {
  const mutation = useTypedMutation(XRealtimeSetCourseDistributionSettings, {
    onSettled: async () => {
      await typedInvalidateQuery(XRealtimeGetCourseDistributionSettings, { courseId })
    },
  })

  return mutation
}

import { useCallback } from 'react'
import { newScenarioData } from 'sierra-client/state/flexible-content/factory'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { apply } from 'sierra-domain/editor/operations'
import { File } from 'sierra-domain/flexible-content/types'
import { assert } from 'sierra-domain/utils'

export type ScenarioFile = File & { data: { type: 'scenario' } }
export function assertScenarioCard(file: File): asserts file is ScenarioFile {
  assert(file.data.type === 'scenario')
}

export const useResetScenarioCard = (file: ScenarioFile): (() => void) => {
  const { operationState } = useCreatePageContext()

  return useCallback(() => {
    apply(operationState, {
      type: 'update-files',
      fileIds: [file.id],
      update: file => {
        assertScenarioCard(file)
        file.data = newScenarioData()
      },
    })
  }, [file.id, operationState])
}

export const replaceOrAdd = <T>(array: Array<T> | undefined, item: T, compF: (_: T) => boolean): Array<T> => {
  if (!array) return [item]
  const index = array.findIndex(compF)
  if (index !== -1) {
    return [...array.slice(0, index), item, ...array.slice(index + 1)]
  }
  return [...array, item]
}

import { useSetAtom } from 'jotai'
import { useAtomValue } from 'jotai/index'
import { useEffect, useMemo } from 'react'
import { useCachedQuery } from 'sierra-client/state/api'
import {
  initialUserAttributesAtom,
  isReadonlyAttributesAtom,
  isReadonlyUserMetadataAtom,
} from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/atoms'
import { useInitialiseAttributesDraft } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/hooks/use-initialize-attribute-draft'
import { useUserInvitationDomainLoader } from 'sierra-client/views/manage/components/user-attributes/hooks/use-invitation-domains'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeAdminCanEditUserAttributes } from 'sierra-domain/routes'

export type Status = 'loading' | 'loaded' | 'error'

export const useUserAttributeSettingsLoader = ({
  userId,
  hasEditPermission,
}: {
  userId: UserId
  hasEditPermission: boolean
}): Status => {
  const initialUserAttributes = useAtomValue(initialUserAttributesAtom)
  const setReadOnlyAttributes = useSetAtom(isReadonlyAttributesAtom)
  const setReadOnlyMetadata = useSetAtom(isReadonlyUserMetadataAtom)

  const { isLoading: isLoadingInvitationDomains } = useUserInvitationDomainLoader()
  const { isLoading: isLoadingReadOnly, data: canEditUserAttributes } = useCachedQuery(
    XRealtimeAdminCanEditUserAttributes,
    {
      userId,
    }
  )
  const { reinitialise: initialiseAttributesDraft, isLoading } = useInitialiseAttributesDraft(userId)

  useEffect(
    () => setReadOnlyAttributes(canEditUserAttributes === false),
    [setReadOnlyAttributes, canEditUserAttributes]
  )

  useEffect(() => {
    setReadOnlyMetadata(!hasEditPermission)
  }, [hasEditPermission, setReadOnlyMetadata])

  // User data
  useEffect(() => {
    void initialiseAttributesDraft()
  }, [initialiseAttributesDraft])

  return useMemo(() => {
    if (isLoading || isLoadingReadOnly || isLoadingInvitationDomains) return 'loading'
    else if (initialUserAttributes !== undefined) return 'loaded'
    else return 'error'
  }, [isLoading, isLoadingReadOnly, isLoadingInvitationDomains, initialUserAttributes])
}

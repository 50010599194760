import { Link as TanstackRouterLink, type LinkProps as TanstackLinkProps } from '@tanstack/react-router'
import { ReactNode, forwardRef } from 'react'
import { useShouldForceHardNavigation } from 'sierra-client/state/user/plugins/check-should-force-hard-navigation'
import { iife } from 'sierra-domain/utils'
import { Text, TextSize } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const StyledText = styled(Text)`
  cursor: pointer;

  transition: all 150ms;

  &:hover {
    color: ${token('foreground/primary')};
  }
`

export type LinkAction =
  | {
      onClick: () => Promise<void> | void
      href?: string
      openInNewTab?: boolean
    }
  | (Partial<Pick<HTMLLinkElement, 'target' | 'rel'>> & {
      onClick?: () => Promise<void> | void
      href: string
      openInNewTab?: boolean
    })

export type RouterLinkProps = Omit<TanstackLinkProps, 'to'> & LinkAction

export const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref): JSX.Element => {
  const { children, onClick, replace, href, openInNewTab = false, preload = false, ...extraProps } = props

  const openInNewTabProps =
    openInNewTab === true
      ? ({
          target: '_blank',
          rel: 'noopener noreferrer',
        } as const)
      : undefined

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: use typed links
    <TanstackRouterLink
      ref={ref}
      preload={preload}
      {...extraProps}
      to={href as any}
      onClick={onClick}
      {...openInNewTabProps}
    >
      {children}
    </TanstackRouterLink>
  )
})

export type LinkProps = {
  children: ReactNode
  size?: TextSize
  bold?: boolean
  next?: boolean
  openInNewTab?: boolean
} & LinkAction

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      children,
      next: preferClientSideNavigation = false,
      bold = true,
      size = 'regular',
      openInNewTab,
      ...props
    },
    ref
  ) => {
    const shouldForceHardNavigation = useShouldForceHardNavigation()

    const openInNewTabProps =
      openInNewTab === true
        ? ({
            target: '_blank',
            rel: 'noopener noreferrer',
          } as const)
        : undefined

    const Component = iife(() => {
      if (preferClientSideNavigation && !shouldForceHardNavigation) {
        return RouterLink
      } else {
        return 'a'
      }
    })

    return (
      <StyledText
        color='foreground/muted'
        ref={ref}
        as={Component}
        size={size}
        bold={bold}
        {...openInNewTabProps}
        {...props}
      >
        {children}
      </StyledText>
    )
  }
)

import { motion } from 'framer-motion'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { Icon } from 'sierra-ui/components'
import { Text } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

const StylesPopupWrapper = styled(motion.div)`
  background-color: white;
  border: 1px solid ${palette.grey[5]};
  justify-content: center;
  align-items: center;
  width: 270px;
  height: fit-content;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  margin-top: 4px;
  cursor: pointer;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  pointer-events: all;
`

const IconWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 10px;
`

const ImageContainer = styled.img`
  object-fit: cover;
  &:hover {
    filter: brightness(90%);
  }
`

const ImageWrapper = styled.div`
  position: relative;
  height: 80px;
  border-radius: 6px;
  overflow: hidden;
`

const TextWrapper = styled(Text)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  bottom: 30px;
  justify-content: center;
`

export const styles = {
  sanaDefault: '3D render',
  digitalArt: 'Digital art',
  oilPainting: 'Oil painting',
  abstract: 'Abstract',
  empty: 'Empty',
}

export const ImageStylesPopup: React.FC<{
  showStyles: boolean
  selectedStyle: number
  setSelectedStyle: (_: number) => void
  setShowstyles: (_: boolean) => void
}> = ({ showStyles, selectedStyle, setSelectedStyle, setShowstyles }) => {
  const styleExampleImages = [
    'https://storage.googleapis.com/sierra-static/images/image-generation-style-1.png',
    'https://storage.googleapis.com/sierra-static/images/image-generation-style-2.png',
    'https://storage.googleapis.com/sierra-static/images/image-generation-style-3.png',
    'https://storage.googleapis.com/sierra-static/images/image-generation-style-4.png',
  ]

  const isDebugMode = useIsDebugMode()
  return (
    <>
      {showStyles && (
        <StylesPopupWrapper>
          {isDebugMode && (
            <ImageWrapper>
              <ImageContainer
                width={'120px'}
                height={'80px'}
                src={
                  'https://images.unsplash.com/photo-1535378620166-273708d44e4c?ixid=M3wxNzY2MzR8MHwxfHNlYXJjaHwxMXx8YXJ0aWZpY2lhbCUyMGludGVsbGlnZW5jZXxlbnwwfHx8fDE2OTM1NTc2MTl8MA&ixlib=rb-4.0.3&auto=format&q=50&crop=edges&fit=max&dpr=2&w=1600&h=1600'
                }
                onClick={() => {
                  setSelectedStyle(4)
                  setShowstyles(false)
                }}
              />

              {selectedStyle === 4 && (
                <>
                  <IconWrapper>
                    <Icon size='size-16' iconId='checkmark' color='white' />
                  </IconWrapper>
                </>
              )}
              <TextWrapper bold={selectedStyle === 4 && true} size='small' color='white'>
                {Object.values(styles)[4]}
              </TextWrapper>
            </ImageWrapper>
          )}

          {styleExampleImages.map((image, index) => (
            <>
              <ImageWrapper>
                <ImageContainer
                  width={'120px'}
                  height={'80px'}
                  src={image}
                  onClick={() => {
                    setSelectedStyle(index)
                    setShowstyles(false)
                  }}
                />

                {selectedStyle === index && (
                  <>
                    <IconWrapper>
                      <Icon size='size-16' iconId='checkmark' color='white' />
                    </IconWrapper>
                  </>
                )}
                <TextWrapper bold={selectedStyle === index && true} size='small' color='white'>
                  {Object.values(styles)[index]}
                </TextWrapper>
              </ImageWrapper>
            </>
          ))}
        </StylesPopupWrapper>
      )}
    </>
  )
}

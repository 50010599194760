import { useAtomValue } from 'jotai'
import { useCallback, useMemo } from 'react'
import { AssetType, ImageResolverOptions, resolveNamespacedCourseImage } from 'sierra-client/api/content'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { loadNamespacedCourseAssetsAtom } from 'sierra-client/state/settings'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'

export const useLoadNamespacedCourseAssets = (): boolean => {
  const loadNamespacedCourseAssets = useAtomValue(loadNamespacedCourseAssetsAtom)
  const flag = useFlag('namespaced-course-assets')
  const useNamespaced = flag || loadNamespacedCourseAssets
  return useNamespaced
}

// TODO: rename to useResolveAsset, this is not just for courses
export const useResolveCourseAsset = ({
  image,
  options,
  assetContext,
  assetType,
}: {
  image: ImageUnion | undefined
  options?: ImageResolverOptions
  assetContext: AssetContext
  assetType?: AssetType
}): string => {
  const useNamespaced = useLoadNamespacedCourseAssets()
  const url = useMemo(() => {
    return resolveNamespacedCourseImage(image, options, useNamespaced, assetContext, assetType)
  }, [image, options, useNamespaced, assetContext, assetType])
  return url
}

// TODO: rename to useAssetResolver
export const useCourseAssetResolver = ({
  options,
}: {
  options?: ImageResolverOptions
} = {}): ((image: ImageUnion | undefined, assetContext: AssetContext) => string) => {
  const useNamespaced = useLoadNamespacedCourseAssets()

  return useCallback(
    (image: ImageUnion | undefined, assetContext: AssetContext) => {
      return resolveNamespacedCourseImage(image, options, useNamespaced, assetContext)
    },
    [options, useNamespaced]
  )
}

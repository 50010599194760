import fuzzysort from 'fuzzysort'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useShortcutMenuDispatch } from 'sierra-client/components/shortcut-menu/context'
import { resolveIndex } from 'sierra-client/components/shortcut-menu/resolve-index'
import { RootShortcutMenu } from 'sierra-client/components/shortcut-menu/root-shortcut-menu'
import {
  SearchBar,
  SearchBarContainer,
  SearchResult,
  SearchResults,
} from 'sierra-client/components/shortcut-menu/search-ui'
import type {
  ActionShortcut,
  ComponentShortcut,
  Group,
  LinkShortcut,
  Shortcut,
  ToggleShortcut,
} from 'sierra-client/components/shortcut-menu/types'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey, TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsOwnerUser } from 'sierra-client/state/user/user-selector'
import { FCC } from 'sierra-client/types'
import { UserOrganizationsPermission } from 'sierra-domain/api/user'
import { IconId } from 'sierra-ui/components'

/**
 * The core data-model does not (and should not) know about translations,
 * so we add this restriction in the UI layer
 *
 */
export type UILabel =
  | TranslationKey
  // `{type: 'untranslated'}` is really only appropriate for developer tools
  // or employee-only actions. Use it sparingly.
  | { type: 'untranslated'; value: string }

// There is no permission that represents owners, so that has to be handled separately
export const OWNER_ONLY = Symbol('Only available for Sana employees.')
type OrgPermissionOrOwner = UserOrganizationsPermission | typeof OWNER_ONLY

type UIShortcut<T extends Shortcut> = Omit<T, 'type' | 'label'> & {
  iconId: IconId
  label: UILabel
  permission: OrgPermissionOrOwner
}

function resolveLabel(label: UILabel, t: TranslationLookup): string {
  if (typeof label === 'string') {
    return t(label)
  } else {
    return label.value
  }
}

const ReactGroupContext = React.createContext<Group | undefined>(undefined)
const Group: FCC<{ label: Group }> = ({ label, children }) => {
  return <ReactGroupContext.Provider value={label}>{children}</ReactGroupContext.Provider>
}

function useRegisterShortcut(shortcut: Shortcut, permission: OrgPermissionOrOwner): void {
  const dispatch = useShortcutMenuDispatch()
  const contextGroup = useContext(ReactGroupContext)
  const isOwner = useSelector(selectIsOwnerUser)
  const orgPermissions = useOrganizationPermissions()

  useEffect(() => {
    if (permission === OWNER_ONLY && !isOwner) return
    if (permission !== OWNER_ONLY && !orgPermissions.has(permission)) return

    const shortcutWithGroup: Shortcut = { ...shortcut }
    const group = shortcut.group ?? contextGroup
    if (group !== undefined) shortcutWithGroup.group = group

    const label = shortcutWithGroup.label
    void dispatch({ type: 'register-shortcut', shortcut: shortcutWithGroup })
    return () => {
      void dispatch({ type: 'unregister-shortcut', label })
    }
  }, [dispatch, shortcut, contextGroup, isOwner, orgPermissions, permission])
}

const Link: React.FC<UIShortcut<LinkShortcut>> = ({
  label: unstableLabel,
  iconId,
  disabled,
  href,
  group,
  permission,
}) => {
  const { t } = useTranslation()
  const label = resolveLabel(unstableLabel, t)
  const shortcut = useMemo(
    (): LinkShortcut => ({ type: 'link', label, iconId, disabled, href, group }),
    [label, iconId, disabled, href, group]
  )
  useRegisterShortcut(shortcut, permission)

  return null
}

const Action: React.FC<UIShortcut<ActionShortcut>> = ({
  label: unstableLabel,
  iconId,
  disabled,
  run: unstableRun,
  group,
  permission,
}) => {
  const { t } = useTranslation()
  const label = resolveLabel(unstableLabel, t)
  const run = useStableFunction(unstableRun)
  const shortcut = useMemo(
    (): ActionShortcut => ({ type: 'action', label, iconId, disabled, run, group }),
    [label, iconId, disabled, run, group]
  )
  useRegisterShortcut(shortcut, permission)

  return null
}

const Toggle: React.FC<UIShortcut<ToggleShortcut>> = ({
  label: unstableLabel,
  iconId,
  disabled,
  isOn: unstableIsOn,
  toggle: unstableToggle,
  group,
  permission,
}) => {
  const { t } = useTranslation()
  const label = resolveLabel(unstableLabel, t)
  const isOn = useStableFunction(unstableIsOn)
  const toggle = useStableFunction(unstableToggle)
  const shortcut = useMemo(
    (): ToggleShortcut => ({ type: 'toggle', label, iconId, disabled, isOn, toggle, group }),
    [label, iconId, disabled, isOn, toggle, group]
  )
  useRegisterShortcut(shortcut, permission)

  return null
}

const NestedComponent: React.FC<
  UIShortcut<ComponentShortcut> & { Component: React.ReactNode | React.FC }
> = ({ label: unstableLabel, iconId, disabled, Component, group, permission }) => {
  const { t } = useTranslation()
  const label = resolveLabel(unstableLabel, t)
  const shortcut = useMemo(
    (): ComponentShortcut => ({ type: 'component', label, iconId, disabled, group }),
    [label, iconId, disabled, group]
  )

  const dispatch = useShortcutMenuDispatch()
  useEffect(() => {
    void dispatch({ type: 'update-component', label, Component })
  }, [Component, dispatch, label])

  useRegisterShortcut(shortcut, permission)

  return null
}

function indexItem<T extends Record<string, unknown>>(item: T): { search: string; item: T } {
  const search = Object.values(item)
    .map(value => JSON.stringify(value))
    .join(',')
  return { search, item }
}

export type ShortcutMenuSettingsItem = { id: string; label: string; iconId?: IconId }
const SettingsComponent: React.FC<{
  items: ShortcutMenuSettingsItem[]
  currentItemIds: string[]
  onItemSelected: (item: ShortcutMenuSettingsItem) => void
}> = ({ items, currentItemIds, onItemSelected }) => {
  const { t } = useTranslation()
  const [query, setQuery] = useState('')
  const [index, setIndex] = useState(0)

  const searchItems = useMemo(() => {
    if (query.length === 0) return items
    const indexedItems = items.map(indexItem)
    return fuzzysort.go(query, indexedItems, { key: 'search' }).map(({ obj }) => obj.item)
  }, [query, items])

  return (
    <>
      <SearchBarContainer>
        <SearchBar
          value={query}
          placeholder={t('shortcut-menu.default.dropdown-prompt')}
          onChange={text => {
            setQuery(text)
            setIndex(0)
          }}
          onIndexChanged={value =>
            setIndex(prev => resolveIndex(value === 'decrement' ? prev - 1 : prev + 1, searchItems))
          }
          onResultSelected={() => {
            const item = searchItems[index]
            if (item) onItemSelected(item)
          }}
        />
      </SearchBarContainer>
      <SearchResults>
        {searchItems.map((item, itemIndex) => (
          <SearchResult
            key={item.id}
            iconId={currentItemIds.includes(item.id) ? 'checkmark' : item.iconId}
            $selected={index === itemIndex}
            onClick={() => {
              onItemSelected(item)
            }}
          >
            {item.label}
          </SearchResult>
        ))}
      </SearchResults>
    </>
  )
}

const Settings: React.FC<{
  label: UILabel
  iconId?: IconId
  items: ShortcutMenuSettingsItem[]
  onItemSelected: (item: ShortcutMenuSettingsItem) => void
  currentItemIds?: string[]
  group?: Group
  permission: OrgPermissionOrOwner
}> = ({
  label,
  iconId = 'settings',
  items: unstableItems,
  currentItemIds: unstableCurrentItemIds = [],
  onItemSelected: unstableOnItemSelected,
  group,
  permission,
}) => {
  const items = useDeepEqualityMemo(unstableItems)
  const currentItemIds = useDeepEqualityMemo(unstableCurrentItemIds)
  const onItemSelected = useStableFunction(unstableOnItemSelected)

  return (
    <NestedComponent
      label={label}
      permission={permission}
      iconId={iconId}
      Component={
        <SettingsComponent items={items} currentItemIds={currentItemIds} onItemSelected={onItemSelected} />
      }
      group={group}
    />
  )
}

export const ShortcutMenu = {
  Link,
  Action,
  Toggle,
  NestedComponent,
  Group,
  Settings,
  Root: RootShortcutMenu,
} as const

import { motion } from 'framer-motion'
import { useAtom, useAtomValue } from 'jotai'
import _ from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDragLayer } from 'react-dnd'
import { getRootFolderOfTeamspace } from 'sierra-client/api/hooks/use-teamspace'
import { useContentDrag } from 'sierra-client/components/common/dnd/use-content-table-drag'
import { Link, LinkAction } from 'sierra-client/components/common/link'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { getAssetContextFromEditableContent } from 'sierra-client/components/util/asset-contex'
import {
  AnimateHideWithoutEllipsis,
  collapsableSidebarStateAtom,
  titleVariants,
} from 'sierra-client/features/collapsable-sidebar'
import { NestedItemList } from 'sierra-client/features/collapsable-sidebar/items/shared/nested-item'
import {
  LEFTPADDING_MAIN_ITEM,
  LEFTPADDING_SUB_ITEM,
} from 'sierra-client/features/global-sidebar/components/constants'
import { HoverCSS, SelectedCSS } from 'sierra-client/features/global-sidebar/components/sidebar-item'
import {
  TeamspaceIcon,
  extractCourseGroupContent,
  getRoleIsAtLeastEditorAndCommenter,
  useTeamspaceContentDrop,
} from 'sierra-client/features/teamspace'
import { teamspaceContentClickedLogger } from 'sierra-client/features/teamspace/logger'
import { usePathname } from 'sierra-client/hooks/router/use-pathname'
import { useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { useRouterEditorIds } from 'sierra-client/hooks/use-router-ids'
import { useScrollToView } from 'sierra-client/hooks/use-scroll-to-view'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { atomWithStorage } from 'sierra-client/state/storage'
import { FCC } from 'sierra-client/types'
import { useHasManageAccess } from 'sierra-client/views/manage/permissions/use-has-manage-access'
import { isCollaboratorRoleAboveOrEqual } from 'sierra-client/views/workspace/utils/collaborator-role-utils'
import { Linkable, continueUrl } from 'sierra-client/views/workspace/utils/urls'
import { editUrl } from 'sierra-domain/api/editable-content'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import type {
  FolderRow,
  Teamspace,
  TeamspaceContent,
  TeamspaceEditableContent,
  TeamspaceNonEditorCourse,
} from 'sierra-domain/api/teamspace'
import { isDefined } from 'sierra-domain/utils'
import { resolveTokenOrColor } from 'sierra-ui/color/token-or-color'
import { Icon, Tooltip, TruncatedTextWithTooltip } from 'sierra-ui/components'
import { InternalTruncatedText, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { ConditionalWrapper } from 'sierra-ui/utils'
import { useGenerateDomId } from 'sierra-ui/utils/use-generate-dom-id'
import styled, { css } from 'styled-components'

function useExpanded(
  key: string
): [expanded: boolean, setExpanded: React.Dispatch<React.SetStateAction<boolean>>] {
  const expandedAtom = useMemo(() => atomWithStorage(key, true), [key])
  return useAtom(expandedAtom)
}

const imageMarginRight = '12px'
const imageWidth = '16px'

const InlineImage = styled.img`
  flex-shrink: 0;
  height: 12px;
  width: ${imageWidth};
  margin-right: ${imageMarginRight};
  object-fit: cover;
  border-radius: 3px;
  position: relative;
  outline: 1px solid rgba(0, 0, 0, 0.05);
  outline-offset: -1px;
  user-select: none;
`

const InlineFolderIcon = styled.div`
  flex-shrink: 0;
  height: 18px;
  width: ${imageWidth};
  margin-right: ${imageMarginRight};
  object-fit: cover;
  border-radius: 6px;
  position: relative;
  outline-offset: -1px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ContentLI = styled.li<{ isDragging?: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 1px;

  ${p =>
    p.isDragging === true &&
    css`
      pointer-events: none;
    `}
`

const _ContentLink = styled(Link).attrs({ bold: false })<{ selected: boolean; $canDrop?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 36px;
  width: 100%;
  padding: 0 12px 0 ${LEFTPADDING_SUB_ITEM};

  border-radius: ${p => p.theme.borderRadius['size-10']};
  transition: background-color 200ms cubic-bezier(0.25, 0.1, 0.25, 1);

  ${p =>
    p.$canDrop === true &&
    css`
      outline: 2px solid ${p.theme.color.blueVivid};
      outline-offset: -2px;
    `}
  ${Text} {
    opacity: 0.8;
    transition:
      opacity 100ms cubic-bezier(0.25, 0.1, 0.25, 1),
      color 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  ${SelectedCSS}
  ${HoverCSS}
`

const ContentLink: FCC<{
  isSelected: boolean
  contentId: string
  href: string
  canDrop?: boolean
}> = ({ children, href, isSelected, canDrop }) => {
  const action: LinkAction = { href }

  return (
    <_ContentLink next selected={isSelected} {...action} $canDrop={canDrop}>
      {children}
    </_ContentLink>
  )
}

const ExpandIcon = styled(Icon)`
  color: ${token('foreground/muted')};
  margin-left: auto;
  transition: color 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
`

export const ContentButton = styled.button`
  background: transparent;
  display: flex;
  align-items: center;
  border-radius: ${p => p.theme.borderRadius['size-6']};
  cursor: pointer;
  padding-top: 8px;
  padding-right: 10px;
  padding-bottom: 8px;
  padding-left: ${LEFTPADDING_MAIN_ITEM};
  margin-top: 4px;

  &:hover {
    ${ExpandIcon} {
      color: ${token('foreground/primary')};
    }
  }
`

const OneLineText = styled(Text).attrs({ bold: true })`
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  align-items: center;
  flex: 1;
`

const OpacityText = styled(Text).attrs({ bold: true, size: 'small' })`
  white-space: nowrap;
`

const ViewAllButton = styled.button`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 36px;
  width: 100%;
  padding: 0 12px 0 10px;
  margin: 2px 0;

  border-radius: ${p => p.theme.borderRadius['size-10']};
  background-color: transparent;
  transition: background-color 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
  cursor: pointer;

  color: ${token('foreground/secondary')};

  & ${OpacityText} {
    color: ${token('foreground/secondary')};
  }

  &:hover {
    color: ${token('foreground/primary')};
    background-color: ${token('surface/soft')};

    & ${OpacityText} {
      color: ${token('foreground/primary')};
    }
  }
`

const ViewAllIcon = styled(Icon)``

const ContentContentItem: React.FC<{
  content: TeamspaceEditableContent | TeamspaceNonEditorCourse
}> = ({ content }) => {
  const editableContent = content.content
  const assetContext = useMemo(() => getAssetContextFromEditableContent(editableContent), [editableContent])
  const { drag, isDragging } = useContentDrag(editableContent, assetContext)
  const hasManageAccess = useHasManageAccess()

  const dispatch = useDispatch()
  const editorContentId = useRouterEditorIds()?.contentId
  const isCurrentPage = editableContent.id === editorContentId

  const { highestCollaboratorRole } = content.content

  const roleIsAtLeastCommenter =
    isDefined(highestCollaboratorRole) && isCollaboratorRoleAboveOrEqual(highestCollaboratorRole, 'commenter')

  const hasAtLeastCommentingAccessToNonEditorCourse = roleIsAtLeastCommenter && hasManageAccess
  const canAtLeastComment =
    content.type === 'non-editor-course'
      ? hasAtLeastCommentingAccessToNonEditorCourse
      : roleIsAtLeastCommenter

  const linkable: Linkable = { id: content.content.id, type: content.content.type }
  const link = canAtLeastComment ? editUrl(content.content.type, content.content.id) : continueUrl(linkable)

  const imageSrc = useResolveCourseAsset({ image: editableContent.image, assetContext })

  return (
    <ContentLI
      // TODO: `react-dnd` isn't maintained. Replace it with another library and remove this type assertion.
      ref={drag as (el: HTMLLIElement) => void}
      isDragging={isDragging}
      onClick={() => {
        if (editableContent.teamspaceId !== undefined) {
          void dispatch(
            teamspaceContentClickedLogger({
              contentId: content.content.id,
              teamspaceId: editableContent.teamspaceId,
              clickedFrom: 'sidebar',
            })
          )
        }
      }}
    >
      <ContentLink contentId={editableContent.id} isSelected={isCurrentPage} href={link}>
        <InlineImage src={imageSrc} />
        <motion.div variants={titleVariants}>
          <TruncatedTextWithTooltip color='foreground/secondary'>
            {editableContent.title}
          </TruncatedTextWithTooltip>
        </motion.div>
      </ContentLink>
    </ContentLI>
  )
}

const ContentFolderItem: React.FC<{
  content: FolderRow
  teamspace: Teamspace | undefined
}> = ({ teamspace, content }) => {
  const { t } = useTranslation()
  const nodeRef = useRef<HTMLLIElement | null>(null)
  const pathname = usePathname()

  const href = `/t/${content.teamspaceId}/${content.id}`
  const isCurrentPage = pathname.startsWith(href)

  const [assetContext] = useState({ type: 'unknown' as const })
  const { drag, isDragging } = useContentDrag(content, assetContext)
  const { isOver, canDrop, drop, confirmModal } = useTeamspaceContentDrop(teamspace, content)

  drop(drag(nodeRef))

  return (
    <ContentLI ref={nodeRef} isDragging={isDragging}>
      <ContentLink contentId={content.id} isSelected={isCurrentPage} href={href} canDrop={isOver && canDrop}>
        <InlineFolderIcon>
          <Icon iconId='folder' color='foreground/secondary' />
        </InlineFolderIcon>
        <TruncatedTextWithTooltip color='foreground/secondary'>
          {content.displayName}
        </TruncatedTextWithTooltip>
      </ContentLink>
      <ActionModal
        open={confirmModal.isOpen}
        title={confirmModal.title}
        onClose={confirmModal.close}
        primaryAction={confirmModal.primaryAction}
        primaryActionLabel={t('dictionary.continue')}
      >
        {confirmModal.body}
      </ActionModal>
    </ContentLI>
  )
}

const ContentItem: React.FC<{
  content: TeamspaceContent
  teamspace: Teamspace | undefined
}> = ({ content, teamspace }) => {
  switch (content.type) {
    case 'folder':
      return <ContentFolderItem teamspace={teamspace} content={content} />

    case 'editable':
      return <ContentContentItem content={content} />

    case 'non-editor-course':
      return <ContentContentItem content={content} />
  }
}

const FoldingSection: React.FC<{
  id: string
  expanded: boolean
  content: TeamspaceContent[]
  teamspace?: Teamspace | undefined
}> = ({ id, expanded, content, teamspace }) => {
  const { t } = useTranslation()

  const [numberOfContent, setNumberOfContent] = useState(7)

  const takeNumber = content.length === numberOfContent + 1 ? numberOfContent + 1 : numberOfContent

  return (
    <NestedItemList id={id} open={expanded}>
      {_.take(content, takeNumber).map(content => (
        <ContentItem
          teamspace={teamspace}
          content={content}
          key={content.type === 'folder' ? content.id : content.content.id}
        />
      ))}

      {content.length > numberOfContent + 1 && (
        <ViewAllButton onClick={() => setNumberOfContent(numberOfContent + 10)}>
          <View gap='12' grow>
            <ViewAllIcon iconId='overflow-menu--horizontal' color='currentColor' />
            <OpacityText>{t('dictionary.view-more')}</OpacityText>
          </View>
        </ViewAllButton>
      )}
    </NestedItemList>
  )
}

const ExpandIconButton = styled(Icon)`
  color: ${token('foreground/muted')};
  transition: color 100ms cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    color: ${token('foreground/primary')};
    border-radius: 6px;
  }
`

const ContentWrapper = styled.div<{ selected: boolean; $canDrop: boolean; isDragging: boolean }>`
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: ${token('surface/default')};
  display: flex;
  align-items: center;
  border-radius: ${p => p.theme.borderRadius['size-10']};
  cursor: pointer;
  overflow: hidden;

  margin-top: 4px;

  ${p =>
    p.$canDrop === true &&
    css`
      outline: 2px solid ${p.theme.color.blueVivid};
      border-radius: 10px;
    `}
  ${p =>
    p.selected &&
    css`
      background-color: ${resolveTokenOrColor('org/primary', p.theme).opacity(0.08)};
    `}
    &:hover {
    ${p =>
      !p.selected &&
      !p.isDragging &&
      css`
        background-color: ${resolveTokenOrColor('org/primary', p.theme).opacity(0.08)};
      `}
  }

  & ${OneLineText} {
    color: ${token('foreground/secondary')};
    ${p =>
      p.selected &&
      css`
        color: ${token('foreground/primary')};
      `}
  }
`

const ExpandClickArea = styled(View)`
  justify-content: flex-end;

  &:hover {
    ${ExpandIconButton} {
      color: ${token('foreground/primary')};
    }
  }
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  padding: 8px;
  height: 36px;
`

const HideOverflowLink = React.forwardRef<HTMLAnchorElement, { href: string; children: React.ReactNode }>(
  ({ href, children }, ref) => {
    return (
      <StyledLink next={true} href={href} ref={ref}>
        {children}
      </StyledLink>
    )
  }
)

const CategoryView = styled(View).attrs({ direction: 'column', gap: 'none' })``

type ContentProps = {
  title: string
  titleSelected: boolean
  content: TeamspaceContent[]
  viewAllHref: string
  expandKey: string
  teamspace: Teamspace
  isOver?: boolean
}

const ContentList = React.forwardRef<HTMLDivElement, ContentProps>(
  ({ title, titleSelected, content, viewAllHref, expandKey, isOver, teamspace }, ref) => {
    const [expanded, setExpanded] = useExpanded(expandKey)
    const hasManageAccess = useHasManageAccess()

    const { t } = useTranslation()
    const { isDragging } = useDragLayer(monitor => ({
      isDragging: monitor.isDragging(),
    }))

    const contentToShow = useMemo(() => {
      const { topLevelContent, courseGroupContent } = extractCourseGroupContent(content)
      return topLevelContent.map(c => {
        // if it is folder, just show it
        if (c.type === 'folder') {
          return c
        }

        const { roleIsAtLeastCommenter, roleIsAtLeastEditor } = getRoleIsAtLeastEditorAndCommenter({
          highestCollaboratorRole: c.content.highestCollaboratorRole,
          hasManageAccess,
          isEditableContent: c.type === 'editable',
        })

        // If you can edit it, show it as it is
        if (roleIsAtLeastCommenter || roleIsAtLeastEditor) {
          return c
        }

        // course-groups are not editable so anything that is editable should just be shown as it is
        if (c.type === 'editable') {
          return c
        }

        // Logic below to find the content inside a course-group that is the active one
        const activeEdition = c.activeUserEditionWithStatus
        const editionToShow: TeamspaceEditableContent | TeamspaceNonEditorCourse | undefined =
          courseGroupContent[c.content.id]?.find(edition => edition.content.id === activeEdition?.id)

        return editionToShow ?? c
      })
    }, [content, hasManageAccess])

    const sidebarState = useAtomValue(collapsableSidebarStateAtom)

    useEffect(() => {
      if (sidebarState === 'collapsed') {
        setExpanded(false)
      }
    }, [sidebarState, setExpanded])

    const containerRef = useRef<HTMLDivElement | null>(null)
    useScrollToView(containerRef, { shouldScroll: titleSelected })

    const foldingSectionDomId = useGenerateDomId()

    return (
      <>
        <CategoryView ref={containerRef}>
          <ContentWrapper
            ref={ref}
            selected={titleSelected}
            $canDrop={isOver ?? false}
            isDragging={isDragging}
          >
            <HideOverflowLink href={viewAllHref}>
              <TeamspaceIcon displayName={teamspace.displayName} themeName={teamspace.iconTheme} small />
              <ConditionalWrapper
                condition={!isDragging}
                renderWrapper={c => <Tooltip title={t('teamspaces.view-teamspace')}>{c}</Tooltip>}
              >
                <AnimateHideWithoutEllipsis $minWidth={125}>
                  <InternalTruncatedText
                    lines={1}
                    color={titleSelected ? 'foreground/primary' : 'foreground/secondary'}
                  >
                    {title}
                  </InternalTruncatedText>
                </AnimateHideWithoutEllipsis>
              </ConditionalWrapper>
            </HideOverflowLink>

            <ExpandClickArea
              role='button'
              padding='8 10'
              grow
              onClick={() => setExpanded(previous => !previous)}
              justifyContent='flex-end'
              aria-expanded={expanded}
              aria-controls={foldingSectionDomId}
              aria-label={t('teamspace.view-content-in', { name: teamspace.displayName })}
            >
              <ExpandIconButton
                iconId={expanded ? 'chevron--up--small' : 'chevron--down--small'}
                color='foreground/muted'
              />
            </ExpandClickArea>
          </ContentWrapper>

          <FoldingSection
            id={foldingSectionDomId}
            teamspace={teamspace}
            expanded={expanded}
            content={contentToShow}
          />
        </CategoryView>
      </>
    )
  }
)

const getTimestampFromContent = (content: TeamspaceContent): Date | undefined => {
  switch (content.type) {
    case 'editable': {
      return content.content.timestamp
    }
    case 'folder': {
      return content.updatedAt
    }
  }
}

const DroppableContent: React.FC<ContentProps & { teamspace: Teamspace | undefined }> = ({
  title,
  titleSelected,
  content,
  viewAllHref,
  expandKey,
  teamspace,
}) => {
  const { t } = useTranslation()
  const nodeRef = useRef<HTMLDivElement | null>(null)

  const rootFolder = teamspace.content.find(
    (it): it is FolderRow => it.type === 'folder' && it.parentFolderId === undefined
  )

  const { isOver, canDrop, drop, confirmModal } = useTeamspaceContentDrop(teamspace, rootFolder)
  drop(nodeRef)

  const recentlyEditedSortedContent = useMemo(
    () => _.reverse(_.sortBy(content, getTimestampFromContent)),
    [content]
  )

  return (
    <>
      <ContentList
        ref={nodeRef}
        title={title}
        titleSelected={titleSelected}
        content={recentlyEditedSortedContent}
        viewAllHref={viewAllHref}
        expandKey={expandKey}
        isOver={isOver && canDrop}
        teamspace={teamspace}
      />
      <ActionModal
        open={confirmModal.isOpen}
        title={confirmModal.title}
        onClose={confirmModal.close}
        primaryAction={confirmModal.primaryAction}
        primaryActionLabel={t('dictionary.continue')}
      >
        {confirmModal.body}
      </ActionModal>
    </>
  )
}

const getTeamspaceContentOnRootLevel = (
  teamspace: Teamspace,
  rootFolderId: NanoId12 | undefined
): TeamspaceContent[] => {
  if (rootFolderId !== undefined) {
    const content = teamspace.content.filter(it => it.parentFolderId === rootFolderId)
    return content
  }

  const content: TeamspaceContent[] = teamspace.content.filter(
    (it): it is TeamspaceEditableContent => it.type === 'editable'
  )

  return content
}

export const Teamspaces: React.FC<{ teamspaces: Teamspace[] }> = ({ teamspaces }) => {
  const pathname = usePathname()

  return (
    <>
      {teamspaces.map(it => {
        const href = `/t/${it.id}`
        const selected = pathname === href
        const rootFolder = getRootFolderOfTeamspace(it)
        const content = getTeamspaceContentOnRootLevel(it, rootFolder?.id)

        return (
          <DroppableContent
            teamspace={it}
            key={it.id}
            title={it.displayName}
            titleSelected={selected}
            content={content}
            viewAllHref={`/t/${it.id}`}
            expandKey={`global-sidebar/docs/teamspace/${it.id}`}
          />
        )
      })}
    </>
  )
}

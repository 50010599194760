import { DraggableProvidedDragHandleProps } from '@hello-pangea/dnd'
import _ from 'lodash'
import { default as React, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { IconMenu } from 'sierra-client/components/common/icon-menu'
import { domNodePath } from 'sierra-client/editor/debug/slate-debug-mode'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FCC } from 'sierra-client/types'
import { insertNodeAfterNodeWithId, removeNodeWithId } from 'sierra-client/views/v3-author/command'
import { isCardElement, isElementType } from 'sierra-client/views/v3-author/queries'
import { useRenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { addBlockAfterCurrentBlock } from 'sierra-client/views/v3-author/slash-menu/domain'
import { replaceIdsInNodes } from 'sierra-domain/collaboration/slate-document-map'
import { ColorBuilder, color } from 'sierra-ui/color'
import { MenuItem } from 'sierra-ui/components'
import { IconButton } from 'sierra-ui/primitives'
import { Element } from 'slate'
import { ReactEditor, useSlateStatic } from 'slate-react'
import { css, default as styled, useTheme } from 'styled-components'

const ActionsMenu = styled.span.attrs({
  gap: '4',
  contentEditable: false,
})<{
  $vertical: 'top' | 'middle'
  $position?: 'outside' | 'inside'
  $visible: boolean
}>`
  user-select: none;
  display: flex;
  position: absolute;
  top: ${p => (p.$vertical === 'top' ? 0 : '50%')};
  transform: translateY(${p => (p.$vertical === 'middle' ? '-50%' : '0')});
  grid-column: unset;

  /*
    * Positioning outside overrides the horizontal logic above.
    * Supports 'title-card-heading' in 'title-card' and similar.
    */
  ${p =>
    p.$position === 'outside' &&
    css`
      left: -3rem;
    `}

  transition: opacity 150ms;
  opacity: ${p => (p.$visible ? 1 : 0)};

  /* Removing padding to right helps prevent the menu from interfering with the click-area
     of the element beneath it in the editor */
  padding-right: 0;
`

type BlockMenuInnerProps = {
  element: Element
  alignment?: 'top' | 'middle'
  draggable: boolean
}

function useIconColor(): ColorBuilder {
  const theme = useTheme()
  return color(theme.home.textColor).opacity(0.5)
}

export const BlockDragIcon: React.FC<{
  element: Element
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined
}> = ({ element, dragHandleProps }) => {
  const { t } = useTranslation()
  const editor = useSlateStatic()
  const { id: blockId } = element
  const iconButtonColor = useIconColor()
  const menuItems = useMemo<MenuItem[]>(
    () => [
      {
        type: 'label',
        id: 'duplicate',
        label: t('dictionary.duplicate'),
        icon: 'duplicate',
        onClick: () => {
          const duplicateBlock = replaceIdsInNodes([_.cloneDeep(element)])[0]
          if (duplicateBlock !== undefined && Element.isElement(duplicateBlock))
            insertNodeAfterNodeWithId(editor, blockId, duplicateBlock)
        },
      },
      {
        type: 'label',
        id: 'delete',
        label: t('admin.delete'),
        icon: 'trash-can',
        color: 'destructive/background',
        onClick: () => removeNodeWithId(editor, blockId),
      },
    ],
    [blockId, editor, element, t]
  )

  return (
    <IconMenu
      iconId='draggable'
      size='small'
      color={iconButtonColor}
      closeOnPick
      onClose={() => {
        ReactEditor.focus(editor)
      }}
      items={menuItems}
      {...dragHandleProps}
    />
  )
}

export const AddBlockIcon: React.FC<{ element: Element }> = ({ element }) => {
  const { t } = useTranslation()
  const editor = useSlateStatic()

  const handleAddClick = useCallback((): void => {
    editor.pushActionsLogEntry('add-block-icon-clicked')
    const path = ReactEditor.findPath(editor, element)
    addBlockAfterCurrentBlock({ path, editor })
  }, [editor, element])

  const iconButtonColor = useIconColor()
  return (
    <IconButton
      iconId='add'
      size='small'
      variant='transparent'
      color={iconButtonColor}
      onClick={handleAddClick}
      tooltip={t('author.slate.add-block')}
    />
  )
}

function getInnerBlock(anchor: HTMLElement, blockId: string): HTMLElement | undefined {
  const [firstChild] = Array.from(anchor.children)
  const dataBlockInner = _.last(anchor.querySelectorAll(`[data-block-inner="${blockId}"]`))
  return (dataBlockInner ?? firstChild) as HTMLElement | undefined
}

function calculateOffset(
  anchor: HTMLElement,
  innerBlock: HTMLElement,
  menuWidth: number
): number | undefined {
  const editorLeftEdge =
    Array.from(domNodePath(anchor))
      .find(node => 'data-slate-editor' in node.attributes)
      ?.getBoundingClientRect().left ?? 0

  // All our slate elements have two bounding boxes: one for the container and one for the content.
  // We want the BlockMenu to be close to the inner of these boxes, but not overlapping it
  // It is important that the BlockMenu's left edge is never outside the editor
  const outerRect = anchor.getBoundingClientRect()
  const innerRect = innerBlock.getBoundingClientRect()
  const outerElementEdge = Math.min(outerRect.left, innerRect.left)
  const innerElementEdge = Math.max(outerRect.left, innerRect.left)
  const initialMenuLeftEdge = innerElementEdge - menuWidth
  const leftMenuEdge = initialMenuLeftEdge < editorLeftEdge ? editorLeftEdge : initialMenuLeftEdge
  const offset = leftMenuEdge - outerElementEdge

  return offset
}

const BlockMenuInner: FCC<BlockMenuInnerProps> = ({ children, element, alignment = 'top' }) => {
  const editor = useSlateStatic()
  const { id: blockId } = element
  const renderingContext = useRenderingContext()

  const [anchor, setAnchor] = useState<HTMLElement>()
  const menuRef = useRef<HTMLDivElement | null>(null)
  const [hovered, { on: hoverOn, off: hoverOff }] = useToggle()
  const [focused, { on: focusOn, off: focusOff }] = useToggle()

  useEffect(() => {
    try {
      const anchor = ReactEditor.toDOMNode(editor, element)
      anchor.addEventListener('mouseover', hoverOn)
      anchor.addEventListener('mouseout', hoverOff)

      anchor.addEventListener('focusin', focusOn)
      anchor.addEventListener('focusout', focusOff)

      setAnchor(anchor)
      return () => {
        anchor.removeEventListener('mouseover', hoverOn)
        anchor.removeEventListener('mouseover', hoverOff)

        anchor.removeEventListener('focusin', hoverOn)
        anchor.removeEventListener('focusout', hoverOff)
      }
    } catch (e) {
      console.debug(`Unable to find slate node for element ${element.id}`)
    }
  }, [hoverOn, hoverOff, setAnchor, editor, element, focusOn, focusOff])

  const menuWidth = menuRef.current?.getBoundingClientRect().width ?? 0
  const innerBlock = anchor ? getInnerBlock(anchor, blockId) : undefined
  const offset = anchor && innerBlock ? calculateOffset(anchor, innerBlock, menuWidth) : undefined

  return (
    <ActionsMenu
      $vertical={alignment}
      $position={renderingContext.actionsPositioning}
      $visible={hovered || focused}
      ref={menuRef}
      // eslint-disable-next-line react/forbid-component-props
      style={{ left: offset }}
    >
      {children}
      {/* Slate requires an empty node here */}
      <span />
    </ActionsMenu>
  )
}

export const BlockMenu: FCC<
  {
    // TODO: we should try to remove these props
    inline: boolean
    disabled: boolean
  } & BlockMenuInnerProps
> = ({ children, ...props }) => {
  const { element, disabled, draggable, inline } = props

  const isCard = isCardElement(element)
  const isDraggableQuestionCard = isElementType('question-card', element) && draggable
  const shouldNotBeWrapped = isCard && !isDraggableQuestionCard

  const hasChildren = React.Children.toArray(children).some(it => Boolean(it))

  if (!hasChildren) return null
  if (shouldNotBeWrapped) return null
  if (disabled === true) return null
  if (inline) return null

  return <BlockMenuInner {...props}>{children}</BlockMenuInner>
}

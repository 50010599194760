import { UseQueryResult } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { CachedQueryOptions, useCachedQuery } from 'sierra-client/state/api'
import { newHomeActivityOverride } from 'sierra-client/views/learner/home/atoms'
import { QueryCourseLearnerUnionResponse } from 'sierra-domain/api/content-v2'
import { HomeContentResponse } from 'sierra-domain/api/learn'
import { XRealtimeContentHome, XRealtimeContentQueryCourse } from 'sierra-domain/routes'

export function useIsHomePageActivityEnabled(): boolean {
  const flag = useFlag('home-page-activity')
  const override = useAtomValue(newHomeActivityOverride)
  if (override === null) return flag
  else return override
}

export const useHomeContent = <TSelectData = HomeContentResponse>(
  options: CachedQueryOptions<TSelectData> = {}
): UseQueryResult<TSelectData> => {
  const isHomePageActivityEnabled = useIsHomePageActivityEnabled()
  return useCachedQuery(
    XRealtimeContentHome,
    { includeNotifications: isHomePageActivityEnabled },
    {
      retry: false,
      ...options,
    }
  )
}

export const useLiveContentMutation = <TSelectData = QueryCourseLearnerUnionResponse>(
  courseId: string,
  options: CachedQueryOptions<TSelectData> = {}
): UseQueryResult<TSelectData, unknown> => {
  return useCachedQuery(
    XRealtimeContentQueryCourse,
    { courseId },
    {
      retry: false,
      ...options,
    }
  )
}

import { color, deriveTheme } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

const RadioCheckboxWrapper = styled.div.attrs({ contentEditable: false })<{
  $checked: boolean
  radio: boolean
}>`
  grid-area: checkbox;
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  min-height: 1rem;
  max-width: 1rem;
  max-height: 1rem;
  border-width: 1px;
  border-style: solid;
  border-radius: ${p => (p.radio === true ? '100%' : '2px')};
  background-color: transparent;

  border-color: ${p =>
    p.$checked
      ? color(deriveTheme(p.theme).secondaryTextColor).opacity(0.15).toString()
      : color(deriveTheme(p.theme).secondaryTextColor).opacity(0.15).toString()};
  color: ${p => (p.$checked ? palette.primitives.black : deriveTheme(p.theme).textColor)};
`

export const RadioCheckbox: React.FC<{
  checked: boolean
  radio: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}> = ({ checked, radio, onClick }) => {
  return (
    <RadioCheckboxWrapper $checked={checked} radio={radio} onClick={onClick}>
      {checked && <Icon iconId={'checkmark'} size={'size-12'} />}

      <span />
    </RadioCheckboxWrapper>
  )
}

import React, { useRef } from 'react'
import { Emoji, useEmojiPicker } from 'sierra-client/components/common/emoji'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Message } from 'sierra-domain/chat'
import { Icon, Tooltip } from 'sierra-ui/components'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const SeparatorBar = styled.div`
  background-color: ${token('border/default')};
  width: 1px;
  height: 16px;
  margin-left: 4px;
  margin-right: 4px;
`

const MessageMenu = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  border: 1px solid ${token('border/default')};
  background-color: ${token('surface/default')};
  top: -30px;
  left: 8px;
  z-index: 1;
  padding-right: 4px;
  padding-left: 4px;

  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
`

const MenuButton = styled.button`
  display: flex;
  align-items: center;
  padding: 8px;
  height: 34px;
  color: ${token('foreground/primary')};
  background-color: ${token('surface/default')};

  &:hover {
    background-color: ${token('surface/soft')};
    cursor: pointer;
  }
`

export const MessageActionMenu: React.FC<{
  onReply?: () => void
  onOpenEditMode?: () => void
  visible: boolean
  onReaction?: (reaction: string) => void
  message: Message
}> = ({ onReply, onReaction, onOpenEditMode, visible }) => {
  const { t } = useTranslation()

  const { open, isOpen, close, currentAnchor } = useEmojiPicker()
  const emojiButtonRef = useRef<HTMLButtonElement | null>(null)

  if (!onReply && !onReaction) return null
  if (!(isOpen && emojiButtonRef.current === currentAnchor) && !visible) return null

  const onEmojiSelected = (emoji: string): void => {
    close()
    onReaction?.(emoji)
  }

  const toggleEmojiPicker = (): void => {
    if (isOpen) {
      close()
    } else if (emojiButtonRef.current) {
      open(emojiButtonRef.current, onEmojiSelected)
    }
  }

  return (
    <MessageMenu>
      {onReaction && (
        <>
          <MenuButton onClick={() => onReaction('👍')}>
            <Emoji size={14} emojiUnicode='👍'></Emoji>
          </MenuButton>
          <MenuButton onClick={() => onReaction('🎉')}>
            <Emoji size={14} emojiUnicode='🎉'></Emoji>
          </MenuButton>
          <MenuButton onClick={() => onReaction('✅')}>
            <Emoji size={14} emojiUnicode='✅'></Emoji>
          </MenuButton>
          <Tooltip title={t('dictionary.react')}>
            <MenuButton ref={emojiButtonRef} onClick={toggleEmojiPicker}>
              <Icon size='size-16' iconId='face--add' color='currentColor' />
            </MenuButton>
          </Tooltip>
          {(onReply || onOpenEditMode) && <SeparatorBar />}
        </>
      )}

      {onReply && (
        <Tooltip title={t('dictionary.reply')}>
          <MenuButton onClick={onReply}>
            <Icon size='size-16' iconId='chat' color='currentColor' />
          </MenuButton>
        </Tooltip>
      )}

      {onOpenEditMode && (
        <Tooltip title={t('dictionary.edit')}>
          <MenuButton onClick={onOpenEditMode}>
            <Icon size='size-16' iconId='edit' color='currentColor' />
          </MenuButton>
        </Tooltip>
      )}
    </MessageMenu>
  )
}

import { FC, useMemo } from 'react'
import { LiveContentAssignmentType } from 'sierra-client/components/common/modals/multi-assign-modal/types'
import { GridArea } from 'sierra-client/features/program/components/grid'
import { StepNumber } from 'sierra-client/features/program/components/step-number'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CourseTitleAndImage } from 'sierra-client/lib/tabular/components/cells'
import { bareContentId } from 'sierra-client/views/manage/content/utils/content-utils'
import { useOutlineEdit } from 'sierra-client/views/manage/programs/staggered-assignments/hooks/use-outline-edit'
import {
  getLiveContentAssignmentStateFromStep,
  getLiveContentAssignmentTranslation,
  getStepTypeTranslation,
  isContentProgramStep,
  isStepLiveCourseOrPath,
  renderDueDate,
  renderSchedule,
  renderScheduleDescriptionWithPreviousCompletion,
} from 'sierra-client/views/manage/programs/staggered-assignments/utils'
import { manageUrl } from 'sierra-client/views/workspace/utils/urls'
import { ContentProgramStep, ProgramOutline, ProgramStep } from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'
import { ProgramId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { assertNever } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const LiveSessionAssignmentDisplay: React.FC<{ step: ContentProgramStep }> = ({ step }) => {
  const { t } = useTranslation()
  const isHidden = !isStepLiveCourseOrPath(step)
  const assignmentState: LiveContentAssignmentType = getLiveContentAssignmentStateFromStep(step)
  const label = getLiveContentAssignmentTranslation(assignmentState, t)

  if (isHidden) {
    return null
  }

  return (
    <View direction='row' gap='2'>
      <Icon iconId='elbow--arrow' color='foreground/muted' />
      <Text color='foreground/secondary'>{label}</Text>
    </View>
  )
}

function getAssetContext(step: ProgramStep, programId: ProgramId): AssetContext {
  switch (step.type) {
    case 'course':
      return { type: 'course', courseId: CourseId.parse(bareContentId(step.courseId)) }
    case 'path':
      return { type: 'path', pathId: step.pathId }
    case 'email':
      return { type: 'program', programId }
    default:
      assertNever(step)
  }
}

const Grid = styled.div<{ $withBorder: boolean }>`
  display: grid;
  grid-template-columns: auto 2fr repeat(2, 1fr);
  grid-template-areas: 'number content schedule due-date';
  column-gap: 16px;
  align-items: center;
  padding-block: 16px;

  ${p =>
    p.$withBorder
      ? css`
          border-bottom: 1px solid ${token('border/strong')};
        `
      : css``};
`

type StepCardProps = {
  outline: ProgramOutline
  step: ProgramStep
  previousStep: ProgramStep | undefined
  withBorder: boolean
  programId: ProgramId
}

export const StepCard: FC<StepCardProps> = ({ outline, step, previousStep, withBorder, programId }) => {
  const { t } = useTranslation()
  const afterCompletionOptionEnabled = useFlag('program-after-previous-completion')

  const scheduleDescription = afterCompletionOptionEnabled
    ? renderScheduleDescriptionWithPreviousCompletion({ outline, index: step.index, t })
    : renderSchedule(step, previousStep, t).description

  const {
    setPanels: {
      editOutline: { on: openOutlineEdit },
      addEmailNotification: { on: openEmailEdit },
    },
  } = useOutlineEdit()

  const onStepTitleClick = useMemo(() => {
    switch (step.type) {
      case 'course':
        return { href: manageUrl({ type: step.courseKind, id: bareContentId(step.courseId) }) }
      case 'path':
        return { href: manageUrl({ type: 'path', id: bareContentId(step.pathId) }) }
      case 'email':
        return {
          onTitleClick: () => {
            openOutlineEdit()
            openEmailEdit(step.emailTemplateBindingsId)
          },
        }
      default:
        assertNever(step)
    }
  }, [openEmailEdit, openOutlineEdit, step])

  return (
    <Grid $withBorder={withBorder} role='listitem' aria-label={step.title}>
      <GridArea area='number'>
        <StepNumber outline={outline} index={step.index} />
      </GridArea>

      <GridArea area='content'>
        <CourseTitleAndImage
          image={step.image}
          title={step.title}
          subline={getStepTypeTranslation(step, t)}
          stepType={step.type}
          assetContext={getAssetContext(step, programId)}
          {...onStepTitleClick}
        />
      </GridArea>

      <GridArea area='schedule'>
        <View direction='column' gap='2'>
          <Text>{scheduleDescription}</Text>
          {isContentProgramStep(step) ? <LiveSessionAssignmentDisplay step={step} /> : null}
        </View>
      </GridArea>

      {isContentProgramStep(step) && (
        <GridArea area='due-date'>
          <Text color='foreground/secondary'>{renderDueDate(step.dueDate, t).description}</Text>
        </GridArea>
      )}
    </Grid>
  )
}

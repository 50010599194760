import { LayoutGroup, motion } from 'framer-motion'
import _ from 'lodash'
import React, { FC, useId, useMemo, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { DragItemTypes, MatchThePairsQuestionItem } from 'sierra-client/components/common/dnd/dnd-types'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSafeFileContext } from 'sierra-client/views/flexible-content/file-context'
import { useAncestorWithType } from 'sierra-client/views/v3-author/hooks'
import { assertElementType, isElementType } from 'sierra-client/views/v3-author/queries'
import { QuestionCardBodyWrapper } from 'sierra-client/views/v3-author/question-card/question-card-body-wrapper'
import { useQuestionCardData } from 'sierra-client/views/v3-author/question-card/question-card-data-layer'
import { RenderLeafInner } from 'sierra-client/views/v3-author/render-leaf'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { Entity } from 'sierra-domain/entity'
import { asNonNullable } from 'sierra-domain/utils'
import { QuestionCardMatchThePairsAlternativeOption } from 'sierra-domain/v3-author'
import { color } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { token, zIndex } from 'sierra-ui/theming'
import { Element } from 'slate'
import { RenderElementProps, useSelected } from 'slate-react'
import styled, { css } from 'styled-components'

export const QuestionCardMatchThePairsContainer = React.forwardRef<HTMLDivElement, SlateWrapperProps>(
  ({ children, attributes }, ref) => {
    const preview = !useSelected()

    return (
      <div {...attributes} ref={ref}>
        <RenderingContext
          withGrid={false}
          preventDrag={true}
          allowBlockComments={false}
          disableMenu={true}
          preview={preview}
        >
          {children}
        </RenderingContext>
      </div>
    )
  }
)

type OptionStatus = 'unsubmitted' | 'correct' | 'incorrect' | 'correct-response-hidden'

type Option = Entity<QuestionCardMatchThePairsAlternativeOption>
type OptionWithStatus = Option & { status: OptionStatus }

const ItemText: React.FC<{
  option: Option
  attributes: RenderElementProps['attributes']
  status: OptionStatus
}> = ({ option, status }) => (
  <Text size='regular' color={status === 'correct' ? 'black' : undefined}>
    {option.children.map((child, index) => (
      <RenderLeafInner
        key={index}
        leaf={child}
        text={child}
        attributes={{
          'data-slate-leaf': true,
        }}
      >
        {child.text}
      </RenderLeafInner>
    ))}
  </Text>
)

type ListItemContainerProps = {
  $position: 'left' | 'right'
  $isDragging: boolean
  $canDrag: boolean
  $status: OptionStatus
  $index: number
  $keepElevated: boolean
}

const ListItemContainer = styled(motion.div)<ListItemContainerProps>`
  padding: 24px;
  grid-column: ${p => (p.$position === 'left' ? 1 : 2)} / span 1;
  grid-row: ${p => p.$index + 1} / span 1;
  text-align: center;

  ${p => (p.$position === 'left' ? 'padding-right: 64px;' : 'padding-left: 64px;')};
  background-color: ${p => (p.$status === 'correct' ? color('white') : token('surface/soft'))};
  backdrop-filter: blur(33px);
  /** We don't want to select text while dragging */
  user-select: none;

  ${p =>
    p.$canDrag &&
    css`
      cursor: grab;

      &:hover {
        background-color: ${token('surface/soft').opacity(0.2)};
      }
    `}

  ${p =>
    p.$isDragging &&
    css`
      background-color: ${token('surface/soft').opacity(0.3)};
      z-index: ${zIndex.OVERLAY};
      pointer-events: none;
    `}

    ${p =>
    p.$keepElevated &&
    css`
      z-index: ${zIndex.OVERLAY};
    `}

  border-radius: ${p => (p.$position === 'left' ? '16px 0 0 16px' : '0 16px 16px 0')};
  display: flex;
  justify-content: center;

  transition: background-color 200ms ease;

  /** Cover gap for successful lanes */
  ${p =>
    p.$position === 'left' &&
    p.$status === 'correct' &&
    css`
      width: calc(100% + 2px);
    `}
`

const MiddleIconOuterContainer = styled.div<{ $index: number }>`
  grid-column: 1 / span 2;
  grid-row: ${p => p.$index + 1} / span 1;
  position: relative;
  pointer-events: none;
`

const MiddleIconContainer = styled.div<{ $status: OptionStatus }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  gap: 8px;

  height: 36px;
  padding: ${p => (p.$status === 'unsubmitted' ? '8px 10px' : '12px 14px')};
  border-radius: 18px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${p =>
    p.$status === 'correct' ? token('success/background') : token('surface/default')};
  z-index: 1;
`

const IconComponentForStatus: FC<{ status: OptionStatus }> = ({ status }) => {
  const { t } = useTranslation()

  switch (status) {
    case 'unsubmitted':
      return <Icon iconId='equals' size='size-16' />

    case 'correct':
      return (
        <>
          <Icon iconId='checkmark--filled' size='size-16' color='success/foreground' />
          <Text size='micro' bold color='success/foreground'>
            {t('dictionary.correct')}
          </Text>
        </>
      )
    case 'incorrect':
      return (
        <>
          <Icon iconId='close--circle--filled' size='size-16' />
          <Text size='micro' bold>
            {t('dictionary.incorrect')}
          </Text>
        </>
      )
    case 'correct-response-hidden':
      return <Icon iconId='equals' size='size-16' />
  }
}

const MiddleIcon: React.FC<{ status: OptionStatus; index: number }> = ({ index, status }) => {
  return (
    <MiddleIconOuterContainer $index={index}>
      <MiddleIconContainer $status={status}>
        <IconComponentForStatus status={status} />
      </MiddleIconContainer>
    </MiddleIconOuterContainer>
  )
}

const ListItem: SlateFC<{
  option: Option
  index: number
  listId: string
  onSwap?: (id1: string, id2: string) => void
  position: 'left' | 'right'
  status: OptionStatus
}> = ({ option, _attributes, index, listId, onSwap, position, status }) => {
  const canDrag = useQuestionCardData().questionCardData.evaluation === undefined
  const [keepElevated, setKeepElevated] = useState(false)

  const [{ isDragging }, drag] = useDrag<MatchThePairsQuestionItem, void, { isDragging: boolean }>({
    type: listId,
    canDrag,
    item: () => {
      return { type: DragItemTypes.MatchThePairsOption, id: option.id }
    },
    collect: monitor => {
      const res = {
        isDragging: monitor.isDragging(),
      }
      return res
    },
  })

  const [{ handlerId, isOver }, drop] = useDrop<
    MatchThePairsQuestionItem,
    void,
    { handlerId: string | symbol | null; isOver: boolean }
  >({
    accept: listId,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
      }
    },
    drop(item) {
      onSwap?.(item.id, option.id)
    },
  })

  const callbackRef = useStableFunction((el: HTMLDivElement) => {
    drag(drop(el))
  })

  return (
    <ListItemContainer
      ref={callbackRef}
      layout='position'
      $position={position}
      $isDragging={isDragging}
      $canDrag={canDrag}
      $status={status}
      $index={index}
      $keepElevated={keepElevated}
      data-handler-id={handlerId}
      contentEditable={false}
      initial={false}
      drag={canDrag}
      dragSnapToOrigin
      dragTransition={{
        min: 0,
        max: 0,
        bounceStiffness: 1000,
        bounceDamping: 100,
      }}
      dragElastic={0.1}
      onDragEnd={() => {
        setTimeout(() => {
          setKeepElevated(false)
        }, 500)
      }}
      onDragStart={() => {
        setKeepElevated(true)
      }}
      animate={{
        scale: isOver ? 1.1 : 1,
        originX: position === 'left' ? 1 : 0,
      }}
    >
      <ItemText option={option} attributes={_attributes} status={status} />
    </ListItemContainer>
  )
}

const swapArrayElements = <T,>(arr: T[], _i: number, _j: number): T[] => {
  if (_i === _j) return arr

  const i = Math.min(_i, _j)
  const j = Math.max(_i, _j)

  return [
    ...arr.slice(0, i),
    asNonNullable(arr[j]),
    ...arr.slice(i + 1, j),
    asNonNullable(arr[i]),
    ...arr.slice(j + 1),
  ]
}

const GridWrapper = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  row-gap: 16px;
  column-gap: 2px;
`

const useConfigurationData = (element: Element): { hideCorrectAnswers: boolean } => {
  const file = useSafeFileContext()?.file
  const fileData = file?.data
  const parent = useAncestorWithType({ nodeId: element.id, type: 'question-card' })
  const assessmentHideAnswerLabel =
    fileData !== undefined &&
    fileData.type === 'assessment-card' &&
    fileData.settings.hideCorrectAnswers === true

  const hideCorrectAnswers = (parent?.hideCorrectAnswers ?? false) || assessmentHideAnswerLabel

  return {
    hideCorrectAnswers,
  }
}

const QuestionCardMatchThePairsBodyLearner: SlateFC = props => {
  const { updateResponse, questionCardData } = useQuestionCardData()
  const leftListId = useId()
  const rightListId = useId()

  const { hideCorrectAnswers } = useConfigurationData(props.element)

  const state = useMemo(() => {
    if (questionCardData.response.type !== 'match-the-pairs') return { left: [], right: [] }

    const left: OptionWithStatus[] = []
    const right: OptionWithStatus[] = []
    const options: Record<string, Option> = {}

    // left id -> correct right id
    const correctAnswers: Record<string, string> = {}

    for (const alternative of props.element.children) {
      if (!isElementType('question-card-match-the-pairs-alternative', alternative)) continue
      const [l, r] = alternative.children
      options[l.id] = l
      options[r.id] = r
    }

    for (const { firstId, secondId, status: responseStatus } of questionCardData.response.pairs) {
      const leftOption = options[firstId]
      const rightOption = options[secondId]

      // The alternative may have been deleted after the user submitted a response.
      // We'll ignore those rows. We'll also ignore rows where just one part of the pair
      // is missing, although it shouldn't be possible to do that. It may happen during
      // normalization I suppose, or as part of the duplicate id correction.
      if (!leftOption || !rightOption) continue

      // If we configured the question card to hide the correct status we hide the correct and incorrect statuses
      const status =
        hideCorrectAnswers && (responseStatus === 'correct' || responseStatus === 'incorrect')
          ? 'correct-response-hidden'
          : responseStatus

      left.push({ ...leftOption, status })
      right.push({ ...rightOption, status })
    }

    return { left, right, correctAnswers }
  }, [hideCorrectAnswers, props.element.children, questionCardData.response])

  // callback to swap two items
  const swapLeft = (id1: string, id2: string): void => {
    const currLeft = state.left
    const i = currLeft.findIndex(option => option.id === id1)
    const j = currLeft.findIndex(option => option.id === id2)

    if (i === -1 || j === -1) return

    updateResponse({
      type: 'match-the-pairs',
      pairs: _.zip(swapArrayElements(state.left, i, j), state.right).map(([l, r]) => ({
        firstId: asNonNullable(l).id,
        secondId: asNonNullable(r).id,
        status: 'unsubmitted',
      })),
    })
  }

  const swapRight = (id1: string, id2: string): void => {
    const currRight = state.right
    const i = currRight.findIndex(option => option.id === id1)
    const j = currRight.findIndex(option => option.id === id2)

    if (i === -1 || j === -1) return

    updateResponse({
      type: 'match-the-pairs',
      pairs: _.zip(state.left, swapArrayElements(state.right, i, j)).map(([l, r]) => ({
        firstId: asNonNullable(l).id,
        secondId: asNonNullable(r).id,
        status: 'unsubmitted',
      })),
    })
  }

  return (
    <View direction='column' gap='16' contentEditable={false}>
      <GridWrapper contentEditable={false}>
        <LayoutGroup>
          {state.left.map((option, index) => (
            <React.Fragment key={option.id}>
              <ListItem
                {...props}
                option={option}
                position='left'
                index={index}
                listId={leftListId}
                onSwap={swapLeft}
                status={option.status}
              />
              <MiddleIcon index={index} status={option.status} />
            </React.Fragment>
          ))}
          {state.right.map((option, index) => (
            <ListItem
              {...props}
              key={option.id}
              option={option}
              position='right'
              index={index}
              listId={rightListId}
              onSwap={swapRight}
              status={option.status}
            />
          ))}
        </LayoutGroup>
      </GridWrapper>
    </View>
  )
}

const QuestionCardMatchThePairsBodyCreate: SlateFC = props => {
  return <>{props.children}</>
}

export const QuestionCardMatchThePairsBody: SlateFC = props => {
  const { element, mode } = props

  assertElementType('question-card-match-the-pairs-body', element)

  if (mode === 'create') {
    return (
      <QuestionCardBodyWrapper {...props}>
        <QuestionCardMatchThePairsBodyCreate {...props} />
      </QuestionCardBodyWrapper>
    )
  } else {
    return (
      <QuestionCardBodyWrapper {...props}>
        <QuestionCardMatchThePairsBodyLearner {...props} />
      </QuestionCardBodyWrapper>
    )
  }
}

import { useEffect, useMemo } from 'react'
import { VideoPlayerCard } from 'sierra-client/components/liveV2/cards/video-player-card'
import { cardViewed } from 'sierra-client/core/logging/authoring/logger'
import { RecapFile } from 'sierra-client/state/flexible-content/recap-file'
import { useDispatch } from 'sierra-client/state/hooks'
import { FocusEditorContext } from 'sierra-client/views/flexible-content/editor-focus-context'
import { EmbedCard } from 'sierra-client/views/flexible-content/embed-card'
import { FileContext } from 'sierra-client/views/flexible-content/file-context'
import { LearnerImageCard } from 'sierra-client/views/flexible-content/image-card'
import { SlateCard } from 'sierra-client/views/flexible-content/slate-card'
import { StickyNotesCard } from 'sierra-client/views/sticky-notes-card/learner'
import { LiveDropAWordCard } from 'sierra-client/views/v3-author/drop-a-word/live-drop-a-word-card'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { CreateContentId } from 'sierra-domain/api/nano-id'

const Switch: React.FC<{ file: RecapFile; courseId: CreateContentId }> = ({ file, courseId }) => {
  const mode: EditorMode = 'recap'

  const assetContext = useMemo(() => ({ type: 'course' as const, courseId: courseId }), [courseId])

  switch (file.data.type) {
    case 'image':
      return <LearnerImageCard data={file.data} assetContext={assetContext} />
    case 'embed':
      return <EmbedCard readOnly data={file.data} />
    case 'sticky-notes':
      return <StickyNotesCard blockedDueToSessionLimit={false} fileId={file.id} mode={mode} />
    case 'video':
      return <VideoPlayerCard video={file.data.video} />
    case 'poll':
    case 'sliding-scale':
    case 'flip-cards':
    case 'reflections':
    case 'question-card':
    case 'slate-card':
    case 'bullet':
    case 'general':
    case 'external-notepad':
    case 'notepad':
      return <SlateCard readOnly courseId={courseId} file={file} mode={mode} />
    case 'drop-a-word':
      return <LiveDropAWordCard contentId={courseId} addWordDisabled fileData={file.data} fileId={file.id} />

    default:
      file.data satisfies never
      return null
  }
}

export const RecapCardRenderer: React.FC<{ file: RecapFile; courseId: CreateContentId }> = ({
  file,
  courseId,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    void dispatch(
      cardViewed({
        contentId: courseId,
        fileId: file.id,
        cardType: file.data.type,
        mode: 'recap',
      })
    )
  }, [courseId, dispatch, file.data.type, file.id])

  return (
    <FocusEditorContext>
      <FileContext file={file} flexibleContentId={courseId}>
        <Switch file={file} courseId={courseId} />
      </FileContext>
    </FocusEditorContext>
  )
}

import React, { useCallback, useMemo } from 'react'
import { useDuplicateEditableContentMutation } from 'sierra-client/api/hooks/use-editable-content'
import { ContentTableRow } from 'sierra-client/components/common/content-table-row'
import { useContentDrag } from 'sierra-client/components/common/dnd/use-content-table-drag'
import { IconMenu } from 'sierra-client/components/common/icon-menu'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { Thumbnail as CommonThumbnail } from 'sierra-client/components/common/thumbnail'
import { getAssetContextFromEditableContent } from 'sierra-client/components/util/asset-contex'
import { MenuItemWithMoveToTeamspaces, TeamspaceContentTypeName } from 'sierra-client/features/teamspace'
import { teamspaceContentClickedLogger } from 'sierra-client/features/teamspace/logger'
import { UseDeleteCourse, useDeleteCourse } from 'sierra-client/hooks/use-delete-course'
import { usePost } from 'sierra-client/hooks/use-post'
import { useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import { useIsSmallDesktop } from 'sierra-client/state/browser/selectors'
import { useDispatch } from 'sierra-client/state/hooks'
import { useHasManageAccess } from 'sierra-client/views/manage/permissions/use-has-manage-access'
import { CTAActions } from 'sierra-client/views/workspace/components/content-table-cta-buttons'
import {
  CoursePublishStateLabel,
  LastUpdateLabel,
  MetadataWrapper,
  RowContainer,
  TruncatedAutoWidth,
} from 'sierra-client/views/workspace/components/content-table-row-components'
import { StyledThumbnail } from 'sierra-client/views/workspace/create/styled-thumbnail'
import { isCollaboratorRoleAboveOrEqual } from 'sierra-client/views/workspace/utils/collaborator-role-utils'
import { Linkable, continueUrl, manageUrl } from 'sierra-client/views/workspace/utils/urls'
import { EditableContent, editUrl } from 'sierra-domain/api/editable-content'
import { CourseId, PathId } from 'sierra-domain/api/nano-id'
import { PinnableContentType } from 'sierra-domain/api/user'
import {
  XRealtimeAdminPathsDeletePath,
  XRealtimeUserAddPins,
  XRealtimeUserRemovePins,
} from 'sierra-domain/routes'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { Icon, MenuItem, Tooltip } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const pinnableContentType = (createContentKind: EditableContent['type']): PinnableContentType => {
  switch (createContentKind) {
    case 'native:self-paced':
    case 'native:live':
      return 'course'
    case 'path':
      return 'path'
    default:
      assertNever(createContentKind)
  }
}

const NoWrapText = styled(Text)`
  text-wrap: nowrap;
`

const ContentDisplay: React.FC<{ content: EditableContent; isPinned: boolean }> = ({ content, isPinned }) => {
  const isDesktop = !useIsSmallDesktop()
  const { t } = useTranslation()

  const isCourse = content.type === 'native:self-paced'
  const isTemplate =
    (content.type === 'native:self-paced' || content.type === 'native:live') &&
    content.templateSettings !== undefined

  return (
    <>
      <View gap='8'>
        {isPinned && (
          <Tooltip title={t('workspace.create.pinned')}>
            <Icon iconId='pin--filled' color='foreground/muted' />
          </Tooltip>
        )}
        <TruncatedAutoWidth lines={2} size='small' bold>
          {content.title}
        </TruncatedAutoWidth>
      </View>
      <MetadataWrapper wrap={isDesktop ? 'nowrap' : 'wrap'} gap='6' grow>
        <NoWrapText color='foreground/muted'>
          <TeamspaceContentTypeName isTemplate={isTemplate} type={content.type} />
        </NoWrapText>
        <LastUpdateLabel lastEditorId={content.lastEditedBy} updatedAt={content.timestamp} />
        {isCourse && !isTemplate && (
          <CoursePublishStateLabel published={content.published} pending={content.pending} />
        )}
      </MetadataWrapper>
    </>
  )
}

const Actions: React.FC<{
  content: EditableContent
  items: MenuItem[]
  reloadContent: () => void
  link: string
  linkable: Linkable
}> = ({ content, items, reloadContent, link, linkable }) => {
  const haveEditAccess =
    content.highestCollaboratorRole === 'editor' || content.highestCollaboratorRole === 'owner'
  const isMoveableContent = content.type === 'native:live' || content.type === 'native:self-paced'
  const showItemMenuWithMoveTeamspaceOption = haveEditAccess && isMoveableContent

  return (
    <>
      <CTAActions canEdit={true} content={content} linkable={linkable} link={link} />
      {!showItemMenuWithMoveTeamspaceOption ? (
        <IconMenu iconId='overflow-menu--vertical' closeOnPick items={items} color='foreground/muted' />
      ) : (
        <MenuItemWithMoveToTeamspaces onMoveSuccess={reloadContent} defaultItems={items} content={content} />
      )}
    </>
  )
}

const DeleteAlertModal: React.FC<{ content: EditableContent; onDeleted: () => void } & UseDeleteCourse> = ({
  content,
  onDeleted,
  remove,
  loading,
  showDeleteConfirm,
  closeDeleteConfirm,
}) => {
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()

  const deletePath = useCallback(
    async (deletePathId: PathId): Promise<void> => {
      await postWithUserErrorException(XRealtimeAdminPathsDeletePath, { pathId: deletePathId })
    },
    [postWithUserErrorException]
  )

  const assetContext = useMemo(() => getAssetContextFromEditableContent(content), [content])
  const thumbnailSrc = useResolveCourseAsset({
    image: content.image,
    options: { type: 'admin', size: 'small' },
    assetContext,
  })

  return (
    <ActionModal
      open={showDeleteConfirm}
      isLoading={loading}
      onClose={closeDeleteConfirm}
      primaryAction={async () => {
        if (content.type === 'path') {
          await deletePath(content.id)
          onDeleted()
        } else {
          void remove()
        }
      }}
      title={t('content.delete-headline')}
      primaryActionLabel={t('content.delete-button')}
      deleteAction
    >
      <View marginTop='8' marginBottom='8'>
        <Text size='regular'>{t('content.delete-confirm')}</Text>
      </View>
      <View marginBottom='32'>
        <CommonThumbnail image={thumbnailSrc} radius={0.5} width={2} height={2} />
        <Text size='regular' bold>
          {content.title}
        </Text>
      </View>
    </ActionModal>
  )
}

export const CreateContentTableRow: React.FC<{
  content: EditableContent
  isPinned: boolean
  onDeleted: () => void
  reloadContent: () => void
  isOver?: boolean
}> = ({ content, isPinned, onDeleted, reloadContent, isOver }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()

  const deleteProps = useDeleteCourse({
    courseId: CourseId.parse(content.id),
    onDeleted,
  })

  const isCourse = content.type === 'native:self-paced'

  const hasManageAccess = useHasManageAccess()

  const { highestCollaboratorRole } = content
  const roleIsAtLeastEditor =
    isDefined(highestCollaboratorRole) && isCollaboratorRoleAboveOrEqual(highestCollaboratorRole, 'editor')

  const roleIsAtLeastCommenter =
    isDefined(highestCollaboratorRole) && isCollaboratorRoleAboveOrEqual(highestCollaboratorRole, 'commenter')

  const linkable: Linkable = useMemo(
    () => ({ id: content.id, type: content.type }),
    [content.id, content.type]
  )

  const duplicateEditableContentMutation = useDuplicateEditableContentMutation()

  const defaultItems: MenuItem[] = useMemo(() => {
    const addPin = async (): Promise<void> => {
      await postWithUserErrorException(XRealtimeUserAddPins, {
        items: [{ id: content.id, type: pinnableContentType(content.type) }],
      }).then(() => reloadContent())
    }

    const removePin = async (): Promise<void> => {
      await postWithUserErrorException(XRealtimeUserRemovePins, {
        items: [{ id: content.id, type: pinnableContentType(content.type) }],
      }).then(() => reloadContent())
    }

    return [
      {
        id: 'edit',
        type: 'label',
        hidden: !roleIsAtLeastCommenter,
        label: t('dictionary.edit'),
        icon: 'edit',
        onClick: () => {
          void getGlobalRouter().navigate({ to: editUrl(content.type, content.id) })
        },
      },
      {
        id: 'view',
        type: 'label',
        label: t('dictionary.view'),
        hidden: content.type === 'native:self-paced' && !content.published,
        icon: 'view',
        onClick: () => {
          void getGlobalRouter().navigate({ to: continueUrl(linkable) as string })
        },
      },
      {
        id: 'duplicate',
        type: 'label',
        icon: 'duplicate',
        hidden: content.type === 'path' || !roleIsAtLeastEditor,
        animateIcon: false,
        label: t('dictionary.duplicate'),
        onClick: () => {
          duplicateEditableContentMutation.mutate(
            {
              originalFlexibleContentId: CourseId.parse(content.id),
              title: t('content.copy-of-title', { title: content.title }),
            },
            {
              onSuccess: () => {
                reloadContent()
              },
            }
          )
        },
      },
      {
        hidden: !hasManageAccess || (isCourse && !content.published),
        id: 'manage',
        type: 'label',
        label: t('admin.author.manage'),
        icon: 'trend--up',
        onClick: () => {
          void getGlobalRouter().navigate({ to: manageUrl(linkable) as string })
        },
      },
      {
        // Note: Users that have the permission `EDIT_METADATA` but not the role `EDITOR` won't see
        //  the settings item here. That's a limitation of the data we have easily available.
        hidden: !(content.type !== 'path' && roleIsAtLeastEditor),
        id: 'settings',
        type: 'label',
        label: t('settings.settings'),
        icon: 'settings',
        onClick: async () => {
          await dispatch(settingsActions.fetch({ courseId: CourseId.parse(content.id) }))
          void dispatch(settingsActions.setView('course-settings'))
        },
      },
      {
        id: 'pin',
        type: 'label',
        label: isPinned ? t('live.unpin') : t('dictionary.pin'),
        icon: 'pin',
        hidden: !roleIsAtLeastEditor,
        onClick: async (): Promise<void> => {
          if (isPinned) {
            await removePin()
          } else {
            await addPin()
          }
        },
      },
      {
        hidden: !roleIsAtLeastEditor,
        id: 'delete',
        type: 'label',
        label: t('dictionary.delete'),
        icon: 'trash-can',
        color: 'destructive/background',
        onClick: deleteProps.openDeleteConfirm,
      },
    ]
  }, [
    content,
    dispatch,
    duplicateEditableContentMutation,
    hasManageAccess,
    isCourse,
    isPinned,
    linkable,
    deleteProps.openDeleteConfirm,
    postWithUserErrorException,
    reloadContent,
    roleIsAtLeastCommenter,
    roleIsAtLeastEditor,
    t,
  ])

  const assetContext = useMemo(() => getAssetContextFromEditableContent(content), [content])

  const { drag, isDragging, canDrag } = useContentDrag(content, assetContext)

  const onRowClick = useCallback(() => {
    if (content.teamspaceId !== undefined) {
      void dispatch(
        teamspaceContentClickedLogger({
          contentId: content.id,
          teamspaceId: content.teamspaceId,
          clickedFrom: 'page',
        })
      )
    }
  }, [content.id, content.teamspaceId, dispatch])

  const link = roleIsAtLeastCommenter ? editUrl(content.type, content.id) : continueUrl(linkable)

  const thumbnailSrc = useResolveCourseAsset({
    image: content.image,
    options: { type: 'default', size: 'small' },
    assetContext,
  })

  return (
    <>
      <RowContainer
        // TODO: `react-dnd` isn't maintained. Replace it with another library and remove this type assertion.
        ref={drag as (el: HTMLDivElement) => void}
        role='listitem'
        isDroppable={false}
        isOver={isOver ?? false}
        isDragging={isDragging}
      >
        <ContentTableRow
          href={link}
          onRowClick={onRowClick}
          isOver={isOver}
          canDrag={canDrag}
          left={<ContentDisplay isPinned={isPinned} content={content} />}
          thumbnail={<StyledThumbnail lazy={true} image={thumbnailSrc} />}
          right={<div></div>}
          actions={
            <Actions
              items={defaultItems}
              content={content}
              reloadContent={reloadContent}
              link={link}
              linkable={linkable}
            />
          }
        />
        <DeleteAlertModal content={content} onDeleted={onDeleted} {...deleteProps} />
      </RowContainer>
    </>
  )
}

import { useAtomValue } from 'jotai'
import React, { useCallback, useContext, useMemo } from 'react'
import { newPageCardAlignmentAtom } from 'sierra-client/state/settings'
import { FCC } from 'sierra-client/types'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { File } from 'sierra-domain/flexible-content/types'
import { Theme } from 'sierra-ui/theming/legacy-theme'
import { ThemeProvider } from 'styled-components'

export type FileContext = {
  file: File
  flexibleContentId: CreateContentId
}

const ReactFileContext = React.createContext<FileContext | undefined>(undefined)

export const FileContext: FCC<{ file: File; flexibleContentId: CreateContentId }> = ({
  children,
  file,
  flexibleContentId,
}) => {
  const value = useMemo(() => ({ file, flexibleContentId }), [file, flexibleContentId])
  const isWideLayout = file.layout === 'wide'
  const isNewPageCardAlignment = useAtomValue(newPageCardAlignmentAtom)

  const patchTheme = useCallback(
    (theme: Theme): Theme => ({
      ...theme,
      editor: { ...theme.editor, isWideLayout, isNewPageCardAlignment },
    }),
    [isWideLayout, isNewPageCardAlignment]
  )

  return (
    <ReactFileContext.Provider value={value}>
      <ThemeProvider theme={patchTheme}>{children}</ThemeProvider>
    </ReactFileContext.Provider>
  )
}

export function useFileContext(): FileContext {
  const context = useContext(ReactFileContext)
  if (context === undefined) throw new Error('Expected component to be wrapped in a FileContext')
  return context
}

export function useSafeFileContext(): FileContext | undefined {
  return useContext(ReactFileContext)
}

import React, { useCallback } from 'react'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { DisplayNone } from 'sierra-client/views/v3-author/components'
import { assertElementType, isElementType } from 'sierra-client/views/v3-author/queries'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { SlidingScaleDataProvider } from 'sierra-client/views/v3-author/sliding-scale-card/data-layer'
import { CreateSlider, Slider } from 'sierra-client/views/v3-author/sliding-scale-card/slider'
import { BlockWrapper } from 'sierra-client/views/v3-author/wrapper'
import { createParagraph } from 'sierra-domain/v3-author/create-blocks'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { height_100dvh } from 'sierra-ui/utils'
import { Transforms } from 'slate'
import { useSlateStatic } from 'slate-react'
import styled, { css } from 'styled-components'

const AddParagraphArea = styled.div`
  ${height_100dvh}
  width: 100%;
  position: absolute;
  bottom: 100%;
  cursor: text;
`
const interactiveCardGrid = css`
  row-gap: 0;
  padding: 3rem 0rem;

  & {
    --middle: minmax(1rem, 6rem);
    --gutter: 1fr;

    @media screen and (max-width: ${v2_breakpoint.phone}) {
      --gutter: 4%;
    }
  }
`

const SlidingInteractiveCardWrapper = styled(BlockWrapper)`
  ${interactiveCardGrid}
`

const OptionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  > * {
    width: 240px;
    max-width: 45%;

    &:last-child {
      text-align: right;
    }
  }
`

export const SlidingScaleCardContainer = React.forwardRef<HTMLDivElement, SlateWrapperProps>((props, ref) => {
  const { children, attributes, mode, element, readOnly } = props
  assertElementType('sliding-scale-card', element)
  const userId = useSelector(selectUserId)
  const editor = useSlateStatic()

  const firstNode = editor.children[0]
  const hasNoParagraph = isElementType('sliding-scale-card', firstNode)

  const addParagraph = useCallback(() => {
    if (readOnly) return

    Transforms.insertNodes(editor, createParagraph(), { at: [0] })
    Transforms.setSelection(editor, {
      anchor: { path: [0, 0], offset: 0 },
      focus: { path: [0, 0], offset: 0 },
    })
  }, [editor, readOnly])

  if (userId === undefined) return <DisplayNone>{children}</DisplayNone>
  else
    return (
      <RenderingContext preventDrag={true} disableMenu={false} withGrid={true}>
        {hasNoParagraph && <div contentEditable={false} style={{ height: '24px' }}></div>}

        <SlidingInteractiveCardWrapper {...props} {...attributes} ref={ref}>
          {hasNoParagraph && (
            <AddParagraphArea contentEditable={false} onClick={addParagraph}></AddParagraphArea>
          )}
          <RenderingContext withGrid={false}>
            <SlidingScaleDataProvider element={element} mode={mode}>
              <div contentEditable={false}>
                {mode === 'create' || mode === 'template' || mode === 'version-history' ? (
                  <CreateSlider />
                ) : (
                  <Slider />
                )}
              </div>
              <OptionsContainer>{children}</OptionsContainer>
            </SlidingScaleDataProvider>
          </RenderingContext>
        </SlidingInteractiveCardWrapper>
      </RenderingContext>
    )
})

export const SlidingScaleCard: SlateFC = ({ element, children }) => {
  assertElementType('sliding-scale-card', element)

  return <>{children}</>
}

import { DateTime } from 'luxon'
import { FC, useMemo } from 'react'
import {
  useLiveSessionStateMutation,
  useResetCurrentSessionForRoomQuery,
} from 'sierra-client/api/hooks/use-live-session'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { useIsFacilitatorOrLearnerLedSession } from 'sierra-client/components/liveV2/hooks/use-is-facilitator-or-learner-led-session'
import { useHasRecapAvailable } from 'sierra-client/features/sana-now/post-session-page/use-has-recap-available'
import { UseSessionBotDataQueryResult } from 'sierra-client/features/sana-now/post-session-page/use-session-bot-data'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import { useIsTablet } from 'sierra-client/state/browser/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { liveSessionStateChanged } from 'sierra-client/state/live-session/actions'
import { selectIsGuestUser } from 'sierra-client/state/user/user-selector'
import {
  constructScheduleFromLiveData,
  formatEventSchedule,
  formatLiveSessionTimeAsSchedule,
} from 'sierra-client/views/manage/event-groups/event-utils'
import { LiveRoomId, LiveSessionId } from 'sierra-domain/api/nano-id'
import {
  XRealtimeStrategyLiveSessionCreateNewLiveSessionInRoom,
  XRealtimeStrategyLiveSessionGetRoomDetails,
} from 'sierra-domain/routes'
import { iife } from 'sierra-domain/utils'
import { MenuButton, MenuItem } from 'sierra-ui/components'
import { Button, Heading, IconButton, Spacer, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export const BlurButton = styled(IconButton).attrs({
  disabledHover: true,
  disabledBackgroundHover: true,
  color: 'white',
})`
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(32px);
  width: 40px;
  height: 40px;
  border-radius: 12px;
  flex-shrink: 0;
`

const ContentWrapper = styled(View)`
  padding: 0 var(--post-session-recap-page-padding);
`

const RoomControlsButton: FC<{
  roomId: LiveRoomId
  liveSessionId: LiveSessionId
}> = ({ roomId, liveSessionId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const mutation = useLiveSessionStateMutation()
  const canFacilitate = useIsFacilitatorOrLearnerLedSession()

  const { mutate: createNewSessionInRoom } = useTypedMutation(
    XRealtimeStrategyLiveSessionCreateNewLiveSessionInRoom
  )
  const result = useCachedQuery(XRealtimeStrategyLiveSessionGetRoomDetails, { roomId })
  const resetRootRoomQuery = useResetCurrentSessionForRoomQuery(roomId)

  if (result.data === undefined) {
    return null
  }

  const isASessionOngoing = result.data.currentSession.endedAt === undefined

  if (isASessionOngoing) {
    return (
      <Button
        variant='success'
        onClick={() => {
          void getGlobalRouter().navigate({
            to: '/room/$liveRoomId',
            params: { liveRoomId: result.data.roomId },
          })
          void resetRootRoomQuery()
        }}
      >
        {t('sana-now.post-session-page.join-current-session-button-text')}
      </Button>
    )
  }

  if (!canFacilitate) return null

  return (
    <>
      <MenuButton
        menuWidth={240}
        variant='secondary'
        menuItems={[
          {
            type: 'label',
            id: 'start',
            label: t('sana-now.post-session-page.new-session-in-room'),
            description: t('sana-now.post-session-page.new-session-in-room--description'),
            onClick: () => {
              createNewSessionInRoom(
                {
                  roomId,
                },
                {
                  onSuccess: async () => {
                    await getGlobalRouter().navigate({
                      to: '/room/$liveRoomId',
                      params: { liveRoomId: roomId },
                    })
                    await resetRootRoomQuery()
                  },
                }
              )
            },
          },
          {
            type: 'label',
            id: 'start',
            label: t('live.reopen-session'),
            description: t('sana-now.post-session-page.reopen-session-description'),
            onClick: () => {
              mutation.mutate(
                {
                  liveSessionId,
                  state: 'active',
                },
                {
                  onSuccess: () => {
                    void dispatch(liveSessionStateChanged('active'))
                  },
                }
              )
            },
          },
        ]}
        onSelect={() => {}}
      >
        {t('sana-now.post-session-page.reopen-session-dropdown')}
      </MenuButton>
    </>
  )
}

const getSessionButtonText = (startedAt?: Date): string => {
  if (startedAt === undefined) return 'Unknown'

  const startedDt = DateTime.fromJSDate(startedAt)
  return startedDt.toFormat('MMM d, h:mm a')
}

const getTooltip = (startedAt?: Date, endedAt?: Date): string | undefined => {
  if (endedAt !== undefined && startedAt !== undefined) {
    return formatEventSchedule(
      constructScheduleFromLiveData({
        allDay: false,
        startTime: startedAt.toISOString(),
        endTime: endedAt.toISOString(),
      }),
      { month: 'long' }
    )
  }

  return undefined
}

const RoomSessionsButtons: FC<{ roomId: LiveRoomId }> = ({ roomId }) => {
  const { t } = useTranslation()
  const liveSession = useLiveSessionContext()
  const result = useCachedQuery(XRealtimeStrategyLiveSessionGetRoomDetails, { roomId })
  const { sessions } = result.data ?? {}

  const { sessionsToShow, moreSessions } = useMemo(() => {
    if (sessions === undefined)
      return { sessionsToShow: [], currentlyViewingSession: undefined, moreSessions: [] }

    const pastSessions = sessions.filter(s => s.endedAt !== undefined)
    const sessionsToShow = pastSessions.slice(0, 3)
    const currentlyViewingSession = pastSessions.find(
      session => session.sessionId === liveSession.liveSessionId
    )

    if (currentlyViewingSession !== undefined && !sessionsToShow.includes(currentlyViewingSession)) {
      sessionsToShow.push(currentlyViewingSession)
    }

    const moreSessions = pastSessions.filter(
      s => sessionsToShow.includes(s) === false && currentlyViewingSession !== s
    )

    return {
      sessionsToShow,
      moreSessions,
    }
  }, [liveSession.liveSessionId, sessions])

  if (sessions === undefined) return null
  if (sessions.length <= 1) return null

  const onSessionSelected = (sessionId: LiveSessionId): void => {
    void getGlobalRouter().navigate({
      to: '/l/$liveSessionId',
      params: { liveSessionId: sessionId },
    })
  }

  return (
    <>
      <View>
        {sessionsToShow.map(session => (
          <Button
            aria-label={getTooltip(session.startedAt, session.endedAt)}
            variant={session.sessionId === liveSession.liveSessionId ? 'ghost' : 'secondary'}
            key={session.sessionId}
            onClick={() => onSessionSelected(session.sessionId)}
          >
            {getSessionButtonText(session.startedAt)}
          </Button>
        ))}
        {moreSessions.length > 0 && (
          <MenuButton
            variant='secondary'
            menuItems={moreSessions.map(
              session =>
                ({
                  type: 'label',
                  id: session.sessionId,
                  label: getSessionButtonText(session.startedAt),
                  selected: liveSession.liveSessionId === session.sessionId,
                  onClick: () => {
                    onSessionSelected(session.sessionId)
                  },
                }) satisfies MenuItem
            )}
            onSelect={() => {}}
          >
            {t('dictionary.more')}
          </MenuButton>
        )}
      </View>
    </>
  )
}

export const TitleRow: FC<{ summaryData: UseSessionBotDataQueryResult }> = ({ summaryData }) => {
  const { t } = useTranslation()
  const liveSession = useLiveSessionContext()
  const mutation = useLiveSessionStateMutation()
  const liveSessionRoomsFlag = useFlag('live-session-rooms')

  const dispatch = useDispatch()
  const guestUserState = useSelector(selectIsGuestUser)
  const hasRecap = useHasRecapAvailable(liveSession.liveSessionId) && liveSession.data.enableRecap === true
  const isSmallScreen = useIsTablet()
  const canFacilitate = useIsFacilitatorOrLearnerLedSession()

  const roomId = liveSession.data.roomId
  const liveRoomsEnabled = useFlag('live-session-rooms') && roomId !== undefined

  const timeString = iife(() => {
    if (liveSession.data.type === 'scheduled') {
      return formatLiveSessionTimeAsSchedule(liveSession.data)
    }

    if (liveSession.data.endedAt !== undefined && liveSession.data.startedAt !== undefined) {
      return formatEventSchedule(
        constructScheduleFromLiveData({
          allDay: false,
          startTime: liveSession.data.startedAt,
          endTime: liveSession.data.endedAt,
        }),
        { month: 'long' }
      )
    }

    return undefined
  })

  const titleComponent = (
    <View direction='column'>
      {liveRoomsEnabled && (
        <Heading size='h5' bold color='foreground/muted'>
          {t('sana-now.post-session-page.title.session-summary')}
        </Heading>
      )}
      {!liveRoomsEnabled && timeString !== undefined && (
        <Heading size='h5' bold color='foreground/muted'>
          {timeString}
        </Heading>
      )}

      <Heading size='h2'>{liveSession.data.title}</Heading>
    </View>
  )

  const buttons =
    guestUserState === 'not-guest' ? (
      <View>
        {liveRoomsEnabled && <RoomControlsButton liveSessionId={liveSession.liveSessionId} roomId={roomId} />}

        {canFacilitate && !liveRoomsEnabled && (
          <Button
            variant='secondary'
            onClick={() => {
              mutation.mutate(
                {
                  liveSessionId: liveSession.liveSessionId,
                  state: 'active',
                },
                {
                  onSuccess: () => {
                    void dispatch(liveSessionStateChanged('active'))
                  },
                }
              )
            }}
          >
            {t('live.reopen-session')}
          </Button>
        )}
        {hasRecap === true && (
          <Button variant='primary' href={`/r/${liveSession.liveSessionId}`}>
            {t('dictionary.view-content')}
          </Button>
        )}
      </View>
    ) : null

  if (isSmallScreen) {
    return (
      <View
        direction='column'
        gap='16'
        paddingTop={summaryData.data?.type === 'completed' ? undefined : '48'}
      >
        <ContentWrapper gap='16' direction='column'>
          {titleComponent}
          {buttons}
          {liveSessionRoomsFlag && liveSession.data.roomId && guestUserState === 'not-guest' && (
            <RoomSessionsButtons roomId={liveSession.data.roomId} />
          )}
        </ContentWrapper>
      </View>
    )
  }

  if (liveSessionRoomsFlag && liveSession.data.roomId) {
    return (
      <>
        <Spacer size='48' />
        <ContentWrapper>
          <View grow direction='column' gap='24'>
            {titleComponent}
            <View justifyContent='space-between' alignItems='flex-end'>
              {guestUserState === 'not-guest' && <RoomSessionsButtons roomId={liveSession.data.roomId} />}
              {buttons}
            </View>
          </View>
        </ContentWrapper>
      </>
    )
  }

  return (
    <>
      <Spacer size='48' />
      <ContentWrapper>
        <View grow direction='column' gap='24'>
          <View justifyContent='space-between' alignItems='flex-end'>
            {titleComponent}
            {buttons}
          </View>
        </View>
      </ContentWrapper>
    </>
  )
}

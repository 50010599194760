import { Rgba } from 'sierra-ui/color'
import { rgbaToThumbHash, thumbHashToAverageRGBA, thumbHashToDataURL } from 'thumbhash'

const binaryToBase64 = (binary: Uint8Array): string => btoa(String.fromCharCode(...binary))
const base64ToBinary = (base64: string): Uint8Array =>
  new Uint8Array(
    atob(base64)
      .split('')
      .map(x => x.charCodeAt(0))
  )

export const createImageThumbhashBase64 = (image: HTMLImageElement): string | undefined => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (!context) return undefined

  // The image width and height must be smaller than 100px
  const scale = 100 / Math.max(image.width, image.height)
  canvas.width = Math.round(image.width * scale)
  canvas.height = Math.round(image.height * scale)
  context.drawImage(image, 0, 0, canvas.width, canvas.height)

  const pixels = context.getImageData(0, 0, canvas.width, canvas.height)
  const hash = rgbaToThumbHash(pixels.width, pixels.height, pixels.data)
  const hashBase64 = binaryToBase64(hash)

  return hashBase64
}

export const createDataURlFromThumbhashBase64 = (thumbhashBase64: string): string => {
  const thumbhash = base64ToBinary(thumbhashBase64)
  return thumbHashToDataURL(thumbhash)
}

export const thumbhashAverageColor = (thumbhashBase64: string): Rgba => {
  const thumbhash = base64ToBinary(thumbhashBase64)
  const res = thumbHashToAverageRGBA(thumbhash)

  return {
    r: res.r * 255,
    g: res.g * 255,
    b: res.b * 255,
    a: 1,
  }
}

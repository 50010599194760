import { UseQueryResult } from '@tanstack/react-query'
import { getInsightsQueryOptions } from 'sierra-client/features/insights/api-hooks/query-options'
import { useCachedQuery } from 'sierra-client/state/api'
import { InsightViews } from 'sierra-domain/api/insights'
import { XAnalyticsListViews } from 'sierra-domain/routes'

export const useInsightsViews = (): UseQueryResult<InsightViews> => {
  const response = useCachedQuery(
    XAnalyticsListViews,
    {},
    { ...getInsightsQueryOptions<InsightViews>(), staleTime: 5 * 60 * 1000 }
  )
  return response
}

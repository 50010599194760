import { default as React, useEffect, useMemo } from 'react'
import { DocumentSyncChecker, DocumentTypeChecker } from 'sierra-client/editor/document-type-checker'
import { EditorJotaiContext } from 'sierra-client/editor/editor-jotai-context'
import { EditorTextActionsProvider } from 'sierra-client/features/text-actions'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { Shortcut } from 'sierra-client/views/v3-author/slash-menu'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { TagsContext, TagsList, useGetTags } from 'sierra-client/views/v3-author/tags/tags-menu'
import { ChatIdentifier } from 'sierra-domain/api/chat'
import { AssetContext } from 'sierra-domain/asset-context'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { SlashMenuEntryId, SlashMenuState, allSlashMenuEntryIds } from 'sierra-domain/v3-author/slash-menu'
import { Editor } from 'slate'
import { Slate as SlateContext } from 'slate-react'

type EditorContextValue = {
  editorId: string
  chatId?: ScopedChatId
  chatIdentifier?: ChatIdentifier
  mode: EditorMode
  supportedSlashMenuEntryIds: readonly SlashMenuEntryId[]
  readOnly: boolean
  enableCommenting: boolean
  rulesOfBlocksViolations: 'warning' | 'error'
  assetContext: AssetContext
}

const Context = React.createContext<EditorContextValue | undefined>(undefined)

/**
 * Note: The context is provided by an <EditorContext> component, so there must be one of these in the UI tree in
 * order to use this hook.
 */
export const useEditorContextValue = (): EditorContextValue => {
  const context = React.useContext(Context)
  if (context === undefined)
    throw new Error('No context found. This component must be wrapped in an <EditorContext> component.')

  return useDeepEqualityMemo(context)
}

/**
 * The current id of the editor. This is most commonly used as a key to identify which editor state
 * to look at in the redux state.
 *
 * Note: see `useAuthoringContext`
 */
export const useEditorId = (): string => useEditorContextValue().editorId

export const useRulesOfBlockViolationSeveriy = (): EditorContextValue['rulesOfBlocksViolations'] =>
  useEditorContextValue().rulesOfBlocksViolations

/**
 * The id of the chat attached to the current editor. This will be undefined if chat is currently
 * unsupported for this editor.
 *
 * Note: see `useAuthoringContext`
 */
export const useEditorChatId = (): ScopedChatId | undefined => useEditorContextValue().chatId
export const useEditorChatIdentifier = (): ChatIdentifier | undefined =>
  useEditorContextValue().chatIdentifier

/**
 * This value can be used to determine the context in which the editor is being used - i.e. if it
 * is being used to render content for learners or for creators.
 */
export const useEditorMode = (): EditorMode => useEditorContextValue().mode

/**
 * Indicates if the editor is in readOnly mode.
 */
export const useEditorReadOnly = (): boolean => useEditorContextValue().readOnly

/**
 * Indicates if commenting is enabled in the editor
 */
export const useEditorCommentingEnabled = (): boolean => useEditorContextValue().enableCommenting

export const useEditorAssetContext = (): AssetContext => useEditorContextValue().assetContext

type SlateOptions = Omit<Parameters<typeof SlateContext>[0], 'onChange'>
export type EditorContextOptions = SlateOptions & {
  editor: Editor
  editorId: string
  chatId?: ScopedChatId
  chatIdentifier?: ChatIdentifier
  mode: EditorMode
  supportedSlashMenuEntryIds?: SlashMenuState['idsSupportedByEditor']
  readOnly: boolean
  enableCommenting: boolean
  rulesOfBlocksViolations?: 'error' | 'warning'
  assetContext: AssetContext
}
export const EditorContext = ({
  children,
  editorId,
  editor,
  chatId,
  chatIdentifier,
  mode,
  readOnly,
  enableCommenting,
  supportedSlashMenuEntryIds = allSlashMenuEntryIds.filter(id => id !== 'question-card'),
  rulesOfBlocksViolations = 'warning',
  assetContext,
  ...rest
}: EditorContextOptions): JSX.Element => {
  const contextValue: EditorContextValue = useMemo(
    () => ({
      editorId,
      chatId,
      chatIdentifier,
      mode,
      supportedSlashMenuEntryIds,
      readOnly,
      enableCommenting,
      rulesOfBlocksViolations,
      assetContext,
    }),
    [
      editorId,
      chatId,
      chatIdentifier,
      mode,
      supportedSlashMenuEntryIds,
      readOnly,
      enableCommenting,
      rulesOfBlocksViolations,
      assetContext,
    ]
  )

  const tagsContextValue = useGetTags(editor)
  const { updateTags, targetRect: tagsTargetRect, index: tagsIndex } = tagsContextValue

  const onChange = useStableFunction(() => {
    updateTags()
  })

  useEffect(() => {
    if (chatId === undefined) {
      console.debug('[EditorContext] No chatId was set. The chat is disabled.')
    }
  }, [chatId])

  return (
    <Context.Provider value={contextValue}>
      <SlateContext {...rest} editor={editor} onChange={onChange}>
        <EditorJotaiContext
          readOnly={readOnly}
          editor={editor}
          key={editorId}
          supportedSlashMenuEntryIds={supportedSlashMenuEntryIds}
        >
          <TagsContext mode={mode} value={tagsContextValue}>
            <EditorTextActionsProvider key={editorId}>
              <Shortcut supportedSlashMenuEntryIds={supportedSlashMenuEntryIds} />
              {tagsTargetRect && !readOnly && (
                <TagsList targetRect={tagsTargetRect} currentIndex={tagsIndex} />
              )}

              {/* No need to run checks on the document if it is not editable */}
              {!readOnly && (
                <>
                  <DocumentTypeChecker key={`types-${editorId}`} />
                  <DocumentSyncChecker key={`sync-${editorId}`} />
                </>
              )}

              {children}
            </EditorTextActionsProvider>
          </TagsContext>
        </EditorJotaiContext>
      </SlateContext>
    </Context.Provider>
  )
}

import { motion } from 'framer-motion'
import _ from 'lodash'
import { default as React, useCallback, useMemo, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { resolveUserAvatar } from 'sierra-client/api/content'
import { DragItemTypes, PollAlternativeDragItem } from 'sierra-client/components/common/dnd/dnd-types'
import { generativeFeatureUsed } from 'sierra-client/core/logging/authoring/logger'
import { TextToSpeech } from 'sierra-client/editor/text-to-speech'
import { useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import * as flexSelectors from 'sierra-client/state/flexible-content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { PollData } from 'sierra-client/state/interactive-card-data/poll-card'
import { selectIsFacilitator } from 'sierra-client/state/live-session/selectors'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { FCC } from 'sierra-client/types'
import {
  useCreatePageContextSafe,
  useCreatePageNodeIdContextSafe,
  useCreatePageYDocContext,
} from 'sierra-client/views/flexible-content/create-page-context'
import { useThemeForFile } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { UploadImageModal } from 'sierra-client/views/upload-image-modal/upload-image-modal'
import { removeNodeWithId, replaceNodeWithId, updateNodeWithId } from 'sierra-client/views/v3-author/command'
import { useEditorAssetContext, useEditorReadOnly } from 'sierra-client/views/v3-author/context'
import {
  useAncestorWithType,
  useIsInLatestSelection,
  useParent,
  usePath,
  useSiblings,
} from 'sierra-client/views/v3-author/hooks'
import { InteractiveCardInstruction } from 'sierra-client/views/v3-author/interactive-card-instruction'
import { usePollData } from 'sierra-client/views/v3-author/poll-card/poll-card-data-layer'
import { useGeneratePollCard } from 'sierra-client/views/v3-author/poll-card/use-generate-poll-card'
import { OptionIdsToNumber, calculateRoundedPercentages } from 'sierra-client/views/v3-author/poll-card/utils'
import { assertElementType, isElementType } from 'sierra-client/views/v3-author/queries'
import { RenderingContext, useRenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { PollTypes, useGeneratePollItems } from 'sierra-client/views/v3-author/slash-menu/content-generation'
import { EditorMode, SlateFC, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { ContentType } from 'sierra-domain/collaboration/types'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { Entity } from 'sierra-domain/entity'
import { hasOnlyEmptyTextInNodes, textInNodes } from 'sierra-domain/slate-util'
import { assertNever, isDefined } from 'sierra-domain/utils'
import {
  CustomElement,
  SanaEditor,
  PollCardAlternative as SlatePollCardAlterantive,
} from 'sierra-domain/v3-author'
import { createPollCardAlternative } from 'sierra-domain/v3-author/create-blocks'
import { color, deriveTheme, color as resolveColor } from 'sierra-ui/color'
import { AvatarStack, Icon, MenuButton, MenuItem, Tooltip } from 'sierra-ui/components'
import { Button, IconButton, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { palette, spacing, token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { FlexDirection } from 'sierra-ui/types'
import { Editor, Element, Path, Transforms } from 'slate'
import { ReactEditor, useSelected, useSlateStatic } from 'slate-react'
import styled, { css, useTheme } from 'styled-components'

const MetaData = styled.div`
  opacity: 0;
`

const OverlayLayer = styled.div.attrs({ contentEditable: false })`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${p => p.theme.borderRadius['size-12']};
  background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.25));
  opacity: 0.85;
  transition: all 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
`

type PercentagesProps = {
  displayResults: boolean | undefined
  direction: 'row' | 'column'
}

const Percentages = styled.div<PercentagesProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;

  ${p =>
    p.displayResults !== true &&
    css`
      opacity: 0;
    `}
  ${Text} {
    color: ${p => p.theme.home.backgroundColor};
    font-variant-numeric: tabular-nums;
  }

  @media print {
    display: none;
  }

  flex-direction: ${p => (p.direction === 'column' ? 'row-reverse' : 'row')};
`

type BaseChoiceProps = {
  $backgroundImageSrc?: string
  direction: 'row' | 'column'
  $columnStyling?: boolean
  $userVoted: boolean
}

const BaseChoiceStyles = css<BaseChoiceProps>`
  transition: all 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
  cursor: pointer;
  position: relative;
  isolation: isolate;
  display: flex;
  flex: 1 1 0;
  border-radius: 12px;
  ${p =>
    p.$columnStyling === true
      ? css`
          flex-direction: row;
          align-items: center;
          padding: 1rem 1.5rem;
        `
      : css`
          flex-direction: column;
          justify-content: flex-start;
          justify-content: space-between;
          padding: 1.5rem;
        `};

  background-color: ${p => p.theme.home.textColor};
  color: ${p => p.theme.home.backgroundColor};

  ${p =>
    p.$backgroundImageSrc !== undefined &&
    css`
      color: ${palette.primitives.white};
      background: url('${p.$backgroundImageSrc}');
      background-position: center;
      background-size: cover;
    `}
  &:not(.results):hover {
    ${MetaData} {
      opacity: 0.5;
    }
  }
`

type LearnerChoiceProps = BaseChoiceProps & {
  $result: number
}
const LearnerChoice = styled(motion.div)<LearnerChoiceProps>`
  ${BaseChoiceStyles};

  height: 100%;
  min-height: 88px;
  flex: 1;

  @media screen and (max-width: ${v2_breakpoint.tablet}px) {
    min-height: auto;
  }

  ${p =>
    p.direction === 'row' &&
    css`
      min-height: 350px;
    `}
  &.results ${Percentages} {
    display: flex;
  }

  &.results {
    ${MetaData} {
      display: none;
    }

    background-color: ${p => color(p.theme.home.textColor).opacity(0.2).toString()};
  }
`

const DragButton = styled(IconButton)<{ direction: 'row' | 'column'; hasImage: boolean }>`
  transition: all 100ms;
  opacity: 0;
  cursor: grab;
  border: none;
  color: ${p => (p.hasImage && p.direction === 'row' ? 'white' : p.theme.home.backgroundColor)};
`

const RowIndicators = css<{ $indicateDropBorder: 'before' | 'after' | undefined }>`
  ${p =>
    p.$indicateDropBorder === 'after' &&
    css`
      &::before {
        content: '';
        position: absolute;
        width: 5px;
        border-radius: 2.5px;
        height: 100%;
        background-color: ${token('foreground/primary').opacity(0.25)};
        right: -10.5px;
        top: 0;
      }
    `}
  ${p =>
    p.$indicateDropBorder === 'before' &&
    css`
      &::before {
        content: '';
        position: absolute;
        width: 5px;
        border-radius: 2.5px;
        height: 100%;
        background-color: ${token('foreground/primary').opacity(0.25)};
        left: -10.5px;
        top: 0;
      }
    `}
`

const ColumnIndicators = css<{ $indicateDropBorder: 'before' | 'after' | undefined }>`
  ${p =>
    p.$indicateDropBorder === 'after' &&
    css`
      &::before {
        content: '';
        position: absolute;
        height: 2px;
        border-radius: 2.5px;
        width: 100%;
        background-color: ${token('foreground/primary').opacity(0.6)};
        bottom: -5px;
        left: 0;
      }
    `}
  ${p =>
    p.$indicateDropBorder === 'before' &&
    css`
      &::before {
        content: '';
        position: absolute;
        height: 2px;
        border-radius: 2.5px;
        width: 100%;
        background-color: ${token('foreground/primary').opacity(0.6)};
        left: 0;
        top: -5px;
      }
    `}
`
type CreateProps = {
  $isDragging: boolean
  $indicateDropBorder: 'after' | 'before' | undefined
  $isSelected: boolean
} & BaseChoiceProps

const CreatePollCardAlternative = styled(motion.div)<CreateProps>`
  position: relative;

  ${BaseChoiceStyles};

  ${p =>
    p.$isSelected === true &&
    css`
      box-shadow: 0px 0px 0px 6px ${token('form/focus/highlight')};
    `}

  height: 100%;
  align-items: center;

  ${p =>
    p.direction === 'row' &&
    css`
      min-height: 350px;
      align-items: start;
    `}

  ${p =>
    p.direction === 'column' &&
    css`
      min-height: 68px;
    `}  

  flex: 1;

  &:hover {
    ${DragButton} {
      opacity: 1;
    }

    ${OverlayLayer} {
      opacity: 0.95;
    }
  }

  cursor: text;
  opacity: ${p => (p.$isDragging ? '0.5' : '1')};
  ${p => (p.direction === 'row' ? RowIndicators : ColumnIndicators)};
`

const AlternativeOverlay = styled.div<{ $backgroundImageSrc?: string; $shouldFade: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  border-radius: 12px;
  pointer-events: none;
  transition: background-color 150ms cubic-bezier(0.25, 0.1, 0.25, 1);

  ${p =>
    p.$backgroundImageSrc !== undefined &&
    css`
      ${p.$shouldFade &&
      css`
        background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.25));
      `}
    `};
`

type ResultOverlayProps = {
  $result: number
  $userVoted: boolean
  direction: 'row' | 'column'
}
const ResultOverlay = styled.div<ResultOverlayProps>`
  position: absolute;
  color: ${p => p.theme.home.textColor};
  background-color: ${p =>
    p.$userVoted ? p.theme.home.textColor : color(p.theme.home.textColor).opacity(0.6).toString()};
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 12px;

  height: ${p => {
    if (p.direction === 'row') {
      return `${p.$result}%;`
    } else {
      return `100%;`
    }
  }};

  width: ${p => {
    if (p.direction === 'column') {
      return `${p.$result}%;`
    } else {
      return `100%;`
    }
  }};

  transition-duration: 400ms;
  transition-delay: all 400ms;
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
`
type PollDirection = Extract<FlexDirection, 'row' | 'column'>

const getPollDirection = (alternativeCount: number, isMobile: boolean): PollDirection => {
  return isMobile ? 'column' : alternativeCount > 3 ? 'column' : 'row'
}

const PollPercentageText = styled(Text)`
  background-color: ${p => color(p.theme.home.textColor).toString()};
  padding: ${spacing['2']} ${spacing['10']};
  border-radius: 1rem;
`

export const PollCardAlternativeRowContainer = styled(View).attrs({
  direction: 'column',
  gap: 'xsmall',
})`
  margin-top: 1rem;
`

const BottomContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
`

const SkipButton = styled.div`
  color: ${p => deriveTheme(p.theme).textColor};
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
  opacity: 0.75;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`

const TotalVotes = (): JSX.Element | null => {
  const { pollData } = usePollData()
  const { t } = useTranslation()

  if (pollData?.totalVotes === undefined) return null

  return (
    <View contentEditable={false}>
      <InteractiveCardInstruction color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular'>
        {t('learner.poll-card.total-votes', { totalVotes: pollData.totalVotes })}
      </InteractiveCardInstruction>
    </View>
  )
}

const SeeResults = (): JSX.Element | null => {
  const { userVoteSkipped, skip } = usePollData()
  const isFacilitator = useSelector(selectIsFacilitator)
  const { t } = useTranslation()

  if (userVoteSkipped === true || skip === undefined || isFacilitator !== true) return null

  return (
    <View contentEditable={false}>
      <SkipButton onClick={skip}>{t('learner.poll-card.see-results')}</SkipButton>
    </View>
  )
}

const VerticalPollTextContanier = styled.div<{ direction: PollDirection }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  @media screen and (max-width: ${v2_breakpoint.tablet}) {
    gap: 0.5rem;
  }

  ${p =>
    p.direction === 'column'
      ? css`
          margin: 1rem;
          gap: 8px;
          flex: 1 1;
          align-items: center;
          @media screen and (max-width: ${v2_breakpoint.phone}) {
            flex-direction: column;
          }
        `
      : css`
          height: 100%;
          align-items: start;
          flex-direction: column;
        `};
`

const AlternativesContainer = styled.div<{ $direction: PollDirection }>`
  display: grid;
  gap: ${p => (p.$direction === 'row' ? spacing.xsmall : spacing.xxsmall)};

  ${p =>
    p.$direction === 'row'
      ? css`
          grid-auto-columns: 1fr;
          grid-auto-flow: column;
        `
      : ``} @media screen and (
    max-width: ${v2_breakpoint.tablet}px) {
    grid-auto-flow: row;
  }
`

const GeneratePollButton: React.FC<{
  editor: SanaEditor
  elementId: string
  createContentId: CreateContentId
  contentType: ContentType
}> = ({ editor, elementId, createContentId, contentType }) => {
  const dispatch = useDispatch()
  const nodeId = useCreatePageNodeIdContextSafe()?.nodeId
  const parent = useParent({ nodeId: elementId })
  const pollCardId = isElementType('poll-card', parent) ? parent.id : undefined
  const { yDoc } = useCreatePageYDocContext()
  const [isGenerating, setIsGenerating] = useState(false)
  const { t } = useTranslation()

  const file = useSelector(state =>
    nodeId !== undefined ? flexSelectors.selectFlexibleContentFile(state, createContentId, nodeId) : undefined
  )
  const color = resolveColor(deriveTheme(useThemeForFile(file)).textColor)

  const { generatePollCard } = useGeneratePollCard({ yDoc, currentNodeId: nodeId, createContentId })
  const generatePoll = useCallback(
    async (type: PollTypes) => {
      if (pollCardId === undefined) return

      setIsGenerating(true)
      const pollCard = await generatePollCard(type)
      setIsGenerating(false)
      if (pollCard) {
        const newNode: CustomElement = { ...pollCard, id: pollCardId }
        Editor.withoutNormalizing(editor, () => {
          replaceNodeWithId(editor, pollCardId, newNode)
        })

        // Segment logging for generative features used
        void dispatch(
          generativeFeatureUsed({
            contentId: createContentId,
            contentType,
            generativeFeature: `generate-${type}-poll-poll-card`,
          })
        )
      }
    },
    [contentType, createContentId, dispatch, editor, generatePollCard, pollCardId]
  )

  const menuItems: MenuItem<PollTypes>[] = useGeneratePollItems().map(({ id, label }) => ({
    id,
    label,
    type: 'label',
  }))

  return (
    <>
      <View
        onClick={e => {
          e.stopPropagation() //This is for not toggling preview when clicking
        }}
      >
        <MenuButton disabled={isGenerating} menuItems={menuItems} onSelect={item => generatePoll(item.id)}>
          {t('author.slate.generate')}
        </MenuButton>
        <Tooltip
          title={
            <View direction='column'>
              <Text size='regular' color='surface/default'>
                Let Sana generate a poll for your specific use case:
              </Text>
              <Text size='regular' color='surface/default'>
                A <b>check-in</b> poll to warm up the participants, based on your overall tone.
              </Text>
              <Text size='regular' color='surface/default'>
                An <b>introductory</b> poll to gauge the audience, based on the following content.
              </Text>
              <Text size='regular' color='surface/default'>
                A <b>reflective</b> poll to catch up, based on the content that precedes the poll.
              </Text>
            </View>
          }
        >
          <Icon color={color} iconId='help'></Icon>
        </Tooltip>
      </View>
    </>
  )
}

const UnselectableView = styled(View)`
  user-select: none;
`

export const PollCardAlternativeRow: SlateFC = ({ children, element, mode, readOnly }) => {
  assertElementType('poll-card-alternative-container', element)
  const editor = useSlateStatic()
  const path = usePath({ nodeId: element.id })
  const newOptionIndex = element.children.length
  const isMobile = useIsMobile()

  const direction = getPollDirection(element.children.length, isMobile)
  const preview = !useSelected()
  const { t } = useTranslation()

  const addOption = useCallback((): void => {
    Transforms.insertNodes(editor, createPollCardAlternative(), { at: [...path, newOptionIndex] })
  }, [path, editor, newOptionIndex])

  const context = useCreatePageContextSafe()

  return (
    <RenderingContext preview={preview}>
      <View contentEditable={false}>
        <InteractiveCardInstruction color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular'>
          {t('learner.poll-card.select-answer')}
        </InteractiveCardInstruction>
      </View>

      <View direction='column' gap='xsmall'>
        <AlternativesContainer $direction={direction}>{children}</AlternativesContainer>

        {!readOnly && (
          <UnselectableView justifyContent='flex-end' contentEditable={false} marginTop='xsmall'>
            {context !== undefined ? (
              <GeneratePollButton
                elementId={element.id}
                editor={editor}
                createContentId={context.createContentId}
                contentType={context.contentType}
              />
            ) : (
              <div contentEditable={false}>Generate poll button can only be on the create page</div>
            )}
            <View grow />
            <Button icon='add' onClick={addOption}>
              {t('author.slate.poll-add-option')}
            </Button>
          </UnselectableView>
        )}
      </View>
      <BottomContainer contentEditable={false}>
        {mode !== 'create' && <TotalVotes />}
        {mode === 'live' && <SeeResults />}
      </BottomContainer>
    </RenderingContext>
  )
}

const AlternativeBody = styled.div<{ direction: PollDirection }>`
  margin-bottom: ${p => (p.direction === 'row' ? spacing.xsmall : '0')};
  padding: 0.5rem;
  position: relative;
  height: fit-content;
  width: 100%;
`

const PreviewBody = styled(AlternativeBody)<{ direction: PollDirection }>`
  border: none;
  display: inline;
  flex-direction: row;
`

const PreviewPlaceholder = styled.span.attrs({ contentEditable: false })`
  padding: 0.5rem;
  top: 0;
  left: 0;
  margin: auto;
  position: absolute;
  pointer-events: none;
  user-select: none;
`

const PreviewIconContainer = styled.div.attrs({ contentEditable: false })<{ $direction: PollDirection }>`
  padding: 0.5rem;
  ${p =>
    p.$direction === 'column'
      ? css`
          margin: auto 0;
        `
      : css`
          top: 8px;
        `};
  right: 8px;
  position: absolute;
  user-select: none;
`

const ImageEl = styled.img<{ direction: PollDirection }>`
  width: 100%;
  height: 200px;
  border-radius: 4px;

  object-fit: cover;
  object-position: center center;
  background-color: ${palette.grey[5]};

  ${p =>
    p.direction === 'column' &&
    css`
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 8px;
    `}
`

const Image: React.FC<{ image: ImageUnion; direction: PollDirection; assetContext: AssetContext }> = ({
  image,
  assetContext,
  direction,
}) => {
  const src = useResolveCourseAsset({ image, assetContext, options: { type: 'course', size: 'small' } })
  return <ImageEl contentEditable={false} direction={direction} src={src} />
}

function pollDataToOptionsIdsToNumber(pollData: PollData): OptionIdsToNumber {
  const optionIdsToNumber: OptionIdsToNumber = {}

  pollData.optionResults.forEach(({ optionId, totalVotes }) => {
    optionIdsToNumber[optionId] = totalVotes
  })

  return optionIdsToNumber
}

const DISPLAYED_AVATARS = 5

const LearnerPollAlternative = React.forwardRef<
  HTMLDivElement,
  {
    pollId: string
    element: Entity<SlatePollCardAlterantive>
    attributes: SlateWrapperProps['attributes']
    children: React.ReactNode
    mode: EditorMode
  }
>(({ element, attributes, children, mode }, ref) => {
  const { pollData, userVoteId, userVoteSkipped, voteOnOption } = usePollData()
  const optionId = element.id
  const choiceData = pollData?.optionResults.find(data => data.optionId === optionId)
  const userId = useSelector(selectUserId)

  const resultPercentages = useMemo(() => {
    if (pollData === undefined) return {}

    // Calculate rounded percentages and make sure they add up to 100
    return calculateRoundedPercentages(pollDataToOptionsIdsToNumber(pollData))
  }, [pollData])

  const vote = (id: string): void => {
    if (userId !== undefined && pollData) {
      voteOnOption(id)
    }
  }
  const { image } = element

  const siblings = useSiblings({ nodeId: element.id })

  const isMobile = useIsMobile()
  const direction = getPollDirection(siblings.length, isMobile)

  const assetContext = useEditorAssetContext()
  const resolvedBackgroundImage = useResolveCourseAsset({
    image: image,
    assetContext,
    options: { type: 'course', size: 'small' },
  })
  const backgroundImageSrc = direction === 'row' && image !== undefined ? resolvedBackgroundImage : undefined

  const showResults = userVoteId !== undefined || userVoteSkipped === true
  const shouldShowInlineImage = direction === 'column' && image !== undefined
  const shouldFade =
    backgroundImageSrc !== undefined && !shouldShowInlineImage && !hasOnlyEmptyTextInNodes([element])

  const columnStyling = direction === 'column'
  const { t } = useTranslation()

  const resolvedUsers = useUsersLegacy(choiceData?.votedByUserIds.slice(0, DISPLAYED_AVATARS) ?? [])
    .filter(isDefined)
    .map(resolveUserAvatar)

  return (
    <LearnerChoice
      {...attributes}
      ref={ref}
      className={showResults ? 'results' : ''}
      onClick={() => {
        vote(optionId)
      }}
      role={userVoteId === undefined ? 'button' : undefined}
      $userVoted={userVoteId === optionId}
      $result={resultPercentages[optionId] ?? 0}
      direction={direction}
      $backgroundImageSrc={backgroundImageSrc}
      $columnStyling={columnStyling}
      transition={{ duration: 0.1 }}
      whileHover={{ scale: showResults ? 1 : 1.01, transition: { duration: 0.1 } }}
      whileTap={{ scale: showResults ? 1 : 0.99, transition: { duration: 0.1 } }}
    >
      <AlternativeOverlay
        contentEditable={false}
        $shouldFade={shouldFade || showResults}
        $backgroundImageSrc={backgroundImageSrc}
      />
      {showResults && (
        <ResultOverlay
          contentEditable={false}
          $userVoted={userVoteId === optionId}
          $result={resultPercentages[optionId] ?? 0}
          direction={direction}
        />
      )}
      {shouldShowInlineImage && <Image image={image} direction={direction} assetContext={assetContext} />}
      <VerticalPollTextContanier direction={direction}>
        <div>{children}</div>
        <Percentages contentEditable={false} direction={direction} displayResults={pollData && showResults}>
          <PollPercentageText color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='micro' bold>
            {resultPercentages[optionId]}%
          </PollPercentageText>
          <View gap='6'>
            {mode === 'live' && <AvatarStack users={resolvedUsers} size='minuscule' />}
            {choiceData?.totalVotes !== undefined &&
              (userVoteId === optionId ? (
                choiceData.totalVotes === 1 ? (
                  <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='micro' bold>
                    {_.capitalize(t('dictionary.you'))}{' '}
                  </Text>
                ) : (
                  <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='micro'>
                    {t('content.poll.you-and-n-other', { count: choiceData.totalVotes - 1 })}
                  </Text>
                )
              ) : (
                <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='micro'>
                  {t('dicitionary.n-votes', {
                    count: choiceData.totalVotes,
                  })}
                </Text>
              ))}
          </View>
        </Percentages>
      </VerticalPollTextContanier>
    </LearnerChoice>
  )
})

const CreatePreviewAlternativeContent: FCC<{
  direction: PollDirection
  backgroundImageSrc: string | undefined
  element: Entity<SlatePollCardAlterantive>
  onClickChangeImage: () => void
  numberOfSiblings: number
  isEditing: boolean
}> = ({
  direction,
  backgroundImageSrc,
  children,
  element,
  onClickChangeImage,
  numberOfSiblings,
  isEditing,
}) => {
  const { image } = element
  const { t } = useTranslation()
  const readOnly = useEditorReadOnly()
  const editor = useSlateStatic()

  const isEmpty = hasOnlyEmptyTextInNodes([element]) && image === undefined && !readOnly
  const optionNumber = (_.last(usePath({ nodeId: element.id })) ?? 0) + 1

  const shouldShowInlineImage = direction === 'column' && image !== undefined
  const shouldFade =
    backgroundImageSrc !== undefined && !shouldShowInlineImage && !hasOnlyEmptyTextInNodes([element])

  const assetContext = useEditorAssetContext()

  const items: MenuItem[] = [
    {
      id: 'change',
      type: 'label',
      label: t('author.image.change'),
      icon: 'browse',
      onClick: onClickChangeImage,
    },
    {
      id: 'remove',
      type: 'label',
      label: t('author.image.remove'),
      icon: 'trash-can',
      onClick: () => updateNodeWithId(editor, element.id, { image: undefined }),
    },
  ]

  return (
    <>
      {shouldShowInlineImage && <Image image={image} direction={direction} assetContext={assetContext} />}
      <AlternativeOverlay
        contentEditable={false}
        $shouldFade={shouldFade}
        $backgroundImageSrc={backgroundImageSrc}
      />

      <PreviewBody direction={direction}>
        {children}

        {isEmpty && (
          <PreviewPlaceholder>
            {t('author.slate.option')} {optionNumber}
          </PreviewPlaceholder>
        )}
      </PreviewBody>

      {isEditing && (
        <View alignItems='center' justifyContent='flex-start' contentEditable={false} gap='4'>
          {!image ? (
            <IconButton
              iconId='image'
              variant='primary'
              color='surface/default'
              tooltip='Add background image'
              onClick={onClickChangeImage}
            />
          ) : (
            <IconMenu
              color={direction === 'row' ? 'white' : 'surface/default'}
              variant={direction === 'row' ? 'transparent' : 'primary'}
              menuItems={items}
              iconId='no-image'
              onSelect={() => {}}
            />
          )}
          <View />
          {numberOfSiblings > 2 && (
            <IconButton
              iconId='trash-can'
              variant={image && direction === 'row' ? 'transparent' : 'primary'}
              color={image && direction === 'row' ? 'white' : 'surface/default'}
              tooltip={t('dictionary.delete')}
              onClick={() => removeNodeWithId(editor, element.id)}
            />
          )}
        </View>
      )}
    </>
  )
}

const NoPollFound = React.forwardRef<HTMLDivElement, SlateWrapperProps>(
  ({ element, attributes, children }, ref) => {
    assertElementType('poll-card-alternative', element)

    return (
      <div {...attributes} ref={ref} contentEditable={false}>
        <Debug>[DEBUG] A poll-card ancestor could not be found</Debug>
        <LoadingSpinner />
        {children}
      </div>
    )
  }
)

export const PollCardAlternative = React.forwardRef<HTMLDivElement, SlateWrapperProps>((props, ref) => {
  const { element, mode, attributes, children, readOnly } = props
  assertElementType('poll-card-alternative', element)

  const poll = useAncestorWithType({ nodeId: element.id, type: 'poll-card' })
  const { preview } = useRenderingContext()
  const [createDndElement, setCreateDndElement] = useState<HTMLDivElement | null>(null)

  const editor = useSlateStatic()
  const [showImageModal, { on, off }] = useToggle()

  const isSelected = useIsInLatestSelection({ nodeId: element.id })

  const { image } = element
  const isMobile = useIsMobile()
  const siblings = useSiblings({ nodeId: element.id })
  const direction = getPollDirection(siblings.length, isMobile)

  const assetContext = useEditorAssetContext()

  const resolvedImageSrc = useResolveCourseAsset({
    image,
    assetContext,
    options: { type: 'course', size: 'normal' },
  })
  const backgroundImageSrc = direction === 'row' && image !== undefined ? resolvedImageSrc : undefined

  const [dropIndicator, setDropIndicator] = useState<'after' | 'before'>()
  const theme = useTheme()
  const path = ReactEditor.findPath(editor, element)

  const parent = useParent({ nodeId: element.id })
  const parentId = Element.isElement(parent) ? parent.id : undefined

  const indexOfElement = siblings.indexOf(element)
  const isFirstSibling = indexOfElement === 0
  const isLastSibling = indexOfElement === siblings.length - 1
  const firstTextNodeInFront = textInNodes([element])[0]

  const [{ isDragging }, dragRef] = useDrag<PollAlternativeDragItem, void, { isDragging: boolean }>(
    () => ({
      type: DragItemTypes.PollAlternative,
      item: {
        type: DragItemTypes.PollAlternative,
        id: element.id,
        parentId: parentId,
        path: path,
        theme: theme,
        firstTextNode: firstTextNodeInFront,
        image: element.image,
        assetContext,
        styling: {
          direction: direction,
          width: createDndElement?.clientWidth,
          height: createDndElement?.clientHeight,
        },
      },
      collect: monitor => ({ isDragging: monitor.isDragging() }),
    }),
    [
      element.id,
      element.image,
      parentId,
      path,
      theme,
      firstTextNodeInFront,
      direction,
      createDndElement?.clientWidth,
      createDndElement?.clientHeight,
      assetContext,
    ]
  )

  const [{ isOver, canDrop }, dropRef] = useDrop<
    PollAlternativeDragItem,
    void,
    { isOver: boolean; canDrop: boolean }
  >(() => {
    return {
      accept: DragItemTypes.PollAlternative,
      collect: monitor => ({
        isOver: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
      }),
      hover: (item, monitor) => {
        if (!monitor.isOver({ shallow: true })) return

        const hoveringPosition = monitor.getClientOffset()
        const targetBoundingBox = createDndElement?.getBoundingClientRect()

        if (hoveringPosition !== null && targetBoundingBox !== undefined) {
          if (direction === 'column') {
            setDropIndicator(
              hoveringPosition.y > targetBoundingBox.y + targetBoundingBox.height / 2 ? 'after' : 'before'
            )
          } else {
            setDropIndicator(
              hoveringPosition.x > targetBoundingBox.x + targetBoundingBox.width / 2 ? 'after' : 'before'
            )
          }
        }
      },
      canDrop: item => {
        if (item.parentId !== parentId) return false
        if (item.id === element.id) return false

        return true
      },
      drop: (item, monitor) => {
        if (monitor.didDrop()) return

        if (dropIndicator === 'before') {
          if (isFirstSibling) return Transforms.moveNodes(editor, { at: item.path, to: path })
          if (isLastSibling) return Transforms.moveNodes(editor, { at: item.path, to: Path.previous(path) })
          if (Path.isBefore(item.path, path))
            return Transforms.moveNodes(editor, { at: item.path, to: Path.previous(path) })

          return Transforms.moveNodes(editor, { at: item.path, to: path })
        }

        if (isFirstSibling) return Transforms.moveNodes(editor, { at: item.path, to: Path.next(path) })
        if (isLastSibling) return Transforms.moveNodes(editor, { at: item.path, to: path })
        if (Path.isBefore(item.path, path)) return Transforms.moveNodes(editor, { at: item.path, to: path })

        return Transforms.moveNodes(editor, { at: item.path, to: Path.next(path) })
      },
    }
  }, [
    createDndElement,
    direction,
    parentId,
    element.id,
    dropIndicator,
    isFirstSibling,
    editor,
    path,
    isLastSibling,
  ])

  dropRef(createDndElement)

  switch (mode) {
    case 'live':
    case 'self-paced':
    case 'review':
    case 'placement-test':
    case 'recap':
      return poll === undefined ? (
        <NoPollFound {...props} ref={ref} />
      ) : (
        <LearnerPollAlternative
          attributes={attributes}
          element={element}
          pollId={poll.id}
          mode={mode}
          ref={ref}
        >
          <TextToSpeech element={element} />
          {children}
        </LearnerPollAlternative>
      )

    case 'create': {
      const showPreview = readOnly || preview === true

      return (
        <>
          <CreatePollCardAlternative
            $backgroundImageSrc={backgroundImageSrc}
            direction={direction}
            $columnStyling={direction === 'column'}
            {...attributes}
            ref={current => {
              setCreateDndElement(current)
              if (ref === null) return
              else if (typeof ref === 'function') ref(current)
              else ref.current = current
            }}
            $userVoted={false}
            $isDragging={isDragging}
            $indicateDropBorder={canDrop && isOver ? dropIndicator : undefined}
            $isSelected={isSelected}
          >
            <CreatePreviewAlternativeContent
              backgroundImageSrc={backgroundImageSrc}
              direction={direction}
              element={element}
              numberOfSiblings={siblings.length}
              onClickChangeImage={on}
              isEditing={!showPreview}
            >
              {children}
            </CreatePreviewAlternativeContent>

            {!readOnly && showPreview && (
              <PreviewIconContainer $direction={direction}>
                <DragButton
                  direction={direction}
                  hasImage={image !== undefined}
                  variant='ghost'
                  color='currentColor'
                  size='small'
                  iconId='draggable'
                  // TODO: `react-dnd` isn't maintained. Replace it with another library and remove this type assertion.
                  ref={dragRef as (el: HTMLButtonElement) => void}
                />
              </PreviewIconContainer>
            )}
          </CreatePollCardAlternative>

          {showImageModal && (
            <>
              <UploadImageModal
                open={showImageModal}
                onUploadDone={image => {
                  updateNodeWithId(editor, element.id, { image })
                  off()
                }}
                onClose={off}
                assetContext={assetContext}
              />
            </>
          )}
        </>
      )
    }
    case 'template':
    case 'version-history':
      return (
        <CreatePollCardAlternative
          $isSelected={false}
          $isDragging={false}
          $indicateDropBorder={undefined}
          $backgroundImageSrc={backgroundImageSrc}
          direction={direction}
          $columnStyling={direction === 'column'}
          {...attributes}
          ref={ref}
          $userVoted={false}
        >
          <CreatePreviewAlternativeContent
            backgroundImageSrc={backgroundImageSrc}
            direction={direction}
            element={element}
            numberOfSiblings={siblings.length}
            onClickChangeImage={() => {}}
            isEditing={false}
          >
            {children}
          </CreatePreviewAlternativeContent>
        </CreatePollCardAlternative>
      )

    default:
      assertNever(mode)
  }
})

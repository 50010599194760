import { z } from 'zod'

export const GeneralSettingsFormData = z.object({
  timezone: z.string().nullable(),
  platformName: z.string().min(4),
  platformDescription: z.string(),
  pageTitle: z.string(),
  welcomeMessage: z.string(),

  openAiResponses: z.boolean(),
  enabledNewIntegrations: z.boolean(),

  googleDrive: z.object({
    connect: z.boolean(),
    assistant: z.boolean(),
  }),
  microsoft: z.object({
    connect: z.boolean(),
    assistant: z.boolean(),
  }),

  meetSettings: z.object({
    enabled: z.boolean(),
  }),
  teamsSettings: z.object({
    enabled: z.boolean(),
  }),
  zoomSettings: z.object({
    enabled: z.boolean(),
  }),
  sanaLiveSettings: z.object({
    enabled: z.boolean(),
  }),
})

export type GeneralSettingsFormData = z.infer<typeof GeneralSettingsFormData>

import React, { useRef } from 'react'
import { resolveThemeColor, useThemes } from 'sierra-client/hooks/use-themes'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FCC } from 'sierra-client/types'
import { ColorPicker } from 'sierra-client/views/v3-author/title-card/color-picker'
import { color } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { MUIClickAwayListener, MUIPopper } from 'sierra-ui/mui'
import { Text, View } from 'sierra-ui/primitives'
import { usePrimitiveButtonPseudoStyles } from 'sierra-ui/primitives/button/button'
import { palette, spacing } from 'sierra-ui/theming'
import { legacyLight, Theme, ThemeName } from 'sierra-ui/theming/legacy-theme'
import { PseudoStyles } from 'sierra-ui/utils/pseudo-styling'
import styled, { css, ThemeProvider } from 'styled-components'

const StyledIcon = styled(Icon)`
  margin-top: 3px;
`

const ThemeIcon = styled(Icon).attrs({
  size: 'size-16',
  color: 'currentColor',
  hoverColor: 'currentColor',
})`
  color: ${p => p.theme.home.textColor};
  background-color: ${p => p.theme.home.backgroundColor};
  border: 1px solid transparent;
  border-radius: ${p => p.theme.borderRadius['size-6']};

  ${p =>
    p.theme.home.backgroundColor === palette.primitives.white &&
    css`
      border: 1px solid ${palette.grey[5]};
    `}
`

const StyledText = styled(Text)`
  padding-bottom: 5px;
  color: ${p => p.theme.color.grey40};
  border-bottom: 1px solid ${palette.grey[5]};
  font-weight: 500;
`
const Styleddiv = styled.div`
  padding: ${spacing['2']};
`

const ColorIconsWrapper = styled(View)`
  padding-bottom: 10px;
`
const StyledView = styled(View)`
  width: 224px;
  padding: 12px;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.08);
`

const StyledHeaderButton = styled.button<{ $pseudoStyles: PseudoStyles }>`
  transition: background-color 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 6px;
  height: 24px;
  background-color: white;
  padding: 4px;

  color: var(--primitive-button__foreground);

  ${p => p.$pseudoStyles.default};

  &:hover,
  &:active {
    ${p => p.$pseudoStyles.hover};
  }

  &:focus-visible {
    ${p => p.$pseudoStyles.focus};
  }

  &[disabled] {
    ${p => p.$pseudoStyles.disabled};
  }
`

const MenuButton = React.forwardRef<HTMLButtonElement, { children?: React.ReactNode; toggle: () => void }>(
  ({ children, toggle }, ref) => {
    const styles = usePrimitiveButtonPseudoStyles('primary')

    return (
      <StyledHeaderButton onClick={toggle} ref={ref} $pseudoStyles={styles}>
        {children}
      </StyledHeaderButton>
    )
  }
)

const Menu: FCC<{ theme?: Theme }> = ({ children, theme }) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  const [open, { off, toggle }] = useToggle()
  return (
    <ThemeProvider theme={theme ?? legacyLight}>
      <MUIClickAwayListener onClickAway={off}>
        <MenuButton ref={anchorRef} toggle={toggle}>
          <ThemeIcon size='size-16' iconId='text--color' />
          <Icon size='size-16' color='black' iconId={open ? 'chevron--up--small' : 'chevron--down--small'} />
          {anchorRef.current !== null && (
            <MUIPopper placement='bottom' anchorEl={anchorRef.current} open={open}>
              {children}
            </MUIPopper>
          )}
        </MenuButton>
      </MUIClickAwayListener>
    </ThemeProvider>
  )
}
export const StacksColorPicker: React.FC<{
  onMouseOver: (theme: string) => void
  onMouseOut: () => void
  onClick: (theme: string) => void
  onClose?: () => void
  selectedTheme: ThemeName | undefined
  theme: Theme
}> = ({ onMouseOut, onMouseOver, onClick, selectedTheme, theme }) => {
  const { t } = useTranslation()

  const { newPresetThemes, customThemes } = useThemes()

  return (
    <Menu theme={theme}>
      <StyledView direction='column' radius='regular' borderColor='grey5' background='white'>
        <>
          {Object.keys(customThemes).length > 0 && (
            <>
              <StyledText color='grey40' size='micro'>
                {t('author.custom')}
              </StyledText>
              <ColorIconsWrapper gap='none' wrap='wrap' direction='row'>
                {Object.entries(customThemes).map(([name, theme]) => (
                  <Styleddiv
                    key={name}
                    onMouseEnter={() => onMouseOver(name)}
                    onMouseLeave={() => onMouseOut()}
                  >
                    <ColorPicker.Color
                      key={name}
                      color={resolveThemeColor(theme.backgroundColor)}
                      isSelected={selectedTheme === name}
                      onClick={() => onClick(name)}
                    >
                      <StyledIcon iconId='text--color' color={color(theme.foregroundColor)} />
                    </ColorPicker.Color>
                  </Styleddiv>
                ))}
              </ColorIconsWrapper>
            </>
          )}
          <StyledText color='grey40' size='micro'>
            {t('author.preset')}
          </StyledText>
          <ColorIconsWrapper gap='none' wrap='wrap' direction='row'>
            {Object.entries(newPresetThemes).map(([name, theme]) => (
              <Styleddiv key={name} onMouseEnter={() => onMouseOver(name)} onMouseLeave={() => onMouseOut()}>
                <ColorPicker.Color
                  key={name}
                  color={resolveThemeColor(theme.backgroundColor)}
                  isSelected={selectedTheme === name}
                  onClick={() => onClick(name)}
                >
                  <StyledIcon iconId='text--color' color={color(theme.textColor)} />
                </ColorPicker.Color>
              </Styleddiv>
            ))}
          </ColorIconsWrapper>
        </>
      </StyledView>
    </Menu>
  )
}

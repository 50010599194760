import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { QueryKey, UseQueryOptions, UseQueryResult, useQuery, useQueryClient } from '@tanstack/react-query'
import { getOperationAST } from 'graphql'
import { GraphQLClient, RequestOptions, Variables } from 'graphql-request'
import { useCallback, useRef } from 'react'
import { getGlobalRouter } from 'sierra-client/router'
import { getLastItem } from 'sierra-client/utils/array.utils'

const GRAPHQL_ENDPOINT = '/graphql/operation' as const

const REACT_QUERY_CACHE_KEY = 'graphql' as const

const graphQLClient = new GraphQLClient(GRAPHQL_ENDPOINT, {
  method: 'POST',
  headers: { accept: 'application/json' },
  jsonSerializer: {
    parse: JSON.parse,
    stringify: JSON.stringify,
  },
  requestMiddleware: request => ({
    ...request,
    url: `${request.url}/${request.operationName}`,
  }),
})

/**
 * Use a GraphQL request.
 *
 * Query name must be unique.
 *
 * Example query without parameters:
 *
 * ```tsx
 * import { graphql } from 'sierra-client/api/graphql/gql'
 *
 * const queryResult = useGraphQuery(
 *  graphql(`
 *    query users {
 *      users(limit: 10, next: null) {
 *        data {
 *          displayName
 *        }
 *      }
 *    }
 *  `)
 * )
 * ```
 *
 * Example with query parameter:
 *
 * ```tsx
 * import { graphql } from 'sierra-client/api/graphql/gql'
 *
 * const queryResult = useGraphQuery(
 *  graphql(`
 *    query users($limit: Int!) {
 *      users(limit: $limit, next: null) {
 *        data {
 *          displayName
 *        }
 *      }
 *    }
 *  `),
 *  { limit: 1 }
 * )
 ```
 */

export const getGraphQueryKey = <A = any, V = Variables>(
  document: TypedDocumentNode<A, V>,
  variables?: V
): QueryKey => {
  const operationName = getOperationAST(document)?.name?.value
  const queryKey = [REACT_QUERY_CACHE_KEY, operationName, ...(variables !== undefined ? [variables] : [])]
  return queryKey
}

export const useInvalidateGraphQuery = <A = any, V = Variables>(
  document: TypedDocumentNode<A, V>,
  variables?: V
): (() => Promise<void>) => {
  const queryClient = useQueryClient()
  const queryKey = useRef(getGraphQueryKey(document, variables))

  return useCallback(() => queryClient.invalidateQueries({ queryKey: queryKey.current }), [queryClient])
}

export const useResetGraphQuery = <A = any, V = Variables>(
  document: TypedDocumentNode<A, V>,
  variables?: V
): (() => Promise<void>) => {
  const queryClient = useQueryClient()
  const queryKey = useRef(getGraphQueryKey(document, variables))

  return useCallback(
    () => queryClient.resetQueries({ queryKey: queryKey.current, exact: true }),
    [queryClient]
  )
}

export const requestHeaders = (): { [key: string]: string } => {
  const headers: { [key: string]: string } = {}

  headers['Client-Path'] = window.location.pathname

  const topMatchedRouteId = getLastItem(getGlobalRouter().state.matches)?.routeId
  if (topMatchedRouteId !== undefined) {
    headers['Client-Route-Id'] = topMatchedRouteId
  }

  return headers
}

export const useGraphQuery = <A = any, V = Variables, B = A>(
  {
    document,
    queryOptions = {},
  }: {
    document: TypedDocumentNode<A, V>
    queryOptions?: Omit<UseQueryOptions<A, unknown, B, QueryKey>, 'queryKey' | 'queryFn'>
  },
  variables?: V
): UseQueryResult<B, unknown> => {
  const queryKey = getGraphQueryKey(document, variables)

  const query = useQuery<A, unknown, B, QueryKey>({
    queryKey,
    queryFn: async () => {
      try {
        const options = {
          document,
          variables,
          requestHeaders: requestHeaders(),
        } as RequestOptions<Variables, A>

        const response = await graphQLClient.request(options)
        return response
      } catch (error) {
        console.error(`Error when fetching GraphQL request: ${JSON.stringify(queryKey)}`)
        console.error(error)
        throw error
      }
    },
    ...queryOptions,
  })
  return query
}

export const graphQuery = <T = any, V = Variables>(
  document: TypedDocumentNode<T, V>,
  variables?: V
): Promise<T> => {
  const options = {
    document,
    variables,
    requestHeaders: requestHeaders(),
  } as RequestOptions<Variables, T>
  return graphQLClient.request(options)
}

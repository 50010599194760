import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import React, { DragEvent, useCallback, useEffect, useId, useRef, useState } from 'react'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { logger } from 'sierra-client/logger/logger'
import { FCC } from 'sierra-client/types'
import { CancelablePromise, makeCancelablePromise } from 'sierra-client/utils/cancelable-promise'
import { CourseGenOutputControls } from 'sierra-client/views/flexible-content/generate-from-file-modal/course-gen-output-controls'
import { PdfProcessingError } from 'sierra-client/views/flexible-content/use-pdf-convert'
import {
  FileTooBigError,
  PdfUploadResult,
  PdfUploadStatus,
  StartGeneratingPdf,
  UploadMediaProgressHandler,
  UploadMediaWithProgress,
  acceptedPdfFiles,
} from 'sierra-client/views/v3-author/common/media-uploader/types'
import { OutputControls } from 'sierra-domain/api/author-v2'
import { AssetContext } from 'sierra-domain/asset-context'
import { isDefined, isNonNullable } from 'sierra-domain/utils'
import { Icon, ProgressBar } from 'sierra-ui/components'
import { LoaderAnimation, Text, View } from 'sierra-ui/primitives'
import { Button, ButtonStyles, usePrimitiveButtonPseudoStyles } from 'sierra-ui/primitives/button/button'
import { token } from 'sierra-ui/theming'
import { DesignToken } from 'sierra-ui/theming/tokens/types'
import { PseudoStyles } from 'sierra-ui/utils/pseudo-styling'
import styled, { css } from 'styled-components'

const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
`

const StyledBrowseFilesButton = styled.label<{ $grow: boolean; $pseudoStyles: PseudoStyles }>`
  ${ButtonStyles};
`

const BrowseFilesButton: FCC<{ htmlFor: string }> = ({ htmlFor, children }) => {
  const pseudoStyles = usePrimitiveButtonPseudoStyles('primary')

  return (
    <StyledBrowseFilesButton htmlFor={htmlFor} $pseudoStyles={pseudoStyles} $grow>
      {children}
    </StyledBrowseFilesButton>
  )
}

const IdleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const ErrorContainer = styled(View)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${token('surface/default')};
`

const ErrorMessage = styled(View)`
  max-width: 280px;
`

const DropZone = styled.label<{ $dragging: boolean; $background: DesignToken }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: ${p => token(p.$background)(p)};
  color: ${token('foreground/muted')};

  ${p =>
    p.$dragging === true &&
    css`
      background: ${token('org/primary')};
      color: ${token('foreground/primary')};
    `}
`

const FormInnerContainer = styled(View)`
  width: 100%;
  padding-block-end: 32px;
  padding-inline: 32px;
  gap: 32px;
`

const InstructionContainer = styled(View).attrs({
  direction: 'column',
})`
  max-width: 310px;
  margin-inline: auto;
`

const LoadingContainer = styled(View)`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${token('surface/default')};
`

const LoaderAnimationContainer = styled.div`
  margin-bottom: 24px;
`

const ProgressBarContainer = styled.div`
  width: 100%;
  max-width: 230px;
  margin-block-start: 24px;
`

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 32px;
`

const CourseGenPdfUploadFailed: React.FC<{
  fileInputDomId: string
  isDragging: boolean
  status: PdfUploadStatus & { type: 'error' }
  disclaimer: string | JSX.Element
}> = ({ fileInputDomId, isDragging, status, disclaimer }) => {
  const { t } = useTranslation()

  return (
    <ErrorContainer alignItems='center' direction='column'>
      <DropZone htmlFor={fileInputDomId} $dragging={isDragging} $background='surface/default'>
        <ErrorMessage gap='32' direction='column' alignItems='center'>
          <Icon iconId='close--circle--filled' color='destructive/background' />
          <View direction='column' gap='8'>
            <Text>{status.title}</Text>
            <Text color='foreground/muted'>{status.message}</Text>
          </View>
        </ErrorMessage>
      </DropZone>

      <FormInnerContainer direction='column' paddingTop='8'>
        <View gap='24' direction='column'>
          <BrowseFilesButton htmlFor={fileInputDomId}>
            {t('author.pdf-uploader.label.browse')}
          </BrowseFilesButton>
          <InstructionContainer>
            <Text color='foreground/muted' size='micro'>
              {disclaimer}
            </Text>
          </InstructionContainer>
        </View>
      </FormInnerContainer>
    </ErrorContainer>
  )
}

const CourseGenPdfUploading: React.FC<{
  status: PdfUploadStatus & { type: 'uploading' | 'processing' }
  handleCancelUpload: () => void
}> = ({ status, handleCancelUpload }) => {
  const { t } = useTranslation()
  return (
    <LoadingContainer direction='column' alignItems='center' justifyContent='center'>
      <LoaderAnimationContainer>
        <LoaderAnimation size={28} />
      </LoaderAnimationContainer>
      <Text>
        {status.type === 'uploading'
          ? t('author.pdf-uploader.label.uploading')
          : t('author.pdf-uploader.label.processing')}
      </Text>
      <Text color='foreground/muted' size='small'>
        {status.file.name}
      </Text>
      <ProgressBarContainer>
        <ProgressBar percent={status.progress} />
      </ProgressBarContainer>
      <ButtonContainer>
        <Button variant='secondary' onClick={handleCancelUpload}>
          {t('dictionary.cancel')}
        </Button>
      </ButtonContainer>
    </LoadingContainer>
  )
}

export const CourseGenPdfUploader = ({
  uploadFile,
  startGenerating,
  maxFileSizeMb,
  title,
  description,
  disclaimer,
  assetContext,
}: {
  uploadFile: UploadMediaWithProgress<PdfUploadResult>
  startGenerating: StartGeneratingPdf
  maxFileSizeMb: number
  title: string
  description: string
  disclaimer: string | JSX.Element
  assetContext: AssetContext
}): JSX.Element => {
  const { t } = useTranslation()
  const fileInputDomId = useId()
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [isDragging, setIsDragging] = useState(false)
  const isOutputControlsEnabled = useFlag('content-generation-output-controls')

  const [outputControls, setOutputControls] = useState<OutputControls | undefined>(undefined)
  const [status, setStatus] = useState<PdfUploadStatus>({ type: 'idle' })

  useEffect(() => {
    if (!isOutputControlsEnabled) {
      return
    }

    if (outputControls !== undefined && status.type === 'success') {
      startGenerating({ pdfId: status.pdfId, controls: outputControls })
    }
  }, [isOutputControlsEnabled, outputControls, startGenerating, status])

  const handleProgressUpdate = useCallback<UploadMediaProgressHandler>((type, value) => {
    setStatus(prev => {
      if (prev.type === 'uploading' || prev.type === 'processing') {
        return {
          ...prev,
          type,
          progress: type === 'processing' ? 50 + value * 0.5 : value * 0.5,
        }
      }
      return prev
    })
  }, [])

  const uploadFileCancelable = useCallback(
    (file: File): CancelablePromise<PdfUploadResult> => {
      return makeCancelablePromise(uploadFile(file, assetContext, handleProgressUpdate))
    },
    [handleProgressUpdate, uploadFile, assetContext]
  )

  const handleFileUpload = useCallback(
    async (file: File): Promise<void> => {
      try {
        const request = uploadFileCancelable(file)

        setStatus({ type: 'uploading', file, request, progress: 0 })

        const { pdfId } = await request.promise

        if (!isOutputControlsEnabled) {
          startGenerating({ pdfId, controls: undefined })
        }
        setStatus({ type: 'success', pdfId })
      } catch (error) {
        logger.error('Error uploading pdf', { error })

        if (error instanceof FileTooBigError) {
          setStatus({
            type: 'error',
            title: t('author.pdf-uploader.error.too-big.title'),
            message: t('author.pdf-uploader.error.too-big.message', { maxFileSizeMb }),
          })
        } else if (error instanceof PdfProcessingError) {
          setStatus({
            type: 'error',
            title: t('author.pdf-uploader.error.processing-failed.title'),
            message: t('author.pdf-uploader.error.processing-failed.message'),
          })
        } else {
          setStatus({
            type: 'error',
            title: t('author.pdf-uploader.error.random.title'),
            message: t('author.pdf-uploader.error.random.message'),
          })
        }
      }
    },
    [isOutputControlsEnabled, maxFileSizeMb, startGenerating, t, uploadFileCancelable]
  )

  const handleCancelUpload = useCallback(() => {
    if ('request' in status) {
      status.request.cancel()
    }

    if (isNonNullable(fileInputRef.current)) {
      fileInputRef.current.value = ''
    }

    setOutputControls(undefined)
    setStatus({ type: 'idle' })
  }, [status])

  const handleDragEnter = useCallback((event: DragEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragging(true)
  }, [])

  const handleDragLeave = useCallback((event: DragEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragging(false)
  }, [])

  return (
    <>
      <Form
        aria-hidden={status.type === 'uploading'}
        onDragOver={handleDragEnter}
        onDragEnter={handleDragEnter}
        onDragEnd={handleDragLeave}
        onDragLeave={handleDragLeave}
        onDrop={event => {
          event.preventDefault()
          event.stopPropagation()
          setIsDragging(false)

          const droppedFiles = Array.from(event.dataTransfer.files)
          const [file] = droppedFiles.filter(file => file.type === 'application/pdf')

          if (isDefined(file)) {
            void handleFileUpload(file)
          } else if (droppedFiles[0]) {
            setStatus({
              type: 'error',
              title: t('author.pdf-uploader.error.unsupported-format.title'),
              message: t('author.pdf-uploader.error.unsupported-format.message'),
            })
          }
        }}
      >
        <VisuallyHidden.Root>
          <input
            ref={fileInputRef}
            type='file'
            name='files[]'
            accept={acceptedPdfFiles}
            id={fileInputDomId}
            onChange={event => {
              const file = event.target.files?.[0]
              if (isDefined(file)) {
                void handleFileUpload(file)
              }
            }}
          />
        </VisuallyHidden.Root>

        <IdleContainer aria-hidden={status.type === 'error'}>
          <View grow>
            <DropZone htmlFor={fileInputDomId} $dragging={isDragging} $background='surface/soft'>
              <Icon iconId='upload' />
            </DropZone>
          </View>
          <FormInnerContainer alignItems='center' direction='column' paddingTop='40'>
            <InstructionContainer>
              <Text bold>{title}</Text>
              <Text color='foreground/muted'>{description}</Text>
            </InstructionContainer>
            <View gap='24' direction='column'>
              <BrowseFilesButton htmlFor={fileInputDomId}>
                {t('author.pdf-uploader.label.browse')}
              </BrowseFilesButton>
              <InstructionContainer>
                <Text color='foreground/muted' size='micro'>
                  {disclaimer}
                </Text>
              </InstructionContainer>
            </View>
          </FormInnerContainer>
        </IdleContainer>

        {status.type === 'error' && !isOutputControlsEnabled && (
          <CourseGenPdfUploadFailed
            fileInputDomId={fileInputDomId}
            isDragging={isDragging}
            status={status}
            disclaimer={disclaimer}
          />
        )}
      </Form>

      {isOutputControlsEnabled && outputControls === undefined ? (
        <CourseGenOutputControls
          pdfUploadStatus={status}
          setOutputControls={setOutputControls}
          handleFileUpload={handleFileUpload}
        />
      ) : status.type === 'uploading' || status.type === 'processing' ? (
        <CourseGenPdfUploading status={status} handleCancelUpload={handleCancelUpload} />
      ) : null}
    </>
  )
}

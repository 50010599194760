import { createFileRoute } from '@tanstack/react-router'

import React from 'react'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { HomeV3 } from 'sierra-client/views/learner/home/home-v3'

const HomePage: React.FC = () => {
  return <HomeV3 />
}

const RouteComponent = requireLoggedIn(HomePage)

export const Route = createFileRoute('/')({
  component: RouteComponent as React.FC,
})

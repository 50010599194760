import _ from 'lodash'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useRouterSelfPacedIdsWithFileId } from 'sierra-client/hooks/use-router-ids'
import { logger } from 'sierra-client/logger/logger'
import { getGlobalRouter } from 'sierra-client/router'
import { selectBackendNextUp, selectLinearNextUp } from 'sierra-client/state/card-progress/selectors'
import {
  selectFileIds,
  selectFlexibleContent,
  selectFlexibleContentFile,
} from 'sierra-client/state/flexible-content/selectors'
import { isFolder } from 'sierra-client/state/flexible-content/types'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { fetchCourseDataById } from 'sierra-client/state/v2/courses-actions'
import {
  getHrefToCourse,
  getHrefToNextUpAfterPendingCourse,
} from 'sierra-client/views/learner/path/get-href-to-course'
import { useNavigate } from 'sierra-client/views/self-paced/useNavigate'
import { NextUp } from 'sierra-domain/api/backend-self-paced/types'
import { CourseId, CreateContentId, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { FileId, FolderId } from 'sierra-domain/flexible-content/identifiers'
import {
  SelfPacedFile,
  fileIsSupportedInContent,
  isSelfPacedFile,
} from 'sierra-domain/flexible-content/support'
import { FlexibleContentJsonData, Folder, Node } from 'sierra-domain/flexible-content/types'
import { asNonNullable, assertNever, iife, isDefined } from 'sierra-domain/utils'

export type FlexibleContentFilesContext = {
  currentFile: SelfPacedFile | undefined
  goTo: (fileId: FileId) => void
  flexibleContentId: CreateContentId
  flexibleContent?: FlexibleContentJsonData
  fileIds: FileId[]
  getFile: (fileId: FileId) => SelfPacedFile | undefined
  rootFolder?: Folder
  currentFolder: Folder | undefined

  smartNextUp: NextUp
  linearNextUp: NextUp
  nextUp: NextUp
  goToNextUp: (next: Exclude<NextUp, { type: 'none' }>) => void

  currentReview: FolderId | undefined
  setCurrentReview: (folderId: FolderId | undefined) => void

  currentPlacementTest: FolderId | undefined
  setCurrentPlacementTest: (folderId: FolderId | undefined) => void
  startPlacementTest: (_: { folderId: FolderId; firstFileId: FileId | undefined } | undefined) => void
  prevFile: SelfPacedFile | undefined
  nextFile: SelfPacedFile | undefined
}

const FlexibleContentFilesContextObj = createContext<FlexibleContentFilesContext | undefined>(undefined)

const isSupportedNode = (node?: Node): boolean => {
  if (node === undefined) return false
  if (node.type === 'folder') return true

  if (node.type === 'file') {
    return fileIsSupportedInContent(node.data.type, 'self-paced')
  }
  return false
}

const supportedContentNodeMap = (content?: FlexibleContentJsonData): FlexibleContentJsonData | undefined => {
  if (content === undefined) return undefined

  const nodeMap = _.chain(Object.values(content.nodeMap))
    .filter(node => isSupportedNode(node))
    .compact()
    .map(node => {
      if (isFolder(node)) {
        return { ...node, nodeIds: node.nodeIds.filter(nodeId => isSupportedNode(content.nodeMap[nodeId])) }
      }
      return node
    })
    .keyBy(node => node.id)
    .value()

  return {
    nodeMap,
  }
}

function useCreateFlexibleContentFilesContext({
  flexibleContentId,
  fileId,
}: {
  flexibleContentId: SelfPacedContentId
  fileId: FileId
}): FlexibleContentFilesContext {
  const dispatch = useDispatch()
  const navigate = useNavigate({ flexibleContentId })
  const useLinearNextUp = useFlag('learn/linear-progression-in-self-paced')

  useEffect(() => {
    void dispatch(fetchCourseDataById({ courseId: CourseId.parse(flexibleContentId) }))
  }, [dispatch, flexibleContentId])

  const content = useSelector(state => selectFlexibleContent(state, flexibleContentId))
  const flexibleContent = useMemo(() => supportedContentNodeMap(content), [content])
  const rootFolder = flexibleContent?.nodeMap['folder:root']

  if (rootFolder && rootFolder.type !== 'folder') {
    throw new Error('folder:root is not a folder')
  }

  const fileIds = useSelector(state => selectFileIds(state, flexibleContentId))
  const currentReduxFile = useSelector(state => selectFlexibleContentFile(state, flexibleContentId, fileId))
  const currentFile =
    currentReduxFile === undefined || isSelfPacedFile(currentReduxFile)
      ? currentReduxFile
      : iife(() => {
          logger.captureError(new Error(`Unsupported file type in self-paced [selector]`), {
            fileType: currentReduxFile.type,
            flexibleContentId,
            fileId: currentReduxFile.id,
          })
          return undefined
        })

  useLayoutEffect(() => {
    if (!isDefined(currentFile)) {
      void getGlobalRouter().navigate({ to: `/s/${flexibleContentId}` })
    }
  }, [currentFile, flexibleContentId])

  const goTo = useCallback(
    (fileId: FileId) => {
      if (fileIds.includes(fileId)) navigate(asNonNullable(fileId))
    },
    [fileIds, navigate]
  )

  const currentFolder = useMemo((): Folder | undefined => {
    if (currentFile === undefined) return undefined
    const folder = Object.values(flexibleContent?.nodeMap ?? {}).find(
      node => isFolder(node) && node.nodeIds.includes(currentFile.id)
    )

    if (folder?.type === 'folder') return folder
    return undefined
  }, [flexibleContent, currentFile])

  const getFile = useCallback(
    (fileId?: FileId): SelfPacedFile | undefined => {
      if (fileId === undefined) return undefined
      const nodeMap = flexibleContent?.nodeMap
      if (nodeMap === undefined) return undefined

      const node = nodeMap[fileId]
      if (node === undefined) return undefined

      if (node.type !== 'file') throw new Error(`${fileId} is not a file, got ${node.type}`)

      if (!isSelfPacedFile(node)) {
        logger.captureError(new Error(`Unsupported file type in self-paced [getFile]`), {
          fileType: node.type,
          flexibleContentId,
          fileId: node.id,
        })
        return undefined
      }

      return node
    },
    [flexibleContent?.nodeMap, flexibleContentId]
  )

  const backendNextUp = useSelector(state => selectBackendNextUp(state, flexibleContentId))

  const linearNextUp = useSelector(state => selectLinearNextUp(state, flexibleContentId))(currentFile?.id)

  const [currentReview, setCurrentReview] = useState<FolderId | undefined>(undefined)
  const [currentPlacementTest, setCurrentPlacementTest] = useState<FolderId | undefined>(undefined)

  // Should navigate to first card in the placement test folder and then set the placement test
  const startPlacementTest: FlexibleContentFilesContext['startPlacementTest'] = useCallback(nextUp => {
    setCurrentPlacementTest(nextUp?.folderId)
  }, [])

  const goToNextUp = useCallback<FlexibleContentFilesContext['goToNextUp']>(
    next => {
      switch (next.type) {
        case 'next-course-in-path':
        case 'next-course-in-program': {
          const href = getHrefToCourse({
            course: next.course,
            pathId: next.pathId,
            programId: next.programId,
            skipCourseOverview: true,
          })

          void getGlobalRouter().navigate({ to: href })
          break
        }
        case 'course-completed':
        case 'program-completed':
        case 'program-pending':
          void getGlobalRouter().navigate({ to: '/' })
          break
        case 'review':
          setCurrentReview(next.folderId)
          break
        case 'placement-test':
          startPlacementTest({ folderId: next.folderId, firstFileId: next.firstFileId })
          break
        case 'file':
          goTo(next.fileId)
          break
        case 'course-pending': {
          const href = getHrefToNextUpAfterPendingCourse({ pendingCourse: next })
          void getGlobalRouter().navigate({ to: href })
          break
        }
        default:
          assertNever(next)
      }
    },
    [startPlacementTest, goTo]
  )

  const { prevFile, nextFile } = useMemo(() => {
    const currentIndex = fileIds.findIndex(fileId => fileId === currentFile?.id)

    const prevFileId = fileIds[currentIndex - 1]
    const nextFileId = fileIds[currentIndex + 1]

    return {
      prevFile: prevFileId !== undefined ? getFile(prevFileId) : undefined,
      nextFile: nextFileId !== undefined ? getFile(nextFileId) : undefined,
    }
  }, [fileIds, getFile, currentFile?.id])

  const nextUp = useMemo(
    () => (useLinearNextUp ? linearNextUp : backendNextUp),
    [linearNextUp, backendNextUp, useLinearNextUp]
  )

  return useMemo(
    () => ({
      currentFile,
      goTo,
      getFile,
      flexibleContentId,
      flexibleContent,
      rootFolder,
      fileIds,
      currentFolder,
      smartNextUp: backendNextUp,
      linearNextUp,
      nextUp,
      goToNextUp,
      currentReview,
      setCurrentReview,
      currentPlacementTest,
      setCurrentPlacementTest,
      startPlacementTest,
      prevFile,
      nextFile,
    }),
    [
      currentFile,
      goTo,
      flexibleContentId,
      getFile,
      flexibleContent,
      rootFolder,
      fileIds,
      currentFolder,
      backendNextUp,
      linearNextUp,
      nextUp,
      goToNextUp,
      currentReview,
      setCurrentReview,
      currentPlacementTest,
      setCurrentPlacementTest,
      startPlacementTest,
      prevFile,
      nextFile,
    ]
  )
}

const ResolveFlexibleContentFilesContext: React.FC<{
  flexibleContentId: SelfPacedContentId
  fileId: FileId
  onResolved: (context: FlexibleContentFilesContext | undefined) => void
}> = ({ flexibleContentId, fileId, onResolved }) => {
  const value = useCreateFlexibleContentFilesContext({ flexibleContentId, fileId })

  useEffect(() => {
    onResolved(value)
    return () => onResolved(undefined)
  }, [value, onResolved])

  return null
}

export const SelfPacedFilesProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const ids = useRouterSelfPacedIdsWithFileId()

  const [context, setContext] = useState<FlexibleContentFilesContext | undefined>(undefined)
  const value = context?.flexibleContentId === ids?.flexibleContentId ? context : undefined

  const content = useSelector(state => selectFlexibleContent(state, ids?.flexibleContentId))

  return (
    <FlexibleContentFilesContextObj.Provider value={value}>
      {/* Only create the value for this context on the self-paced page (but not on the /s/[id]/next route) */}
      {isDefined(ids) && isDefined(content) && (
        <ResolveFlexibleContentFilesContext
          flexibleContentId={ids.flexibleContentId}
          fileId={ids.fileId}
          key={ids.flexibleContentId}
          onResolved={setContext}
        />
      )}
      {children}
    </FlexibleContentFilesContextObj.Provider>
  )
}

export const useSelfPacedFiles = (): FlexibleContentFilesContext => {
  const context = useContext(FlexibleContentFilesContextObj)

  if (context === undefined) {
    throw new Error('This component must be wrapped in a FlexibleContentFilesProvider')
  }

  return context
}

export const useSelfPacedFilesSafe = (): FlexibleContentFilesContext | undefined => {
  return useContext(FlexibleContentFilesContextObj)
}

import React from 'react'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { ColorPicker } from 'sierra-client/views/v3-author/title-card/color-picker'
import { color } from 'sierra-ui/color'
import { RgbaString } from 'sierra-ui/color/types'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StyledView = styled(View)`
  margin-top: 0.5rem;
  padding: 0.75rem;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
`

export const stickyNoteColors: RgbaString[] = (
  [
    'orangePastel',
    'yellowPastel',
    'greenPastel',
    'bluePastel',
    'purplePastel',
    'pinkPastel',
    'redPastel',
  ] as const
).map(c => color(c).toRgbaString())

export const StickyNotesColorPicker: React.FC<{
  className?: string
  onMouseOver: (color: string) => void
  onMouseOut: (color: string) => void
  onClick: (color: string) => void
  onClose?: () => void
  selectedColor: string
}> = ({ className, onClose, onMouseOut, onMouseOver, onClick, selectedColor }) => {
  const [isOpen, { off: _close, toggle: toggleOpen }] = useToggle()

  const close = (): void => {
    _close()
    onClose?.()
  }

  return (
    <div className={className} contentEditable={false}>
      <ColorPicker.AlternativeMenu
        selectedColor={selectedColor}
        isOpen={isOpen}
        close={close}
        toggleOpen={toggleOpen}
      >
        <StyledView radius='regular' borderColor='grey5' background='white'>
          {stickyNoteColors.map(color => (
            <ColorPicker.Color
              key={color}
              color={color}
              isSelected={selectedColor === color}
              onMouseOver={() => onMouseOver(color)}
              onMouseOut={() => onMouseOut(color)}
              onClick={() => {
                onClick(color)
                close()
              }}
            />
          ))}
        </StyledView>
      </ColorPicker.AlternativeMenu>
    </div>
  )
}

import React from 'react'
import { RouterLink } from 'sierra-client/components/common/link'
import { LearnEntityDetail } from 'sierra-client/components/recommendations/components/learn-entity-detail'
import { RecommendationContentIcon } from 'sierra-client/components/recommendations/components/recommendation-content-icon'
import { RecentlyVisitedContent } from 'sierra-client/editor/utils/recently-visited/types'
import { useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { LastUpdateLabel } from 'sierra-client/views/workspace/components/content-table-row-components'
import { AssetContext } from 'sierra-domain/asset-context'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { TruncatedText } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Img = styled.img`
  width: auto;
  height: 48px;
  object-fit: cover;
  border-radius: 18px;
  aspect-ratio: 16/9;
`

const Metadata: React.FC<{ entity: RecentlyVisitedContent }> = ({ entity }) => {
  if (entity.kind === 'learner') {
    return <LearnEntityDetail type='none' entity={entity.content} />
  } else {
    const content = entity.content
    return (
      <>
        <RecommendationContentIcon type={entity.content.type} color='foreground/secondary' />
        <LastUpdateLabel
          lastEditorId={content.lastEditedBy}
          updatedAt={content.timestamp}
          color='foreground/primary'
        />
      </>
    )
  }
}

export const RecentlyVisited: React.FC<{ entity: RecentlyVisitedContent }> = ({ entity }) => {
  const assetContext: AssetContext = {
    type: 'course' as const,
    courseId: ScopedCreateContentId.extractId(entity.entry.scopedId),
  }
  const imgSrc = useResolveCourseAsset({
    image: entity.content.image,
    options: { type: 'default', size: 'normal' },
    assetContext,
  })
  return (
    <View gap='12'>
      <RouterLink href={entity.href}>
        <Img src={imgSrc} />
      </RouterLink>
      <View gap='2' direction='column'>
        <RouterLink href={entity.href}>
          <TruncatedText lines={1} size='regular' bold={true}>
            {entity.content.title}
          </TruncatedText>
        </RouterLink>

        <View gap='4'>
          <Metadata entity={entity} />
        </View>
      </View>
    </View>
  )
}

import React, { useCallback, useMemo, useState } from 'react'
import { WIDGET_BUILDER_TRANSITION } from 'sierra-client/features/insights/widget-builder/data-selectors/animation-constants'
import { MenuItemWithRef } from 'sierra-client/features/insights/widget-builder/data-selectors/single-dimension-selector'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { domainRefHash } from 'sierra-client/lib/filter'
import { Context } from 'sierra-client/lib/filter/components/common'
import {
  appendFilter,
  isFilterEqual,
  singleFilterInit,
} from 'sierra-client/lib/filter/components/filter-utils'
import {
  FilterGenerationState,
  GenerateFilterInput,
  generateFilterMenuItem,
} from 'sierra-client/lib/filter/components/generate-filter-input'
import { FilterRoot } from 'sierra-client/lib/filter/components/root'
import { MenuItemWithDomainRep } from 'sierra-client/lib/filter/components/types'
import { FilterDomain, areDimensionRefsEqual } from 'sierra-domain/api/insights'
import { Filter, andAll, getNumberOfFilters } from 'sierra-domain/filter/datatype/filter'
import { filterEmptyFilters } from 'sierra-domain/filter/transport'
import { isDefined } from 'sierra-domain/utils'
import { Button, IconButton, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

// Used to create menu items that can be used with our current filter setup
const decorateWithDomainRep = (
  menuItems: MenuItemWithRef[],
  availableFilters?: FilterDomain[]
): MenuItemWithDomainRep[] => {
  return menuItems
    .flatMap(it => {
      if (it.type === 'nested') {
        return {
          ...it,
          menuItems: decorateWithDomainRep(it.menuItems, availableFilters),
        }
      } else {
        const ref = it.ref
        if (ref === undefined) {
          return it
        }
        const filter = availableFilters?.find(filter => areDimensionRefsEqual(filter.ref, ref))
        if (filter === undefined) {
          return it
        }
        return {
          ...it,
          id: domainRefHash(filter.ref),
          value: filter,
        }
      }
    })
    .filter(isDefined)
}

export const FilterSelector: React.FC<{
  selectedFilter?: Filter
  onChange: (newFilter: Filter) => void
  filterDomains?: FilterDomain[]
  menuItems: MenuItemWithRef[]
  generateFilter?: (query: string) => Promise<Filter | undefined>
  enableResetFilter?: {
    initialFilter: Filter
  }
}> = ({ onChange, selectedFilter, filterDomains, menuItems, generateFilter, enableResetFilter }) => {
  const { t } = useTranslation()

  const [filterGenerationState, setFilterGenerationState] = useState<FilterGenerationState>({
    type: 'closed',
  })
  const aiFilterFeatureEnabled = useFlag('analytics/ai-filter-generation')

  const filter = useMemo<Filter>(() => selectedFilter ?? andAll([]), [selectedFilter])
  const hasFilters = getNumberOfFilters(filter) > 0
  const showAiFilter = aiFilterFeatureEnabled && generateFilter !== undefined

  const realMenuItems = useMemo(() => {
    const items = [
      showAiFilter ? generateFilterMenuItem : undefined,
      ...decorateWithDomainRep(menuItems, filterDomains),
    ]

    //TODO(tomas): Fix this when you are back
    return items.filter(isDefined) as MenuItemWithDomainRep[]
  }, [filterDomains, showAiFilter, menuItems]) satisfies MenuItemWithDomainRep[]

  const ctx = useMemo<Context>(
    () => ({
      menuItems: realMenuItems, // Overrides internal menu item structure to make sure we get the exact same selections
      domainReps: filterDomains ?? [],
      update: modify => onChange(filterEmptyFilters(modify(selectedFilter ?? andAll([])), false)),
      remove: () => onChange(andAll([])),
    }),
    [filterDomains, realMenuItems, onChange, selectedFilter]
  )

  const handleChange = useCallback(
    (item: MenuItemWithRef) => {
      if (item.id === generateFilterMenuItem.id) {
        setTimeout(() => {
          setFilterGenerationState({ type: 'writing' })
        }, 300)
      } else if (item.ref !== undefined) {
        const filterRef = item.ref
        const domainRep = filterDomains?.find(it => areDimensionRefsEqual(it.ref, filterRef))

        if (domainRep !== undefined) {
          // Unfortunately we need a timeout here to wait for the dropdown to close, which in turn sets focus on the dropdown trigger
          // If we dont wait we will open the filter selector and that will be closed by the shift in focus...
          setTimeout(() => {
            ctx.update(f => appendFilter(f, singleFilterInit(domainRep)))
          }, 300)
        }
      }
    },
    [ctx, filterDomains]
  )

  const onDeleteAll = useCallback(() => {
    ctx.remove()
  }, [ctx])

  const onReset = useCallback(() => {
    if (enableResetFilter === undefined) return
    ctx.update(() => enableResetFilter.initialFilter)
  }, [ctx, enableResetFilter])

  const generateAndUpdateFilter = useCallback(
    async (query: string): Promise<Filter | undefined> => {
      if (generateFilter === undefined) return Promise.reject('No filter generation function')

      const filter = await generateFilter(query)

      if (filter === undefined) return undefined

      ctx.update(() => filter)
      return filter
    },
    [generateFilter, ctx]
  )

  return (
    <>
      <FilterRoot ctx={ctx} filter={filter} assetContext={{ type: 'unknown' }}>
        {showAiFilter && (
          <GenerateFilterInput
            filterGenerationState={filterGenerationState}
            setFilterGenerationState={setFilterGenerationState}
            generateFilter={generateAndUpdateFilter}
          />
        )}
        <SingleSelectDropdown
          disabled={filterDomains === undefined}
          withSearch={filterDomains !== undefined && filterDomains.length > 8}
          searchPlaceholder={t('menu.search.placeholder')}
          menuItems={realMenuItems}
          onSelect={handleChange}
          renderTrigger={() =>
            hasFilters || filterGenerationState.type !== 'closed' ? (
              <IconButton iconId='add' variant='secondary' aria-label={t('user-filter.add-filter')} />
            ) : (
              <Button
                variant='secondary'
                grow
                icon='add'
                disabled={filterDomains === undefined || filterDomains.length === 0}
              >
                {t('user-filter.add-filter')}
              </Button>
            )
          }
        />
        {hasFilters && (
          <View
            variants={{ 'hide': { opacity: 0 }, 'show-rest': { opacity: 1 } }}
            animated
            transition={WIDGET_BUILDER_TRANSITION}
          >
            <IconButton
              iconId='checkbox--cross'
              variant='transparent'
              size='small'
              tooltip={t('user-filter.remove-filter')}
              onClick={onDeleteAll}
            />
          </View>
        )}
        {enableResetFilter !== undefined && !isFilterEqual(enableResetFilter.initialFilter, filter) && (
          <View>
            <IconButton
              iconId='reset'
              variant='transparent'
              size='small'
              tooltip={t('user-filter.reset-filter')}
              onClick={onReset}
            />
          </View>
        )}
      </FilterRoot>
    </>
  )
}

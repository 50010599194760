import React from 'react'
import { RouterLink } from 'sierra-client/components/common/link'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  ContentClassification,
  ContentItem,
  getContentClassificationData,
} from 'sierra-client/views/manage/content/utils/content-utils'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { Icon, Label, LabelProps, Tooltip, TruncatedText } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type BaseContentProps = Pick<ContentItem, 'contentType' | 'courseKind' | 'isCourseEdition'>

const classificationToOtherLabelProps: Record<
  ContentClassification,
  Pick<LabelProps, '$bgColor' | '$borderColor' | '$color'>
> = {
  path: {
    $bgColor: 'purpleVivid',
    // $borderColor: 'purpleVivid',
    $color: 'white',
  },
  live: {
    $bgColor: 'redVivid',
    // $borderColor: 'redVivid',
    $color: 'white',
  },
  external: {
    $bgColor: 'white',
    $borderColor: 'grey10',
    $color: 'grey90',
  },
  course: {
    $bgColor: 'grey5',
    // $borderColor: 'grey5',
    $color: 'grey90',
  },
}

export const ContentLabels: React.FC<BaseContentProps> = props => {
  const { t } = useTranslation()

  const { classification, iconId, translationKey } = getContentClassificationData(props)
  const otherLabelProps = classificationToOtherLabelProps[classification]

  return (
    <Label $size='small' iconId={iconId} {...otherLabelProps}>
      {t(translationKey)}
    </Label>
  )
}

const UnderscoreOnHoverA = styled.a`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

type ContentRowProps = BaseContentProps & {
  image?: ImageUnion
  title?: string
  metadataAddon?: React.ReactNode
  titleAddon?: React.ReactNode
  pillAddon?: React.ReactNode
  href?: string
  options?: {
    hideImage?: boolean
    hidePill?: boolean
  }
  language?: string | undefined
  assetContext: AssetContext
}

const FlexRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
`

export const ContentRow: React.FC<ContentRowProps> = ({
  image,
  isCourseEdition,
  title,
  contentType,
  courseKind,
  metadataAddon,
  pillAddon,
  titleAddon,
  href,
  options = {},
  language,
  assetContext,
}) => {
  const { t } = useTranslation()

  const imgSrc = useResolveCourseAsset({ image, options: { type: 'admin', size: 'small' }, assetContext })

  const { iconId, translationKey } = getContentClassificationData({
    contentType,
    courseKind,
    isCourseEdition,
  })

  const titleElementText = (
    <Tooltip title={title}>
      <TruncatedText lines={2} size='small' bold as='span'>
        {title ?? ''}
      </TruncatedText>
    </Tooltip>
  )

  return (
    <View alignItems='flex-start' gap='xsmall'>
      {options.hideImage !== true && <Thumbnail image={imgSrc} />}
      <View direction='column' gap='none' marginTop='xxsmall'>
        <FlexRow>
          {href === undefined ? (
            titleElementText
          ) : (
            <View direction='column' gap='6'>
              <RouterLink href={href}>
                <UnderscoreOnHoverA>{titleElementText}</UnderscoreOnHoverA>
              </RouterLink>
              {language !== undefined && (
                <Text color='foreground/muted' size='small'>
                  {language}
                </Text>
              )}
            </View>
          )}
          {titleAddon}
        </FlexRow>
        <View gap='4'>
          {options.hidePill !== true && (
            <>
              <Icon iconId={iconId} color='foreground/muted' size='size-14' />
              <Text size='small' color='foreground/muted'>
                {t(translationKey)}
              </Text>
            </>
          )}
          {pillAddon}
        </View>
        {metadataAddon}
      </View>
    </View>
  )
}

import { File, UrlType } from 'sierra-domain/flexible-content/types'
import { CustomElement } from 'sierra-domain/v3-author'
import { IconId } from 'sierra-ui/components'

const embedTypeToIconIdRecord: Record<UrlType, IconId> = {
  airtable: 'airtable-logo',
  figma: 'figma-logo',
  drive: 'drive-logo',
  loom: 'loom-logo',
  miro: 'miro-logo',
  pitch: 'pitch-logo',
  trello: 'trello-logo',
  youtube: 'youtube-logo',
  vimeo: 'vimeo-logo',
}

export const embedTypeToIconId = (urlType: UrlType): IconId => embedTypeToIconIdRecord[urlType]

const fileTypeToIconIdRecord: Record<File['data']['type'], IconId> = {
  'slate-card': 'edit',
  'bullet': 'layout--right-block',
  'live-lobby': 'grid',
  'reflections': 'edit',
  'poll': 'poll',
  'sliding-scale': 'data-scale',
  'flip-cards': 'touch',
  'video': 'play--outline',
  'image': 'image',
  'general': 'book',
  'notepad': 'notebook',
  'external-notepad': 'notebook',
  'question-card': 'help',
  'assessment-card': 'assessment',
  'breakout-room': 'user--group',
  'embed': 'code',
  'sticky-notes': 'sticky-notes',
  'homework': 'task',
  'project-card': 'checkmark--filled',
  'drop-a-word': 'arrow--down',
  'stupid-questions': 'chat',
  'scenario': 'skill--theatermasks',
  // @deprecated
  'roleplay': 'error--stop',
}

export const fileTypeToIconId = (fileType: File['data']['type']): IconId => fileTypeToIconIdRecord[fileType]

export const elementTypeToIconId = (elementType: CustomElement['type']): IconId => {
  switch (elementType) {
    case 'takeaways':
      return 'checkbox--checked'
    case 'question-card':
    case 'question-card-free-text-body':
    case 'question-card-match-the-pairs-alternative':
    case 'question-card-match-the-pairs-alternative-option':
    case 'question-card-match-the-pairs-body':
    case 'question-card-multiple-choice-alternative':
    case 'question-card-multiple-choice-alternative-option':
    case 'question-card-multiple-choice-alternative-explanation':
    case 'question-card-pick-the-best-option-body':
    case 'question-card-select-all-that-apply-body':
    case 'question-variations':
    case 'assessment-question':
      return 'help'
    default:
      return 'touch--filled'
  }
}

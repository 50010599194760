import { UseQueryResult } from '@tanstack/react-query'
import { CachedQueryOptions, useCachedQuery } from 'sierra-client/state/api'
import { RecommendationsResponse } from 'sierra-domain/api/recommendations'
import { XRealtimeStrategyRecommend2 } from 'sierra-domain/routes'

export const recommendationQueryOptions = {
  // Because of the refetchOnWindowFocus setting, these queries can end up running pretty often.
  // Since they can be a bit expensive on the backend we'll set a stale time to reduce the
  // issue a bit.
  staleTime: 1 * 60 * 1000, // 1 min (? what makes sense here? Can probably go pretty high)
  refetchOnMount: 'always', // We always want to refetch if navigating away and back
} satisfies CachedQueryOptions

export function useRecommendations({
  enabled = true,
}: {
  enabled?: boolean
} = {}): UseQueryResult<RecommendationsResponse> {
  return useCachedQuery(
    XRealtimeStrategyRecommend2,
    { limit: 20 },
    { ...recommendationQueryOptions, enabled }
  )
}

import _ from 'lodash'
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { isEditableYjsEditor } from 'sierra-client/editor'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { useSelector } from 'sierra-client/state/hooks'
import { selectQuestionCards } from 'sierra-client/state/self-paced/selectors'
import { useCreatePageContextSafe } from 'sierra-client/views/flexible-content/create-page-context'
import { useFileContext, useSafeFileContext } from 'sierra-client/views/flexible-content/file-context'
import {
  Assessments,
  AssessmentsContext,
  useAssessmentContextUnsafe,
} from 'sierra-client/views/v3-author/assessment-card/assessment-context'
import {
  insertNodeAfterNodeWithId,
  pathsByElementId,
  replaceNodeWithId,
} from 'sierra-client/views/v3-author/command'
import { useIsInPdfExport } from 'sierra-client/views/v3-author/export-pdf/use-is-pdf-export'
import { useAncestorEntryWithType, useAncestorWithType, useParent } from 'sierra-client/views/v3-author/hooks'
import { assertElementType, isElementType } from 'sierra-client/views/v3-author/queries'
import { DropdownQuestionType } from 'sierra-client/views/v3-author/question-card/dropdown-question-type'
import {
  QuestionCardDataProvider,
  useQuestionCardData,
} from 'sierra-client/views/v3-author/question-card/question-card-data-layer'
import { useGenerateQuestionCard } from 'sierra-client/views/v3-author/question-card/use-generate-question-card'
import { getAlternativeStatus } from 'sierra-client/views/v3-author/question-card/utils'
import { ReactQuestionVariationsContext } from 'sierra-client/views/v3-author/question-card/variations'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { assert, assertIsNonNullable, assertNever, isDefined } from 'sierra-domain/utils'
import { CustomElement, QuestionCardBody, SanaEditor } from 'sierra-domain/v3-author'
import {
  createQuestionCard,
  createQuestionCardMatchThePairsAlternative,
  createQuestionCardMultipleChoiceAlternative,
  createQuestionVariations,
} from 'sierra-domain/v3-author/create-blocks'
import { deriveTheme } from 'sierra-ui/color'
import { Icon, IconId } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import { DesignToken } from 'sierra-ui/theming/tokens/types'
import { Editor, Element, Transforms } from 'slate'
import { useSelected, useSlateStatic } from 'slate-react'
import styled from 'styled-components'

const HiddenButton = styled(Button)`
  opacity: 0;
  pointer-events: none;
`

export const MarginContainer = styled.div`
  margin-top: 1rem;
`

export const QuestionCardBodyContainer = React.forwardRef<HTMLDivElement, SlateWrapperProps>(
  ({ children, readOnly, ...rest }, ref) => {
    const preview = !useSelected()

    return (
      <div {...rest} ref={ref}>
        <RenderingContext
          withGrid={false}
          preventDrag={true}
          allowBlockComments={false}
          disableMenu={true}
          preview={preview}
        >
          {children}
        </RenderingContext>
      </div>
    )
  }
)

const KeyboardShortcutText = styled.span`
  opacity: 0.4;
  ${fonts.body.small};
  color: ${p => deriveTheme(p.theme).secondaryTextColor};
`

const Label = styled.div.attrs({ contentEditable: false })`
  user-select: none;
  color: ${p => deriveTheme(p.theme).textColor};
  opacity: 0.75;
  font-weight: ${fonts.weight.medium};
  ${fonts.body.small};
  margin-bottom: 1rem;

  font-size: 0.875rem;
  font-weight: 500;
`

type SubmitRetryButtonWrapperProps = {
  $isHidden?: boolean
}
const SubmitRetryButtonWrapper = styled(View).attrs({
  direction: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
})<SubmitRetryButtonWrapperProps>`
  ${p => p.$isHidden === true && `opacity: 0; pointer-events: none;`}
`

const AssessmentsContinueButton: React.FC<{ assessmentContext: AssessmentsContext }> = ({
  assessmentContext,
}) => {
  const { t } = useTranslation()
  const questionCardsInRedux = useSelector(selectQuestionCards)()

  const assessmentState = assessmentContext.state

  if (questionCardsInRedux === undefined || assessmentState.status !== 'during') return null

  const finishedAssessmentQuestionIndices = assessmentState.questionExerciseIds.map(exerciseId => {
    const responses = questionCardsInRedux[exerciseId]?.responses
    if (responses === undefined) return false
    return Object.values(responses).some(it => it.sessionId === assessmentState.sessionId)
  })

  const nextUnfinishedQuestionIndex = finishedAssessmentQuestionIndices.indexOf(false)

  const numberOfUnfinishedQuestions = finishedAssessmentQuestionIndices.filter(it => it === false).length

  const isOnLastQuestion = assessmentState.currentIndex === assessmentState.questionCount - 1

  const hasAnsweredAllQuestions = nextUnfinishedQuestionIndex === -1

  const nextQuestionIndex = assessmentState.currentIndex + 1

  return (
    <>
      <Button
        variant={hasAnsweredAllQuestions || isOnLastQuestion ? 'primary' : 'secondary'}
        disabled={isOnLastQuestion && !hasAnsweredAllQuestions}
        onClick={async () => {
          const nextState = hasAnsweredAllQuestions
            ? await Assessments.evaluate(assessmentContext.state)
            : Assessments.goTo(assessmentContext.state, nextQuestionIndex)

          assessmentContext.setState(nextState)
        }}
      >
        {hasAnsweredAllQuestions || isOnLastQuestion
          ? t('assessment-card.correct-my-answers')
          : t('dictionary.continue')}
      </Button>
      {isOnLastQuestion && !hasAnsweredAllQuestions && (
        // <Text>Answer {numberOfUnfinishedQuestions} more questions to move on</Text>
        <Text>
          <Trans
            i18nKey={'assessment-card.number-of-questions-left' satisfies TranslationKey}
            values={{
              count: numberOfUnfinishedQuestions,
            }}
            components={{
              wrapper: (
                <strong
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const nextState = Assessments.goTo(assessmentContext.state, nextUnfinishedQuestionIndex)

                    assessmentContext.setState(nextState)
                  }}
                />
              ),
            }}
          />
        </Text>
      )}
    </>
  )
}

const SubmitRetryButton: React.FC = () => {
  const { t } = useTranslation()
  const {
    retry,
    disableRetry,
    questionCardData: { evaluation, isValidResponse },
    submitResponse,
    questionId,
  } = useQuestionCardData()

  const [loading, setLoading] = useState(false)
  const [isEvaluating, setIsEvaluating] = useState(false)
  const assessmentContext = useAssessmentContextUnsafe()

  const currentAssessmentQuestionId =
    assessmentContext?.state.status === 'during'
      ? assessmentContext.state.questionExerciseIds[assessmentContext.state.currentIndex]
      : undefined

  const action = useStableFunction(async () => {
    if (loading) return

    setLoading(true)

    try {
      switch (evaluation) {
        case false: {
          // Retry if response was submitted and incorrect.
          if (disableRetry !== true) {
            retry()
          }
          break
        }
        case true: {
          // Do nothing if there's been a correct response already.
          break
        }
        case undefined: {
          // Submit response if no evaluation has been made yet.
          assert(isValidResponse, 'Cannot submit an invalid response')
          await submitResponse()
          break
        }
        default:
          assertNever(evaluation)
      }
    } finally {
      setLoading(false)
    }
  })

  useEffect(() => {
    if (evaluation !== undefined) {
      setIsEvaluating(false)
    }
  }, [evaluation])

  useEffect(() => {
    const enterListener = (event: KeyboardEvent): void => {
      if (event.key === 'Enter') {
        // Don't try to submit if the response is invalid
        if (!isValidResponse) {
          return
        }

        if (assessmentContext) {
          // If we're in assessments, all questions are rendered; only call the listener for the current question
          if (questionId !== currentAssessmentQuestionId) {
            return
          }

          // Only call if we haven't already submitted
          if (evaluation !== undefined) {
            return
          }

          // If it's already been called
          if (isEvaluating) {
            return
          }

          setIsEvaluating(true)
        }

        void action()
      }
    }

    window.addEventListener('keypress', enterListener)

    return () => {
      window.removeEventListener('keypress', enterListener)
    }
  }, [
    action,
    assessmentContext,
    currentAssessmentQuestionId,
    evaluation,
    isEvaluating,
    isValidResponse,
    questionId,
  ])

  return (
    <>
      <SubmitRetryButtonWrapper $isHidden={evaluation === true && assessmentContext === undefined}>
        {assessmentContext !== undefined && evaluation !== undefined && (
          <AssessmentsContinueButton assessmentContext={assessmentContext} />
        )}

        {evaluation === false && disableRetry !== true && assessmentContext === undefined && (
          <Button variant='secondary' loading={loading} onClick={action} icon='restart'>
            {t('author.slate.retry')}
          </Button>
        )}

        {
          evaluation !== undefined && (
            <HiddenButton>No action</HiddenButton>
          ) /* Empty button to keep the space when no action is available */
        }

        {evaluation === undefined && (
          <>
            <Button
              variant='secondary'
              disabled={loading || !isValidResponse}
              loading={loading}
              onClick={action}
            >
              {t('author.slate.submit')}
            </Button>
            <KeyboardShortcutText>{t('author.slate.press-enter-to-continue')}</KeyboardShortcutText>
          </>
        )}
      </SubmitRetryButtonWrapper>
    </>
  )
}

const EvaluationStatusPill = styled.div<{ $background: DesignToken }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p => token(p.$background)};

  padding: 8px 14px 8px 12px;
  border-radius: 16px;
  gap: 8px;
`

const EvaluationStatus: FC<{ status: 'success' | 'fail' }> = ({ status }) => {
  const { t } = useTranslation()

  const icon: IconId = status === 'success' ? 'checkmark--filled' : 'close--circle--filled'
  const color = status === 'success' ? 'success/foreground' : 'destructive/foreground'
  const background = status === 'success' ? 'success/background' : 'destructive/background'

  return (
    <EvaluationStatusPill $background={background}>
      <Icon color={color} iconId={icon} />
      <Text size='micro' color={color}>
        {t(status === 'success' ? 'author.slate.correct' : 'author.slate.incorrect')}
      </Text>
    </EvaluationStatusPill>
  )
}

const GenerateQuestionButton: React.FC<{
  editor: SanaEditor
  elementId: string
  elementSelection: string
}> = ({ editor, elementId, elementSelection }) => {
  const { t } = useTranslation()
  const { flexibleContentId: createContentId } = useFileContext()
  const nodeId = useFileContext().file.id
  const yDoc = useMemo(() => {
    assert(isEditableYjsEditor(editor))
    const doc = editor.sharedRoot.doc
    assertIsNonNullable(doc)
    return doc
  }, [editor])
  const parent = useParent({ nodeId: elementId })
  const questionCard = isElementType('question-card', parent) ? parent : undefined
  const [isGenerating, setIsGenerating] = useState(false)

  const selection = elementSelection === 'question-card-pick-the-best-option-body' ? 'single' : 'multiple'
  const currentQuestionCardId = questionCard?.id

  const afterSlateBlocks = _.takeWhile(
    editor.children.map(child => (Element.isElement(child) ? child.id : undefined)).filter(isDefined),
    id => id !== currentQuestionCardId
  )

  const { generateQuestionCard } = useGenerateQuestionCard({
    yDoc,
    currentNodeId: nodeId,
    selection,
    createContentId,
    afterSlateBlocks,
  })

  const generateQuestion = useCallback(async () => {
    if (currentQuestionCardId === undefined)
      return console.error('Unable to generate a question. No question-card id could be found.')

    setIsGenerating(true)
    const questionCard = await generateQuestionCard()
    setIsGenerating(false)

    if (questionCard !== undefined) {
      const questionCardWithCurrentId = { ...questionCard, id: currentQuestionCardId }
      Editor.withoutNormalizing(editor, () => {
        replaceNodeWithId(editor, currentQuestionCardId, questionCardWithCurrentId)
      })

      const pathsByElementID = pathsByElementId(editor)
      const currentPath = pathsByElementID[currentQuestionCardId]
      if (currentPath !== undefined) {
        Transforms.select(editor, Editor.start(editor, currentPath))
      }
    }
  }, [currentQuestionCardId, editor, generateQuestionCard])

  return (
    <Button
      variant='secondary'
      onClick={generateQuestion}
      icon='generate--question'
      disabled={isGenerating}
      loading={isGenerating}
    >
      {t('author.slate.generate')}
    </Button>
  )
}

const getAlternativeForType = (type: QuestionCardBody['type']): CustomElement => {
  switch (type) {
    case 'question-card-pick-the-best-option-body':
    case 'question-card-select-all-that-apply-body':
      return createQuestionCardMultipleChoiceAlternative()
    case 'question-card-match-the-pairs-body':
      return createQuestionCardMatchThePairsAlternative()
    case 'question-card-free-text-body':
      throw new Error('Free text questions do not have alternatives')
    default:
      assertNever(type)
  }
}

const AddVariationButton: React.FC<{ nodeId: string }> = ({ nodeId }) => {
  const { t } = useTranslation()
  const editor = useSlateStatic()
  const context = useContext(ReactQuestionVariationsContext)
  const questionCardEntry = useAncestorEntryWithType({ nodeId, type: 'question-card' })

  const addVariation = (): void => {
    if (context !== undefined) return context.addVariation()
    if (questionCardEntry === undefined) return

    const [questionCard, questionCardPath] = questionCardEntry

    // When questions-variations are stable we will wrap all question-cards in
    // question-variations during normalization, which will remove the need for this code:
    Transforms.wrapNodes(
      editor,
      createQuestionVariations({
        includeInPlacementTestAndReview: questionCard.includeInPlacementTestAndReview,
      }),
      { at: questionCardPath }
    )
    Transforms.insertNodes(editor, createQuestionCard(), { at: questionCardPath.concat(1) })
  }

  return (
    <Button variant='secondary' onClick={addVariation} icon='add'>
      {t('author.slate.question-variation.add')}
    </Button>
  )
}

const UnselectableView = styled(View)`
  user-select: none;
  flex-wrap: wrap;
`

export const DropdownContainer = styled(View).attrs({
  gap: 'xxsmall',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginBottom: 'xxsmall',
})`
  border: 0;
`

const QuestionCardBodyWrapperInner: SlateFC = ({ element, children, readOnly, mode }) => {
  assertElementType(
    [
      'question-card-select-all-that-apply-body',
      'question-card-pick-the-best-option-body',
      'question-card-match-the-pairs-body',
    ],
    element
  )

  const editor = useSlateStatic()
  const { t } = useTranslation()
  const questionCard = useAncestorWithType({ nodeId: element.id, type: 'question-card' })
  const {
    questionCardData: { response, evaluation },
  } = useQuestionCardData()

  const file = useSafeFileContext()?.file

  const isAssessment = file?.data.type === 'assessment-card'

  const revealMissedAnswers = questionCard?.hideCorrectAnswers !== true
  const isInPdfExport = useIsInPdfExport()

  const showMissedAnswersText = useMemo(() => {
    if (isAssessment || isInPdfExport) return false

    if (response.type === 'multiple-choice') {
      const results = [...element.children]
        .filter(isElementType('question-card-multiple-choice-alternative'))
        .map(alternative =>
          getAlternativeStatus({ response, solution: alternative, id: alternative.id, submitted: true })
        )

      return (
        isDefined(evaluation) &&
        !revealMissedAnswers &&
        results.some(it => it === 'missed') &&
        !results.some(it => it === 'incorrect')
      )
    } else if (response.type === 'match-the-pairs') {
      return (
        !revealMissedAnswers &&
        isDefined(evaluation) &&
        response.pairs.some(pair => pair.status === 'incorrect')
      )
    }

    return false
  }, [element.children, evaluation, isAssessment, isInPdfExport, response, revealMissedAnswers])

  const addOption = useCallback(() => {
    const lastChild = element.children[element.children.length - 1]
    if (lastChild !== undefined) {
      insertNodeAfterNodeWithId(editor, lastChild.id, getAlternativeForType(element.type))
    }
  }, [editor, element.children, element.type])

  const labelText = useMemo(() => {
    switch (element.type) {
      case 'question-card-select-all-that-apply-body':
        return t('author.slate.select-all-that-apply')
      case 'question-card-pick-the-best-option-body':
        return t('author.slate.pick-the-best-option')
      case 'question-card-match-the-pairs-body':
        return t('author.slate.match-the-pairs')
      default:
        assertNever(element)
    }
  }, [element, t])

  const isCreatePage = useCreatePageContextSafe() !== undefined
  const generateQuestionSupported =
    isCreatePage &&
    (element.type === 'question-card-pick-the-best-option-body' ||
      element.type === 'question-card-select-all-that-apply-body')

  const showCorrectBadge =
    evaluation !== undefined &&
    !isAssessment &&
    !revealMissedAnswers &&
    !isInPdfExport &&
    response.type !== 'match-the-pairs'

  return (
    <>
      <MarginContainer>
        {readOnly ? (
          <Label>{labelText}</Label>
        ) : (
          <DropdownContainer contentEditable={false}>
            <DropdownQuestionType question={element} />
          </DropdownContainer>
        )}
        <View gap='4' direction='column'>
          {children}
        </View>
        <UnselectableView contentEditable={false} gap='xxsmall' direction='row' marginTop='medium'>
          {mode === 'create' && readOnly ? (
            <span />
          ) : mode === 'create' ? (
            <>
              {generateQuestionSupported && (
                <GenerateQuestionButton
                  elementId={element.id}
                  elementSelection={element.type}
                  editor={editor}
                />
              )}
              <View grow />

              <Button variant='secondary' onClick={addOption} icon='add'>
                {t('author.slate.add-option')}
              </Button>

              <AddVariationButton nodeId={element.id} />
            </>
          ) : (
            <>
              <SubmitRetryButton />
              <View grow />
              <span>
                {showMissedAnswersText && (
                  <Text size='small'>{t('author.question-card.missed-correct')}</Text>
                )}
              </span>
              <span>
                {showCorrectBadge && (
                  <>
                    {evaluation === false && <EvaluationStatus status='fail' />}
                    {evaluation === true && <EvaluationStatus status='success' />}
                  </>
                )}
              </span>
            </>
          )}
        </UnselectableView>
      </MarginContainer>
    </>
  )
}

export const QuestionCardBodyWrapper: SlateFC = props => {
  const { element, mode } = props

  // Can be any question card body type
  assertElementType(
    [
      'question-card-select-all-that-apply-body',
      'question-card-pick-the-best-option-body',
      'question-card-match-the-pairs-body',
    ],
    element
  )

  return (
    <QuestionCardDataProvider element={element} mode={mode}>
      <QuestionCardBodyWrapperInner {...props} />
    </QuestionCardDataProvider>
  )
}

import { useMutation } from '@tanstack/react-query'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { SanaImage } from 'sierra-client/components/common/image'
import { useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useUsers } from 'sierra-client/state/users/hooks'
import {
  eventScheduleIsUpcoming,
  formatEventSchedule,
  getEventLocationString,
  getEventScheduleStart,
} from 'sierra-client/views/manage/event-groups/event-utils'
import { CalendarEventSchedule, EventLocation } from 'sierra-client/views/manage/event-groups/types'
import { withPanel } from 'sierra-client/views/manage/utils/with-modal'
import { CalendarEventId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { LightUser } from 'sierra-domain/user'
import { isNonNullable } from 'sierra-domain/utils'
import { AvatarStack, TruncatedText } from 'sierra-ui/components'
import { Button, Heading, IconButton, Spacer, Text, View } from 'sierra-ui/primitives'
import { spacing, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const OuterContainer = styled(View).attrs({ direction: 'column' })`
  position: relative;
  min-height: 100%;
`

const CloseIconContainer = styled.div`
  position: absolute;
  top: ${spacing['16']};
  right: ${spacing['16']};
`

type Session = {
  id: CalendarEventId
  title: string
  image?: ImageUnion
  assetContext: AssetContext
  schedule: CalendarEventSchedule
  location?: EventLocation
  participants: string[]
  participantLimit?: number

  onSelectedAction: 'assign' | 'unassign'
}

const ListItemImageContainer = styled.div`
  flex-basis: 80px;
  flex-shrink: 0;
`

const StyledSanaImage = styled(SanaImage)`
  /* height: 100%; */
`

const SessionItem: React.FC<{
  session: Session
  usersBeingAssigned: string[]
  onSessionSelected: (session: Session) => Promise<void>
}> = props => {
  const sessionSelectedMutation = useMutation({ mutationFn: () => props.onSessionSelected(props.session) })
  const { t } = useTranslation()
  const seatsRemaining =
    props.session.participantLimit !== undefined
      ? props.session.participantLimit - props.session.participants.length
      : undefined

  const isEnoughSeatsForAllUsers =
    seatsRemaining === undefined || props.usersBeingAssigned.length <= seatsRemaining

  const text = useMemo(() => {
    if (
      props.session.onSelectedAction === 'assign' &&
      isNonNullable(seatsRemaining) &&
      !isEnoughSeatsForAllUsers
    ) {
      return t('manage.event-groups.not-enough-capacity')
    }

    if (isNonNullable(seatsRemaining)) {
      return t('course-detail.free-spots', { count: seatsRemaining })
    }

    if (props.session.participantLimit === undefined) {
      return t('manage.event-groups.n-spots-taken', { count: props.session.participants.length })
    }
  }, [
    isEnoughSeatsForAllUsers,
    props.session.onSelectedAction,
    props.session.participantLimit,
    props.session.participants.length,
    seatsRemaining,
    t,
  ])

  const imageSrc = useResolveCourseAsset({
    image: props.session.image,
    assetContext: props.session.assetContext,
    options: { type: 'admin', size: 'small' },
  })

  return (
    <View>
      <ListItemImageContainer>
        <StyledSanaImage src={imageSrc} ratio='16:9' rounded radius='size-8' />
      </ListItemImageContainer>
      <Spacer size='6' />
      <div>
        <View>
          <TruncatedText lines={1} bold>
            {formatEventSchedule(props.session.schedule, { month: 'long' })}
          </TruncatedText>
        </View>
        <Spacer size='4' />
        <TruncatedText lines={1} lineLength={35} color='foreground/muted'>
          {getEventLocationString(props.session.location) ?? ''}
        </TruncatedText>
      </div>
      <View marginLeft='auto'>
        <Text color='foreground/muted'>{text}</Text>
        <Spacer size='4' />
        <Button
          icon={props.session.onSelectedAction === 'unassign' ? 'checkbox--checkmark' : undefined}
          disabled={props.session.onSelectedAction === 'assign' && !isEnoughSeatsForAllUsers}
          onClick={() => sessionSelectedMutation.mutate()}
          loading={sessionSelectedMutation.isPending}
          variant={props.session.onSelectedAction === 'assign' ? 'secondary' : 'success'}
        >
          {props.session.onSelectedAction === 'assign'
            ? t('manage.event-groups.assign', { count: props.usersBeingAssigned.length })
            : t('manage.event-groups.assigned')}
        </Button>
      </View>
    </View>
  )
}

const TopContainer = styled(View)`
  width: 100%;
  height: 100%;
  overflow: auto;
  scrollbar-gutter: stable;
`

const BottomContainer = styled.div`
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${token('surface/default')};
  padding: ${spacing['medium']};
  padding-top: 0;
`

const getFullName = (user: LightUser): string => `${user.firstName} ${user.lastName}`

// Jonathan
// Jonathan and Damjan
// Jonathan, Damjan and Viktor
// Jonathan, Damjan and 2 others
const UserNames = ({ users }: { users: LightUser[] }): JSX.Element => {
  const { t } = useTranslation()
  const and = t('manage.event-groups.user-list-and')
  if (users.length === 0) return <Text></Text>
  if (users.length === 1) return <Text bold>{getFullName(users[0]!)}</Text>
  if (users.length === 2)
    return (
      <View gap='4'>
        <Text bold>{getFullName(users[0]!)}</Text>
        <Text>{and}</Text>
        <Text bold>{getFullName(users[1]!)}</Text>
      </View>
    )
  if (users.length === 3)
    return (
      <View gap='4'>
        <Text bold>{getFullName(users[0]!)}</Text>
        <Text bold>{getFullName(users[1]!)}</Text>
        <Text>{and}</Text>
        <Text bold>{getFullName(users[2]!)}</Text>
      </View>
    )

  const others = t('manage.event-groups.user-list-count-others', { count: users.length - 2 })
  const firstTwo = users.slice(0, 2)

  return (
    <View gap='4'>
      <Text bold>{firstTwo.map(getFullName).join(', ')}</Text>
      <Text>{and}</Text>
      <Text bold>{others}</Text>
    </View>
  )
}

export const SelectSessionModal = withPanel<{
  users: UserId[]
  sessions: Session[]
  onSessionSelected: (session: Session) => Promise<void>
  onCreateNewSession?: () => void
}>(
  {
    size: { width: 700 },
    disableScrollbarGutter: true,
    overlayVariant: 'light',
  },
  props => {
    const { t } = useTranslation()
    const users = useUsers(props.users)

    const sessions = useMemo((): Session[] => {
      const sections = _.chain(props.sessions)
        .filter(session => eventScheduleIsUpcoming(session.schedule))
        .orderBy(session => getEventScheduleStart(session.schedule))
        .value()
      return sections
    }, [props.sessions])

    const loadedUsers = users.filter(function (it): it is LightUser & { status: 'loaded' } {
      return it.status === 'loaded'
    })

    return (
      <OuterContainer direction='column'>
        <CloseIconContainer>
          <IconButton variant='transparent' size='small' iconId='close' onClick={props.onClose} />
        </CloseIconContainer>

        <TopContainer
          gap='none'
          padding='none medium'
          paddingTop='small'
          justifyContent='flex-start'
          direction='column'
        >
          <Heading size={'h5'} bold>
            {t('manage.event-groups.assign-users-to-events.title')}
          </Heading>
          <Spacer size='24' />
          <View padding='16' background='surface/soft' radius='regular'>
            <AvatarStack size='tiny' withTooltips max={3} users={loadedUsers} />
            <UserNames users={loadedUsers} />
          </View>
          <Spacer size='24' />

          <View>
            <Text bold size='regular'>
              {t('event-groups.upcoming-sessions')}
            </Text>
          </View>

          <Spacer size='16' />

          <View direction='column' gap='24'>
            {sessions.length === 0 && (
              <View>
                <Text>{t('events-groups.no-sessions')}</Text>
              </View>
            )}
            {sessions.map(session => (
              <SessionItem
                key={session.id}
                usersBeingAssigned={props.users}
                session={session}
                onSessionSelected={props.onSessionSelected}
              />
            ))}
          </View>

          <Spacer size='32' />
        </TopContainer>
        <BottomContainer>
          <View justifyContent='flex-end'>
            {props.onCreateNewSession !== undefined && (
              <View marginRight='auto'>
                <Button variant='secondary' onClick={props.onCreateNewSession}>
                  {t('manage.event-groups.assign-users-to-events.new-session')}
                </Button>
              </View>
            )}
            <Button variant='secondary' onClick={props.onClose}>
              {t('manage.event-groups.assign-users-to-events.done')}
            </Button>
          </View>
        </BottomContainer>
      </OuterContainer>
    )
  }
)

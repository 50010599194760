import { useAtomValue } from 'jotai'
import _ from 'lodash'
import { useMemo } from 'react'
import { recentlyVisitedAtom } from 'sierra-client/editor/utils/recently-visited/atom'
import { resolveRecentlyVisitedEntryHref } from 'sierra-client/editor/utils/recently-visited/resolve-href'
import { RecentlyVisitedContent } from 'sierra-client/editor/utils/recently-visited/types'
import { useIsCreateAccessible } from 'sierra-client/hooks/use-create-enabled'
import { useCachedQuery } from 'sierra-client/state/api'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { XRealtimeAuthorListEditableContent, XRealtimeContentHomeBrowse } from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'

export function useRecentlyVisitedContent(): RecentlyVisitedContent[] {
  const entries = useAtomValue(recentlyVisitedAtom)

  const learnerContent = useCachedQuery(XRealtimeContentHomeBrowse, {}).data?.entities
  const enabled = useIsCreateAccessible()
  const editableContent = useCachedQuery(XRealtimeAuthorListEditableContent, {}, { enabled }).data?.content

  return useMemo((): RecentlyVisitedContent[] => {
    const editableContentById = _.keyBy(editableContent, it => it.id)
    const learnerContentById = _.keyBy(learnerContent, it => it.entity.id)
    return _.chain(entries)
      .map((entry): RecentlyVisitedContent | undefined => {
        const base = {
          type: 'recently-visited-content',
          href: resolveRecentlyVisitedEntryHref(entry),
          entry,
          id: entry.scopedId,
        } as const

        const unscopedId = ScopedCreateContentId.extractId(entry.scopedId)
        if (entry.type === 'editor') {
          const content = editableContentById[unscopedId]
          if (content === undefined) return undefined
          else return { ...base, content, kind: 'editable' }
        } else {
          const content = learnerContentById[unscopedId]?.entity
          if (content === undefined) return undefined
          else return { ...base, content, kind: 'learner' }
        }
      })
      .filter(isDefined)
      .orderBy(it => it.entry.date, 'desc')
      .value()
  }, [editableContent, entries, learnerContent])
}

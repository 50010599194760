import { atom } from 'jotai'
import { atomWithStorage } from 'sierra-client/state/storage'

export const searchInsightsCmdKEnabledAtom = atomWithStorage('searchInsightsEnabled', false)
export const debugModeAtom = atomWithStorage('debugModeEnabled', false)
export const showTranslationStringsAtom = atom(false)
export const newPageCardColumnColorAtom = atom(false)
export const newPageCardAlignmentAtom = atom(false)
export const newPageScrollPageAtom = atom(false)
export const chaosTestingEnabledAtom = atom(false)
export const searchRerankingAnnotationModeEnabledAtom = atom(false)
export const newRecapInfraAtom = atom(false)
export const askInsightsModelAtom = atomWithStorage<'sql-v2-few-shot'>('askInsightsModel', 'sql-v2-few-shot')
export const loadNamespacedCourseAssetsAtom = atomWithStorage('loadNamespacedCourseAssets', false)

export type SearchModel =
  | 'default'
  | 'vespa:bm25-freshness'
  | 'vespa:bm25'
  | 'vespa:bm25-minilml6v2-freshness'
  | 'vespa:bm25-minilml6v2-freshness-prefix'
  | 'vespa:bm25-minilml6v2-freshness-prefix-grouped-test'
  | 'vespa:minilml6v2'
export const searchModelAtom = atom<SearchModel>('default')

import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useConfig } from 'sierra-client/context/config-context'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { I18nArgs } from 'sierra-client/hooks/use-translation/types'
import { useDispatch } from 'sierra-client/state/hooks'
import { fetchTagsData } from 'sierra-client/state/v2/tags-actions'
import { getAuthMethodForEmail } from 'sierra-client/views/authentication/common'
import { useMSTeamsInfo } from 'sierra-client/views/manage/settings/components/microsoft-teams'
import { useSlackIntegrationInfo } from 'sierra-client/views/manage/settings/components/slack'
import { useOrgNotificationSettings } from 'sierra-client/views/settings/emails-and-notifications/use-org-notification-settings'
import { LiveIntegrationSettingsForm } from 'sierra-client/views/user-settings/components/live-integrations-form'
import { NotificationSettingsForm } from 'sierra-client/views/user-settings/components/notification-settings-form'
import { UserSettingsForm } from 'sierra-client/views/user-settings/components/user-settings-form'
import { useUserSettings } from 'sierra-client/views/user-settings/use-user-settings'
import {
  AccountSettingsFormConfig,
  HandleAccountSettingsFormChange,
  UserSettingsFormErrors,
  getDefaultAccountSettings,
  validateAccountSettingsForm,
} from 'sierra-client/views/user-settings/user-settings-utils'
import { NotificationState } from 'sierra-domain/api/org-notification-settings'
import { AccountSettings } from 'sierra-domain/api/user'
import { Tabs } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const TabContainer = styled(View).attrs({ direction: 'column' })`
  height: 100%;
  padding-top: 2rem;
`

type SettingsTab =
  | 'settings'
  | 'notifications'
  | 'slack-notifications'
  | 'live-integrations'
  | 'teams-notifications'

export const UserSettings: React.FC<{
  initialTab?: SettingsTab
  onAfterSave?: () => void
}> = ({ initialTab, onAfterSave }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const sanaNowEnabled = useFlag('sana-now')

  const config = useConfig()
  const { currentUser, saveUserSettings } = useUserSettings()

  const notif = useNotif()

  const [userForm, setUserForm] = useState<AccountSettings>(getDefaultAccountSettings())
  const [error, setError] = useState<UserSettingsFormErrors>({
    userAccountSettings: {},
    notificationSettings: {},
  })
  const [serverError, setServerError] = useState<I18nArgs | undefined>()
  const [currentTab, setCurrentTab] = useState<SettingsTab>(initialTab ?? 'settings')

  useEffect(() => {
    void dispatch(fetchTagsData())
  }, [dispatch])

  useEffect(() => {
    if (currentUser === undefined) return

    setUserForm(getDefaultAccountSettings(currentUser))
  }, [currentUser])

  // Logic for disabling inputs
  const formConfig = useMemo<AccountSettingsFormConfig>(() => {
    const email = currentUser?.email ?? ''
    const authMethod = getAuthMethodForEmail(config.auth, email)

    const isSamlAndProvisioning =
      authMethod !== undefined && authMethod.type === 'saml' && authMethod.provisioning

    const disableName = email === '' || isSamlAndProvisioning
    const disableEmail = true
    return {
      disableName,
      disableEmail,
    }
  }, [config.auth, currentUser?.email])

  // Form change
  const handleFormChange: HandleAccountSettingsFormChange = (rootField, field, value) => {
    setUserForm(f => ({
      ...f,
      [rootField]: {
        ...f[rootField],
        [field]: value,
      },
    }))
  }

  const handleSave = async (): Promise<void> => {
    setError({ userAccountSettings: {}, notificationSettings: {} })
    setServerError(undefined)

    const result = validateAccountSettingsForm(userForm, formConfig)
    if (result.hasErrors) {
      setError(result.errors)
      return
    }

    const saveResult = await saveUserSettings(userForm)

    if (saveResult.error) {
      setServerError(saveResult.i18nArgs)
      notif.push({ type: 'error' })
      return
    }

    notif.push({ type: 'custom', level: 'success', body: t('notifications.settings-saved') })
    if (onAfterSave !== undefined) onAfterSave()
  }

  const slackConnection = useSlackIntegrationInfo()
  const { config: msTeamsConfig } = useMSTeamsInfo()
  const { result: orgNotificationSettings } = useOrgNotificationSettings()

  return (
    <View grow direction='column'>
      <View grow direction='column'>
        <Spacer />
        <Tabs
          hideLine
          value={currentTab}
          onChange={(newTab: SettingsTab) => setCurrentTab(newTab)}
          items={_.compact([
            {
              id: 'settings',
              label: t('dictionary.account'),
              content: (
                <TabContainer grow>
                  <UserSettingsForm
                    values={userForm}
                    formConfig={formConfig}
                    errors={error}
                    email={currentUser?.email}
                    onChange={handleFormChange}
                  />
                </TabContainer>
              ),
            },
            orgNotificationSettings.data?.emailSettings.state === NotificationState.Enabled && {
              id: 'notifications',
              label: t('settings.email-notifications'),
              content: (
                <TabContainer>
                  <NotificationSettingsForm
                    values={userForm}
                    formConfig={formConfig}
                    notificationType={'email'}
                    errors={error}
                    onChange={handleFormChange}
                  />
                </TabContainer>
              ),
            },
            orgNotificationSettings.data?.slackSettings.state === NotificationState.Enabled &&
              slackConnection.connection !== undefined && {
                id: 'slack-notifications',
                label: t('settings.slack-notifications'),
                content: (
                  <TabContainer>
                    <NotificationSettingsForm
                      values={userForm}
                      formConfig={formConfig}
                      notificationType={'slack'}
                      errors={error}
                      onChange={handleFormChange}
                    />
                  </TabContainer>
                ),
              },
            orgNotificationSettings.data?.msTeamsSettings.state === NotificationState.Enabled &&
              msTeamsConfig.enabled && {
                id: 'teams-notifications',
                label: t('manage.settings.msTeams.notifications-title'),
                content: (
                  <TabContainer>
                    <NotificationSettingsForm
                      values={userForm}
                      formConfig={formConfig}
                      notificationType={'msTeams'}
                      errors={error}
                      onChange={handleFormChange}
                    />
                  </TabContainer>
                ),
              },
            sanaNowEnabled && {
              id: 'live-integrations',
              label: t('settings.live-meeting-tools-tab-title'),
              content: (
                <TabContainer>
                  <LiveIntegrationSettingsForm />
                </TabContainer>
              ),
            },
          ])}
        />
      </View>
      <View justifyContent='flex-end'>
        <Button onClick={handleSave}>{t('dictionary.save')}</Button>
      </View>
      {serverError !== undefined && (
        <Text size='small' color='redMedium' spacing='xsmall'>
          {t(...serverError)}
        </Text>
      )}
    </View>
  )
}

import { useAtom, useAtomValue } from 'jotai'
import _ from 'lodash'
import { forwardRef, useMemo } from 'react'
import { resolveNamespacedCourseImage } from 'sierra-client/api/content'
import { RouterLink } from 'sierra-client/components/common/link'
import { GlobalSidebarOpenAtom } from 'sierra-client/features/global-sidebar'
import { useLoadNamespacedCourseAssets } from 'sierra-client/hooks/use-resolve-course-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { getLearnEntityName } from 'sierra-client/views/manage/content/utils/content-utils'
import { ContentCardUL } from 'sierra-client/views/showcase/content-card-ul'
import {
  InlinePageMargins,
  PageMarginConstants,
  getEntityIconId,
} from 'sierra-client/views/workspace/components'
import { LastUpdateLabel } from 'sierra-client/views/workspace/components/content-table-row-components'
import { recentlyEditedSettingAtom } from 'sierra-client/views/workspace/create-new/atoms'
import { isCollaboratorRoleAboveOrEqual } from 'sierra-client/views/workspace/utils/collaborator-role-utils'
import { continueUrl } from 'sierra-client/views/workspace/utils/urls'
import { EditableContentFilter } from 'sierra-domain/api/author-v2'
import { CreatePageContent } from 'sierra-domain/api/create-page'
import { editUrl } from 'sierra-domain/api/editable-content'
import { CourseId } from 'sierra-domain/api/nano-id'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { Icon, MenuItem } from 'sierra-ui/components'
import { ContentCard } from 'sierra-ui/missions/foundation'
import { Spacer, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

const FilterText = styled(Text).attrs({ size: 'regular', bold: true })``

const FilterTrigger = forwardRef<HTMLDivElement, { open?: boolean; children: React.ReactNode }>(
  ({ open, children, ...props }, ref) => (
    <View
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      gap='2'
      cursor='pointer'
      ref={ref}
      {...props}
    >
      <FilterText color='foreground/muted'>{children}</FilterText>
      <Icon
        iconId={open === true ? 'chevron--up' : 'chevron--down'}
        size='size-12'
        color='foreground/muted'
      />
    </View>
  )
)

const getFilterLabel = (filter: EditableContentFilter, _t: TranslationLookup): string => {
  switch (filter) {
    case 'you':
      return _t('dictionary.you')
    case 'anyone':
      return _t('dictionary.anyone')
    default:
      assertNever(filter)
  }
}

const TitleContainer = styled(View)`
  ${InlinePageMargins}
`

export const RecentlyEdited: React.FC<{
  createPageContent: CreatePageContent
}> = ({ createPageContent }) => {
  const [filter, setFilter] = useAtom(recentlyEditedSettingAtom)
  const { t } = useTranslation()
  const { myCreations, teamspaces, shared } = createPageContent
  const teamspaceContent = teamspaces.flatMap(teamspace => teamspace.content)
  const sidebarOpen = useAtomValue(GlobalSidebarOpenAtom)

  const me = useSelector(selectUser)

  const { recentlyEdited, isEmptyByYou, isEmpty } = useMemo(() => {
    const recent = [...myCreations.content, ...teamspaceContent, ...shared.content]
      .flatMap(it =>
        it.type === 'editable'
          ? [it.content]
          : it.type === 'native:live' || it.type === 'native:self-paced'
            ? [it]
            : []
      )
      .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())

    const recentlyEditedContent = _.uniqBy(recent, 'id')

    const recentlyEdited = recentlyEditedContent.filter(content =>
      filter === 'you' ? content.lastEditedBy === me?.uuid : true
    )
    const isEmptyByYou =
      recentlyEditedContent.filter(content => content.lastEditedBy === me?.uuid).length === 0

    const isEmpty = recentlyEditedContent.length === 0

    return {
      recentlyEdited,
      isEmptyByYou,
      isEmpty,
    }
  }, [filter, me?.uuid, myCreations.content, shared.content, teamspaceContent])

  const filterItems: MenuItem<EditableContentFilter>[] = [
    {
      type: 'label',
      id: 'you',
      label: t('dictionary.you'),
    },
    {
      type: 'label',
      id: 'anyone',
      label: t('dictionary.anyone'),
    },
  ]

  const loadNamespacedCourseAssets = useLoadNamespacedCourseAssets()

  if (isEmpty) {
    return <></>
  }

  return (
    <>
      <TitleContainer
        marginTop={'24'}
        $sidebarOpen={sidebarOpen}
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        gap='4'
      >
        <FilterText color='foreground/primary'>{t('create-page.recently-edited-by')}</FilterText>
        <SingleSelectDropdown
          selectedItem={filterItems.find(i => i.id === filter)}
          menuItems={filterItems}
          disabled={isEmptyByYou}
          onSelect={item => {
            if (isEmptyByYou) {
              return
            }

            setFilter(item.id)
          }}
          renderTrigger={open =>
            isEmptyByYou ? (
              <FilterText>{getFilterLabel(filter, t)}</FilterText>
            ) : (
              <FilterTrigger open={open}>{getFilterLabel(filter, t)}</FilterTrigger>
            )
          }
        />
      </TitleContainer>

      <ContentCardUL
        padding={{
          start: sidebarOpen ? PageMarginConstants[48] : PageMarginConstants[64],
          end: PageMarginConstants[32],
          top: 12,
        }}
      >
        {/* Pick 10 max. */}
        {recentlyEdited.slice(0, 10).map(content => {
          const type = content.type
          const contentLabel = t(getLearnEntityName(type))
          const { highestCollaboratorRole } = content
          const roleIsAtLeastCommenter =
            isDefined(highestCollaboratorRole) &&
            isCollaboratorRoleAboveOrEqual(highestCollaboratorRole, 'commenter')
          const link = roleIsAtLeastCommenter ? editUrl(content.type, content.id) : continueUrl(content)

          const assetContext = { type: 'course' as const, courseId: CourseId.parse(content.id) }
          const thumbnailSrc = resolveNamespacedCourseImage(
            content.image,
            { type: 'default', size: 'normal' },
            loadNamespacedCourseAssets,
            assetContext
          )

          return (
            <ContentCard
              contentLabel={{
                iconId: getEntityIconId(type, false),
                label: contentLabel,
              }}
              key={content.id}
              title={content.title}
              thumbnailSrc={thumbnailSrc}
              metadata={<LastUpdateLabel lastEditorId={content.lastEditedBy} updatedAt={content.timestamp} />}
              renderLink={({ children }) => <RouterLink href={link}>{children}</RouterLink>}
            />
          )
        })}
      </ContentCardUL>
      <Spacer size='32' />
    </>
  )
}

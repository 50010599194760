import { useMutation } from '@tanstack/react-query'
import React, { FC, useCallback, useMemo } from 'react'
import { ContentReferencePresentation } from 'sierra-client/components/chat/chat-message/content-reference-presentation'
import { EditTiptapMessage } from 'sierra-client/components/chat/chat-message/edit-tiptap-message'
import { MessageActionMenu } from 'sierra-client/components/chat/chat-message/message-action-menu'
import { MessageContentReplies } from 'sierra-client/components/chat/chat-message/message-content-replies'
import { MessageReactions } from 'sierra-client/components/chat/message-reactions'
import { ReadOnlyTiptapContent } from 'sierra-client/components/chat/tiptap'
import { Link } from 'sierra-client/components/common/link'
import { usePathname } from 'sierra-client/hooks/router/use-pathname'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { typedPost, useTypedMutation } from 'sierra-client/state/api'
import {
  editMessage,
  reactToMessage,
  resolveComment,
  unresolveComment,
} from 'sierra-client/state/chat/actions'
import { selectMessage } from 'sierra-client/state/chat/selectors'
import { selectFlexibleContent } from 'sierra-client/state/flexible-content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { useCreateRouteIds } from 'sierra-client/views/commenting/use-create-route-ids'
import { buildDeeplink } from 'sierra-client/views/commenting/utils'
import { ChatIdentifier, ReactToChatMessageRequest } from 'sierra-domain/api/chat'
import { CourseId } from 'sierra-domain/api/nano-id'
import { ChatMessageId, ChatMessageReactionId, uuid } from 'sierra-domain/api/uuid'
import { CommentTiptapMessage, Message, TiptapJsonData } from 'sierra-domain/chat'
import { ScopedChatId, ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import {
  XRealtimeChatReactToMessage,
  XRealtimeChatSetMessageResolved,
  XRealtimeChatUpdateMessage,
} from 'sierra-domain/routes'
import { assertNever, iife } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { spacing, token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const Deeplink: FC<{ contentId: ScopedCreateContentId | CourseId; unitId: string; messageId: string }> = ({
  contentId,
  unitId,
  messageId,
}) => {
  const { t } = useTranslation()
  const pathname = usePathname()

  // Deeplinks are not supported in the old editor
  if (CourseId.safeParse(contentId).success && !/^\/create\/(s|l)/.test(pathname)) {
    return null
  }

  const url = buildDeeplink({
    contentId: contentId as ScopedCreateContentId,
    unitId,
    messageId,
  })

  return (
    <Link size='small' href={url} next>
      {t('dictionary.view')}
    </Link>
  )
}

const MessageContainer = styled.div`
  position: relative;
  padding: 0 0.5rem;
  background-color: ${token('surface/default')};
  width: 100%;
`

const PlainMessageWrapper = styled.div`
  display: inline-flex;
  background-color: ${token('surface/soft')};
  border-radius: 1rem;
  padding: 0.3125rem 0.75rem;
  font-size: 14px;
`

const ResolvedBanner = styled(Text).attrs({
  bold: true,
  size: 'technical',
})`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: ${token('surface/soft')};
  text-align: center;
  padding: ${spacing['2']};
`
const CommentMessageWrapper = styled.div<{ isResolved: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${spacing['12']};
  background-color: ${token('surface/default')};
  border: 1px solid ${token('border/default')};
  border-radius: 0.75rem;
  overflow: hidden;
  margin: 0.25rem 0;
  padding: 1.25rem 0.675rem 0.675rem 1rem;
  font-size: 14px;
  transition: all 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);
  color: ${token('foreground/primary')};

  &:hover {
    border: 1px solid ${token('border/strong')};
  }

  ${p =>
    p.isResolved
      ? css`
          ${ResolvedBanner} {
            opacity: 1;
          }
        `
      : css`
          ${ResolvedBanner} {
            opacity: 0;
          }
        `};
`

const MessageWrapper = ({
  children,
  onReply,
  onReaction,
  onOpenEditMode,
  message,
}: {
  children: React.ReactNode
  onReply?: () => void
  onReaction?: (reaction: string) => void
  onOpenEditMode?: () => void
  message: Message
}): JSX.Element => {
  const [isHovering, setIsHovering] = React.useState(false)

  const onMouseEnter = useCallback(() => setIsHovering(true), [setIsHovering])
  const onMouseLeave = useCallback(() => setIsHovering(false), [setIsHovering])

  const handleReply = useMemo(() => {
    if (onReply === undefined) return undefined

    return () => {
      setIsHovering(false)
      onReply()
    }
  }, [onReply])

  return (
    <MessageContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <MessageActionMenu
        visible={isHovering}
        onReply={handleReply}
        onReaction={onReaction}
        onOpenEditMode={onOpenEditMode}
        message={message}
      />
      {children}
    </MessageContainer>
  )
}

type MessageContentProps = {
  chatId: ScopedChatId
  chatIdentifier: ChatIdentifier
  messageId: string
  setThreadId?: (id: string) => void
  withoutContentReferences: boolean
}

const TiptapCommentMessageWrapper = ({
  comment,
  contentId,
  onResolve,
  onUnresolve,
}: {
  comment: CommentTiptapMessage
  onResolve: () => void
  onUnresolve: () => void
  contentId?: ScopedCreateContentId
}): JSX.Element => {
  const { contentReference, id: messageId } = comment
  const isResolved = comment.resolvedAt !== undefined
  const detached = comment.detachedFromContent === true
  const fileId = comment.contentReference.unitId
  const { t } = useTranslation()

  const fileExists = useSelector(state => {
    const flexibleContent =
      contentId !== undefined
        ? selectFlexibleContent(state, ScopedCreateContentId.extractId(contentId))
        : undefined

    return fileId !== undefined && flexibleContent?.nodeMap[fileId as FileId] !== undefined
  })

  return (
    <>
      <CommentMessageWrapper isResolved={isResolved}>
        <ResolvedBanner hidden={!isResolved}>{t('dictionary.resolved')}</ResolvedBanner>

        <ContentReferencePresentation contentReference={contentReference} />

        <ReadOnlyTiptapContent content={comment.tiptapJsonData} edited={comment.timeEdited !== undefined} />

        <View direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton
            iconId='checkmark--outline'
            variant='transparent'
            color={isResolved ? 'greenBright' : 'foreground/primary'}
            tooltip={t(isResolved ? 'dictionary.resolved' : 'dictionary.resolve')}
            onClick={() => (isResolved ? onUnresolve() : onResolve())}
          />

          {detached || !fileExists ? (
            <Icon iconId='unlink' color='redLight' tooltip={t('commenting.comment-content-removed')} />
          ) : (
            comment.contentReference.unitId !== undefined &&
            contentId !== undefined && (
              <Deeplink
                contentId={contentId}
                unitId={comment.contentReference.unitId}
                messageId={messageId}
              />
            )
          )}
        </View>
      </CommentMessageWrapper>
    </>
  )
}

export const MessageContent: FC<MessageContentProps> = ({
  chatId,
  chatIdentifier,
  messageId,
  setThreadId,
}) => {
  const dispatch = useDispatch()
  const [editMode, editModeHandlers] = useToggle(false)

  const route = useCreateRouteIds()
  const contentId = route?.contentId

  const userId = useSelector(selectUserId)
  const message = useSelector(state => selectMessage(state, chatId, messageId))
  const canEdit = userId === message.userId

  const existingReactions = useSelector(
    useCallback(
      state => {
        const message = state.chat.chats[chatId]?.messages[messageId]
        if (message !== undefined && message.type === 'tiptap-plain') {
          return message.reactions
        }
      },
      [chatId, messageId]
    )
  )

  const newChatReactToMessageMutation = useMutation({
    mutationFn: async (params: ReactToChatMessageRequest) => {
      await typedPost(XRealtimeChatReactToMessage, params)
    },
  })

  const { mutate: newChatSetMessageResolved } = useTypedMutation(XRealtimeChatSetMessageResolved)

  const onReply = useCallback(() => {
    setThreadId?.(messageId)
  }, [messageId, setThreadId])

  const canReply = setThreadId !== undefined

  const onReaction = useCallback(
    async (reaction: string): Promise<void> => {
      if (userId === undefined) return

      const newReactionId = ChatMessageReactionId.parse(uuid())
      void dispatch(reactToMessage({ id: newReactionId, chatId, messageId, userId, reaction }))

      const existingReaction =
        existingReactions !== undefined
          ? Object.values(existingReactions).find(r => r.userId === userId && r.reaction === reaction)
          : undefined

      const shouldAddReaction = existingReaction === undefined

      const chatMessageId = ChatMessageId.parse(messageId)
      if (shouldAddReaction) {
        newChatReactToMessageMutation.mutate({
          type: 'add',
          chatIdentifier: chatIdentifier,
          messageId: chatMessageId,
          reactionId: newReactionId,
          reaction,
        })
      } else {
        const existingReactionId = ChatMessageReactionId.parse(existingReaction.id)

        newChatReactToMessageMutation.mutate({
          type: 'remove',
          chatIdentifier,
          messageId: chatMessageId,
          reactionId: existingReactionId,
        })
      }
    },
    [chatId, chatIdentifier, dispatch, existingReactions, messageId, newChatReactToMessageMutation, userId]
  )

  const newUpdateMessageMutation = useTypedMutation(XRealtimeChatUpdateMessage)

  const onEditMessageSaved = useCallback(
    (newMessageContent: TiptapJsonData): void => {
      void dispatch(editMessage({ chatId, messageId, tiptapJsonData: newMessageContent }))

      newUpdateMessageMutation.mutate({
        chatIdentifier,
        messageId: ChatMessageId.parse(messageId),
        messageData: newMessageContent,
      })

      editModeHandlers.off()
    },
    [chatId, chatIdentifier, dispatch, editModeHandlers, messageId, newUpdateMessageMutation]
  )

  const onResolve = useCallback((): void => {
    if (userId !== undefined && message.type === 'tiptap-comment') {
      newChatSetMessageResolved({
        chatIdentifier,
        messageId: ChatMessageId.parse(messageId),
        isResolved: true,
      })

      void dispatch(
        resolveComment({
          chatId,
          threadId: messageId,
          userId,
        })
      )
    }
  }, [chatId, chatIdentifier, dispatch, message.type, messageId, newChatSetMessageResolved, userId])

  const onUnresolve = useCallback((): void => {
    if (message.type === 'tiptap-comment') {
      newChatSetMessageResolved({
        chatIdentifier,
        messageId: ChatMessageId.parse(messageId),
        isResolved: false,
      })

      void dispatch(
        unresolveComment({
          chatId,
          threadId: messageId,
        })
      )
    }
  }, [chatId, chatIdentifier, dispatch, message.type, messageId, newChatSetMessageResolved])

  if (editMode && message.type !== 'emoji') {
    return <EditTiptapMessage onSave={onEditMessageSaved} onCancel={editModeHandlers.off} message={message} />
  }

  return (
    <MessageWrapper
      onReply={canReply ? onReply : undefined}
      onReaction={onReaction}
      onOpenEditMode={canEdit ? editModeHandlers.on : undefined}
      message={message}
    >
      {iife(() => {
        switch (message.type) {
          case 'emoji':
            return null
          case 'tiptap-plain':
            return (
              <PlainMessageWrapper>
                <ReadOnlyTiptapContent
                  content={message.tiptapJsonData}
                  edited={message.timeEdited !== undefined}
                />
              </PlainMessageWrapper>
            )
          case 'tiptap-comment':
            return (
              <TiptapCommentMessageWrapper
                comment={message}
                contentId={contentId}
                onResolve={onResolve}
                onUnresolve={onUnresolve}
              />
            )
          default:
            assertNever(message)
        }
      })}

      <MessageReactions chatId={chatId} onReaction={onReaction} messageId={messageId} />
      <MessageContentReplies chatId={chatId} messageId={messageId} setThreadId={setThreadId} />
    </MessageWrapper>
  )
}

import { UseQueryResult } from '@tanstack/react-query'
import React, { useState } from 'react'
import { MANAGE_HOMEWORK_TABULAR_QUERY_KEYS } from 'sierra-client/api/hooks/use-homework'
import { useCurrentUser } from 'sierra-client/api/hooks/use-user'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { DomainRep, Value } from 'sierra-client/lib/filter'
import { TabularToolbar } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import {
  HOMEWORK_COLUMN_REFS,
  homeworksDataLoader,
  virtualColumns,
} from 'sierra-client/lib/tabular/dataloader/homework'
import { TabularProviderFromTableAPI, useTabularContext } from 'sierra-client/lib/tabular/provider'
import { BasicTabular } from 'sierra-client/lib/tabular/provider/components/basic'
import { Filtering } from 'sierra-client/lib/tabular/tables/homework'
import { useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { useCachedQuery } from 'sierra-client/state/api'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import { ManageHomeworksModal } from 'sierra-client/views/manage/homeworks/manage-homeworks-modal'
import { getChoices } from 'sierra-domain/filter/datatype/domain'
import { Filter, andAll, createFilter } from 'sierra-domain/filter/datatype/filter'
import { Or } from 'sierra-domain/filter/datatype/pred'
import { defaultOp } from 'sierra-domain/filter/query'
import { FilterHomeworkDomainResponse } from 'sierra-domain/filter/request'
import { XRealtimeAdminHomeworkSubmissionFilterDomain } from 'sierra-domain/routes'
import { User } from 'sierra-domain/user'
import { Icon } from 'sierra-ui/components'
import { IconButton, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const useFilterHomeworkDomainReps = (): UseQueryResult<FilterHomeworkDomainResponse> => {
  const res = useCachedQuery(XRealtimeAdminHomeworkSubmissionFilterDomain, {
    key: 'homework.filter',
  })

  return res
}

/**
 * Will give you the default choices for a given domain
 * Will try to default to status beig not-graded and reviewer being the current user
 */
const getDefaultChoices = (domain: DomainRep, currentUser: User): Value[] => {
  const choices = getChoices(domain.domain)

  if (domain.ref.type === 'homeworkSubmission.reviewerIds') {
    return choices.filter(v => v.value === currentUser.uuid)
  } else if (domain.ref.type === 'homeworkSubmission.status') {
    return choices.filter(v => v.value === 'not-graded')
  } else {
    return []
  }
}

const getInitialFilter = (domainReps: DomainRep[], currentUser: User): Filter => {
  const filterGroups = domainReps.map(domain => {
    const defaultChoices = getDefaultChoices(domain, currentUser)
    return createFilter(domain.ref, defaultOp(domain), Or(defaultChoices))
  })

  return andAll(filterGroups)
}

const EmptyViewWrapper = styled(View)`
  height: 100%;
  min-height: 200px;
`

const HomeworkTabular: React.FC = () => {
  const nRows = useTabularContext().pages.flat().length
  const initialState = useTabularContext().dataloaderState === 'init'
  const { t } = useTranslation()
  const emptyView = (
    <EmptyViewWrapper
      margin='small'
      direction='column'
      justifyContent='center'
      alignItems='center'
      background='surface/soft'
    >
      <Icon iconId='task' size='size-16' />
      <View direction='column' gap='2' alignItems='center'>
        <Text bold color='foreground/secondary'>
          {t('homework.no-submission')}
        </Text>
        <Text color='foreground/secondary'>{t('homework.no-available-submissions')}</Text>
      </View>
    </EmptyViewWrapper>
  )

  if (initialState) {
    return (
      <View grow justifyContent='center'>
        <LoadingSpinner />
      </View>
    )
  }

  return nRows === 0 ? emptyView : <BasicTabular />
}

const _ManageHomeworkTabular: React.FC<{ domainReps: DomainRep[]; currentUser: User }> = ({
  domainReps,
  currentUser,
}) => {
  const [modal, openModal] = useState<{ reviewSubmissionId: string } | undefined>()
  const { t } = useTranslation()
  const initialFilters = React.useMemo(
    () => getInitialFilter(domainReps, currentUser),
    [currentUser, domainReps]
  )

  const tableAPI = useTableAPI({
    dataLoader: React.useMemo(
      () => ({ loader: homeworksDataLoader(t), options: { queryKey: MANAGE_HOMEWORK_TABULAR_QUERY_KEYS } }),
      [t]
    ),
    virtualColumns: React.useMemo(
      () =>
        virtualColumns({
          onReview: submissionId => {
            openModal({
              reviewSubmissionId: submissionId,
            })
          },
        }),
      []
    ),
    options: React.useMemo(
      () => ({
        filter: {
          domainReps: domainReps,
          initialFilter: initialFilters,
          allowEmptyFilters: true,
        },
        sorting: {
          initialSorting: [{ direction: 'descending', column: HOMEWORK_COLUMN_REFS.SUBMITTED_AT }],
        },
        limit: 30,
      }),
      [domainReps, initialFilters]
    ),
  })

  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI}>
      <ColumnLayout>
        <View paddingBottom='xsmall'>
          <Filtering api={tableAPI.api} />
          <IconButton
            variant='transparent'
            iconId='reset'
            tooltip={t('dictionary.reset')}
            onClick={() => {
              tableAPI.api.action.setFilter({ filter: initialFilters })
            }}
          />
        </View>
        <TabularToolbar
          countsTranslationKeys={{
            totalKey: 'homework.n-submission',
          }}
          api={tableAPI.api}
          clearFilters={false}
        />
        <HomeworkTabular />
        <ManageHomeworksModal onClose={() => openModal(undefined)} data={modal} />
      </ColumnLayout>
    </TabularProviderFromTableAPI>
  )
}

export const ManageHomeworkTabular: React.FC = () => {
  const { t } = useTranslation()
  const drRes = useFilterHomeworkDomainReps()
  const userRes = useCurrentUser()

  if (drRes.isPending || userRes.isPending) {
    return (
      <View grow justifyContent='center'>
        <LoadingSpinner />
      </View>
    )
  }

  if (drRes.isError || userRes.isError) {
    return <View>{t('manage.homework.error.generic')}</View>
  }

  return <_ManageHomeworkTabular domainReps={drRes.data.domains} currentUser={userRes.data} />
}

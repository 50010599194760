import { capitalize, truncate } from 'lodash'
import React, {
  KeyboardEventHandler,
  ReactElement,
  cloneElement,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { AutoexpandingReflectionInput } from 'sierra-client/views/v3-author/reflection-card/atoms'
import { GroupingButton } from 'sierra-client/views/v3-author/reflection-card/grouping-button'
import { useReflectionsData } from 'sierra-client/views/v3-author/reflection-card/reflection-card-data-layer'
import {
  ReflectionGroupDataLayer,
  useReflectionGroupData,
} from 'sierra-client/views/v3-author/reflection-card/reflection-group-data-layer'
import { SortButton } from 'sierra-client/views/v3-author/reflection-card/sort-button'
import {
  ReflectionCardHeader,
  User,
  UserName,
  UserReflectionReponseMemo,
  UserWriting,
  useAnonymousUserData,
} from 'sierra-client/views/v3-author/reflection-card/user-reflection'
import { MenuItem, RoundAvatar } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { LightTokenProvider, palette, token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const StyledReflectionInput = styled.div`
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  width: 100%;
`

const StyledReflectionInputInner = styled.div`
  font-size: 1rem;
  min-height: 200px;
  padding: 14px 20px 16px 20px;
  background-color: ${palette.primitives.white};
  color: ${palette.primitives.black};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${palette.grey[5]};
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.04);
`

const UserNameWithPadding = styled(UserName)`
  padding: 8px;
`

let responseCache: Record<string, string> | undefined = undefined
const setResponseCache = (key: string, value: string): void => {
  responseCache = { [key]: value }
}

const getCachedInput = (key: string): string => {
  return responseCache?.[key] ?? ''
}

const ReflectionInput = forwardRef<HTMLDivElement, { allowSkip?: boolean }>(({ allowSkip = false }, ref) => {
  const { t } = useTranslation()
  const reflectionData = useReflectionsData()
  if (reflectionData.readOnly === true) throw new Error(`Can't add reflection in read only mode`)

  const { submitReflection, skip, anonymous, setAnonymous, setIsTypingReflection, reflectionId } =
    reflectionData
  const realUser = useSelector(selectUser)
  const [response, setResponse] = useState<string>(getCachedInput(reflectionId))
  // const [open, { off, toggle }] = useToggle(false)

  const anonymousUser = useAnonymousUserData()
  const user = anonymous === true ? anonymousUser : realUser

  const handleResponseChanged = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const value = e.target.value
    const res = truncate(value, { length: 5000, omission: '' })
    setResponseCache(reflectionId, res)
    setResponse(res)
  }

  const handleSubmit = (): void => {
    if (response.length === 0) return
    submitReflection(response)
    setResponseCache(reflectionId, '')
    setResponse('')
  }

  const handleKeyPress: KeyboardEventHandler<HTMLTextAreaElement> = event => {
    event.stopPropagation()
    if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
      event.preventDefault()
      handleSubmit()
    }
  }

  useEffect(() => {
    if (!setIsTypingReflection) return

    if (response.length > 0) {
      setIsTypingReflection(true)
      const timeout = setTimeout(() => setIsTypingReflection(false), 5000)
      return () => clearTimeout(timeout)
    } else {
      setIsTypingReflection(false)
    }
  }, [response, setIsTypingReflection])

  const menuItems: MenuItem<'not-anonymous' | 'anonymous'>[] = [
    {
      id: 'not-anonymous',
      type: 'label',
      label: t('learner.reflection-card.post-as-yourself'),
      selected: anonymous === false,
    },
    {
      id: 'anonymous',
      type: 'label',
      selected: anonymous === true,
      label: t('learner.reflection-card.post-anonymously'),
    },
  ]

  const selectedItem =
    anonymous === true
      ? menuItems.find(item => item.id === 'anonymous')
      : menuItems.find(item => item.id === 'not-anonymous')

  return (
    <StyledReflectionInput ref={ref}>
      <LightTokenProvider>
        <StyledReflectionInputInner>
          <ReflectionCardHeader>
            <User $isMe>
              <RoundAvatar
                size='small'
                firstName={user?.firstName}
                lastName={user?.lastName}
                src={getAvatarImage(user?.uuid, user?.avatar)}
                color={user?.avatarColor}
              />
              {setAnonymous ? (
                <SingleSelectDropdown
                  bold
                  selectedItem={selectedItem}
                  menuItems={menuItems}
                  variant='ghost'
                  onSelect={item => (item.id === 'anonymous' ? setAnonymous(true) : setAnonymous(false))}
                />
              ) : (
                <UserNameWithPadding>
                  {anonymous === true ? anonymousUser.firstName : capitalize(t('dictionary.you'))}
                </UserNameWithPadding>
              )}
            </User>
          </ReflectionCardHeader>
          <AutoexpandingReflectionInput
            placeholder={t('learner.reflection-card.placeholder')}
            title='Add a reflection...'
            value={response}
            onKeyDown={handleKeyPress}
            onChange={handleResponseChanged}
          />
          <View marginTop={'auto'} justifyContent='flex-end' gap='8'>
            {allowSkip && (
              <Button variant='secondary' onClick={skip}>
                {t('dictionary.skip')}
              </Button>
            )}
            <Button disabled={response.length === 0} onClick={handleSubmit}>
              {t('dictionary.post')}
            </Button>
          </View>
        </StyledReflectionInputInner>
      </LightTokenProvider>
    </StyledReflectionInput>
  )
})

const GRID_ROW_SIZE = 1
const GRID_GAP = 10

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 0 ${GRID_GAP}px;
  grid-auto-rows: ${GRID_ROW_SIZE}px;

  @media screen and (max-width: ${v2_breakpoint.phone}) {
    grid-template-columns: unset;
  }
`

const Cell: React.FC<{ contents: ReactElement<{ ref?: (ref: HTMLDivElement | null) => void }> }> = ({
  contents,
}) => {
  const [spans, setSpans] = useState(0)
  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  const updateSize: ResizeObserverCallback = useCallback(entries => {
    requestAnimationFrame(() => {
      const [entry] = entries
      if (entry === undefined) return

      const height = entry.target.clientHeight
      const spans = Math.ceil(height / GRID_ROW_SIZE)

      // enusre some padding between the rows of the cells
      const padding = GRID_GAP

      setSpans(spans + padding)
    })
  }, [])

  useEffect(() => {
    if (ref === null) return

    const observer = new ResizeObserver(updateSize)
    observer.observe(ref)

    return () => observer.disconnect()
  }, [ref, updateSize])

  const handleRef = useCallback((ref: HTMLDivElement | null) => {
    setRef(ref)
  }, [])

  const style = useMemo(
    () => ({
      gridRowEnd: `span ${spans}`,
    }),
    [spans]
  )
  const contentElement = useMemo(() => cloneElement(contents, { ref: handleRef }), [contents, handleRef])

  // eslint-disable-next-line react/forbid-dom-props
  return <div style={style}>{contentElement}</div>
}

const BasicMaisonryGrid: React.FC = (): JSX.Element => {
  const reflectionData = useReflectionsData()
  const { readOnly, reflectionId, responses, totalReflectionsCount, usersTyping, userHasResponded } =
    reflectionData
  const hasMore = responses.length !== totalReflectionsCount
  const showResponses = readOnly === true || userHasResponded === true

  return (
    <GridContainer>
      {readOnly !== true && (
        <Cell contents={<ReflectionInput allowSkip={userHasResponded === false} />}></Cell>
      )}
      {showResponses && (
        <>
          {responses
            .filter(response => response.skipped !== true)
            .map(response => (
              <Cell
                key={response.id}
                contents={
                  <UserReflectionReponseMemo
                    reflectionId={reflectionId}
                    reflectionResponse={response}
                    editResponse={readOnly === true ? undefined : reflectionData.editResponse}
                    reactToResponse={readOnly === true ? undefined : reflectionData.reactToResponse}
                    unReactToResponse={readOnly === true ? undefined : reflectionData.unReactToResponse}
                    deleteReflection={readOnly === true ? undefined : reflectionData.deleteReflection}
                    submitReflectionAnswer={
                      readOnly === true ? undefined : reflectionData.submitReflectionAnswer
                    }
                    editReflectionAnswer={readOnly === true ? undefined : reflectionData.editReflectionAnswer}
                    deleteReflectionAnswer={
                      readOnly === true ? undefined : reflectionData.deleteReflectionAnswer
                    }
                    allowAnonymity={
                      readOnly === true ? undefined : reflectionData.setAnonymous !== undefined ? true : false
                    }
                  />
                }
              />
            ))}
          {!hasMore &&
            usersTyping.map(({ userId, anonymous }) => (
              <Cell
                key={userId}
                contents={<UserWriting key={userId} userId={userId} anonymous={anonymous} />}
              />
            ))}
        </>
      )}
    </GridContainer>
  )
}

const GroupView = styled(View).attrs({ direction: 'column', padding: '16', radius: 'size-16' })`
  background-color: ${token('foreground/primary').opacity(0.1)};
`

const BasicMaisonryGridWithGroups: React.FC = (): JSX.Element => {
  const reflectionData = useReflectionsData()
  const { readOnly, reflectionId, responses, totalReflectionsCount, usersTyping, userHasResponded } =
    reflectionData
  const hasMore = responses.length !== totalReflectionsCount
  const showResponses = readOnly === true || userHasResponded === true

  return (
    <GridContainer>
      {readOnly !== true && (
        <Cell contents={<ReflectionInput allowSkip={userHasResponded === false} />}></Cell>
      )}
      {showResponses && (
        <>
          {reflectionData.buckets?.map(bucket => {
            return (
              <Cell
                key={bucket.bucketName}
                contents={
                  <GroupView key={bucket.bucketName}>
                    <View paddingLeft='8' paddingBottom='4'>
                      <Text bold size='small'>
                        {bucket.bucketName}
                      </Text>
                    </View>
                    {responses
                      .filter(response => response.skipped !== true)
                      .filter(response => bucket.responseIds.includes(response.id))
                      .map(response => {
                        return (
                          <UserReflectionReponseMemo
                            key={response.id}
                            reflectionId={reflectionId}
                            reflectionResponse={response}
                            editResponse={readOnly === true ? undefined : reflectionData.editResponse}
                            reactToResponse={readOnly === true ? undefined : reflectionData.reactToResponse}
                            unReactToResponse={
                              readOnly === true ? undefined : reflectionData.unReactToResponse
                            }
                            deleteReflection={readOnly === true ? undefined : reflectionData.deleteReflection}
                            submitReflectionAnswer={
                              readOnly === true ? undefined : reflectionData.submitReflectionAnswer
                            }
                            editReflectionAnswer={
                              readOnly === true ? undefined : reflectionData.editReflectionAnswer
                            }
                            deleteReflectionAnswer={
                              readOnly === true ? undefined : reflectionData.deleteReflectionAnswer
                            }
                            allowAnonymity={
                              readOnly === true
                                ? undefined
                                : reflectionData.setAnonymous !== undefined
                                  ? true
                                  : false
                            }
                          />
                        )
                      })}
                  </GroupView>
                }
              />
            )
          })}
          {responses
            .filter(response => response.skipped !== true)
            .filter(response =>
              reflectionData.buckets !== undefined
                ? !reflectionData.buckets.flatMap(bucket => bucket.responseIds).includes(response.id)
                : true
            )
            .map(response => (
              <Cell
                key={response.id}
                contents={
                  <View paddingLeft='16' paddingRight='16'>
                    <UserReflectionReponseMemo
                      reflectionId={reflectionId}
                      reflectionResponse={response}
                      editResponse={readOnly === true ? undefined : reflectionData.editResponse}
                      reactToResponse={readOnly === true ? undefined : reflectionData.reactToResponse}
                      unReactToResponse={readOnly === true ? undefined : reflectionData.unReactToResponse}
                      deleteReflection={readOnly === true ? undefined : reflectionData.deleteReflection}
                      submitReflectionAnswer={
                        readOnly === true ? undefined : reflectionData.submitReflectionAnswer
                      }
                      editReflectionAnswer={
                        readOnly === true ? undefined : reflectionData.editReflectionAnswer
                      }
                      deleteReflectionAnswer={
                        readOnly === true ? undefined : reflectionData.deleteReflectionAnswer
                      }
                      allowAnonymity={
                        readOnly === true
                          ? undefined
                          : reflectionData.setAnonymous !== undefined
                            ? true
                            : false
                      }
                    />
                  </View>
                }
              />
            ))}
          {!hasMore &&
            usersTyping.map(({ userId, anonymous }) => (
              <Cell
                key={userId}
                contents={<UserWriting key={userId} userId={userId} anonymous={anonymous} />}
              />
            ))}
        </>
      )}
    </GridContainer>
  )
}

const ReflectionsWithGroupDataLayer: React.FC = () => {
  const { t } = useTranslation()

  const { responses, loadMore, totalReflectionsCount, userHasResponded } = useReflectionsData()

  const groupingEnabled = useFlag('smart-group-reflections')
  const { groupBy } = useReflectionGroupData()

  const hasMore = responses.length !== totalReflectionsCount

  return (
    <>
      <View padding='xsmall none' justifyContent='space-between' alignItems='center'>
        <Text color='foreground/primary' bold size='small'>
          {t('dictionary.reflection-plural')}
        </Text>
        <View>
          <GroupingButton />
          <SortButton />
        </View>
      </View>
      {groupingEnabled && groupBy === 'smart' ? <BasicMaisonryGridWithGroups /> : <BasicMaisonryGrid />}
      {hasMore && userHasResponded === true && (
        <View justifyContent='center' alignItems='center' margin='small none'>
          <Button variant='ghost' icon='add' onClick={loadMore}>
            {t('dictionary.show-more')}
          </Button>
        </View>
      )}
    </>
  )
}

export const LearnerReflections: React.FC = () => {
  const { reflectionId } = useReflectionsData()

  return (
    <ReflectionGroupDataLayer reflectionId={reflectionId}>
      <ReflectionsWithGroupDataLayer />
    </ReflectionGroupDataLayer>
  )
}

import _ from 'lodash'
import { unwrapToTextNodes } from 'sierra-client/views/v3-author/command'
import { isElementType, nodeAtPath, parentType } from 'sierra-client/views/v3-author/queries'
import { AssessmentCardChild } from 'sierra-domain/v3-author'
import {
  createAssessmentIntroduction,
  createAssessmentQuestion,
  createHeading,
  createParagraph,
  createQuestionCard,
} from 'sierra-domain/v3-author/create-blocks'
import { Editor, Element, Node, Transforms } from 'slate'

const validAssessmentChildrenTypes = AssessmentCardChild.options.map(x => x.shape.type.value)
function isValidAssessmentCardChild(node: unknown): node is AssessmentCardChild {
  return (
    Element.isElement(node) && validAssessmentChildrenTypes.includes(node.type as AssessmentCardChild['type'])
  )
}

const { insertNodes, removeNodes, wrapNodes, mergeNodes } = Transforms
const { isElement } = Element

const debug = (...messages: unknown[]): void => console.debug('[withAssessmentCard]', ...messages)

// 5. An assessment question only has one question card child

const withAssessmentCard = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (!isElementType('assessment-card', node)) return normalizeNode(entry)

    // Root position
    const isAtRootPosition = parentType(editor, path) === 'editor'

    if (!isAtRootPosition) {
      debug('assessment-cards can only be at the root of documents, unwrapping at', JSON.stringify(path))
      unwrapToTextNodes(editor, { at: path })
      return wrapNodes(editor, createParagraph(), { at: path })
    }

    const children = Array.from(Node.children(editor, path))

    // Remove all invalidChildren
    const invalidChild = children.find(([child]) => !isValidAssessmentCardChild(child))
    if (invalidChild !== undefined) {
      debug('remove invalid nodes at root level assessment-card', JSON.stringify(path))
      return removeNodes(editor, { at: invalidChild[1] })
    }

    const introduction = children[0]?.[0]

    // Add missing introduction and question if empty
    if (introduction === undefined) {
      debug('adding missing assessment-introduction and question in assessment-card', JSON.stringify(path))
      return insertNodes(editor, [createAssessmentIntroduction(), createAssessmentQuestion()], {
        at: path.concat(0),
      })
    }

    if (isElement(introduction) && introduction.type !== 'assessment-introduction') {
      debug('adding missing assessment-introduction in assessment-card', JSON.stringify(path))
      return insertNodes(editor, createAssessmentIntroduction(), {
        at: path.concat(0),
      })
    }

    const firstQuestion = children[1]?.[0]

    if (!(isElement(firstQuestion) && firstQuestion.type === 'assessment-question')) {
      debug('adding missing assessment-question in assessment-card', JSON.stringify(path))
      return insertNodes(editor, createAssessmentQuestion(), {
        at: path.concat(1),
      })
    }

    const misplacedIntroductions = children
      .slice(1, -1)
      .filter(it => isElement(it) && it.type === 'assessment-introduction')

    // Remove all misplaced introductions
    if (misplacedIntroductions.length > 0) {
      debug('removing misplaced introductions at root level assessment-card', JSON.stringify(path))
      return misplacedIntroductions.forEach(intro => {
        removeNodes(editor, { at: intro[1] })
      })
    }

    return normalizeNode(entry)
  }

  return editor
}

const withAssessmentIntroduction = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (!isElementType('assessment-introduction', node)) return normalizeNode(entry)

    // In AssessmentCard
    const isInAnAssessmentCard = parentType(editor, path) === 'assessment-card'

    if (!isInAnAssessmentCard) {
      debug(
        'assessment-introductions can only be at the root of documents, unwrapping at',
        JSON.stringify(path)
      )
      unwrapToTextNodes(editor, { at: path })

      if (nodeAtPath(editor.children, path) !== undefined) wrapNodes(editor, createParagraph(), { at: path })
      return
    }

    const children = Array.from(Node.children(editor, path))

    const invalidChild = children.find(([child]) => !isElementType('heading', child))

    // No other nodes than headlines
    if (invalidChild !== undefined) {
      debug('removing invalid node in assessment-introduction', path)
      return removeNodes(editor, { at: invalidChild[1] })
    }

    // We always have at least one headline
    const headline = children[0]?.[0]

    if (headline === undefined) {
      debug('adding missing headline in assessment-introduction', JSON.stringify(path))
      return insertNodes(editor, createHeading({ children: [{ text: 'Assessment' }] }), {
        at: path.concat(0),
      })
    }

    // All headlines are merged together
    if (children.length > 1) {
      debug('Merge all headings at assessment-introduction', JSON.stringify(path))
      return mergeNodes(editor, { at: path.concat(1) })
    }
  }

  return editor
}

const withAssessmentQuestion = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (!isElementType('assessment-question', node)) return normalizeNode(entry)

    // In AssessmentCard
    const isInAnAssessmentCard = parentType(editor, path) === 'assessment-card'

    if (!isInAnAssessmentCard) {
      debug('assessment-questions can only be at the root of documents, unwrapping at', JSON.stringify(path))
      unwrapToTextNodes(editor, { at: path })
      return wrapNodes(editor, createParagraph(), { at: path })
    }

    const children = Array.from(Node.children(editor, path))

    const invalidChild = children.find(
      ([child]) => !isElementType('question-card', child) && !isElementType('question-variations', child)
    )

    // No other nodes than question-cards or question-variations
    if (invalidChild !== undefined) {
      debug('remove invalid node in assessment-question', JSON.stringify(path))
      return removeNodes(editor, { at: invalidChild[1] })
    }

    // We always have at least one headline
    const questionCard = children[0]?.[0]

    if (questionCard === undefined) {
      debug('adding missing question-card in assessment-question', JSON.stringify(path))
      return insertNodes(editor, createQuestionCard(), {
        at: path.concat(0),
      })
    }

    // All additional question cards are removed
    if (children.length > 1) {
      debug('Remove all additional question cards in assessment-question', JSON.stringify(path))
      return removeNodes(editor, { at: path.concat(1) })
    }
  }

  return editor
}

export const withAssessments: (editor: Editor) => Editor = _.flow([
  withAssessmentCard,
  withAssessmentIntroduction,
  withAssessmentQuestion,
])

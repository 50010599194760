import { useAtom } from 'jotai'
import React, { useEffect, useMemo } from 'react'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { store } from 'sierra-client/state/store'
import { useAssessmentsManagerContext } from 'sierra-client/views/flexible-content/assessments-manager'
import { useFileContext } from 'sierra-client/views/flexible-content/file-context'
import { AssessmentDataProvider } from 'sierra-client/views/v3-author/assessment-card/assessment-card-data-layer'
import {
  AssessmentContextProvider,
  AssessmentState,
  Assessments,
} from 'sierra-client/views/v3-author/assessment-card/assessment-context'
import { ProgressBar } from 'sierra-client/views/v3-author/assessment-card/assessment-progress'
import { AssessmentTimer } from 'sierra-client/views/v3-author/assessment-card/assessment-question'
import { useChildren } from 'sierra-client/views/v3-author/hooks'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { ScopedFileId } from 'sierra-domain/collaboration/types'
import { XRealtimeStrategySelfPacedContentAssessmentGetAssessmentStatus } from 'sierra-domain/routes'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

/* We're explicitly setting the colour to white here and set it to the theme background in assessment introduction and question */
const Wrapper = styled(View).attrs({
  direction: 'column',
  alignItems: 'stretch',
  grow: true,
  background: 'white',
  gap: 'none',
})`
  overflow: auto;
  border: 0;
  width: 100%;
`

const useQuestionExerciseIds = ({ parentId }: { parentId: string }): string[] => {
  const questionBodies =
    useChildren({
      parentId,
      types: [
        'question-card-select-all-that-apply-body',
        'question-card-pick-the-best-option-body',
        'question-card-match-the-pairs-body',
      ],
    }) ?? []

  const questionExerciseIds = [...questionBodies.map(element => element.id)]

  return questionExerciseIds
}

const TopbarContainer = styled(View)`
  position: absolute;
  z-index: 1;
  top: 32px;
  left: 0;
  right: 0;
`

const AssessmentCardLearner = React.forwardRef<HTMLDivElement, SlateWrapperProps>(
  ({ mode, element, children, attributes }, ref) => {
    const { file, flexibleContentId } = useFileContext()

    if (file.data.type !== 'assessment-card') {
      throw new Error(
        `[AssessmentCard] Trying to access assessment card in flexible content but is of type ${file.data.type}`
      )
    }

    const questionCount = element.children.length - 1 // Remove the introduction
    const passingCriteria = file.data.settings.passingCriteria

    const questionExerciseIds = useQuestionExerciseIds({ parentId: element.id })

    const { AssessmentsBackend, AssessmentsState } = useAssessmentsManagerContext()

    const [assessmentsBackendStatus, setAssessmentsBackendStatus] = useAtom(AssessmentsBackend)

    const [assessmentsState, setAssessmentsState] = useAtom(AssessmentsState)

    useEffect(() => {
      void postWithUserErrorException(
        XRealtimeStrategySelfPacedContentAssessmentGetAssessmentStatus,
        {
          contentId: flexibleContentId,
          fileId: ScopedFileId.extractId(file.id),
        },
        store.dispatch
      ).then(it => {
        setAssessmentsBackendStatus(previous => ({ ...previous, [file.id]: it.status }))
      })
    }, [file.id, flexibleContentId, setAssessmentsBackendStatus])

    const startState: AssessmentState = useMemo(
      () => ({
        status: 'start',
        questionCount,
        passingCriteria,
        fileId: file.id,
        questionExerciseIds,
        contentId: flexibleContentId,
      }),
      [file.id, flexibleContentId, passingCriteria, questionCount, questionExerciseIds]
    )

    const assessmentState = useMemo(() => {
      const state = assessmentsState[file.id]
      return {
        state: state ?? startState,
        setState: (newState: AssessmentState) =>
          setAssessmentsState(previous => ({ ...previous, [file.id]: newState })),
        status: assessmentsBackendStatus[file.id],
      }
    }, [assessmentsState, file.id, startState, assessmentsBackendStatus, setAssessmentsState])

    assertElementType('assessment-card', element)

    return (
      <AssessmentContextProvider value={assessmentState}>
        <AssessmentDataProvider element={element} mode={mode}>
          <Wrapper padding='none' {...attributes} ref={ref}>
            <TopbarContainer direction='row' justifyContent='center' gap='8'>
              <ProgressBar
                assessmentState={assessmentState.state}
                next={() => {
                  const newState = Assessments.next(assessmentState.state)
                  assessmentState.setState(newState)
                }}
                previous={() => {
                  const newState = Assessments.previous(assessmentState.state)
                  assessmentState.setState(newState)
                }}
              />
              {assessmentState.state.status === 'during' && assessmentState.state.endTime !== undefined && (
                <AssessmentTimer />
              )}
            </TopbarContainer>
            {children}
          </Wrapper>
        </AssessmentDataProvider>
      </AssessmentContextProvider>
    )
  }
)

const AssessmentCardCreate = React.forwardRef<HTMLDivElement, SlateWrapperProps>(
  ({ children, attributes }, ref) => (
    <Wrapper padding='small' {...attributes} ref={ref}>
      {children}
    </Wrapper>
  )
)

export const AssessmentCardWrapper = React.forwardRef<HTMLDivElement, SlateWrapperProps>((props, ref) => {
  const { children, element, mode } = props
  assertElementType('assessment-card', element)

  const Renderer =
    mode === 'create' || mode === 'template' || mode === 'version-history'
      ? AssessmentCardCreate
      : AssessmentCardLearner

  return (
    <RenderingContext preventDrag={true} allowBlockComments={true} disableMenu={true}>
      <Renderer {...props} ref={ref}>
        {children}
      </Renderer>
    </RenderingContext>
  )
})

import { forwardRef, useEffect, useRef, useState } from 'react'
import { editorGrid } from 'sierra-client/editor/layout'
import { TextToSpeech } from 'sierra-client/editor/text-to-speech'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Placeholder } from 'sierra-client/views/v3-author/components'
import { useEditorReadOnly } from 'sierra-client/views/v3-author/context'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { usePlaceholder } from 'sierra-client/views/v3-author/rendering-context'
import { SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { hasOnlyEmptyTextInNodes, typesInNodes } from 'sierra-domain/slate-util'
import { Heading as UIHeading } from 'sierra-ui/primitives'
import { Element } from 'slate'
import { useSelected } from 'slate-react'
import styled from 'styled-components'

type HeadingSize = 'hSuper' | 'h1' | 'h2' | 'h3' | 'h4'

/**
 * Previously, the "level" property corresponded directly to h1, h2, etc.
 * However, we needed to add an even bigger heading at one point, which then became h1 (level 5).
 * This meant the other levels needed to be shifted strangely like this.
 */
export function getHeadingSize(level: number): HeadingSize {
  if (level === 0) return 'h1'
  if (level === 1) return 'h2'
  if (level === 2) return 'h3'
  if (level === 3) return 'h4'
  if (level === 5) return 'hSuper'

  // This should not be reachable, but we do not enforce it
  return 'h4'
}

const StyledHeading = styled(UIHeading)<{
  $minWidth?: number
  $size: HeadingSize
}>`
  position: relative;
  color: ${p => p.theme.home.textColor};

  overflow: visible;

  ${p => (p.$minWidth !== undefined ? `min-width: ${p.$minWidth}px;` : undefined)}

  ${editorGrid}
`

function useShouldShowPlaceholder(element: Element): boolean {
  const readOnly = useEditorReadOnly()
  const isEmpty = hasOnlyEmptyTextInNodes([element]) && !typesInNodes([element]).includes('tag')
  const shouldShowPlaceholder = useSelected() && !readOnly && isEmpty
  return shouldShowPlaceholder
}

export const HeadingWrapper = forwardRef<HTMLHeadingElement, SlateWrapperProps>(
  ({ children, attributes, element, ...props }, ref) => {
    const placeholderRef = useRef<HTMLSpanElement | null>(null)
    const { t } = useTranslation()
    assertElementType('heading', element)

    const { level } = element
    const shouldShowPlaceholder = useShouldShowPlaceholder(element)

    const contextPlaceholder = usePlaceholder()
    const [placeholderWidth, setPlaceholderWidth] = useState(0)

    /* In certain cases, the heading is centred. To prevent the placeholder from being positioned absolutely to the right of the centre, we measure its size*/
    useEffect(() => {
      if (shouldShowPlaceholder) {
        if (placeholderRef.current !== null) {
          setPlaceholderWidth(placeholderRef.current.getBoundingClientRect().width)
        }
      } else {
        setPlaceholderWidth(0)
      }
    }, [shouldShowPlaceholder])

    const size = getHeadingSize(level ?? 2)
    return (
      <StyledHeading
        color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP'
        $minWidth={placeholderWidth}
        size={size}
        $size={size}
        data-block-inner={element.id}
        {...attributes}
        {...props}
        ref={ref}
        avoidHanging={false}
      >
        <span>
          {shouldShowPlaceholder && (
            <Placeholder ref={placeholderRef}>
              {contextPlaceholder ?? t('author.block-editor.heading')}
            </Placeholder>
          )}
          {children}
        </span>

        <TextToSpeech element={element} />
      </StyledHeading>
    )
  }
)

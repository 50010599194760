import React, { useMemo } from 'react'
import { ContentTableRow } from 'sierra-client/components/common/content-table-row'
import { useContentDrag } from 'sierra-client/components/common/dnd/use-content-table-drag'
import { IconMenu } from 'sierra-client/components/common/icon-menu'
import { getAssetContextFromTeamspaceContent } from 'sierra-client/components/util/asset-contex'
import { useGetFormattedTime } from 'sierra-client/core/format'
import { teamspaceContentClickedLogger } from 'sierra-client/features/teamspace/logger'
import { usePost } from 'sierra-client/hooks/use-post'
import { useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useIsSmallDesktop } from 'sierra-client/state/browser/selectors'
import { useDispatch } from 'sierra-client/state/hooks'
import { getContentClassificationData } from 'sierra-client/views/manage/content/utils/content-utils'
import { CTAActions } from 'sierra-client/views/workspace/components/content-table-cta-buttons'
import {
  MetadataWrapper,
  RowContainer,
} from 'sierra-client/views/workspace/components/content-table-row-components'
import { StyledThumbnail } from 'sierra-client/views/workspace/create/styled-thumbnail'
import {
  SelfEnrollActionMetaData,
  getCurrentFolderDisplayName,
  pinnableContentType,
} from 'sierra-client/views/workspace/teamspace/common-table-row'
import { Linkable, continueUrl } from 'sierra-client/views/workspace/utils/urls'
import { CourseKind } from 'sierra-domain/api/common'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import {
  Teamspace,
  TeamspaceEditableContent,
  TeamspaceNonEditorCourse,
  isCourseGroup,
} from 'sierra-domain/api/teamspace'
import { XRealtimeUserAddPins, XRealtimeUserRemovePins } from 'sierra-domain/routes'
import { ExtractFrom, STATIC_EMPTY_ARRAY, isDefined } from 'sierra-domain/utils'
import { Icon, MenuItem, Tooltip } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'

const LearnerRow: React.FC<{
  teamspaceContent: TeamspaceEditableContent | TeamspaceNonEditorCourse
  teamspace?: Teamspace
  isPinned: boolean
  showCurrentFolder: boolean
  onPin?: (id: string, isPinned: boolean) => void
  isOver: boolean
  contentMeta?: {
    type: `${ExtractFrom<CourseKind, 'scorm:course-group' | 'native:course-group'>}-edition`
    courseGroupId: NanoId12
  }
}> = ({ teamspaceContent, teamspace, isPinned, showCurrentFolder, onPin, isOver, contentMeta }) => {
  const dispatch = useDispatch()
  const { t, dynamicT } = useTranslation()
  const { postWithUserErrorException } = usePost()
  const isDesktop = !useIsSmallDesktop()
  const content = teamspaceContent.content
  const isCourseEdition = isDefined(contentMeta?.type)
  const formattedDuration = useGetFormattedTime(
    teamspaceContent.type === 'editable'
      ? isDefined(teamspaceContent.content.duration)
        ? teamspaceContent.content.duration
        : undefined
      : undefined,
    false
  )

  const assetContext = useMemo(
    () => getAssetContextFromTeamspaceContent(teamspaceContent),
    [teamspaceContent]
  )
  const { drag, isDragging, canDrag } = useContentDrag(content, assetContext)

  const { type } = content
  const isSelfpaced = type === 'native:self-paced'
  const isTemplate = (isSelfpaced || type === 'native:live') && content.templateSettings !== undefined

  const linkable: Linkable = isCourseEdition
    ? {
        id: contentMeta.courseGroupId,
        type:
          contentMeta.type === 'scorm:course-group-edition' ? 'scorm:course-group' : 'native:course-group',
      }
    : {
        id: content.id,
        type: content.type,
      }
  const link = continueUrl(linkable)

  const addPin = async (): Promise<void> => {
    await postWithUserErrorException(XRealtimeUserAddPins, {
      items: [{ id: content.id, type: pinnableContentType(content.type) }],
    }).then(() => onPin?.(content.id, true))
  }

  const removePin = async (): Promise<void> => {
    await postWithUserErrorException(XRealtimeUserRemovePins, {
      items: [{ id: content.id, type: pinnableContentType(content.type) }],
    }).then(() => onPin?.(content.id, false))
  }

  const defaultItems: MenuItem[] = [
    {
      hidden: isSelfpaced && !content.published,
      id: 'view',
      type: 'label',
      label: t('dictionary.view'),
      icon: 'view',
      onClick: () => {
        void getGlobalRouter().navigate({ to: continueUrl(linkable) as string })
      },
    },
    {
      id: 'pin',
      type: 'label',
      label: isPinned ? t('workspace.create.unpin-from-top') : t('workspace.create.pin-to-top'),
      icon: 'pin',
      onClick: async (): Promise<void> => {
        if (isPinned) {
          await removePin()
        } else {
          await addPin()
        }
      },
    },
  ]

  const currentFolder = isDefined(teamspace)
    ? getCurrentFolderDisplayName(teamspace, teamspaceContent.parentFolderId)
    : undefined
  const currentFolderTitle = showCurrentFolder && isDefined(currentFolder) ? `${currentFolder} / ` : undefined

  const hideDetailsButton =
    teamspaceContent.content.type === 'native:live' ||
    teamspaceContent.content.type === 'native:event-group' ||
    ('published' in teamspaceContent.content && !teamspaceContent.content.published)

  const contentClassification = getContentClassificationData({
    contentType: 'course',
    courseKind: content.type,
    isCourseEdition: isCourseEdition,
    isTemplate: isTemplate,
  })

  const thumbnail = useResolveCourseAsset({
    image: content.image,
    options: { type: 'default', size: 'small' },
    assetContext,
  })

  return (
    <RowContainer
      // TODO: `react-dnd` isn't maintained. Replace it with another library and remove this type assertion.
      // Do not allow for dragging in course editions
      ref={isCourseEdition ? null : (drag as (el: HTMLDivElement) => void)}
      role='listitem'
      isDroppable={false}
      isOver={isOver}
      isDragging={isDragging}
    >
      <ContentTableRow
        href={link}
        canDrag={canDrag && !isCourseEdition}
        isOver={isOver}
        onRowClick={() => {
          if (content.teamspaceId !== undefined) {
            void dispatch(
              teamspaceContentClickedLogger({
                contentId: content.id,
                teamspaceId: content.teamspaceId,
                clickedFrom: 'page',
              })
            )
          }
        }}
        left={
          <>
            <View gap='8' grow>
              {isPinned && (
                <Tooltip title={t('workspace.create.pinned')}>
                  <Icon iconId='pin--filled' color='foreground/muted' />
                </Tooltip>
              )}
              <View wrap={isDesktop ? 'nowrap' : 'wrap'} gap='6'>
                {isDefined(currentFolderTitle) && (
                  <Text bold color='foreground/muted'>
                    {currentFolderTitle}
                  </Text>
                )}
                <Text size='small' bold>
                  {content.title}
                </Text>
              </View>
            </View>
            <MetadataWrapper wrap={isDesktop ? 'nowrap' : 'wrap'} gap='6' grow>
              <View gap='4'>
                <Icon iconId={contentClassification.iconId} size='size-12' color='foreground/muted' />
                <Text capitalize='first' color='foreground/muted' bold size='small'>
                  {t(contentClassification.translationKey)}
                </Text>
              </View>
              {isDefined(formattedDuration) && (
                <Text bold color='foreground/muted'>
                  {formattedDuration}
                </Text>
              )}
              {teamspaceContent.selfEnrollAction !== undefined && (
                <SelfEnrollActionMetaData selfEnrollAction={teamspaceContent.selfEnrollAction} />
              )}
              {teamspaceContent.type === 'editable' && isDefined(teamspaceContent.content.language) && (
                <Text size='small' color='foreground/muted'>
                  {dynamicT(`language.${teamspaceContent.content.language}`)}
                </Text>
              )}
            </MetadataWrapper>
          </>
        }
        thumbnail={<StyledThumbnail image={thumbnail} />}
        right={<div></div>}
        actions={
          <>
            <CTAActions
              canEdit={false}
              content={teamspaceContent.content}
              linkable={linkable}
              link={link}
              hideDetailsButton={hideDetailsButton}
            />
            <IconMenu
              iconId='overflow-menu--vertical'
              closeOnPick
              items={defaultItems}
              color='foreground/muted'
            />
          </>
        }
      />
    </RowContainer>
  )
}

const CourseGroupTableRow: React.FC<{
  teamspaceContent: TeamspaceNonEditorCourse
  groupedContent?: Array<TeamspaceEditableContent | TeamspaceNonEditorCourse>
  isPinned: boolean
  onPin: (id: string, isPinned: boolean) => void
  isOver: boolean
  teamspace: Teamspace
  showCurrentFolder: boolean
}> = ({
  teamspaceContent,
  groupedContent = STATIC_EMPTY_ARRAY,
  isPinned,
  onPin,
  isOver,
  teamspace,
  showCurrentFolder,
}) => {
  const activeEdition = teamspaceContent.activeUserEditionWithStatus

  const editionToShow: TeamspaceEditableContent | TeamspaceNonEditorCourse | undefined = groupedContent.find(
    edition => edition.content.id === activeEdition?.id
  )
  if (isDefined(editionToShow) && isDefined(editionToShow.content.courseGroupId)) {
    return (
      <LearnerRow
        teamspaceContent={editionToShow}
        isPinned={isPinned}
        isOver={isOver}
        onPin={onPin}
        teamspace={teamspace}
        showCurrentFolder={showCurrentFolder}
        contentMeta={
          isCourseGroup(teamspaceContent)
            ? {
                type: `${teamspaceContent.content.type}-edition`,
                courseGroupId: editionToShow.content.courseGroupId,
              }
            : undefined
        }
      />
    )
  }
  return null
}
export const LearnerContentTableRow: React.FC<{
  teamspaceContent: TeamspaceEditableContent | TeamspaceNonEditorCourse
  groupedContent: Array<TeamspaceEditableContent | TeamspaceNonEditorCourse>
  teamspace: Teamspace
  isPinned: boolean
  isOver: boolean
  showCurrentFolder: boolean
  onPin: (id: string, isPinned: boolean) => void
}> = ({ teamspaceContent, groupedContent, isPinned, onPin, isOver, teamspace, showCurrentFolder }) => {
  if (teamspaceContent.type === 'non-editor-course' && isCourseGroup(teamspaceContent)) {
    return (
      <CourseGroupTableRow
        teamspaceContent={teamspaceContent}
        groupedContent={groupedContent}
        isPinned={isPinned}
        onPin={onPin}
        isOver={isOver}
        teamspace={teamspace}
        showCurrentFolder={showCurrentFolder}
      />
    )
  }
  return (
    <LearnerRow
      teamspaceContent={teamspaceContent}
      isPinned={isPinned}
      isOver={isOver}
      onPin={onPin}
      teamspace={teamspace}
      showCurrentFolder={showCurrentFolder}
    />
  )
}

import { DateTime } from 'luxon'
import React from 'react'
import { UserTimelinePanelQueryQuery } from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { RootSection } from 'sierra-client/features/program/admin/user-timeline/root-section'
import { Section } from 'sierra-client/features/program/admin/user-timeline/section'
import { Step } from 'sierra-client/features/program/admin/user-timeline/step'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useCourseAssetResolver } from 'sierra-client/hooks/use-resolve-course-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { clusterSections } from 'sierra-client/views/manage/programs/staggered-assignments/renderer/clusterSection'
import { renderScheduleDescriptionWithPreviousCompletion } from 'sierra-client/views/manage/programs/staggered-assignments/utils'
import { ProgramOutline } from 'sierra-domain/api/manage'
import { CourseId, PathId } from 'sierra-domain/api/nano-id'
import { ProgramId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { assertNever, iife, isDefined } from 'sierra-domain/utils'
import { Icon, StepBadge, TruncatedText } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { ConditionalWrapper } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StepRow = styled.div<{ $header?: boolean; $highlighted?: boolean }>`
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 8px;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  align-items: center;
  border-bottom: 1px solid ${token('border/default')};
  background: ${p => (p.$highlighted === true ? token('surface/soft')(p) : 'transparent')};
  padding: 20px 12px;

  padding: ${p =>
    p.$header === true &&
    css`
      padding: 8px 0;
    `};
`

const ProgramStartStepContainer = styled.div`
  display: flex;
  gap: 8px;
`

function getResourceUrl(contentId: string): string {
  const [scope, id] = contentId.split(':')

  if (scope === 'path') {
    return `/manage/paths/${id}`
  } else if (scope === 'course') {
    return `/manage/courses/${id}`
  }

  throw Error('Failed to resolve url from contentId ' + contentId)
}

function getAssetContext(contentId: string): AssetContext {
  const [scope, id] = contentId.split(':')

  if (scope === 'path') {
    return { type: 'path', pathId: PathId.parse(id) }
  } else if (scope === 'course') {
    return { type: 'course', courseId: CourseId.parse(id) }
  }

  return { type: 'unknown' }
}

export const TimelinePanelContent: React.FC<{
  outline: ProgramOutline
  userWithProgramSchedule: UserTimelinePanelQueryQuery['user']
  programId: ProgramId
}> = ({ outline, userWithProgramSchedule, programId }) => {
  const { t } = useTranslation()

  const sectionsFeatureEnabled = useFlag('programs/sections')
  const clusters = clusterSections(outline)
  const assetResolver = useCourseAssetResolver({
    options: {
      type: 'default',
      size: 'small',
    },
  })

  if (!isDefined(userWithProgramSchedule)) {
    return null
  }

  // If all steps are without due dates, we just hide that column.
  const hideDueDates = userWithProgramSchedule.programSchedule.every(step => !isDefined(step.dueDate))

  const programAvailableAt = userWithProgramSchedule.programSchedule[0]?.availableAt ?? null

  const enrolledBy = userWithProgramSchedule.enrollment?.enrolledBy?.__typename
  const enrollmentReason = iife(() => {
    switch (enrolledBy) {
      case undefined: {
        return null
      }
      case 'EnrolledByRule': {
        return t('manage.programs.enrollment-reason.by-rule')
      }
      case 'EnrolledBySelf': {
        return t('manage.programs.enrollment-reason.by-self')
      }
      case 'EnrolledByAdmin': {
        return t('manage.programs.enrollment-reason.by-admin')
      }
      default:
        assertNever(enrolledBy)
    }
  })

  if (sectionsFeatureEnabled) {
    return (
      <>
        <View justifyContent='space-between' marginBottom='16' paddingLeft='16' paddingRight='16'>
          {programAvailableAt !== null && (
            <View>
              <Text bold>{t('manage.programs.program-start')}</Text>
              <View gap='2'>
                <Text bold color='foreground/secondary'>
                  {enrollmentReason}
                </Text>
                <Text color='foreground/secondary'>
                  {DateTime.fromISO(programAvailableAt).toFormat('yyyy-MM-dd')}
                </Text>
              </View>
            </View>
          )}

          <Text bold>{t('manage.program.program-details.outline.available')}</Text>
        </View>

        <View direction='column' gap='32' marginBottom='32'>
          {clusters.map((cluster, clusterIndex) => {
            const sectionIndex = cluster.sectionIndex
            const section = isDefined(sectionIndex) ? outline.sections[sectionIndex] : null
            const clusterKey = isDefined(section)
              ? 'draftId' in section
                ? section.draftId
                : section.id
              : clusterIndex

            return (
              <ConditionalWrapper
                key={clusterKey}
                condition={isDefined(cluster.sectionIndex)}
                renderWrapper={children =>
                  isDefined(section) ? <Section section={section}>{children}</Section> : null
                }
                renderWrapperElse={children => <RootSection>{children}</RootSection>}
              >
                {cluster.members.map(step => {
                  const matchingEnrollmentStep = userWithProgramSchedule.programSchedule.find(
                    enrollmentStep => {
                      switch (enrollmentStep.__typename) {
                        case 'UserProgramEmailStep': {
                          return step.type === 'email' && step.resourceId === enrollmentStep.resourceId
                        }
                        case 'UserProgramPathStep':
                        case 'UserProgramCourseStep': {
                          switch (step.type) {
                            case 'email':
                              return false
                            case 'course':
                            case 'path': {
                              const contentIdWithoutScope = enrollmentStep.content.contentId.split(':')[1]
                              const contentId = step.type === 'course' ? step.courseId : step.pathId

                              return contentId === contentIdWithoutScope
                            }
                          }
                        }
                      }
                    }
                  )

                  if (matchingEnrollmentStep === undefined) {
                    return null
                  }

                  return (
                    <Step
                      key={step.index}
                      outline={outline}
                      step={step}
                      enrollmentStep={matchingEnrollmentStep}
                      programId={programId}
                    />
                  )
                })}
              </ConditionalWrapper>
            )
          })}
        </View>
      </>
    )
  }

  return (
    <TimelineContainer>
      <StepRow $header={true}>
        <Text bold>{t('dictionary.step')}</Text>
        <Text bold>{t('dictionary.scheduled')}</Text>
        {!hideDueDates && <Text bold>{t('dictionary.due')}</Text>}
      </StepRow>
      {enrollmentReason !== null && programAvailableAt !== null && (
        <StepRow $highlighted>
          <View>
            <ProgramStartStepContainer>
              <Icon iconId='play--circle--filled' />
              <Text bold size='small'>
                {t('manage.programs.program-start')}
              </Text>
            </ProgramStartStepContainer>
          </View>

          <View direction='column' gap='none'>
            <Text>{enrollmentReason}</Text>
            <Text color='foreground/muted'>
              {DateTime.fromISO(programAvailableAt).toFormat('yyyy-MM-dd')}
            </Text>
          </View>

          {!hideDueDates && <span />}
        </StepRow>
      )}

      {userWithProgramSchedule.programSchedule.map((userProgramStep, index) => {
        const programStep = outline.steps.find(step => {
          switch (userProgramStep.__typename) {
            case 'UserProgramEmailStep': {
              return step.type === 'email' && step.resourceId === userProgramStep.resourceId
            }
            case 'UserProgramPathStep':
            case 'UserProgramCourseStep': {
              switch (step.type) {
                case 'email':
                  return false
                case 'course':
                case 'path': {
                  const contentIdWithoutScope = userProgramStep.content.contentId.split(':')[1]
                  const contentId = step.type === 'course' ? step.courseId : step.pathId

                  return contentId === contentIdWithoutScope
                }
              }
            }
          }
        })

        if (programStep === undefined) {
          return null
        }

        const stepSchedule = renderScheduleDescriptionWithPreviousCompletion({
          outline: outline,
          index,
          t,
        })

        type StepPresentation =
          | {
              id: string
              type: 'content'
              image: ImageUnion | undefined
              title: string
              progress: number
              url: string
              assetContext: AssetContext
            }
          | {
              type: 'email'
              id: string
              image: ImageUnion | undefined
              title: string
              progress: number
              assetContext: AssetContext
            }

        const stepPresentation: StepPresentation = iife(() => {
          switch (userProgramStep.__typename) {
            case 'UserProgramEmailStep': {
              return {
                id: userProgramStep.resourceId,
                type: 'email',
                image: convertGQLImage(userProgramStep.image),
                assetContext: { type: 'program', programId },
                title: userProgramStep.title,
                progress:
                  isDefined(userProgramStep.availableAt) &&
                  DateTime.fromISO(userProgramStep.availableAt) < DateTime.now()
                    ? 1
                    : 0,
              }
            }
            case 'UserProgramCourseStep':
            case 'UserProgramPathStep': {
              return {
                id: userProgramStep.contentId,
                type: 'content',
                title: isDefined(userProgramStep.content) ? userProgramStep.content.title : 'N/A',
                url: getResourceUrl(userProgramStep.contentId),
                image: isDefined(userProgramStep.content)
                  ? convertGQLImage(userProgramStep.content.image)
                  : undefined,
                assetContext: getAssetContext(userProgramStep.contentId),
                progress: isDefined(userProgramStep.progress) ? userProgramStep.progress.progress : 0,
              }
            }
            default:
              assertNever(userProgramStep)
          }
        })

        const stepNumber = outline.steps.reduce((num, step, j) => {
          if (step.type !== 'email' && j <= index) {
            return num + 1
          } else {
            return num
          }
        }, 0)

        return (
          <StepRow key={stepPresentation.id}>
            <div>
              <View gap='16'>
                {stepPresentation.type === 'email' ? (
                  <StepBadge size='small' variant='email' />
                ) : (
                  <StepBadge size='small' variant='default' num={stepNumber} />
                )}

                <Thumbnail
                  height={2}
                  width={3}
                  radius={0.375}
                  image={assetResolver(stepPresentation.image, stepPresentation.assetContext)}
                />

                <View direction='column' gap='none'>
                  {iife(() => {
                    switch (stepPresentation.type) {
                      case 'content': {
                        return (
                          <a href={stepPresentation.url}>
                            <TruncatedText bold>{stepPresentation.title}</TruncatedText>
                          </a>
                        )
                      }
                      case 'email': {
                        return <TruncatedText bold>{stepPresentation.title}</TruncatedText>
                      }
                      default:
                        assertNever(stepPresentation)
                    }
                  })}

                  <Text color='foreground/muted' bold>
                    {iife(() => {
                      const { progress } = stepPresentation

                      switch (true) {
                        case progress === 0: {
                          return (
                            <Text bold color='foreground/muted'>
                              {t('dictionary.not-started')}
                            </Text>
                          )
                        }
                        case progress === 1: {
                          return (
                            <View gap='4'>
                              <Icon iconId='checkmark--filled' color='success/background' size='size-12' />
                              <Text bold color='success/background'>
                                {t('dictionary.complete')}
                              </Text>
                            </View>
                          )
                        }
                        default: {
                          return (
                            <Text bold color='foreground/muted'>
                              {t('manage.programs.n-percentage-progress', {
                                percentage: Math.floor(progress * 100),
                              })}
                            </Text>
                          )
                        }
                      }
                    })}
                  </Text>
                </View>
              </View>
            </div>

            <div>
              <Text>{stepSchedule}</Text>

              {iife(() => {
                switch (programStep.schedule.type) {
                  case 'now': {
                    return (
                      <Text color='foreground/muted'>
                        {isDefined(userProgramStep.availableAt)
                          ? DateTime.fromISO(userProgramStep.availableAt).toISODate()
                          : t('manage.programs.not-assigned-yet')}
                      </Text>
                    )
                  }
                  case 'absolute': {
                    return null
                  }
                  case 'relative': {
                    return (
                      <Text color='foreground/muted'>
                        {isDefined(userProgramStep.availableAt)
                          ? DateTime.fromISO(userProgramStep.availableAt).toISODate()
                          : t('manage.programs.not-assigned-yet')}
                      </Text>
                    )
                  }
                  case 'on-previous-steps-completion': {
                    const availableAt = userProgramStep.availableAt
                    return (
                      <Text color='foreground/muted'>
                        {availableAt !== undefined && availableAt !== null
                          ? DateTime.fromISO(availableAt).toISODate()
                          : t('manage.programs.not-assigned-yet')}
                      </Text>
                    )
                  }
                  default:
                    assertNever(programStep.schedule)
                }
              })}
            </div>

            {!hideDueDates && (
              <td>
                {iife(() => {
                  if (programStep.type === 'email' || programStep.dueDate === undefined) {
                    return null
                  }

                  switch (programStep.dueDate.type) {
                    case 'absolute': {
                      const setDueDate = programStep.dueDate.date
                      const calculatedDueDate = userProgramStep.dueDate

                      if (setDueDate !== calculatedDueDate) {
                        return (
                          <>
                            <Text>{setDueDate}</Text>
                            {isDefined(calculatedDueDate) ? (
                              <Text color='foreground/muted'>{userProgramStep.dueDate}</Text>
                            ) : null}
                          </>
                        )
                      } else {
                        return <Text>{setDueDate}</Text>
                      }
                    }
                    case 'relative': {
                      const realisedDueDate = userProgramStep.dueDate

                      return (
                        <>
                          <Text>
                            {t('manage.programs.n-days-later', {
                              count: programStep.dueDate.days,
                            })}
                          </Text>

                          {isDefined(realisedDueDate) ? (
                            <Text color='foreground/muted'>{userProgramStep.dueDate}</Text>
                          ) : null}
                        </>
                      )
                    }
                    default:
                      assertNever(programStep.dueDate)
                  }
                })}
              </td>
            )}
          </StepRow>
        )
      })}
    </TimelineContainer>
  )
}

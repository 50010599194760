import { UseMutationResult, useMutation } from '@tanstack/react-query'
import React, { useMemo } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { graphQuery, useGraphQuery, useInvalidateGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { MeetLogo } from 'sierra-client/components/common/logos/meet-logo'
import { TeamsLogo } from 'sierra-client/components/common/logos/teams-logo'
import { ZoomLogo } from 'sierra-client/components/common/logos/zoom-logo'
import { ReactSimpleOauth2LoginRef } from 'sierra-client/components/sana-now-integration-oauth/shared'
import {
  Platform,
  platformOauthComponent,
} from 'sierra-client/components/sana-now-integration-oauth/use-platform-oauth'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { NotificationHeader } from 'sierra-client/views/user-settings/components/notification-input'
import { assertNever, iife, isDefined } from 'sierra-domain/utils'
import { Button, LoadingSpinner, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Divider = styled.div`
  width: 100%;
  background-color: ${token('border/strong')};
  height: 1px;
`

const liveIntegrationsStatusQuery = graphql(`
  query UserSettingsLiveIntegrationStatus {
    viewer {
      integrations {
        googleMeetAuthenticated
        googleMeetClientId
        microsoftTeamsAuthenticated
        microsoftTeamsClientId
        zoomAuthenticated
        zoomClientId
      }
    }
  }
`)

const revokeTeamsIntegrationMutation = graphql(`
  mutation RevokeTeamsIntegration {
    revokeMicrosoftTeamsToken {
      __typename
    }
  }
`)

const revokeMeetIntegrationMutation = graphql(`
  mutation RevokeMeetIntegration {
    revokeGoogleMeetToken {
      __typename
    }
  }
`)

const revokeZoomIntegrationMutation = graphql(`
  mutation RevokeZoomIntegration {
    revokeZoomToken {
      __typename
    }
  }
`)

const useRevokeIntegration = (platform: Platform): UseMutationResult<unknown, Error, void, unknown> => {
  const revokeTeamsIntegration = useMutation({
    mutationFn: () => graphQuery(revokeTeamsIntegrationMutation, {}),
  })
  const revokeMeetIntegration = useMutation({
    mutationFn: () => graphQuery(revokeMeetIntegrationMutation, {}),
  })
  const revokeZoomIntegration = useMutation({
    mutationFn: () => graphQuery(revokeZoomIntegrationMutation, {}),
  })

  switch (platform) {
    case 'meet':
      return revokeMeetIntegration
    case 'teams':
      return revokeTeamsIntegration
    case 'zoom':
      return revokeZoomIntegration
    default:
      assertNever(platform)
  }
}

const ApplicationIcon = styled.div`
  width: 64px;
  height: 64px;
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  border: 0.8px solid ${token('border/strong')};
  flex-shrink: 0;
`

const IntegrationView: React.FC<{ platform: Platform; isAuthenticated: boolean; clientId: string }> = ({
  platform,
  isAuthenticated,
  clientId,
}) => {
  const { t } = useTranslation()
  const revokeIntegration = useRevokeIntegration(platform)
  const invalidate = useInvalidateGraphQuery(liveIntegrationsStatusQuery)
  const ref = React.useRef<ReactSimpleOauth2LoginRef | null>(null)

  const platformInfo = useMemo(() => {
    switch (platform) {
      case 'meet':
        return {
          name: t('dictionary.google-meet'),
          logo: <MeetLogo width={32} height={32} />,
        }
      case 'zoom':
        return {
          name: t('dictionary.zoom'),
          logo: <ZoomLogo width={32} height={32} />,
        }
      case 'teams':
        return {
          name: t('dictionary.microsoft-teams'),
          logo: <TeamsLogo width={32} height={32} />,
        }
      default:
        assertNever(platform)
    }
  }, [platform, t])

  const PlatformOauth = platformOauthComponent(platform)

  return (
    <>
      <View paddingTop='16' paddingBottom='16' justifyContent='space-between' gap='64'>
        <View>
          <ApplicationIcon>{platformInfo.logo}</ApplicationIcon>
          <Spacer size='4' />
          <View gap='4' direction='column'>
            <Text size='small' bold>
              {platformInfo.name}
            </Text>
            <Text size='small' color='foreground/muted'>
              {iife(() => {
                switch (platform) {
                  case 'meet':
                    return t('settings.live-meeting-tools-provider-subtitle--meet')
                  case 'teams':
                    return t('settings.live-meeting-tools-provider-subtitle--teams')
                  case 'zoom':
                    return t('settings.live-meeting-tools-provider-subtitle--zoom')
                  default:
                    assertNever(platform)
                }
              })}
            </Text>
          </View>
        </View>

        <Button
          variant={isAuthenticated ? 'destructive' : 'secondary'}
          loading={revokeIntegration.isPending}
          onClick={() => {
            if (isAuthenticated) {
              revokeIntegration.mutate(undefined, { onSuccess: invalidate })
            } else {
              ref.current?.onBtnClick()
            }
          }}
        >
          {isAuthenticated
            ? t('settings.live-meeting-tools-button--remove')
            : t('settings.live-meeting-tools-button--connect')}
        </Button>
      </View>
      <Divider />
      <PlatformOauth ref={ref} clientId={clientId} onSuccess={invalidate} />
    </>
  )
}

export const LiveIntegrationSettingsForm: React.FC = () => {
  const { data, isSuccess } = useGraphQuery({ document: liveIntegrationsStatusQuery })
  const meetIntegrationEnabled = useFlag('sana-now-meet-integration')

  if (!isSuccess) return <LoadingSpinner />

  return (
    <View direction='column' alignItems='stretch'>
      <NotificationHeader label={'Integrations'} />
      {meetIntegrationEnabled && isDefined(data.viewer.integrations.googleMeetClientId) && (
        <IntegrationView
          platform='meet'
          isAuthenticated={data.viewer.integrations.googleMeetAuthenticated}
          clientId={data.viewer.integrations.googleMeetClientId}
        />
      )}

      {isDefined(data.viewer.integrations.microsoftTeamsClientId) && (
        <IntegrationView
          platform='teams'
          isAuthenticated={data.viewer.integrations.microsoftTeamsAuthenticated}
          clientId={data.viewer.integrations.microsoftTeamsClientId}
        />
      )}

      {isDefined(data.viewer.integrations.zoomClientId) && (
        <IntegrationView
          platform='zoom'
          isAuthenticated={data.viewer.integrations.zoomAuthenticated}
          clientId={data.viewer.integrations.zoomClientId}
        />
      )}
    </View>
  )
}

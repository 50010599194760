import { FC, useMemo } from 'react'
import { CellProps, Column, useTable } from 'react-table'
import { resolveUserAvatar } from 'sierra-client/api/content'
import { Link } from 'sierra-client/components/common/link'
import { Table } from 'sierra-client/components/table/table'
import { useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { TableContainer } from 'sierra-client/views/manage/components/common'
import { LightText, parseStringToJsonContent } from 'sierra-client/views/v3-author/homework/homework-atoms'
import { CourseId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { ListExercisesResponse } from 'sierra-domain/homework'
import { isDefined } from 'sierra-domain/utils'
import { AvatarStack, Icon, Tooltip, TruncatedText } from 'sierra-ui/components'
import { FreeTextEditor } from 'sierra-ui/missions/workflows/free-text-editor'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type HomeworkCardRow = {
  id: string
  homework?: string
  submissions: { numberOfSubmissions: number; expectedNumberOfSubmissions: number }
  homeworkData: { title?: string; reviewInstructions?: string; courseId: string; courseImage?: ImageUnion }
  context: { courseTitle: string; courseId: string; fileId: string; pathTitle?: string; pathId?: string }
  reviewerInfo: { reviewerIds: UserId[] }
}

const StyledImg = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: ${p => p.theme.borderRadius['size-10']};
  object-fit: cover;
`

const ReviewInstructionsContainer = styled.div`
  max-height: 80px;
  overflow: hidden;
  opacity: 0.4;
`

const TitleText = styled(Text)`
  min-width: fit-content;
`

const getUrlForCourse = (courseId: string, fileId: string): string => `/s/${courseId}/file:${fileId}`

const ContextBreadcrumb: FC<{ context: HomeworkCardRow['context'] }> = ({ context }) => {
  return (
    <View>
      <Link next href={getUrlForCourse(context.courseId, context.fileId)}>
        <Tooltip title='View in context'>
          <View gap='4'>
            {context.pathTitle !== undefined && context.pathId !== undefined && (
              <>
                <TitleText as={LightText} size='small'>
                  {context.pathTitle}
                </TitleText>
                <Icon iconId='chevron--right--small' color='grey25' />
              </>
            )}
            <TruncatedText size='small' lines={1}>
              {context.courseTitle}
            </TruncatedText>
          </View>
        </Tooltip>
      </Link>
    </View>
  )
}

export const HomeworksTable: React.FC<{ data: ListExercisesResponse['data'] }> = ({ data }) => {
  const { t } = useTranslation()

  const tableColumns: Column<HomeworkCardRow>[] = useMemo(
    () => [
      {
        Header: t('dictionary.homework-singular'),
        accessor: 'homeworkData',
        width: '40%',
        Cell: (props: CellProps<HomeworkCardRow, HomeworkCardRow['homeworkData']>) => {
          const assetContext = { type: 'course' as const, courseId: CourseId.parse(props.value.courseId) }
          const imgSrc = useResolveCourseAsset({
            image: props.value.courseImage,
            options: { type: 'course', size: 'small' },
            assetContext,
          })

          return (
            <View alignItems={props.value.reviewInstructions !== undefined ? 'flex-start' : 'center'}>
              <View>
                <StyledImg src={imgSrc} />
              </View>
              <View direction='column'>
                <TruncatedText lines={1} bold size='small'>
                  {props.value.title !== undefined ? props.value.title : t('dictionary.homework-singular')}
                </TruncatedText>
                {props.value.reviewInstructions !== undefined && (
                  <ReviewInstructionsContainer>
                    <FreeTextEditor
                      content={parseStringToJsonContent(props.value.reviewInstructions)}
                      editable={false}
                    />
                  </ReviewInstructionsContainer>
                )}
              </View>
            </View>
          )
        },
      },
      {
        Header: t('manage.homework.submission-plural'),
        accessor: 'submissions',
        width: '10%',
        Cell: (props: CellProps<HomeworkCardRow, HomeworkCardRow['submissions']>) => {
          return (
            <View>
              <Text size='small'>{props.value.numberOfSubmissions}</Text>
              <Text size='small' color='grey35'>
                {t('homeworks-table__submissions--submitted-vs-total-separator')}
              </Text>
              <Text size='small'>{props.value.expectedNumberOfSubmissions}</Text>
            </View>
          )
        },
      },
      {
        Header: t('manage.homework.context'),
        accessor: 'context',
        width: '30%',
        Cell: (props: CellProps<HomeworkCardRow, HomeworkCardRow['context']>) => {
          return (
            <View
              direction='row'
              gap='4'
              onClick={event => {
                event.stopPropagation()
              }}
            >
              <ContextBreadcrumb context={props.value} />
            </View>
          )
        },
      },
      {
        Header: t('manage.homework.reviewer-plural'),
        accessor: 'reviewerInfo',
        width: '20%',
        Cell: (props: CellProps<HomeworkCardRow, HomeworkCardRow['reviewerInfo']>) => {
          const resolvedUsers = useUsersLegacy(props.value.reviewerIds)
            .filter(isDefined)
            .map(resolveUserAvatar)
          return (
            <View>
              {resolvedUsers.length > 0 && (
                <AvatarStack size='small' max={4} withTooltips users={resolvedUsers} />
              )}
            </View>
          )
        },
      },
    ],
    [t]
  )

  const tableData = useMemo<HomeworkCardRow[]>(
    () =>
      data.map(homework => ({
        id: homework.id,
        context: {
          courseTitle: homework.courseData.courseTitle,
          courseId: homework.courseData.courseId,
          fileId: homework.courseData.cardId,
          pathTitle: homework.path?.title,
          pathId: homework.path?.id,
        },
        homework: homework.courseData.cardTitle,
        reviewerInfo: { reviewerIds: homework.assignedReviewers?.map(reviewer => reviewer.userId) ?? [] },
        submissions: {
          numberOfSubmissions: homework.numberOfSubmissions,
          expectedNumberOfSubmissions: homework.expectedNumberOfSubmissions,
        },
        homeworkData: {
          courseId: homework.courseData.courseId,
          courseImage: homework.courseData.image,
          title: homework.courseData.cardTitle,
          reviewInstructions: homework.reviewInstructions?.tiptapJSONData,
        },
      })),
    [data]
  )

  const tableInstance = useTable({
    columns: tableColumns,
    data: tableData,
  })

  const navigateToHomework = (row: HomeworkCardRow): void => {
    void getGlobalRouter().navigate({ to: `/manage/exercises/${row.id}` })
  }

  return (
    <TableContainer>
      <Table
        tableInstance={tableInstance}
        getRowProps={row => ({
          onClick: () => navigateToHomework(row.original),
        })}
      />
      {data.length === 0 && (
        <View justifyContent='center' padding='large'>
          <Text size='small' color='grey40'>
            No exercises available
          </Text>
        </View>
      )}
    </TableContainer>
  )
}

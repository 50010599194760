import DOMPurify from 'dompurify'
import { useState } from 'react'
import {
  useAddTeamspaceMembersMutation,
  useRemoveTeamspaceMemberMutation,
} from 'sierra-client/api/hooks/use-teamspace'
import { RouterLink } from 'sierra-client/components/common/link'
import {
  getAssetContextFromEditableContent,
  getAssetContextFromLearnEntity,
} from 'sierra-client/components/util/asset-contex'
import { CollaboratorRoleDropdown, TeamspaceIcon } from 'sierra-client/features/teamspace'
import { useCourseAssetResolver, useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { useTypedMutation } from 'sierra-client/state/api'
import { useUser } from 'sierra-client/state/users/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { buildDeeplink } from 'sierra-client/views/commenting/utils'
import { convertLearnEntityToLinkable } from 'sierra-client/views/workspace/utils/entity-to-linkable'
import { detailsUrl } from 'sierra-client/views/workspace/utils/urls'
import { editUrl, getScopedCreateIdFromContent } from 'sierra-domain/api/editable-content'
import { entityDescription } from 'sierra-domain/api/entities'
import { HomeNotification } from 'sierra-domain/api/learn'
import { CourseRole } from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'
import { TeamspaceRole } from 'sierra-domain/api/teamspace'
import { UserId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { XRealtimeAdminCoursesSetCourseRoles } from 'sierra-domain/routes'
import { assertIsNonNullable } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { Icon, RoundAvatar, TruncatedText } from 'sierra-ui/components'
import { Button, InternalTruncatedText, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { ButtonStyles, usePrimitiveButtonPseudoStyles } from 'sierra-ui/primitives/button/button'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

const Title = styled(InternalTruncatedText).attrs({ lines: 1 })`
  flex-shrink: 0;
`

function useUsername(userId: UserId): string {
  const user = useUser(userId)
  return user?.status === 'loaded' ? `${user.firstName} ${user.lastName}` : '...'
}

const notificationDimension = '32px'

const IconContainer = styled.div`
  height: ${notificationDimension};
  width: ${notificationDimension};
  max-width: ${notificationDimension};
  flex-shrink: 0;
`

const NotificationIcon = styled(Icon)`
  height: ${notificationDimension};
  width: ${notificationDimension};
  border-radius: 9999px;
  color: white;
  background: ${color('orangeVivid')};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Img = styled.img`
  width: auto;
  height: 48px;
  object-fit: cover;
  border-radius: 18px;
  aspect-ratio: 16/9;
`

const NotificationContainer: React.FC<{
  icon: React.ReactNode
  title: React.ReactNode
  content: React.ReactNode
}> = ({ icon, title, content }) => {
  return (
    <View direction='column' gap='10'>
      <View gap='12'>
        <IconContainer>{icon}</IconContainer>
        {title}
      </View>

      <View gap='12'>
        <IconContainer />
        {content}
      </View>
    </View>
  )
}

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const AssignmentNotification: React.FC<{
  notification: Extract<HomeNotification, { type: 'user-assigned' }>
}> = ({ notification }) => {
  const assetResolver = useCourseAssetResolver({ options: { type: 'default', size: 'normal' } })

  if (notification.assignments.length === 0) return null
  else if (notification.assignments.length > 1) {
    return (
      <>
        <NotificationContainer
          icon={<NotificationIcon iconId='course' />}
          title={
            <Title size='regular' bold>
              <Trans
                i18nKey='notification.new-assignments'
                values={{ count: notification.assignments.length }}
              />
            </Title>
          }
          content={
            <>
              <Ul>
                {notification.assignments.map(({ entity }) => (
                  <li key={entity.id}>
                    <RouterLink href={detailsUrl(convertLearnEntityToLinkable(entity))}>
                      <Title size='regular' bold>
                        {entity.title}
                      </Title>
                    </RouterLink>
                  </li>
                ))}
              </Ul>
            </>
          }
        />
      </>
    )
  } else {
    const entity = notification.assignments[0]?.entity
    assertIsNonNullable(entity)
    const linkable = convertLearnEntityToLinkable(entity)
    const assetContext = getAssetContextFromLearnEntity(entity)
    const imgSrc = assetResolver(entity.image, assetContext)

    return (
      <NotificationContainer
        icon={<NotificationIcon iconId='course' />}
        title={
          <Title size='regular' bold>
            <Trans
              i18nKey='notification.new-assignments'
              values={{ count: notification.assignments.length }}
            />
          </Title>
        }
        content={
          <>
            <RouterLink href={detailsUrl(linkable)}>
              <Img src={imgSrc} />
            </RouterLink>
            <View direction='column' gap='4'>
              <RouterLink href={detailsUrl(linkable)}>
                <Title size='regular' bold>
                  {entity.title}
                </Title>
              </RouterLink>

              <TruncatedText lines={2} size='small' color='foreground/secondary'>
                {entityDescription(entity) ?? ''}
              </TruncatedText>
            </View>
          </>
        }
      />
    )
  }
}

const UserAvatar: React.FC<{ userId: UserId }> = ({ userId }) => {
  const user = useUser(userId)
  return (
    <>
      {user?.status === 'loaded' ? (
        <RoundAvatar
          size='medium'
          firstName={user.firstName}
          lastName={user.lastName}
          src={getAvatarImage(user.uuid, user.avatar)}
          color={user.avatarColor}
        />
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}

const CommentNotification: React.FC<{
  notification: Extract<HomeNotification, { type: 'user-mentioned-in-comment' | 'user-thread-responded-to' }>
}> = ({ notification }) => {
  const { editable, reference } = notification
  const fileId =
    reference?.unitId !== undefined && FileId.safeParse(reference.unitId).success
      ? FileId.parse(reference.unitId)
      : undefined

  const href =
    fileId !== undefined
      ? buildDeeplink({
          contentId: getScopedCreateIdFromContent(editable),
          unitId: fileId,
          messageId: notification.messageId,
        })
      : editUrl(editable.type, editable.id, fileId)

  const username = useUsername(notification.authorUserId)

  const assetContext = getAssetContextFromEditableContent(editable)
  const imgSrc = useResolveCourseAsset({
    image: editable.image,
    options: { type: 'default', size: 'normal' },
    assetContext,
  })

  return (
    <NotificationContainer
      icon={<UserAvatar userId={notification.authorUserId} />}
      title={
        <Trans
          i18nKey={
            notification.type === 'user-thread-responded-to'
              ? 'notification.user-responded-to-you-in-thread'
              : 'notification.user-mentioned-you-in-comment'
          }
          values={{ user: username }}
        />
      }
      content={
        <>
          <RouterLink href={href}>
            <Img src={imgSrc} />
          </RouterLink>
          <View direction='column' gap='4'>
            <RouterLink href={href}>
              <Title size='regular' bold>
                {editable.title}
              </Title>
            </RouterLink>

            <TruncatedText lines={2} size='small' color='foreground/secondary'>
              {DOMPurify.sanitize(notification.message, { ALLOWED_TAGS: [] })}
            </TruncatedText>
          </View>
        </>
      }
    />
  )
}

const TeamspaceNotification: React.FC<{
  notification: Extract<HomeNotification, { type: 'teamspace-access-requested' }>
}> = ({ notification }) => {
  const { teamspace, requesterUserId } = notification

  const { t } = useTranslation()
  const href = `/t/${teamspace.id}`
  const [role, setRole] = useState<TeamspaceRole>(teamspace.defaultRole)
  const [requesterRole, setRequesterRole] = useState<TeamspaceRole | undefined>(notification.requesterRole)
  const addMemberMutation = useAddTeamspaceMembersMutation()
  const removeMemberMutation = useRemoveTeamspaceMemberMutation()
  const username = useUsername(requesterUserId)

  return (
    <NotificationContainer
      icon={<UserAvatar userId={requesterUserId} />}
      title={<Trans i18nKey='notification.user-requested-teamspace-access' values={{ user: username }} />}
      content={
        <>
          <RouterLink href={href}>
            <TeamspaceIcon
              displayName={teamspace.displayName}
              themeName={teamspace.iconTheme}
              inactive={false}
            />
          </RouterLink>

          <View direction='column' gap='4' grow>
            <RouterLink href={href}>
              <Title size='regular' bold>
                {teamspace.displayName}
              </Title>
            </RouterLink>
          </View>

          <View>
            {requesterRole === undefined ? (
              <>
                <CollaboratorRoleDropdown selectedRole={role} onSelect={setRole} />
                <Button
                  disabled={addMemberMutation.isPending}
                  onClick={() => {
                    addMemberMutation.mutate(
                      {
                        identities: [
                          {
                            identity: { type: 'user', id: requesterUserId },
                            role: role,
                          },
                        ],
                        teamspaceId: teamspace.id,
                      },
                      { onSuccess: () => setRequesterRole(role) }
                    )
                  }}
                >
                  {t('notification.grant-access')}
                </Button>
              </>
            ) : (
              <Button
                disabled={removeMemberMutation.isPending}
                onClick={() =>
                  removeMemberMutation.mutate(
                    { teamspaceId: teamspace.id, identity: { type: 'user', id: requesterUserId } },
                    {
                      onSuccess: () => setRequesterRole(undefined),
                    }
                  )
                }
              >
                {t('notification.revoke-access')}
              </Button>
            )}
          </View>
        </>
      }
    />
  )
}

const CourseRoleDropdown: React.FC<{
  selectedRole: 'editor' | 'commenter'
  onSelect: (value: 'editor' | 'commenter') => void
}> = ({ selectedRole, onSelect }) => {
  const { t } = useTranslation()

  const rolesToMenuItems = {
    editor: {
      id: 'editor',
      type: 'label',
      label: t('author.role-editor'),
    },
    commenter: {
      id: 'commenter',
      type: 'label',
      label: t('author.role-commenter'),
    },
  } as const
  const menuItems = Object.values(rolesToMenuItems)
  const selectedItem = rolesToMenuItems[selectedRole]

  return (
    <SingleSelectDropdown
      variant='ghost'
      onSelect={item => {
        onSelect(item.id)
      }}
      selectedItem={selectedItem}
      menuItems={menuItems}
    />
  )
}

const CollaboratorNotification: React.FC<{
  notification: Extract<HomeNotification, { type: 'collaborator-access-requested' }>
}> = ({ notification }) => {
  const { t } = useTranslation()
  const { editable, requesterUserId } = notification
  const href = editUrl(editable.type, editable.id)
  const inviteUserMutation = useTypedMutation(XRealtimeAdminCoursesSetCourseRoles, {})
  const [role, setRole] = useState<'editor' | 'commenter'>('editor')
  const [requesterRole, setRequesterRole] = useState<CourseRole | undefined>(notification.requesterRole)
  const username = useUsername(requesterUserId)

  const assetContext = getAssetContextFromEditableContent(editable)
  const imgSrc = useResolveCourseAsset({
    image: editable.image,
    options: { type: 'default', size: 'normal' },
    assetContext,
  })

  return (
    <NotificationContainer
      icon={<UserAvatar userId={requesterUserId} />}
      title={<Trans i18nKey='notification.user-requested-collaborator-access' values={{ user: username }} />}
      content={
        <>
          <View alignItems='center' grow>
            <View grow>
              <RouterLink href={href}>
                <Img src={imgSrc} />
              </RouterLink>
              <RouterLink href={href}>
                <Title size='regular' bold>
                  {editable.title}
                </Title>
              </RouterLink>
            </View>

            <View>
              {requesterRole === undefined ? (
                <>
                  <CourseRoleDropdown selectedRole={role} onSelect={setRole} />
                  <Button
                    disabled={inviteUserMutation.isPending}
                    onClick={() => {
                      inviteUserMutation.mutate(
                        {
                          assignments: [{ assign: true, userId: requesterUserId, role: role }],
                          courseId: editable.id,
                        },
                        { onSuccess: () => setRequesterRole(role) }
                      )
                    }}
                  >
                    {t('notification.grant-access')}
                  </Button>
                </>
              ) : (
                <Button
                  disabled={inviteUserMutation.isPending}
                  onClick={() =>
                    inviteUserMutation.mutate(
                      {
                        assignments: [{ assign: false, userId: requesterUserId, role: role }],
                        courseId: editable.id,
                      },
                      { onSuccess: () => setRequesterRole(undefined) }
                    )
                  }
                >
                  {t('notification.revoke-access')}
                </Button>
              )}
            </View>
          </View>
        </>
      }
    />
  )
}
const DownloadButton = styled.a`
  ${ButtonStyles}
`

const CertificateNotification: React.FC<{
  notification: Extract<
    HomeNotification,
    { type: 'certificate-issued-to-user' | 'issued-certificate-expiring' }
  >
}> = ({ notification }) => {
  const { certificate } = notification
  const pseudoStyles = usePrimitiveButtonPseudoStyles('primary')
  const { t } = useTranslation()
  return (
    <NotificationContainer
      icon={<NotificationIcon iconId='star--filled' />}
      title={
        <Title size='regular' bold>
          {notification.type === 'issued-certificate-expiring'
            ? t('notification.certificate-expiring')
            : t('notification.certificate-earned')}
        </Title>
      }
      content={
        <>
          <View alignItems='center' grow>
            <View grow>
              <Img src={certificate.previewImageUrl} />
              <Title size='regular' bold>
                {certificate.title}
              </Title>
            </View>

            {notification.type === 'issued-certificate-expiring' ? (
              <Text>
                <Trans
                  i18nKey='notification.certificate-expires-on'
                  values={{ date: notification.expiresAt.toLocaleDateString() }}
                />
              </Text>
            ) : (
              <DownloadButton
                href={certificate.downloadUrl}
                download
                $pseudoStyles={pseudoStyles}
                $grow={false}
              >
                {t('dictionary.download')}
              </DownloadButton>
            )}
          </View>
        </>
      }
    />
  )
}

const RecapReadyNotification: React.FC<{
  notification: Extract<HomeNotification, { type: 'recap-ready' }>
}> = ({ notification }) => {
  const { t } = useTranslation()
  const { liveSession } = notification

  const assetContext: AssetContext = { type: 'course', courseId: CourseId.parse(liveSession.backingCourse) }
  const imgSrc = useResolveCourseAsset({
    image: liveSession.image,
    options: { size: 'small', type: 'default' },
    assetContext,
  })

  return (
    <NotificationContainer
      icon={<NotificationIcon iconId='video' />}
      title={
        <Title size='regular' bold>
          {t('notification.new-session-summary-available')}
        </Title>
      }
      content={
        <>
          <View alignItems='center' grow>
            <View grow>
              <RouterLink href={`/l/${liveSession.id}`}>
                <Img src={imgSrc} />
              </RouterLink>
              <RouterLink href={`/l/${liveSession.id}`}>
                <Title size='regular' bold>
                  {liveSession.title}
                </Title>
                {notification.summary !== undefined && (
                  <TruncatedText lines={2} size='small' color='foreground/secondary'>
                    {notification.summary}
                  </TruncatedText>
                )}
              </RouterLink>
            </View>
          </View>
        </>
      }
    />
  )
}

const HomeworksReadyNotification: React.FC<{
  notification: Extract<HomeNotification, { type: 'homeworks-available-for-review' }>
}> = () => {
  const { t } = useTranslation()
  return (
    <NotificationContainer
      icon={<NotificationIcon iconId='clipboard' />}
      title={
        <Title size='regular' bold>
          <RouterLink href='/manage/exercises'>
            {t('notification.new-exercise-available-for-review')}
          </RouterLink>
        </Title>
      }
      content={undefined}
    />
  )
}

export const NotificationCard: React.FC<{ notification: HomeNotification }> = ({ notification }) => {
  switch (notification.type) {
    case 'user-assigned':
      return <AssignmentNotification notification={notification} />
    case 'user-thread-responded-to':
    case 'user-mentioned-in-comment':
      return <CommentNotification notification={notification} />
    case 'teamspace-access-requested':
      return <TeamspaceNotification notification={notification} />

    case 'collaborator-access-requested':
      return <CollaboratorNotification notification={notification} />
    case 'certificate-issued-to-user':
      return <CertificateNotification notification={notification} />
    case 'issued-certificate-expiring':
      return <CertificateNotification notification={notification} />
    case 'recap-ready':
      return <RecapReadyNotification notification={notification} />
    case 'homeworks-available-for-review':
      return <HomeworksReadyNotification notification={notification} />
  }
  notification satisfies never
  return <></>
}

import { useFlag } from 'sierra-client/hooks/use-flag'
import { useReflectionGroupData } from 'sierra-client/views/v3-author/reflection-card/reflection-group-data-layer'
import { ReflectionCardGroupByState } from 'sierra-domain/live-session'
import { MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

const GROUP_LABEL = {
  individual: 'Individual posts',
  smart: 'Smart groups',
} as const satisfies Record<ReflectionCardGroupByState, string>

type MenuItemKey = keyof typeof GROUP_LABEL

export const GroupingButton = (): JSX.Element | null => {
  const groupingEnabled = useFlag('smart-group-reflections')
  const { groupBy, setGroupBy, canSetGroupBy } = useReflectionGroupData()

  if (!groupingEnabled) return null
  if (!canSetGroupBy) return null

  const groupByMenuItems = [
    {
      type: 'label',
      id: 'individual',
      label: GROUP_LABEL.individual,
      selected: groupBy === 'individual',
      icon: 'speaker--notes',
    },
    {
      type: 'label',
      id: 'smart',
      label: GROUP_LABEL.smart,
      selected: groupBy === 'smart',
      description: 'Automatically group posts based on topics',
      icon: 'sana-symbol',
    },
  ] as const satisfies MenuItem<MenuItemKey>[]

  const selecedItem = groupByMenuItems.find(item => item.id === groupBy)

  return (
    <SingleSelectDropdown<MenuItemKey>
      menuItems={groupByMenuItems}
      selectedItem={selecedItem}
      onSelect={item => setGroupBy(item.id)}
      grow={false}
      variant='ghost'
    />
  )
}

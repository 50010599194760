import { EditorContent } from '@tiptap/react'
import React from 'react'
import {
  useCreatePageContext,
  useCreatePageYDocContext,
} from 'sierra-client/views/flexible-content/create-page-context'
import { useSpeakerNoteContext } from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/context'
import { useSpeakerNotesEditor } from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/speaker-note-editor'
import {
  SpeakerNoteProps,
  SpeakerRenderer,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/types'
import { usePolarisContext } from 'sierra-client/views/flexible-content/polaris-editor-provider/polaris-editor-provider'
import {
  tiptapCursorStyles,
  tiptapPlaceholder,
} from 'sierra-client/views/sticky-notes-card/common/tiptap-styles'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled, { css } from 'styled-components'

const StyledEditorContent = styled(EditorContent)<{ $editable: boolean }>`
  min-width: 20px;

  ${p =>
    p.$editable &&
    css`
      cursor: text;
    `}
  .ProseMirror,
    .ProseMirror [data-placeholder] {
    ${fonts.body.micro};
    ${p =>
      !p.$editable &&
      css`
        font-weight: 500;
      `}
  }

  .ProseMirror p.is-editor-empty:first-child::before {
    color: ${token('foreground/muted')};
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  strong {
    font-weight: 500;

    ${p =>
      !p.$editable &&
      css`
        font-weight: 800;
      `}
  }

  ${p =>
    p.$editable &&
    css`
      cursor: text;
    `};

  ${tiptapPlaceholder};
  ${tiptapCursorStyles};
`

const SpeakerNotesImplementation: React.FC<SpeakerNoteProps> = props => {
  const editor = useSpeakerNotesEditor(props)

  const { setFocusedSpeakerNote } = useSpeakerNoteContext()

  return (
    <StyledEditorContent
      $editable={props.editable}
      editor={editor}
      onFocus={() => setFocusedSpeakerNote?.(props.file.id)}
      onBlur={() => setFocusedSpeakerNote?.(undefined)}
    />
  )
}

export const CreateSpeakerNotes: React.FC<SpeakerRenderer> = props => {
  const { scopedCreateContentId } = useCreatePageContext()
  const isLive = ScopedCreateContentId.contentType(scopedCreateContentId) === 'live'
  const { yDoc, awareness } = useCreatePageYDocContext()

  if (!isLive) {
    return null
  }

  return <SpeakerNotesImplementation {...props} yDoc={yDoc} awareness={awareness} />
}

export const LiveSpeakerNotes: React.FC<SpeakerRenderer> = props => {
  const { yDoc, awareness } = usePolarisContext()

  return <SpeakerNotesImplementation {...props} yDoc={yDoc} awareness={awareness} />
}

import React, { FC } from 'react'
import { useResolveCourseAsset } from 'sierra-client/hooks/use-resolve-course-asset'
import { CardGrid } from 'sierra-client/views/v3-author/wrapper'
import { AssetContext } from 'sierra-domain/asset-context'
import { BulletCard } from 'sierra-domain/v3-author'
import styled, { css } from 'styled-components'

const BulletCardImage = styled.div<{
  $url?: string
  $variant: 'leading-image' | 'trailing-image' | 'full-width-image'
}>`
  position: sticky;
  height: calc(100% - 16px);
  width: calc(100% - 16px);
  margin: 8px;
  top: 8px;
  border-radius: 8px;

  user-select: none;
  background-image: url(${p => p.$url});
  ${p =>
    p.$variant === 'full-width-image' &&
    css`
      position: absolute;
      z-index: 0;
    `}
  background-size: cover;
  background-position: center;
  transition: background-image 300ms ease-out;
  will-change: background-image;

  grid-column: ${p =>
    ({
      'leading-image': '1 / span 7',
      'trailing-image': '8 / span 7',
      'full-width-image': '1 / span 14',
    })[p.$variant]};
`

const BulletCardWrapper = styled.div<{
  $variant: 'leading-image' | 'trailing-image' | 'full-width-image'
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: transparent;
  padding-top: 5rem;
  padding-bottom: 5rem;

  ${p =>
    p.$variant === 'full-width-image' &&
    css`
      z-index: 1;
    `}

  grid-column: ${p =>
    ({
      'trailing-image': '2 / span 5',
      'leading-image': '9 / span 5',
      'full-width-image': '1 / span 14',
    })[p.$variant]};
`

export const RenderBulletCard: FC<{
  node: BulletCard
  children: React.ReactNode
  assetContext: AssetContext
}> = ({ node, children, assetContext }) => {
  const variant = node.variant ?? 'trailing-image'

  const image = useResolveCourseAsset({ image: node.image, assetContext })

  return (
    <CardGrid>
      {variant === 'leading-image' ? (
        <>
          <BulletCardImage $variant={variant} $url={image} />
          <BulletCardWrapper $variant={variant}>{children}</BulletCardWrapper>
        </>
      ) : (
        <>
          <BulletCardWrapper $variant={variant}>{children}</BulletCardWrapper>
          <BulletCardImage $variant={variant} $url={image} />
        </>
      )}
    </CardGrid>
  )
}

import { useMutation, useQuery } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { maxBy } from 'lodash'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLiveSessionIdContextIfAvailable } from 'sierra-client/components/liveV2/live-session-id-provider'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { PollUpdatedData, liveSessionDataChannel } from 'sierra-client/realtime-data/channels'
import { typedPost, useCachedQuery } from 'sierra-client/state/api'
import { useSelector } from 'sierra-client/state/hooks'
import { PollData, currentPollDataAtom } from 'sierra-client/state/interactive-card-data/poll-card'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { FCC } from 'sierra-client/types'
import { useFileContext } from 'sierra-client/views/flexible-content/file-context'
import { useSetCardProgress } from 'sierra-client/views/flexible-content/progress-tracking/set-progress-provider'
import { useRecapContext } from 'sierra-client/views/recap/recap-context'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { Entity } from 'sierra-domain/entity'
import { createNanoId12FromString } from 'sierra-domain/nanoid-extensions'
import {
  XRealtimeStrategyContentDataPollGetPollResults,
  XRealtimeStrategyContentDataPollUpsertPollVote,
} from 'sierra-domain/routes'
import { PollCard as SlatePollCard, allPollCardAlternatives } from 'sierra-domain/v3-author'

type PollDataLayer = {
  pollData?: PollData
  userVoteId?: string
  userVoteSkipped?: boolean
  voteOnOption: (optionId: string) => void
  skip?: () => void
}

type OptionResult = {
  optionId: string
  totalVotes: number
  votedByUserIds: UserId[]
  isChosenByUser: boolean
}

const LiveContext = React.createContext<PollDataLayer | undefined>(undefined)
const SelfPacedContext = React.createContext<PollDataLayer | undefined>(undefined)
const FallbackContext = React.createContext<PollDataLayer | undefined>(undefined)
const RecapContext = React.createContext<PollDataLayer | undefined>(undefined)

type PollCardProvider = FCC<{ element: Entity<SlatePollCard> }>

const FallbackDataProvider: PollCardProvider = ({ children }) => {
  const value = useMemo(
    () => ({
      pollData: undefined,
      userVoteId: undefined,
      userVoteSkipped: undefined,
      voteOnOption: () => {},
      skip: undefined,
    }),
    []
  )
  return <FallbackContext.Provider value={value}>{children}</FallbackContext.Provider>
}

const LiveRealTimeDataHandler = ({
  liveSessionId,
  eventId,
  onData,
  onReadyToReceiveData,
}: {
  liveSessionId: LiveSessionId
  eventId: string
  onData: (newData: PollUpdatedData) => void
  onReadyToReceiveData: (receiving: boolean) => void
}): null => {
  const { isReceivingData } = liveSessionDataChannel.useChannelEvent({
    channelId: liveSessionId,
    event: 'poll-updated',
    eventId,
    callback: onData,
  })

  useEffect(() => {
    onReadyToReceiveData(isReceivingData)
  }, [onReadyToReceiveData, isReceivingData])

  return null
}

function updatedAt(value?: string): number {
  return new Date(value ?? 0).valueOf()
}

const BackendDataProvider: PollCardProvider = ({ element, children }) => {
  const userId = useSelector(selectUserId)
  const { file, flexibleContentId } = useFileContext()
  const { setCardCompleted } = useSetCardProgress()
  const setPollData = useSetAtom(currentPollDataAtom)
  const [realtimeReady, setRealtimeReady] = useState<boolean>(false)
  const [realtimePollData, setRealtimePollData] = useState<PollUpdatedData | undefined>(undefined)
  const [userVoteSkipped, setUserVoteSkipped] = useState<boolean>(false)

  const pollId = element.id
  const pollAlternatives = useDeepEqualityMemo(allPollCardAlternatives(element))
  const [userVoteId, setUserVoteId] = useState<string | undefined>(undefined)

  // Synthetically create a version of the poll
  const versionId = createNanoId12FromString(pollAlternatives.map(alt => alt.id).join())

  const { liveSessionId: scopedLiveSessionId } = useLiveSessionIdContextIfAvailable() ?? {}
  const liveSessionId =
    scopedLiveSessionId !== undefined ? ScopedLiveSessionId.extractId(scopedLiveSessionId) : undefined

  // Complete a card if a user has voted on an option
  useEffect(() => {
    if (userVoteId !== undefined) {
      setCardCompleted()
    }
  }, [userVoteId, setCardCompleted])

  const pollResults = useQuery({
    queryKey: [
      XRealtimeStrategyContentDataPollGetPollResults.path,
      {
        contentId: flexibleContentId,
        pollId,
        versionId,
        fileId: file.id,
        liveSessionId,
      },
    ],
    queryFn: () => {
      return typedPost(XRealtimeStrategyContentDataPollGetPollResults, {
        contentId: flexibleContentId,
        pollId,
        versionId,
        fileId: file.id,
        liveSessionId,
      })
    },
    enabled: realtimeReady || liveSessionId === undefined,
  })

  const pollData = useMemo(() => {
    const latestPollData = maxBy([realtimePollData, pollResults.data], data =>
      data?.updatedAt !== undefined ? new Date(data.updatedAt).valueOf() : 0
    )

    let optionResults: OptionResult[] | undefined = undefined
    if (
      realtimePollData !== undefined &&
      updatedAt(realtimePollData.updatedAt) > updatedAt(pollResults.data?.updatedAt)
    ) {
      optionResults = realtimePollData.optionResults.map(optionResult => ({
        optionId: optionResult.optionId,
        totalVotes: optionResult.totalVotes,
        votedByUserIds: optionResult.chosenByUserIds,
        isChosenByUser: userId !== undefined && optionResult.chosenByUserIds.includes(userId),
      }))
    } else {
      optionResults = pollResults.data?.optionResults.map(optionResult => ({
        optionId: optionResult.optionId,
        totalVotes: optionResult.totalVotes,
        votedByUserIds: optionResult.chosenByUserIds ?? [],
        isChosenByUser: optionResult.isChosenByUser,
      }))
    }

    const newPollData: PollData = {
      optionResults: [],
      totalVotes: latestPollData?.totalVotes ?? 0,
      updatedAt: latestPollData?.updatedAt ?? '',
    }

    // user choice is only available when requesting the data directly from the backend
    const userChoice = optionResults?.find(optionResult => optionResult.isChosenByUser)?.optionId

    pollAlternatives.forEach(({ id }) => {
      const upstreamData = optionResults?.find(choiceData => choiceData.optionId === id)

      if (upstreamData) {
        newPollData.optionResults.push({
          optionId: id,
          totalVotes: upstreamData.totalVotes,
          votedByUserIds: upstreamData.votedByUserIds,
        })
      } else {
        newPollData.optionResults.push({
          optionId: id,
          totalVotes: 0,
          votedByUserIds: [],
        })
      }
    })

    return {
      newPollData,
      userChoice,
    }
  }, [pollAlternatives, pollResults.data, realtimePollData, userId])

  useEffect(() => {
    setPollData(pollData.newPollData)
  }, [setPollData, pollData.newPollData])

  useEffect(() => {
    setUserVoteId(pollData.userChoice)
  }, [pollData.userChoice])

  const skip = useCallback(() => {
    if (userVoteId === undefined) {
      setUserVoteSkipped(true)
    }
  }, [userVoteId])

  const voteOnOptionMutation = useMutation({
    mutationFn: async (optionId: string) =>
      typedPost(XRealtimeStrategyContentDataPollUpsertPollVote, {
        contentId: flexibleContentId,
        optionId: optionId,
        pollId,
        versionId,
        fileId: file.id,
        liveSessionId,
      }),
  })

  const voteOnOption = useCallback(
    (optionId: string) => {
      if (userId === undefined) throw new Error('no userId')

      const choiceData = pollData.newPollData.optionResults.find(
        choiceData => choiceData.optionId === optionId
      )
      if (choiceData === undefined) {
        throw new Error('invalid choice')
      }

      voteOnOptionMutation.mutate(optionId, { onSuccess: () => pollResults.refetch() })
      setUserVoteId(optionId)
    },
    [pollData.newPollData.optionResults, pollResults, userId, voteOnOptionMutation]
  )

  const onNewRealTimeData = useCallback((newData: PollUpdatedData) => {
    setRealtimePollData(data =>
      maxBy([data, newData], data => (data !== undefined ? new Date(data.updatedAt).valueOf() : 0))
    )
  }, [])

  const value = useMemo(
    () => ({
      pollData: pollData.newPollData,
      userVoteId,
      voteOnOption,
      skip: liveSessionId !== undefined ? skip : undefined,
      userVoteSkipped,
    }),
    [pollData.newPollData, userVoteId, voteOnOption, userVoteSkipped, liveSessionId, skip]
  )

  return (
    <SelfPacedContext.Provider value={value}>
      {children}

      {liveSessionId !== undefined && (
        <LiveRealTimeDataHandler
          liveSessionId={liveSessionId}
          eventId={`${liveSessionId}:${flexibleContentId}:${pollId}:${versionId}`}
          onData={onNewRealTimeData}
          onReadyToReceiveData={setRealtimeReady}
        />
      )}
    </SelfPacedContext.Provider>
  )
}

const BackendRecapDataProvider: PollCardProvider = ({ element, children }) => {
  const { file, flexibleContentId } = useFileContext()

  const pollId = element.id
  const pollAlternatives = useDeepEqualityMemo(allPollCardAlternatives(element))

  // Synthetically create a version of the poll
  const versionId = createNanoId12FromString(pollAlternatives.map(alt => alt.id).join())

  const recapContext = useRecapContext()
  if (recapContext === undefined) throw new Error('no recap context')

  const scopedLiveSessionId = recapContext.liveSessionId
  const liveSessionId = ScopedLiveSessionId.extractId(scopedLiveSessionId)

  const pollResults = useCachedQuery(XRealtimeStrategyContentDataPollGetPollResults, {
    contentId: flexibleContentId,
    pollId,
    versionId,
    fileId: file.id,
    liveSessionId,
  })

  const { newPollData, userChoice } = useMemo(() => {
    const newPollData: PollData = {
      optionResults: [],
      totalVotes: pollResults.data?.totalVotes ?? 0,
      updatedAt: pollResults.data?.updatedAt ?? '',
    }

    const optionResults = pollResults.data?.optionResults.map(optionResult => ({
      optionId: optionResult.optionId,
      totalVotes: optionResult.totalVotes,
      votedByUserIds: optionResult.chosenByUserIds ?? [],
    }))

    // user choice is only available when requesting the data directly from the backend
    const userChoice = pollResults.data?.optionResults.find(optionResult => optionResult.isChosenByUser)
      ?.optionId

    pollAlternatives.forEach(({ id }) => {
      const upstreamData = optionResults?.find(choiceData => choiceData.optionId === id)

      if (upstreamData) {
        newPollData.optionResults.push({
          optionId: id,
          totalVotes: upstreamData.totalVotes,
          votedByUserIds: upstreamData.votedByUserIds,
        })
      } else {
        newPollData.optionResults.push({
          optionId: id,
          totalVotes: 0,
          votedByUserIds: [],
        })
      }
    })

    return {
      newPollData,
      userChoice,
    }
  }, [pollAlternatives, pollResults.data])

  const userVoteSkipped = userChoice === undefined ? true : false

  const providerData = useMemo(
    () => ({
      pollData: newPollData,
      userVoteId: userChoice,
      userVoteSkipped,
      voteOnOption: () => {},
      skip: () => {},
    }),
    [newPollData, userChoice, userVoteSkipped]
  )

  return <RecapContext.Provider value={providerData}>{children}</RecapContext.Provider>
}

const modeToProvider: Record<EditorMode, PollCardProvider | undefined> = {
  'live': BackendDataProvider,
  'self-paced': BackendDataProvider,
  'recap': BackendRecapDataProvider,
  'placement-test': undefined,
  'version-history': undefined,
  'create': undefined,
  'review': undefined,
  'template': undefined,
}

export const PollDataProvider: FCC<{ element: Entity<SlatePollCard>; mode: EditorMode }> = ({
  mode,
  element,
  children,
}) => {
  const Provider = modeToProvider[mode] ?? FallbackDataProvider

  return <Provider element={element}>{children}</Provider>
}

export const usePollData = (): PollDataLayer => {
  const liveData = useContext(LiveContext)
  const selfPacedData = useContext(SelfPacedContext)
  const recapData = useContext(RecapContext)
  const fallbackData = useContext(FallbackContext)

  const data = liveData ?? selfPacedData ?? recapData ?? fallbackData

  if (!data) {
    throw new Error('Poll data not provided')
  }

  return data
}

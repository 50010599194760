import { useMutation } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { typedPost, useCachedQuery } from 'sierra-client/state/api'
import { selectIsCollaborator } from 'sierra-client/state/author-course-settings/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { useHasManageAccess } from 'sierra-client/views/manage/permissions/use-has-manage-access'
import { dropAWordWordAdded, dropAWordWordDeleted } from 'sierra-client/views/v3-author/drop-a-word/logger'
import { DropAWord } from 'sierra-client/views/v3-author/drop-a-word/renderer/drop-a-word'
import { PillDefinition } from 'sierra-client/views/v3-author/drop-a-word/renderer/types'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { DropAWordCardDeleteWordRequest, DropAWordCardUpsertWordRequest } from 'sierra-domain/api/strategy-v2'
import { UUID } from 'sierra-domain/api/uuid'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { DropAWordData } from 'sierra-domain/flexible-content/types'
import {
  XRealtimeStrategyContentDataDropAWordDeleteWord,
  XRealtimeStrategyContentDataDropAWordUpsertWord,
  XRealtimeStrategyContentDataGetDropAWordResponses,
} from 'sierra-domain/routes'
import { color } from 'sierra-ui/color'

function onTitleChanged(): void {
  throw new Error('Cannot change title in self-paced')
}

export const SelfPacedDropAWordCard: React.FC<{
  contentId: CreateContentId
  fileId: FileId
  fileData: DropAWordData
}> = ({ contentId, fileId, fileData }) => {
  const isCollaborator = useSelector(selectIsCollaborator)
  const hasManageAccess = useHasManageAccess()
  const userId = useSelector(selectUserId)
  const dispatch = useDispatch()

  const dropAWordResponses = useCachedQuery(XRealtimeStrategyContentDataGetDropAWordResponses, {
    contentId,
    fileId,
  })

  const upsertWordMutation = useMutation({
    mutationFn: (data: DropAWordCardUpsertWordRequest) =>
      typedPost(XRealtimeStrategyContentDataDropAWordUpsertWord, data),
    onSuccess: () => dropAWordResponses.refetch(),
  })

  const deleteWordMutation = useMutation({
    mutationFn: (data: DropAWordCardDeleteWordRequest) =>
      typedPost(XRealtimeStrategyContentDataDropAWordDeleteWord, data),
    onSuccess: () => dropAWordResponses.refetch(),
  })

  const deletePillWithId = useStableFunction((responseId: UUID) => {
    deleteWordMutation.mutate({
      contentId,
      fileId,
      responseId,
    })

    const word = dropAWordResponses.data?.responses.find(pill => pill.id === responseId)?.word
    if (word === undefined || userId === undefined) return
    void dispatch(dropAWordWordDeleted({ wordLength: word.length, userId }))
  })

  const pills: PillDefinition[] | undefined = useMemo(
    () =>
      dropAWordResponses.data?.responses.map(response => ({
        id: response.id,
        word: response.word,
        color: color(response.color),
        userId: response.userId,
      })),
    [dropAWordResponses.data]
  )

  const canDeletePillWithId = useCallback(
    (responseId: UUID) => {
      if (isCollaborator || hasManageAccess) return true

      const pill = pills?.find(pill => pill.id === responseId)
      return pill?.userId === userId
    },
    [hasManageAccess, isCollaborator, pills, userId]
  )

  const onAddWord = useStableFunction((word: string) => {
    upsertWordMutation.mutate({
      contentId,
      fileId,
      word,
    })

    if (userId === undefined) return
    void dispatch(dropAWordWordAdded({ wordLength: word.length, userId }))
  })

  return (
    <DropAWord
      title={fileData.title}
      onTitleChanged={onTitleChanged}
      pills={pills}
      deletePillWithId={deletePillWithId}
      canDeletePillWithId={canDeletePillWithId}
      onAddWord={onAddWord}
    />
  )
}

import React, { useCallback, useMemo, useState } from 'react'
import { MeetLogo } from 'sierra-client/components/common/logos/meet-logo'
import { TeamsLogo } from 'sierra-client/components/common/logos/teams-logo'
import { ZoomLogo } from 'sierra-client/components/common/logos/zoom-logo'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { BugReportModal } from 'sierra-client/components/liveV2/live-layer/bug-report-modal'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { useConfig } from 'sierra-client/context/config-context'
import { Logging } from 'sierra-client/core/logging'
import { GoogleMeetButton } from 'sierra-client/features/sana-now/create-session-panel/video-call-provider-buttons/google-meet-button'
import { MicrosoftTeamsButton } from 'sierra-client/features/sana-now/create-session-panel/video-call-provider-buttons/microsoft-teams-button'
import { ZoomButton } from 'sierra-client/features/sana-now/create-session-panel/video-call-provider-buttons/zoom-button'
import { ResponsiveNowButton } from 'sierra-client/features/sana-now/header/buttons'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useResetBooleanAfterDelay } from 'sierra-client/hooks/use-reset-boolean-after-delay'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { inferVideoCallProviderFromUrl } from 'sierra-client/lib/sana-now/get-video-call-provider-from-video-call-setting'
import { useTypedMutation } from 'sierra-client/state/api'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectIsFacilitator } from 'sierra-client/state/live-session/selectors'
import { VideoCallSetting } from 'sierra-domain/content/session'
import { XRealtimeAuthorLiveSessionsUpdateLiveSettings } from 'sierra-domain/routes'
import { guardWith, iife } from 'sierra-domain/utils'
import { Icon, MenuItem, Modal } from 'sierra-ui/components'
import { Button, IconButton, InputPrimitive, Spacer, Text, View } from 'sierra-ui/primitives'
import { MenuDropdownPrimitive } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

const IconContainer = styled.div`
  display: grid;
  place-items: center;
  padding-left: 12px;
`

const StyledView = styled(View).attrs({ gap: 'none', direction: 'column' })`
  padding: 20px 24px;
`

const PaddingView = styled(View).attrs({})`
  padding-top: 20px;
`

const ErrorText = styled(Text).attrs({
  size: 'micro',
})`
  color: ${p => p.theme.color.redVivid};
`

const EditMeetingToolSettingModal: React.FC<{ open: boolean; onCloseModal: () => void }> = ({
  open,
  onCloseModal,
}) => {
  const [validationError, setValidationError] = useState(false)
  const liveSession = useLiveSessionContext()
  const meetingToolSettings = useConfig().settings.meetingToolsSettings

  const meetIntegrationEnabled =
    useFlag('sana-now-meet-integration') && meetingToolSettings.meetSettings.enabled
  const teamsIntegrationEnabled = meetingToolSettings.teamsSettings.enabled
  const zoomIntegrationEnabled = meetingToolSettings.zoomSettings.enabled
  const anyIntegrationEnabled = meetIntegrationEnabled || teamsIntegrationEnabled || zoomIntegrationEnabled

  const { t } = useTranslation()

  const updateSettingsMutation = useTypedMutation(XRealtimeAuthorLiveSessionsUpdateLiveSettings, {
    onSuccess: () => onCloseModal(),
  })

  const [url, setUrl] = useState(
    liveSession.data.videoCallSetting.type === 'url' ? liveSession.data.videoCallSetting.url : ''
  )

  const { isEnabled: recentlyClicked, setTrue: setRecentlyClicked } = useResetBooleanAfterDelay()

  const handleCopyLink = (): void => {
    void window.navigator.clipboard.writeText(url)
    setRecentlyClicked()
  }

  const handleUpdateVideoCallSetting = useCallback(() => {
    const videoCallSetting = url.length > 0 ? { type: 'url', url } : { type: 'none' }

    if (guardWith(VideoCallSetting, videoCallSetting)) {
      updateSettingsMutation.mutate({
        liveSessionId: liveSession.liveSessionId,
        videoCallSetting,
      })
    } else {
      setValidationError(true)
    }
  }, [liveSession.liveSessionId, updateSettingsMutation, url])

  return (
    <AppThemeTokenProvider followSystemColorScheme={false}>
      <Modal open={open} onClose={onCloseModal} size={{ width: 500 }}>
        <StyledView>
          <Text color='foreground/primary' bold size='large'>
            {t('sana-now.edit-video-call-setting-modal.title')}
          </Text>
          <Spacer size='4' />
          <Text color={'foreground/secondary'}>{t('sana-now.edit-video-call-setting-modal.subtitle')}</Text>
          <Spacer size='16' />
          {anyIntegrationEnabled && (
            <View>
              {meetIntegrationEnabled && <GoogleMeetButton onLink={setUrl} />}
              {teamsIntegrationEnabled && <MicrosoftTeamsButton onLink={setUrl} />}
              {zoomIntegrationEnabled && <ZoomButton onLink={setUrl} />}
            </View>
          )}
          <Spacer size='16' />

          <View>
            <InputPrimitive
              id='url-input'
              type='text'
              onChange={e => setUrl(e.target.value)}
              value={url}
              leadingVisual={
                <IconContainer>
                  {iife(() => {
                    switch (inferVideoCallProviderFromUrl(url)) {
                      case 'google-meet':
                        return <MeetLogo width={16} height={16} />
                      case 'microsoft-teams':
                        return <TeamsLogo width={16} height={16} />
                      case 'zoom':
                        return <ZoomLogo width={16} height={16} />
                      default:
                        return <Icon iconId='video-call' color='foreground/muted' />
                    }
                  })}
                </IconContainer>
              }
              placeholder={t('sana-now.edit-video-call-setting-modal.input-placeholder')}
              onKeyDown={e => {
                if (e.key === 'Enter') handleUpdateVideoCallSetting()
              }}
            />
            {url.length > 0 && (
              <>
                <IconButton
                  variant='secondary'
                  iconId={recentlyClicked ? 'checkmark' : 'clipboard'}
                  tooltip={t('share.copy-link')}
                  onClick={handleCopyLink}
                />
                <IconButton
                  variant='transparent'
                  iconId='trash-can'
                  tooltip={t('dictionary.clear')}
                  onClick={() => {
                    setUrl('')
                  }}
                />
              </>
            )}
          </View>

          <View paddingTop={'4'}>
            {validationError && (
              <ErrorText>{t('sana-now.edit-video-call-setting-modal.invalid-url-error')}</ErrorText>
            )}
          </View>
          <PaddingView grow alignItems='flex-end' justifyContent='flex-end'>
            <Button disabled={false} onClick={onCloseModal} variant='secondary'>
              {t('dictionary.cancel')}
            </Button>
            <Button
              disabled={false}
              loading={updateSettingsMutation.isPending}
              onClick={handleUpdateVideoCallSetting}
            >
              {t('sana-now.edit-video-call-setting-modal.save-button')}
            </Button>
          </PaddingView>
        </StyledView>
      </Modal>
    </AppThemeTokenProvider>
  )
}

export const OptionsButton = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showReportBug, setShowReportBug] = useState(false)
  const [showEditMeetingTool, setShowEditMeetingTool] = useState(false)

  const liveSession = useLiveSessionContext()
  const isFacilitator = useSelector(selectIsFacilitator)
  const contentId = liveSession.data.flexibleContentId
  const card = useSelectCurrentCard()

  const updateSettingsMutation = useTypedMutation(XRealtimeAuthorLiveSessionsUpdateLiveSettings)

  const onToggleTranscriptionsClick = useCallback(() => {
    if (isFacilitator) {
      const isTranscribingSession = liveSession.data.transcribeSession
      const liveSessionId = liveSession.liveSessionId
      updateSettingsMutation.mutate({ liveSessionId, transcribeSession: !isTranscribingSession })
    }
  }, [isFacilitator, liveSession.data.transcribeSession, liveSession.liveSessionId, updateSettingsMutation])

  const createUrl = `/create/l/${contentId}/${card?.id}`

  const onToggleGuestAccessClick = useCallback(() => {
    if (isFacilitator) {
      const guestsAreAllowed = liveSession.data.allowGuestAccess
      const liveSessionId = liveSession.liveSessionId
      updateSettingsMutation.mutate({ liveSessionId, enableGuestAccess: !guestsAreAllowed })
    }
  }, [isFacilitator, liveSession.data.allowGuestAccess, liveSession.liveSessionId, updateSettingsMutation])

  const items = useMemo(() => {
    const menuItems: MenuItem[] = []
    const guestAccessEnabled = liveSession.data.allowGuestAccess
    const isTranscribing = liveSession.data.transcribeSession
    const hasVideoCallUrl = liveSession.data.videoCallSetting.type === 'url'

    if (isFacilitator) {
      menuItems.push({
        type: 'label',
        id: 'transcribe-session',
        label: isTranscribing
          ? t('live.smart-features.title.disable')
          : t('live.smart-features.title.enable'),
        description: isTranscribing
          ? t('live.smart-features.description.disable')
          : t('live.smart-features.description.enable'),
      })

      menuItems.push({
        type: 'label',
        id: 'guest-access',
        label: guestAccessEnabled
          ? t('admin.author.sessions.disable-guest-access')
          : t('admin.author.sessions.allow-guest-access'),
      })

      menuItems.push({
        type: 'label',
        id: 'edit-meeting-tool-setting',
        label: hasVideoCallUrl
          ? t('sana-now.header.settings.change-meeting-link')
          : t('sana-now.header.settings.add.meeting-link'),
      })

      menuItems.push({
        type: 'label',
        id: 'edit-content',
        label: t('live.edit-content'),
      })
    }

    menuItems.push({
      type: 'label',
      id: 'report-bug',
      label: t('live.report-a-bug'),
    })
    return menuItems
  }, [
    isFacilitator,
    liveSession.data.allowGuestAccess,
    liveSession.data.transcribeSession,
    liveSession.data.videoCallSetting.type,
    t,
  ])

  return (
    <div id='tour-live-help'>
      <MenuDropdownPrimitive
        renderTrigger={() => (
          <ResponsiveNowButton iconId='overflow-menu--horizontal' title={t('dictionary.more')} />
        )}
        closeOnSelect
        menuItems={items}
        onSelect={item => {
          if (item.id === 'report-bug') {
            void dispatch(Logging.liveSession.helpClicked())
            setShowReportBug(true)
          } else if (item.id === 'guest-access') {
            onToggleGuestAccessClick()
          } else if (item.id === 'edit-meeting-tool-setting') {
            setShowEditMeetingTool(true)
          } else if (item.id === 'edit-content') {
            window.open(createUrl, '_blank', 'noopener,noreferrer')
          } else if (item.id === 'transcribe-session') {
            onToggleTranscriptionsClick()
          }
        }}
      />

      {showReportBug && <BugReportModal open={showReportBug} onCloseModal={() => setShowReportBug(false)} />}
      {showEditMeetingTool && (
        <EditMeetingToolSettingModal
          open={showEditMeetingTool}
          onCloseModal={() => setShowEditMeetingTool(false)}
        />
      )}
    </div>
  )
}

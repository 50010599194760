import { useMemo } from 'react'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { useHasContentKindPermission, useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import * as settingsState from 'sierra-client/state/author-course-settings/slice'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { CourseSettingsButton } from 'sierra-client/views/course-settings/components/buttons'
import { HorizontalSettingsField } from 'sierra-client/views/course-settings/tabs/utils'
import { SettingsTabComponent } from 'sierra-client/views/course-settings/types'
import { CourseKind } from 'sierra-domain/api/common'
import { Spacer } from 'sierra-ui/primitives'

export const ExportTab: SettingsTabComponent = ({ courseId }) => {
  const { t } = useTranslation()
  const courseKind = useSelector(settingsState.selectors.selectCourseKind)
  const user = useSelector(selectUser)
  const scormExportFlag = useFlag('scorm/export-scorm')
  const organizationPermissions = useOrganizationPermissions()
  const canExportScormPermission = organizationPermissions.has('EXPORT_SCORM')
  const canAccessCreate = organizationPermissions.has('ACCESS_EDITOR')
  const canViewDraft = useHasContentKindPermission(courseId, 'VIEW_DRAFT')

  const canExportScorm = useMemo(() => {
    const courseKindsWithScormExport: CourseKind[] = ['native:self-paced', 'native:course-group']

    if (courseKind === null || !courseKindsWithScormExport.includes(courseKind)) return false

    return scormExportFlag && canExportScormPermission
  }, [courseKind, scormExportFlag, canExportScormPermission])

  const canDuplicate = useMemo(
    () =>
      courseKind !== null &&
      courseKind !== 'native:course-group' &&
      courseKind !== 'scorm:course-group' &&
      canAccessCreate,
    [courseKind, canAccessCreate]
  )

  if (courseKind === null || !user) return null

  const isNativeCourse = ['native:live', 'native:self-paced'].includes(courseKind)
  // the pdf export is based on the latest draft of the course
  const canExportPdf = isNativeCourse && canViewDraft

  return (
    <>
      {canDuplicate && (
        <>
          <HorizontalSettingsField
            title={t('course-settings.duplicate-course')}
            description={t('course-settings.duplicate-description')}
          >
            <CourseSettingsButton.DuplicateCourse courseId={courseId} courseKind={courseKind} />
          </HorizontalSettingsField>
          <Spacer size='24' />
        </>
      )}

      {canExportScorm && (
        <>
          <HorizontalSettingsField
            title={t('content.settings-export-as-scorm')}
            description={t('author.export-scorm-description')}
          >
            <CourseSettingsButton.ExportAsScorm courseId={courseId} />
          </HorizontalSettingsField>
          <Spacer size='24' />
        </>
      )}

      {canExportPdf && (
        <HorizontalSettingsField
          title={t('content.settings-export-as-pdf')}
          description={t('author.export-pdf-description')}
        >
          {courseKind === 'native:self-paced' && (
            <CourseSettingsButton.ExportAsPdf courseId={courseId} contentType='self-paced' />
          )}
          {courseKind === 'native:live' && (
            <CourseSettingsButton.ExportAsPdf courseId={courseId} contentType='live' />
          )}
        </HorizontalSettingsField>
      )}
    </>
  )
}

import React from 'react'
import { resolveThemeColor, useThemes } from 'sierra-client/hooks/use-themes'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ColorPicker } from 'sierra-client/views/v3-author/title-card/color-picker'
import { color } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { palette, spacing } from 'sierra-ui/theming'
import { ThemeName, getTheme } from 'sierra-ui/theming/legacy-theme'
import styled, { useTheme } from 'styled-components'

const StyledIcon = styled(Icon)`
  margin-top: 3px;
`

const StyledText = styled(Text)`
  padding-bottom: 5px;
  color: ${p => p.theme.color.grey40};
  border-bottom: 1px solid ${palette.grey[5]};
  font-weight: 500;
`
const Styleddiv = styled.div`
  padding: ${spacing['2']};
`

const ColorIconsWrapper = styled(View)`
  padding-bottom: 10px;
`
const StyledView = styled(View)`
  width: 224px;
  padding: 12px;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.08);
`

export const BlockQuoteColorPicker: React.FC<{
  onMouseOver: (theme: string) => void
  onMouseOut: () => void
  onClick: (theme: string) => void
  onClose?: () => void
  selectedTheme: ThemeName
}> = ({ onMouseOut, onMouseOver, onClick, selectedTheme }) => {
  const { t } = useTranslation()

  const theme = useTheme()

  const { newPresetThemes, customThemes } = useThemes()

  return (
    <ColorPicker.Menu theme={getTheme(theme, selectedTheme)}>
      <StyledView direction='column' radius='regular' borderColor='grey5' background='white'>
        <>
          {Object.keys(customThemes).length > 0 && (
            <>
              <StyledText color='grey40' size='micro'>
                {t('author.custom')}
              </StyledText>
              <ColorIconsWrapper gap='none' wrap='wrap' direction='row'>
                {Object.entries(customThemes).map(([name, theme]) => (
                  <Styleddiv
                    key={name}
                    onMouseEnter={() => onMouseOver(name)}
                    onMouseLeave={() => onMouseOut()}
                  >
                    <ColorPicker.Color
                      key={name}
                      color={resolveThemeColor(theme.backgroundColor)}
                      isSelected={selectedTheme === name}
                      onClick={() => onClick(name)}
                    >
                      <StyledIcon iconId='text--color' color={color(theme.foregroundColor)} />
                    </ColorPicker.Color>
                  </Styleddiv>
                ))}
              </ColorIconsWrapper>
            </>
          )}
          <StyledText color='grey40' size='micro'>
            {t('author.preset')}
          </StyledText>
          <ColorIconsWrapper gap='none' wrap='wrap' direction='row'>
            {Object.entries(newPresetThemes).map(([name, theme]) => (
              <Styleddiv key={name} onMouseEnter={() => onMouseOver(name)} onMouseLeave={() => onMouseOut()}>
                <ColorPicker.Color
                  key={name}
                  color={resolveThemeColor(theme.backgroundColor)}
                  isSelected={selectedTheme === name}
                  onClick={() => onClick(name)}
                >
                  <StyledIcon iconId='text--color' color={color(theme.textColor)} />
                </ColorPicker.Color>
              </Styleddiv>
            ))}
          </ColorIconsWrapper>
        </>
      </StyledView>
    </ColorPicker.Menu>
  )
}

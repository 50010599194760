import { useMemo } from 'react'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { ReadOnlyStickyNoteEditor } from 'sierra-client/views/sticky-notes-card/editor'
import { useNote, useSectionNoteIds } from 'sierra-client/views/sticky-notes-card/helpers'
import {
  Avatar,
  BaseNoteContainer,
  FooterText,
  NoteBody,
  NoteFooter,
  SectionGrid,
  SectionHeader,
} from 'sierra-client/views/sticky-notes-card/shared-styles'
import { stickyNoteColors } from 'sierra-client/views/sticky-notes-card/sticky-notes-color-picker'
import { Data, SectionData } from 'sierra-client/views/sticky-notes-card/types'
import { StickyNotesCardYjsApi } from 'sierra-domain/card/sticky-notes-card'
import { asNonNullable } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { Spacer, View } from 'sierra-ui/primitives'

const Note: React.FC<{ noteId: string; api: StickyNotesCardYjsApi }> = ({ noteId, api }) => {
  const [metadata] = useNote(api, noteId)
  const currentUser = useSelector(selectUser)

  if (!metadata || !currentUser) return null

  const hasReacted = currentUser.uuid in metadata.reactions
  const reactionCount = Object.keys(metadata.reactions).length

  return (
    <BaseNoteContainer $color={metadata.color || asNonNullable(stickyNoteColors[0])}>
      <NoteBody className='NoteBody' id={`note-${noteId}`}>
        <ReadOnlyStickyNoteEditor api={api} noteId={noteId} user={currentUser} />
      </NoteBody>
      <NoteFooter>
        <Avatar userId={metadata.userId} />
        <View grow />
        <FooterText $isMe={hasReacted}>{reactionCount > 0 && reactionCount}</FooterText>
        <Spacer size='4' />
        <Icon
          iconId={hasReacted ? 'star--filled' : 'star'}
          color={color(hasReacted ? 'black' : 'rgba(0, 0, 0, 0.25)')}
        />
      </NoteFooter>
    </BaseNoteContainer>
  )
}

const Section: React.FC<{
  section: SectionData
  api: StickyNotesCardYjsApi
  allSections: SectionData[]
}> = ({ section, api, allSections }) => {
  const sectionIds = useMemo(() => allSections.map(section => section.id), [allSections])
  const noteIds = useSectionNoteIds(api, section.id, sectionIds)

  return (
    <>
      <SectionHeader>
        <span>{section.title}</span>
      </SectionHeader>
      <SectionGrid>
        {noteIds.map(noteId => (
          <Note key={noteId} noteId={noteId} api={api} />
        ))}
      </SectionGrid>
    </>
  )
}

type ReadOnlyStickyNotesViewProps = { sections: Data['sections']; api: StickyNotesCardYjsApi }
export const ReadOnlyStickyNotesView = (props: ReadOnlyStickyNotesViewProps): JSX.Element => {
  return (
    <>
      {props.sections.map(section => (
        <Section key={section.id} api={props.api} section={section} allSections={props.sections} />
      ))}
    </>
  )
}

import { useSetAtom } from 'jotai'
import React, { useEffect, useMemo, useState } from 'react'
import { queryClient } from 'sierra-client/api/query-client'
import { IconMenu } from 'sierra-client/components/common/icon-menu'
import { AddWidgetsHelpers } from 'sierra-client/features/insights/add-widgets-helpers'
import { useDashboardShareWithAll } from 'sierra-client/features/insights/api-hooks/use-dashboard-share-with-all'
import { useGetDashboard } from 'sierra-client/features/insights/api-hooks/use-get-dashboard'
import { toQueryRequest } from 'sierra-client/features/insights/api-hooks/use-query-analytics-data'
import { useUpsertDashboard } from 'sierra-client/features/insights/api-hooks/use-upsert-dashboard'
import { dashboardsMetaAtom } from 'sierra-client/features/insights/atoms'
import { DashboardSelector } from 'sierra-client/features/insights/dashboard-selector/dashboard-selector'
import { redirectToDashboard } from 'sierra-client/features/insights/dashboard-selector/utils'
import { DashboardErrorCard } from 'sierra-client/features/insights/dashboard/dashboard-error'
import {
  DashboardModal,
  DashboardModalAction,
} from 'sierra-client/features/insights/dashboard/dashboard-modal'
import { StickyDashboardHeader } from 'sierra-client/features/insights/dashboard/sticky-dashboard-header'
import { useDashboardFilter } from 'sierra-client/features/insights/dashboard/use-dashboard-filter'
import { getUpsertDashboardDataForWidget } from 'sierra-client/features/insights/dashboard/utils'
import { WidgetGrid } from 'sierra-client/features/insights/dashboard/widget-grid'
import { useAllFilterDomainReps } from 'sierra-client/features/insights/hooks/use-insights-views'
import {
  insightsDashboardDeletedLogger,
  insightsDashboardDuplicateLogger,
  insightsDashboardRenamedLogger,
  insightsDashboardSharedWithAllLogger,
} from 'sierra-client/features/insights/logger'
import { InsightsAssistantWithSearch } from 'sierra-client/features/insights/search-insights'
import { SearchWidgetResult } from 'sierra-client/features/insights/search-insights/types'
import { ShareDashboardPanel } from 'sierra-client/features/insights/share-dashboard/share-dashboard-panel'
import { DashboardFilterSelector } from 'sierra-client/features/insights/widget-builder/data-selectors/dashboard-filter-selector'
import { PartialDashboardWidget } from 'sierra-client/features/insights/widget-builder/types'
import { WidgetBuilderModalState } from 'sierra-client/features/insights/widget-builder/widget-builder-modal'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getCachedQueryKey, typedPost } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import { DetailsHeader } from 'sierra-client/views/manage/components/details-header'
import { useReportJobs } from 'sierra-client/views/manage/reports/use-reports'
import { OutputFormat } from 'sierra-domain/api/analytics-reporting'
import { Dashboard, DashboardWidget, FilterDomain, InsightsDashboardRole } from 'sierra-domain/api/insights'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { XAnalyticsDashboardsGet, XRealtimeAnalyticsReportsSubmitJob } from 'sierra-domain/routes'
import { MenuItem } from 'sierra-ui/components'
import { Button, LoadingSpinner, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'
import { WidgetBuilderModal } from '../widget-builder'

const DashboardSelectorContainer = styled(View)`
  flex: 0 1 260px;
`

const InsightsDashboardView = styled(View).attrs({ direction: 'column', paddingBottom: '48' })`
  width: 100%;
`

const Divider = styled.div`
  display: flex;
  width: auto;
  height: 2.5rem;
  color: ${token('border/strong')};
  background-color: ${token('surface/default')};
  align-self: stretch;
  align-items: center;
`

const _InsightsDashboard: React.FC<{
  id: NanoId12
  dashboard: Dashboard
  role: InsightsDashboardRole
  filterDomains: FilterDomain[] | undefined
}> = ({ id, dashboard, role, filterDomains }) => {
  const mutateDashboard = useUpsertDashboard()
  const allFilterDomainReps = useAllFilterDomainReps()

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const newInsightsHome = useFlag('insights/new-home')
  const [widgetBuilderModalState, setWidgetBuilderModalState] = useState<WidgetBuilderModalState>('closed')
  const [modalAction, setModalAction] = useState<DashboardModalAction>()
  const [dashboardFilter, setDashboardFilter] = useDashboardFilter({ dashboardId: id, filterDomains })
  const shareWithAllMutation = useDashboardShareWithAll()
  const { jobs, addJob } = useReportJobs({ pollInterval: 3000 })
  const { postWithUserErrorException } = usePost()
  const [currentJob, setCurrentJob] = useState<{ id: string; fileFormat: OutputFormat }>()

  const canOnlyView = role === 'viewer'
  const canShare = role === 'owner'
  const canEdit = role === 'editor' || role === 'owner'
  const canDelete = role === 'owner'

  const handleSelectWidget = (dashboardWidget: DashboardWidget): void => {
    if (canEdit) {
      setWidgetBuilderModalState({ type: 'open-edit', dashboardWidget })
    } else {
      setWidgetBuilderModalState({ type: 'open-viewer', dashboardWidget })
    }
  }

  const handleInsightsAssistantTemplate = (template: SearchWidgetResult): void => {
    setWidgetBuilderModalState({
      type: canEdit ? 'open-template-from-dashboard' : 'open-template-from-dashboard-viewer',
      dashboardWidget: {
        widget: template.widget,
        title: template.title,
        settings: {
          automaticTitle: false,
        },
      },
    })
  }

  const handleRemove = async (dashboardWidget: DashboardWidget): Promise<void> => {
    const deleteWidgetRequest = {
      dashboard: {
        ...dashboard,
        widgets: dashboard.widgets.filter(widget => widget.id !== dashboardWidget.id),
      },
    }
    try {
      await mutateDashboard.mutateAsync(deleteWidgetRequest)
    } catch (error) {
      console.error('Could not delete', { deleteWidgetRequest })
    }
  }

  const onModalSubmit = async (
    partialDashboardWidget: PartialDashboardWidget,
    onSuccess: () => void,
    dashboardId?: NanoId12
  ): Promise<void> => {
    if (dashboardId === undefined) {
      const upsertData = getUpsertDashboardDataForWidget({
        dashboardWidget: partialDashboardWidget,
        dashboard,
        allFilterDomainReps,
      })
      mutateDashboard.mutate(
        { dashboard: upsertData.dashboard },
        {
          onSuccess,
        }
      )
    } else {
      const queryKey = getCachedQueryKey(XAnalyticsDashboardsGet, { dashboardId })
      const dashboardResponse = await queryClient.fetchQuery({
        queryKey: queryKey,
        queryFn: () => typedPost(XAnalyticsDashboardsGet, { dashboardId }),
      })
      const upsertData = getUpsertDashboardDataForWidget({
        dashboardWidget: partialDashboardWidget,
        dashboard: dashboardResponse.dashboard.dashboard,
        allFilterDomainReps,
      })
      mutateDashboard.mutate(
        { dashboard: upsertData.dashboard },
        {
          onSuccess: () => {
            onSuccess()
            void redirectToDashboard(dashboardId)
          },
        }
      )
    }
  }

  const handleDuplicateWidget = (dashboardWidget: DashboardWidget): void => {
    void onModalSubmit(
      {
        widget: dashboardWidget.widget,
        settings: dashboardWidget.settings,
        title: `${dashboardWidget.title} (${t('dictionary.duplicate')})`,
      },
      () => {}
    )
  }

  const handleLayoutChange = async (widgets: DashboardWidget[]): Promise<void> => {
    const updateWidgetsRequest = { dashboard: { ...dashboard, widgets } }
    try {
      await mutateDashboard.mutateAsync(updateWidgetsRequest)
    } catch (error) {
      console.error('Could not update layout', { updateWidgetsRequest })
    }
  }

  const handleTitleChange = async (widgetId: string, newTitle: string, automatic: boolean): Promise<void> => {
    const updateWidgetsRequest = {
      dashboard: {
        ...dashboard,
        widgets: dashboard.widgets.map(widget => {
          if (widget.id === widgetId) {
            return {
              ...widget,
              title: newTitle,
              settings: {
                ...widget.settings,
                automaticTitle: automatic,
              },
            }
          }
          return widget
        }),
      },
    }
    try {
      await mutateDashboard.mutateAsync(updateWidgetsRequest)
    } catch (error) {
      console.error('Could not update title', { updateWidgetsRequest })
    }
  }

  const handleBuildWidgetFromHelper = (partialDashboardWidget?: PartialDashboardWidget): void => {
    if (partialDashboardWidget === undefined) {
      setWidgetBuilderModalState({ type: 'open-new' })
    } else {
      setWidgetBuilderModalState({ type: 'open-duplicate', dashboardWidget: partialDashboardWidget })
    }
  }

  const handleOnShareWithAll = (shareWithAll: boolean): void => {
    shareWithAllMutation.mutate({ dashboardId: id, shareWithAll })
    void dispatch(insightsDashboardSharedWithAllLogger({ dashboardId: id, value: shareWithAll }))
  }

  const handleAsyncDownload = async (dashboard: Dashboard, fileFormat: OutputFormat): Promise<void> => {
    const reports = dashboard.widgets.map(widget => ({
      report: widget.title,
      query: toQueryRequest(widget.widget).query,
    }))

    const submittedJob = await postWithUserErrorException(XRealtimeAnalyticsReportsSubmitJob, {
      reportId: 'analytics-batch',
      report: dashboard.title,
      reports,
      fileFormat,
    })

    setCurrentJob({ id: submittedJob.job.jobId, fileFormat })
    addJob(submittedJob.job)
  }

  const job = useMemo(
    () => jobs.find(job => job.reportId === 'analytics-batch' && job.jobId === currentJob?.id),
    [currentJob, jobs]
  )

  const overflowMenuItems: MenuItem[] = [
    {
      type: 'group' as const,
      id: 'dashboard-options',
      label: t('manage.insights.dashboard.options'),
      menuItems: [
        {
          type: 'label' as const,
          id: 'rename',
          label: t('manage.insights.dashboard.rename'),
          icon: 'edit' as const,
          onClick: () => {
            setModalAction('rename')
            void dispatch(
              insightsDashboardRenamedLogger({
                dashboardId: dashboard.id,
              })
            )
          },
          disabled: canOnlyView,
        },
        {
          type: 'label' as const,
          id: 'duplicate',
          label: t('manage.insights.dashboard.duplicate'),
          icon: 'duplicate' as const,
          onClick: () => {
            setModalAction('duplicate')
            void dispatch(insightsDashboardDuplicateLogger({ dashboardId: dashboard.id }))
          },
        },
        {
          type: 'nested' as const,
          id: 'download',
          label: t('manage.insights.dashboard.download'),
          icon: 'download' as const,
          menuItems: [
            {
              type: 'label' as const,
              icon: 'download',
              loading: currentJob?.fileFormat === 'xlsx' && job?.status === 'pending',
              id: 'download-xlsx-async',
              label: t('admin.analytics.download-xlsx'),
              onClick: () => handleAsyncDownload(dashboard, 'xlsx'),
            },
            {
              type: 'label' as const,
              icon: 'download',
              loading: currentJob?.fileFormat === 'csv' && job?.status === 'pending',
              id: 'download-csv-async',
              label: t('admin.analytics.download-csv'),
              onClick: () => handleAsyncDownload(dashboard, 'csv'),
            },
          ],
        },
      ],
    },
    {
      type: 'label' as const,
      id: 'delete',
      label: t('manage.insights.dashboard.delete'),
      icon: 'trash-can' as const,
      color: 'destructive/background' as const,
      onClick: () => {
        setModalAction('delete')
        void dispatch(insightsDashboardDeletedLogger({ dashboardId: dashboard.id }))
      },
      disabled: !canDelete,
    },
  ]

  return (
    <>
      {newInsightsHome && (
        <DetailsHeader
          backlink={{ href: '/manage/insights/dashboards', label: 'manage.backlinks--dashboards' }}
        />
      )}
      <InsightsDashboardView>
        <View paddingBottom='small'>
          <InsightsAssistantWithSearch
            onAskAssistant={query => {
              setWidgetBuilderModalState({
                type: canEdit ? 'open-generate-from-dashboard' : 'open-generate-from-dashboard-viewer',
                query,
              })
            }}
            handleTemplateSelect={handleInsightsAssistantTemplate}
          />
        </View>
        <StickyDashboardHeader>
          <View grow alignItems='center'>
            <DashboardSelectorContainer>
              <DashboardSelector selectedId={id} />
            </DashboardSelectorContainer>

            {filterDomains !== undefined && filterDomains.length > 0 && (
              <View data-intercom-target='dashboard-filter' alignItems='baseline'>
                {/* eslint-disable-next-line react/jsx-no-literals */}
                <Divider>{'|'}</Divider>
                <DashboardFilterSelector
                  dashboardId={id}
                  selectedFilter={dashboardFilter}
                  onChange={setDashboardFilter}
                  filterDomains={filterDomains}
                />
              </View>
            )}
          </View>
          <View gap='8'>
            {canEdit && (
              <Button
                data-testid='add-widget-button'
                variant='primary'
                onClick={() => {
                  setWidgetBuilderModalState({ type: 'open-new' })
                }}
              >
                {t('manage.insights.dashboard.new.widget')}
              </Button>
            )}
            {canShare && (
              <ShareDashboardPanel
                dashboardId={id}
                shareWithAll={dashboard.shareWithAll}
                onShareWithAll={handleOnShareWithAll}
              />
            )}
            <IconMenu
              iconId='overflow-menu--horizontal'
              aria-label='dashboard-options-menu'
              items={overflowMenuItems}
            />
          </View>
        </StickyDashboardHeader>
        {dashboard.widgets.length > 0 && (
          <WidgetGrid
            key={dashboard.id}
            dashboardFilter={dashboardFilter}
            onSelect={handleSelectWidget}
            dashboardWidgets={dashboard.widgets}
            onRemove={handleRemove}
            onDuplicate={handleDuplicateWidget}
            onTitleChange={handleTitleChange}
            onLayoutChange={handleLayoutChange}
            canEdit={canEdit}
          />
        )}
        <>
          {canEdit && dashboard.widgets.length === 0 && (
            <AddWidgetsHelpers onSelect={handleBuildWidgetFromHelper} />
          )}
          <WidgetBuilderModal
            state={widgetBuilderModalState}
            onClose={() => {
              setWidgetBuilderModalState('closed')
            }}
            onSubmit={onModalSubmit}
            isSubmitting={mutateDashboard.isPending}
            errorText={mutateDashboard.error?.status === 400 ? t('user-error.other.unknown') : undefined}
          />
        </>
      </InsightsDashboardView>
      <DashboardModal action={modalAction} onClose={() => setModalAction(undefined)} dashboard={dashboard} />
    </>
  )
}

export const InsightsDashboard: React.FC<{ id: NanoId12 }> = ({ id }) => {
  const queryResult = useGetDashboard({ dashboardId: id })
  const setDashboardsMeta = useSetAtom(dashboardsMetaAtom)

  useEffect(() => {
    if (queryResult.data !== undefined) {
      setDashboardsMeta(current => ({
        ...current,
        [id]: {
          ...current[id],
          widgetsMeta: queryResult.data.dashboard.dashboard.widgets.map(dashboardWidget => ({
            type: dashboardWidget.widget.type,
            layout: dashboardWidget.layout,
          })),
        },
      }))
    }
  }, [id, queryResult.data, setDashboardsMeta])

  if (queryResult.isPending) {
    return <LoadingSpinner />
  }

  if (queryResult.isError) {
    return (
      <View direction='column' grow>
        <View>
          <View>
            <DashboardSelector selectedId={id} />
          </View>
        </View>
        <View direction='column' alignItems='stretch' grow>
          <DashboardErrorCard />
        </View>
      </View>
    )
  }

  const { dashboard, role, filterDomains } = queryResult.data.dashboard

  return <_InsightsDashboard id={id} dashboard={dashboard} role={role} filterDomains={filterDomains} />
}

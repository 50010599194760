import { AnimatePresence, motion } from 'framer-motion'
import { useSetAtom } from 'jotai'
import _ from 'lodash'
import { useCallback, useEffect, useRef } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { generativeFeatureUsed } from 'sierra-client/core/logging/authoring/logger'
import { usePost } from 'sierra-client/hooks/use-post'
import { useCourseAssetResolver } from 'sierra-client/hooks/use-resolve-course-asset'
import { CustomThemeRecord, useThemes } from 'sierra-client/hooks/use-themes'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { logger } from 'sierra-client/logger/logger'
import { subscribeSse } from 'sierra-client/state/api'
import * as actions from 'sierra-client/state/author-course-settings/actions'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import { useDispatch } from 'sierra-client/state/hooks'
import { seededShuffle } from 'sierra-client/utils/seeded-shuffle'
import { useOrgNotificationSettings } from 'sierra-client/views/settings/emails-and-notifications/use-org-notification-settings'
import { Card, LittleCard, SmartCardsAtom } from 'sierra-client/views/showcase/sections/lil-stack'
import { CourseId } from 'sierra-domain/api/nano-id'
import { NotificationState } from 'sierra-domain/api/org-notification-settings'
import { AssetContext } from 'sierra-domain/asset-context'
import { isRight } from 'sierra-domain/either'
import { createNanoId12FromString } from 'sierra-domain/nanoid-extensions'
import { XRealtimeAuthorExtractAllImagesInContent } from 'sierra-domain/routes'
import { SSEXRealtimeAuthorGenerateSmartCards } from 'sierra-domain/routes-sse'
import { asNonNullable } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { Spacer, Switch, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { CustomTheme, CustomThemeName, isCustomThemeName } from 'sierra-ui/theming/legacy-theme'
import styled from 'styled-components'

const SmartReviewSection = styled(View)`
  background-color: ${token('surface/strong')};
  border-radius: 16px;
`

const OpaqueText = styled(Text)`
  opacity: 0.8;
`

const AnimatingSmartCardContainer = styled.div<{ $width: number }>`
  width: ${p => p.$width}px;
  position: relative;
  transition: 1s;
  height: 100%;
  cursor: pointer;
`

const CircleIconContainer = styled(motion.div)`
  position: absolute;
  left: -5px;
  bottom: -5px;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(34px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 100ms ease-in-out;

  :hover {
    background: rgba(0, 0, 0, 0.35);
  }
`

export const TinyCardContents: React.FC<{
  imageUrl?: string
  theme: CustomTheme | undefined
  smartCards: Card[]
  index: number
  card: Card
}> = ({ imageUrl, theme, smartCards, index, card }) => (
  <View style={{ height: '100%' }} direction='column' justifyContent='space-between'>
    <View>
      <Text color={imageUrl !== undefined ? 'white' : color(theme?.backgroundColor ?? 'white')} bold>
        {smartCards.length - index} of {smartCards.length}
      </Text>
    </View>
    <View gap='none' direction='column'>
      <Text
        style={{ maxWidth: '34ch' }}
        color={imageUrl !== undefined ? 'white' : color(theme?.backgroundColor ?? 'white')}
        bold
        size='large'
      >
        {card.title}
      </Text>
      <OpaqueText
        style={{ maxWidth: '34ch' }}
        color={imageUrl !== undefined ? 'white' : color(theme?.backgroundColor ?? 'white')}
        bold
        size='large'
      >
        {card.description}
      </OpaqueText>
    </View>
  </View>
)

export const AnimatingSmartCardStack: React.FC<{
  closeModal?: () => void
  setSmartCardsAtom: (cards: { cards: Card[]; mode: 'edit' | 'view' } | undefined) => void
  smartCards: Card[] | undefined
  customThemes: CustomThemeRecord
  assetContext: AssetContext
}> = ({ closeModal, setSmartCardsAtom, smartCards, customThemes, assetContext }) => {
  const assetResolver = useCourseAssetResolver()
  return (
    <AnimatingSmartCardContainer
      onClick={() => {
        closeModal?.()
        setSmartCardsAtom(smartCards !== undefined ? { cards: smartCards, mode: 'edit' } : undefined)
      }}
      $width={smartCards !== undefined && smartCards.length > 0 ? 72 + 16 : 0}
    >
      <AnimatePresence>
        {smartCards && smartCards.length > 0 && (
          <>
            {smartCards.slice(0, 5).map((card, index) => {
              const theme =
                card.theme !== undefined
                  ? isCustomThemeName(card.theme)
                    ? customThemes[card.theme]
                    : undefined
                  : undefined
              const imageUrl = card.image !== undefined ? assetResolver(card.image, assetContext) : undefined
              return (
                <LittleCard
                  // using the iteration index as the key makes cards change position when new ones are added. looks kind of fun
                  key={card.id}
                  index={index}
                  color={theme?.foregroundColor ?? '#fff'}
                  imageUrl={imageUrl}
                >
                  <TinyCardContents
                    imageUrl={imageUrl}
                    theme={theme}
                    smartCards={smartCards}
                    index={index}
                    card={card}
                  />
                </LittleCard>
              )
            })}
            <CircleIconContainer
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 1, delay: 2 } }}
              exit={{ opacity: 0 }}
            >
              <Icon color={'white'} size='size-14' iconId='edit' />
            </CircleIconContainer>
          </>
        )}
      </AnimatePresence>
      <div style={{ height: '60px' }}></div>
    </AnimatingSmartCardContainer>
  )
}

export const SmartCardsGenerator: React.FC<{
  smartCards: Card[] | undefined
  setSmartCards: (cards: Card[] | undefined) => void
  selfPacedContentId: CourseId
  closeModal?: () => void
  layout: 'standard' | 'manage'
  assetContext: AssetContext
}> = ({ smartCards, setSmartCards, selfPacedContentId, closeModal, layout, assetContext }) => {
  const abortControllerRef = useRef<AbortController>()

  const notif = useNotif()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { customThemes } = useThemes()

  const isSmartReviewNotificationDisabled =
    useOrgNotificationSettings().result.data?.notificationSettings.smartReview.state ===
    NotificationState.Disabled

  const { postWithUserErrorCode } = usePost()

  const setSmartCardsAtom = useSetAtom(SmartCardsAtom)

  const handleGenerateSmartCards = useCallback(async (): Promise<void> => {
    const themes = Object.keys(customThemes) as CustomThemeName[]
    setSmartCards([])
    let text = ''
    const courseImages = await postWithUserErrorCode(XRealtimeAuthorExtractAllImagesInContent, {
      courseId: selfPacedContentId,
    }).then(it => (isRight(it) ? _.shuffle(it.right.images) : []))

    const randomNumber = Math.random() * 100

    let generatedSmartCards: Card[] = []

    let error: unknown = undefined

    await subscribeSse({
      route: SSEXRealtimeAuthorGenerateSmartCards,
      input: { courseId: selfPacedContentId },
      onError: e => {
        // bearer:disable javascript_lang_logger_leak
        console.error(e)
        error = e
      },
      signal: abortControllerRef.current?.signal,
      onMessage: event => {
        text += event.data.text
        generatedSmartCards = text
          .split('###')
          .map((card, index) => {
            const [title, ...text] = card.split('---')
            return {
              id: createNanoId12FromString(index.toString()),
              index,
              title: title?.trim() ?? 'Refresh your knowledge!',
              description: text.join('').trim(),
              theme: asNonNullable(themes[index % themes.length]),
              image:
                // this is to not get the same image for every card in courses with few images
                seededShuffle(courseImages, `${index + randomNumber}`)[0],
              sourceCourseId: selfPacedContentId,
            }
          })
          .filter(it => it.description !== '' && it.title !== '')
        if (generatedSmartCards.length > (smartCards?.length ?? 0)) {
          setSmartCards(generatedSmartCards)
        }
      },
    })

    if (error !== undefined) {
      notif.push({ type: 'error' })
      setSmartCards(undefined)
      logger.error('Error in stream when trying to generate smart cards', { error: error })
      return
    }

    if (generatedSmartCards.length === 0) {
      notif.push({ type: 'error' })
      setSmartCards(undefined)
      logger.error('Zero smart cards where generated')
    }

    void dispatch(
      actions.updateCourseSmartCards(
        generatedSmartCards.map(card => ({
          id: card.id,
          title: card.title,
          description: card.description,
          image: card.image,
          theme: card.theme !== undefined ? { type: 'preset' as const, name: card.theme } : undefined,
          sourceCourseId: card.sourceCourseId,
        }))
      )
    )

    await dispatch(settingsActions.saveSettings({ notify: false }))

    void dispatch(
      generativeFeatureUsed({
        contentId: selfPacedContentId,
        contentType: 'self-paced',
        generativeFeature: 'generate-smart-cards',
      })
    )
  }, [
    customThemes,
    setSmartCards,
    postWithUserErrorCode,
    selfPacedContentId,
    dispatch,
    smartCards?.length,
    notif,
  ])

  useEffect(() => {
    return () => {
      abortControllerRef.current?.abort()
    }
  }, [])

  return layout === 'manage' ? (
    <View direction='row'>
      <View direction='column'>
        <Text size='regular' bold>
          Smart review
        </Text>
        <Spacer size='4' />
        <View direction='row'>
          <Switch
            disabled={isSmartReviewNotificationDisabled}
            ariaLabel='enable-smart-reviews'
            onChange={async switched => {
              if (!switched) {
                abortControllerRef.current?.abort()
                dispatch(actions.updateCourseSmartCards(undefined))
                await dispatch(settingsActions.saveSettings({ notify: false }))
                setSmartCards(undefined)
              }
              if (switched) {
                abortControllerRef.current = new AbortController()
                await handleGenerateSmartCards()
              }
            }}
            checked={smartCards?.length !== undefined}
          />

          <Text size='small' bold>
            {t('smart-review.modal.title')}
          </Text>
        </View>
        {isSmartReviewNotificationDisabled === true ? (
          <Text style={{ maxWidth: '40ch' }} bold size='small' color={'foreground/muted'}>
            {t('smart-review.disabled')}
          </Text>
        ) : (
          <Text style={{ maxWidth: '40ch' }} size='small' color='grey35'>
            {t('smart-review.modal.description')}
          </Text>
        )}
      </View>
      <div style={{ alignSelf: 'flex-end' }}>
        <AnimatingSmartCardStack
          closeModal={closeModal}
          setSmartCardsAtom={setSmartCardsAtom}
          smartCards={smartCards}
          customThemes={customThemes}
          assetContext={assetContext}
        />
      </div>
    </View>
  ) : (
    <SmartReviewSection direction='row' padding='24' grow justifyContent='space-between' alignItems='center'>
      <View direction='row' gap='none' alignItems='flex-start'>
        <AnimatingSmartCardStack
          closeModal={closeModal}
          setSmartCardsAtom={setSmartCardsAtom}
          smartCards={smartCards}
          customThemes={customThemes}
          assetContext={assetContext}
        />
        <View gap='2' direction='column'>
          <View gap='6'>
            <Text bold>{t('smart-review.modal.title')}</Text>

            <Text color='foreground/secondary'>{'·'}</Text>
            <Text color='foreground/secondary'>
              {t('smart-review.modal.number-of-cards', { numberOfCards: smartCards?.length ?? 5 })}
            </Text>
          </View>
          {isSmartReviewNotificationDisabled === true ? (
            <Text bold color={'foreground/muted'}>
              Smart review notifications are turned off for your organization.
            </Text>
          ) : (
            <Text style={{ width: '32ch' }} color='foreground/secondary'>
              {t('smart-review.modal.description')}
            </Text>
          )}
        </View>
      </View>
      <Switch
        disabled={isSmartReviewNotificationDisabled}
        ariaLabel='enable-smart-reviews'
        onChange={async switched => {
          if (!switched) {
            abortControllerRef.current?.abort()
            dispatch(actions.updateCourseSmartCards(undefined))
            await dispatch(settingsActions.saveSettings({ notify: false }))
            setSmartCards(undefined)
          }
          if (switched) {
            abortControllerRef.current = new AbortController()
            await handleGenerateSmartCards()
          }
        }}
        checked={smartCards?.length !== undefined}
      />
    </SmartReviewSection>
  )
}

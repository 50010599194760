import React from 'react'
import { config } from 'sierra-client/config/global-config'
import { Flags } from 'sierra-domain/api/private'

const FlagContext = React.createContext<Flags>({} as Flags)

// todo: should we move the flag state to jotai instead?

export const FlagContextProvider: React.FC<{
  children: React.ReactNode
}> = React.memo(({ children }) => {
  const backendFlags = config.organization.flags
  return <FlagContext.Provider value={backendFlags}>{children}</FlagContext.Provider>
})

/**
 * @deprecated: Use useFlag instead
 */
export function useFlags(): Flags {
  return React.useContext(FlagContext)
}

import { useEffect } from 'react'
import { initErrorTracking } from 'sierra-client/error/init-error-tracking'
import { useFlag } from 'sierra-client/hooks/use-flag'

export const ErrorTracking: React.FC = () => {
  const withReplays = useFlag('session-replays')

  useEffect(() => {
    initErrorTracking({
      withReplays,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { keyBy } from 'lodash'
import { DateTime } from 'luxon'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { getServerTimeNow } from 'sierra-client/state/collaboration/selectors'
import { AwarenessData, CustomAwarenessData } from 'sierra-client/state/live-session/types'
import { RootState } from 'sierra-client/state/types'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import {
  BreakoutRoom,
  BreakoutSession,
  LiveSessionData,
  Recording,
  ReflectionCardGroupByState,
  SessionState,
} from 'sierra-domain/live-session'
import { XRealtimeStrategyLiveSessionUpdateFacilitators } from 'sierra-domain/routes'

type ThunkAPI = { state: RootState }

export const liveSessionDataChanged = createAction<LiveSessionData>('liveSession/dataChanged')

export const liveSessionAwarenessStatesChanged = createAction<AwarenessData[]>(
  'liveSession/awarenessStatesChanged'
)

export const liveSessionLocalAwarenessStateMerged = createAction<Partial<CustomAwarenessData>>(
  'liveSession/localAwarenessStateMerged'
)

export const liveSessionClearInSessionLocalAwarenessData = createAction(
  'liveSession/liveSessionClearInSessionLocalAwarenessData'
)

export const liveSessionFacilitatorFlip = createAction<{ cardId: string; flip: boolean }>(
  'liveSession/facilitatorFlip'
)

export const liveSessionGroupReflectionResponsesToggle = createAction<{
  reflectionId: string
  groupBy: ReflectionCardGroupByState
}>('liveSession/reflectionResponsesGroupBy')

export const liveSessionCleared = createAction('liveSession/cleared')

export const liveSessionStateChanged = createAction<SessionState>('liveSession/stateChanged')

export const startBreakoutSession = createAsyncThunk<
  BreakoutSession,
  { breakoutRooms: BreakoutRoom[]; duration: number | undefined },
  ThunkAPI
>('liveSession/startBreakoutSession', (args, { getState }) => {
  const { duration, breakoutRooms } = args
  const serverTime = getServerTimeNow(getState().collaboration.clock)
  const now = new Date(serverTime).getTime()
  const endTime = duration !== undefined ? new Date(now + duration * 60 * 1000).toISOString() : undefined
  return { endTime, breakoutRooms: keyBy(breakoutRooms, 'id') }
})

export const startTimer = createAsyncThunk<{ endTime: string }, { durationSeconds: number }, ThunkAPI>(
  'liveSession/starTimer',
  (args, { getState }) => {
    const serverTime = getServerTimeNow(getState().collaboration.clock)
    return { endTime: DateTime.fromISO(serverTime).plus({ seconds: args.durationSeconds }).toISO() }
  }
)

export const addFacilitator = createAsyncThunk<
  void,
  { userId: UserId; liveSessionId: LiveSessionId },
  { state: RootState }
>('liveSession/addFacilitator', async (payload, { dispatch }) => {
  await postWithUserErrorException(
    XRealtimeStrategyLiveSessionUpdateFacilitators,
    {
      updates: [{ action: 'add', userId: payload.userId }],
      liveSessionId: payload.liveSessionId,
    },
    dispatch
  )
})

export const removeFacilitator = createAsyncThunk<
  void,
  { userId: UserId; liveSessionId: LiveSessionId },
  { state: RootState }
>('liveSession/removeFacilitator', async (payload, { dispatch }) => {
  await postWithUserErrorException(
    XRealtimeStrategyLiveSessionUpdateFacilitators,
    {
      updates: [{ action: 'remove', userId: payload.userId }],
      liveSessionId: payload.liveSessionId,
    },
    dispatch
  )
})

export const recordingStarted = createAsyncThunk<
  Recording,
  { liveSessionId: LiveSessionId; id: string },
  ThunkAPI
>('liveSession/recordingStarted', async (props, { getState }) => {
  const userId = selectUserId(getState())

  if (userId === undefined) {
    throw new Error(`Can't start recording: No user ID`)
  }

  const startTime = getServerTimeNow(getState().collaboration.clock)

  return { ...props, userId, startTime }
})

export const recordingEnded = createAsyncThunk<{ id: string }, { id: string }, ThunkAPI>(
  'liveSession/recordingEnded',
  props => {
    return { ...props }
  }
)

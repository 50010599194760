import { resolveTokenOrColor } from 'sierra-ui/color/token-or-color'
import { View } from 'sierra-ui/primitives'
import styled, { css } from 'styled-components'

export type FileBaseProps = {
  $isCurrentItem?: boolean
  $isHighlighted?: boolean
}

export type MultiSelectionFileProps = {
  $isSelected?: boolean
}

export const MultiSelectionFileCSS = css<MultiSelectionFileProps & FileBaseProps>`
  border: 1px solid transparent;

  ${p =>
    Boolean(p.$isSelected) &&
    !Boolean(p.$isCurrentItem) &&
    css`
      background-color: ${p => resolveTokenOrColor('org/primary', p.theme).opacity(0.04)};
    `}
`

export const FileBackgroundCss = css<FileBaseProps>`
  background-color: ${p =>
    Boolean(p.$isCurrentItem)
      ? resolveTokenOrColor('org/primary', p.theme).opacity(0.1)
      : Boolean(p.$isHighlighted)
        ? 'rgba(142, 217, 249, 0.16)'
        : 'transparent'};

  ${p =>
    Boolean(p.$isHighlighted) &&
    !Boolean(p.$isCurrentItem) &&
    css`
      outline: 2px solid rgba(142, 217, 249, 0.36);
      margin-right: 2px;
    `}

  ${p =>
    !(Boolean(p.$isCurrentItem) || Boolean(p.$isHighlighted)) &&
    css`
      &:hover {
        background-color: ${p => resolveTokenOrColor('org/primary', p.theme).opacity(0.04)};
      }
    `};

  ${p =>
    Boolean(p.$isHighlighted) &&
    !Boolean(p.$isCurrentItem) &&
    css`
      &:hover {
        background-color: rgba(142, 217, 249, 0.25);
      }
    `}
`

export const DistanceBetweenSidebarFiles = '2px'

export const FileContainerBaseStylings = css`
  padding: 8px 4px 8px 10px;
  border-radius: 0.5rem;
  height: 36px;
`
export const FolderTitleRowLearner = styled(View).attrs({
  cursor: 'pointer',
  alignItems: 'center',
  gap: '10',
})`
  height: 36px;
  user-select: none;
  padding: 8px 4px 8px 10px;
  margin-bottom: 2px;
`

import { UseQueryResult } from '@tanstack/react-query'
import { getInsightsQueryOptions } from 'sierra-client/features/insights/api-hooks/query-options'
import { typedInvalidateAllQueries, useCachedQuery } from 'sierra-client/state/api'
import { InsightsDashboardListResponse } from 'sierra-domain/api/insights'
import { XAnalyticsDashboardsList } from 'sierra-domain/routes'

export const useListDashboards = (): UseQueryResult<InsightsDashboardListResponse> => {
  const dashboardData = useCachedQuery(
    XAnalyticsDashboardsList,
    {},
    { ...getInsightsQueryOptions<InsightsDashboardListResponse>(), staleTime: 10 * 1000 }
  )

  return dashboardData
}

export const invalidateListDashboards = async (): Promise<void> => {
  await typedInvalidateAllQueries(XAnalyticsDashboardsList)
}

import 'array-flat-polyfill'
import DOMPurify from 'dompurify'
import { MotionGlobalConfig } from 'framer-motion'
import { enableMapSet } from 'immer'
import { useAtomValue, useSetAtom } from 'jotai'
import log from 'loglevel'
import { SnackbarProvider } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import { browserName, browserVersion, osName, osVersion } from 'react-device-detect'
import { Provider } from 'react-redux'
import { IntercomProvider, useIntercom } from 'react-use-intercom'
import { QueryClientProvider } from 'sierra-client/api/query-client'
import { SyncBrowserState } from 'sierra-client/components/browser/sync-browser-state'
import { UpdateBrowserSession } from 'sierra-client/components/browser/update-browser-session'
import { DndContext } from 'sierra-client/components/common/dnd/dnd-context'
import { useObservabilityTags } from 'sierra-client/components/liveV2/hooks/use-observability-tags'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { intercomOverrideAtom } from 'sierra-client/components/shortcut-menu/intercom-product-tour'
import { PermissionsInspector } from 'sierra-client/components/shortcut-menu/permissions-inspector/permissions-inspector'
import { AppWideShortcuts } from 'sierra-client/components/shortcut-menu/shortcuts'
import {
  IntercomPointerEventVisibility,
  isIntercomBootedAtom,
} from 'sierra-client/components/util/show-intercom-launcher'
import { config } from 'sierra-client/config/global-config'
import { ApplicationThemeProviders } from 'sierra-client/config/theme-providers'
import { ConfigContextProvider, useConfig } from 'sierra-client/context/config-context'
import { FlagContextProvider, useFlags } from 'sierra-client/context/flag-context'
import { LiveSessionContext } from 'sierra-client/context/live-session-context'
import { NotificationContext } from 'sierra-client/context/notification-context'
import { PageContext } from 'sierra-client/context/page-context'
import { DisabledScormProvider, EnabledScormProvider } from 'sierra-client/context/scorm-context'
import { Auth } from 'sierra-client/core/auth'
import { NativeAuth } from 'sierra-client/core/auth/cookie'
import { TokenAuth } from 'sierra-client/core/auth/token'
import { LanguageUpdater } from 'sierra-client/core/language-updater'
import { LocalLogger } from 'sierra-client/core/logging/local'
import { FanoutLogger, Logger } from 'sierra-client/core/logging/logger'
import { SegmentLogger } from 'sierra-client/core/logging/segment'
import { segmentService } from 'sierra-client/core/segment'
import { UserSingleton, createLogging } from 'sierra-client/core/user'
import { ErrorTracking } from 'sierra-client/error/error-tracking'
import { StrategicErrorBoundary } from 'sierra-client/error/strategic-error-boundary'
import { GlobalSidebar } from 'sierra-client/features/global-sidebar'
import { ReactDebug } from 'sierra-client/features/react-debug-mode'
import { MoveTeamspaceContentConfirmModal } from 'sierra-client/features/teamspace'
import { useFlag } from 'sierra-client/hooks/use-flag'
import { ShowCorrectAnswersContext } from 'sierra-client/hooks/use-show-correct-answers'
import { TranslationContextProvider, useTranslation } from 'sierra-client/hooks/use-translation'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { Logs } from 'sierra-client/layout/sana-page/components/logs'
import { DebugControls } from 'sierra-client/lib/use-debug/debug-controls'
import { logger } from 'sierra-client/logger/logger'
import { RealTimeDataClientProvider } from 'sierra-client/realtime-data/real-time-data-provider'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { loggingSlice } from 'sierra-client/state/logging/slice'
import { selectOrganization } from 'sierra-client/state/organization/selectors'
import { fetchOrganization, organizationSlice } from 'sierra-client/state/organization/slice'
import { store } from 'sierra-client/state/store'
import { StatePlugins } from 'sierra-client/state/user/plugins'
import { selectUser, selectUserOrganizationId } from 'sierra-client/state/user/user-selector'
import { FCC } from 'sierra-client/types'
import { FeatureDetection } from 'sierra-client/utils/feature-detection'
import { isPlaywrightInstance } from 'sierra-client/utils/playwright'
import { ReleaseMetadata } from 'sierra-client/utils/release-metadata'
import { AuthenticationLayout } from 'sierra-client/views/authentication/authentication-layout'
import { AuthenticationContent } from 'sierra-client/views/authentication/native/components/authentication-content'
import { AuthenticationContainer } from 'sierra-client/views/authentication/native/components/common'
import { useHasManageAccess } from 'sierra-client/views/manage/permissions/use-has-manage-access'
import { SmartCards } from 'sierra-client/views/showcase/sections/lil-stack'
import { AppExitedRequestParams } from 'sierra-domain/segment/event'
import { GlobalRadixTooltipProvider } from 'sierra-ui/components/tooltip'
import { Button, Text } from 'sierra-ui/primitives'
import { GlobalStyle } from 'sierra-ui/theming/global-style'
import * as smoothscroll from 'smoothscroll-polyfill'

enableMapSet()

export function initAppGlobals(): void {
  if (isPlaywrightInstance()) {
    // Animations steal time from Playwright tests and can cause flakiness, so we disable them.
    MotionGlobalConfig.skipAnimations = true
  }

  // DOMPurify will by default remove the target attribute, but this must be preserved since we
  // use target="_blank" for links.
  // This hook is copied from https://github.com/cure53/DOMPurify/tree/main/demos
  if (DOMPurify.isSupported) {
    const elementSupportsAttribute = (element: Element, attribute: string): boolean => attribute in element

    DOMPurify.addHook('afterSanitizeAttributes', function (node: Element) {
      // set all elements owning target to target=_blank
      if (elementSupportsAttribute(node, 'target')) {
        node.setAttribute('target', '_blank')
      }
    })
  }

  UserSingleton.initialize(store, createLogging())

  if (typeof window !== 'undefined') {
    switch (config.auth.type) {
      case 'native':
        Auth.initialize(new NativeAuth(store.dispatch, null))
        break
      case 'scorm':
        if (config.auth.token === undefined) throw Error('No token available')
        Auth.initialize(new TokenAuth(store.dispatch, config.auth.token))
        break
      case 'scorm-native':
        Auth.initialize(new NativeAuth(store.dispatch, null))
        break
      default:
        throw Error('Method of authorization not supported')
    }

    if (Boolean(config.segmentApiKey)) {
      segmentService.initialize(config.segmentApiKey)

      const logger = new FanoutLogger([new SegmentLogger(), new LocalLogger()])
      logger.initialize()
      Logger.initialize(logger)
    } else {
      const logger = new LocalLogger()
      logger.initialize()
      Logger.initialize(logger)
    }
  }

  if (typeof window !== 'undefined') {
    window.addEventListener('keydown', function (event) {
      if (event.ctrlKey || event.metaKey) {
        switch (String.fromCharCode(event.which).toLowerCase()) {
          case 's':
            event.preventDefault()
            break
        }
      }
    })
    smoothscroll.polyfill()

    window.addEventListener('beforeunload', () => {
      void segmentService.track(new AppExitedRequestParams())
    })
  }
}

const IntercomLoader = (): null => {
  const intercomFlag = useFlag('intercom')

  const user = useSelector(selectUser)
  const organization = useSelector(selectOrganization)
  const intercom = useIntercom()

  const enable = intercomFlag && config.auth.type === 'native'

  const setIsIntercomBooted = useSetAtom(isIntercomBootedAtom)

  const hasManageAccess = useHasManageAccess()

  useEffect(() => {
    if (enable) {
      if (user !== undefined && organization !== undefined) {
        intercom.shutdown()
        intercom.boot({
          name: `${user.firstName} ${user.lastName}`,
          userId: user.uuid,
          email: user.email,
          hideDefaultLauncher: true,
          userHash: user.userIntercomHash,
          customAttributes: {
            organization_uuid: user.organizationUuid,
            organization_name: user.organizationName,
            organization_domain: organization.domain,
            is_admin: hasManageAccess,
            access_level: user.accessLevel,
          },
        })
        setIsIntercomBooted(true)
      } else {
        intercom.shutdown()
        intercom.boot({ hideDefaultLauncher: true })
        setIsIntercomBooted(false)
      }
    }
  }, [enable, user, intercom, organization, setIsIntercomBooted, hasManageAccess])

  return null
}

const OrganizationLoader = (): null => {
  const organizationId = useSelector(selectUserOrganizationId)
  const dispatch = useDispatch()

  useEffect(() => {
    if (organizationId !== undefined) void dispatch(fetchOrganization())
    else void dispatch(organizationSlice.actions.organizationCleared())
  }, [organizationId, dispatch])

  return null
}

const ValidateDomain: FCC = ({ children }) => {
  const config = useConfig()
  const { t } = useTranslation()

  if (config.exists) {
    return <>{children}</>
  }

  return (
    <SanaPage mode='light' headerType='none' page={PageIdentifier.AuthenticationLogin()}>
      <AuthenticationLayout>
        <AuthenticationContainer>
          <AuthenticationContent message={t('invalid-domain.header')}>
            <Text color='foreground/primary' size='small' lineLength={45} align='center'>
              {t('invalid-domain.body')}
            </Text>
            <a href='https://www.sanalabs.com/contact'>
              <Button>{t('invalid-domain.contact-sales')}</Button>
            </a>
          </AuthenticationContent>
        </AuthenticationContainer>
      </AuthenticationLayout>
    </SanaPage>
  )
}

const VisibilityListener = (): null => {
  const hasRunRef = useRef(false)
  const [visibilityState, setState] = useState('visible')

  useObservabilityTags({
    visibilityState,
  })

  useEffect(() => {
    if (hasRunRef.current) return
    hasRunRef.current = true

    const onVisibilityChange = (): void => {
      setState(document.visibilityState)
      if (document.visibilityState === 'hidden') {
        logger.debug('document hidden')
      } else {
        logger.debug('document visible')
      }
    }

    const onBeforeUnload = (): void => {
      logger.debug('beforeunload')
    }

    document.addEventListener('visibilitychange', onVisibilityChange)
    document.addEventListener('beforeunload', onBeforeUnload)

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
      document.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [])

  return null
}

const AddObservabilityTags = (): JSX.Element => {
  const hasRunRef = useRef(false)
  const user = useSelector(selectUser)
  const flags = useFlags()
  const url = `${window.location.origin}${window.location.pathname}`
  const { tenantId } = useSelector(selectOrganization) ?? {}

  useObservabilityTags({
    url,
    userId: user?.uuid,
    isGuestUser: (user?.isGuestUser === true).toString(),
    browserName,
    browserVersion,
    osName,
    osVersion,
    tenantId,
    release: process.env.GITHUB_SHA,
  })

  useEffect(() => {
    if (!user) return
    if (hasRunRef.current) return
    hasRunRef.current = true

    logger.debug('flags', { flags })
  }, [flags, user])

  return <VisibilityListener />
}

const SanaIntercomProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const intercomOverride = useAtomValue(intercomOverrideAtom)
  const intercomEnabled = useFlag('intercom')

  return (
    <IntercomProvider
      appId={config.intercomAppId}
      apiBase='https://api-iam.eu.intercom.io'
      autoBoot={false}
      shouldInitialize={intercomEnabled || intercomOverride}
    >
      {children}
    </IntercomProvider>
  )
}

const SyncLoggingStatus = (): null => {
  const isSandbox = useFlag('sandbox')
  const segmentEnabled = useFlag('segment')

  // For organizations flagged as sandbox or have the segment flag disabled, we don't send data to Segment to
  // prevent skew in metrics
  const loggingEnabled = !isSandbox && segmentEnabled

  useEffect(() => {
    store.dispatch(loggingSlice.actions.setLoggingEnabled(loggingEnabled))
  }, [loggingEnabled])

  return null
}

export type AppComponentProps = {
  children: React.ReactNode
}

export const AppRootComponent = (props: AppComponentProps): JSX.Element => {
  const { children } = props

  useEffect(() => {
    // Set logLevel on load
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as any).setLogLevel = log.setLevel
    log.setLevel(config.logLevel as log.LogLevelDesc)

    // For the recaptcha URL, use recaptcha.net instead of google.com.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as any).recaptchaOptions = {
      useRecaptchaNet: true,
    }
  }, [])

  const ScormProvider = config.scorm.isScorm ? EnabledScormProvider : DisabledScormProvider

  return (
    <FlagContextProvider>
      <ConfigContextProvider value={config.organization}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={2500}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <TranslationContextProvider>
            <SanaIntercomProvider>
              <SyncLoggingStatus />
              <FeatureDetection />
              <ErrorTracking />
              <ReleaseMetadata />
              <ApplicationThemeProviders>
                <LiveSessionContext.Provider value={config.liveSession}>
                  <GlobalStyle />
                  <Provider store={store}>
                    <GlobalRadixTooltipProvider>
                      <ScormProvider>
                        <Logs />
                        <NotificationContext.Provider>
                          <StrategicErrorBoundary id='app'>
                            <RealTimeDataClientProvider>
                              <QueryClientProvider>
                                <ValidateDomain>
                                  <StatePlugins />
                                  <LanguageUpdater />
                                  <OrganizationLoader />
                                  <IntercomLoader />
                                  <IntercomPointerEventVisibility />
                                  <UpdateBrowserSession />
                                  <AddObservabilityTags />
                                  <PageContext.Provider>
                                    <SyncBrowserState />
                                    {/* Use route as key to detect which components are mounted and unmounted */}
                                    <ShowCorrectAnswersContext>
                                      <ShortcutMenu.Root>
                                        <DebugControls />
                                        <DndContext>
                                          <MoveTeamspaceContentConfirmModal />
                                          <GlobalSidebar>{children}</GlobalSidebar>
                                        </DndContext>
                                        <SmartCards />
                                        <AppWideShortcuts />
                                      </ShortcutMenu.Root>
                                    </ShowCorrectAnswersContext>
                                    <ReactDebug />
                                    <PermissionsInspector />
                                  </PageContext.Provider>
                                </ValidateDomain>
                              </QueryClientProvider>
                            </RealTimeDataClientProvider>
                          </StrategicErrorBoundary>
                        </NotificationContext.Provider>
                      </ScormProvider>
                    </GlobalRadixTooltipProvider>
                  </Provider>
                </LiveSessionContext.Provider>
              </ApplicationThemeProviders>
            </SanaIntercomProvider>
          </TranslationContextProvider>
        </SnackbarProvider>
      </ConfigContextProvider>
    </FlagContextProvider>
  )
}

import { AnimatePresence, motion } from 'framer-motion'
import { atom, useAtomValue } from 'jotai'
import React, { useEffect, useMemo } from 'react'
import { useIsCreateAccessible } from 'sierra-client/hooks/use-create-enabled'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import { selectCurrentCourseId } from 'sierra-client/state/content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { useCreatePageContextSafe } from 'sierra-client/views/flexible-content/create-page-context'
import { DisplayNone } from 'sierra-client/views/v3-author/components'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { LearnerReflections } from 'sierra-client/views/v3-author/reflection-card/learner'
import { ReflectionCardPreview } from 'sierra-client/views/v3-author/reflection-card/preview'
import { ReflectionsDataProvider } from 'sierra-client/views/v3-author/reflection-card/reflection-card-data-layer'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { InteractiveCardWrapper } from 'sierra-client/views/v3-author/wrapper'
import { iife } from 'sierra-domain/utils'
import styled from 'styled-components'

export { withReflectionCard } from 'sierra-client/views/v3-author/reflection-card/with-reflection-card'

const ReflectionsBlockContainer = styled.div`
  margin-top: 1rem;
`

const StyledInteractiveCardWrapper = styled(InteractiveCardWrapper)`
  position: relative;
  padding: 4rem 0.5rem;

  & {
    margin-top: 4rem;
  }

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    border-radius: ${p => p.theme.borderRadius['size-6']};
    transition: background-color 0.1s;
  }
`

export const ReflectionCardContainer = React.forwardRef<HTMLDivElement, SlateWrapperProps>((props, ref) => {
  const { children, attributes, mode, element } = props
  assertElementType('reflection-card', element)
  const userId = useSelector(selectUserId)

  const dispatch = useDispatch()
  const isCreate = mode === 'create' || mode === 'template'
  const courseId = useSelector(selectCurrentCourseId)
  const isCreateAccessible = useIsCreateAccessible()

  const createPageContextAtom = useCreatePageContextSafe()?.editOrResponsesStateAtom
  const liveSessionIdAtom = useMemo(() => {
    if (createPageContextAtom === undefined) return atom(undefined)
    else return createPageContextAtom
  }, [createPageContextAtom])
  const editOrResponsesState = useAtomValue(liveSessionIdAtom)

  // We need to fetch the latest state of collaborators in order to know if a user can remove reflections
  useEffect(() => {
    if (courseId !== undefined && !isCreate && isCreateAccessible)
      void dispatch(settingsActions.fetch({ courseId }))
  }, [dispatch, courseId, isCreate, isCreateAccessible])

  if (userId === undefined) return <DisplayNone>{children}</DisplayNone>

  return (
    <RenderingContext preventDrag={true} disableMenu={false}>
      <StyledInteractiveCardWrapper {...props} {...attributes} ref={ref}>
        <RenderingContext withGrid={false}>
          {children}
          <ReflectionsBlockContainer contentEditable={false}>
            <ReflectionsDataProvider element={element} mode={mode}>
              <AnimatePresence mode='wait'>
                {iife(() => {
                  if (isCreate && editOrResponsesState?.type !== 'responses')
                    return (
                      <motion.div
                        key='preview'
                        transition={{ duration: 0.2 }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <ReflectionCardPreview />
                      </motion.div>
                    )
                  else
                    return (
                      <motion.div
                        key='learner'
                        transition={{ duration: 0.2 }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <LearnerReflections />
                      </motion.div>
                    )
                })}
              </AnimatePresence>
            </ReflectionsDataProvider>
          </ReflectionsBlockContainer>
        </RenderingContext>
      </StyledInteractiveCardWrapper>
    </RenderingContext>
  )
})

export const ReflectionCard: SlateFC = ({ element, children }) => {
  assertElementType('reflection-card', element)

  return <>{children}</>
}

import React from 'react'
import { useConfig } from 'sierra-client/context/config-context'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { HasLines } from 'sierra-client/views/authentication/native/components/common'
import { Azure } from 'sierra-client/views/authentication/provider-icons/azure'
import { Google } from 'sierra-client/views/authentication/provider-icons/google'
import { Okta } from 'sierra-client/views/authentication/provider-icons/okta'
import { AuthMethodUnion } from 'sierra-domain/api/private'
import { XRealtimeAuthOauthGetLoginUrl, XRealtimeAuthSamlGetLoginUrl } from 'sierra-domain/routes'
import { Icon } from 'sierra-ui/components'
import { Button, Checkbox, Spacer, Text, View } from 'sierra-ui/primitives'
import { ButtonStyles, usePrimitiveButtonPseudoStyles } from 'sierra-ui/primitives/button/button'
import { spacing } from 'sierra-ui/theming'
import { PseudoStyles } from 'sierra-ui/utils/pseudo-styling'
import styled from 'styled-components'

export const intersperse = <T,>(x: T, ys: T[]): T[] => new Array<T>().concat(...ys.map(y => [x, y])).slice(1)

export const Separator: React.FC<unknown> = () => {
  const { t } = useTranslation()

  return <HasLines>{t('login.or')}</HasLines>
}

export const getAuthMethodForEmail = (
  methods: AuthMethodUnion[],
  email: string
): AuthMethodUnion | undefined => {
  const parts = email.split('@')

  if (parts.length < 2) return undefined

  const domain = parts[1]

  // For the social method, we do not treat it as a separate method since it is always used in
  // combination with the password method. The configurator will ensure that this invariant
  // is always true. This method will therefore never return the social method.

  for (const method of methods) {
    if (method.type !== 'social' && method.suffixes !== null && method.suffixes.some(v => v === domain))
      return method
  }

  for (const method of methods) {
    if (method.type !== 'social' && method.suffixes === null) return method
  }

  return undefined
}

export const getAuthMethodTypeForEmail = (
  methods: AuthMethodUnion[],
  email: string
): AuthMethodUnion['type'] | undefined => getAuthMethodForEmail(methods, email)?.type

export const useLogIn = (): {
  handleSamlLogIn: (provider: string | null) => Promise<void>
  handleSocialLogIn: (provider: string) => Promise<void>
} => {
  const { postWithUserErrorException } = usePost()

  return {
    handleSamlLogIn: async (provider: string | null): Promise<void> => {
      const { url } = await postWithUserErrorException(XRealtimeAuthSamlGetLoginUrl, {
        path: location.pathname,
        provider: provider === null ? undefined : provider,
      })
      location.href = url
    },
    handleSocialLogIn: async (provider: string): Promise<void> => {
      const { url } = await postWithUserErrorException(XRealtimeAuthOauthGetLoginUrl, {
        path: location.pathname,
        provider,
      })
      location.href = url
    },
  }
}

const ButtonDivider = styled.span`
  display: inline-block;
  height: 0.75rem;
  width: 1px;
  background-color: ${p => p.theme.color.grey10};
  margin-left: 0.5rem;
  margin-right: 0.75rem;
`

const providerIcons = {
  google: Google,
  azure: Azure,
  okta: Okta,
}

type ProviderIconId = keyof typeof providerIcons

const renderProviderIcon = (provider: ProviderIconId): JSX.Element => {
  switch (provider) {
    case 'google':
      return <Google />
    case 'azure':
      return <Azure />
    case 'okta':
      return <Okta />
  }
}

type ContinueWithSamlProps = {
  provider: string | null
  display: string | null
  icon: string | null
}

const StyledButton = styled.button<{ $grow: boolean; $pseudoStyles: PseudoStyles }>`
  ${ButtonStyles}
  height: 40px;
`

export const ContinueWithSaml: React.FC<ContinueWithSamlProps> = ({ provider, display, icon }) => {
  const { handleSamlLogIn } = useLogIn()
  const { t } = useTranslation()
  const pseudoStyles = usePrimitiveButtonPseudoStyles('secondary')

  return (
    <StyledButton $grow $pseudoStyles={pseudoStyles} onClick={() => handleSamlLogIn(provider)}>
      {icon !== null ? renderProviderIcon(icon as ProviderIconId) : <Icon iconId='locked' size='size-24' />}
      <ButtonDivider />
      {display === null ? <Text>{t('login.continue-with-sso')}</Text> : display}
    </StyledButton>
  )
}

export const ContinueWithGoogle: React.FC<unknown> = () => {
  const { handleSocialLogIn } = useLogIn()
  const { t } = useTranslation()
  const pseudoStyles = usePrimitiveButtonPseudoStyles('secondary')

  return (
    <StyledButton $grow $pseudoStyles={pseudoStyles} onClick={() => handleSocialLogIn('google')}>
      <Google /> <ButtonDivider /> <Text>{t('login.continue-with-google')}</Text>
    </StyledButton>
  )
}

export const ContinueAsGuest: React.FC<{ switchToLiveGuestAccess: () => void }> = ({
  switchToLiveGuestAccess,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Spacer />
      <Button variant='primary' onClick={switchToLiveGuestAccess} grow>
        {t('login.continue-as-guest')}
      </Button>
    </>
  )
}

const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 1.5rem;
`

const A = styled.a`
  opacity: 0.5;

  &:hover {
    text-decoration: underline;
  }
`

const TermsLabel = styled(Text)`
  margin-top: ${spacing['2']};
  margin-left: 0.5rem;
`

type TermsProps = {
  termsAccepted: boolean
  setTermsAccepted: (accepted: boolean) => void
}

export const Terms: React.FC<TermsProps> = ({ termsAccepted, setTermsAccepted }) => {
  const config = useConfig()

  return (
    <CheckboxContainer>
      <View marginTop={'2'}>
        <Checkbox
          id='terms-checkbox'
          checked={termsAccepted}
          onCheckedChange={checked => {
            setTermsAccepted(checked === true)
          }}
        />
      </View>
      <TermsLabel color='foreground/primary' as='label' size='small' htmlFor='terms-checkbox'>
        <Trans
          i18nKey={'create-account.tos' satisfies TranslationKey}
          components={{
            link1: (
              <A
                target='_blank'
                // Terms of use for user of company subscriber
                href={
                  config.terms?.termsOfUse ??
                  'https://www.sanalabs.com/download/legal/Terms-of-Use-for-User-of-Company-Subscriber.pdf'
                }
              />
            ),
            link2: (
              <A
                target='_blank'
                // privacy notice
                href={config.terms?.privacyNotice ?? 'https://sanalabs.com/legal/privacy-notice/'}
              />
            ),
            link3: (
              <A
                target='_blank'
                // cookie notice
                href={
                  config.terms?.cookieNotice ?? 'https://www.sanalabs.com/download/legal/Cookie-Notice.pdf'
                }
              />
            ),
          }}
        />
      </TermsLabel>
    </CheckboxContainer>
  )
}

const BoldLink = styled.a`
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const PrivacyPolicyDisclaimer: React.FC = () => {
  return (
    <View justifyContent='center' marginTop='16'>
      <Text size='micro' color='foreground/muted'>
        <Trans
          i18nKey='login.privacy-policy'
          components={{
            privacyLink: <BoldLink target='_blank' href='https://sanalabs.com/legal/privacy-notice/' />,
          }}
        />
      </Text>
    </View>
  )
}

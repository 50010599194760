import { useCallback } from 'react'
import { useConfirmationModalContext } from 'sierra-client/components/common/modals/confirmation-modal'
import { useBreakoutInSession } from 'sierra-client/components/liveV2/hooks/use-breakout-in-session'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { liveSessionSlice } from 'sierra-client/state/live-session/slice'
import { FileId } from 'sierra-domain/flexible-content/identifiers'

export const useGoToNode = (): ((fileId: FileId) => void) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const breakoutInSession = useBreakoutInSession()
  const confirmationModalContext = useConfirmationModalContext()

  const goToNode = useCallback(
    (fileId: FileId): void => {
      if (breakoutInSession) {
        confirmationModalContext.show({
          bodyText: t('live.breakout-rooms.leave-card-confirmation'),
          onConfirm: () => {
            void dispatch(liveSessionSlice.actions.stopBreakoutSession())
            void dispatch(liveSessionSlice.actions.setCurrentCardId(fileId))
          },
        })
      } else {
        void dispatch(liveSessionSlice.actions.setCurrentCardId(fileId))
      }
    },
    [breakoutInSession, confirmationModalContext, dispatch, t]
  )

  return goToNode
}

import { AnimatePresence, motion } from 'framer-motion'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useDrag } from 'react-dnd'
import { Folding } from 'sierra-client/features/program'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useTracking } from 'sierra-client/views/manage/programs/hooks/use-tracking'
import { useOutlineEdit } from 'sierra-client/views/manage/programs/staggered-assignments/hooks/use-outline-edit'
import { Dropzone } from 'sierra-client/views/manage/programs/staggered-assignments/renderer/sections/dropzone'
import { EmptyStateDropZone } from 'sierra-client/views/manage/programs/staggered-assignments/renderer/sections/empty-state-drop-zone'
import { ProgramSectionDragItem } from 'sierra-client/views/manage/programs/staggered-assignments/renderer/types'
import { isDefined } from 'sierra-domain/utils'
import { EditableText, Icon, MenuItem } from 'sierra-ui/components'
import { IconButton, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import { FCC } from 'sierra-ui/types'
import styled, { css } from 'styled-components'

const DragHandle = styled(Icon).attrs({
  iconId: 'draggable',
})`
  color: ${token('foreground/muted')};

  &:hover {
    color: ${token('foreground/primary')};
    cursor: grab;
  }
`

const Container = styled(motion.div).attrs({
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: { layout: { type: 'ease', duration: 0.3 } },
})<{ $isDragging: boolean }>`
  display: grid;
  grid: min-content / auto 1fr auto;
  grid-template-areas: 'handle title actions' '. description .' 'steps steps steps';
  grid-auto-rows: min-content;
  align-items: center;
  column-gap: 8px;
  border-radius: 16px;
  background: ${token('surface/soft')};
  position: relative;
  padding: 14px;

  ${p =>
    p.$isDragging
      ? css`
          opacity: 0.4 !important;
        `
      : css``}
`

const GridArea: FCC<{ area: string; isDragging: boolean }> = ({ children, area, isDragging }) => {
  return (
    <motion.div layout={isDragging ? false : 'position'} style={{ gridArea: area }}>
      {children}
    </motion.div>
  )
}

const StepListContainer = styled(motion.div).attrs({
  variants: {
    closed: {
      y: -10,
      opacity: 0,
      height: 0,
      marginTop: 0,
      padding: '0px 2px',
    },
    open: {
      y: 0,
      opacity: 1,
      height: 'auto',
      marginTop: 24,
      padding: '2px 2px',
    },
  },
  initial: 'closed',
  animate: 'open',
  exit: 'closed',
  transition: {
    duration: 0.2,
    ease: 'easeInOut',
  },
})`
  grid-area: steps;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: hidden;
`

type SectionRendererProps = { sectionIndex: number | null }

export const SectionRenderer: FCC<SectionRendererProps> = ({ children, sectionIndex }) => {
  const { t } = useTranslation()

  const nodeRef = useRef<HTMLDivElement>(null)
  const [collapsed, setCollapsed] = useState(false)
  const [editingTitle, setEditingTitle] = useState(false)
  const [editingDescription, setEditingDescription] = useState(false)
  const titleRef = useRef<HTMLParagraphElement>(null)
  const descriptionRef = useRef<HTMLParagraphElement>(null)
  const tracking = useTracking()

  const { outline, deleteSection, ungroupSection, patchSection } = useOutlineEdit()

  const section = isDefined(sectionIndex) ? outline.sections[sectionIndex] : null

  const menuItems = useMemo((): MenuItem[] => {
    return [
      {
        id: 'ungroup',
        type: 'label',
        label: t('manage.programs.edit-program.ungroup-section'),
        icon: 'grid',
      },
      {
        id: 'delete',
        type: 'label',
        label: t('dictionary.delete'),
        icon: 'trash-can',
        color: 'destructive/background',
      },
    ]
  }, [t])

  const handleMenuAction = useCallback(
    (item: MenuItem) => {
      if (!isDefined(sectionIndex)) {
        return
      }

      switch (item.id) {
        case 'ungroup': {
          ungroupSection(sectionIndex)
          return
        }
        case 'delete': {
          deleteSection(sectionIndex)
          return
        }
        default:
          return
      }
    },
    [sectionIndex, deleteSection, ungroupSection]
  )

  const [{ isDragging }, handleRef, dragRef] = useDrag<
    ProgramSectionDragItem,
    { index: number },
    { isDragging: boolean }
  >(() => {
    return {
      type: 'program-section',

      canDrag: true,

      item: () => {
        if (!isDefined(sectionIndex) || !isDefined(section)) return null

        return {
          type: 'program-section',
          index: sectionIndex,
          title: section.title,
          description: section.description ?? undefined,
        }
      },
      collect: monitor => ({ isDragging: monitor.isDragging() }),
    }
  }, [section, sectionIndex])

  handleRef(dragRef(nodeRef))

  if (!isDefined(section) || sectionIndex === null) {
    return null
  }

  const hasChildren = React.Children.count(children) > 0

  return (
    <Container ref={nodeRef} $isDragging={isDragging} tabIndex={0} layout={!isDragging}>
      <GridArea area='handle' isDragging={isDragging}>
        <DragHandle />
      </GridArea>
      <GridArea area='title' isDragging={isDragging}>
        <EditableText
          ref={titleRef}
          color='foreground/primary'
          bold
          withSingleClick
          value={section.title}
          onRename={value => {
            patchSection(sectionIndex, { title: value })
          }}
          placeholder='Edit title...'
          contentEditable={editingTitle}
          setContentEditable={setEditingTitle}
        />
      </GridArea>

      <GridArea area='description' isDragging={isDragging}>
        <AnimatePresence initial={false} mode='popLayout'>
          {!collapsed && (
            <Folding layout>
              <EditableText
                ref={descriptionRef}
                color='foreground/secondary'
                withSingleClick
                value={section.description ?? ''}
                onRename={value => {
                  patchSection(sectionIndex, { description: value })
                }}
                placeholder='Add a description'
                contentEditable={editingDescription}
                setContentEditable={setEditingDescription}
              />
            </Folding>
          )}
        </AnimatePresence>
      </GridArea>

      <GridArea area='actions' isDragging={isDragging}>
        <View>
          <IconMenu
            variant='transparent'
            size='small'
            iconId='overflow-menu--horizontal'
            menuItems={menuItems}
            onSelect={handleMenuAction}
          />
          <IconButton
            iconId={collapsed ? 'chevron--down--small' : 'chevron--up--small'}
            variant='transparent'
            size='small'
            onClick={() =>
              setCollapsed(wasCollapsed => {
                const collapsed = !wasCollapsed
                tracking.program.section.toggleCollapseSection({ sectionIndex, collapsed })
                return collapsed
              })
            }
            disabled={!hasChildren}
          />
        </View>
      </GridArea>

      {hasChildren ? (
        !collapsed && (
          <StepListContainer layout={isDragging ? false : 'position'}>{children}</StepListContainer>
        )
      ) : (
        <EmptyStateDropZone sectionIndex={sectionIndex} />
      )}

      <Dropzone position='above' sectionIndex={sectionIndex} collapsed={collapsed} />
      <Dropzone position='below' sectionIndex={sectionIndex} collapsed={collapsed} />
    </Container>
  )
}

import React from 'react'
import { ServerConfig } from 'sierra-domain/api/private'

const ConfigContext = React.createContext<ServerConfig>({} as ServerConfig)

export const ConfigContextProvider = ConfigContext.Provider

export function useConfig(): Omit<
  ServerConfig,
  // Flags should be accesed via useFlag
  'flags'
> {
  return React.useContext(ConfigContext)
}

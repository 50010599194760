import { ElementExtended } from 'sierra-client/views/v3-author/export-pdf/types'
import { UserTranscriptCourse, UserTranscriptResponse } from 'sierra-domain/api/manage'

const resolveCourseStatusModule = (course: UserTranscriptCourse): ElementExtended[] => {
  const elements: ElementExtended[] = []

  elements.push({
    id: course.id,
    type: 'course-status',
    course: course,
  })

  return elements
}

export const resolvePrintableTranscriptElements = (transcript: UserTranscriptResponse): ElementExtended[] => {
  const elements: ElementExtended[] = []

  elements.push({
    id: transcript.user.email,
    type: 'user-info',
    user: transcript.user,
  })
  elements.push(...transcript.courses.flatMap(course => resolveCourseStatusModule(course)))

  return elements
}

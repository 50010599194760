import { UseMutationResult } from '@tanstack/react-query'
import { typedInvalidateQuery, useTypedMutation } from 'sierra-client/state/api'
import { UnpublishDistributedCourseRequest } from 'sierra-domain/api/author-v2'
import { RequestError } from 'sierra-domain/error'
import {
  XRealtimeGetCourseDistributionPublishStates,
  XRealtimeGetCourseDistributionSettings,
  XRealtimeUnpublishDistributedCourse,
} from 'sierra-domain/routes'

export const useUnpublishDistributedCourseMutation = (): UseMutationResult<
  null,
  RequestError,
  UnpublishDistributedCourseRequest
> => {
  const mutation = useTypedMutation(XRealtimeUnpublishDistributedCourse, {
    onSuccess: async (_, input) => {
      const courseId = input.contentId

      await typedInvalidateQuery(XRealtimeGetCourseDistributionPublishStates, { courseId })
      await typedInvalidateQuery(XRealtimeGetCourseDistributionSettings, { courseId })
    },
  })

  return mutation
}

import { useMutation } from '@tanstack/react-query'
import Placeholder from '@tiptap/extension-placeholder'
import { Editor } from '@tiptap/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { createCommentersFromMentions } from 'sierra-client/components/chat/mention/create-commenters-from-mentions'
import { CustomMention } from 'sierra-client/components/chat/mention/custom-mention'
import { useMentionSuggestion } from 'sierra-client/components/chat/mention/use-mention-suggestion'
import { SendOnEnter } from 'sierra-client/components/chat/send-on-enter'
import {
  StyledEditorContent,
  getTiptapBaseExtensions,
  getTiptapContents,
} from 'sierra-client/components/chat/tiptap'
import { useOnUnmount } from 'sierra-client/hooks/use-on-unmount'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { typedPost } from 'sierra-client/state/api'
import {
  chatLocalAwarenessStateMerged,
  sendCommentMessage,
  sendPlainMessage,
} from 'sierra-client/state/chat/actions'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { ChatIdentifier, CreateChatMessageRequest } from 'sierra-domain/api/chat'
import { ChatMessageId, uuid } from 'sierra-domain/api/uuid'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { XRealtimeChatCreateMessage } from 'sierra-domain/routes'
import { IconButton } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import { useOnChanged } from 'sierra-ui/utils'
import { maxWidth } from 'sierra-ui/utils/media-query-styles'
import styled from 'styled-components'

const StyledInput = styled(StyledEditorContent)`
  flex: 1;
  height: 100%;
  max-height: 180px;
  outline: none;
  letter-spacing: inherit;
  resize: none;
  display: block;
  overflow: auto;

  .ProseMirror {
    padding: 0.5rem 0.875rem;
    background-color: transparent;
    transition: background-color 150ms cubic-bezier(0.25, 0.1, 0.25, 1);

    p,
    p::placeholder {
      ${maxWidth.tablet} {
        ${fonts.body.regular};
      }
    }
  }
`

const MessageInputContainer = styled.div`
  margin: 0 1.5rem 1.5rem 1.5rem;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: ${token('border/default')};
  border-radius: 0.25rem;

  &:hover {
    border: 1px solid ${token('border/strong')};
    transition: border 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  &:focus-within {
    border-color: ${token('border/strong')};
  }

  ${maxWidth.tablet} {
    font-size: 16px;
  }
`

const useSyncAwareness = ({ chatId, text }: { chatId: ScopedChatId; text: string }): void => {
  const dispatch = useDispatch()
  const isWritingTimeoutRef = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    return () => {
      void dispatch(
        chatLocalAwarenessStateMerged({
          chatId,
          partialLocalAwarenessState: { isWritingTo: undefined },
        })
      )
      isWritingTimeoutRef.current && clearTimeout(isWritingTimeoutRef.current)
    }
  }, [dispatch, chatId])

  useEffect(() => {
    isWritingTimeoutRef.current && clearTimeout(isWritingTimeoutRef.current)

    if (text === '') {
      void dispatch(
        chatLocalAwarenessStateMerged({
          chatId,
          partialLocalAwarenessState: { isWritingTo: undefined },
        })
      )
      return
    }

    void dispatch(
      chatLocalAwarenessStateMerged({ chatId, partialLocalAwarenessState: { isWritingTo: 'root' } })
    )

    isWritingTimeoutRef.current = setTimeout(() => {
      void dispatch(
        chatLocalAwarenessStateMerged({
          chatId,
          partialLocalAwarenessState: { isWritingTo: undefined },
        })
      )
    }, 2000)
  }, [dispatch, chatId, text])
}

const useSyncTypingState = ({
  threadId,
  text,
  setCurrentUserIsTyping,
}: {
  threadId: string
  text: string
  setCurrentUserIsTyping: (threadId: string | undefined) => void
}): void => {
  const isWritingTimeoutRef = useRef<ReturnType<typeof setTimeout>>()

  const cancelTimeout = useStableFunction(() => {
    isWritingTimeoutRef.current && clearTimeout(isWritingTimeoutRef.current)
  })

  useOnUnmount(() => {
    setCurrentUserIsTyping(undefined)
    cancelTimeout()
  })

  useOnChanged(
    (_prev, curr) => {
      cancelTimeout()

      if (curr.text === '') {
        setCurrentUserIsTyping(undefined)
        return
      }

      setCurrentUserIsTyping(curr.threadId)

      isWritingTimeoutRef.current = setTimeout(() => {
        setCurrentUserIsTyping(undefined)
      }, 2000)
    },
    useMemo(() => ({ threadId, text }), [threadId, text])
  )
}

export const MessageInput = ({
  chatId,
  chatIdentifier,
  unitId,
  blockId,
  threadId,
  placeholder = 'Write something...',
  triggerFocus,
  setCurrentUserIsTyping,
}: {
  chatId: ScopedChatId
  chatIdentifier: ChatIdentifier
  unitId?: string
  blockId?: string
  threadId?: string
  placeholder?: string
  triggerFocus?: boolean
  setCurrentUserIsTyping: (threadId: string | undefined) => void
}): JSX.Element => {
  const dispatch = useDispatch()
  const userId = useSelector(selectUserId)
  const [text, setText] = useState('')
  useSyncAwareness({ chatId: chatId, text })
  useSyncTypingState({ threadId: threadId ?? 'root', text, setCurrentUserIsTyping })
  const mentionSuggestion = useMentionSuggestion()

  const newChatSendMessageMutation = useMutation({
    mutationFn: async (params: CreateChatMessageRequest) => {
      await typedPost(XRealtimeChatCreateMessage, params)
    },
  })

  // Mentions are currently only supported on /create/{l,s}
  const mentionPlugin = useMemo(
    () =>
      mentionSuggestion !== undefined
        ? [
            CustomMention.configure({
              HTMLAttributes: {
                class: 'mention',
              },
              suggestion: mentionSuggestion,
            }),
          ]
        : [],
    [mentionSuggestion]
  )

  const send = useStableFunction(async (editor: Editor): Promise<void> => {
    if (text === '') return
    if (userId === undefined) return

    // To make the sending seems snapper dispite the new async
    // newChat step we clear the input box first and then send
    const data = getTiptapContents(editor)
    setText('')
    editor.commands.clearContent()

    const messageId = ChatMessageId.parse(uuid())

    if (blockId !== undefined) {
      void dispatch(
        sendCommentMessage({
          id: messageId,
          chatId,
          threadId: threadId ?? 'root',
          userId,
          tiptapJsonData: data,
          contentReference: { type: 'block', unitId, blockId },
        })
      )
    } else {
      void dispatch(
        sendPlainMessage({
          id: messageId,
          chatId,
          threadId: threadId ?? 'root',
          userId,
          tiptapJsonData: data,
        })
      )
    }

    const responseToMessageId = threadId !== undefined ? ChatMessageId.parse(threadId) : undefined

    newChatSendMessageMutation.mutate({
      chatIdentifier,
      messageData: data,
      responseToMessageId,
      messageId,
    })

    // If someone was tagged/mentioned and do not have editor or commenter access, add them as commenter
    createCommentersFromMentions(data, dispatch)
  })

  const editor = useMemo(
    () =>
      new Editor({
        extensions: [
          ...getTiptapBaseExtensions(),
          // Note: SendOnEnter needs to come before the mentions plugin,
          // otherwise pressing enter when a mention is active will
          // cause the message to be sent instead of selecting the mention.
          SendOnEnter.configure({
            send: () => {
              void send(editor)
            },
          }),
          ...mentionPlugin,
          Placeholder.configure({
            placeholder: placeholder,
          }),
        ],
        onUpdate: ({ editor: curEditor }) => {
          const updatedTextContent = curEditor.getText().trim()
          setText(updatedTextContent)
        },
      }),
    [mentionPlugin, placeholder, send]
  )

  useEffect(() => () => editor.destroy(), [editor])
  useEffect(() => {
    if (triggerFocus === true) {
      editor.commands.focus()
    }
  }, [editor, triggerFocus])

  if (userId === undefined) return <>Must be logged in</>

  return (
    <>
      <MessageInputContainer>
        <StyledInput editor={editor}></StyledInput>
        <IconButton
          size='small'
          variant='transparent'
          iconId='send--filled'
          onClick={() => send(editor)}
          disabled={!text.length}
        />
      </MessageInputContainer>
    </>
  )
}

import { useBlocker } from '@tanstack/react-router'
import { useAtomValue, useSetAtom } from 'jotai'
import React, { useEffect } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { FileExplorer as FacilitatorFileExplorer } from 'sierra-client/components/liveV2/facilitator/file-explorer'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { LiveSessionTitle } from 'sierra-client/components/liveV2/live-session-title'
import { FileExplorer as ParticipantFileExplorer } from 'sierra-client/components/liveV2/participant/file-explorer'
import { HeaderItemTitle, collapsableSidebarStateAtom } from 'sierra-client/features/collapsable-sidebar'
import {
  GlobalNestedSidebar,
  GlobalNestedSidebarComponent,
  GlobalSidebarLiveSessionContentReferenceAtom,
  GlobalSidebarOpenAtom,
} from 'sierra-client/features/global-sidebar'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsFacilitator } from 'sierra-client/state/live-session/selectors'
import {
  SpeakerNoteContext,
  useSpeakerNoteContext,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/context'
import { Tooltip } from 'sierra-ui/components'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const SpeakerNotesIconButton = styled(IconButton).attrs({ variant: 'transparent', size: 'small' })``

const SpeakerNotesButton: React.FC = () => {
  const { t } = useTranslation()
  const { currentTab, setTab } = useSpeakerNoteContext()
  const sidebarStatus = useAtomValue(collapsableSidebarStateAtom)
  const oldGlobalSidebarOpen = useAtomValue(GlobalSidebarOpenAtom)

  useEffect(() => {
    if (sidebarStatus === 'collapsed' || oldGlobalSidebarOpen) {
      setTab('Outline')
    }
  }, [oldGlobalSidebarOpen, setTab, sidebarStatus])

  return (
    <>
      <HeaderItemTitle animate={sidebarStatus}>
        <Text bold size='micro' color='foreground/muted'>
          {currentTab === 'Notes' ? t('create.speaker-notes.hide') : t('create.speaker-notes.add')}
        </Text>
      </HeaderItemTitle>
      <Tooltip title={currentTab === 'Notes' ? t('sidebar.close-notes') : t('sidebar.open-notes')}>
        <SpeakerNotesIconButton
          iconId={currentTab === 'Notes' ? 'checkmark--filled' : 'speaker--notes'}
          onClick={() => setTab(currentTab === 'Notes' ? 'Outline' : 'Notes')}
        />
      </Tooltip>
    </>
  )
}

const FacilitatorNestedSidebar: GlobalNestedSidebarComponent = () => (
  <SpeakerNoteContext>
    <View paddingLeft='6' gap='none'>
      <SpeakerNotesButton />
    </View>

    <FacilitatorFileExplorer />
  </SpeakerNoteContext>
)

const ParticipantNestedSidebar: GlobalNestedSidebarComponent = () => {
  return (
    <div>
      <View paddingLeft='8' gap='8'>
        <LiveSessionTitle />
      </View>
      <ParticipantFileExplorer />
    </div>
  )
}

const AccidentalNavigationBlocker = (): JSX.Element => {
  const { t } = useTranslation()
  const { status, proceed, reset } = useBlocker()

  return (
    <ActionModal
      open={status === 'blocked'}
      primaryAction={proceed}
      onClose={reason => {
        if (reason !== 'primaryAction') {
          reset()
        }
      }}
    >
      {t('live.leave-confirmation-question')}
    </ActionModal>
  )
}

export const SetGlobalSidebarContentReference = (): JSX.Element | null => {
  const setContentReference = useSetAtom(GlobalSidebarLiveSessionContentReferenceAtom)
  const { flexibleContentId, endedAt } = useLiveSessionContext().data
  const currentCard = useSelectCurrentCard()

  useEffect(() => {
    setContentReference({
      contentId: flexibleContentId,
      fileId: endedAt === undefined ? currentCard?.id : undefined,
    })

    return () => {
      setContentReference(undefined)
    }
  }, [currentCard?.id, endedAt, flexibleContentId, setContentReference])

  return null
}

export const LiveSessionGlobalSidebar: React.FC = () => {
  const isFacilitator = useSelector(selectIsFacilitator)

  return (
    <>
      <AccidentalNavigationBlocker />
      <GlobalNestedSidebar
        renderSidebar={() => {
          const Renderer = isFacilitator ? FacilitatorNestedSidebar : ParticipantNestedSidebar
          return <Renderer />
        }}
      />
    </>
  )
}
